import { getText } from '../../../lang'

export const getGoogleWarningData = (otherIntegrationsData) => {
  const integrationStrings = otherIntegrationsData.map((integration) => {
    return `${integration.organizationName} and ${integration.locationName} (ID: ${integration.locationId})`
  })
  const integrationList = integrationStrings.join(';\n')
  const warningMessage = `${getText('TEXT_PLEASE_NOTE_THIS_EMAIL_ATTACHED_SEVERAL_OTHER_INTEGRATIONS')} 
${integrationList};
${getText('TEXT_IF_YOU_DISCONNECT_THIS_INTEGRATION_ALL_INTEGRATIONS_ABOVE_WILL_BE_DISCONNECTED_TOO')}`

  return warningMessage
}
