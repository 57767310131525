import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='175'
    height='165'
    viewBox='0 0 175 165'
    fill='none'
  >
    <g filter='url(#filter0_d_14387_39781)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M90.4615 123.149C88.5498 125.617 85.4502 125.617 83.5385 123.149V123.149C81.7419 120.944 80.8437 119.841 79.819 118.946C77.6032 117.012 74.897 115.726 71.9978 115.23C70.6572 115 69.2349 115 66.3902 115H54.2291C47.5427 115 45.1181 114.304 42.6737 112.997C40.2292 111.689 38.3108 109.771 37.0035 107.326C35.6962 104.882 35 102.457 35 95.7709V49.2291C35 42.5427 35.6962 40.1181 37.0035 37.6737C38.3108 35.2292 40.2292 33.3108 42.6737 32.0035C45.1181 30.6962 47.5427 30 54.2291 30H120.771C127.457 30 129.882 30.6962 132.326 32.0035C134.771 33.3108 136.689 35.2292 137.997 37.6737C139.304 40.1181 140 42.5427 140 49.2291V95.7709C140 102.457 139.304 104.882 137.997 107.326C136.689 109.771 134.771 111.689 132.326 112.997C129.882 114.304 127.457 115 120.771 115H107.61C104.765 115 103.343 115 102.002 115.23C99.103 115.726 96.3968 117.012 94.181 118.946C93.1563 119.841 92.2581 120.944 90.4615 123.149V123.149Z'
        fill='white'
      />
    </g>
    <defs>
      <filter
        id='filter0_d_14387_39781'
        x='0'
        y='0'
        width='175'
        height='165'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dy='5' />
        <feGaussianBlur stdDeviation='17.5' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0.32549 0 0 0 0 0.333333 0 0 0 0 0.501961 0 0 0 0.14 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow_14387_39781'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow_14387_39781'
          result='shape'
        />
      </filter>
    </defs>
  </svg>
)

const SVGBackgroundShape = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGBackgroundShape
