import React, { memo } from 'react'
import SVGEmojiNew from '../../../../../icons/SVG/SVGEmojiNew'
import { Emoji } from './Emoji'
import './MessageEmoji.scss'

const MessageEmoji = (props) => {
  const {
    showEmojiModal,
    newMessageValue,
    currentCursorPosition,
    setnewMessageValue,
    setshowEmojiModal,
    isCustomerRedStatus,
    isCustomerYellowStatusAndRestricted,
    isCustomerPhoneNumberValid,
    setFocusOverInput,
    setcurrentCursorPosition,
    conversation,
  } = props

  const formatedMessageTextWithEmoji = (message, emoji) => {
    return [
      message.slice(0, currentCursorPosition),
      emoji,
      message.slice(currentCursorPosition),
    ].join('')
  }

  return (
    <div
      className={'emoji-with-icon' + (showEmojiModal ? ' active-emoji' : '')}
      id={'msg_emoji'}
    >
      <Emoji
        isShow={showEmojiModal}
        onClickOutside={() => {
          if (showEmojiModal) setshowEmojiModal(false)
        }}
        addEmoji={(emoji) => {
          let body = newMessageValue.inputMessageForTags
          let bodyMention = newMessageValue.inputMessageForSending

          const outputNewValue = formatedMessageTextWithEmoji(body, emoji.native)
          const outputPlainText = formatedMessageTextWithEmoji(
            bodyMention,
            emoji.native
          )

          setnewMessageValue({
            inputMessageForTags: outputNewValue,
            inputMessageForSending: outputPlainText,
          })

          const updatedCursorPosition = currentCursorPosition + emoji.native.length
          setcurrentCursorPosition && setcurrentCursorPosition(updatedCursorPosition)
          setFocusOverInput(true)
        }}
        onKeyUp={(e) => {
          setcurrentCursorPosition &&
            setcurrentCursorPosition(e.target.selectionStart)
        }}
        onClick={(e) => {
          setcurrentCursorPosition &&
            setcurrentCursorPosition(e.target.selectionStart)
        }}
      />
      <SVGEmojiNew
        className={`message-center-button icon-size ${
          isCustomerPhoneNumberValid ||
          isCustomerRedStatus ||
          isCustomerYellowStatusAndRestricted ||
          conversation.kind === 'gbm'
            ? 'disabled'
            : ''
        }`}
        onClick={() => {
          if (
            !isCustomerPhoneNumberValid &&
            !isCustomerRedStatus &&
            !isCustomerYellowStatusAndRestricted &&
            conversation.kind !== 'gbm'
          ) {
            setshowEmojiModal(!showEmojiModal)
          }
        }}
      />
    </div>
  )
}

export default memo(MessageEmoji)
