import React from 'react'
import { Radio } from 'antd'
import './MatRadioButton.scss'

const MatRadioButton = (props) => {
  const { value, radioOptions, type, buttonStyle } = props

  return (
    <Radio.Group
      className={`mat-radio-group ${props.className || ''}`}
      options={radioOptions}
      onChange={props.onChange}
      value={value}
      type={type}
      buttonStyle={buttonStyle}
    />
  )
}

MatRadioButton.defaultProps = {
  radioOptions: [{ label: 'Radio', value: '1' }],
  type: '',
  buttonStyle: '',
}

export default MatRadioButton
