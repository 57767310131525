import React, { memo, useState } from 'react'
import { Tooltip } from 'antd'
import { getText } from '../../../../../lang'
import SVGRemindMe from '../../../../../icons/SVG/SVGRemindMe'
import DateTimePickerModal from '../../../../../components/api/DateTimePickerModal'
import MatButton from '../../../../../components/MatButton'
import MatModal from '../../../../../components/MatModal'
import MatRow from '../../../../../components/MatRow'
import './MessageReminderAction.scss'

const RemindMePicker = (props) => {
  const {
    conversation,
    addToList,
    unarchiveConversation,
    unAssignConversation,
    forMobile,
    visibleFromDropMenu,
    setOpenReminder,
  } = props

  const [visible, setVisible] = useState(false)
  const [remindedDay, setRemindedDay] = useState('')
  const title = (
    <MatRow style={{ justifyContent: 'center' }}>
      <SVGRemindMe
        style={{ marginRight: '10px' }}
        color={conversation.isNew ? '#a3a6be' : '#0e0e0e'}
      />
      {getText('TEXT_REMIND_ME_TO_FOLLOW_UP')}
    </MatRow>
  )

  const remindMeOptions = [
    {
      label: getText('WORD_LATER_TODAY'),
      value: 'later_today',
    },
    {
      label: getText('WORD_TOMORROW'),
      value: 'tomorrow',
    },
    {
      label: getText('WORD_NEXT_WEEK'),
      value: 'next_week',
    },
    {
      label: getText('WORD_PICK_TIME_AND_DAY'),
      value: 'pick_time_and_day',
    },
  ]

  return (
    <MatModal
      noTrigger={forMobile}
      width={335}
      className={'message-date-picker-dropdown'}
      title={title}
      trigger={
        <Tooltip
          destroyTooltipOnHide={true}
          trigger={['hover', 'click']}
          title={getText('TEXT_REMIND_ME_TO_FOLLOW_UP')}
        >
          <MatButton
            buttonText={
              <SVGRemindMe
                color={
                  (conversation.isNew || conversation.kind === 'gbm') &&
                  'var(--thridyTextColor)'
                }
              />
            }
            className='message_popover_trigger'
            style={{ backgroundColor: 'transparent', padding: 0 }}
            disabled={conversation.isNew || conversation.kind === 'gbm'}
          />
        </Tooltip>
      }
      visible={visible || visibleFromDropMenu}
      onTrrigerClick={() => {
        setVisible(true)
      }}
      onCancel={() => {
        setVisible(false)
        setOpenReminder && setOpenReminder(false)
      }}
      hideFooter
    >
      <DateTimePickerModal
        conversation={conversation}
        remindedDay={remindedDay}
        closePopover={() => {
          setVisible(false)
          setOpenReminder && setOpenReminder(false)
        }}
        title={title}
        addToList={addToList}
        unarchiveConversation={unarchiveConversation}
        unAssignConversation={unAssignConversation}
        trigger={
          <>
            {remindMeOptions.map((item) => (
              <MatButton
                key={item.value}
                typeButton={'radius'}
                onClick={() => setRemindedDay(item.value)}
                buttonText={item.label}
              />
            ))}
          </>
        }
      />
    </MatModal>
  )
}

export default memo(RemindMePicker)
