import React, { memo, useEffect, useState } from 'react'
import { Form } from 'antd'
import { getText } from '../../../../lang'
import RadioFormButton from '../../../../components/Form/RadioFormButton'
import InputFormText from '../../../../components/Form/InputFormText'
import WorkflowDetectIntentWaitForReply from './WorkflowDetectIntentWaitForReply'
import WorkflowActionDone from './WorkflowActionDone'
import WorkflowActions from './WorkflowActions'
import WorkflowAddAction from './WorkflowAddActions'

const WorkflowDetectIntent = ({ radioOption, mainField, form }) => {
  const [selectedType, setSelectedType] = useState('')
  const [isLoading, setisLoading] = useState(true)

  useEffect(() => {
    const values = form.getFieldValue('actions')[0]
    for (let i = 0; i < values.intents.length; i++) {
      if (values.intents[i].actions.length > 0) {
        const element = values.intents[i].type
        let type = ''
        if (element === 'positive reply') {
          type = 0
        } else if (element === 'negative reply') {
          type = 1
        } else if (element === 'no reply') {
          type = 2
        }
        setSelectedType(type)
        break
      }
    }
    setisLoading(false)
  }, [])

  Form.useWatch(['actions', mainField.name, 'intents', 0], form)
  Form.useWatch(['actions', mainField.name, 'intents', 1], form)
  Form.useWatch(['actions', mainField.name, 'intents', 2], form)

  return (
    <>
      <InputFormText name={[mainField.name, 'type']} hidden />
      {Boolean(!isLoading) && (
        <Form.List name={[mainField.name, 'intents']}>
          {(fields, { add, remove }) => (
            <>
              <div className='detect-intent-wrapper'>
                <div className='detect-intent-title'>
                  {getText('WORD_DETECT_INTENT')}
                </div>
                <div className='detect-intent-actions'>
                  <RadioFormButton
                    radioOptions={radioOption}
                    value={selectedType}
                    onChange={(e) => {
                      setSelectedType(e.target.value)
                    }}
                  />
                  {selectedType === '' ? <WorkflowDetectIntentWaitForReply /> : null}
                </div>
              </div>
              {fields.map((intentsField, index) => {
                const fieldItemNameActions = [
                  'actions',
                  mainField.name,
                  'intents',
                  intentsField.name,
                  'actions',
                ]
                const fieldItemActions = form.getFieldValue(fieldItemNameActions)
                return (
                  <Form.Item hidden={selectedType !== index} key={intentsField.name}>
                    <WorkflowDetectIntentWaitForReply
                      mainField={mainField}
                      field={intentsField}
                      form={form}
                    />
                    <Form.List name={[selectedType, 'actions']}>
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map((intentActionsField, index) => {
                            return (
                              <>
                                {fieldItemActions.length > 0 && (
                                  <WorkflowActionDone type={selectedType} />
                                )}
                                <WorkflowActions
                                  key={index}
                                  form={form}
                                  mainField={mainField}
                                  selectedType={selectedType}
                                  intentActionsField={intentActionsField}
                                  onRemove={() => remove(intentActionsField.name)}
                                />
                              </>
                            )
                          })}
                          {Boolean(fieldItemActions.length !== 3) && (
                            <WorkflowAddAction
                              fieldItemActions={fieldItemActions}
                              type={selectedType}
                              onAddAction={add}
                            />
                          )}
                        </>
                      )}
                    </Form.List>
                  </Form.Item>
                )
              })}
            </>
          )}
        </Form.List>
      )}
    </>
  )
}

export default memo(WorkflowDetectIntent)
