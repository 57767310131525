import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='12' cy='12' r='12' fill={props.color || '#F6F7FB'} />
      <path
        d='M15.5021 16H8.5021C8.36949 16 8.24232 16.0527 8.14855 16.1464C8.05478 16.2402 8.0021 16.3674 8.0021 16.5C8.0021 16.6326 8.05478 16.7598 8.14855 16.8536C8.24232 16.9473 8.36949 17 8.5021 17H15.5021C15.6347 17 15.7619 16.9473 15.8557 16.8536C15.9494 16.7598 16.0021 16.6326 16.0021 16.5C16.0021 16.3674 15.9494 16.2402 15.8557 16.1464C15.7619 16.0527 15.6347 16 15.5021 16ZM8.5021 15H8.5471L10.6321 14.81C10.8605 14.7872 11.0741 14.6866 11.2371 14.525L15.7371 10.025C15.9118 9.84048 16.0062 9.59425 15.9996 9.34027C15.993 9.08628 15.8861 8.84525 15.7021 8.67L14.3321 7.3C14.1533 7.13205 13.919 7.03568 13.6738 7.02922C13.4285 7.02277 13.1895 7.10668 13.0021 7.265L8.5021 11.765C8.34048 11.928 8.23985 12.1416 8.2171 12.37L8.0021 14.455C7.99537 14.5282 8.00487 14.6021 8.02993 14.6712C8.055 14.7403 8.095 14.8031 8.1471 14.855C8.19382 14.9013 8.24923 14.938 8.31015 14.9629C8.37107 14.9878 8.4363 15.0004 8.5021 15ZM13.6371 8L15.0021 9.365L14.0021 10.34L12.6621 9L13.6371 8Z'
        fill='#474656'
      />
    </svg>
  )
}

const SVGChatEditImage = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGChatEditImage
