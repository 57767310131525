import React from 'react'
import './PageFull.scss'

const PageFull = ({ menuOpen, children, className, style }) => {
  return (
    <div
      style={style}
      className={`my-page-full ${menuOpen ? '' : 'side-menu-closedV2'} ${
        className || ''
      }`}
    >
      {children}
    </div>
  )
}

export default PageFull
