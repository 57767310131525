import React, { useCallback } from 'react'
import SVGProfileBorderDashed from '../../icons/SVG/SVGProfileBorderDashed'
import './ProfileLetters.scss'

const alphabeticArray = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']

const ProfileNumbers = ({ name, className, withoutIcon, activeIcon }) => {
  const getColor = useCallback(() => {
    const first = name ? name[0].toUpperCase() : ''
    if (first) {
      let index = alphabeticArray.indexOf(first)
      const color = ['blue', 'red', 'green', 'yellow'][index % 4]
      return color
    }
    return 'blue'
  }, [name])

  return (
    <>
      {!withoutIcon ? <SVGProfileBorderDashed /> : null}
      <div
        className={`mat-user-profile-letters mat-user-profile-numbers ${getColor()} ${
          className || ''
        } `}
      >
        {activeIcon && <div className='active-icon' />}
        {typeof name === 'string'
          ? name &&
            name
              .toUpperCase()
              .split(' ')
              .map((word) => word.substring(0, 4))
              .slice(0, 4)
          : Array.isArray(name)
            ? name.map((word) => {
                return word ? word.toUpperCase().substring(0, 1).slice(0, 2) : null
              })
            : null}
      </div>
    </>
  )
}

export default ProfileNumbers
