import { getText } from '../lang'

const utilsLocalizations = {
  localizedText: (text, whoSent_this) => {
    let localizedText = text
    switch (text) {
      case 'sell':
        localizedText = getText('WORD_SELL').toLowerCase()
        break
      case 'buy':
        localizedText = getText('WORD_BUY').toLowerCase()
        break
      case 'Automations changed the consent status of this customer from Pending to Refused':
        localizedText = getText(
          'TEXT_AUTOMATIONS_CHANGED_CONSENT_STATUS_THIS_CUSTOMER_PENDING_REFUSED'
        )
        break
      case 'Automations changed the consent status of the customer from Compliant to Opted-out':
        localizedText = getText(
          'TEXT_AUTOMATIONS_CHANGED_CONSENT_STATUS_THIS_CUSTOMER_COMPLIANT_OPTED_OUT'
        )
        break
      case 'Automations changed the consent status of this customer from Compliant to Refused':
        localizedText = getText(
          'TEXT_AUTOMATIONS_CHANGED_CONSENT_STATUS_THIS_CUSTOMER_COMPLIANT_REFUSED'
        )
        break
      case `${whoSent_this} changed the consent status of this customer from Compliant to Refused`:
        localizedText = `${whoSent_this} ${getText(
          'TEXT_USERNAME_CHANGED_CONSENT_STATUS_THIS_CUSTOMER_COMPLIANT_REFUSED'
        )}`
        break
      default:
        break
    }
    return localizedText
  },
}

export default utilsLocalizations
