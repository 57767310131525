import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import { Form, Input } from 'antd'
import { getText } from '../../../lang'

const InputFormText = forwardRef((props, ref) => {
  const inputRef = useRef()

  useImperativeHandle(ref, () => ({
    autoFocus(position) {
      setTimeout(() => {
        inputRef.current.focus()
        if (position) {
          inputRef.current.input.selectionStart = position
          inputRef.current.input.selectionEnd = position
        }
      }, 10)
    },
  }))

  return (
    <Form.Item
      className={`mat-form-item ${props.formClassName || ''}`}
      style={props.formStyle}
      label={props.label}
      name={props.name}
      hasFeedback={props.hasFeedback}
      initialValue={props.initialValue}
      validateStatus={props.validateStatus}
      hidden={props.hidden}
      rules={[
        {
          required: props.required,
          message: props.errorMessage
            ? props.errorMessage
            : `${getText('WORD_PLEASE_INPUT')} ${props.placeholder ? props.placeholder : props.label}!`,
          whitespace: props.required,
          validator: props.validator,
        },
        {
          max: props.maxLength,
          message: `${
            props.label ? props.label : props.placeholder
          } ${getText('TEXT_MUST_BE_MAXIMUM')} ${props.maxLength} ${getText('WORD_CHARACTERS')}.`,
        },
        {
          min: props.minLength,
          message: `${
            props.label ? props.label : props.placeholder
          } ${getText('TEXT_MUST_BE_MINIMUM')} ${props.minLength} ${getText('WORD_CHARACTERS')}.`,
        },
      ]}
    >
      <Input
        id={props.id}
        className={`mat-input-affix-wrapper ${props.inputClassName || ''}`}
        placeholder={props.placeholder ? props.placeholder : props.label}
        allowClear={props.allowClear}
        disabled={props.disabled}
        ref={inputRef}
        maxLength={props.maxLength}
        onPressEnter={props.onPressEnter}
        autoComplete={props.autoComplete}
        size={props.size}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        autoFocus={props.autoFocus}
        addonBefore={props.addonBefore}
        onChange={(e) => {
          if (e.target.value === '') {
            props.onClear()
          }
          props.onChange && props.onChange(e)
        }}
        onClick={props.onClick}
        onKeyDown={props.onKeyDown}
        onKeyUp={props.onKeyUp}
      />
    </Form.Item>
  )
})

InputFormText.defaultProps = {
  name: 'text',
  required: false,
  hasFeedback: false,
  allowClear: true,
  autoComplete: 'off',
  size: 'middle',
  onPressEnter: (e) => {
    e.preventDefault()
  },
  onClear: () => {},
  minLength: 0,
}

export default InputFormText
