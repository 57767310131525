import React, { memo } from 'react'
import { motion } from 'framer-motion'
import SVGOnline from '../../../../../icons/SVG/SVGOnline'
import { formatDateTime } from '../../../../../utils'
import './CenterMessageOnline.scss'
import { MESSAGE_BLOCK_ANIMATIONS } from '../../../utils/messageUtils'

const CenterOnline = (props) => {
  const { conversation, lastSeenDate } = props
  const fullName = conversation.receiver.fullName || conversation.receiver.phone

  return (
    <motion.div
      className='user-online-center-wrapper'
      {...MESSAGE_BLOCK_ANIMATIONS}
      layout
    >
      <div className='center-code'>
        <div className='icon-with-name'>
          <SVGOnline />
          <div className='online-user'>
            <div className='user-name'>{fullName} was recently online</div>
          </div>
        </div>
        <div className='date'>{formatDateTime(lastSeenDate)}</div>
      </div>
    </motion.div>
  )
}

export default memo(CenterOnline)
