import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width={props.width || '27'}
    height={props.heigth || '17'}
    viewBox='0 0 27 17'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect
      x='1'
      y='1'
      width='25'
      height='15'
      rx='4'
      stroke={props.color || '#9995B7'}
      strokeWidth='1.7'
      fill='transparent'
    />
    <path
      d='M13.5 7.95C12.7342 7.95 12.1111 7.33318 12.1111 6.575C12.1111 5.81683 12.7342 5.2 13.5 5.2C14.2658 5.2 14.8889 5.81683 14.8889 6.575C14.8889 6.87874 15.1376 7.125 15.4444 7.125C15.7513 7.125 16 6.87874 16 6.575C16 5.39934 15.1674 4.41322 14.0556 4.16236V3.55C14.0556 3.24626 13.8068 3 13.5 3C13.1932 3 12.9444 3.24626 12.9444 3.55V4.16236C11.8326 4.41322 11 5.39934 11 6.575C11 7.93972 12.1215 9.05 13.5 9.05C14.2658 9.05 14.8889 9.66683 14.8889 10.425C14.8889 11.1832 14.2658 11.8 13.5 11.8C12.7342 11.8 12.1111 11.1832 12.1111 10.425C12.1111 10.1213 11.8624 9.875 11.5556 9.875C11.2487 9.875 11 10.1213 11 10.425C11 11.6007 11.8326 12.5868 12.9444 12.8376V13.45C12.9444 13.7537 13.1932 14 13.5 14C13.8068 14 14.0556 13.7537 14.0556 13.45V12.8376C15.1674 12.5868 16 11.6007 16 10.425C16 9.06028 14.8785 7.95 13.5 7.95Z'
      fill={props.color || '#9995B7'}
      stroke={props.color || '#9995B7'}
      strokeWidth='0.5'
    />
  </svg>
)

const SVGOrgPaymentIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGOrgPaymentIcon
