import React from 'react'
import { getText } from '../../../lang'
import SVGThreePointVerticalIcon from '../../../icons/SVG/SVGThreePointVerticalIcon'
import MatDropMenu from '../../../components/MatDropMenu'
import utilDate from '../../../utils/utilsDate'

const ReportMobileView = ({ handleEditReport, item }) => {
  return (
    <div className='deposit-list-item-mobile-view-wrapper'>
      <div className='deposit-list-item-mobile-info' style={{ width: '100%' }}>
        <span className='payment-amount' style={{ color: '#0e0e0e' }}>
          {item.name}
        </span>
        <span className='payment-grey-item'>
          {item.updatedAt ? utilDate.getDateTimeForPayment(item.updatedAt) : ''}
        </span>
      </div>
      <MatDropMenu
        placement={'bottomLeft'}
        showArrow={false}
        style={{ width: 'auto' }}
        overlayStyle={{ width: '100px' }}
        options={[
          {
            name: getText('WORD_DOWNLOAD'),
            value: 'download',
          },
          {
            name: getText('ACTION_DELETE'),
            value: 'delete',
          },
        ]}
        onChange={(val) => {
          handleEditReport && handleEditReport(val, item)
        }}
        trigger={
          <div className='payment-page-subscription-icon in-report-page'>
            <SVGThreePointVerticalIcon />
          </div>
        }
      />
    </div>
  )
}

export default ReportMobileView
