import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width='17'
    height='16'
    viewBox='0 0 17 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M13.5002 12.667H10.8335'
      stroke={props.color || '#A3A1B7'}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.59987 7.24634C8.5332 7.23967 8.4532 7.23967 8.37987 7.24634C6.7932 7.19301 5.5332 5.89301 5.5332 4.29301C5.5332 2.65967 6.8532 1.33301 8.4932 1.33301C10.1265 1.33301 11.4532 2.65967 11.4532 4.29301C11.4465 5.89301 10.1865 7.19301 8.59987 7.24634Z'
      stroke={props.color || '#A3A1B7'}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.49336 14.5404C7.28003 14.5404 6.07336 14.2338 5.15336 13.6204C3.54003 12.5404 3.54003 10.7804 5.15336 9.70711C6.98669 8.48044 9.99336 8.48044 11.8267 9.70711'
      stroke={props.color || '#A3A1B7'}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

const SVGFilterUnassigned = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGFilterUnassigned
