import React from 'react'
import LocationCouponTitleTextarea from '../mobileCoupon/LocationCouponTitleTextarea'
import LocationsCouponPopover from '../mobileCoupon/LocationsCouponPopover'

const LocationPopupHeader = (props) => {
  const {
    headerEn,
    headerFr,
    headerSp,
    lang,
    onChangeHeaderEn,
    onChangeHeaderFr,
    onChangeHeaderSp,
    organization,
    onChangeColor,
    headerColor,
    headerFontSize,
    setHeaderFontSize,
    visibleHeaderEdit,
    setVisibleHeaderEdit,
    onChangeLanguage,
    withoutSelect,
  } = props

  return (
    <div className='text-with-edit-icon'>
      {lang === 'en' && (
        <LocationCouponTitleTextarea
          value={headerEn}
          titleColor={headerColor}
          titleFontSize={headerFontSize}
          onChangeTitle={(e) => {
            onChangeHeaderEn && onChangeHeaderEn(e)
          }}
        />
      )}
      {lang === 'fr' && (
        <LocationCouponTitleTextarea
          value={headerFr}
          titleColor={headerColor}
          titleFontSize={headerFontSize}
          onChangeTitle={(e) => {
            onChangeHeaderFr && onChangeHeaderFr(e)
          }}
        />
      )}
      {lang === 'sp' && (
        <LocationCouponTitleTextarea
          value={headerSp}
          titleColor={headerColor}
          titleFontSize={headerFontSize}
          onChangeTitle={(e) => {
            onChangeHeaderSp && onChangeHeaderSp(e)
          }}
        />
      )}
      <LocationsCouponPopover
        organization={organization}
        color={headerColor}
        setColor={(color) => {
          onChangeColor && onChangeColor(color)
        }}
        fontSize={headerFontSize}
        setFontSize={setHeaderFontSize}
        visibleEdit={visibleHeaderEdit}
        setVisibleEdit={setVisibleHeaderEdit}
        whitoutFontSize
        withoutSelect={withoutSelect}
        onChangeLanguage={onChangeLanguage}
      />
    </div>
  )
}

export default LocationPopupHeader
