import React from 'react'
import Icon from '@ant-design/icons'
import { MainColor } from '../../utils'

const SVGIcon = (props) => (
  <svg
    width={props.width || '16'}
    height={props.height || '16'}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M8.01339 1.93994C5.80673 1.93994 4.01339 3.73327 4.01339 5.93994V7.86661C4.01339 8.27328 3.84006 8.89327 3.63339 9.23994L2.86673 10.5133C2.39339 11.2999 2.72006 12.1733 3.58673 12.4666C6.46006 13.4266 9.56006 13.4266 12.4334 12.4666C13.2401 12.1999 13.5934 11.2466 13.1534 10.5133L12.3867 9.23994C12.1867 8.89327 12.0134 8.27328 12.0134 7.86661V5.93994C12.0134 3.73994 10.2134 1.93994 8.01339 1.93994Z'
      stroke={props.color || MainColor}
      strokeMiterlimit='10'
      strokeLinecap='round'
    />
    <path
      d='M9.24694 2.13321C9.04027 2.07321 8.82694 2.02655 8.60694 1.99988C7.96694 1.91988 7.35361 1.96655 6.78027 2.13321C6.97361 1.63988 7.45361 1.29321 8.01361 1.29321C8.57361 1.29321 9.05361 1.63988 9.24694 2.13321Z'
      stroke={props.color || MainColor}
      strokeWidth='1.5'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.0137 12.7068C10.0137 13.8068 9.11367 14.7068 8.01367 14.7068C7.467 14.7068 6.96034 14.4801 6.60034 14.1201C6.24034 13.7601 6.01367 13.2535 6.01367 12.7068'
      stroke={props.color || MainColor}
      strokeWidth='1.5'
      strokeMiterlimit='10'
    />
  </svg>
)

const SVGMessageRemindMeIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGMessageRemindMeIcon
