import React from 'react'
import { getText } from '../../lang'
import SVGSuccess from '../../icons/SVG/SVGSuccess'
import MatModal from '../MatModal'
import MatRow from '../MatRow'

const SuccessModal = ({ icon, content, text, open }) => {
  return (
    <MatModal noTrigger hideFooter visible={open} closable={false}>
      <MatRow style={{ flexDirection: 'column', padding: '15px 0' }}>
        <div style={{ marginRight: '0' }}>{icon || <SVGSuccess />}</div>
        <h3
          style={{
            marginTop: '20px',
            fontWeight: 'bold',
            fontSize: '30px',
            marginRight: '0',
          }}
        >
          {content || `${getText('WORD_SUCCESS')}!`}
        </h3>
        <h4
          style={{
            fontWeight: '500',
            fontSize: '16px',
            marginRight: '0',
            color: '#77808d',
          }}
        >
          {text}
        </h4>
      </MatRow>
    </MatModal>
  )
}

export default SuccessModal
