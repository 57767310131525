import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width={props.width || 21}
    height={props.height || 20}
    viewBox='0 0 21 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.4863 3.18022C16.5794 2.27326 15.1089 2.27326 14.2019 3.18022L13.1194 4.26276L16.4038 7.54716L17.4863 6.46461C18.3933 5.55765 18.3933 4.08718 17.4863 3.18022ZM15.3431 8.60782L12.0587 5.32343L4.65567 12.7265C4.18855 13.1936 3.87271 13.7904 3.74917 14.4394L3.44148 16.0557C3.30952 16.7489 3.91766 17.357 4.61084 17.2251L6.22719 16.9174C6.87614 16.7938 7.47295 16.478 7.94007 16.0109L15.3431 8.60782Z'
      fill='#A3A1B7'
    />
  </svg>
)

const SVGEditNew = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGEditNew
