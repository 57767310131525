import { fetchFromUrlGETAsync } from "../../utils/UrlHelper";

const urlAppointment = "/locations";

const fs = {
  getAppointmentList: async (itemId, start_date, end_date) => {
    const urlParams = [
      { key: "start_date", value: start_date },
      { key: "end_date", value: end_date },
    ];
    const result = await fetchFromUrlGETAsync(
      urlAppointment + "/" + itemId + "/appointment",
      urlParams
    );
    return result;
  },
};

const loc = {};

const appointmentActions = Object.assign(fs, loc);

export default appointmentActions;
