import React from 'react'
import Icon from '@ant-design/icons'
import { MainColor } from '../../utils'

const SVGIcon = (props) => (
  <svg
    width={props.width || '16'}
    height={props.height || '16'}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M6.87988 12.647C6.97322 12.7204 7.06655 12.787 7.16655 12.8604C7.92655 13.4137 8.69322 13.8537 9.45988 14.1804C10.2265 14.507 10.9532 14.667 11.6332 14.667C12.0999 14.667 12.5332 14.5804 12.9332 14.4137C13.3399 14.247 13.6999 13.987 14.0266 13.627C14.2199 13.4137 14.3665 13.187 14.4799 12.947C14.5932 12.707 14.6465 12.4604 14.6465 12.2204C14.6465 12.0337 14.6065 11.867 14.5399 11.7004C14.4665 11.5337 14.3466 11.3937 14.1732 11.2737L11.9666 9.70702C11.7999 9.59369 11.6465 9.50702 11.4999 9.44702C11.3532 9.38702 11.2199 9.36035 11.0932 9.36035C10.9265 9.36035 10.7732 9.40702 10.6199 9.50035C10.4665 9.58035 10.3065 9.70702 10.1399 9.87369L9.63322 10.3737C9.55988 10.447 9.47322 10.487 9.35988 10.487C9.29988 10.487 9.24655 10.4804 9.19322 10.4604C9.14655 10.4404 9.10655 10.4204 9.07322 10.407C8.94655 10.3404 8.79988 10.247 8.63988 10.127'
      stroke={props.color || MainColor}
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7.15301 8.8135C6.80634 8.46016 6.48634 8.10683 6.18634 7.76016C5.89301 7.4135 5.68634 7.12683 5.56634 6.90683C5.55301 6.86683 5.53301 6.82683 5.51301 6.7735C5.49967 6.72016 5.49301 6.6735 5.49301 6.62016C5.49301 6.5135 5.52634 6.42683 5.59967 6.3535L6.10634 5.82683C6.26634 5.66683 6.39301 5.50683 6.47967 5.3535C6.57301 5.20016 6.61967 5.04016 6.61967 4.88016C6.61967 4.7535 6.58634 4.6135 6.52634 4.4735C6.46634 4.32683 6.37967 4.1735 6.25967 4.00683L4.71301 1.82683C4.59301 1.6535 4.43967 1.5335 4.26634 1.4535C4.09967 1.3735 3.91301 1.3335 3.72634 1.3335C3.23301 1.3335 2.76634 1.54016 2.33967 1.96016C1.98634 2.2935 1.73301 2.66683 1.57301 3.0735C1.41301 3.4735 1.33301 3.90683 1.33301 4.36016C1.33301 5.0535 1.49301 5.78016 1.81301 6.54016C2.13301 7.2935 2.57301 8.0535 3.11967 8.8135C3.67301 9.5735 4.29301 10.3002 4.97967 10.9935'
      stroke={props.color || MainColor}
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14.6663 1.3335L1.33301 14.6668'
      stroke={props.color || MainColor}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

const SVGMessageIncomingFinishedIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGMessageIncomingFinishedIcon
