import React, { forwardRef, useState, useImperativeHandle, memo } from 'react'
import { getText } from '../../../lang'
import SelectFromTagsList from '../../../components/api/SelectFromTagsList'

const TagsFilter = forwardRef((props, ref) => {
  const { onChangeTags } = props

  useImperativeHandle(ref, () => {
    return {
      resetTags: () => {
        setListTagsSelected([])
        onChangeTags([])
      },
    }
  })

  const [listTagsSelected, setListTagsSelected] = useState([])

  return (
    <div className='dropdown-with-selected-item'>
      <SelectFromTagsList
        label={getText('WORD_TAGS')}
        placeholder={getText('WORD_SELECT_TAGS')}
        value={listTagsSelected}
        mode='multiple'
        multipleNewStyle
        onChangeItem={(val, items) => {
          onChangeTags(items)
          setListTagsSelected(
            items.map((item) => {
              return item.value
            })
          )
        }}
        notForm
      />
    </div>
  )
})

export default memo(TagsFilter)
