import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width='17'
    height='16'
    viewBox='0 0 17 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M15.1668 4.16634V7.56633C15.1668 8.41299 14.8868 9.12634 14.3868 9.61967C13.8935 10.1197 13.1802 10.3997 12.3335 10.3997V11.6063C12.3335 12.0597 11.8268 12.333 11.4535 12.0797L10.8068 11.653C10.8668 11.4463 10.8935 11.2197 10.8935 10.9797V8.26636C10.8935 6.90636 9.98683 5.99967 8.62683 5.99967H4.10016C4.00683 5.99967 3.92016 6.00635 3.8335 6.01302V4.16634C3.8335 2.46634 4.96683 1.33301 6.66683 1.33301H12.3335C14.0335 1.33301 15.1668 2.46634 15.1668 4.16634Z'
      stroke={props.color || '#A3A1B7'}
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.8935 8.26668V10.98C10.8935 11.22 10.8668 11.4467 10.8068 11.6533C10.5602 12.6333 9.74683 13.2467 8.62683 13.2467H6.8135L4.80016 14.5867C4.50016 14.7933 4.10016 14.5733 4.10016 14.2133V13.2467C3.42016 13.2467 2.8535 13.02 2.46016 12.6267C2.06016 12.2267 1.8335 11.66 1.8335 10.98V8.26668C1.8335 7.00002 2.62016 6.12668 3.8335 6.01335C3.92016 6.00668 4.00683 6 4.10016 6H8.62683C9.98683 6 10.8935 6.90668 10.8935 8.26668Z'
      stroke={props.color || '#A3A1B7'}
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

const SVGFilterMainInbox = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGFilterMainInbox
