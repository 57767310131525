import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width='22'
    height='22'
    viewBox='0 0 22 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M7.10677 16.9583C3.8251 16.9583 1.14844 14.2817 1.14844 11C1.14844 7.71833 3.8251 5.04166 7.10677 5.04166C7.4826 5.04166 7.79427 5.35333 7.79427 5.72916C7.79427 6.105 7.4826 6.41666 7.10677 6.41666C4.57677 6.41666 2.52344 8.47 2.52344 11C2.52344 13.53 4.57677 15.5833 7.10677 15.5833C9.63677 15.5833 11.6901 13.53 11.6901 11C11.6901 10.6242 12.0018 10.3125 12.3776 10.3125C12.7534 10.3125 13.0651 10.6242 13.0651 11C13.0651 14.2817 10.3884 16.9583 7.10677 16.9583Z'
      fill={props.color || '#7854F6'}
    />
    <path
      d='M14.6641 17.1875C14.2882 17.1875 13.9766 16.8758 13.9766 16.5C13.9766 16.1242 14.2882 15.8125 14.6641 15.8125C17.3132 15.8125 19.4766 13.6492 19.4766 11C19.4766 8.35083 17.3132 6.1875 14.6641 6.1875C12.0149 6.1875 9.85156 8.35083 9.85156 11C9.85156 11.3758 9.5399 11.6875 9.16406 11.6875C8.78823 11.6875 8.47656 11.3758 8.47656 11C8.47656 7.59 11.2541 4.8125 14.6641 4.8125C18.0741 4.8125 20.8516 7.59 20.8516 11C20.8516 14.41 18.0741 17.1875 14.6641 17.1875Z'
      fill={props.color || '#7854F6'}
    />
  </svg>
)

const SVGLocLivechat = (props) => (
  <Icon component={() => <SVGIcon {...props} />} {...props} />
)

export default SVGLocLivechat
