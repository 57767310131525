import React from "react";
import Icon from "@ant-design/icons";
import { MainColor } from "../../utils";

const SVGIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="7.436"
    height="12.751"
    viewBox="0 0 7.436 12.751"
  >
    <g
      id="Group_8821"
      dataname="Group 8821"
      transform="translate(6.375 1.061) rotate(90)"
    >
      <line
        id="Line_313"
        dataname="Line 313"
        x2="5.315"
        y2="5.315"
        fill="none"
        stroke={props.color || MainColor}
        strokeLinecap="round"
        strokeWidth="1.5"
      />
      <line
        id="Line_314"
        dataname="Line 314"
        x1="5.315"
        y2="5.315"
        transform="translate(5.315)"
        fill="none"
        stroke={props.color || MainColor}
        strokeLinecap="round"
        strokeWidth="1.5"
      />
    </g>
  </svg>
);

const SVGArrowLeft = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />;
      }}
      {...props}
    />
  );
};

export default SVGArrowLeft;
