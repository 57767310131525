import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width={props.width || '24'}
    height={props.height || '24'}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M3 6H10'
      stroke={props.color || '#77808D'}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M3 12H12'
      stroke={props.color || '#77808D'}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M19 12H21'
      stroke={props.color || '#77808D'}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14 6L21 6'
      stroke={props.color || '#77808D'}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M13 18H20'
      stroke={props.color || '#77808D'}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M3 18H6'
      stroke={props.color || '#77808D'}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <circle
      cx='8'
      cy='18'
      r='2'
      stroke={props.color || '#77808D'}
      strokeWidth='1.5'
    />
    <circle
      cx='17'
      cy='12'
      r='2'
      stroke={props.color || '#77808D'}
      strokeWidth='1.5'
    />
    <circle
      cx='12'
      cy='6'
      r='2'
      stroke={props.color || '#77808D'}
      strokeWidth='1.5'
    />
  </svg>
)

const SVGHamburgerFilterIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGHamburgerFilterIcon
