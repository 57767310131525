import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg width={30} height={30} viewBox='0 0 30 30' {...props}>
      <defs>
        <clipPath id='prefix__a'>
          <path fill='none' d='M0 0h30v30H0z' />
        </clipPath>
      </defs>
      <g data-name='Symbol 9 \u2013 1' clipPath='url(#prefix__a)'>
        <path
          data-name='Union 1'
          d='M0 30v-3.75c0-4.126 6.75-7.5 15-7.5s15 3.375 15 7.5V30zM7.5 7.5A7.5 7.5 0 1115 15a7.5 7.5 0 01-7.5-7.5z'
          fill={props.color ? props.color : '#fff'}
        />
      </g>
    </svg>
  )
}

const SVGChatNoImage = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGChatNoImage
