import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M21.4031 12.9L16.9031 15.2L13.7031 12L16.9031 8.80005L21.4031 11C22.2031 11.4 22.2031 12.6 21.4031 12.9Z'
      stroke='#0E0E0E'
      strokeWidth='1.5'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M16.9 8.79996L13.7 12L4.1 21.6L3.5 21.9C2.8 22.3 2 21.7 2 20.9V3.09996C2 2.29996 2.8 1.79996 3.5 2.09996L16.9 8.79996Z'
      stroke='#0E0E0E'
      strokeWidth='1.5'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M16.9016 15.2L4.10156 21.6L13.7016 12L16.9016 15.2Z'
      stroke='#0E0E0E'
      strokeWidth='1.5'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M13.7016 12L4.10156 2.40002'
      stroke='#0E0E0E'
      strokeWidth='1.5'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

const SVGGooglePlayIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGGooglePlayIcon
