import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.5 6.91699C2.5 4.70786 4.29086 2.91699 6.5 2.91699H13.5C15.7091 2.91699 17.5 4.70785 17.5 6.91699V14.3337C17.5 16.5428 15.7091 18.3337 13.5 18.3337H6.5C4.29086 18.3337 2.5 16.5428 2.5 14.3337V6.91699Z'
        stroke={props.color || '#77808D'}
        strokeWidth='1.5'
      />
      <path
        d='M2.5 7.5H17.5'
        stroke={props.color || '#77808D'}
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M6.66406 1.66699L6.66406 4.16699'
        stroke={props.color || '#77808D'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.3359 1.66699V4.16699'
        stroke={props.color || '#77808D'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <circle
        cx='9.9974'
        cy='12.5003'
        r='0.833333'
        fill={props.color || '#77808D'}
      />
      <ellipse
        cx='13.3333'
        cy='12.5003'
        rx='0.833333'
        ry='0.833333'
        fill={props.color || '#77808D'}
      />
      <ellipse
        cx='6.66927'
        cy='12.5003'
        rx='0.833333'
        ry='0.833333'
        fill={props.color || '#77808D'}
      />
    </svg>
  )
}

const SVGAppointmentDateIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGAppointmentDateIcon
