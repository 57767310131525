import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width='30'
    height='30'
    viewBox='0 0 30 30'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='15' cy='15' r='15' fill='#DAE9FF' />
    <path
      d='M18.6447 15.8667C18.6474 15.8504 18.6496 15.834 18.6513 15.8176H18.9441H19.4806V15.8667H19.4177H18.6447ZM18.0477 16.5052C18.3439 16.4449 18.5449 16.2251 18.6213 15.9686L18.7646 16.6674C18.7937 16.809 18.8192 16.9493 18.8406 17.0889C18.7001 16.7806 18.4096 16.5424 18.0477 16.5052ZM17.9329 19.0048C18.4221 19.0048 18.8351 18.6438 18.9175 18.1764L18.9174 20.5131H18.2795V19.8102V19.1435H17.6129H12.1985H11.5318V19.8102V20.5131H10.894V18.0704C10.928 18.5881 11.3657 19.0048 11.8913 19.0048H12.9793H14.3332L13.8804 18.4162C13.9631 18.2308 13.9924 18.0202 13.9558 17.8128L13.9533 17.7984L13.9501 17.7841L13.8744 17.4434C13.7904 17.0426 13.4704 16.7435 13.073 16.6712L13.073 16.6711L13.0619 16.6692L12.1648 16.5216H17.7366L16.7622 16.682L16.7509 16.6838L16.7397 16.6861C16.3875 16.7565 16.0383 17.0338 15.9498 17.4561L15.8755 17.7903C15.718 18.4493 16.2496 19.0048 16.8449 19.0048H17.9329ZM11.7572 16.5011C11.4062 16.5506 11.102 16.7818 10.9667 17.1102C10.9888 16.9626 11.0155 16.8158 11.046 16.6705L11.1976 15.9501C11.268 16.2088 11.4643 16.4338 11.7572 16.5011ZM11.1728 15.8175C11.1745 15.834 11.1767 15.8503 11.1794 15.8666H10.3936H10.3307V15.8175H10.8801H11.1728Z'
      fill='#0052CC'
      stroke='#0052CC'
      strokeWidth='1.33333'
    />
    <path
      d='M10.417 9.97947H11.4922C11.6586 9.97947 11.7865 10.1075 11.7865 10.2738V10.7603C11.7865 10.9267 11.6585 11.0546 11.4922 11.0546H10.417C10.3146 11.0546 10.2634 11.1571 10.3018 11.2466C10.4298 11.477 10.609 11.669 10.865 11.8226C11.3642 12.117 12.0042 12.0786 12.4778 11.7458C12.6954 11.5922 12.849 11.4002 12.9642 11.1826H16.8426C16.945 11.4002 17.1115 11.5922 17.3291 11.7458C17.8026 12.0786 18.4426 12.1042 18.9418 11.8098C19.1978 11.6563 19.377 11.4642 19.5051 11.2338C19.5562 11.1442 19.4923 11.0419 19.3899 11.0419H18.3147C18.1483 11.0419 18.0203 10.9138 18.0203 10.7475V10.2611C18.0203 10.0947 18.1484 9.9667 18.3147 9.9667H19.3899C19.4923 9.9667 19.5435 9.86429 19.5051 9.77474C19.377 9.54439 19.1978 9.35234 18.9418 9.19876C18.4426 8.90439 17.8026 8.94279 17.3291 9.27555C17.1114 9.44189 16.9578 9.63386 16.8425 9.85144H12.9769C12.8617 9.63385 12.6953 9.44188 12.4777 9.2883C12.0169 8.95546 11.3641 8.92981 10.8777 9.22429C10.6218 9.37786 10.4426 9.56991 10.3145 9.80026C10.2633 9.87705 10.3273 9.97946 10.4169 9.97946L10.417 9.97947Z'
      fill='#0052CC'
    />
  </svg>
)

const SVGPostPurchaseIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGPostPurchaseIcon
