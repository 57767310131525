import React from "react";
import Icon from "@ant-design/icons";

const SVGIcon = (props) => {
  return (
    <svg
      width="240"
      height="146"
      viewBox="0 0 240 146"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M239.258 115.111C239.352 117.982 236.995 118.918 234.938 118.982C232.88 119.046 224.994 117.62 224.927 115.559C224.859 113.498 232.635 111.583 234.693 111.518C236.751 111.454 239.164 112.241 239.258 115.111Z"
        fill="#7AC6DC"
      />
      <path
        d="M217.76 143.011C214.917 146.545 211.281 145.47 208.879 143.628C206.476 141.785 198.99 132.717 201.031 130.18C203.072 127.642 213.868 132.596 216.27 134.438C218.672 136.281 220.603 139.476 217.76 143.011Z"
        fill="#96E764"
      />
      <path
        d="M216.181 1.85832C213.385 -1.10957 210.08 0.215343 207.953 2.12697C205.825 4.03859 199.357 13.0401 201.365 15.171C203.372 17.3019 213.096 11.7552 215.224 9.84354C217.351 7.93192 218.978 4.82622 216.181 1.85832Z"
        fill="#F9CF67"
      />
      <path
        d="M17.4292 50.7538C15.9796 53.0184 18.3857 55.432 20.8373 56.9285C23.2889 58.425 27.8256 59.6137 28.8664 57.9878C29.9072 56.3619 27.0581 52.5371 24.6065 51.0406C22.1549 49.5441 18.8789 48.4892 17.4292 50.7538Z"
        fill="#73D198"
      />
      <path
        d="M30.0395 111.098C27.7116 107.767 29.7807 104.923 32.1689 103.332C34.5571 101.741 39.436 100.94 41.1074 103.332C42.7788 105.724 40.6098 110.402 38.2216 111.993C35.8334 113.585 32.3675 114.43 30.0395 111.098Z"
        fill="#4FFF8B"
      />
      <path
        d="M0.653048 86.8361C-0.759817 84.8142 0.495979 83.0884 1.9454 82.1226C3.39482 81.1568 6.35591 80.6709 7.37032 82.1226C8.38472 83.5742 7.06832 86.4137 5.61889 87.3795C4.16947 88.3453 2.06591 88.858 0.653048 86.8361Z"
        fill="#E7647F"
      />
      <path
        d="M224.907 31.6332C226.896 33.1228 226.255 35.1453 225.188 36.505C224.12 37.8647 221.462 39.2297 220.034 38.1603C218.606 37.0908 218.948 33.9919 220.016 32.6322C221.084 31.2725 222.918 30.1437 224.907 31.6332Z"
        fill="#73D198"
      />
      <path
        d="M36.4461 5.90737C34.9128 8.10148 36.2756 9.97426 37.8485 11.0223C39.4214 12.0704 42.6347 12.5976 43.7355 11.0223C44.8363 9.44704 43.4078 6.36574 41.8349 5.31767C40.262 4.26961 37.9793 3.71326 36.4461 5.90737Z"
        fill="#F9CF67"
      />
    </svg>
  );
};

const SVGVideoStatsHeaderThirdCircles = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />;
      }}
      {...props}
    />
  );
};

export default SVGVideoStatsHeaderThirdCircles;
