import React from "react";
import Icon from "@ant-design/icons";

const SVGIcon = (props) => (
  <svg
    width="42"
    height="43"
    viewBox="0 0 42 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="21" cy="21.5" r="21" fill="#3F8CFF" />
    <path
      d="M16.3322 13.9922C15.8719 13.9922 15.4989 14.3655 15.4989 14.8255C15.4989 15.2855 15.8719 15.6589 16.3322 15.6589V17.3255H13.8322H12.9989C12.5386 17.3255 12.1655 17.6989 12.1655 18.1589C12.1655 18.6189 12.5386 18.9922 12.9989 18.9922V28.1589C12.9989 28.6189 13.3719 28.9922 13.8322 28.9922H17.1655H20.4989C20.9591 28.9922 21.3322 28.6189 21.3322 28.1589V26.4922C21.3322 26.0322 21.7054 25.6589 22.1655 25.6589C22.6258 25.6589 22.9989 26.0322 22.9989 26.4922V28.1589C22.9989 28.6189 23.3719 28.9922 23.8322 28.9922H27.1655C27.6258 28.9922 27.9989 28.6189 27.9989 28.1589V15.6589C28.4591 15.6589 28.8322 15.2855 28.8322 14.8255C28.8322 14.3655 28.4591 13.9922 27.9989 13.9922H16.3322ZM20.4989 17.3255C20.9591 17.3255 21.3322 17.6989 21.3322 18.1589C21.3322 18.6189 20.9591 18.9922 20.4989 18.9922C20.0386 18.9922 19.6655 18.6189 19.6655 18.1589C19.6655 17.6989 20.0386 17.3255 20.4989 17.3255ZM23.8322 17.3255C24.2924 17.3255 24.6655 17.6989 24.6655 18.1589C24.6655 18.6189 24.2924 18.9922 23.8322 18.9922C23.3719 18.9922 22.9989 18.6189 22.9989 18.1589C22.9989 17.6989 23.3719 17.3255 23.8322 17.3255ZM14.6655 18.9922H16.3322V27.3255H14.6655V18.9922ZM20.4989 20.6589C20.9591 20.6589 21.3322 21.0322 21.3322 21.4922C21.3322 21.9522 20.9591 22.3255 20.4989 22.3255C20.0386 22.3255 19.6655 21.9522 19.6655 21.4922C19.6655 21.0322 20.0386 20.6589 20.4989 20.6589ZM23.8322 20.6589C24.2924 20.6589 24.6655 21.0322 24.6655 21.4922C24.6655 21.9522 24.2924 22.3255 23.8322 22.3255C23.3719 22.3255 22.9989 21.9522 22.9989 21.4922C22.9989 21.0322 23.3719 20.6589 23.8322 20.6589Z"
      fill="#F8F9F9"
    />
  </svg>
);

const SVGDepositATBIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />;
      }}
      {...props}
    />
  );
};

export default SVGDepositATBIcon;
