import React from 'react'
import { Pagination, Empty } from 'antd'
import { getText } from '../../lang'
import { TABLE_DEFAULT_ROW_NUMBER } from '../../devOptions'
import LoadingSpinner from '../LoadingSpinner'
import './MatList.scss'

const MatList = (props) => {
  const {
    onShowSizeChange,
    onPaginationChange,
    hideOnSinglePage,
    defaultPageSize,
    paginationTotalCount,
    renderItem,
    data,
    loading,
    paginationCurrentPage,
    pagination,
    grid,
    containerClassName,
    header,
  } = props

  return loading ? (
    <LoadingSpinner />
  ) : data && data.length ? (
    <>
      {grid ? (
        <div className={`grid-container ${containerClassName || ''}`}>
          {data.map((item, index) => {
            return (
              <div key={JSON.stringify(item)} className='grid-item'>
                {renderItem(item, index)}
              </div>
            )
          })}
        </div>
      ) : (
        <div className={`list-container ${containerClassName || ''}`}>
          {header}
          {data.map((item, index) => {
            return (
              <div key={JSON.stringify(item)} className='list-item'>
                {renderItem(item, index)}
              </div>
            )
          })}
        </div>
      )}
      {pagination && (
        <Pagination
          current={paginationCurrentPage + 1}
          total={paginationTotalCount}
          defaultPageSize={defaultPageSize}
          hideOnSinglePage={hideOnSinglePage}
          onChange={(e) => {
            onPaginationChange(e - 1)
          }}
          onShowSizeChange={(e, ee) => {
            onShowSizeChange(ee)
          }}
          responsive
          showLessItems={true}
          showQuickJumper={false}
          showSizeChanger={false}
          showTitle={false}
          showTotal={false}
        />
      )}
    </>
  ) : (
    <Empty
      description={props.emptyDescription || getText('WORD_NO_DATA')}
      image={Empty.PRESENTED_IMAGE_SIMPLE}
    />
  )
}

MatList.defaultProps = {
  data: [],
  loading: false,
  renderItem: (item) => {},
  // Pagination
  hideOnSinglePage: true,
  defaultPageSize: TABLE_DEFAULT_ROW_NUMBER,
  paginationCurrentPage: 1,
  paginationTotalCount: 0,
  pagination: true,
  onPaginationChange: () => {},
  onShowSizeChange: () => {},
}

export default MatList
