import React from 'react'
import { useNavigate } from 'react-router-dom'
import MatButton from '../../../../components/MatButton'
import './OrganizationNewPage.scss'

const OrganizationListTitle = ({
  title,
  url,
  disabled,
  buttonTitle,
  disabledButtonInfo,
  modalButton,
  state,
  subtitle,
  titleNextTo,
}) => {
  const navigate = useNavigate()

  return (
    <div className='organization-new-list-header'>
      <div className='organization-new-list-title-part'>
        <div className='organization-new-list-left-part'></div>
        <div className='title-row'>
          <p>{title}</p> {titleNextTo}
        </div>
        <div className='subtitle'>{subtitle}</div>
      </div>
      <div className='organization-new-list-right-part'>
        {disabled && <div style={{ marginRight: '5px' }}>{disabledButtonInfo}</div>}
        {modalButton || (
          <MatButton
            htmlType={'button'}
            buttonText={buttonTitle}
            onClick={() => {
              navigate(url, { state })
            }}
            typeButton={'radius'}
            disabled={disabled}
          />
        )}
      </div>
    </div>
  )
}

export default OrganizationListTitle
