import { v4 as uuidv4 } from 'uuid';

/*
* Set sessionId for a new window of user
* but it doesn't work for 'duplicate' tab
*/
const setSessionId = () => {
  if (!sessionStorage.getItem('sessionId')) {
    sessionStorage.setItem('sessionId', String(uuidv4()));
  }
};

const getSessionId = () => {
  return sessionStorage.getItem('sessionId')
};

const isSessionIdEqual = (sessionId) => {
  return sessionId === sessionStorage.getItem('sessionId');
};

export {
  setSessionId,
  getSessionId,
  isSessionIdEqual
}
