import React from 'react'
import TreeSelectForm from '../../../../../components/Form/TreeSelectForm'
import { getText } from '../../../../../lang'

const LocationQuestionsOptions = ({
  step,
  index,
  treeData,
  isDataTree,
  handleSelectedLocationChange,
}) => {
  const getDefaultLocationId = (step) => {
    if (step.locationQuestions && step.locationQuestions.locationId) {
      return step.locationQuestions.locationId
    }
  }

  return (
    <div>
      <TreeSelectForm
        loading={isDataTree}
        label={getText(
          'TEXT_WHICH_LOCATION_WOULD_YOU_LIKE_CHOOSE_DISPLAYING_QUESTION'
        )}
        treeData={treeData.list || []}
        treeCheckable={true}
        multipleNewStyle
        placeholder={getText('WORD_SELECT_LOCATIONS')}
        message={getText('TEXT_AT_LEAST_ONE_LOCATION_MUST_BE_SELECTED')}
        onChange={(value) => {
          handleSelectedLocationChange(index, value[0])
        }}
        defaultValue={getDefaultLocationId(step)}
      />
    </div>
  )
}

export default LocationQuestionsOptions
