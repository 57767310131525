import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      viewBox='0 0 100 100'
      width='14px'
      height='14px'
      fill={props.color || '#f8a326'}
    >
      <path d='M17 95c6.6 0 12-5.4 12-12 0-6.2-4.8-11.4-11-12V51h64v20.1c-6.6.5-11.5 6.3-11 13 .5 6.6 6.3 11.5 13 11s11.5-6.3 11-13c-.4-5.1-4-9.4-9-10.6V48.8c0-1-.8-1.8-1.8-1.8H52V28.8c6.5-1.1 10.9-7.3 9.8-13.8C60.7 8.5 54.5 4.1 48 5.2S37.1 12.5 38.2 19c.9 5 4.8 9 9.8 9.8V47H15.8c-1 0-1.8.8-1.8 1.8v22.6C7.6 73 3.7 79.6 5.4 86c1.3 5.3 6.1 9 11.6 9z'></path>
    </svg>
  )
}

const SVGOrganization = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGOrganization
