import React, { memo } from 'react'
import { Tooltip } from 'antd'
import { useSelector } from 'react-redux'
import SVGPhoneCall from '../../../../../icons/SVG/SVGPhoneCall'
import { MainColor } from '../../../../../utils'
import { getText } from '../../../../../lang'
import MatButton from '../../../../../components/MatButton'
import {
  msgTooltipText,
  sendCustomPhone,
  sendPhoneCall,
} from '../../../utils/messageUtils'
import { MESSENGER } from '../../../utils/constants'
import './MessagePhoneCallAction.scss'

const MessageCallPhoneButton = (props) => {
  const { conversation, disabled } = props

  const organization = useSelector((state) => {
    return state.authReducer.user.organization
  })

  const isMobileApp = useSelector((state) => {
    return state.authReducer.isMobileApp
  })

  if (conversation.is_archived) {
    return null
  }

  return props.conversation.kind !== MESSENGER && conversation.receiver_phone ? (
    <Tooltip
      destroyTooltipOnHide={true}
      trigger={['hover', 'click']}
      placement='top'
      title={getText('WORD_PHONE_CALL')}
    >
      <MatButton
        className={'phone_call_button'}
        disabled={disabled || conversation.isActiveVoiceCall}
        onClick={() => {
          if (
            organization.restrictions
              .restrictUserVoiceCallsFromUsingOrganizationNumbers
          ) {
            sendCustomPhone(conversation, isMobileApp)
          } else {
            sendPhoneCall(conversation.receiver_phone, conversation)
          }
        }}
        icon={
          <SVGPhoneCall
            color={
              disabled || conversation.isActiveVoiceCall
                ? 'var(--thridyTextColor)'
                : MainColor
            }
          />
        }
      />
    </Tooltip>
  ) : (
    <Tooltip title={msgTooltipText(conversation)}>
      <span className={'mat-btn phone_call_button'} disabled={true}>
        <SVGPhoneCall color={'var(--thridyTextColor)'} />
      </span>
    </Tooltip>
  )
}

export default memo(MessageCallPhoneButton)
