import React from 'react';
// import SVGBellDisable from './SVGBellDisable';
import SVGBellEnable from './SVGBellEnable';

const SVGMessageNotificationEnableDisable = (props) => {
    return (
        !props.enable ? null : <SVGBellEnable />
    );
};

export default SVGMessageNotificationEnableDisable;