import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='18'
      height='22'
      viewBox='0 0 18 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.63745 1.58296C2.94747 1.58296 1.58296 2.61927 1.58296 4.66552V20.2039C1.58296 20.3031 1.63995 20.3569 1.68744 20.3843C1.73493 20.4139 1.80985 20.435 1.89639 20.3864L8.63136 16.6084C8.87197 16.4744 9.1664 16.4734 9.40807 16.6095L16.085 20.3812C16.1726 20.4318 16.2475 20.4086 16.295 20.3801C16.3425 20.3516 16.3984 20.2978 16.3984 20.1986V4.85864C16.3984 4.04922 16.3984 1.58296 12.3482 1.58296H5.63745ZM1.79297 22C1.48165 22 1.17034 21.9166 0.887514 21.7499C0.331367 21.4259 0 20.8465 0 20.2039V4.66552C0 1.7001 2.05468 0 5.63745 0H12.3482C15.9278 0 17.9814 1.77186 17.9814 4.85864V20.1986C17.9814 20.8423 17.65 21.4217 17.0928 21.7457C16.5377 22.0707 15.8687 22.077 15.3072 21.7604L9.0176 18.2072L2.66993 21.7678C2.39555 21.9219 2.09479 22 1.79297 22Z'
        fill={props.color || 'black'}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.8112 8.6836H5.08848C4.65159 8.6836 4.297 8.32902 4.297 7.89212C4.297 7.45522 4.65159 7.10064 5.08848 7.10064H12.8112C13.2481 7.10064 13.6027 7.45522 13.6027 7.89212C13.6027 8.32902 13.2481 8.6836 12.8112 8.6836Z'
        fill={props.color || 'black'}
      />
    </svg>
  )
}

const SVGOrgTagsIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGOrgTagsIcon
