import React from 'react'
import { getText } from '../../../../../../lang'
import BuildContent from './BuildContent'

const YourOwnContent = (props) => {
  const {
    listVariables,
    textMessage,
    settextMessage,
    onStateChange,
    organization,
    setCurrentCursorPosition,
    addNameInMessenger,
    textAreRef,
    tabValue,
  } = props

  return (
    <>
      <div className='powerfull-text-generated'>
        {getText('WORD_BUILD_YOUR_OWN_BROADCAST')}
      </div>
      <div
        style={{ color: '#0e0e0e', fontWeight: 'bold' }}
        className='powerfull-text-generated'
      >
        {getText('TEXT_CHOOSE_DYNAMIC_CONTENT_TO_BE_APPLIED_TEXT')}
      </div>
      <BuildContent
        listVariables={listVariables}
        textMessage={textMessage}
        settextMessage={settextMessage}
        onStateChange={onStateChange}
        organization={organization}
        setCurrentCursorPosition={setCurrentCursorPosition}
        addNameInMessenger={addNameInMessenger}
        tabValue={tabValue}
        textAreRef={textAreRef}
        showTextarea={true}
      />
    </>
  )
}

export default YourOwnContent
