import React from 'react'
import PageFull from '../../../../../components/PageFull'
import './MatadorConnectComponents.scss'

const MatadorConnectLayout = ({ children, className }) => {
  return (
    <PageFull className={`matador-connect-page-wrapper ${className || ''}`}>
      {children}
    </PageFull>
  )
}

export default MatadorConnectLayout
