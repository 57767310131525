import React from "react";
import Icon from "@ant-design/icons";

const SVGIcon = (props) => {
  return (
    <svg
      width="231"
      height="130"
      viewBox="0 0 231 130"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M230.463 67.6242C231.082 70.4319 228.935 71.7648 226.922 72.1881C224.91 72.6113 216.89 72.5878 216.446 70.5719C216.001 68.5561 223.3 65.3112 225.312 64.888C227.325 64.4647 229.844 64.8165 230.463 67.6242Z"
        fill="#7AC6DC"
      />
      <path
        d="M191.606 109.863C189.458 113.838 185.683 113.416 182.981 112.024C180.28 110.632 171.251 103.02 172.793 100.167C174.336 97.3133 185.865 100.298 188.567 101.691C191.268 103.083 193.754 105.889 191.606 109.863Z"
        fill="#E7647F"
      />
      <path
        d="M183.455 1.85832C180.658 -1.10957 177.354 0.215344 175.226 2.12697C173.098 4.03859 166.63 13.0401 168.638 15.171C170.646 17.3019 180.369 11.7552 182.497 9.84354C184.625 7.93192 186.251 4.82622 183.455 1.85832Z"
        fill="#F9CF67"
      />
      <path
        d="M1.09328 36.7538C-0.356373 39.0184 2.04976 41.432 4.50137 42.9285C6.95298 44.425 11.4896 45.6137 12.5305 43.9878C13.5713 42.3619 10.7221 38.5371 8.27053 37.0406C5.81893 35.5441 2.54293 34.4892 1.09328 36.7538Z"
        fill="#73D198"
      />
      <path
        d="M18.0239 126.831C15.696 123.499 17.7651 120.656 20.1533 119.064C22.5414 117.473 27.4203 116.672 29.0917 119.064C30.7631 121.456 28.5941 126.135 26.206 127.726C23.8178 129.317 20.3518 130.162 18.0239 126.831Z"
        fill="#F39970"
      />
      <path
        d="M18.8327 87.1798C17.4199 85.1579 18.6757 83.4321 20.1251 82.4663C21.5745 81.5005 24.5356 81.0147 25.55 82.4663C26.5644 83.918 25.248 86.7574 23.7986 87.7232C22.3492 88.689 20.2456 89.2017 18.8327 87.1798Z"
        fill="#FFAEBE"
      />
      <path
        d="M208.571 37.6332C210.56 39.1228 209.919 41.1453 208.852 42.505C207.784 43.8647 205.127 45.2297 203.698 44.1603C202.27 43.0908 202.612 39.9919 203.68 38.6322C204.748 37.2725 206.582 36.1437 208.571 37.6332Z"
        fill="#73D198"
      />
      <path
        d="M39.9929 16.8527C38.4597 19.0468 39.8225 20.9196 41.3954 21.9676C42.9682 23.0157 46.1815 23.5429 47.2823 21.9676C48.3832 20.3923 46.9546 17.3111 45.3817 16.263C43.8089 15.2149 41.5261 14.6586 39.9929 16.8527Z"
        fill="#F9CF67"
      />
    </svg>
  );
};

const SVGVideoStatsHeaderFirstCircles = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />;
      }}
      {...props}
    />
  );
};

export default SVGVideoStatsHeaderFirstCircles;
