import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width='23'
    height='21'
    viewBox='0 0 23 21'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      opacity='0.4'
      d='M23 14.9817C23 18.1902 20.424 20.7892 17.2155 20.8007H17.204H5.8075C2.6105 20.8007 0 18.2132 0 15.0047V14.9932C0 14.9932 0.0069 9.90328 0.0161 7.34338C0.01725 6.86268 0.56925 6.59358 0.9453 6.89258C3.6777 9.06033 8.56405 13.0129 8.625 13.0646C9.4415 13.719 10.4765 14.0881 11.5345 14.0881C12.5925 14.0881 13.6275 13.719 14.444 13.052C14.505 13.0117 19.2821 9.17763 22.0559 6.97423C22.4331 6.67408 22.9873 6.94318 22.9885 7.42273C23 9.96308 23 14.9817 23 14.9817Z'
      fill='#5B69E2'
    />
    <path
      d='M22.3975 3.17509C21.4016 1.29829 19.442 0.0999908 17.2846 0.0999908H5.80761C3.65021 0.0999908 1.69061 1.29829 0.69471 3.17509C0.47161 3.59484 0.57741 4.11809 0.94886 4.41479L9.48761 11.2446C10.0856 11.7276 10.8101 11.968 11.5346 11.968C11.5392 11.968 11.5427 11.968 11.5461 11.968C11.5496 11.968 11.5542 11.968 11.5576 11.968C12.2821 11.968 13.0066 11.7276 13.6046 11.2446L22.1434 4.41479C22.5148 4.11809 22.6206 3.59484 22.3975 3.17509Z'
      fill='#5B69E2'
    />
  </svg>
)

const SVGIntegrationEmail = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGIntegrationEmail
