import { fetchFromUrlPUTAsync, fetchFromUrlGETAsync } from '../../utils/UrlHelper';

const urlAppearance = "/chat-settings/";
const urlAppearanceTriggers = "/triggers";

const fs = {

    save: async (obj, id) => {
        const urlParams = Object.keys(obj).map(key => { return { key, value: obj[key] } });
        const result = await fetchFromUrlPUTAsync(`${urlAppearance}/${id}/appearance`, urlParams);
        return result;
    },

    getAppearance: async (ln, token) => {
        const urlParams = [
            { key: "language", value: ln }
        ];
        const result = await fetchFromUrlGETAsync(`${urlAppearanceTriggers}/${token}`, urlParams);
        return result;
    },
};


const loc = {};


const appearanceAction = Object.assign(fs, loc);

export default appearanceAction;