import React, { useEffect, useState, useRef } from 'react'
import { Form } from 'antd/es'
import { getText } from '../../../../lang'
import { getLongName } from '../../../../utils'
import InputFormTextAreaSecondary from '../../../../components/Form/InputFormTextArea/InputFormTextAreaSecondary'
import InputFormTextSecondary from '../../../../components/Form/InputFormText/InputFormTextSecondary'
import SVGOrgSMSIcon from '../../../../icons/SVG/SVGOrgSMSIcon'
import SVGOrgEmailIcon from '../../../../icons/SVG/SVGOrgEmailIcon'
import RadioFormButtonSecondary from '../../../../components/Form/RadioFormButton/RadioFormButtonSecondary'
import MatRadioToggle from '../../../../components/MatRadioToggle'
import MatBlockWithLabel from '../../../../components/MatBlockWithLabel'
import AddVariableInMessage from '../../../../components/AddVariableInMessage'
import { EMAIL, NIGHT, SMS } from '../../../../devOptions'
import MinimumTimeBetweenMessage from './MinimumTimeBetweenMessage'
import AutoReplyDelay from './AutoReplyDelay'

const AutoReplyPanel = (props) => {
  const {
    selectedAiBotMessage,
    sequenceData,
    languages,
    triggeredOn,
    selectedIndex,
    form,
    field,
    type,
  } = props

  const [selectedLangTab, setSelectedLangTab] = useState(languages[0])
  const [currentCursorPosition, setCurrentCursorPosition] = useState(0)
  const [communicationMethod, setCommunicationMethod] = useState('')
  const textAreaInputRef = useRef()
  const subjectInputRef = useRef()

  const getSequenceList = Form.useWatch('auto_reply_messages', form)

  useEffect(() => {
    setSelectedLangTab(languages[0])
  }, [])

  useEffect(() => {
    setCommunicationMethod(selectedAiBotMessage.send_via)
  }, [selectedAiBotMessage.id])

  useEffect(() => {
    const messageContent = selectedAiBotMessage
      ? selectedAiBotMessage.text[selectedLangTab] ||
        (selectedAiBotMessage.subject &&
          selectedAiBotMessage.subject[selectedLangTab])
      : ''
    const messageLength = messageContent ? messageContent.length : 0
    setCurrentCursorPosition(messageLength)
  }, [selectedAiBotMessage.id, selectedLangTab])

  const handleClickVariable = (variable, isSubject = false) => {
    const autoMessagesList = form.getFieldValue('auto_reply_messages')
    const fieldKey = isSubject ? 'subject' : 'text'
    const currentText = autoMessagesList[selectedIndex][fieldKey][selectedLangTab]
    const newText = [
      currentText && currentText.slice(0, currentCursorPosition),
      variable,
      currentText && currentText.slice(currentCursorPosition),
    ].join('')

    autoMessagesList[selectedIndex][fieldKey][selectedLangTab] = newText
    form.setFieldsValue({ auto_reply_messages: autoMessagesList })
    setCurrentCursorPosition(currentCursorPosition + variable.length)

    const inputRef = isSubject ? subjectInputRef : textAreaInputRef
    inputRef.current &&
      inputRef.current.autoFocus(currentCursorPosition + variable.length)
  }

  const radioOptions = [
    {
      label: getText('WORD_SMS_UC'),
      value: SMS,
      icon: (
        <SVGOrgSMSIcon
          width={14}
          height={14}
          color={communicationMethod === SMS ? '#FFFFFF' : '#77808d'}
        />
      ),
      disabled:
        (sequenceData?.type === NIGHT || type === NIGHT) && selectedIndex === 1,
    },
    {
      label: getText('WORD_EMAIL'),
      value: EMAIL,
      icon: (
        <SVGOrgEmailIcon
          width={14}
          height={14}
          color={communicationMethod === EMAIL ? '#FFFFFF' : '#77808d'}
        />
      ),
      disabled:
        (sequenceData?.type === NIGHT || type === NIGHT) && selectedIndex === 1,
    },
  ]

  const listVariables = [
    {
      label: 'customer_first_name',
      value: ' {{customer_first_name}} ',
    },
    {
      label: 'customer_last_name',
      value: ' {{customer_last_name}} ',
    },
    {
      label: 'organization_name',
      value: ' {{organization_name}} ',
    },
    {
      label: 'location_name',
      value: ' {{location_name}} ',
    },
    {
      label: 'touchpoint',
      value: ' {{touchpoint}} ',
    },
    {
      label: 'year',
      value: ' {{year}} ',
    },
    {
      label: 'make',
      value: ' {{make}} ',
    },
    {
      label: 'model',
      value: ' {{model}} ',
    },
    {
      label: 'VIN',
      value: ' {{VIN}} ',
    },
    {
      label: 'trade_year',
      value: ' {{trade_year}} ',
    },
    {
      label: 'trade_make',
      value: ' {{trade_make}} ',
    },
    {
      label: 'trade_model',
      value: ' {{trade_model}} ',
    },
    {
      label: 'trade_VIN',
      value: ' {{trade_VIN}} ',
    },
    {
      label: 'offer_amount',
      value: ' {{offer_amount}} ',
    },
    {
      label: 'price_estimate',
      value: ' {{price_estimate}} ',
    },
  ]

  const langOptions = languages.map((lang) => {
    return {
      label: getLongName(lang),
      value: lang,
    }
  })

  return (
    <div className='aibot-reply-item-panel-wrapper'>
      <RadioFormButtonSecondary
        name={[field, 'send_via']}
        radioOptions={radioOptions}
        size='large'
        label={getText('WORD_TYPE_OF_MESSAGE')}
        onChange={(e) => {
          if (
            getSequenceList?.length === 2 &&
            (sequenceData?.type === NIGHT || type === NIGHT) &&
            selectedIndex === 0
          ) {
            form.setFieldValue(
              ['auto_reply_messages', 1, 'send_via'],
              e.target.value === SMS ? EMAIL : SMS
            )
          }
          setCommunicationMethod(e.target.value)
        }}
      />
      <MatBlockWithLabel black label={getText('TEXT_SEND_FOLLOWUP')}>
        <AutoReplyDelay
          field={field}
          form={form}
          triggeredOn={triggeredOn}
          sequenceData={sequenceData}
          selectedMessage={selectedAiBotMessage}
          selectedIndex={selectedIndex}
          type={type}
        />
        {triggeredOn === 'activity' && selectedAiBotMessage.isFirstItem && (
          <MinimumTimeBetweenMessage
            field={field}
            selectedMessage={selectedAiBotMessage}
          />
        )}
      </MatBlockWithLabel>
      <MatBlockWithLabel
        labelClassName={'aibot-mat-block-label-wrapper'}
        black
        label={
          communicationMethod === EMAIL
            ? getText('WORD_EMAIL')
            : getText('WORD_SMS_UC')
        }
        icon={
          <MatRadioToggle
            size='large'
            className='language-switch'
            value={selectedLangTab}
            onChange={(lang) => setSelectedLangTab(lang)}
            radioOptions={langOptions}
          />
        }
      >
        {communicationMethod === EMAIL && (
          <>
            <InputFormTextSecondary
              placeholder={getText('WORD_SUBJECT')}
              formStyle={{ marginBottom: '10px' }}
              initialValue={
                selectedAiBotMessage.subject
                  ? {
                      [selectedLangTab]: selectedAiBotMessage.subject
                        ? selectedAiBotMessage.subject[selectedLangTab]
                        : '',
                    }
                  : ''
              }
              name={[field, 'subject', selectedLangTab]}
              onClick={(e) => setCurrentCursorPosition(e.target.selectionStart)}
              onKeyUp={(e) => setCurrentCursorPosition(e.target.selectionStart)}
              onChange={(e) => setCurrentCursorPosition(e.target.selectionStart)}
            />
            <AddVariableInMessage
              black
              onClick={(variable) => handleClickVariable(variable, true)}
              data={listVariables}
              formStyle={'marginBottom: 10px'}
            />
          </>
        )}
        <InputFormTextAreaSecondary
          ref={textAreaInputRef}
          name={[field, 'text', selectedLangTab]}
          initialValue={{
            [selectedLangTab]: selectedAiBotMessage.text[selectedLangTab],
          }}
          autoSize={false}
          formClassName='with-variables'
          placeholder={getText('WORD_MESSAGE')}
          allowClear={false}
          onClick={(e) => setCurrentCursorPosition(e.target.selectionStart)}
          onKeyUp={(e) => setCurrentCursorPosition(e.target.selectionStart)}
          onChange={(e) => setCurrentCursorPosition(e.target.selectionStart)}
          message={`${getLongName(selectedLangTab)} field is required.`}
        />
      </MatBlockWithLabel>
      <AddVariableInMessage
        black
        onClick={(variable) => handleClickVariable(variable)}
        data={listVariables}
      />
    </div>
  )
}

export default AutoReplyPanel
