import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='30'
      height='30'
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle
        opacity='0.15'
        cx='15'
        cy='15'
        r='15'
        fill={props.color || '#EE7B6C'}
      />
      <path
        d='M15 6C10.0291 6 6 10.0292 6 15C6 19.9708 10.0292 24 15 24C19.9708 24 24 19.9708 24 15C24 10.0292 19.9708 6 15 6ZM17.7732 11.5203C18.3749 11.5203 18.872 12.0175 18.872 12.6192C18.872 13.2209 18.3749 13.718 17.7732 13.718C17.1715 13.718 16.6744 13.2209 16.6744 12.6192C16.6744 12.0175 17.1715 11.5203 17.7732 11.5203ZM12.4883 11.5203C13.09 11.5203 13.5872 12.0175 13.5872 12.6192C13.5872 13.2209 13.09 13.718 12.4883 13.718C11.8866 13.718 11.3895 13.2209 11.3895 12.6192C11.3895 12.0175 11.8866 11.5203 12.4883 11.5203ZM18.7936 18.9244C18.6103 19.029 18.3749 18.9768 18.244 18.7936C17.5376 17.6687 16.308 17.0146 14.9998 17.0146C13.6656 17.0146 12.4359 17.6948 11.7295 18.8459C11.651 18.9505 11.5201 19.0292 11.3893 19.0292C11.3108 19.0292 11.2584 19.0031 11.18 18.9768C10.9967 18.8722 10.9445 18.6105 11.0491 18.4273C11.9125 17.0407 13.3776 16.2297 14.9997 16.2297C16.5956 16.2297 18.0607 17.0407 18.9241 18.3751C19.029 18.5581 18.9768 18.7936 18.7936 18.9244H18.7936Z'
        fill={props.color || '#EE7B6C'}
      />
    </svg>
  )
}

const SVGNPSScoreBadIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGNPSScoreBadIcon
