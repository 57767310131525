import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M19.0962 19.16C19.6862 18.26 19.9062 17.8 20.3562 16.79C17.0362 15.53 16.5062 10.8 19.7862 8.98999C18.7862 7.72999 17.3762 7 16.0462 7C15.0862 7 14.4262 7.25001 13.8362 7.48001C13.3362 7.67001 12.8862 7.84 12.3262 7.84C11.7262 7.84 11.1962 7.65001 10.6362 7.45001C10.0262 7.23001 9.38616 7 8.58616 7C7.09616 7 5.50616 7.91 4.49616 9.47C3.07616 11.67 3.32616 15.79 5.61616 19.31C6.43616 20.57 7.53616 21.98 8.96616 22C9.56616 22.01 9.95616 21.83 10.3862 21.64C10.8762 21.42 11.4062 21.18 12.3362 21.18C13.2662 21.17 13.7862 21.42 14.2762 21.64C14.6962 21.83 15.0762 22.01 15.6662 22C17.1162 21.98 18.2762 20.42 19.0962 19.16Z'
      fill='#0E0E0E'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.8365 2C15.9965 3.1 15.5465 4.19001 14.9565 4.95001C14.3265 5.77001 13.2265 6.41 12.1665 6.37C11.9765 5.31 12.4665 4.21999 13.0665 3.48999C13.7365 2.68999 14.8665 2.07 15.8365 2Z'
      fill='#0E0E0E'
    />
  </svg>
)

const SVGAppleIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGAppleIcon
