import React from 'react'
import SVGArrowLeftIcon from '../../../../../icons/SVG/SVGArrowLeftIcon'
import SVGArrowRightIcon from '../../../../../icons/SVG/SVGArrowRightIcon'
import './SampleArrows.scss'

export function SampleNextArrow({ className, onClick, ...props }) {
  return (
    <div {...props} className={className} onClick={onClick}>
      <SVGArrowLeftIcon width={16} height={14} />
    </div>
  )
}

export function SamplePrevArrow({ className, onClick, ...props }) {
  return (
    <div {...props} className={className} onClick={onClick}>
      <SVGArrowRightIcon width={16} height={14} />
    </div>
  )
}
