import React, { memo } from 'react'
import { getText, getTextServerError } from '../../../../../lang'
import InputTextarea from '../../../../../components/InputTextarea'
import InputMentionSecondary from '../../../../../components/InputMentions/InputMentionSecondary'
import { listVariables } from '../../../../../components/AddVariableInMessage/ListVariables'
import messageActions from '../../../../../store/modules/messageActions'
import { notifyError } from '../../../../../utils/Notify'

const SendEmailInput = (props) => {
  const {
    emailRef,
    sendingMsg,
    emailTemplatesList,
    handleSendMsg,
    setIsTemplate,
    newEmailSubject,
    setshowEmojiModal,
    setnewEmailSubject,
    newMessageValue,
    setnewMessageValue,
    isCustomerRedStatus,
    setSelectedUserTagList,
    userAdditionalConfigsBtn,
    setcurrentCursorPosition,
    setcurrentCursorPositionSubject,
    conversation,
  } = props

  if (isCustomerRedStatus) {
    return (
      <InputTextarea
        disabled={isCustomerRedStatus}
        placeholder={getText('TEXT_THE_CLIENT_HAS_NOT_GIVEN_EMAIL_CONSENT')}
      />
    )
  }

  const replaceDynamicTagsSubject = (
    changedText,
    changedSubject,
    selectedTemplate,
    newPlainTextValue
  ) => {
    setnewMessageValue({
      inputMessageForTags: changedText,
      inputMessageForSending: changedText,
    })
    setnewEmailSubject({
      newValue: newPlainTextValue.replace(selectedTemplate.name, changedSubject),
      newPlainTextValue: newPlainTextValue.replace(
        selectedTemplate.name,
        changedSubject
      ),
    })
  }

  const replaceDynamicTagsText = (
    changedText,
    changedSubject,
    selectedTemplate,
    newPlainTextValue
  ) => {
    setnewMessageValue({
      inputMessageForTags: newPlainTextValue.replace(
        selectedTemplate.name,
        changedText
      ),
      inputMessageForSending: newPlainTextValue.replace(
        selectedTemplate.name,
        changedText
      ),
    })
    setnewEmailSubject({
      newValue: changedSubject,
      newPlainTextValue: changedSubject,
    })
  }

  return (
    <div className={'mat-message-email-input-wrapper'}>
      <InputMentionSecondary
        placeholder={getText('WORD_SUBJECT')}
        singleLine={true}
        value={newEmailSubject.newValue}
        ref={emailRef}
        onChange={async (newValue, newPlainTextValue, mentions) => {
          let selectedTemplate
          setSelectedUserTagList((oldList) => {
            const newArray = oldList.filter((item1) => {
              return mentions.some((item2) => item1.name === item2.id)
            })
            return newArray
          })
          if (mentions.length) {
            selectedTemplate = emailTemplatesList.find((item1) =>
              mentions.some((item2) => item1.display === item2.display)
            )
          }
          if (!sendingMsg && !selectedTemplate) {
            setnewEmailSubject({
              newValue: newValue,
              newPlainTextValue: newPlainTextValue,
            })
            setshowEmojiModal(false)
            setcurrentCursorPositionSubject &&
              setcurrentCursorPositionSubject(newValue.length)
          } else if (Boolean(selectedTemplate)) {
            setIsTemplate(true)
            if (conversation.isNew) {
              replaceDynamicTagsSubject(
                newMessageValue.inputMessageForSending +
                  ` ${selectedTemplate.replacementItem()}`,
                selectedTemplate.replacementSubject(),
                selectedTemplate,
                newPlainTextValue
              )
            } else {
              const result = await messageActions.messagePreviewDynamicTags(
                newMessageValue.inputMessageForSending +
                  ` ${selectedTemplate.getValue()}`,
                conversation._id,
                selectedTemplate.getSubject()
              )
              if (result.success) {
                replaceDynamicTagsSubject(
                  result.data.message,
                  result.data.subject,
                  selectedTemplate,
                  newPlainTextValue
                )
              } else {
                notifyError(getTextServerError(result.errMsg))
              }
            }
          }
        }}
        onKeyDown={(e) => {
          setcurrentCursorPositionSubject &&
            setcurrentCursorPositionSubject(e.target.selectionStart)
        }}
        onClick={(e) => {
          setcurrentCursorPositionSubject &&
            setcurrentCursorPositionSubject(e.target.selectionStart)
        }}
        dynamicTagsList={listVariables()}
        templateList={emailTemplatesList}
      />
      <InputMentionSecondary
        placeholder={getText('TEXT_TYPE_EMAIL_BODY')}
        autoSize={{ minRows: 2, maxRows: 2 }}
        value={newMessageValue.inputMessageForSending}
        onChange={async (newValue, newPlainTextValue, mentions) => {
          let selectedTemplate
          setSelectedUserTagList((oldList) => {
            const newArray = oldList.filter((item1) => {
              return mentions.some((item2) => item1.name === item2.id)
            })
            return newArray
          })
          if (mentions.length) {
            selectedTemplate = emailTemplatesList.find((item1) =>
              mentions.some((item2) => item1.display === item2.display)
            )
          }
          if (!sendingMsg && !selectedTemplate) {
            setnewMessageValue({
              inputMessageForTags: newValue,
              inputMessageForSending: newPlainTextValue,
            })
            setcurrentCursorPosition && setcurrentCursorPosition(newValue.length)
          } else if (Boolean(selectedTemplate)) {
            setIsTemplate(true)
            if (conversation.isNew) {
              replaceDynamicTagsText(
                selectedTemplate.replacementItem(),
                newEmailSubject.newValue +
                  ` ${selectedTemplate.replacementSubject()}`,
                selectedTemplate,
                newPlainTextValue
              )
            } else {
              const result = await messageActions.messagePreviewDynamicTags(
                selectedTemplate.getValue(),
                conversation._id,
                newEmailSubject.newValue + ` ${selectedTemplate.getSubject()}`
              )
              if (result.success) {
                replaceDynamicTagsText(
                  result.data.message,
                  result.data.subject,
                  selectedTemplate,
                  newPlainTextValue
                )
              } else {
                notifyError(getTextServerError(result.errMsg))
              }
            }
          }
        }}
        onSelect={async (prefix, id, name) => {
          if (prefix === '@') {
            setSelectedUserTagList((oldList) => {
              oldList.push({ id: id, name: name, _id: id })
              return [...oldList]
            })
          }
        }}
        onKeyDown={(e) => {
          if (userAdditionalConfigsBtn) {
            if (!sendingMsg && !e.shiftKey && e.key === 'Enter') {
              handleSendMsg()
            }
          } else {
            if (!sendingMsg && e.shiftKey && e.key === 'Enter') {
              handleSendMsg()
            }
          }
          setcurrentCursorPosition &&
            setcurrentCursorPosition(e.target.selectionStart)
        }}
        onClick={(e) => {
          if (!sendingMsg) {
            setshowEmojiModal(false)
          }
          setcurrentCursorPosition &&
            setcurrentCursorPosition(e.target.selectionStart)
        }}
        dynamicTagsList={listVariables()}
        templateList={emailTemplatesList}
      />
    </div>
  )
}

export default memo(SendEmailInput)
