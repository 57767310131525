import React from 'react'
import Icon from '@ant-design/icons'
import { MainColor } from '../../utils'

const SVGIcon = (props) => (
  <svg
    width={props.width || '16'}
    height={props.height || '16'}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M5.33301 1.3335V3.3335'
      stroke={props.color || MainColor}
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.667 1.3335V3.3335'
      stroke={props.color || MainColor}
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M2.33301 6.06006H13.6663'
      stroke={props.color || MainColor}
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.807 10.5134L10.447 12.8734C10.3537 12.9667 10.267 13.14 10.247 13.2667L10.1203 14.1667C10.0737 14.4934 10.3004 14.72 10.627 14.6734L11.527 14.5467C11.6537 14.5267 11.8337 14.44 11.9204 14.3467L14.2804 11.9867C14.687 11.58 14.8804 11.1067 14.2804 10.5067C13.687 9.91337 13.2137 10.1067 12.807 10.5134Z'
      stroke={props.color || MainColor}
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.4668 10.8535C12.6668 11.5735 13.2268 12.1335 13.9468 12.3335'
      stroke={props.color || MainColor}
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8 14.6668H5.33333C3 14.6668 2 13.3335 2 11.3335V5.66683C2 3.66683 3 2.3335 5.33333 2.3335H10.6667C13 2.3335 14 3.66683 14 5.66683V8.00016'
      stroke={props.color || MainColor}
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7.99666 9.13314H8.00265'
      stroke={props.color || MainColor}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.52987 9.13314H5.53585'
      stroke={props.color || MainColor}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.52987 11.1331H5.53585'
      stroke={props.color || MainColor}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

const SVGMessageAppointmentUpdatedIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGMessageAppointmentUpdatedIcon
