import React from "react";
import Icon from "@ant-design/icons";

const SVGIcon = (props) => {
  return (
    <svg
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="60"
        cy="60"
        r="55"
        fill="#E4EFFF"
        stroke="white"
        strokeWidth="10"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M78.3307 51.292H66.3357L73.2107 41.667H74.3307C76.5399 41.667 78.3307 43.4579 78.3307 45.667V51.292ZM41.6641 54.042H78.3307V74.3336C78.3307 76.5428 76.5399 78.3337 74.3307 78.3337H45.6641C43.4549 78.3337 41.6641 76.5428 41.6641 74.3337V54.042ZM48.2897 51.292H41.6641V45.667C41.6641 43.4579 43.4549 41.667 45.6641 41.667H55.1647L48.2897 51.292ZM51.6692 51.292L58.5442 41.667H69.8312L62.9562 51.292H51.6692ZM56.3307 60.9698V70.0309C56.3307 71.2289 57.6659 71.9435 58.6628 71.279L65.4586 66.7484C66.3492 66.1547 66.3492 64.846 65.4586 64.2522L58.6628 59.7217C57.6659 59.0571 56.3307 59.7717 56.3307 60.9698Z"
        fill="url(#paint0_linear_21058_207821)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_21058_207821"
          x1="47.093"
          y1="43.4767"
          x2="79.8496"
          y2="71.856"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5A9CFF" />
          <stop offset="1" stopColor="#284DCF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const SVGVideoStatsHeaderVideoCircle = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />;
      }}
      {...props}
    />
  );
};

export default SVGVideoStatsHeaderVideoCircle;
