import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='337'
      height='122'
      viewBox='0 0 337 122'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M55.0039 34.3176C56.3051 34.1086 57.6398 34 58.9999 34C72.8071 34 83.9999 45.1929 83.9999 59C83.9999 72.8071 72.8071 84 58.9999 84C57.6398 84 56.3051 83.8914 55.0039 83.6824C55.0297 90.5106 55.2273 94.2873 56.7439 97.2638C58.2779 100.274 60.7256 102.722 63.7362 104.256C67.1588 106 71.6392 106 80.6 106H311.4C320.361 106 324.841 106 328.264 104.256C331.274 102.722 333.722 100.274 335.256 97.2638C337 93.8413 337 89.3608 337 80.4V37.6C337 28.6392 337 24.1587 335.256 20.7362C333.722 17.7256 331.274 15.2779 328.264 13.7439C324.841 12 320.361 12 311.4 12H80.6C71.6392 12 67.1588 12 63.7362 13.7439C60.7256 15.2779 58.2779 17.7256 56.7439 20.7362C55.2273 23.7127 55.0297 27.4894 55.0039 34.3176Z'
        fill='white'
      />
      <path
        d='M272.481 45.7395L265.373 49.8453L265.371 41.6362L272.481 45.7395Z'
        fill='#7854F6'
      />
      <circle
        cx='220.144'
        cy='42.0941'
        r='3'
        transform='rotate(121.748 220.144 42.0941)'
        fill='#FABC05'
      />
      <circle
        cx='276.755'
        cy='82.7533'
        r='2'
        transform='rotate(121.748 276.755 82.7533)'
        fill='#F7064E'
      />
      <g filter='url(#filter0_d_12860_26762)'>
        <circle cx='59' cy='59' r='21' fill='white' />
      </g>
      <path
        d='M68.414 66.8281C53.2668 66.8281 53.379 66.8281 49.5859 66.8281C49.2621 66.8281 49 67.0902 49 67.4141C49 67.7379 49.2621 68 49.5859 68H68.414C68.7378 68 68.9999 67.7379 68.9999 67.4141C68.9999 67.0902 68.7378 66.8281 68.414 66.8281Z'
        fill='#48AF80'
      />
      <path
        d='M54.3515 60.9687H50.7578C50.4339 60.9687 50.1719 61.2308 50.1719 61.5547V65.6562H54.9375V61.5547C54.9375 61.2308 54.6754 60.9687 54.3515 60.9687Z'
        fill='#48AF80'
      />
      <path
        d='M60.2109 57.4531H56.6952C56.3714 57.4531 56.1093 57.7152 56.1093 58.039V65.6562H60.7968V58.039C60.7968 57.7152 60.5347 57.4531 60.2109 57.4531Z'
        fill='#48AF80'
      />
      <path
        d='M68.8282 52.8591L64.7267 48.1717C64.4978 47.9428 64.127 47.9428 63.8981 48.1717L59.7966 52.8591C59.6289 53.0268 59.5786 53.2785 59.6695 53.4977C59.7599 53.7169 59.9739 53.8594 60.2108 53.8594H61.9686V65.6562H66.6561V53.8594H68.414C68.6508 53.8594 68.8649 53.7169 68.9552 53.4977C69.0462 53.2786 68.9959 53.0268 68.8282 52.8591Z'
        fill='#48AF80'
      />
      <defs>
        <filter
          id='filter0_d_12860_26762'
          x='0'
          y='0'
          width='122'
          height='122'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='2' dy='2' />
          <feGaussianBlur stdDeviation='20' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.32549 0 0 0 0 0.333333 0 0 0 0 0.501961 0 0 0 0.1 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_12860_26762'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_12860_26762'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  )
}

export const SVGIntentBased = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}
