import React, { useCallback, useEffect, useState } from 'react'
import { debounce } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import locationActions from '../../../store/modules/locationActions'
import { getText } from '../../../lang'
import SVGSearch from '../../../icons/SVG/SVGSearch'
import { TABLE_DEFAULT_ROW_NUMBER } from '../../../devOptions'
import MatButton from '../../../components/MatButton'
import PageFull from '../../../components/PageFull'
import MatList from '../../../components/MatList'
import MatRow from '../../../components/MatRow'
import InputText from '../../../components/InputText'
import MatSelect from '../../../components/MatSelect'
import LocationListItem from './LocationListItem'
import './LocationList.scss'

const LocationList = (props) => {
  const { organization } = props
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [locationsData, setLocationsData] = useState({ list: [], total: 0 })
  const [page, setPage] = useState(0)
  const [selectedStatus, setSelectedStatus] = useState(true)
  const [searchTerm, setSearchTerm] = useState('')
  const [search, setSearch] = useState('')

  const user = useSelector((state) => {
    return state.authReducer.user
  })

  useEffect(() => {
    props.setPageTitle(getText('TITLE_LOCATION_LIST'))
  }, [])

  const getLocations = async () => {
    setIsLoading(true)
    const result = await locationActions.getPageList(
      page,
      TABLE_DEFAULT_ROW_NUMBER,
      search,
      {},
      selectedStatus
    )
    if (result.success) {
      setLocationsData({
        list: result.data,
        total: result.max,
      })
    }
    setIsLoading(false)
  }

  useEffect(() => {
    getLocations()
  }, [page, selectedStatus, search, organization._id])

  const handleCreate = () => {
    navigate('/location/settings/new')
  }

  const handleChangeStatusFilter = (value) => {
    const fill = async () => {
      if (value === 'all') {
        setSelectedStatus('all')
      } else {
        setSelectedStatus(value)
      }
    }
    fill()
  }

  const debouncedChange = useCallback(
    debounce((options) => setSearch(options), 1000),
    []
  )

  return (
    <PageFull className='location-list-wrapper'>
      <MatRow className='location-top-header'>
        <div className='d-flex'>
          {user.isSuperAdmin && user.isAdmin && (
            <MatButton
              style={{ minWidth: '165px' }}
              onClick={handleCreate}
              buttonText={getText('ACTION_CREATE_LOCATION')}
            />
          )}
          <div className='loc-header-select'>
            <MatSelect
              value={selectedStatus}
              onSelectItem={(options) => handleChangeStatusFilter(options.value)}
              options={[
                { label: getText('WORD_ALL'), value: 'all' },
                { label: getText('WORD_ACTIVE'), value: true },
                { label: getText('WORD_INACTIVE'), value: false },
              ]}
            />
          </div>
        </div>
        <div className='inputWithSearch'>
          <SVGSearch />
          <InputText
            className='inputSearch'
            name='searchKeyword'
            placeholder={getText('TEXT_LOCATIONS_SEARCH')}
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value)
              debouncedChange(e.target.value)
            }}
          />
        </div>
      </MatRow>
      <MatList
        grid
        loading={isLoading}
        data={locationsData.list}
        pagination
        paginationCurrentPage={page}
        paginationTotalCount={locationsData.total}
        onPaginationChange={setPage}
        containerClassName=''
        renderItem={(location) => {
          return (
            <LocationListItem
              item={location}
              key={location._id}
              languages={organization.languages}
              allItems={locationsData.list}
            />
          )
        }}
      />
    </PageFull>
  )
}

export default LocationList
