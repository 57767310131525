import { fetchFromUrlGETAsync, fetchFromUrlPUTAsync, fetchFromUrlPOSTAsync } from '../../utils/UrlHelper';

const urlChatSettings = "/chat-settings";

const fs = {
    getSingle: async (itemId) => {
        const result = await fetchFromUrlGETAsync(urlChatSettings + "/" + itemId, []);
        return result;
    },
    insert: async (obj, itemId) => {
        const urlParams = Object.keys(obj).map(key => { return { key, value: obj[key] } });
        const result = await fetchFromUrlPOSTAsync(urlChatSettings + "/" + itemId, urlParams);
        return result;
    },
    update: async (obj, itemId) => {
        delete obj._id;
        const urlParams = Object.keys(obj).map(key => { return { key, value: obj[key] } });
        const result = await fetchFromUrlPUTAsync(urlChatSettings + "/" + itemId, urlParams);
        return result;
    },

    getSingleForWidget: async (obj, location_id) => {
        const urlParams = Object.keys(obj).map(key => { return { key, value: obj[key] } });
        const result = await fetchFromUrlGETAsync("/triggers/" + location_id, urlParams);
        return result;
    },
    setSendQuestionForWidget: async (obj, token) => {
        const urlParams = Object.keys(obj).map(key => { return { key, value: obj[key] } });
        const result = await fetchFromUrlPOSTAsync("/triggers/" + token, urlParams);
        return result;
    },

};

const loc = {};

const chatSettingsMainActions = Object.assign(fs, loc);

export default chatSettingsMainActions;