import React from 'react'
import { CloseOutlined } from '@ant-design/icons'
import SVGWorkflowCreateNoteIcon from '../../../../icons/SVG/SVGWorkflowCreateNoteIcon'
import { getText } from '../../../../lang'
import InputFormTextArea from '../../../../components/Form/InputFormTextArea'
import MatRow from '../../../../components/MatRow'

const WorkflowActionNoteItem = ({ field, onRemove }) => {
  return (
    <div className='workflow-action-item-wrapper'>
      <MatRow flexStart className='workflow-action-item-row'>
        <div className='workflow-action-item-icon' style={{ color: '#2E7EF7' }}>
          <SVGWorkflowCreateNoteIcon width={48} height={48} />
          {getText('WORD_NOTE')}
        </div>
        <div className='workflow-action-item-textarea-wrapper'>
          <InputFormTextArea
            inputClassName='note-textarea'
            name={[field.name, 'config', 'text']}
            allowClear={false}
            maxRows={2}
            placeholder={getText('WORD_YOUR_NOTE')}
            required
          />
        </div>
      </MatRow>
      <CloseOutlined
        style={{ color: '#77808d', fontSize: '10px' }}
        className='workflow-remove-icon'
        onClick={() => {
          onRemove && onRemove()
        }}
      />
    </div>
  )
}

export default WorkflowActionNoteItem
