import React from 'react'
import { Progress } from 'antd'

function getSize(size) {
  switch (size) {
    case 'mobile':
      return 63
    case 'tablet':
      return 56
    default:
      return 73
  }
}
const ProgressChartRate = ({ percent, screenSize }) => (
  <Progress
    trailColor='none'
    size={getSize(screenSize)}
    strokeLinecap='butt'
    type='circle'
    strokeColor='#F8BA05'
    strokeWidth={13}
    percent={percent}
    showInfo={false}
  />
)

export default ProgressChartRate
