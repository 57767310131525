import React from 'react'
import MatButton from '../../../../components/MatButton'
import './CreateCampaignType.scss'

const CreateCampaignType = (props) => {
  const { icon, title, description, action, btnName } = props

  return (
    <div className='two-block'>
      <div className='icon-with-text'>
        {icon && icon}
        <div className='text'>
          {Boolean(title) && <h2> {title} </h2>}
          {Boolean(description) && <span> {description} </span>}
        </div>
      </div>
      <MatButton onClick={action} buttonText={btnName || ''} size={'large'} />
    </div>
  )
}

export default CreateCampaignType
