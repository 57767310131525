import React, { useState, useEffect, useMemo } from 'react'
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Line,
  LineChart,
  ResponsiveContainer,
} from 'recharts'
import { isMobile, isTablet } from 'react-device-detect'
import SVGBackgroundShape from '../../../../../icons/SVG/SVGBackgroundShape'
import statActions from '../../../../../store/modules/statActions'
import { getText } from '../../../../../lang'
import LoadingSpinner from '../../../../../components/LoadingSpinner'
import MatSelect from '../../../../../components/MatSelect'
import { notifyError } from '../../../../../utils/Notify'
import LineChartHeader from './LineChartHeader'

import './lineChart.scss'

const chartWidthForTablet = 820
const chartWidthForDesktop = 920
const chartWidthForMobile = 350
const intervalForMobile = 6
const interval = 0

function formatData(data = {}, by) {
  const res = []
  if (by === 'monthly') {
    for (const key in data) {
      res.push({ value: data[key], date: key.slice(0, 3) })
    }
  } else {
    for (const key in data) {
      res.push({ value: data[key], date: key })
    }
  }

  return res
}

function createWidth() {
  if (isTablet) {
    return chartWidthForTablet
  }
  if (isMobile) {
    return chartWidthForMobile
  }
  return chartWidthForDesktop
}

function createInterval(int) {
  if (isMobile) {
    return int + intervalForMobile
  }
  return int
}

const LineChartDashboard = (props) => {
  const [chartIsLoading, setChartIsLoading] = useState(true)
  const [selectedChartType, setSelectedChartType] = useState(
    props.selectedDataType === 'loc' ? 'appointment_set' : 'messages_received'
  )
  const [dataChart, setDataChart] = useState({})
  const isMouth = true

  const formattedData = useMemo(() => {
    return formatData(dataChart, isMouth)
  }, [dataChart, isMouth])

  const list_chart_options = useMemo(
    () => [
      {
        label: getText('WORD_MESSAGES_RECEIVED'),
        value: 'messages_received',
      },
      {
        label: getText('TEXT_MESSAGES_SENT'),
        value: 'messages_sent',
      },
      {
        label: getText('WORD_APPOINMENTS_SET'),
        value: 'appointment_set',
      },
      {
        label: getText('WORD_REVIEW_INVITES_SENT'),
        value: 'invites_sent',
      },
    ],
    []
  )

  useEffect(() => {
    const refreshDataChartFS = async () => {
      setChartIsLoading(true)
      let result = await statActions.getDashboardChart1(
        'monthly',
        selectedChartType,
        props.selectedDataType,
        props._location_id
      )

      if (result.success) {
        setDataChart(result.data)
      } else {
        notifyError(result.errMsg ? result.errMsg : getText('ERR_GENERAL'))
      }
      setChartIsLoading(false)
    }
    refreshDataChartFS()
  }, [isMouth, selectedChartType, props.selectedDataType, props._location_id])

  const renderSelectChart = () => {
    const selectedChartLabel = list_chart_options.find(
      (obj) => obj.value === selectedChartType
    )

    return (
      <div>
        <MatSelect
          className='dashboard-data-chart'
          secondary
          value={selectedChartLabel.label}
          options={list_chart_options}
          onSelect={(value) => setSelectedChartType(value)}
        />
      </div>
    )
  }

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className='custom-tooltip'>
          <p className='label'>
            {isMobile ? getText('THIS_MOUTH') : getText('THIS_DAY')}x
          </p>
          <p className='intro'>{payload[0].value}</p>
          <p className='desc label'>{label}</p>
          <div className='shape-wrap'>
            <SVGBackgroundShape />
          </div>
        </div>
      )
    }
    return null
  }

  return (
    <div className='conversation-chart'>
      <div className='line-chart-header-content'>
        <LineChartHeader text={getText('CONVERSATIONS_PER_MONTH')} />
        <div className='line-chart-header-right'>{renderSelectChart()}</div>
      </div>
      <div className='conversation_mouth'>
        {chartIsLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <div className={`chart-background${!isMouth ? ' day' : ''}`} />
            <ResponsiveContainer>
              <LineChart
                width={createWidth()}
                height={isMouth ? 250 : 400}
                data={formattedData || []}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                colorProgress={{
                  boxShadow: '0px 3px 3px rgba(108, 93, 211, 0.3)',
                }}
              >
                <CartesianGrid strokeDasharray='' vertical={false} />
                <XAxis
                  dataKey='date'
                  stroke='rgba(162, 163, 165, 1)'
                  angle={isMouth ? 0 : -90}
                  interval={
                    isMouth ? createInterval(interval) : createInterval(interval / 2)
                  }
                  tickMargin={isMouth ? 15 : 45}
                  height={isMouth ? 35 : 150}
                />
                <YAxis stroke='rgba(162, 163, 165, 1)' tickMargin={25} />
                <Tooltip content={<CustomTooltip />} />

                <Line
                  boxShadow={'0px 3px 3px rgba(108, 93, 211, 0.3)'}
                  type='monotone'
                  activeDot={{ r: 7 }}
                  renderDot
                  dataKey='value'
                  stroke='rgba(108, 93, 211, 1)'
                  strokeWidth={4}
                  dot={false}
                />
              </LineChart>
            </ResponsiveContainer>
          </>
        )}
      </div>
    </div>
  )
}

export default LineChartDashboard
