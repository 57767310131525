import React, { memo, useEffect, useState } from 'react'
import { getText } from '../../../../../lang'
import SVGChatClose from '../../../../../icons/SVG/SVGChatClose'
import SVGDate from '../../../../../icons/SVG/SVGDate'
import DateTimePicker from '../../../../../components/api/DateTimePicker'
import MatPopover from '../../../../../components/MatPopover'
import MatButton from '../../../../../components/MatButton'
import './MessageScheduledAction.scss'

const deviceSize = [
  'mobile',
  'tabletPortrait',
  'tablet',
  'tabletLandscape',
  'messageRightDrawer',
]

const deviceSizesForUiChanges = ['tablet', 'tabletPortrait', 'mobile']

const MessagesPicker = (props) => {
  const {
    isMessageScheduled,
    active,
    screenSize,
    disabled,
    showScheduledMessage,
    setShowScheduledMessage,
  } = props
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (showScheduledMessage && deviceSizesForUiChanges.includes(screenSize)) {
      setVisible(true)
    }
  }, [showScheduledMessage])

  return (
    <MatPopover
      title={getText('TEXT_SEND_THIS_MESSAGE_LATER')}
      trigger='click'
      visible={visible}
      onVisibleChange={() => {
        if (!isMessageScheduled && active) {
          setVisible(!visible)
          if (deviceSizesForUiChanges.includes(screenSize)) {
            setShowScheduledMessage(!showScheduledMessage)
          }
        }
      }}
      overlayClassName={'message-date-picker-dropdown'}
      placement={deviceSize.includes(screenSize) ? 'topRight' : 'top'}
      triggerPopover={
        !deviceSizesForUiChanges.includes(screenSize) ? (
          // This feature temporary unavailable
          // <Tooltip placement={deviceSize.includes(screenSize) ? 'topRight' : 'top'} title={getText('TEXT_THIS_MESSAGE_WILL_NOT_BE_VISIBLE')}>
          <MatButton
            icon={<SVGDate />}
            className={`icon-size only-stroke ${
              active ? 'active-scheduled-message' : ''
            }`}
            disabled={disabled}
            style={{ marginLeft: '0' }}
          />
        ) : // </Tooltip>
        // <div
        //   className={`message_popover_trigger icon-size ${
        //     isMessageScheduled ? 'disabled' : ''
        //   } ${active ? 'active-scheduled-message' : 'disabled'}`}
        // >
        //   <SVGDate />
        // </div>
        deviceSizesForUiChanges.includes(screenSize) ? null : (
          <MatButton
            icon={<SVGDate />}
            className={`icon-size only-stroke ${
              active ? 'active-scheduled-message' : ''
            }`}
            disabled={disabled}
            style={{ marginLeft: '0' }}
          />
        )
      }
      content={
        <>
          <DateTimePicker
            visible={visible}
            onClickSend={(date) => {
              setVisible(false)
              if (deviceSizesForUiChanges.includes(screenSize)) {
                setShowScheduledMessage(false)
              }
              props.onClickSend(date)
            }}
          />
          <div
            className='close-icon'
            onClick={() => {
              setVisible(false)
              if (deviceSizesForUiChanges.includes(screenSize)) {
                setShowScheduledMessage(false)
              }
            }}
          >
            <SVGChatClose color={'#77808D'} />
          </div>
        </>
      }
    />
  )
}

export default memo(MessagesPicker)
