import React, { forwardRef, memo, useCallback } from 'react'
import { debounce } from 'lodash'
import { Empty, Select } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { getText } from '../../lang'
import LoadingSpinner from '../LoadingSpinner'
import './MatSelect.scss'

const MatSelect = forwardRef((props, ref) => {
  const debouncedChange = useCallback(
    debounce((options) => props.onSearch && props.onSearch(options), 700),
    []
  )

  return (
    <div className={`mat-select-wrapper ${props.mainSelectClassName || ''}`}>
      {props.label ? <label className='select-label'>{props.label}</label> : null}
      <div
        className={
          'mat-select-sub-wrapper ' +
          (props.icon ? 'select-with-icon ' : '') +
          (props.primary ? 'primary ' : '') +
          (props.secondary ? 'secondary ' : '')
        }
      >
        {props.icon && <div className='select-left-icon'>{props.icon}</div>}
        <Select
          className={`mat-select ${props.className || ''}`}
          ref={ref}
          defaultValue={props.defaultValue}
          value={props.loading ? getText('WORD_LOADING') : props.value}
          labelInValue={props.labelInValue}
          popupClassName={
            'mat-select-dropdown ' +
            (props.primary ? 'primary ' : '') +
            (props.dropdownClassName || '') +
            (props.secondary ? 'secondary ' : '')
          }
          loading={props.loading}
          mode={props.mode}
          placement={props.placement}
          disabled={props.disabled}
          showSearch={props.showSearch}
          onSearch={props.showSearch && debouncedChange}
          maxTagCount={props.maxTagCount}
          filterOption={false}
          suffixIcon={props.showArrow || <DownOutlined />}
          popupMatchSelectWidth={props.dropdownMatchSelectWidth}
          dropdownStyle={props.dropdownStyle}
          menuItemSelectedIcon={props.menuItemSelectedIcon}
          onDropdownVisibleChange={props.onDropdownVisibleChange}
          getPopupContainer={
            props.getPopupContainer
              ? props.getPopupContainer
              : (trigger) => trigger.parentNode
          }
          onSelect={(value) => {
            props.onSelect && props.onSelect(value)
            let found = props.options.find((option) => option.value === value)
            if (found) {
              props.onSelectItem && props.onSelectItem(found)
            }
          }}
          onDeselect={props.onDeselect}
          notFoundContent={
            props.loading ? (
              <LoadingSpinner />
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )
          }
          onPopupScroll={(e) => {
            e.persist()
            props.onPopupScroll && props.onPopupScroll(e)
            if (
              Math.ceil(e.target.scrollTop + e.target.offsetHeight) ===
              e.target.scrollHeight
            ) {
              props.onLoadMore && props.onLoadMore()
            }
          }}
          options={props.customOption && props.options}
          onChange={props.onChange}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          onClick={props.onClick}
        >
          {props.customOption
            ? props.children
            : props.options.map((option, index) => (
                <Select.Option
                  key={option.value + '_' + index}
                  value={option.value}
                  className={
                    option.label === props.value
                      ? 'ant-select-item-option-selected'
                      : ''
                  }
                  disabled={option.label === props.value}
                >
                  {option.label}
                </Select.Option>
              ))}
        </Select>
      </div>
    </div>
  )
})

export default memo(MatSelect)
