import React from 'react'
import { Progress } from 'react-sweet-progress'
import 'react-sweet-progress/lib/style.css'
import { getText } from '../../../../../lang'
import SVGEllipseSmsScore from '../../../../../icons/SVG/SVGEllipseSmsScore'
import SVGSmsIcon from '../../../../../icons/SVG/SVGSmsIcon'
import AutoOptimizing from './AutoOptimizing'
import './SmsHealthScore.scss'

export default function SmsHealthScore({ score }) {
  return (
    <div className='health-score'>
      <div className='health-score-header'>
        <SVGSmsIcon />
        <div className='health-label'>{getText('SMS_HEALTH_SCORE')}</div>
      </div>
      <div className='health-score-graphic'>
        <div className='ellipse-wrap'>
          <SVGEllipseSmsScore />
        </div>
        <div className='score'>{score}%</div>
        <div className='progress-wrapper'>
          <div className='average-circle-progress-part' />
          <div className='progress-wrapper-background'>
            <Progress
              symbolClassName='hide-default-icon'
              width={270}
              height={174}
              status={'success'}
              strokeWidth={6}
              type='circle'
              percent={50}
              theme={{
                success: {
                  color: 'rgba(241, 243, 253, 1)',
                },
                active: {
                  color: '#fbc630',
                },
                default: {
                  color: '#fbc630',
                },
              }}
            />
          </div>
          <Progress
            symbolClassName='hide-default-icon'
            width={270}
            height={174}
            status={'success'}
            strokeWidth={6}
            type='circle'
            percent={score / 2}
            className={'progress'}
            theme={{
              success: {
                color: 'rgba(56, 81, 211, 1)',
              },
              active: {
                color: '#fbc630',
              },
              default: {
                color: '#fbc630',
              },
            }}
          />
        </div>
      </div>
      <AutoOptimizing />
    </div>
  )
}
