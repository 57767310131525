import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CSVBoxButton } from '@csvbox/react'
import SVGUploadExcel from '../../../../icons/SVG/SVGUploadExcel'
import { getText } from '../../../../lang'
import { notifyError } from '../../../../utils/Notify'
import MatButton from '../../../../components/MatButton'
import MatRow from '../../../../components/MatRow'
import BroadcastCampaignPage from '../../BroadcastCampaignPage'
import MatSwitch from '../../../../components/MatSwitch'
import MatInputNumber from '../../../../components/InputNumber'
import MatBlockWithLabel from '../../../../components/MatBlockWithLabel'
import './CreateByUpload.scss'

const initialStat = {
  fileUploadPage: true,
  hasExcludeCustomers: false,
  updateCustomersData: false,
  days_to_skip: '',
  excludeArchived: false,
}

const CreateByUpload = (props) => {
  const [uploadCampaign, setUploadCampaign] = useState(initialStat)
  const navigate = useNavigate()

  return (
    <BroadcastCampaignPage
      title={getText('TEXT_UPLOAD_WITH_EXCEL_FILE')}
      className='first-create-campaign-page file-upload-page'
    >
      <div className='file-upload-block two-block'>
        <CSVBoxButton
          licenseKey={process.env.REACT_APP_CSV_BOX_LICENSE_KEY}
          user={{ user_id: process.env.REACT_APP_CSV_BOX_USER }}
          onImport={(result, data) => {
            if (result) {
              setUploadCampaign({
                ...uploadCampaign,
                fileUploadPage: true,
                import_id: data.import_id,
                uploadExcel: data.raw_file,
                uploadFileName: data.original_filename,
              })
            } else {
              notifyError('Fail to upload Data')
            }
          }}
          render={(launch, isLoading) => {
            return (
              <MatButton
                className='csv-box-button'
                disabled={isLoading}
                onClick={launch}
                buttonText={
                  <>
                    <SVGUploadExcel />
                    {uploadCampaign.uploadFileName ? (
                      <div className='file-name'>
                        {uploadCampaign.uploadFileName}
                      </div>
                    ) : (
                      <div className='no-file'>
                        {getText('TEXT_CLICK_HERE_TO_UPLOAD_YOUR_CSV_EXCEL_FILE')}{' '}
                      </div>
                    )}
                  </>
                }
              />
            )
          }}
        />
      </div>
      <MatSwitch
        parentClassName={'exclude-switch-wrapper'}
        label={getText('UPDATE_CUSTOMERS_DATA_BROADCAST_PAGE')}
        value={uploadCampaign.updateCustomersData}
        onChange={(checked) => {
          setUploadCampaign({
            ...uploadCampaign,
            updateCustomersData: checked,
          })
        }}
      />
      <MatBlockWithLabel
        style={{ marginTop: '20px' }}
        className={'exclude-switch-with-input'}
      >
        <MatSwitch
          parentClassName={'exclude-switch-wrapper'}
          label={getText(
            'TEXT_EXCLUDE_CUSTOMERS_WHO_HAVE_BEEN_MESSAGED_IN_THE_LAST'
          )}
          value={uploadCampaign.hasExcludeCustomers}
          onChange={(checked) => {
            setUploadCampaign({
              ...uploadCampaign,
              hasExcludeCustomers: checked,
            })
          }}
        />
        <MatInputNumber
          style={{ width: '100px', marginLeft: '10px' }}
          disabled={!uploadCampaign.hasExcludeCustomers}
          value={uploadCampaign.days_to_skip}
          onChange={(e) => {
            if (String(e.target.value).length > 3) {
              return false
            } else {
              setUploadCampaign({
                ...uploadCampaign,
                days_to_skip: e.target.value,
              })
            }
          }}
        />
        <MatBlockWithLabel
          labelStyle={{ paddingBottom: '0', marginLeft: '10px' }}
          label={getText('WORD_DAY_S')}
        />
      </MatBlockWithLabel>
      <MatSwitch
        parentClassName={'exclude-switch-wrapper'}
        label={getText('TEXT_EXCLUDE_ARCHIVED_CUSTOMERS_FROM_BROADCAST')}
        value={uploadCampaign.excludeArchived}
        onChange={(checked) => {
          setUploadCampaign({
            ...uploadCampaign,
            excludeArchived: checked,
          })
        }}
      />
      <MatRow style={{ marginTop: '65px', justifyContent: 'space-between' }}>
        <MatButton
          buttonText={getText('ACTION_CANCEL')}
          htmlType={''}
          onClick={() => {
            navigate('/broadcasting/create-campaign')
          }}
          typeButton={'cancel'}
          size='large'
          style={{ maxWidth: '200px', width: '100%' }}
        />
        <MatButton
          buttonText={getText('ACTION_APPLY')}
          htmlType={''}
          disabled={!uploadCampaign.uploadExcel}
          onClick={() => {
            if (uploadCampaign.hasExcludeCustomers) {
              if (uploadCampaign.days_to_skip) {
                if (uploadCampaign.days_to_skip < 0) {
                  notifyError('Please write only positive number')
                } else if (uploadCampaign.days_to_skip > 999) {
                  notifyError('The maximum number can be 999')
                } else {
                  navigate('/broadcasting/create-campaign/sub-pages', {
                    state: uploadCampaign,
                  })
                }
              } else {
                notifyError(getText('TEXT_PLEASE_WRITE_NUMBER_OF_DAYS'))
              }
            } else {
              navigate('/broadcasting/create-campaign/sub-pages', {
                state: uploadCampaign,
              })
            }
          }}
          size='large'
          style={{ maxWidth: '200px', width: '100%' }}
        />
      </MatRow>
    </BroadcastCampaignPage>
  )
}

export default CreateByUpload
