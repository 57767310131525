import React from 'react'
import { Tabs } from 'antd'
import { getText } from '../../../../lang'
import { getLongName, replaceWords } from '../../../../utils'
import InputFormTextArea from '../../../../components/Form/InputFormTextArea'
import AddVariableInMessage from '../../../../components/AddVariableInMessage'
import InputFormText from '../../../../components/Form/InputFormText'
import { TEMPLATE_TYPES } from '.'

const SendMessageTab = (props) => {
  const {
    onChangeTextarea,
    onClickTextarea,
    onKeyUpTextarea,
    textAreaRef,
    onChangeTabs,
    languages,
    form,
    templateType,
    onChangeSubject,
    onKeyUpSubject,
    onClickSubject,
    textAreaSubjectRef,
    onAddVariableSubjectClick,
    variablesData,
  } = props

  const getErrorMessage = (lng) => {
    if (Boolean(templateType === TEMPLATE_TYPES.EMAIL)) {
      return replaceWords(getText('ERROR_TEMPLATE_TEXTAREA_BODY'), { lang: lng })
    } else {
      return replaceWords(getText('ERROR_TEMPLATE_TEXTAREA'), { lang: lng })
    }
  }

  return (
    <div>
      <Tabs
        className='mat-tabs'
        onChange={(lng) => onChangeTabs(languages[lng])}
        items={languages.map((lng, index) => {
          return {
            label: getLongName(lng) + ' ' + getText('WORD_MESSAGES_LC'),
            key: index,
            forceRender: true,
            children: (
              <>
                {Boolean(templateType === TEMPLATE_TYPES.EMAIL) && (
                  <>
                    <InputFormText
                      ref={textAreaSubjectRef}
                      name={['subject', lng]}
                      label={getText('LABEL_TEMPLATE_SUBJECT')}
                      required
                      // maxLength={100}
                      onChange={(e) => {
                        onChangeSubject && onChangeSubject(e)
                      }}
                      onKeyUp={(e) => {
                        onKeyUpSubject && onKeyUpSubject(e)
                      }}
                      onClick={(e) => {
                        onClickSubject && onClickSubject(e)
                      }}
                    />

                    <AddVariableInMessage
                      data={variablesData}
                      onClick={onAddVariableSubjectClick}
                    />
                  </>
                )}
                <InputFormTextArea
                  name={['text', lng]}
                  formClassName='with-variables'
                  style={{ height: 0, marginBottom: 25 }}
                  ref={textAreaRef}
                  placeholder={''}
                  allowClear={false}
                  maxLength={1400}
                  showCount={true}
                  label={
                    Boolean(templateType === TEMPLATE_TYPES.EMAIL) &&
                    getText('LABEL_TEMPLATE_BODY')
                  }
                  onChange={(e) => {
                    onChangeTextarea && onChangeTextarea(e.target.selectionStart)
                  }}
                  onClick={(e) => {
                    onClickTextarea && onClickTextarea(e.target.selectionStart)
                  }}
                  onKeyUp={(e) => {
                    onKeyUpTextarea && onKeyUpTextarea(e.target.selectionStart)
                  }}
                  maxLenghthMsg={
                    form &&
                    form.getFieldValue('text')[lng] &&
                    form.getFieldValue('text')[lng].length > 1400
                      ? getText('ERROR_MSG_MESSAGE_NAME_LENGTH')
                      : ''
                  }
                  message={getErrorMessage(lng)}
                  required
                />
              </>
            ),
          }
        })}
      />
    </div>
  )
}

export default SendMessageTab
