import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='11.138'
    height='6.983'
    viewBox='0 0 11.138 6.983'
  >
    <g
      id='Group_8916'
      data-name='Group 8916'
      transform='translate(-214.086 -120.086)'
    >
      <line
        id='Line_313'
        dataname='Line 313'
        x2='4.155'
        y2='4.155'
        transform='translate(215.5 121.5)'
        fill='none'
        stroke={props.color || '#a3a6be'}
        strokeLinecap='round'
        strokeWidth='2'
      />
      <line
        id='Line_314'
        dataname='Line 314'
        x1='4.155'
        y2='4.155'
        transform='translate(219.655 121.5)'
        fill='none'
        stroke={props.color || '#a3a6be'}
        strokeLinecap='round'
        strokeWidth='2'
      />
    </g>
  </svg>
)

const SVGArrowDown = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGArrowDown
