import { fetchFromUrlPUTAsync } from '../../utils/UrlHelper';

const urlOrganizations = "/organizations/lead-forms";

const fs = {
    EditTouchpointUsers: async (itemId, removeUsers, newUsers, touchpointName = "") => {
        const urlParams = [];
        newUsers.length && urlParams.push({ key: "newUsers", value: newUsers });
        removeUsers.length && urlParams.push({ key: "removeUsers", value: removeUsers });
        touchpointName && urlParams.push({ key: "name", value: touchpointName });

        const result = await fetchFromUrlPUTAsync(`${urlOrganizations}/${itemId}`, urlParams);
        return result;
    },
};

const loc = {};

const touchpointsAction = Object.assign(fs, loc);

export default touchpointsAction;