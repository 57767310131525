import React from 'react'
import LoginPageIcon from '../../../icons/login-page-icon.png'

const LoginPageIcons = (props) => {
  return (
    <div className='login-page-right-card'>
      <img src={LoginPageIcon} alt='login right pic' />
    </div>
  )
}

export default LoginPageIcons
