import React from 'react'
import { getKValueFormat } from '../../../../utils'
import { getText } from '../../../../lang'

const GreetingItem = (props) => {
  const { item } = props
  return (
    <div className='chat-item-wrapper greeting-item'>
      <div className='info-item'>
        <p>{getText('WORD_GREETING')}</p>
        <h5>{item.name}</h5>
      </div>
      <div className='info-item'>
        <p>{getText('WORD_DISPLAYED')}</p>
        <h5>{getKValueFormat(item.display_count)}</h5>
      </div>
      <div className='info-item'>
        <p>{getText('WORD_CONVERSATIONS')}</p>
        <h5>{getKValueFormat(item.count_conversations)}</h5>
      </div>
      <div className='info-item'>
        <p>{getText('WORD_CONVERSION_RATIO')}</p>
        <h5>{item.conversation_ratio ? item.conversation_ratio : 0}%</h5>
      </div>
    </div>
  )
}

export default GreetingItem
