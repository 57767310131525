import React from "react";
import Icon from "@ant-design/icons";

const SVGIcon = (props) => {
  return (
    <svg
      width="43"
      height="42"
      viewBox="0 0 43 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="21.25" cy="21" r="21" fill="#EA9957" />
      <path
        d="M14.9155 14.2549C13.5347 14.2549 12.4155 15.3741 12.4155 16.7549V25.0882C12.4155 26.7549 13.2489 27.5882 14.9155 27.5882H26.5822C28.2489 27.5882 29.0822 26.7549 29.0822 25.0882V18.4216C29.0822 17.9613 28.7089 17.5882 28.2489 17.5882H27.4155V15.0882C27.4155 14.628 27.0422 14.2549 26.5822 14.2549H14.9155ZM14.9155 15.9215H25.7489V17.5882H14.9155C14.4555 17.5882 14.0822 17.2151 14.0822 16.7549C14.0822 16.2946 14.4555 15.9215 14.9155 15.9215ZM24.9155 20.9216C25.3755 20.9216 25.7489 21.2946 25.7489 21.7549C25.7489 22.2151 25.3755 22.5882 24.9155 22.5882C24.4555 22.5882 24.0822 22.2151 24.0822 21.7549C24.0822 21.2946 24.4555 20.9216 24.9155 20.9216Z"
        fill="white"
      />
    </svg>
  );
};

const SVGPaymentSalesMonthToDateIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />;
      }}
      {...props}
    />
  );
};

export default SVGPaymentSalesMonthToDateIcon;
