import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='17'
      height='17'
      viewBox='0 0 17 17'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.47123 14.6852L13.0292 3.0235C13.1928 2.48068 12.5753 1.86315 12.0325 2.02679L0.370797 5.58476C-0.0741157 5.71549 -0.127034 6.29926 0.257567 6.68387L2.75041 9.18543C3.02125 9.45627 3.41554 9.52785 3.66608 9.3423L10.8519 4.20411L5.70826 11.3845C5.52814 11.6404 5.59971 12.0347 5.87055 12.3056L8.37212 14.7984C8.75672 15.183 9.3405 15.1301 9.47123 14.6852Z'
        fill='#5D4AA0'
      />
    </svg>
  )
}

const SVGNpsScoreInvite = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGNpsScoreInvite
