import React from 'react'
import { Button, Form, message, List, Row, Tooltip, Divider } from 'antd'
import {
  EyeOutlined,
  EyeInvisibleOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons'
import InputFormTextSecondary from '../../../../../components/Form/InputFormText/InputFormTextSecondary'
import { getText } from '../../../../../lang'
import SelectFormSecondary from '../../../../../components/Form/SelectForm/SelectFormSecondary'
import MatButton from '../../../../../components/MatButton'

const actionOptions = [
  { label: 'Show', value: 'show' },
  { label: 'Hide', value: 'hide' },
]

const conditionOptions = [
  { label: 'Contains', value: 'contains' },
  { label: 'Starts With', value: 'starts_with' },
  { label: 'Matches', value: 'matches' },
  { label: 'Ends With', value: 'ends_with' },
]

const UrlConditionManager = ({ urls, setUrls, hideAction = false }) => {
  const [form] = Form.useForm()

  const conditionExists = (newCondition) => {
    return urls.some(
      (condition) =>
        condition.condition === newCondition.condition &&
        condition.link === newCondition.link
    )
  }

  const EnhancedTooltip = () => {
    const tooltipText = (
      <div>
        <p>
          Content visibility is dynamically controlled based on the page's address.
          By default, content is visible. Use 'show' and 'hide' conditions to
          customize visibility.
        </p>
        <br />
        <p>
          <b>Show:</b> Overrides hide conditions, ensuring content is visible when
          matched.
        </p>
        <p>
          <b>Hide:</b> Makes content invisible when its conditions match, unless
          overridden by a 'show' condition.
        </p>
        <p>
          <b>Prioritization:</b> When both 'show' and 'hide' conditions match, 'show'
          takes precedence, making content visible.
        </p>
        <br />
        <p>
          <b>Contains:</b> Includes specific text.
        </p>
        <p>
          <b>Starts with:</b> Begins with specific text.
        </p>
        <p>
          <b>Matches:</b> Exactly matches specific text.
        </p>
        <p>
          <b>Ends with:</b> Ends with specific text.
        </p>
        <p>
          Protocol ('http://', 'https://') and 'www.' are ignored in comparisons.
        </p>
      </div>
    )

    return (
      <Tooltip title={tooltipText} overlayStyle={{ width: 250 }}>
        <InfoCircleOutlined style={{ marginLeft: 8, color: 'rgba(0,0,0,.45)' }} />
      </Tooltip>
    )
  }

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        if (conditionExists(values)) {
          message.error('This condition has already been added.')
          return
        }
        values.action = hideAction ? 'show' : values.action
        setUrls([...urls, values])
        form.resetFields()
      })
      .catch((info) => {})
  }

  const removeCondition = (index) => {
    const newConditions = urls.filter((_, idx) => idx !== index)
    setUrls(newConditions)
  }

  const displayConditionLabel = (condition) => {
    let item = conditionOptions.find((x) => x.value === condition.condition)

    return `Condition: ${item.label}`
  }

  const renderConditionDescription = (item) => {
    return (
      <div>
        <span>
          Action: <b>{item.action}</b>
        </span>
        <br />
        <span>
          Link: <span style={{ color: '#0075ff', opacity: 0.8 }}>{item.link}</span>
        </span>
      </div>
    )
  }

  return (
    <div>
      <Divider />
      <span>
        URL Targeting
        {EnhancedTooltip()}
      </span>

      <Form
        form={form}
        initialValues={{
          action: hideAction ? 'show' : null,
          condition: null,
          link: null,
        }}
        className='mt-3'
        layout='vertical'
      >
        <div className='d-flex' style={{ gap: 10 }}>
          {hideAction ? null : (
            <SelectFormSecondary
              label='Action'
              name='action'
              options={actionOptions}
              showSearch={false}
              required
            />
          )}
          <SelectFormSecondary
            label='Condition'
            name='condition'
            options={conditionOptions}
            showSearch={false}
            required
          />
          <InputFormTextSecondary
            label='Link'
            name='link'
            required
            errorMessage={getText('ERR_VALIDATION_REQUIRED')}
          />
        </div>
        <Row>
          <div className='d-flex w-100 mb-3 justify-content-end'>
            <MatButton
              buttonText={'Add Condition'}
              htmlType='button'
              onClick={handleSubmit}
            />
          </div>
        </Row>
      </Form>

      {urls && urls.length > 0 && (
        <div>
          <span style={{ fontSize: 15 }}>Added Conditions</span>
          <List
            itemLayout='horizontal'
            dataSource={urls}
            renderItem={(item, index) => (
              <List.Item
                actions={[
                  <Button
                    size='small'
                    type='link'
                    onClick={() => removeCondition(index)}
                  >
                    Remove
                  </Button>,
                ]}
              >
                <List.Item.Meta
                  avatar={
                    item.action === 'show' ? (
                      <EyeOutlined />
                    ) : (
                      <EyeInvisibleOutlined />
                    )
                  }
                  title={displayConditionLabel(item)}
                  description={<div>{renderConditionDescription(item)}</div>}
                  style={{ wordBreak: 'break-word' }}
                />
              </List.Item>
            )}
          />
        </div>
      )}
      <Divider />
    </div>
  )
}

export default UrlConditionManager
