import React, { useEffect, useRef, useState } from 'react'
import { Form, Tabs, Tooltip, Typography } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import _ from 'lodash'
import { useLocation, useNavigate } from 'react-router-dom'
import { getText, getTextServerError } from '../../../../../lang'
import MatModal from '../../../../../components/MatModal'
import PageFull from '../../../../../components/PageFull'
import PageFix from '../../../../../components/PageFix'
import MatForm from '../../../../../components/Form/MatForm'
import MatBlockWithLabel from '../../../../../components/MatBlockWithLabel'
import AddVariableInMessage from '../../../../../components/AddVariableInMessage'
import { getLongName } from '../../../../../utils'
import { notifyError, notifySuccess } from '../../../../../utils/Notify'
import questionsActions from '../../../../../store/modules/questionsActions'
import InputFormTextAreaSecondary from '../../../../../components/Form/InputFormTextArea/InputFormTextAreaSecondary'
import SwtichFormSecondary from '../../../../../components/Form/SwitchForm/SwtichFormSecondary'
import InputFormTextSecondary from '../../../../../components/Form/InputFormText/InputFormTextSecondary'
import SelectUsersAssigned from './SelectUsersAssigned'
import SelectStatus from './SelectStatus'

const { Text } = Typography

const QuestionEditModal = ({
  trigger,
  locationObj,
  organization,
  refreshList,
  questionData,
  modalOpen,
  setModalOpen,
}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const locationId = locationObj._id
  const { languages } = organization
  const [loading, setLoading] = useState(false)
  const [selectedLangTab, setSelectedLangTab] = useState(languages[0])
  const [currentCursorPosition, setCurrentCursorPosition] = useState(0)
  const textAreaRef = useRef()
  const [form] = Form.useForm()
  const isNewQuestion = _.isEmpty(questionData)
  const [type, setType] = useState()
  const [status, setStatus] = useState()
  const [useAsRedirect, setUseAsRedirect] = useState(false)

  useEffect(() => {
    setSelectedLangTab(languages[0])
    if (!isNewQuestion) {
      form.setFieldsValue({
        ...questionData,
        answer: questionData && questionData.answer && questionData.answer.answer,
        enabled_forward: isNewQuestion
          ? true
          : questionData && questionData.enabled_forward,
      })
      setType(questionData.type)
      setStatus(questionData.status)
      setUseAsRedirect(questionData.useRedirect)
    } else {
      form.setFieldsValue({
        enabled_forward: isNewQuestion
          ? true
          : questionData && questionData.enabled_forward,
      })
    }
  }, [questionData])

  useEffect(() => {
    if (isNewQuestion && location.pathname.includes('edit')) {
      navigate(`/location/settings/${locationId}/livechat-questions`)
    }
  }, [location.pathname])

  const answerDataIsValid = (answer) => {
    const answerLanguages = Object.keys(answer)
    if (answerLanguages.length !== 0) {
      if (answerLanguages.length !== languages.length) {
        return false
      }

      let isValidData = 0
      for (const lng of languages) {
        if (answer[lng]) {
          isValidData += 1
        } else {
          isValidData -= 1
        }
      }
      return answerLanguages.length === Math.abs(isValidData)
    }
    return true
  }

  const onFinish = async (values) => {
    setLoading(true)
    const allValues = form.getFieldsValue(true)
    const {
      question,
      answer,
      newUsers,
      removeUsers,
      enabled_forward,
      useAsTouchpoint,
      redirectedURL,
      useRedirect,
    } = allValues
    let { type } = allValues

    // the BE set answer and question property, an object with 3 languages all being empty strings
    // We shouold remove the language not used by organization as it leads to an error
    for (const lng in question) {
      if (!languages.includes(lng)) {
        delete question[lng]
        if (answer) {
          delete answer[lng]
        }
      }
    }
    for (const lng of languages) {
      if (!question[lng]) {
        return notifyError(
          getTextServerError(
            `The ( ${getLongName(lng)} ) field is required in question message`
          )
        )
      }
    }

    if (redirectedURL && !isValidUrl(redirectedURL)) {
      setLoading(false)
      return notifyError(getText('INVALID_URL'))
    }

    // checking if one answer is present, the other is also required
    if (answer !== null && !answerDataIsValid(answer)) {
      for (const lng of languages) {
        if (type && answer[lng] && answer[lng].includes('{{')) {
          notifyError(
            getTextServerError(
              `You can't use any dynamic tags with a smart answer. Check ${getLongName(
                lng
              )} language`
            )
          )
        }

        if (!answer[lng] && !useAsRedirect) {
          notifyError(
            getTextServerError(getText('MSG_ANSWER_IS_REQUIRED')).replace(
              '[lang]',
              getLongName(lng)
            )
          )
          setLoading(false)
          return
        }
      }
    }
    const payload = {
      question,
      enabled_forward: enabled_forward,
      useAsTouchpoint: useAsTouchpoint,
      status: status || '',
      redirectedURL: redirectedURL || '',
      useRedirect: useRedirect || false,
    }
    if (newUsers && newUsers.length > 0) {
      payload.newUsers = newUsers
    }
    if (answer !== null) {
      for (let value of Object.values(answer)) {
        if (value !== '' && !useAsRedirect) {
          payload.answer = answer
        }
      }
    }
    if (!isNewQuestion) {
      if (removeUsers && removeUsers.length > 0) {
        payload.removeUsers = removeUsers
      }
    }

    // Type
    type = type ? 'SMART_ANSWER' : null
    payload.type = type

    // return
    let result
    if (!isNewQuestion) {
      result = await questionsActions.update(payload, questionData._id)
    } else {
      payload._location_id = locationId
      result = await questionsActions.insert(payload)
    }

    if (result.success) {
      notifySuccess(getText('MSG_QUESTION_SAVED_SUCCESSFULLY'))
      navigate(`/location/settings/${locationId}/livechat-questions`)
      setModalOpen(false)
      refreshList && refreshList()
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
    setLoading(false)
  }

  const onFinishFailed = (errorInfo) => {
    const newQuestion = errorInfo.values
    for (const lng of languages) {
      if (!newQuestion.question[lng]) {
        return notifyError(
          getText('MSG_QUESTION_IS_REQUIRED').replace('[lang]', getLongName(lng))
        )
      }
    }
    if (errorInfo.errorFields.length > 0) {
      notifyError(errorInfo.errorFields[0].errors[0])
    }
  }

  const handleClickVariable = (variable) => {
    const currentContent = form.getFieldValue(['answer', selectedLangTab])
    const newContent = [
      currentContent && currentContent.slice(0, currentCursorPosition),
      variable,
      currentContent && currentContent.slice(currentCursorPosition),
    ].join('')

    form.setFieldsValue({
      answer: {
        [selectedLangTab]: newContent,
      },
    })
    setCurrentCursorPosition(currentCursorPosition + variable.length)
    textAreaRef.current &&
      textAreaRef.current.autoFocus(currentCursorPosition + variable.length)
  }

  const listVariablesQuestion = [
    {
      label: 'customer_first_name',
      value: ' {{customer_first_name}} ',
    },
    {
      label: 'customer_last_name',
      value: ' {{customer_last_name}} ',
    },
    {
      label: 'location_name',
      value: ' {{location_name}} ',
    },
    {
      label: 'organization_name',
      value: ' {{organization_name}} ',
    },
  ]

  const isValidUrl = (url) => {
    return url.startsWith('http://') || url.startsWith('https://')
  }

  const formName = isNewQuestion
    ? 'formSaveNewQuestion'
    : `formSaveEditQuestion_${questionData._id}`

  return (
    <MatModal
      noTrigger={!isNewQuestion}
      title={getText('TITLE_CREATE_NEW_QUESTION')}
      trigger={trigger}
      confirmLoading={loading}
      visible={modalOpen}
      forceRender={false}
      formName={formName}
      onCancel={() => {
        setModalOpen(false)
      }}
      okText={getText('ACTION_SAVE')}
    >
      <PageFull className='livechat-question-edit w-100'>
        <PageFix>
          <MatForm
            form={form}
            name={formName}
            initialValues={{
              question: {
                en:
                  (questionData &&
                    questionData.question &&
                    questionData.question.en) ||
                  '',
                fr:
                  (questionData &&
                    questionData.question &&
                    questionData.question.fr) ||
                  '',
                sp:
                  (questionData &&
                    questionData.question &&
                    questionData.question.sp) ||
                  '',
              },
              answer: {
                en:
                  (questionData &&
                    questionData.answer &&
                    questionData.answer.answer &&
                    questionData.answer.answer.en) ||
                  '',
                fr:
                  (questionData &&
                    questionData.answer &&
                    questionData.answer.answer &&
                    questionData.answer.answer.fr) ||
                  '',
                sp:
                  (questionData &&
                    questionData.answer &&
                    questionData.answer.answer &&
                    questionData.answer.answer.sp) ||
                  '',
              },
              newUsers:
                (questionData &&
                  questionData.users &&
                  questionData.users.map((u) => u._id)) ||
                [],
              type: null,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <MatBlockWithLabel black label={getText('ACTION_ASSIGN_TO')}>
              <SelectUsersAssigned
                form={form}
                questionData={questionData}
                isNewQuestion={isNewQuestion}
                modalOpen={modalOpen}
              />
            </MatBlockWithLabel>
            <Tabs
              className='mat-tabs w-100'
              onChange={(index) => {
                setSelectedLangTab(languages[index])
              }}
              items={languages.map((lng, index) => {
                return {
                  label: getLongName(lng) + ' ' + getText('WORD_MESSAGES_LC'),
                  key: index,
                  forceRender: true,
                  children: (
                    <>
                      <InputFormTextAreaSecondary
                        label={getText('LABEL_QUESTION_MESSAGE')}
                        name={['question', lng]}
                        required
                        message={getText('ERR_VALIDATION_REQUIRED')}
                      />
                      <div className='d-flex justify-content-between align-items-center mb-2'>
                        <Text
                          style={{ fontSize: 12, fontWeight: '500' }}
                          className='w-100'
                        >
                          {getText('REDIRECT_URL')}
                          <Tooltip title={getText('REDIRECT_URL_TOOLTIP')}>
                            <InfoCircleOutlined style={{ marginLeft: 8 }} />
                          </Tooltip>
                        </Text>

                        <SwtichFormSecondary
                          formClassName='mb-2 w-auto pr-2'
                          name='useRedirect'
                          onChange={(value) => setUseAsRedirect(value)}
                        />
                      </div>

                      <InputFormTextSecondary
                        name='redirectedURL'
                        required={useAsRedirect}
                        placeholder={getText('REDIRECT_URL')}
                      />
                      {!useAsRedirect && (
                        <div>
                          <InputFormTextAreaSecondary
                            ref={textAreaRef}
                            label={getText('LABEL_ANSWER_MESSAGE')}
                            name={['answer', lng]}
                            formClassName='with-variables'
                            onChange={(e) =>
                              setCurrentCursorPosition(e.target.selectionStart)
                            }
                            onKeyUp={(e) =>
                              setCurrentCursorPosition(e.target.selectionStart)
                            }
                            onClick={(e) =>
                              setCurrentCursorPosition(e.target.selectionStart)
                            }
                            rules={[
                              {
                                required: !!type,
                                message: `Please input answer for ${getLongName(
                                  lng
                                )} language`,
                                whitespace: true,
                              },
                              {
                                message:
                                  "You can't use any dynamic tags with a smart answer",
                                validator: (_, value) => {
                                  if (!value || !value.includes('{{') || !type) {
                                    return Promise.resolve()
                                  } else {
                                    return Promise.reject(
                                      "You can't use any dynamic tags with a smart answer"
                                    )
                                  }
                                },
                              },
                            ]}
                          />
                          <div
                            style={{
                              opacity: !!type === true ? 0.5 : 1,
                              marginTop: 10,
                            }}
                          >
                            <AddVariableInMessage
                              onClick={handleClickVariable}
                              data={listVariablesQuestion}
                              disabled={!!type}
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ),
                }
              })}
            />
            {!useAsRedirect && (
              <>
                <SelectStatus
                  name='status'
                  label={getText('WORD_STATUS')}
                  onChange={(e) => {
                    setStatus(e)
                  }}
                />
                <SwtichFormSecondary
                  name='type'
                  text={getText('LABEL_SHOW_ANSWER_CHAT')}
                  rowStyle={{ marginTop: 20 }}
                  onChange={(value) => setType(value)}
                />
                <SwtichFormSecondary
                  name='enabled_forward'
                  text={getText('FORWARD_NEW_LEADS_TO_CRM')}
                  rowStyle={{ marginTop: 20 }}
                />
                <SwtichFormSecondary
                  name='useAsTouchpoint'
                  text={getText('TEXT_USE_QUESTION_AS_TOUCHPOINT')}
                  rowStyle={{ marginTop: 20 }}
                />
              </>
            )}
          </MatForm>
        </PageFix>
      </PageFull>
    </MatModal>
  )
}
export default QuestionEditModal
