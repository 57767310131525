import React, { useEffect, useState } from 'react'
import SVGSent from '../../../../icons/SVG/SVGSent'
import SVGGrowUp from '../../../../icons/SVG/SVGGrowUp'
import SVGLargeGraph from '../../../../icons/SVG/SVGLargeGraph'
import { getKValueFormat, MainColor } from '../../../../utils'
import { getText } from '../../../../lang'
import ProgresBar from '../../../../components/ProgressBar/ProgessBar'

const GreatingTabHeader = (props) => {
  const [chartData, setData] = useState([])
  const [maxItem, setMaxItem] = useState(0)

  useEffect(() => {
    changeData()
  }, [props.chartData])

  const changeData = () => {
    let obj = props.chartData
    let data = []
    for (const key in obj) {
      if (!isNaN(new Date(key).getTime())) {
        data.push(calculateChartPercentage(obj[key], key))
      }
    }

    setMaxItem(props.chartData.highest_conversation_count || 1)
    setData(data)
  }

  const calculateChartPercentage = (item, label) => {
    return {
      displayed_count: item.displayed_count,
      Conversation_count: item.by_greeting_count,
      value: item.displayed_count,
      label: `${
        new Date(label).getDate() < 10
          ? '0' + new Date(label).getDate()
          : new Date(label).getDate()
      }`,
    }
  }

  return (
    <div className='greetings-statistic'>
      <div className='total-conversation'>
        <label>{getText('WORD_TOTAL_CONVERSATIONS')}</label>
        <div className='icon'>
          <SVGSent color='#F8A326' />
        </div>
        <div className='data'>
          <div className='amount'>
            {props.count ? getKValueFormat(props.count) : 0}
          </div>
          <div className='text'>{getText('TEXT_FOR_LAST_MONTH')}</div>
        </div>
        <span className='background-icon'>
          <SVGLargeGraph />
        </span>
      </div>
      <div className='conversations-statistic'>
        <div className='conversations'>
          <div className='conversations-header'>
            <label>{getText('TEXT_CONVERSATIONS_FOR_LAST_MONTH')}</label>
            <div className='statistic-info'>
              <div className='info'>
                <label className='yellow' />
                <label>{getText('WORD_CONVERSATION')}</label>
              </div>
            </div>
          </div>
          <div className='statistic-data'>
            <div className='data'>
              <div className='percentage'>
                <SVGGrowUp />
                {props.conversationRatio}%
              </div>
              <div className='text'>{getText('TEXT_COMPARED_FROM_LAST_MONTH')}</div>
            </div>
            <div className='statistic-bar'>
              <ProgresBar
                max={maxItem || 1}
                scale={100}
                height={100}
                barConversationColor='#F4F7FC'
                barColor='#FFA820'
                barDisplayedColor={MainColor}
                items={chartData || []}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GreatingTabHeader
