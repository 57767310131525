export const SET_USER = 'SET_USER'
export const SET_TOKEN = 'SET_TOKEN'
export const SET_UNREAD_MSG = 'SET_UNREAD_MSG'
export const SET_MY_INBOX_UNREAD_MSG = 'SET_MY_INBOX_UNREAD_MSG'
export const SET_ARCHIVED_MSG = 'SET_ARCHIVED_MSG'
export const SET_NEEDS_ATTENTION_MSG = 'SET_NEEDS_ATTENTION_MSG'
export const SET_UNASSIGNED_MSG = 'SET_UNASSIGNED_MSG'
export const SET_LANG = 'SET_LANG'
export const SET_MENU_OPEN = 'SET_MENU_OPEN'
export const SET_LAST_ACTION_TIME = 'SET_LAST_ACTION_TIME'
export const SET_SELF_ACTION_TIME = 'SET_SELF_ACTION_TIME'
export const SET_MOBILE_APP = 'SET_MOBILE_APP'
export const SET_FCM_TOKEN = 'SET_FCM_TOKEN'
export const SET_LOADING_ORGANIZATION = 'SET_LOADING_ORGANIZATION'
export const SET_VEHICLE_INTEREST = 'SET_VEHICLE_INTEREST'
export const SET_CONVERSATION = 'SET_CONVERSATION'
export const SET_AI_SUMMARY = 'SET_AI_SUMMARY'

const authReducer = (state, action) => {
  if (!state) {
    state = {
      accessToken: {},
      user: {},
      myMessagesInboxUnreadMessages: 0,
      unreadMessages: 0,
      archivedMessages: 0,
      needsAttentionMessages: {
        myMessagesInbox: 0,
        mainInboxCount: 0,
      },
      unassignedMessages: 0,
      lang: 'en',
      menuOpen: true,
      lastActionTime: 0,
      selfActionTime: 0,
      isMobileApp: false,
      fcmToken: '',
      loadingOrg: false,
      vehicleOfInterest: {},
      selectedConversation: false,
      aiSummary: false,
    }
  }

  switch (action.type) {
    case SET_USER:
      const user = action.payload
      const organization = user.organization
      if (organization) {
        user.organization.languages =
          organization.defaultLanguage !== organization.languages[0]
            ? organization.languages.reverse()
            : organization.languages
      }
      return { ...state, user }
    case SET_TOKEN:
      if (Object.entries(action.payload).length !== 0) {
        action.payload.refreshTime = new Date(Date.now())
      }
      return { ...state, accessToken: action.payload }
    case SET_UNREAD_MSG:
      return { ...state, unreadMessages: action.payload }
    case SET_MY_INBOX_UNREAD_MSG:
      return {
        ...state,
        myMessagesInboxUnreadMessages: action.payload,
      }
    case SET_ARCHIVED_MSG:
      return { ...state, archivedMessages: action.payload }
    case SET_NEEDS_ATTENTION_MSG:
      return { ...state, needsAttentionMessages: action.payload }
    case SET_UNASSIGNED_MSG:
      return { ...state, unassignedMessages: action.payload }
    case SET_LANG:
      return { ...state, lang: action.payload }
    case SET_MENU_OPEN:
      return { ...state, menuOpen: action.payload }
    case SET_LAST_ACTION_TIME:
      return { ...state, lastActionTime: action.payload }
    case SET_SELF_ACTION_TIME:
      return { ...state, selfActionTime: action.payload }
    case SET_MOBILE_APP:
      return { ...state, isMobileApp: action.payload }
    case SET_FCM_TOKEN:
      return { ...state, fcmToken: action.payload }
    case SET_LOADING_ORGANIZATION:
      return { ...state, loadingOrg: action.payload }
    case SET_VEHICLE_INTEREST:
      return { ...state, vehicleOfInterest: action.payload }
    case SET_CONVERSATION:
      const newState = {
        ...state,
        selectedConversation: action.payload,
      }
      return newState
    case SET_AI_SUMMARY:
      return { ...state, aiSummary: action.payload }
    default:
      return state
  }
}

export default authReducer
