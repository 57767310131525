import React, { useState } from 'react'
import { getLongName } from '../../../../utils'
import SwitchWithText from '../../components/SwitchWithText'

const LanguageItem = (props) => {
  const {
    lng,
    isDefault,
    disabled,
    onSetAsDefaultClick,
    isSelected,
    onChangeSwitch,
  } = props

  const [value, setValue] = useState(isSelected)

  return (
    <SwitchWithText
      title={getLongName(lng)}
      value={value}
      isDefault={isDefault}
      disabled={disabled}
      onSetAsDefaultClick={() => {
        if (value) {
          onSetAsDefaultClick(lng)
        }
      }}
      onChangeSwitch={(e) => {
        setValue(e)
        onChangeSwitch(lng)
      }}
    />
  )
}

export default LanguageItem
