import React from 'react'
import { Form, Tabs, Typography } from 'antd'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import TreeSelectForm from '../../../../../components/Form/TreeSelectForm'
import { getLongName } from '../../../../../utils'
import { getText } from '../../../../../lang'
import InputFormTextAreaSecondary from '../../../../../components/Form/InputFormTextArea/InputFormTextAreaSecondary'

const LocationsOptions = ({
  step,
  index,
  treeData,
  isDataTree,
  addSelectedLocations,
  getSelectedLocationIds,
  handleLocationReorder,
  handleLocationChange,
  organization,
}) => {
  const onChange = (value) => {
    let list = value
    let item
    value.forEach((element) => {
      item = treeData.list.find((item) => item.key === element)
      if (item && item.children) {
        const newItems = item.children.map((x) => x.id)
        list = list.filter((x) => x !== element)
        list = [...list, ...newItems]
      }
    })
    addSelectedLocations(index, list)
  }

  return (
    <Form.Item>
      <TreeSelectForm
        loading={isDataTree}
        name={'locations'}
        label={getText('TEXT_CHOOSE_SEVERAL_LOCATIONS_AND_AFTERWARD')}
        treeData={treeData.list || []}
        treeCheckable={true}
        multipleNewStyle
        placeholder={getText('WORD_SELECT_LOCATIONS')}
        message={getText('TEXT_AT_LEAST_ONE_LOCATION_MUST_BE_SELECTED')}
        onChange={(value) => {
          onChange(value)
        }}
        defaultValue={getSelectedLocationIds(step)}
        required
      />
      <Tabs
        className='mat-tabs'
        items={organization.languages.map((lng, yIndex) => {
          return {
            label: getLongName(lng) + ' ' + getText('WORD_MESSAGES_LC'),
            key: yIndex,
            forceRender: true,
            children: (
              <>
                <DragDropContext onDragEnd={handleLocationReorder(index)}>
                  <Droppable droppableId='droppable' direction='vertical'>
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {step.locations &&
                          step.locations.map((location, locationIndex) => (
                            <Draggable
                              key={location.location._id}
                              draggableId={location.location._id}
                              index={locationIndex}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div className='d-flex align-items-center mb-2'>
                                    <div className='ml-2 d-flex flex-column'>
                                      <Typography.Text
                                        style={{ fontSize: 18 }}
                                        strong
                                      >
                                        {location.location.name}
                                      </Typography.Text>
                                      <Typography.Text>
                                        {location.organization.name}
                                      </Typography.Text>
                                    </div>
                                  </div>
                                  <InputFormTextAreaSecondary
                                    label='Custom Name'
                                    name={[
                                      `customName${locationIndex}-${index}`,
                                      lng,
                                    ]}
                                    onChange={(e) => {
                                      e.persist()
                                      handleLocationChange(
                                        index,
                                        location.location._id,
                                        e.target.value,
                                        lng
                                      )
                                    }}
                                    message={getText('ERR_VALIDATION_REQUIRED')}
                                    autoSize={false}
                                    initialValue={
                                      location.locationCustomName &&
                                      location.locationCustomName[lng]
                                    }
                                  />
                                </div>
                              )}
                            </Draggable>
                          ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </>
            ),
          }
        })}
      />
    </Form.Item>
  )
}

export default LocationsOptions
