import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width={22}
      height={22}
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g
        stroke='#0E0E0E'
        strokeWidth={1.5}
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <path d='M20.167 10.174v-3.85c0-3.209-1.284-4.492-4.492-4.492h-3.85c-3.208 0-4.492 1.283-4.492 4.492v1.008h2.842c3.208 0 4.492 1.283 4.492 4.492v2.841h1.008c3.208 0 4.492-1.283 4.492-4.491z' />
        <path d='M14.667 15.674v-3.85c0-3.209-1.284-4.492-4.492-4.492h-3.85c-3.208 0-4.492 1.283-4.492 4.492v3.85c0 3.208 1.284 4.491 4.492 4.491h3.85c3.208 0 4.492-1.283 4.492-4.491z' />
        <path d='M5.573 13.748l1.788 1.788 3.566-3.575' />
      </g>
    </svg>
  )
}

const SVGSettingsTransferIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGSettingsTransferIcon
