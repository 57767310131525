import React from 'react'
import { parsePhoneNumber } from 'libphonenumber-js'
import { getText } from '../../../lang'
import InputFormTextSecondary from '../../../components/Form/InputFormText/InputFormTextSecondary'
import InputFormPhoneSecondary from '../../../components/Form/InputFormPhone/InputFormPhoneSecondary'
import InputFormEmailSecondary from '../../../components/Form/InputFormEmail/InputFormEmailSecondary'
import { notifyError } from '../../../utils/Notify'

const PersonalInformationSection = (props) => {
  const { organization, handleMethodTypeChange, form, dispatch } = props
  return (
    <>
      <InputFormTextSecondary
        name='firstName'
        label={getText('WORD_FIRST_NAME')}
        required
        hasFeedback={false}
        errorMessage='First name is required'
      />
      <InputFormTextSecondary
        name='lastName'
        label={getText('WORD_LAST_NAME')}
        required
        hasFeedback={false}
        errorMessage='Last name is required'
      />
      <InputFormPhoneSecondary
        autoCompleteCountryCode
        initialCountry={
          organization &&
          organization.twilio_number &&
          parsePhoneNumber(organization.twilio_number).country &&
          parsePhoneNumber(organization.twilio_number).country.toLowerCase()
        }
        name='phone'
        label={getText('WORD_PHONE')}
        onChange={(value) => {
          dispatch({ type: 'CHANGE', name: 'phone', payload: value })
          form.setFieldValue('phone', value)
          if (!value) {
            handleMethodTypeChange('phone')
          }
        }}
      />
      <InputFormEmailSecondary
        name='email'
        label={getText('WORD_EMAIL')}
        placeholder={getText('WORD_EMAIL')}
        hasFeedback={false}
        onChange={(e) => {
          dispatch({
            type: 'CHANGE',
            name: 'email',
            payload: e.target.value,
          })
          if (!e.target.value) {
            handleMethodTypeChange('email')
          }
        }}
        onInvalid={(value) => {
          if (value) {
            notifyError(getText('TEXT_EMAIL_IS_NOT_VALID'))
          }
        }}
      />
    </>
  )
}

export default PersonalInformationSection
