import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M4.96013 3.33148C3.26513 3.33148 1.93847 3.75981 1.70431 5.38898C1.6293 5.91064 1.7943 6.42398 2.17346 6.79482C2.3618 6.97898 2.56097 7.19815 2.8243 7.41982C3.48514 7.97732 4.32764 8.60398 5.11597 9.16481C7.28514 10.7065 8.99764 11.6648 10.0118 11.6648C11.026 11.6648 12.7385 10.7065 14.9077 9.16481C15.6968 8.60398 16.5393 7.97648 17.1993 7.41982C17.4627 7.19815 17.6618 6.97982 17.8502 6.79482C18.2293 6.42398 18.3943 5.91064 18.3193 5.38898C18.0852 3.75981 16.7585 3.33148 15.0635 3.33148H4.96013ZM1.67847 8.46148V13.3315C1.67847 15.1723 3.17097 16.6648 5.01181 16.6648H15.0118C16.8527 16.6648 18.3452 15.1723 18.3452 13.3315V8.46148C17.6618 9.11065 16.8985 9.78065 15.8193 10.5448C13.456 12.2181 11.3335 13.3315 10.0118 13.3315C8.69014 13.3315 6.56765 12.2181 4.20431 10.5448C3.12431 9.78065 2.3618 9.11065 1.67847 8.46148Z'
      fill='#A3A1B7'
    />
  </svg>
)

const SVGEmailNew = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGEmailNew
