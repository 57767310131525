import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import organizationActions from '../../store/modules/organizationActions'

const StripeReturn = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  useEffect(() => {
    const init = async () => {
      if (window.opener && window.name === 'stripeAuth') {
        const data = await organizationActions.authPayment(id)
        window.opener.postMessage(
          {
            type: 'stripeAuth',
            data,
          },
          window.location.origin
        )
        window.close()
      } else {
        navigate('/')
      }
    }
    init()
  }, [])

  return null
}

export default StripeReturn
