import React from 'react'
import SVGDownloadIconNew from '../../../../icons/SVG/SVGDownloadIconNew'

const BroadcastListDesktopItemExcel = ({ title, rowName, href }) => {
  return (
    <div className='broadcast-message-row-wrapper-with-margin'>
      <div className='broadcast-message-row'>
        <p className='broadcast-more-info-row-title'>{title}</p>
      </div>
      <div className='download-broadcast-row-wrapper'>
        <div className='broadcast-more-info-message-text'>{rowName}</div>
        <a className='download-link-broadcast' href={href} download>
          <SVGDownloadIconNew />
        </a>
      </div>
    </div>
  )
}

export default BroadcastListDesktopItemExcel
