import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = ({ color }) => (
  <svg
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='16' cy='16' r='16' fill={color || '#F0EFFB'} opacity='0.1' />
    <g clipPath='url(#clip0_27240_298775)'>
      <path
        d='M18.47 13L22 16.53M22 16.53L18.47 20.06M22 16.53H10.7156'
        stroke={color || '#6C5DD3'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_27240_298775'>
        <rect
          width='15'
          height='17'
          fill='white'
          transform='matrix(0 1 -1 0 25 9)'
        />
      </clipPath>
    </defs>
  </svg>
)

const SVGSmsAutomationArrowIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGSmsAutomationArrowIcon
