import React, { useEffect, useState, useRef, Suspense, lazy, useMemo } from 'react'
import querystring from 'querystring'
import { Drawer } from 'antd'
import _ from 'lodash'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import messageActions from '../../../store/modules/messageActions'
import locationActions from '../../../store/modules/locationActions'
import authActions from '../../../store/modules/authActions'
import { getText } from '../../../lang'
import { moveToLoginPage } from '../../../utils'
import SVGDrawerManageLocationsPurple from '../../../icons/SVG/SVGDrawerManageLocationsPurple'
import { DEALER_SOCKET, ELEADS, VIN_SOLUTION } from '../../../config/constants'
import { notifyError } from '../../../utils/Notify'
import SelectLocations from '../../../components/api/SelectLocations'
import SelectOrganization from '../../../components/api/SelectOrganization'
import LoadingSpinner from '../../../components/LoadingSpinner'
import MatSelect from '../../../components/MatSelect'
import { useSocket } from '../../../utils/socket'
import {
  ARCHIVED,
  MY_MESSAGES,
  PHONE,
  UNASSIGNED,
} from '../../messages/utils/constants'
import './ExtensionProfileInfo.scss'

const MessageRight = lazy(
  () => import('../../messages/right/MessageRightMain/MessageRight')
)

const MessageCenter = lazy(
  () => import('../../messages/center/MessageCenterMain/MessageCenter')
)

const extractSearchParams = (string) => {
  return querystring.parse(string.substr(1))
}

const ExtensionProfileInfo = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [loading, setloading] = useState(false)
  const [conversation, setConversation] = useState({})
  const [locationData, setLocationData] = useState({})
  const [msgChangeEmailSMSType, setmsgChangeEmailSMSType] = useState('')
  const [rightBoxVisible, setRightBoxVisible] = useState(false)

  const query = extractSearchParams(location.search)

  const organization = useSelector((state) => {
    return state.authReducer.user.organization
  })

  const organizationID = useMemo(
    () => (organization ? organization._id : ''),
    [organization]
  )

  const isAdmin = useSelector((state) => {
    return state.authReducer.user.isAdmin || false
  })

  const isSupportAgent = useSelector((state) => {
    return state.authReducer.user.isSupportAgent || false
  })

  const isManager = useSelector((state) => {
    return state.authReducer.user.isManager || false
  })

  const user = useSelector((state) => {
    return state.authReducer.user
  })

  let MessageCenterRef = useRef()

  useEffect(() => {
    setloading(true)
    const { phone_number } = query
    setmsgChangeEmailSMSType(PHONE)
    if (phone_number) {
      const handleCreateNewConversationFromURL = async (phone_number) => {
        if (_.isEmpty(user)) {
          moveToLoginPage(navigate, false, {
            state: { goBack: true },
          })
        } else {
          let newPhone = phone_number
            .replace(/\s/g, '')
            .replace(/[()]/g, '')
            .replace('+', '')
          const result = await messageActions.apply(newPhone)
          if (result.success) {
            await messageActions.sendExtensionCRM(result.data.conversation._id)
            result.data.conversation.isNew = result.data.isNew
            result.data.conversation.isSetDataReciver = true
            const resultLocation = await locationActions.getSingle(user._location_id)
            if (resultLocation.success) {
              setLocationData(resultLocation.data)
            }
            if (query.email) {
              if (!query.email.match(/\.[a-zA-Z]{2,}$/)) {
                const lastDotIndex = query.email.lastIndexOf('.')
                let domainSuffix = query.email.slice(lastDotIndex + 1)
                if (domainSuffix === 'c') {
                  query.email = query.email.slice(0, lastDotIndex + 1) + 'com'
                }
              }
              result.data.conversation.receiver.email = query.email
            }
            if (query.first_name) {
              result.data.conversation.receiver.firstName = query.first_name
              result.data.conversation.receiver.fullName = query.first_name
            }
            if (query.last_name) {
              result.data.conversation.receiver.lastName = query.last_name
              result.data.conversation.receiver.fullName += ' ' + query.last_name
            }
            if (query.source) {
              result.data.conversation.receiver.source =
                (query.source.toLowerCase() === DEALER_SOCKET.toLowerCase() &&
                  DEALER_SOCKET) ||
                (query.source.toLowerCase() === ELEADS.toLowerCase() && ELEADS) ||
                (query.source.toLowerCase() === VIN_SOLUTION.toLowerCase() &&
                  VIN_SOLUTION) ||
                'other'
            }
            if (query.website) {
              result.data.conversation.receiver.website = query.website
            }
            setConversation(result.data.conversation)
          }
          setloading(false)
        }
      }
      handleCreateNewConversationFromURL(phone_number)
    }
  }, [organization, user])

  const onReciveMessage = (event, data) => {
    if (user._organization_id !== data._organization_id) return
    let type_folder
    if (conversation.unassigned) {
      type_folder = MY_MESSAGES
    } else {
      type_folder = UNASSIGNED
    }
    let selected = conversation?._id === data.conversation._id

    if (selected) {
      switch (event) {
        case 'ASSIGN_CONVERSATION_USER':
          if (!data.conversation.users.includes(user._id)) return
          if (MessageCenterRef && MessageCenterRef.current) {
            MessageCenterRef.current.addNewMessage(data.conversation.messages[0])
          }
          setConversation(data.conversation)
          return
        case 'UNASSIGN_CONVERSATION_USER':
        case 'ARCHIVE_CONVERSATION':
        case 'UPDATE_CONVERSATION':
        case 'UNARCHIVE_CONVERSATION':
          setConversation(data.conversation)
          return
        case 'SCHEDULED_MESSAGE_REMOVED':
          data.newInsertedMsg.isScheduled = true
          MessageCenterRef.current.removeScheduledMessage(data.newInsertedMsg)
          return
        case 'NEW_NOTE_APPEAR':
          data.conversation.notes[0]._conversation_id = data.conversation._id
          data.conversation.notes[0].type = 'note'
          data.conversation.notes[0].body = data.conversation.notes[0].text
          data.conversation.notes[0].whoSentThis_name =
            data.conversation.notes[0].user_name
          if (MessageCenterRef && MessageCenterRef.current) {
            MessageCenterRef.current.addNewMessage(data.conversation.notes[0])
          }
          setConversation(data.conversation)
          return
        case 'SCHEDULED_MESSAGE_CREATED':
          let newMessage = data.newInsertedMsg
          newMessage._conversation_id = conversation._id
          newMessage.isScheduled = true
          if (MessageCenterRef && MessageCenterRef.current) {
            MessageCenterRef.current.addNewMessage(newMessage)
          }
          return
        default:
      }
      if (event === 'MARK_READ_CONVERSATION') {
        return refreshConversation(data.conversation, false, false)
      }
      if (
        [MY_MESSAGES].includes(type_folder) &&
        !data.conversation.users.includes(user._id)
      )
        return
    }
  }

  const onReciveMessageNew = (event, data) => {
    try {
      if (data && data.conversation) {
        let isToMe = false
        if (user._organization_id === data._organization_id) {
          isToMe = true
        }
        if (isToMe) {
          if (
            data.conversation._id === conversation._id ||
            data.conversation.receiver_phone === conversation.receiver_phone
          ) {
            if (event === 'NEW_INVITATION_AVAILABLE') {
              data.newInsertedInv.isInvitation = true
              if (MessageCenterRef && MessageCenterRef.current) {
                MessageCenterRef.current.addNewMessage(data.newInsertedInv)
              }
            } else if (event === 'VIDEO_MESSAGE_ADDED') {
              data.video._conversation_id = data.conversation._id
              data.video.video = true
              if (MessageCenterRef && MessageCenterRef.current) {
                MessageCenterRef.current.addNewMessage(data.video)
              }
            } else if (event === 'VIDEO_MESSAGE_CHANGED') {
              if (data.video.status === 'VIDEO_PROCESSING_FAILED') {
                notifyError(
                  getText(
                    'MSG_VIDEO_PROCESSING_FAILED_IN_ORDER_TO_RETRY_UPLOADING_IT'
                  )
                )
              }
              data.video._conversation_id = data.conversation._id
              data.video.video = true
              if (MessageCenterRef && MessageCenterRef.current) {
                MessageCenterRef.current.addNewMessage(data.video)
              }
            } else if (event === 'MESSAGE_STATUS_WAS_CHANGED') {
              MessageCenterRef.current.updateSmsStatus(
                data.newInsertedMsg.smsStatus,
                data.newInsertedMsg.id
              )
            } else if (event === 'NEW_MESSAGE_AVAILABLE') {
              let newMessage = data.newInsertedMsg
              newMessage._conversation_id = conversation._id
              if (MessageCenterRef && MessageCenterRef.current) {
                MessageCenterRef.current.addNewMessage(newMessage)
              }
            } else if (event === 'NEXT_SCHEDULED_MESSAGES_UPDATED') {
              let list = []
              if (Array.isArray(data.nextScheduledMessages.dripSequence)) {
                list = list.concat(data.nextScheduledMessages.dripSequence)
              }
              if (data.nextScheduledMessages.superhuman) {
                list.push(data.nextScheduledMessages.superhuman)
              }
              if (data.nextScheduledMessages.followUp) {
                data.nextScheduledMessages.followUp.type =
                  data.nextScheduledMessages.followUp.status
                list.push(data.nextScheduledMessages.followUp)
              }
              if (MessageCenterRef && MessageCenterRef.current) {
                MessageCenterRef.current.addNextScheduledMessage(list)
              }
            }
          }
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  useSocket(
    `private-organization=${organizationID}-conversation=${conversation._id}`,
    ['INVITATION_WAS_CHANGED'],
    onReciveMessageNew,
    organizationID &&
      query.phone_number !== '' &&
      !_.isEmpty(user) &&
      !!conversation._id
  )

  useSocket(
    `private-organization=${organizationID}-conversation=list`,
    ['NEW_INVITATION_AVAILABLE', 'VIDEO_MESSAGE_ADDED', 'NEW_MESSAGE_AVAILABLE'],
    onReciveMessageNew,
    organizationID && query.phone_number !== '' && !_.isEmpty(user)
  )

  useSocket(
    `private-organization=${organizationID}-conversation=${conversation._id}`,
    [
      'MESSAGE_STATUS_WAS_CHANGED',
      'VIDEO_MESSAGE_CHANGED',
      'NEW_CONVERSATION_SUMMARY',
      'NEXT_SCHEDULED_MESSAGES_UPDATED',
    ],
    onReciveMessageNew,
    organizationID &&
      conversation._id &&
      query.phone_number !== '' &&
      !_.isEmpty(user)
  )

  useSocket(
    `private-organization=${organizationID}-conversation=list`,
    ['MARK_READ_CONVERSATION', 'ARCHIVE_CONVERSATION', 'UNARCHIVE_CONVERSATION'],
    onReciveMessage,
    organizationID
  )
  useSocket(
    `private-organization=${organizationID}-conversation=${conversation._id}`,
    [
      'UPDATE_CUSTOMER',
      'CONSENT_CHANGED',
      'PHONE_STATUS_CHANGED',
      'UPDATE_CONVERSATION',
      'ASSIGN_CONVERSATION_USER',
      'UNASSIGN_CONVERSATION_USER',
      'NEW_NOTE_APPEAR',
      'SCHEDULED_MESSAGE_CREATED',
      'SCHEDULED_MESSAGE_UPDATED',
      'SCHEDULED_MESSAGE_REMOVED',
      'CONVERSATION_REMINDER_CREATED',
      'CONVERSATION_REMINDER_CANCELLED',
      'CONVERSATION_REMINDER_NUDGED',
      'CONVERSATION_INTENTS_UPDATED',
      'CUSTOMER_STATUS_WAS_CHANGED',
    ],
    onReciveMessage,
    !!organizationID && !!conversation._id
  )

  useSocket(
    `private-organization=${organizationID}`,
    ['APPOINTMENT_CREATED'],
    onReciveMessage,
    !!organizationID
  )

  if (query.phone_number === '') {
    return null
  }

  const onChangeEmailSMSType = (type) => {
    setmsgChangeEmailSMSType(type)
  }

  const refreshReceiverOrTagsOnConversation = (prop, value) => {
    if (conversation) {
      const newConversation = _.cloneDeep(conversation)
      newConversation[prop] = value
      setConversation(newConversation)
    }
  }
  const refreshConversation = (conv) => {
    setConversation(conv)
  }

  if (query.phone_number === '') {
    return
  }

  const restrictSupAgeAccessToAllLoc =
    organization?.restrictions?.restrictSupportAgentsAccessToAllLocations
  const restrictManagersAccessToAllLoc =
    organization?.restrictions?.restrictManagersAccessToAllLocations

  return !loading ? (
    !_.isEmpty(conversation) ? (
      <Suspense fallback={<div>Loading</div>}>
        <div className='extension-profile-info-wrapper'>
          <div className='extension_location_wrapper'>
            <div className='extension_user_location'>
              <SelectOrganization primary showSearch={true} />
            </div>

            {(!restrictSupAgeAccessToAllLoc && isSupportAgent) ||
            (!restrictManagersAccessToAllLoc && isManager) ||
            (isAdmin && locationData) ? (
              <div className='extension_user_location'>
                <SelectLocations
                  primary
                  dropdownClassName={`mat-header-dropdown-responsive-second`}
                  location={locationData}
                  onSelectItem={async (item) => {
                    setLocationData(item)
                    await authActions.setLocation(item)
                  }}
                />
              </div>
            ) : (
              <div className='extension_user_location'>
                <MatSelect
                  icon={<SVGDrawerManageLocationsPurple />}
                  options={[
                    {
                      label: locationData.name,
                      value: locationData._id,
                    },
                  ]}
                  primary
                  dropdownClassName={`mat-header-dropdown-responsive-second 
                          }`}
                  value={locationData._id}
                />
              </div>
            )}
          </div>
          <div className='message_right_panel'>
            <Drawer
              placement='right'
              closable={false}
              onClose={() => setRightBoxVisible(false)}
              open={rightBoxVisible}
              width={312}
              rootClassName='message_right_panel-drawer message_right_panel-drawer-extension'
            >
              <section className='message_right_panel'>
                <MessageRight
                  conversation={conversation}
                  onSaveReciver={(value) =>
                    refreshReceiverOrTagsOnConversation('receiver', value)
                  }
                  onSaveTags={(value) =>
                    refreshReceiverOrTagsOnConversation('tags_items', value)
                  }
                  refreshConversation={refreshConversation}
                  msgChangeEmailSMSType={msgChangeEmailSMSType}
                  screenSize={'mobile'}
                  onCloseDrawer={() => setRightBoxVisible(false)}
                />
              </section>
            </Drawer>
          </div>
          <div className='message_center_panel'>
            <MessageCenter
              changeMessageType={PHONE}
              showRightBox={() => setRightBoxVisible(true)}
              messageType={conversation.is_archived ? ARCHIVED : MY_MESSAGES}
              conversation={conversation}
              disabled={false}
              refreshTags={(value) =>
                refreshReceiverOrTagsOnConversation('tags_items', value)
              }
              refreshConversation={refreshConversation}
              screenSize={'mobile'}
              ref={MessageCenterRef}
              onChangeEmailSMSType={onChangeEmailSMSType}
              msgChangeEmailSMSType={msgChangeEmailSMSType}
              isBlueExtension={true}
            />
          </div>
        </div>
      </Suspense>
    ) : (
      <LoadingSpinner />
    )
  ) : (
    <LoadingSpinner />
  )
}

export default ExtensionProfileInfo
