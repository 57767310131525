import React from 'react'
import Icon from '@ant-design/icons'
import { MainColor } from '../../utils'

const SVGIcon = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='10.258'
      height='14.208'
      viewBox='0 0 10.258 14.208'
    >
      <g id='noun_Location_1355745' opacity='0.8'>
        <g id='Layer_3' data-name='Layer 3'>
          <path
            id='Path_866'
            data-name='Path 866'
            d='M23.38,9.25a5.129,5.129,0,0,0-4.353,7.841l4.353,6.367,4.353-6.367A5.129,5.129,0,0,0,23.38,9.25Zm0,7.016a1.887,1.887,0,1,1,1.887-1.887A1.887,1.887,0,0,1,23.38,16.266Z'
            transform='translate(-18.251 -9.25)'
            fill={props.color || MainColor}
          />
        </g>
      </g>
    </svg>
  )
}

const SVGDrawerManageLocationsPurple = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGDrawerManageLocationsPurple
