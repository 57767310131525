import React from 'react'
import { Divider } from 'antd'
import './MatButtonWithDropDown.scss'
import SVGArrowDown from '../../icons/SVG/SVGArrowDown'
import MatSelect from '../MatSelect'
import MatLongPressButton from '../MatLongPressButton'

const MatButtonWithDropDown = ({
  isLoading,
  onDropDownChange,
  placement,
  options,
  onClick,
  disabled,
  buttonText,
}) => {
  return (
    <div
      className='button-with-dropdown-wrapper'
      style={{ opacity: isLoading ? 0.5 : 1 }}
    >
      <MatLongPressButton
        onLongPress={() => {
          // if (deviceSizes.includes(screenSize) && !disableLongPress) {
          //   setShowScheduledMessage(true)
          // }
        }}
        onClick={onClick}
        disabled={disabled || isLoading}
        buttonText={buttonText}
        className={`message-center-button`}
        //   icon={
        //     isLoading ? (
        //       <LoadingOutlined style={{ fontSize: 12 }} />
        //     ) : isShowSendMethodType ? (
        //       <SVGSendMessageNew color={sendingMsg ? MainColor : '#6c757d'} />
        //     ) : !userAdditionalConfigsBtn ? (
        //       <SVGSendMessageNew
        //         width={16}
        //         height={16}
        //         color={sendingMsg ? '#fff' : 'rgba(255, 255, 255, 0.1)'}
        //       />
        //     ) : (
        //       <SVGPressEnterIcon width={16} height={12} />
        //     )
        //   }
      />

      <Divider type='vertical' />
      <MatSelect
        options={options || []}
        suffixIcon={<SVGArrowDown color={'#fff'} />}
        placement={placement || 'topRight'}
        onChange={(val) => {
          onDropDownChange && onDropDownChange(val)
        }}
      />
    </div>
  )
}

export default MatButtonWithDropDown
