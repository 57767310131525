import React from 'react'
import { Popover, Typography } from 'antd'
import { getText } from '../../../lang'
import MatButton from '../../../components/MatButton'

const { Title, Paragraph } = Typography

const GAInfo = () => {
  const content = () => {
    return (
      <div>
        <Title level={5}>{getText('GA_TRACK_PAGE_VIEWS_TITLE')}</Title>
        <Paragraph>{getText('GA_TRACK_PAGE_VIEWS_PARAGRAPH_1')}</Paragraph>
        <ul className='pl-4'>
          <li>
            <strong>{getText('GA_CATEGORY')}:</strong> {getText('GA_MATADOR')}
          </li>
          <li>
            <strong>{getText('GA_ACTION')}:</strong> {getText('GA_ACTION_DETAILS')}
          </li>
          <li>
            <strong>{getText('GA_LABEL')}:</strong> {getText('GA_SUBMISSION')}
          </li>
        </ul>
        <Paragraph>{getText('GA_TRACK_PAGE_VIEWS_PARAGRAPH_2')}</Paragraph>
        <Paragraph>{getText('GA_TRACK_PAGE_VIEWS_PARAGRAPH_3')}</Paragraph>
        <Paragraph>{getText('GA_TRACK_PAGE_VIEWS_PARAGRAPH_4')}</Paragraph>
      </div>
    )
  }

  return (
    <Popover
      content={content}
      title={getText('GA_GOOGLE_ANALYTICS_4_TRACKING')}
      style={{ padding: 16 }}
    >
      <MatButton
        typeButton='text'
        htmlType='button'
        buttonText={getText('GA_INFO_ABOUT_GA')}
        style={{ marginBottom: 50, backgroundColor: '#1677ff', color: '#fff' }}
      />
    </Popover>
  )
}

export default GAInfo
