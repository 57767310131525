import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = () => {
  return (
    <svg
      width='129'
      height='30'
      viewBox='0 0 1000 232'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_1_2)'>
        <path
          d='M579.8 177.6V195.2C574.5 196.4 569.1 197.4 562.6 197.4C541 197.4 526.8 187.1 526.8 163.6V116.1H511V98.1H526.8V70.5H547.8V98.1H576.7V116.1H547.8V161.8C547.8 174.7 554.9 178.6 567 178.6C570.5 178.6 574.6 178.2 579.8 177.6Z'
          fill='#2D2D66'
        />
        <path
          d='M377.8 62.5V195.2H356.8V113.3L319.4 162.3H312.3L274.7 113.1V195.2H253.7V62.5H261L315.8 135.5L370.6 62.5H377.8Z'
          fill='#2D2D66'
        />
        <path
          d='M496.1 98.1V195.2H475.9V182.7C468.6 192 456.3 197.3 442.7 197.3C415.2 197.3 395.2 175.5 395.2 146.7C395.2 117.8 415 95.9 442.7 95.9C457.1 95.9 469.6 101.8 476.7 111.9V98.1H496.1ZM475.5 146.7C475.5 124.6 460.7 114.9 446.2 114.9C429.4 114.9 416.3 128.1 416.3 146.7C416.3 165.5 429.4 178.3 446.2 178.3C460.7 178.4 475.5 168.7 475.5 146.7Z'
          fill='#2D2D66'
        />
        <path
          d='M688.2 98.1V195.2H668V182.7C660.7 192 648.4 197.3 634.8 197.3C607.3 197.3 587.3 175.5 587.3 146.7C587.3 117.8 607.1 95.9 634.8 95.9C649.2 95.9 661.7 101.8 668.8 111.9V98.1H688.2ZM667.6 146.7C667.6 124.6 652.8 114.9 638.3 114.9C621.5 114.9 608.4 128.1 608.4 146.7C608.4 165.5 621.5 178.3 638.3 178.3C652.8 178.4 667.6 168.7 667.6 146.7Z'
          fill='#2D2D66'
        />
        <path
          d='M688.2 98.1V195.2H668V182.7C660.7 192 648.4 197.3 634.8 197.3C607.3 197.3 587.3 175.5 587.3 146.7C587.3 117.8 607.1 95.9 634.8 95.9C649.2 95.9 661.7 101.8 668.8 111.9V98.1H688.2ZM667.6 146.7C667.6 124.6 652.8 114.9 638.3 114.9C621.5 114.9 608.4 128.1 608.4 146.7C608.4 165.5 621.5 178.3 638.3 178.3C652.8 178.4 667.6 168.7 667.6 146.7Z'
          fill='#2D2D66'
        />
        <path
          d='M806.7 64.1V195.2H787.3V181.4C780.2 191.5 767.7 197.4 753.3 197.4C725.6 197.4 705.8 175.6 705.8 146.8C705.8 117.9 725.8 96 753.3 96C766.7 96 778.4 101.1 785.7 110V64.1H806.7ZM786.1 146.7C786.1 124.8 771.3 114.9 756.8 114.9C740 114.9 726.9 127.8 726.9 146.5C726.9 165.3 739.8 178.3 756.8 178.3C771.3 178.4 786.1 168.9 786.1 146.7Z'
          fill='#2D2D66'
        />
        <path
          d='M824.1 146.5C824.1 118 846.6 95.9 875.3 95.9C904.2 95.9 926.9 118.2 926.9 146.5C926.9 174.8 904.2 197.3 875.3 197.3C846.7 197.3 824.1 174.8 824.1 146.5ZM905.6 146.7C905.6 128.5 892.5 114.9 875.5 114.9C858.1 114.9 845.2 128.5 845.2 146.7C845.2 164.7 858.1 178.1 875.3 178.1C892.5 178.1 905.6 164.5 905.6 146.7Z'
          fill='#2D2D66'
        />
        <path
          d='M1000 98.1V116.5H993.7C978.3 116.5 965.2 125.4 965.2 143.4V195.2H944.4V98.1H963.8V110.8C970.7 101.9 981 97.2 992.1 97.2C995.5 97.1 997.8 97.5 1000 98.1Z'
          fill='#2D2D66'
        />
        <path
          d='M195.9 1.2C194.8 -0.499999 192.2 -0.399999 191.2 1.5V1.6C190.9 2.2 190.8 3 191.1 3.7C192.6 8.1 193.5 12.9 193.5 17.9C193.5 41.8 174.3 61.2 150.4 61.4H55.7C31.9 61.2 12.6 41.8 12.6 17.9C12.6 12.9 13.4 8.2 15 3.7C15.2 3 15.2 2.3 14.9 1.6V1.5C14 -0.399999 11.4 -0.499999 10.3 1.2C3.8 10.8 0 22.4 0 34.8C0 57.1 12.1 76.6 30.1 87.1C33.6 89.1 37.2 90.8 41.1 92.1C30.8 105.4 24.7 122 24.7 140.1C24.7 161.8 33.5 181.4 47.7 195.6L78.4 226.3C81.5 229.4 85.7 231.2 90.1 231.2H116.3C120.7 231.2 124.9 229.4 128 226.3L158.7 195.6C172.9 181.4 181.7 161.7 181.7 140.1C181.7 122 175.5 105.3 165.2 92C169 90.7 172.6 89 176 87.1C194 76.6 206.1 57.1 206.1 34.8C206.1 22.4 202.3 10.8 195.9 1.2ZM135.5 171.2C100.8 206.9 100.8 206.9 100.8 206.9V185.1C89.5 184.6 79.2 179.8 71.7 172.4L70.9 171.6C63 163.5 58.1 152.4 58.1 140.2C58.1 127.8 63.1 116.6 71.3 108.5C79.4 100.4 90.6 95.3 103 95.3C115.4 95.3 126.6 100.3 134.7 108.5C142.8 116.6 147.8 127.8 147.8 140.2C147.9 152.3 143.3 163.2 135.5 171.2Z'
          fill='#706AFF'
        />
      </g>
      <defs>
        <clipPath id='clip0_1_2'>
          <rect width='1000' height='231.3' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

const SVGLoginLogo = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGLoginLogo
