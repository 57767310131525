import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='76'
    height='38'
    viewBox='0 0 76 38'
    fill='none'
  >
    <path
      d='M71 38C73.7614 38 76.0338 35.7519 75.6715 33.0144C75.2379 29.7378 74.3777 26.5247 73.1074 23.458C71.1977 18.8477 68.3987 14.6586 64.8701 11.1299C61.3414 7.60131 57.1523 4.80226 52.542 2.89258C47.9316 0.982897 42.9902 -1.60152e-06 38 -1.66103e-06C33.0098 -1.72054e-06 28.0684 0.982897 23.458 2.89258C18.8477 4.80226 14.6586 7.60131 11.1299 11.1299C7.60132 14.6586 4.80225 18.8477 2.89258 23.458C1.62231 26.5247 0.762114 29.7378 0.328475 33.0144C-0.0338303 35.7519 2.23858 38 5 38L38 38L71 38Z'
      fill='#F9FAFB'
    />
  </svg>
)

const SVGBackgroundEllipse = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGBackgroundEllipse
