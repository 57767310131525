import React from 'react'
import { Steps } from 'antd'
import { BROADCASTING_SUB_PAGES } from '../../../../../config'
import SVGChatCheck from '../../../../../icons/SVG/SVGChatCheck'
import authActions from '../../../../../store/modules/authActions'

const { Step } = Steps

const StepHeader = (props) => {
  const { current, deviceSize } = props

  if (!(deviceSize === 'tabletPortrait') && !(deviceSize === 'mobile')) {
    return (
      <Steps current={current} labelPlacement='vertical'>
        {BROADCASTING_SUB_PAGES(authActions.getLanguage()).map((item) => {
          return (
            <Step
              key={item.title}
              title={item.title}
              description={item.description}
              icon={<SVGChatCheck />}
            />
          )
        })}
      </Steps>
    )
  }

  return (
    <div className='mobile-steps-wrapper'>
      <div className='my-responsive-steps-wrapper'>
        <div className='my-responsive-step-icon' />
        <div className='my-responsive-step-text-wrapper'>
          <div className='my-responsive-step-text-steps'>
            <div>Step {current + 1}</div>
            <div>/{BROADCASTING_SUB_PAGES(authActions.getLanguage()).length}</div>
          </div>
          <div className='my-responsive-step-text-info'>
            <div>
              {BROADCASTING_SUB_PAGES(authActions.getLanguage())[current].title}
            </div>
          </div>
        </div>
      </div>
      <div className='my-responsive-steps-progress'>
        <div
          style={{
            width: `${Math.floor(
              (current + 1) *
                (100 / BROADCASTING_SUB_PAGES(authActions.getLanguage()).length)
            )}%`,
          }}
          className='my-responsive-steps-progress-line'
        ></div>
      </div>
    </div>
  )
}

export default StepHeader
