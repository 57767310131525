import React from 'react'
import SVGWorkflowLinears from '../../../../icons/SVG/SVGWorkflowLinears'

const WorkflowActionDone = ({ type }) => {
  return (
    <div className='add-workflow-action-wrapper'>
      <SVGWorkflowLinears
        positivecolor={type === 0 ? '#5BC78B' : '#E5E9F2'}
        negativecolor={type === 1 ? '#EE7B6C' : '#E5E9F2'}
        noreplycolor={type === 2 ? '#F9C735' : '#E5E9F2'}
      />
    </div>
  )
}

export default WorkflowActionDone
