import React, { useState } from 'react'
import dayjs from 'dayjs'
import MatDropMenu from '../../../../../components/MatDropMenu'
import { getText } from '../../../../../lang'
import SVGThreePoint from '../../../../../icons/SVG/SVGThreePoint'
import authActions from '../../../../../store/modules/authActions'
import ConfirmPopup from '../../../../../components/ConfirmPopup'
import MatSwitchSecondary from '../../../../../components/MatSwitch/MatSwitchSecondary'
import './MatadorConnectComponents.scss'

const MatadorConnectListItem = ({
  item,
  handleSwitch,
  handleEdit,
  style,
  hasSwitch,
  hasImage,
  greetingDefault,
  handleSetDefault,
}) => {
  const userLang = authActions.getLanguage()

  const [loading, setLoading] = useState(false)
  const realDateFormat = dayjs(item.createdAt).locale(userLang).format('D MMM, YYYY')

  return (
    <div style={style} className='matador-connect-list-one-item-wrapper'>
      <div className='matador-connect-image-title'>
        <div className='matador-connect-image-with-switch'>
          {hasSwitch && (
            <MatSwitchSecondary
              value={
                (item.status && item.status.toLowerCase() === 'active') ||
                item.enabled
              }
              onChange={(checked) => {
                setLoading(true)
                handleSwitch && handleSwitch(checked, item)
              }}
              loading={loading}
            />
          )}
          {hasImage && (
            <img
              src={item.thumbnailUrl}
              alt={item.title && item.title[0] && item.title[0].text}
              className='item-image'
            />
          )}
        </div>
        {(item.title && item.title[0].text) ||
          (item.question && item.question['en']) ||
          (item.name && item.name)}
      </div>
      <div className='matador-connect-date-title'>{realDateFormat}</div>
      <div className='matador-connect-action-container'>
        {greetingDefault && (
          <>
            {item.isDefault ? (
              <p className='matador-connect-greeting-default current'>
                {getText('WORD_DEFAULT')}
              </p>
            ) : (
              <ConfirmPopup
                placement={'topRight'}
                title={getText('CONFIRMATION_SET_DEFAULT_GREETING')}
                cancelText={getText('ACTION_CANCEL')}
                okText={getText('TEXT_SET_AS_DEFAULT')}
                onConfirm={() => {
                  handleSetDefault && handleSetDefault(item)
                }}
                trigger={
                  <p
                    className={`matador-connect-greeting-default ${
                      item.enabled && 'active'
                    }`}
                  >
                    {getText('TEXT_SET_AS_DEFAULT')}
                  </p>
                }
              />
            )}
          </>
        )}
        <MatDropMenu
          placement={'bottomRight'}
          overlayClassName='drop-menu-auto-detect-language-dropdown'
          showArrow={false}
          overlayStyle={{ width: '100px' }}
          options={[
            {
              name: getText('ACTION_EDIT'),
              value: 'edit',
            },
            {
              name: getText('ACTION_DELETE'),
              value: 'delete',
            },
          ]}
          onChange={(val) => {
            handleEdit && handleEdit(val, item)
          }}
          trigger={
            <div className='matador-connect-list-edit-delete'>
              <SVGThreePoint />
            </div>
          }
        />
      </div>
    </div>
  )
}

export default MatadorConnectListItem
