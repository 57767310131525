import React from "react";
import Icon from "@ant-design/icons";

const SVGIcon = (props) => (
  <svg
    width={props.width || "10"}
    height={props.height || "10"}
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.45343 4.21919H9.454H5.78177V0.553874C5.78177 0.252219 5.53752 0 5.23587 0H4.76599C4.46441 0 4.2191 0.252219 4.2191 0.553874V4.21919H0.546963C0.245471 4.21919 0 4.46311 0 4.76485V5.23741C0 5.53891 0.245389 5.78177 0.546963 5.78177H4.21919V9.46034C4.21919 9.76183 4.46441 10.0009 4.76607 10.0009H5.23595C5.53761 10.0009 5.78186 9.76175 5.78186 9.46034V5.78177H9.45343C9.75508 5.78177 10.001 5.53882 10.001 5.23741V4.76485C10.001 4.46311 9.75508 4.21919 9.45343 4.21919Z"
      fill={props.color || "#77808D"}
    />
  </svg>
);

const SVGCrossIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />;
      }}
      {...props}
    />
  );
};

export default SVGCrossIcon;
