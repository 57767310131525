import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width={props.width || '22'}
      height={props.height || '22'}
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.05557 12.2754C6.46453 12.2754 5.98438 11.7952 5.98438 11.2042C5.98438 10.6149 6.46453 10.1348 7.05557 10.1348C7.6466 10.1348 8.12676 10.6149 8.12676 11.2042C8.12676 11.7952 7.6466 12.2754 7.05557 12.2754ZM11.2199 12.2754C10.6288 12.2754 10.1487 11.7952 10.1487 11.2042C10.1487 10.6149 10.6288 10.1348 11.2199 10.1348C11.8109 10.1348 12.291 10.6149 12.291 11.2042C12.291 11.7952 11.8109 12.2754 11.2199 12.2754ZM14.313 11.2042C14.313 11.7952 14.7932 12.2754 15.3842 12.2754C15.9753 12.2754 16.4554 11.7952 16.4554 11.2042C16.4554 10.6149 15.9753 10.1348 15.3842 10.1348C14.7932 10.1348 14.313 10.6149 14.313 11.2042Z'
        fill={props.color || '#0E0E0E'}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.019 1.83398C5.69315 1.83398 1.83398 6.18459 1.83398 11.0144C1.83398 12.5567 2.28315 14.144 3.07148 15.5945C3.21815 15.8342 3.23648 16.1362 3.13565 16.4217L2.52148 18.4781C2.38398 18.9739 2.80565 19.3402 3.27315 19.1933L5.12482 18.6434C5.62898 18.4781 6.02315 18.6884 6.49065 18.9739C7.82898 19.7625 9.49732 20.1673 11.0007 20.1673C15.5473 20.1673 20.1673 16.6512 20.1673 10.9869C20.1673 6.10197 16.2257 1.83398 11.019 1.83398Z'
        stroke={props.color || '#0E0E0E'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

const SVGOrgSMSIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGOrgSMSIcon
