import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Layout } from 'antd'
import { useSelector } from 'react-redux'

const { Content } = Layout

const OuterLayout = (props) => {
  const [screenSize, setScreenSize] = useState('desktop')

  const location = useLocation()

  const isMobileApp = useSelector((state) => {
    return state.authReducer.isMobileApp
  })

  const checkScreenSize = () => {
    let cw = window.innerWidth

    let screen = 'desktop'
    if (cw <= 480) {
      screen = 'mobile'
    } else if (cw <= 767) {
      screen = 'tabletPortrait'
    } else if (cw <= 991) {
      screen = 'tablet'
    } else if (cw <= 1190) {
      screen = 'tabletLandscape'
    } else if (cw <= 1400) {
      screen = 'messageRightDrawer'
    }
    setScreenSize(screen)
  }

  useEffect(() => {
    window.addEventListener('resize', checkScreenSize)
    checkScreenSize()
  }, [])

  const getUrlSearchParams = () => {
    const searchParamsRaw = new URLSearchParams(location.search)
    return Object.fromEntries(searchParamsRaw)
  }

  return (
    <Layout
      hasSider
      className={`mat-main-layout mat-outer-layout ${
        isMobileApp ? 'mat-mobile-outer-layout' : ''
      }`}
    >
      <Layout className='mat-layout'>
        <Content className='mat-layout-content'>
          {props.children &&
            React.cloneElement(props.children, {
              urlSearchParams: getUrlSearchParams(),
              screenSize: screenSize,
            })}
        </Content>
      </Layout>
    </Layout>
  )
}

export default OuterLayout
