import React, { useCallback, useState } from 'react'
import { debounce, isArray, isEmpty } from 'lodash'
import { Badge, Drawer, Tag } from 'antd'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { getText } from '../../../lang'
import { MainColor } from '../../../utils'
import SVGHamburgerFilterIcon from '../../../icons/SVG/SVGHamburgerFilterIcon'
import SVGChatClose from '../../../icons/SVG/SVGChatClose'
import SVGAppointmentDateIcon from '../../../icons/SVG/SVGAppointmentDateIcon'
import MatButton from '../../../components/MatButton'
import SelectFilter from '../../../components/api/SelectFilter'
import MatRangePickerSecondary from '../../../components/MatRangePicker/MatRangePickerSecondary'

const deviceSizes = ['desktop', 'messageRightDrawer', 'tabletLandscape']
const deviceSizesForFilterTags = ['mobile', 'tabletPortrait', 'tablet']

const BroadcastListHeader = ({
  totalCount,
  onFiltersChange,
  screenSize,
  automation,
}) => {
  const navigate = useNavigate()
  const isMobileView = screenSize === 'mobile'

  const [open, setOpen] = useState(false)
  const [selectedFilters, setSelectedFilters] = useState({})

  const debouncedChange = useCallback(
    debounce((options) => onFiltersChange(options), 500),
    []
  )

  const options = [
    {
      label: getText('WORD_SENT'),
      value: 'sent',
    },
    {
      label: getText('WORD_IN_PROGRESS'),
      value: 'in_progress',
    },
    {
      label: getText('WORD_NOT_SENT'),
      value: 'not_sent',
    },
    {
      label: getText('WORD_CANCELLED'),
      value: 'cancelled',
    },
  ]

  const handleFitlersChange = (value, stateName, label) => {
    if (stateName === 'status') {
      const obj = {
        ...selectedFilters,
        statusFilter: { label: label, value: value },
      }
      setSelectedFilters(obj)
      debouncedChange(obj)
    } else {
      const obj = { ...selectedFilters, dateFilter: { label: label, value: value } }
      setSelectedFilters(obj)
      debouncedChange(obj)
    }
  }

  const handleRemoveFilter = (filterValue) => {
    const updatedFilters = { ...selectedFilters }
    const filterKey = Object.keys(updatedFilters).find(
      (key) => updatedFilters[key] === filterValue
    )

    if (filterKey) {
      delete updatedFilters[filterKey]
      setSelectedFilters(updatedFilters)
      debouncedChange(updatedFilters)
    }
  }

  const selectFilters = () => {
    return (
      <React.Fragment>
        <SelectFilter
          option={options}
          placeholder={getText('TEXT_FILTER_BY_STATUS')}
          allowClear={true}
          showSearch={false}
          value={
            !isEmpty(selectedFilters)
              ? selectedFilters.statusFilter && selectedFilters.statusFilter.value
              : undefined
          }
          onClear={() => {
            if (Boolean(selectedFilters.statusFilter)) {
              handleRemoveFilter(selectedFilters.statusFilter)
            }
          }}
          onChangeItem={(value, item) =>
            handleFitlersChange(value, 'status', item.label)
          }
        />
        <MatRangePickerSecondary
          allowEmpty={[true, true]}
          inputReadOnly={screenSize === 'mobile'}
          label={''}
          value={
            !isEmpty(selectedFilters)
              ? selectedFilters.dateFilter &&
                selectedFilters.dateFilter.value.map((item) =>
                  item === '' ? item : dayjs(item, 'YYYY-MM-DD')
                )
              : undefined
          }
          suffixIcon={<SVGAppointmentDateIcon />}
          onChange={(date, val) => {
            if (!val[0] && !val[1]) {
              if (Boolean(selectedFilters.dateFilter)) {
                handleRemoveFilter(selectedFilters.dateFilter)
              }
            } else {
              handleFitlersChange(val, 'dateFilter')
            }
          }}
        />
      </React.Fragment>
    )
  }

  return (
    <>
      <div className='broadcast-list-header'>
        <div className='left-content'>
          <p>{getText('WORD_TOTAL_CAMPAIGNS')}</p>
          <span>{totalCount}</span>
        </div>
        <div className='right-content'>
          {deviceSizes.includes(screenSize) ? (
            selectFilters()
          ) : (
            <React.Fragment>
              <MatButton
                className={'broadcast-header-button'}
                icon={
                  <Badge
                    placement={'end'}
                    count={Object.keys(selectedFilters).length}
                    color={'#5b66ea'}
                  >
                    <SVGHamburgerFilterIcon color={MainColor} />
                  </Badge>
                }
                onClick={() => {
                  setOpen(true)
                }}
              />

              <Drawer
                title={getText('WORD_FILTERS')}
                placement={isMobileView ? 'bottom' : 'right'}
                onClose={() => {
                  setOpen(false)
                }}
                closeIcon={<SVGChatClose color={'#A3A6BE'} stroke={2} />}
                open={open}
                rootClassName='broadcast-list-drawer'
              >
                {selectFilters()}
              </Drawer>
            </React.Fragment>
          )}
          {!Boolean(automation) && (
            <MatButton
              buttonText={isMobileView ? '+' : getText('WORD_CREATE_CAMPAIGN')}
              onClick={() => {
                navigate('/broadcasting/create-campaign')
              }}
            />
          )}
        </div>
      </div>
      {deviceSizesForFilterTags.includes(screenSize) &&
      Object.values(selectedFilters) ? (
        <div className='list-variables'>
          {Object.values(selectedFilters).map((item, index) => {
            if (isArray(item.value)) {
              return (
                <Tag
                  className='add-variable'
                  closable
                  onClose={() => handleRemoveFilter(item)}
                  key={index}
                >
                  {item.value[1]
                    ? `${item.value[0]} - ${item.value[1]}`
                    : `${item.value[0]}`}
                </Tag>
              )
            } else {
              return (
                <Tag
                  className='add-variable'
                  closable
                  onClose={() => handleRemoveFilter(item)}
                  key={index}
                >
                  {item.label}
                </Tag>
              )
            }
          })}
        </div>
      ) : null}
    </>
  )
}

export default BroadcastListHeader
