import React from 'react'
import UploadFileForm from '../../../../components/Form/UploadFileForm'
import { getText } from '../../../../lang'
import SVGStoriesImageCloseIcon from '../../../../icons/SVG/SVGStoriesImageCloseIcon'
import SVGStoriesUploadFileIcon from '../../../../icons/SVG/SVGStoriesUploadFileIcon'
import MatBlockWithLabel from '../../../../components/MatBlockWithLabel'

const Dropzone = ({
  title,
  onChange,
  note,
  mediaTypes,
  mediaState,
  mediaSetState,
  placeholder,
}) => {
  return (
    <MatBlockWithLabel black label={title}>
      <UploadaFile
        title={title}
        mediaState={mediaState}
        handleChange={onChange}
        note={note}
        mediaTypes={mediaTypes}
        mediaSetState={mediaSetState}
        placeholder={placeholder}
      />
    </MatBlockWithLabel>
  )
}

const UploadaFile = ({
  handleChange,
  note,
  mediaTypes,
  mediaState,
  title,
  mediaSetState,
  placeholder,
}) => {
  return (
    <UploadFileForm
      className={'big-upload-wrapper'}
      mediaTypes={mediaTypes}
      maxCount={1}
      size={20}
      videoSize={20}
      beforeUploadProps
      storiesVideos
      showUploadList={false}
      onUpload={handleChange}
      isPreview={mediaTypes && !mediaTypes.includes('video')}
      modalTitle={getText('ACTION_EDIT') + ' ' + title}
    >
      {mediaState.file && (
        <div
          className='upload-image-delete-icon'
          onClick={(e) => {
            mediaSetState({
              fileUploadPage: true,
              file: '',
            })
            e.stopPropagation()
          }}
        >
          <SVGStoriesImageCloseIcon />
        </div>
      )}
      {mediaState.file ? (
        mediaTypes && mediaTypes.includes('video/') ? (
          <VideoPreview videoUrl={mediaState.file} />
        ) : (
          <div className='uploaded-media'>
            <img src={mediaState.file} alt={'logo'} />
          </div>
        )
      ) : (
        <div className='empty-dropzone-state'>
          <SVGStoriesUploadFileIcon />
          <p>
            {getText('TEXT_DRAG_A_FILE_HERE')} {getText('WORD_OR')}{' '}
            <span>{getText('WORD_BROWSE')}</span>{' '}
            {getText('TEXT_FOR_A_FILE_TO_UPLOAD')}
            {placeholder && <p>{placeholder}</p>}
          </p>
          <p>{note}</p>
        </div>
      )}
    </UploadFileForm>
  )
}

const VideoPreview = ({ videoUrl }) => {
  return (
    <div className='uploaded-media'>
      <video controls>
        <source src={videoUrl} />
        {getText('ERROR_SORRY_YOUR_BROWSER_DOESNT_SUPPORT_EMBEDDED_VIDEOS')}
      </video>
    </div>
  )
}

export default Dropzone
