import React, { memo, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import SVGLanguage from '../../../../../icons/SVG/SVGLanguage'
import { getText } from '../../../../../lang'
import customerActions from '../../../../../store/modules/customerActions'
import { getLongName } from '../../../../../utils'
import { notifyError, notifySuccess } from '../../../../../utils/Notify'
import MatDropMenu from '../../../../../components/MatDropMenu'
import MatRow from '../../../../../components/MatRow'

const MessageTabRightSelectLanguage = (props) => {
  const { conversation, onSaveReciver } = props

  const [listOrgLanguageOptions, setListOrgLanguageOptions] = useState([])
  let receiver = conversation.receiver ? conversation.receiver : {}

  const organization = useSelector((state) => {
    return state.authReducer.user.organization
  })

  useEffect(() => {
    setListOrgLanguageOptions(
      organization &&
        organization.languages.map((oo) => {
          return {
            value: oo,
            name: getLongName(oo),
            checked: receiver && receiver.language === oo,
          }
        })
    )
  }, [organization, receiver.language])

  const handleOnChange = async (language) => {
    if (receiver) {
      if (conversation.isNew) {
        conversation.isSetDataReciverOnlyLang = true
        conversation.receiver.language = language
        onSaveReciver && onSaveReciver(conversation.receiver)
      } else {
        let result = await customerActions.changeLanguage(language, receiver._id)
        if (result.success) {
          onSaveReciver && onSaveReciver(result.data)
          notifySuccess(getText('NTF_SUCCESS_SAVE_CUSTOMER'))
        } else {
          notifyError(result.errMsg)
        }
      }
    }
  }

  return (
    <MatRow className='tab-info-item-wrapper'>
      <MatRow className='tab-info-icon-with-text'>
        <SVGLanguage />
        <p className='drop-menu-label'>{getText('WORD_LANGUAGE')}</p>
      </MatRow>
      {conversation && !conversation.isNew && receiver.language_auto_detected && (
        <div className='tab-info-auto-detected-language'>
          <div className='message-block'>
            <p>{getText('TEXT_AUTO_DETECTED') + ':'}</p>
            {/* {<span>{getLongName(receiver.language_auto_detected)}</span>} */}
          </div>
        </div>
      )}
      <MatDropMenu
        options={listOrgLanguageOptions}
        autoAdjustOverflow={true}
        onChange={handleOnChange}
        overlayClassName='drop-menu-auto-detect-language-dropdown'
        getPopupContainer={() =>
          document.querySelector('.message_right_panel--top-side')
        }
        trigger={
          <div className='drop-menu-trigger-tab'>
            <div className='drop-menu-label-value'>
              <span className='drop-menu-value'>
                {getLongName(receiver.language)}
              </span>
            </div>
          </div>
        }
      />
    </MatRow>
  )
}

export default memo(MessageTabRightSelectLanguage)
