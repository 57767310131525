import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { formatDistanceToNow } from 'date-fns'
import organizationActions from '../../../../store/modules/organizationActions'
import CenterMessageItem from '../../../messages/center/centerMessageBody/CenterMessage/CenterMessageItem'
import { notifyError } from '../../../../utils/Notify'
import { getText, getTextServerError } from '../../../../lang'
import Logo from './assets/superhuman.png'
import SendImg from './assets/send_button.png'
import CheckIcon from './assets/check 2.svg'

const ChatContainer = styled.div`
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  max-width: 768px;
  margin: auto;
`

const MessageList = styled.div`
  padding: 20px;
  height: 500px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`

const Message = styled.div`
  background-color: ${(props) =>
    props.type === 'received' ? '#5B66EA' : '#E5E9F2'};
  color: ${(props) => (props.type === 'received' ? '#ffffff' : '#0E0E0E')};
  padding: 12px 20px;
  word-break: break-word;
  margin-bottom: 5px;
  max-width: 75%;
  align-self: ${(props) => (props.type === 'received' ? 'flex-end' : 'flex-start')};
  font-size: 14px;
  border-radius: ${(props) =>
    props.type === 'received' ? '16px 16px 0px 16px' : '16px 16px 16px 0px'};
`

const Timestamp = styled.div`
  color: #999999;
  font-size: 10px;
  text-align: ${(props) => (props.type === 'received' ? 'right' : 'left')};
  margin-bottom: 12px;
`

const InputArea = styled.div`
  display: flex;
  padding: 12px;
  background-color: white;
`

const Input = styled.input`
  flex-grow: 1;
  border-radius: 18px;
  padding: 10px;
  border: 1px solid #dcdcdc;
  margin-right: 12px;
`

const SendIcon = styled.img`
  width: 40px;
  height: 40px;
  cursor: pointer;
`

const Header = styled.div`
  background-color: #007bff;
  color: white;
  padding: 28px 24px;
  font-size: 16px;
  display: flex;
  align-items: center;
`

const HeaderTitle = styled.h1`
  font-size: 18px;
  margin: 0;
`

const Note = styled.h3`
  font-size: 16px;
  margin: 0;
`

const LogoImg = styled.img`
  margin-right: 12px;
`

const TypingNote = styled.p`
  color: #a3a6be;
  font-size: 14px;
  padding: 0 10px;
`

const ClearConversation = styled.p`
  text-align: right;
  padding: 10px;
  margin: 0;
  color: #007bff;
  cursor: pointer;
`

const Label = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: #5b66ea;
  text-align: ${(props) => (props.type === 'ai_reply' ? 'left' : 'right')};
  margin-bottom: 2px;
`

const initialData = [
  {
    from: 'organization-number',
    to: 'customer-number',
    type: 'ai_reply',
    createdAt: new Date().toISOString(),
    body: 'Hello! Do you need help?',
  },
]
const ConversationPreview = ({ config, organizationId, locationId }) => {
  const [messages, setMessages] = useState(initialData)
  const [currentMessage, setCurrentMessage] = useState('')
  const [isTyping, setIsTyping] = useState(false)

  useEffect(() => {
    if (messages.length > 0) {
      const element = document.getElementById('messages-list')
      element.scrollTop = element.scrollHeight
    }
  }, [messages])

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      sendMessage()
    }
  }

  const sendMessage = async () => {
    if (!currentMessage.trim()) return

    setIsTyping(true)

    const items = messages
      .filter((x) => x.body)
      .map((message) => {
        return {
          from: message.from,
          to: message.to,
          type: message.type,
          createdAt: message.createdAt,
          body: message.body,
          ...(message.bodyOriginal && { bodyOriginal: message.bodyOriginal }),
        }
      })

    const payload = {
      locationId: locationId,
      messages: [
        ...items,
        {
          from: 'organization-number',
          to: 'customer-number',
          type: 'received',
          createdAt: new Date().toISOString(),
          body: currentMessage,
        },
      ],
      superhumanConfig: config,
    }

    try {
      const myMessage = {
        from: 'organization-number',
        to: 'customer-number',
        type: 'received',
        createdAt: new Date().toISOString(),
        body: currentMessage,
      }

      setMessages((messages) => [...messages, myMessage])
      setCurrentMessage('')

      const response = await organizationActions.sendMessage(payload, organizationId)

      if (!response.success) {
        return notifyError(getTextServerError(response.errMsg))
      }

      const newMessage = {
        from: 'organization-number',
        to: 'customer-number',
        type: 'ai_reply',
        createdAt: new Date().toISOString(),
        body: response.data.message,
        users_to_tag: response.data.users_to_tag,
        ...(response.data.bodyOriginal && {
          bodyOriginal: response.data.bodyOriginal,
        }),
      }

      setMessages((messages) => [...messages, newMessage])
      setIsTyping(false)
    } catch (error) {
      console.error('Error sending message:', error)
      setIsTyping(false)
    }
  }

  const clearConversation = () => {
    setMessages(initialData)
  }

  const renderMessage = (message, index) => {
    let tagContent = null
    let messageContent = null

    if (message.users_to_tag && message.users_to_tag.length > 0) {
      tagContent = (
        <CenterMessageItem
          index={index}
          message={{
            createdAt: new Date().toISOString(),
          }}
          messageTitle={`${getText('WORD_SUPERHUMAN_TAGGED')} ${
            message.users_to_tag.map((user) => user).join(', ') || ''
          }`}
          icon={'@'}
        />
      )
    }

    if (message.body) {
      messageContent = (
        <>
          <Label type={message.type}>
            {message.type === 'ai_reply'
              ? getText('AI_BOT_SUPERHUMAN')
              : getText('WORD_YOU')}
          </Label>
          <Message type={message.type}>{message.body}</Message>
          <Timestamp type={message.type}>
            {message.type === 'ai_reply' && (
              <img
                src={CheckIcon}
                alt='Check'
                width='12'
                height='12'
                style={{ marginRight: '5px' }}
              />
            )}
            {formatDistanceToNow(new Date(message.createdAt), { addSuffix: true })}
            {message.type === 'received' && (
              <img
                src={CheckIcon}
                alt='Check'
                width='12'
                height='12'
                style={{ marginLeft: '5px' }}
              />
            )}
          </Timestamp>
        </>
      )
    }

    return (
      <React.Fragment key={index}>
        {messageContent}
        {tagContent}
      </React.Fragment>
    )
  }

  return (
    <ChatContainer>
      <ClearConversation onClick={clearConversation}>
        {getText('WORD_CLEAR_CONVERSATION')}
      </ClearConversation>
      <Header>
        <LogoImg src={Logo} alt='Superhuman' width='46' height='46' />
        <div>
          <HeaderTitle>{getText('AI_BOT_SUPERHUMAN')}</HeaderTitle>
          <Note>{getText('TEXT_GIVE_YOUR_SUPERHUMAN_CONFIGURATION_TRY')}</Note>
        </div>
      </Header>
      <MessageList id='messages-list'>
        {messages.map((message, index) => renderMessage(message, index))}
      </MessageList>
      <TypingNote style={{ visibility: isTyping ? 'visible' : 'hidden' }}>
        {getText('TEXT_SUPERHUMAN_IS_THINKING')}...
      </TypingNote>
      <InputArea>
        <Input
          placeholder={`${getText('TEXT_TYPE_MESSAGE_TO_SMS_TO_USER')}...`}
          value={currentMessage}
          onChange={(e) => setCurrentMessage(e.target.value)}
          onKeyPress={handleKeyPress}
        />
        <div>
          <SendIcon
            src={SendImg}
            style={{ opacity: currentMessage ? 1 : 0.5 }}
            alt='Send'
            width='40'
            height='40'
            onClick={sendMessage}
          />
        </div>
      </InputArea>
    </ChatContainer>
  )
}

export default ConversationPreview
