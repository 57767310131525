import React from 'react'
import { Textfit } from 'react-textfit'

const deviceSize = ['tabletPortrait', 'mobile']

const VideoStatsItem = ({ value, title, color, icon, secondIcon, screenSize }) => {
  return (
    <div className={`video-stats-item ${color}`}>
      <div className='video-stats-item-value'>
        {icon}
        <Textfit
          max={54}
          min={2}
          style={{
            width:
              screenSize === 'tablet'
                ? '100px'
                : deviceSize.includes(screenSize)
                  ? '200px'
                  : '150px',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
          }}
          forceSingleModeWidth={true}
          mode='single'
          className='text-fit'
        >
          {value}
        </Textfit>
        <h6>{title}</h6>
      </div>
      {secondIcon}
    </div>
  )
}

export default VideoStatsItem
