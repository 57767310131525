import { getText } from '../../lang'
import { fetchFromUrlPOSTAsync, fetchFromUrlGETAsync } from '../../utils/UrlHelper'

const nylasUrl = '/nylas'

const fs = {
  connectNylasAccountForUser: async (dataToSend) => {
    const URL = `${nylasUrl}/auth/url/user?email=${dataToSend.email}`

    return fetchFromUrlGETAsync(URL, [])
  },

  connectNylasAccountForOrganization: async (organizationId, dataToSend) => {
    const URL = `${nylasUrl}/auth/url/location/${organizationId}?email=${dataToSend.email}`

    return fetchFromUrlGETAsync(URL, [])
  },

  disconnectNylasAccountForUser: async () => {
    const URL = `${nylasUrl}/auth/disconnect`

    const result = await fetchFromUrlPOSTAsync(URL, [])

    if (!result.success) {
      throw new Error(result.errMsg || getText('WORD_UNKNOWN_ERROR'))
    }

    return result.data
  },

  disconnectNylasAccountForOrganization: async (organizationId) => {
    const URL = `${nylasUrl}/auth/disconnect/location/${organizationId}`

    const result = await fetchFromUrlPOSTAsync(URL, [])

    if (!result.success) {
      throw new Error(result.errMsg || getText('WORD_UNKNOWN_ERROR'))
    }

    return result.data
  },
}

const loc = {}

const nylasActions = Object.assign(fs, loc)

export default nylasActions
