import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width='15'
    height='16'
    viewBox='0 0 15 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M9.66457 4.11145L5.22012 8.55589C4.60647 9.16954 4.60647 10.1645 5.22012 10.7781C5.83377 11.3918 6.8287 11.3918 7.44235 10.7781L11.8868 6.33367C13.1141 5.10637 13.1141 3.11652 11.8868 1.88923C10.6595 0.661925 8.66965 0.661925 7.44235 1.88923L2.9979 6.33367C1.15695 8.17462 1.15695 11.1594 2.9979 13.0003C4.83885 14.8413 7.82362 14.8413 9.66457 13.0003L14.109 8.55589'
      stroke={props.color || '#77808D'}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

const SVGMessageAtachNew = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGMessageAtachNew
