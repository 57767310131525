import React from 'react';
import Icon from '@ant-design/icons';

const SVGIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="10.75" height="10.432" viewBox="0 0 5.75 4.432">
        <path id="Path_6118" data-name="Path 6118" d="M5.343,14.444v-.853a.227.227,0,0,0-.363-.181l-2.39,1.767a.227.227,0,0,0,0,.363l2.39,1.767a.227.227,0,0,0,.363-.181v-.865A2.945,2.945,0,0,1,7.872,17.7a.2.2,0,0,0,.375-.127A3.217,3.217,0,0,0,5.343,14.444Z" transform="translate(-2.5 -13.365)" fill="#FFF" />
    </svg>
);

const SVGReplyIcon = props => <Icon component={SVGIcon} {...props} />;

export default SVGReplyIcon;