import React, { forwardRef } from 'react'
import { Tag, TreeSelect } from 'antd'
import './MatTreeSelect.scss'

const { SHOW_PARENT } = TreeSelect

const MatTreeSelectSecondary = forwardRef((props, ref) => {
  const tagRender = (propsTag) => {
    const { label, value, closable, onClose } = propsTag
    const onPreventMouseDown = (event) => {
      event.preventDefault()
      event.stopPropagation()
    }
    const selectedTag = props.treeData.find((item) => item.value === value)
    return (
      <Tag
        color={selectedTag ? 'red' : 'blue'}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
      >
        <div className='mat-tag-label'>{label}</div>
      </Tag>
    )
  }

  return (
    <div className='mat-select-wrapper-secondary' style={props.style}>
      {props.label ? <label className='select-label'>{props.label}</label> : null}
      <div
        className={
          'mat-select-sub-wrapper ' +
          (props.icon ? 'select-with-icon ' : '') +
          (props.primary ? 'primary ' : '') +
          (props.secondary ? 'secondary ' : '')
        }
      >
        {props.icon && <div className='select-left-icon'>{props.icon}</div>}
        <TreeSelect
          className={`mat-select ${props.className || ''} ${
            props.multipleNewStyle ? 'mat-multiple-select' : ''
          }`}
          ref={ref}
          treeCheckable={props.treeCheckable}
          placeholder={props.placeholder}
          loading={props.loading}
          showCheckedStrategy={SHOW_PARENT}
          disabled={props.disabled}
          getPopupContainer={(trigger) => trigger.parentNode}
          filterTreeNode={
            props.hasOwnProperty('filterOption')
              ? props.filterOption
              : (input, option) => {
                  return (
                    option.title
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toString().toLowerCase()) > -1
                  )
                }
          }
          treeData={props.treeData}
          tagRender={tagRender}
          onChange={props.onChange}
          defaultValue={props.defaultValue}
          treeDefaultExpandAll={props.treeDefaultExpandAll}
          value={props.value}
          popupClassName={`mat-select-tree-dropdown ${props.popupClassName || ''}`}
        />
      </div>
    </div>
  )
})

export default MatTreeSelectSecondary
