import React, { memo } from 'react'
import _ from 'lodash'
import Slider from 'react-slick'
import { useSelector } from 'react-redux'
import SVGArrowLeftIcon from '../../../../../icons/SVG/SVGArrowLeftIcon'
import SVGArrowRightIcon from '../../../../../icons/SVG/SVGArrowRightIcon'
import SVGVehicleInterestIcon from '../../../../../icons/SVG/SVGVehicleInterestIcon'
import { getText } from '../../../../../lang'
import MatRow from '../../../../../components/MatRow'
import { formatVehiclePrice, usdCurrencyFormat } from '../../../../../utils'
import utilsLocalizations from '../../../../../utils/utilsLocalizations'

const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
  <button
    {...props}
    className={
      'slick-next slick-arrow' +
      (currentSlide === slideCount - 1 ? ' slick-disabled' : '')
    }
    aria-hidden='true'
    aria-disabled={currentSlide === slideCount - 1 ? true : false}
    type='button'
  >
    <SVGArrowRightIcon />
  </button>
)
const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
  <button
    {...props}
    className={
      'slick-prev slick-arrow' + (currentSlide === 0 ? ' slick-disabled' : '')
    }
    aria-hidden='true'
    aria-disabled={currentSlide === 0 ? true : false}
    type='button'
  >
    <SVGArrowLeftIcon />
  </button>
)

const MessageTabRightVehicleInterest = () => {
  let vehicleOfInterest = useSelector((state) => {
    return state.authReducer.vehicleOfInterest
  })

  const vehicleOfInterestList = !_.isEmpty(vehicleOfInterest)
    ? Object.values(vehicleOfInterest.data).flat()
    : []

  const groupSize = 1
  const settings = {
    dots: false,
    infinite: vehicleOfInterestList?.length > 1 ? true : false,
    arrows: vehicleOfInterestList?.length > 1 ? true : false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SlickArrowLeft />,
    prevArrow: <SlickArrowRight />,
  }

  return !_.isEmpty(vehicleOfInterestList) ? (
    <div
      className='tab-info-interests-slider'
      style={{
        borderTop: 'none',
      }}
    >
      <Slider {...settings}>
        {vehicleOfInterestList
          .map((obj, index) => {
            return (
              <MatRow key={index} flexStart className='tab-info-icon-with-text'>
                <SVGVehicleInterestIcon />
                <div className='tab-info-touchpoints'>
                  <p className='drop-menu-label'>
                    {getText('WORD_VEHICLE_OF_INTEREST')} (
                    {utilsLocalizations.localizedText(
                      obj.type.replace('trade-in', 'sell')
                    )}
                    )
                  </p>
                  <>
                    <div className='vehicle-name'>{obj.title}</div>
                    <div className='vehicle-id'>
                      {obj.price && obj.price.value && (
                        <span>{formatVehiclePrice(obj.price.value + '')}</span>
                      )}
                      {obj.stock}
                    </div>
                    <div className='vehicle-id'>{obj.vin}</div>
                    {obj.extraInfo && obj.extraInfo.kbb_offer && (
                      <>
                        <div className='vehicle-name'>
                          {getText('WORD_OFFER_AMOUNT')}
                        </div>
                        <div className='vehicle-id'>
                          {usdCurrencyFormat(obj.extraInfo.kbb_offer)}
                        </div>
                      </>
                    )}
                    {obj.extraInfo && obj.extraInfo.price_estimate && (
                      <>
                        <div className='vehicle-name'>
                          {getText('WORD_ESTIMATE_PRICE')}
                        </div>
                        <div className='vehicle-id'>
                          {usdCurrencyFormat(obj.extraInfo.price_estimate)}
                        </div>
                      </>
                    )}
                  </>
                </div>
              </MatRow>
            )
          })
          .reduce((r, element, index) => {
            index % groupSize === 0 && r.push([])
            r[r.length - 1].push(element)
            return r
          }, [])
          .map((rowContent, index) => {
            return (
              <div key={index} className='slider-row'>
                {rowContent}
              </div>
            )
          })}
      </Slider>
    </div>
  ) : (
    <div
      className='tab-info-interests-slider'
      style={{
        borderTop: 'none',
      }}
    >
      <MatRow flexStart className='tab-info-icon-with-text'>
        <SVGVehicleInterestIcon />
        <div className='tab-info-touchpoints'>
          <p className='drop-menu-label'>{getText('WORD_VEHICLE_OF_INTEREST')}</p>
        </div>
      </MatRow>
    </div>
  )
}

export default memo(MessageTabRightVehicleInterest)
