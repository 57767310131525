import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={props.width || '5.079'}
    height={props.height || '4.895'}
    viewBox='0 0 5.079 4.895'
  >
    <g id='Group_2116' data-name='Group 2116' transform='translate(0.424 0.424)'>
      <g id='Group_1859' data-name='Group 1859' transform='translate(0)'>
        <line
          id='Line_303'
          data-name='Line 303'
          x2='4.23'
          y2='4.046'
          fill='none'
          stroke='#747b95'
          strokeLinecap='round'
          strokeWidth='0.6'
        />
        <line
          id='Line_304'
          data-name='Line 304'
          y1='4.046'
          x2='4.23'
          fill='none'
          stroke='#747b95'
          strokeLinecap='round'
          strokeWidth='0.6'
        />
      </g>
    </g>
  </svg>
)

const SVGCloseTag = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGCloseTag
