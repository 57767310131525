import React from 'react'
import { Progress } from 'react-sweet-progress'
import { getText } from '../../../../lang'

const QuestionItem = (props) => {
  const { item, max_click_value } = props

  return (
    <div className='chat-item-wrapper question-item'>
      <div className='info-item'>
        <p>{getText('WORD_QUESTION')}</p>
        <h5>{item.name || '----'}</h5>
      </div>
      <div className='info-item'>
        <p>{getText('WORD_CLICKS')}</p>
        <div className='statistic'>
          <span className='progress-value'>{item.click_count}</span>
          <Progress
            value={max_click_value}
            percent={(item.click_count / max_click_value) * 100}
            theme={{
              success: {
                symbol: ' ',
                color: '#F8A326',
              },
              active: {
                symbol: ' ',
                color: '#F8A326',
              },
              default: {
                symbol: ' ',
                color: '#F8A326',
              },
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default QuestionItem
