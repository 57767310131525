import React, { useEffect, useState } from 'react'
import {
  FilePdfOutlined,
  FileOutlined,
  VideoCameraOutlined,
  AudioOutlined,
} from '@ant-design/icons'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { uploadAndFetchGETFile } from '../../../../../utils/UrlHelper'
import LoadingSpinner from '../../../../../components/LoadingSpinner'
import { getText } from '../../../../../lang'
import MatImage from '../../../../../components/MatImage'
import { getPDFFile, myWebViewPost } from '../../../../../utils'
import MatButton from '../../../../../components/MatButton'
import './CenterRenderImage.scss'

const style = { fontSize: 26, color: '#352a72' }

const CenterRenderImage = React.memo(({ images }) => {
  const [urlImages, setUrlImages] = useState([])
  const [loading, setloading] = useState(false)
  const [expandImage, setexpandImage] = useState(false)

  const isMobileApp = useSelector((state) => {
    return state.authReducer.isMobileApp
  })

  useEffect(() => {
    const fetchImages = async () => {
      setloading(true)
      for (const item of images) {
        if (item.mediaUrl.includes('api/v1/s3/private')) {
          const result = await uploadAndFetchGETFile(item.mediaUrl, [], true)
          if (result.success) {
            if (result.data.signedUrl) {
              item.mediaUrl = result.data.signedUrl
            }
          }
        } else if (item.mediaUrl.includes('https://www.facebook.com/ads/')) {
          await axios.get(item.mediaUrl).then((data) => {
            if (data.request.responseURL) {
              item.mediaUrl = data.request.responseURL
            }
          })
        }
        setUrlImages((old) => {
          old.push(item)
          return [...old]
        })
      }
      setloading(false)
    }
    fetchImages()
  }, [])

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          {urlImages.map((item, index) => {
            if (!expandImage && index > 1) {
              return null
            }

            if (item.mediaContentType && item.mediaContentType.startsWith('image')) {
              return (
                <MatImage
                  key={`${index}`}
                  url={item.mediaUrl}
                  flipY
                  flipX
                  rotateLeft
                  rotateRight
                  zoomOut
                  zoomIn
                />
              )
            } else if (
              (item.mediaContentType &&
                item.mediaContentType.startsWith('application/pdf')) ||
              (item.mediaContentType && item.mediaContentType.startsWith('file'))
            ) {
              return (
                <MatButton
                  className='single-pdf file-center'
                  key={`${index}`}
                  onClick={async () => {
                    if (isMobileApp) {
                      myWebViewPost(item.mediaUrl)
                    } else {
                      getPDFFile(item.mediaUrl)
                    }
                  }}
                  buttonText={
                    <FilePdfOutlined style={{ ...style, color: '#ff0000' }} />
                  }
                />
              )
            } else {
              return (
                <MatButton
                  className='single-pdf file-center'
                  key={`${index}`}
                  onClick={() => {
                    if (isMobileApp) {
                      myWebViewPost(item.mediaUrl)
                    } else {
                      window.open(item.mediaUrl, '_blank')
                    }
                  }}
                  buttonText={
                    item.mediaContentType === 'video' ? (
                      <VideoCameraOutlined style={{ ...style }} />
                    ) : item.mediaContentType === 'audio' ? (
                      <AudioOutlined style={{ ...style }} />
                    ) : (
                      <FileOutlined style={{ ...style }} />
                    )
                  }
                />
              )
            }
          })}
          {urlImages.length > 2 && (
            <div
              onClick={() => {
                setexpandImage(!expandImage)
              }}
              className='show-all-media'
            >
              {getText(
                expandImage
                  ? 'TEXT_HIDE_ADDITIONAL_VISUALS'
                  : 'TEXT_SHOW_ADDITIONAL_VISUALS'
              )}
            </div>
          )}
        </>
      )}
    </>
  )
})

export default CenterRenderImage
