import React from 'react'
import Icon from '@ant-design/icons'
import { MainColor } from '../../utils'

const SVGIcon = (props) => {
  return (
    <svg
      width={props.width || '16'}
      height={props.height || '16'}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.33301 1.3335V3.3335'
        stroke={props.color || MainColor}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.667 1.3335V3.3335'
        stroke={props.color || MainColor}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2.33301 6.06006H13.6663'
        stroke={props.color || MainColor}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.9997 15.3333C13.4724 15.3333 14.6663 14.1394 14.6663 12.6667C14.6663 11.1939 13.4724 10 11.9997 10C10.5269 10 9.33301 11.1939 9.33301 12.6667C9.33301 14.1394 10.5269 15.3333 11.9997 15.3333Z'
        stroke={props.color || MainColor}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.7141 13.4067L11.3008 12'
        stroke={props.color || MainColor}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.7004 12.0132L11.2871 13.4265'
        stroke={props.color || MainColor}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14 5.66683V10.9068C13.5133 10.3535 12.8 10.0002 12 10.0002C10.5267 10.0002 9.33333 11.1935 9.33333 12.6668C9.33333 13.1668 9.47333 13.6402 9.72 14.0402C9.86 14.2802 10.04 14.4935 10.2467 14.6668H5.33333C3 14.6668 2 13.3335 2 11.3335V5.66683C2 3.66683 3 2.3335 5.33333 2.3335H10.6667C13 2.3335 14 3.66683 14 5.66683Z'
        stroke={props.color || MainColor}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.99666 9.13314H8.00265'
        stroke={props.color || MainColor}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.52987 9.13314H5.53585'
        stroke={props.color || MainColor}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.52987 11.1331H5.53585'
        stroke={props.color || MainColor}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

const SVGConversationCreated = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGConversationCreated
