import React from 'react'
import { Form, InputNumber } from 'antd'
import { getText } from '../../../lang'

const InputFormNumber = (props) => {
  return (
    <Form.Item
      className={`mat-form-item ${props.formClassName || ''}`}
      label={props.label}
      name={props.name}
      initialValue={props.initialValue}
      hasFeedback={props.hasFeedback}
      rules={[
        {
          required: props.required,
          message: props.errorMessage,
          type: props.type,
        },
        {
          min: props.min,
          validator:
            typeof props.min !== 'undefined'
              ? (item, value) => {
                  if (value >= props.min) {
                    return Promise.resolve()
                  }
                  return Promise.reject(
                    props.minMessage || new Error(getText('ERR_MSG_INPUT_NUMBER'))
                  )
                }
              : () => Promise.resolve(),
        },
        {
          max: props.max,
          validator: props.max
            ? (item, value) => {
                if (value < props.max + 1) {
                  return Promise.resolve()
                }
                return Promise.reject(
                  props.maxMessage ||
                    new Error(
                      `${
                        props.label ? props.label : props.placeholder
                      } ${getText('TEXT_MUST_BE_MAXIMUM')} ${props.max} ${getText('WORD_CHARACTERS')}.`
                    )
                )
              }
            : () => Promise.resolve(),
        },
      ]}
      layout='vertical'
      style={props.style}
      noStyle={props.noStyle}
      validateStatus={props.validateStatus}
    >
      <InputNumber
        className={`mat-input-affix-wrapper ${props.inputClassName || ''}`}
        onChange={props.onChange}
        disabled={props.disabled}
        placeholder={props.placeholder ? props.placeholder : props.label}
        step={props.step}
        size={props.size}
        controls={props.controls}
        type='number'
      />
    </Form.Item>
  )
}

InputFormNumber.defaultProps = {
  name: 'numberValue',
  placeholder: '1',
  type: 'number',
  required: false,
  hasFeedback: true,
  step: 1,
  disabled: false,
  size: 'middle',
  controls: false,
}

export default InputFormNumber
