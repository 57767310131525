import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getText } from '../../../../lang'
import LoadingSpinner from '../../../../components/LoadingSpinner'
import MatadorConnectLayout from '../matadorConnect/matadorConnectComponents/MatadorConnectLayout'
import MatadorConnectListTitle from '../matadorConnect/matadorConnectComponents/MatadorConnectListTitle'
import SVGIntegrationGmail from '../../../../icons/SVG/SVGIntegrationGmail'
import SVGIntegrationCRM from '../../../../icons/SVG/SVGIntegrationCRM'
import SVGIntegrationEmail from '../../../../icons/SVG/SVGIntegrationEmail'
import SVGIntegrationFacebook from '../../../../icons/SVG/SVGIntegrationFacebook'
import MatBlockWithLabel from '../../../../components/MatBlockWithLabel'
import SVGIntegrationTouchpointsMail from '../../../../icons/SVG/SVGIntegrationTouchpointsMail'
// import SVGIntegrationWhatsapp from '../../../../icons/SVG/SVGIntegrationWhatsapp'
import GoogleIntegration from './GoogleIntegration'
import CrmTypeSelector from './CrmTypeSelector'
import FacebookIntegration from './facebookIntegration'
import HandleNylasConnection from './HandleNylasConnection'
import IntegreationItem from './IntegreationItem'
import ConnectGmailOrOffice365 from './ConnectGmailOrOffice365'
// import WhatsappIntegration from './whatsappIntegration/WhatsappIntegration'
import './Integration.scss'

const LocationIntegration = (props) => {
  const { locationObj, isNewLocation, user, setLocationObj } = props

  // const [pageLoading, setPageLoading] = useState(true)

  const navigate = useNavigate()
  const { _id } = useParams()

  useEffect(() => {
    props.setFooterButtons && props.setFooterButtons(['save', 'cancel'])
  }, [])

  useEffect(() => {
    if (!isNewLocation && !locationObj) {
      return navigate('/location/list')
    }
  }, [_id])

  if (!locationObj._id && !isNewLocation) {
    return <LoadingSpinner />
  }
  return (
    <MatadorConnectLayout className='location-integration-wrapper'>
      <MatadorConnectListTitle
        title={getText('WORD_INTEGRATIONS')}
        subTitle={getText(
          'TEXT_SUPERCHARGE_YOUR_WORKFLOW_AND_CONNECT_TOOLS_YOU_AND_YOUR_TEAM_USES_EVERY_DAY'
        )}
      />
      <MatBlockWithLabel className='location-integration'>
        {!isNewLocation && user.isAdmin && (
          <IntegreationItem
            icon={<SVGIntegrationGmail />}
            title={getText('ACTION_INTEGRATE_TOUCHPOINTS')}
            subTitle={getText('TEXT_TRIGGERS_WHEN_YOU_RECEIVE_NEW_LEAD')}
            footer={<GoogleIntegration location={locationObj} />}
          />
        )}
        <IntegreationItem
          icon={<SVGIntegrationCRM />}
          title={getText('WORD_CONNECT_TO_CRM')}
          subTitle={getText(
            'TEXT_INTEGRATES_YOUR_DATA_ENHANCING_YOUR_ABILITY_MANAGE_CUSTOMER_INTERACTIONS_RELATIONSHIPS_EFFECTIVELY'
          )}
          footer={
            <CrmTypeSelector
              location={locationObj}
              isNewLocation={isNewLocation}
              setLocationObj={setLocationObj}
            />
          }
        />
        <IntegreationItem
          icon={<SVGIntegrationEmail />}
          title={getText('TEXT_CONNECT_YOUR_EMAIL')}
          subTitle={getText(
            'TEXT_GIVES_POSSIBILITY_HAVE_CONVERSATIONS_WITH_CUSTOMERS_BY_EMAIL'
          )}
          footer={<HandleNylasConnection location={locationObj} />}
        />
        {!isNewLocation && locationObj._id && (
          <IntegreationItem
            icon={<SVGIntegrationFacebook />}
            title={getText('ACTION_INTEGRATE_FB_INSTAGRAM_PAGE')}
            subTitle={getText(
              'TEXT_MERGES_BOTH_PLATFORMS_INTO_UNIFIED_ONLINE_PRESENCE'
            )}
            footer={
              <FacebookIntegration
                locationId={locationObj._id}
                facebookConnection={locationObj.facebookConnection}
                featuresConnectingData={locationObj.featuresConnectingData}
              />
            }
          />
        )}
        {/* {!isNewLocation &&
          locationObj._id &&
          process.env.REACT_APP_ENV === 'STAGE' && (
            <IntegreationItem
              icon={<SVGIntegrationWhatsapp />}
              title={getText('TEXT_LINK_YOUR_WHATSAPP_BUSINESS_ACCOUNT')}
              subTitle={getText(
                'TEXT_PROVIDE_AN_OPPORTUNITY_TO_INTERACT_WITH_CUSTOMERS_THROUGH_AN_INSTANT_MESSAGING_SERVICE'
              )}
              footer={
                <WhatsappIntegration
                  locationId={locationObj._id}
                  facebookConnection={locationObj.facebookConnection}
                  featuresConnectingData={locationObj.featuresConnectingData}
                />
              }
            />
          )} */}
        {!isNewLocation && locationObj._id && (
          <IntegreationItem
            icon={<SVGIntegrationTouchpointsMail />}
            title={getText('TEXT_CONNECT_GMAIL_OR_OFFICE_365')}
            subTitle={getText(
              'TEXT_CONNECT_YOUR_EMAIL_FOR_GETTING_APPOINTMENTS_THERE'
            )}
            footer={<ConnectGmailOrOffice365 locationObj={locationObj} />}
          />
        )}
      </MatBlockWithLabel>
    </MatadorConnectLayout>
  )
}

export default LocationIntegration
