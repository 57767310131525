import React from "react";
import Icon from "@ant-design/icons";

const SVGIcon = (props) => (
  <svg
    width="19"
    height="20"
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.00586 0.0146484C4.45356 0.0146484 4.00586 0.462648 4.00586 1.01465C1.79676 1.01465 0.00575938 2.80565 0.00585938 5.01465V6.07666L18.0059 6.01465V5.01465C18.0059 2.80565 16.215 1.01465 14.0059 1.01465C14.0059 0.462648 13.5582 0.0146484 13.0059 0.0146484C12.4536 0.0146484 12.0059 0.462648 12.0059 1.01465H6.00586C6.00586 0.462648 5.55816 0.0146484 5.00586 0.0146484ZM0.00585938 8.01465V15.0146C0.00585938 17.2236 1.79676 19.0146 4.00586 19.0146H14.0059C16.215 19.0146 18.006 17.2236 18.0059 15.0146V8.01465H0.00585938ZM5.00586 10.0146C5.55816 10.0146 6.00586 10.4626 6.00586 11.0146C6.00586 11.5666 5.55816 12.0146 5.00586 12.0146C4.45356 12.0146 4.00586 11.5666 4.00586 11.0146C4.00586 10.4626 4.45356 10.0146 5.00586 10.0146ZM9.00586 10.0146C9.55816 10.0146 10.0059 10.4626 10.0059 11.0146C10.0059 11.5666 9.55816 12.0146 9.00586 12.0146C8.45356 12.0146 8.00586 11.5666 8.00586 11.0146C8.00586 10.4626 8.45356 10.0146 9.00586 10.0146ZM13.0059 10.0146C13.5582 10.0146 14.0059 10.4626 14.0059 11.0146C14.0059 11.5666 13.5582 12.0146 13.0059 12.0146C12.4536 12.0146 12.0059 11.5666 12.0059 11.0146C12.0059 10.4626 12.4536 10.0146 13.0059 10.0146ZM5.00586 13.0146C5.55816 13.0146 6.00586 13.4626 6.00586 14.0146C6.00586 14.5666 5.55816 15.0146 5.00586 15.0146C4.45356 15.0146 4.00586 14.5666 4.00586 14.0146C4.00586 13.4626 4.45356 13.0146 5.00586 13.0146ZM9.00586 13.0146C9.55816 13.0146 10.0059 13.4626 10.0059 14.0146C10.0059 14.5666 9.55816 15.0146 9.00586 15.0146C8.45356 15.0146 8.00586 14.5666 8.00586 14.0146C8.00586 13.4626 8.45356 13.0146 9.00586 13.0146ZM13.0059 13.0146C13.5582 13.0146 14.0059 13.4626 14.0059 14.0146C14.0059 14.5666 13.5582 15.0146 13.0059 15.0146C12.4536 15.0146 12.0059 14.5666 12.0059 14.0146C12.0059 13.4626 12.4536 13.0146 13.0059 13.0146Z"
      fill="#48AF80"
    />
  </svg>
);

const SVGDepositDateIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />;
      }}
      {...props}
    />
  );
};

export default SVGDepositDateIcon;
