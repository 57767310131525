import { fetchFromUrlGETAsync } from '../../utils/UrlHelper'

const urlGetVersion = '/dashboard/version'

const appActions = {
  getVersion: async () => {
    return await fetchFromUrlGETAsync(urlGetVersion, [])
  },

  getShortUrl: async (hash) => {
    const { data } = await fetchFromUrlGETAsync(`/short/${hash}`)

    return data
  },
}

export default appActions
