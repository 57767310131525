import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width={props.width || '50'}
      height={props.height || '50'}
      viewBox='0 0 50 50'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M31.5843 0H6V50H44.8889V13.3046L31.5843 0ZM10.0404 45.9596V4.0404H26.7071V17.6767H40.8485V45.9596H10.0404ZM39.5066 13.6363L30.7475 4.87724V13.6363H39.5066Z'
        fill='black'
      />
      <path
        d='M37.6584 27.577H35.8137C35.7891 27.3876 35.7387 27.2167 35.6624 27.0642C35.5862 26.9117 35.4853 26.7813 35.3599 26.6731C35.2344 26.5648 35.0856 26.4824 34.9135 26.4259C34.7437 26.3668 34.5556 26.3373 34.349 26.3373C33.9825 26.3373 33.6664 26.4271 33.4008 26.6067C33.1376 26.7862 32.9347 27.0457 32.792 27.3851C32.6518 27.7246 32.5817 28.1353 32.5817 28.6174C32.5817 29.1192 32.653 29.5398 32.7957 29.8792C32.9408 30.2162 33.1437 30.4708 33.4045 30.643C33.6676 30.8127 33.9788 30.8975 34.3379 30.8975C34.5396 30.8975 34.7228 30.8717 34.8876 30.8201C35.0549 30.7684 35.2012 30.6934 35.3267 30.595C35.4546 30.4942 35.5591 30.3724 35.6403 30.2297C35.7239 30.0846 35.7817 29.9211 35.8137 29.739L37.6584 29.7501C37.6265 30.0846 37.5293 30.4142 37.367 30.7389C37.2071 31.0636 36.987 31.36 36.7066 31.6281C36.4262 31.8937 36.0843 32.1052 35.6809 32.2626C35.2799 32.4201 34.82 32.4988 34.301 32.4988C33.6172 32.4988 33.0048 32.3487 32.4636 32.0487C31.925 31.7461 31.4994 31.3058 31.1871 30.7278C30.8747 30.1498 30.7185 29.4463 30.7185 28.6174C30.7185 27.7861 30.8772 27.0814 31.1944 26.5034C31.5117 25.9253 31.941 25.4863 32.4821 25.1862C33.0232 24.8861 33.6295 24.7361 34.301 24.7361C34.7585 24.7361 35.1816 24.8 35.5702 24.9279C35.9588 25.0534 36.3007 25.2379 36.5959 25.4814C36.891 25.7224 37.1308 26.0188 37.3153 26.3705C37.4998 26.7223 37.6142 27.1244 37.6584 27.577Z'
        fill='black'
      />
      <path d='M29.6532 24.8396V32.3957H27.8269V24.8396H29.6532Z' fill='black' />
      <path
        d='M21.4146 32.3957V24.8396H26.6831V26.3228H23.2408V27.8724H26.4138V29.3592H23.2408V30.9125H26.6831V32.3957H21.4146Z'
        fill='black'
      />
      <path
        d='M13.6426 32.3957V24.8396H15.4689V27.8724H18.4463V24.8396H20.2689V32.3957H18.4463V29.3592H15.4689V32.3957H13.6426Z'
        fill='black'
      />
    </svg>
  )
}

const SVGHeicIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGHeicIcon
