import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width={props.width || '14'}
      height={props.height || '13'}
      viewBox='0 0 14 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='7' cy='6.5' r='6.5' fill='#5BC78B' />
      <g clipPath='url(#clip0_19121_62315)'>
        <path
          d='M6.18694 7.78837L9.92119 4.05371L10.496 4.62815L6.18694 8.93724L3.60156 6.35187L4.176 5.77743L6.18694 7.78837Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_19121_62315'>
          <rect
            width='9.75'
            height='9.75'
            fill='white'
            transform='translate(2.125 1.625)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}

const SVGReviewCheck = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGReviewCheck
