import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import SVGFingerHeader from '../../../../../icons/SVG/SVGFingerHeader'
import { getText } from '../../../../../lang'
import { SVGCongraWhite } from '../../../../../icons/SVG/SVGCongraWhite'
import { SVGCongraPurple } from '../../../../../icons/SVG/SVGCongraPurple'
import { notifyError } from '../../../../../utils/Notify'
import SVGSuperHumanRectangleWhiteBg from '../../../../../icons/SVG/SVGSuperHumanRectangleWhiteBg'
import { RANGE_PARAMS, getStartDateForDashboard } from '../../../../../utils'
import SVGAssistedAppointmentRectangleWhiteBg from '../../../../../icons/SVG/SVGAssistedAppointmentRectangleWhiteBg'
import { SVGWooHooIcon } from '../../../../../icons/SVG/SVGWooHooIcon'
import statActions from '../../../../../store/modules/statActions'
import SuperHumanItem from './SuperHumanItem'
import AssistedAppointmentsItem from './AssistedAppointmentsItem'
import './MatadorImpact.scss'

const MatadorImpact = ({
  screenSize,
  _location_id,
  selectedRange,
  selectCustomRange,
}) => {
  const deviceSize = ['tabletPortrait', 'mobile']

  const navigate = useNavigate()

  const [indentData, setIndentData] = useState(0)
  const [lodaing, setLoading] = useState(false)

  useEffect(() => {
    const refreshTheBrainStats = async () => {
      setLoading(true)
      const result = await statActions.getTheSmartReplyStats(
        getStartDateForDashboard(selectedRange, selectCustomRange).start_date,
        getStartDateForDashboard(selectedRange, selectCustomRange).end_date,
        _location_id,
        selectCustomRange
      )
      if (result.success) {
        setIndentData(result.data)
      } else {
        notifyError(result.errMsg)
      }
      setLoading(false)
    }
    if (selectedRange === 'custom' && selectCustomRange) {
      refreshTheBrainStats()
    } else if (RANGE_PARAMS.includes(selectedRange)) {
      refreshTheBrainStats()
    }
  }, [selectedRange, _location_id])

  const handleToMessagePage = (key) => {
    navigate('/messages?type=all', {
      state: {
        matadorImpacts: {
          [key]: true,
          dateFilter: [
            getStartDateForDashboard(selectedRange, selectCustomRange).start_date,
            getStartDateForDashboard(selectedRange, selectCustomRange).end_date,
          ],
          range: selectedRange === 'custom' ? undefined : selectedRange,
          locations: _location_id || false,
        },
      },
    })
  }

  return (
    <div className='matador-impact'>
      <div className='header'>
        <SVGFingerHeader />
        <div className='header-text'>
          <span>{getText('TEXT_MATADOR_IMPACT')}</span>
          <span>{getText('TEXT_HOW_MATADOR_IS_IMPACT_IN_YOUR_BUSINESS')}</span>
        </div>
      </div>
      <div className='item-wrapper'>
        {deviceSize.includes(screenSize) ? (
          <>
            <div
              className='matador-impact-white'
              style={
                indentData && indentData.superhuman_replies === 0
                  ? { filter: 'grayscale(1)' }
                  : {}
              }
            >
              <SVGCongraWhite />
              <div className='matador-impact-text-wrapper'>
                <div
                  className='fingerpriniting-number'
                  onClick={() => handleToMessagePage('superhuman_replies')}
                >
                  {indentData.superhuman_replies || ''}
                </div>
                <div className='fingerpriniting-text'>
                  {getText(
                    indentData.superhuman_replies === 0
                      ? 'TEXT_PLEASE_CONTACT_CLEINTS_SUCCESS_TO_ACTIVATE_SUPERHUMAN'
                      : 'TEXT_MESSAGES_SENT_VIA_SUPER_HUMAN'
                  )}
                </div>
                {indentData.superhuman_replies && (
                  <div className='matador-impact-extra-title'>
                    {`${getText('TEXT_SUPERHUMAN_ASSISTED_APPOINTMENTS')}: `}
                    <span
                      onClick={() =>
                        handleToMessagePage('superhuman_assisted_appointments')
                      }
                    >
                      {indentData.superhuman_assisted_appointments || ''}
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div
              className='matador-impact-purple'
              style={
                indentData && indentData.assisted_appointments === 0
                  ? { filter: 'grayscale(1)' }
                  : {}
              }
            >
              <SVGCongraPurple />
              <div className='matador-impact-text-wrapper'>
                <div className='fingerpriniting-number'>
                  <span onClick={() => handleToMessagePage('assisted_appointments')}>
                    {indentData.assisted_appointments || ''}
                  </span>
                  <SVGWooHooIcon />
                </div>
                <div className='fingerpriniting-text'>
                  <>{getText('TEXT_MATADOR_ASSISTED_APPOINTMENTS')}</>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <SuperHumanItem
              value={indentData.superhuman_replies || 0}
              lodaing={lodaing}
              title={getText('TEXT_MESSAGES_SENT_VIA_SUPER_HUMAN')}
              BgComponent={SVGSuperHumanRectangleWhiteBg}
              screenSize={screenSize}
              extraTitle={String(indentData.superhuman_assisted_appointments) || ''}
              onClick={(status) => handleToMessagePage(status)}
            />
            <AssistedAppointmentsItem
              value={indentData.assisted_appointments || 0}
              title={getText('TEXT_MATADOR_ASSISTED_APPOINTMENTS')}
              BgComponent={SVGAssistedAppointmentRectangleWhiteBg}
              screenSize={screenSize}
              onClick={(status) => handleToMessagePage(status)}
            />
          </>
        )}
      </div>
    </div>
  )
}

export default MatadorImpact
