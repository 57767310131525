import React from 'react'
import DepositDetailsListItemDepositSummary from './DepositDetailsListItemDepositSummary'
import DepositDetailsListItemGeneralInfo from './DepositDetailsListItemGeneralInfo'

const DepositDetailsListItems = ({ item }) => {
  return (
    <div className={'deposit-details-top-info-wrapper'}>
      <DepositDetailsListItemGeneralInfo item={item} />
      <DepositDetailsListItemDepositSummary item={item} />
    </div>
  )
}

export default DepositDetailsListItems
