import React from 'react'
import SVGChartIcon from '../../../../../icons/SVG/SVGChartIcon'

function LineChartHeader({ text }) {
  return (
    <div className='conversation-chart-header'>
      <SVGChartIcon color='rgba(56, 81, 211, 1)' background='#F1F3FD' />
      <div className='header-text'>{text}</div>
    </div>
  )
}

export default LineChartHeader
