import React, { useEffect, useMemo } from 'react'
import { CONNECT_URL } from '../../../../config'
import './Preview.scss'

const ThemePreview = ({ locationId, settings, language = 'en' }) => {
  const getLanguage = useMemo(
    () => (language === 'es' ? 'sp' : language),
    [language]
  )

  useEffect(() => {
    sendPreviewSettings()
  }, [settings])

  const sendPreviewSettings = () => {
    const iframe = document.getElementById(`iframe-preview-subscriber-growth`)
    if (!iframe) {
      return
    }
    iframe.contentWindow.postMessage(getData(), '*')
  }

  const getData = () => {
    return settings
  }

  const onIframeLoad = () => {
    sendPreviewSettings()
  }

  const renderIframe = ({ display }) => {
    return (
      <iframe
        title='Matador Connect Preview'
        id={`iframe-preview-subscriber-growth`}
        height='700rem'
        width='100%'
        frameBorder='none'
        onLoad={onIframeLoad}
        src={`${CONNECT_URL}/subscriber-growth/${locationId}/${getLanguage}`}
        style={{ display }}
        allow='geolocation'
      ></iframe>
    )
  }

  return (
    <div className='matador-connect-preview-default'>
      {renderIframe({
        display: 'block',
      })}
    </div>
  )
}

export default ThemePreview
