import React, { memo, useState } from 'react'
import { Tooltip } from 'antd'
import meetActions from '../../../../../store/modules/meetActions'
import SVGVideo from '../../../../../icons/SVG/SVGVideo'
import { getText } from '../../../../../lang'
import MatButton from '../../../../../components/MatButton'
import { msgTooltipText } from '../../../utils/messageUtils'
import { MainColor } from '../../../../../utils'
import './MessageVideoCallAction.scss'

const MessageVideoCallButton = ({
  conversation,
  refreshConversation,
  disabled,
  isAwaitingConsent,
}) => {
  const [saving, setSaving] = useState(false)

  const handleSendCallRequest = async () => {
    let obj = {}
    if (conversation.kind === 'messenger') {
      obj.messengerId = conversation.receiver.messengerId
    } else {
      obj.phone = conversation.receiver.phone.replace('+', '')
    }
    setSaving(true)
    let result = await meetActions.createRoom(obj, conversation)
    setSaving(false)
    if (result.success) {
      refreshConversation(result.data.conversation)
    }
  }

  return conversation.kind !== 'messenger' &&
    conversation.receiver_phone &&
    !isAwaitingConsent ? (
    <Tooltip
      placement='top'
      destroyTooltipOnHide={true}
      trigger={['hover', 'click']}
      title={getText('WORD_VIDEO_CALL')}
    >
      <MatButton
        className='video_call_button'
        onClick={handleSendCallRequest}
        disabled={disabled}
        loading={saving}
        icon={<SVGVideo color={disabled ? 'var(--thridyTextColor)' : MainColor} />}
      />
    </Tooltip>
  ) : (
    <Tooltip title={msgTooltipText(conversation, isAwaitingConsent)}>
      <span className={'mat-btn video_call_button'} disabled={true}>
        <SVGVideo color={'var(--thridyTextColor)'} />
      </span>
    </Tooltip>
  )
}

export default memo(MessageVideoCallButton)
