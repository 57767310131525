import React from 'react'
import MatRow from '../../../../components/MatRow'
import InputFormText from '../../../../components/Form/InputFormText'
import { getText } from '../../../../lang'
import ConfirmPopup from '../../../../components/ConfirmPopup'
import SVGDeleteGreyIcon from '../../../../icons/SVG/SVGDeleteGreyIcon'

const IPWhiteListItem = ({ field, form, onDelete }) => {
  const item =
    form.getFieldsValue().ipwhiteList &&
    form.getFieldsValue().ipwhiteList[field.name]

  return (
    <MatRow className='ip-whitelist-item'>
      <InputFormText
        label={''}
        formStyle={{ marginBottom: 0 }}
        name={[field.name]}
        placeholder={getText('TEXT_PLEASE_INSERT_WHITELIST_IP_ADDRESS')}
      />
      <ConfirmPopup
        title={getText('MSG_DELETE_IP_WHITE_LIST')}
        placement={'topRight'}
        onConfirm={(e) => {
          e.stopPropagation()
          onDelete(item)
        }}
        okText={getText('WORD_YES')}
        cancelText={getText('WORD_NO')}
        trigger={<SVGDeleteGreyIcon className='whitelist-item-remove' />}
      />
    </MatRow>
  )
}

export default IPWhiteListItem
