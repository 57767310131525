import React from 'react'
import { CloseOutlined } from '@ant-design/icons'
import { getText } from '../../../../lang'
import SVGWorkflowPushCRMIcon from '../../../../icons/SVG/SVGWorkflowPushCRMIcon'
import MatRow from '../../../../components/MatRow'

const WorkflowPushToCRMItem = ({ field, onRemove }) => {
  return (
    <div className='workflow-action-item-wrapper'>
      <MatRow flexStart className='workflow-action-item-row'>
        <div className='workflow-action-item-icon' style={{ color: '#FFC800' }}>
          <SVGWorkflowPushCRMIcon width={48} height={48} />
          {getText('WORD_PUSH_TO_CRM')}
        </div>
        <div className='workflow-action-item-textarea-wrapper'>
          <div className='workflow-action-delay'>
            <div className='detect-intent-wait-for-reply'>
              <span className='title'>
                {getText('TEXT_CREATE_OPPORTUNITY_IN_CRM')}
              </span>
            </div>
          </div>
        </div>
      </MatRow>
      <CloseOutlined
        style={{ color: '#77808d', fontSize: '10px' }}
        className='workflow-remove-icon'
        onClick={() => {
          onRemove && onRemove()
        }}
      />
    </div>
  )
}

export default WorkflowPushToCRMItem
