import { fetchFromUrlPOSTAsync, fetchFromUrlGETAsync } from '../../utils/UrlHelper'

const urlNotes = '/notes'

const fs = {
  saveNew: async (obj, conversation_id) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    const result = await fetchFromUrlPOSTAsync(
      urlNotes + '/' + conversation_id + '/conversation',
      urlParams
    )
    return result
  },
  getNotesList: async (conversation_id, start, range) => {
    const urlParams = [
      { key: 'page', value: start + 1 },
      { key: 'perPage', value: range },
    ]
    const result = await fetchFromUrlGETAsync(
      urlNotes + '/' + conversation_id + '/conversation',
      urlParams
    )
    return result
  },
}

const loc = {}

const notesActions = Object.assign(fs, loc)

export default notesActions
