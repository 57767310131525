import React, { useCallback, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { debounce } from 'lodash'
import userActions from '../../store/modules/userActions'
import locationActions from '../../store/modules/locationActions'
import { getText } from '../../lang'
import SVGSearch from '../../icons/SVG/SVGSearch'
import InputText from '../../components/InputText'
import MatButton from '../../components/MatButton'
import MatRow from '../../components/MatRow'
import MatSelectSecondary from '../../components/MatSelect/MatSelectSecondary'

const PER_PAGE_OPTIONS = 50

const UsersPageHeader = (props) => {
  const [listData, setListData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const {
    user,
    search,
    onSearch,
    filterRef,
    openModal,
    onFiltersChange,
    organization = {},
  } = props
  const [filtersData, setFiltersData] = useState({
    enabled: 'true',
  })
  const debouncedChange = useCallback(
    debounce((options) => onFiltersChange(options), 500),
    []
  )

  useEffect(() => {
    filterRef.current.resetFilters = () => {
      onSearch('')
    }
  }, [])

  useEffect(() => {
    debouncedChange(filtersData)
  }, [filtersData])

  useEffect(() => {
    setFiltersData({ enabled: 'true' })
  }, [organization])

  useEffect(() => {
    const fetchData = async () => {
      if (!user?.organization?.id) return
      setIsLoading(true)
      try {
        const result = await locationActions.getPageList(
          0,
          PER_PAGE_OPTIONS,
          '',
          {},
          true
        )

        if (result.success) {
          const newList = result.data.map((location) => ({
            value: location._id,
            label: location.name,
            ...location,
          }))
          setListData(newList)
        }
      } catch (error) {
        console.error('Error fetching page list:', error)
      } finally {
        setIsLoading(false)
      }
    }
    fetchData()
  }, [user])

  return (
    <div className='user-page-header'>
      <MatRow>
        <MatButton
          type={'button'}
          buttonText={getText('ACTION_CREATE_USER')}
          onClick={() => openModal('edit', false)}
          className='create-user-button'
        />
        <MatSelectSecondary
          value={filtersData.enabled}
          options={[
            { label: getText('WORD_ACTIVE'), value: 'true' },
            { label: getText('WORD_INACTIVE'), value: 'false' },
            { label: getText('WORD_ALL_USERS'), value: '' },
          ]}
          onSelect={(value) => {
            setFiltersData((prevState) => ({
              ...prevState,
              enabled: value,
            }))
          }}
          className='user-select'
          showClearIcon={false}
        />
        {!isMobile && (
          <MatSelectSecondary
            onClear={() =>
              setFiltersData((prevState) => ({
                ...prevState,
                _default_location_id: null,
              }))
            }
            loading={isLoading}
            className='default-location-select'
            value={filtersData._default_location_id}
            placeholder={getText('filter_by_location')}
            options={listData}
            onSelect={(value) =>
              setFiltersData((prevState) => ({
                ...prevState,
                _default_location_id: value,
              }))
            }
          />
        )}
      </MatRow>
      <MatRow className='input-search-button'>
        {user.isSuperAdmin && !user.accessibleOrganizations.length && (
          <MatButton
            buttonText={getText('TEXT_DOWNLOAD_USER_XLS')}
            onClick={userActions.downloadUsersXLS}
          />
        )}
        <div className='inputWithSearch'>
          <SVGSearch />
          <InputText
            value={search}
            name='searchKeyword'
            className='inputSearch'
            placeholder={getText('TEXT_USERS_SEARCH')}
            onChange={(e) => {
              onSearch(e.target.value)
              setFiltersData((prevState) => ({
                ...prevState,
                search: e.target.value,
              }))
            }}
          />
        </div>
      </MatRow>
    </div>
  )
}

export default UsersPageHeader
