import React, { useState } from 'react'
import { Divider, Tag } from 'antd'
import SelectCheckedSecondary from '../../../../components/Form/SelectChecked/SelectCheckedSecondary'
import { getText } from '../../../../lang'
import SwitchForm from '../../../../components/Form/SwitchForm'
import InputFormTextAreaSecondary from '../../../../components/Form/InputFormTextArea/InputFormTextAreaSecondary'
import MatRow from '../../../../components/MatRow'
import SVGDeleteInSideIcon from '../../../../icons/SVG/SVGDeleteInSideIcon'
import ConfirmPopup from '../../../../components/ConfirmPopup'

const ConversationAIIntentConfiguration = ({
  intentName,
  form,
  onRemove,
  field,
  loading,
  usersList,
  onSearch,
  onLoadMore,
  onBlur,
}) => {
  const [excludeFollowingIntents, setExcludeFollowingIntents] = useState(
    form.getFieldValue(['customized_intents', field.name, 'is_excluded'])
  )

  return (
    <>
      <Divider
        plain
        style={{
          margin: '16px 0',
          backgroundColor: 'rgba(91, 102, 234, 0.1)',
        }}
      />
      <MatRow spaceBetween style={{ marginBottom: '12px' }}>
        <Tag
          color='#EAF6F1'
          style={{
            color: '#48AF80',
            height: '30px',
            borderRadius: '100px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {intentName && intentName.label}
        </Tag>
        <ConfirmPopup
          placement={'topRight'}
          title={getText('MSG_REMOVE_INTENT_ITEM')}
          onConfirm={() => {
            onRemove && onRemove()
          }}
          okText={getText('WORD_YES')}
          cancelText={getText('WORD_NO')}
          trigger={
            <SVGDeleteInSideIcon
              style={{
                width: '26px',
                height: '26px',
                backgroundColor: '#FFEBE9',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
            />
          }
        />
      </MatRow>
      <SelectCheckedSecondary
        formStyle={{ margin: '0' }}
        name={[field.name, 'users_to_tag']}
        label={''}
        showSearch={true}
        mode='multiple'
        onSearch={onSearch}
        placeholder={getText('LABEL_SELECT_THE_USERS_TO_TAG')}
        multipleNewStyle
        option={usersList}
        loading={loading}
        required={Boolean(excludeFollowingIntents)}
        onLoadMore={onLoadMore}
        onBlur={onBlur}
        message={getText('TEXT_ADD_AT_LEAST_ONE_USER_WHO_WILL_TAGGING')}
      />
      <SwitchForm
        rowStyle={{ margin: excludeFollowingIntents ? '12px 0 0' : '12px 0' }}
        rowClassName='conversation-ai-switch'
        name={[field.name, 'is_excluded']}
        text={getText('LABEL_EXCLUDE_THIS_INTENT_FROM_BEING_AUTOREPLIED')}
        onChange={() => {
          setExcludeFollowingIntents(!excludeFollowingIntents)
          if (excludeFollowingIntents) {
            form.validateFields([[field.name, 'users_to_tag']])
          }
        }}
      />
      {!excludeFollowingIntents && (
        <InputFormTextAreaSecondary
          formStyle={{ margin: '0' }}
          label={''}
          name={[field.name, 'instructions']}
          placeholder={getText(
            'TEXT_PROVIDE_INSTRUCTIONS_CLARIFY_HOW_YOU_WANT_RESPONSES_INTENT_GENERATED'
          )}
        />
      )}
    </>
  )
}

export default ConversationAIIntentConfiguration
