import React from 'react'
import Icon from '@ant-design/icons'
import { MainColor } from '../../utils'

const SVGIcon = (props) => (
  <svg
    width={props.width || '16'}
    height={props.height || '16'}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M5.33301 1.3335V3.3335'
      stroke={props.color || MainColor}
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.667 1.3335V3.3335'
      stroke={props.color || MainColor}
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M2.33301 6.06006H13.6663'
      stroke={props.color || MainColor}
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.91333 14.6668H5.33333C3 14.6668 2 13.3335 2 11.3335V5.66683C2 3.66683 3 2.3335 5.33333 2.3335H10.6667C13 2.3335 14 3.66683 14 5.66683V8.66683'
      stroke={props.color || MainColor}
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7.99666 9.13314H8.00265'
      stroke={props.color || MainColor}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.52987 9.13314H5.53585'
      stroke={props.color || MainColor}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.52987 11.1331H5.53585'
      stroke={props.color || MainColor}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14.0008 13.9634L11.3584 11.3335'
      stroke={props.color || MainColor}
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M13.9754 11.3584L11.333 14.0008'
      stroke={props.color || MainColor}
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

const SVGMessageAppointmentCanceledIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGMessageAppointmentCanceledIcon
