import React from "react"

function SVGDelete(props) {
  return (
    <svg
      data-name="Group 7469"
      width={12.536}
      height={14.701}
      viewBox="0 0 12.536 14.701"
      {...props}
    >
      <g data-name="Group 7055" fill={props.color ? props.color : "#ff5b72"}>
        <path
          data-name="Path 5802"
          d="M11.266 1.3h-3.25a1.308 1.308 0 00-1.3-1.3H5.85a1.308 1.308 0 00-1.3 1.3H1.3A1.308 1.308 0 000 2.6v.186a.244.244 0 00.248.248h12.039a.244.244 0 00.248-.248V2.6a1.253 1.253 0 00-1.269-1.3z"
        />
        <path
          data-name="Path 5803"
          d="M1.114 13.4a1.308 1.308 0 001.3 1.3h7.784a1.308 1.308 0 001.3-1.3V3.9H1.114zm6.329-6.577a.588.588 0 011.176 0v4.937a.588.588 0 01-1.176 0zm-3.451 0a.588.588 0 011.176 0v4.937a.588.588 0 01-1.176 0z"
        />
      </g>
    </svg>
  )
}

export default SVGDelete
