import React from 'react'
import { LockOutlined } from '@ant-design/icons'
import { Form, Input } from 'antd'
import { getText } from '../../../lang'

const InputFormPasswordConfirmSecondary = (props) => {
  return (
    <Form.Item
      className={`mat-form-item mat-form-item-black ${props.formClassName || ''}`}
      label={props.label}
      name={props.name}
      hasFeedback={props.hasFeedback}
      dependencies={[props.passwordToConfirm]}
      rules={[
        { required: props.required, message: props.message },
        ({ getFieldValue }) => ({
          validator(rule, value) {
            if (!value || getFieldValue(props.passwordToConfirm) === value) {
              return Promise.resolve()
            }
            return Promise.reject(
              getText('THE_TWO_PASSWORDS_THAT_YOU_ENTERED_DO_NOT_MATCH')
            )
          },
        }),
      ]}
    >
      <Input.Password
        className={`mat-input-affix-wrapper mat-input-affix-secondary-wrapper ${
          props.inputClassName || ''
        }`}
        placeholder={props.placeholder}
        prefix={<LockOutlined />}
        value={props.value}
        onChange={props.onChange}
        allowClear={props.allowClear}
        size={props.size}
      />
    </Form.Item>
  )
}

InputFormPasswordConfirmSecondary.defaultProps = {
  name: 'confirmPassword',
  placeholder: getText('WORD_CONFIRM_PASSWORD'),
  message: `${getText('MSG_PLEASE_CONFIRM_YOUR_PASSWORD')}!`,
  required: true,
  hasFeedback: true,
  allowClear: true,
  passwordToConfirm: 'password',
  size: 'middle',
}

export default InputFormPasswordConfirmSecondary
