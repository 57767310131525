import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='22'
      height='21'
      viewBox='0 0 22 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.42329 0C5.6474 -3.08002e-06 5.86227 0.0892804 6.02039 0.248102L10.6014 4.84959C10.9297 5.17935 10.9285 5.71281 10.5987 6.04111C10.269 6.3694 9.73552 6.36821 9.40722 6.03845L6.26583 2.88302V16.1405C6.26583 16.6058 5.88862 16.983 5.4233 16.983C4.95798 16.983 4.58076 16.6058 4.58076 16.1405V2.8831L1.43964 6.03843C1.11135 6.36821 0.57789 6.36941 0.248118 6.04112C-0.0816533 5.71284 -0.082857 5.17937 0.24543 4.8496L4.82619 0.248118C4.98431 0.0892923 5.19918 3.08017e-06 5.42329 0ZM16.5767 3.04835C17.042 3.04835 17.4192 3.42557 17.4192 3.89088V17.1482L20.5604 13.9929C20.8886 13.6631 21.4221 13.6619 21.7519 13.9902C22.0817 14.3185 22.0829 14.852 21.7546 15.1817L17.1738 19.7832C17.0157 19.942 16.8008 20.0313 16.5767 20.0313C16.3526 20.0313 16.1377 19.9421 15.9796 19.7832L11.3986 15.1818C11.0703 14.852 11.0715 14.3185 11.4013 13.9902C11.731 13.6619 12.2645 13.6631 12.5928 13.9929L15.7342 17.1483V3.89088C15.7342 3.42557 16.1114 3.04835 16.5767 3.04835Z'
        fill={props.color || 'black'}
      />
    </svg>
  )
}

const SVGOrgWorkflowIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGOrgWorkflowIcon
