import {
  fetchFromUrlPOSTAsync,
  fetchFromUrlGETAsync,
  fetchFromUrlPUTAsync,
  fetchFromUrlDELETEAsync,
} from '../../utils/UrlHelper'

const urlannouncements = '/announcements'

const fs = {
  getAllList: async (locationId) => {
    const urlParams = []
    if (locationId) {
      urlParams.push({ key: 'locationId', value: locationId })
    }
    const result = await fetchFromUrlGETAsync(urlannouncements, urlParams)
    if (result.success) {
      result.max = result.data.total
      result.data = result.data.announcements
    }
    return result
  },

  insert: async (obj) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    const result = await fetchFromUrlPOSTAsync(urlannouncements, urlParams)
    return result
  },

  update: async (obj, announcement_id) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    const result = await fetchFromUrlPUTAsync(
      urlannouncements + '/' + announcement_id,
      urlParams
    )
    return result
  },

  delete: async (id) => {
    const result = await fetchFromUrlDELETEAsync(urlannouncements + '/' + id, [])
    return result
  },
}

const loc = {}

const announcementsActions = Object.assign(fs, loc)

export default announcementsActions
