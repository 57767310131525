import React, { memo } from 'react'
import { motion } from 'framer-motion'
import { useSelector } from 'react-redux'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
import SVGNotPaid from '../../../../../icons/SVG/SVGNotPaid'
import SVGPaid from '../../../../../icons/SVG/SVGPaid'
import SVGPaymentIcon from '../../../../../icons/SVG/SVGPaymentIcon'
import SVGWarningIcon from '../../../../../icons/SVG/SVGWarningIcon'
import { getText } from '../../../../../lang'
import {
  formatAmount,
  formatDateTime,
  getInitialCountry,
} from '../../../../../utils'
import MatButton from '../../../../../components/MatButton'
import ProfileLetters from '../../../../../components/ProfileLetters'
import './CenterPayment.scss'
import { MESSAGE_BLOCK_ANIMATIONS } from '../../../utils/messageUtils'

const CenterPayment = (props) => {
  const { conversation, message, index, onCancelPayment, onRefundPayment } = props

  let initiatorPhone = conversation.initiator_phone

  const isSupportAgent = useSelector((state) => {
    return state.authReducer.user.isSupportAgent
  })

  const paymentInfo = (message) => {
    switch (message.additionalObject.status) {
      case 'rejected':
        return {
          status: getText('WORD_REJECTED'),
          colorName: 'red',
          icon: <SVGNotPaid />,
          color: '#F12B2C',
        }
      case 'not_paid':
        return {
          status: getText('WORD_NOT_PAID'),
          colorName: 'red',
          icon: <SVGNotPaid />,
          color: '#F12B2C',
        }
      case 'paid':
        return {
          status: getText('WORD_PAID'),
          colorName: 'green',
          color: '#00CB85',
          icon: <SVGPaid />,
        }
      case 'refunded':
        return {
          status: getText('WORD_REFUNDED'),
          colorName: 'yellow',
          color: '#ffc107',
          icon: <SVGWarningIcon width={60} height={60} color={'#ffc107'} />,
        }
      case 'canceled':
        return {
          status: getText('WORD_CANCELED_TWO_L'),
          colorName: 'black',
          color: '#a3a6be',
          icon: <SVGWarningIcon width={60} height={60} color={'#a3a6be'} />,
        }
      default:
        return {
          status: getText('WORD_NEW_STATUS'),
          colorName: 'yellow',
          icon: <SVGWarningIcon width={60} height={60} color={'#F12B2C'} />,
          color: '#F12B2C',
        }
    }
  }

  const confirmRefund = () => {
    const customerName = conversation.receiver.fullName
      ? conversation.receiver.fullName
      : conversation.receiver.phone

    Modal.confirm({
      title: (
        <div>
          {`${getText('MSG_ARE_YOU_SURE_WANT_TO_REFUND')} ${formatAmount(
            message.additionalObject.amount
          )} ${getText('WORD_TO')} ${customerName}?`}
        </div>
      ),
      icon: <ExclamationCircleOutlined />,
      okButtonProps: {
        type: 'primary',
        className: 'mat-btn mat-btn-primary',
      },
      cancelButtonProps: {
        type: 'cancel',
        className: 'mat-btn',
      },
      okText: getText('WORD_YES'),
      cancelText: getText('ACTION_CANCEL'),
      onOk: () => {
        onRefundPayment && onRefundPayment(message)
      },
    })
  }

  const cancelPayment = () => {
    Modal.confirm({
      title: <div>{`${getText('MSG_ARE_YOU_SURE_WANT_TO_CANCEL_PAYMENT')}`}</div>,
      icon: <ExclamationCircleOutlined />,
      okButtonProps: {
        type: 'primary',
        className: 'mat-btn mat-btn-primary',
      },
      cancelButtonProps: {
        type: 'cancel',
        className: 'mat-btn',
      },
      okText: getText('WORD_YES'),
      cancelText: getText('ACTION_CANCEL'),
      onOk: () => {
        onCancelPayment && onCancelPayment(message)
      },
    })
  }

  return (
    <motion.div
      key={index}
      className={'message right payment-type'}
      {...MESSAGE_BLOCK_ANIMATIONS}
      layout
    >
      <div className='message--inner-item'>
        <div className='post_info'>
          <span className='author'>{message.whoSentThis_name}</span>
          <span className='phone'>{initiatorPhone}</span>
        </div>
        <div className='invoice-field'>
          <h2>{getText('WORD_INVOICE')}</h2>
          <div>
            <div
              className={`invoice-notification ${paymentInfo(message).colorName}`}
            >
              <div className='invoice-notes'>
                <div
                  className={`${
                    paymentInfo(message).colorName === 'yellow'
                      ? ' invoice-warning-icon '
                      : ' '
                  } invoice-icon`}
                >
                  {paymentInfo(message).icon}
                </div>
                <div
                  className={
                    message?.additionalObject?.status === 'refunded'
                      ? 'invoice-amount-refunded'
                      : 'invoice-amount'
                  }
                >
                  <span>{paymentInfo(message).status}</span>
                  <span>
                    $ {message.additionalObject.amount}{' '}
                    {getInitialCountry(message.from) === 'ca' ? '' : 'USD'}
                  </span>
                </div>
              </div>
              <div className='payment-icon'>
                <SVGPaymentIcon
                  height={120.796}
                  width={175.691}
                  color={paymentInfo(message).color}
                />
              </div>
            </div>
            <div className='invoice-post'>
              <span>
                <label className='invoice-label'>
                  {getText('WORD_REFERENCE_NUMBER')}
                </label>
                <label>{message.additionalObject.referenceNumber}</label>
              </span>
              <span>
                <label className='invoice-label'>{getText('WORD_NET')}</label>
                <label>
                  {formatAmount(message.additionalObject.net_amount || 0)}
                </label>
              </span>
              <span>
                <label className='invoice-label'>{getText('WORD_FEE')}</label>
                <label>
                  {formatAmount(
                    message.additionalObject.application_fee_amount || 0
                  )}
                </label>
              </span>
              <span className='invoice-post-link'>
                <label className='invoice-label'>{getText('WORD_USER')}</label>
                <label>{`${message.whoSentThis_name}`}</label>
              </span>
            </div>
            <div className='payment-cancel-btn'>
              <MatButton
                buttonText={getText('ACTION_CANCEL')}
                size={'small'}
                typeButton={'cancel'}
                disabled={message.additionalObject.status !== 'not_paid'}
                onClick={cancelPayment}
              />
              {!isSupportAgent && (
                <MatButton
                  buttonText={getText('WORD_REFUND')}
                  size={'small'}
                  disabled={message.additionalObject.status !== 'paid'}
                  onClick={confirmRefund}
                />
              )}
              {message.additionalObject.receipt_urls &&
                message.additionalObject.receipt_urls.length > 0 && (
                  <MatButton
                    buttonText={getText('WORD_RECEIPT')}
                    size={'small'}
                    typeButton={'white'}
                    onClick={() => {
                      window.open(
                        `${message.additionalObject.receipt_urls[0]}`,
                        '_blank'
                      )
                    }}
                  />
                )}
            </div>
          </div>
        </div>
        <div className='date'>{formatDateTime(message.createdAt)}</div>
      </div>
      <ProfileLetters
        withoutIcon
        className='medium'
        name={message.whoSentThis_name || initiatorPhone}
      />
    </motion.div>
  )
}

export default memo(CenterPayment)
