import React from 'react'
import Icon from '@ant-design/icons'
import { MainColor } from '../../utils'

const SVGIcon = (props) => {
  return (
    <svg
      width={props.width || '16'}
      height={props.height || '16'}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.66634 6.66667L9.33301 9.33333M9.33301 6.66667L6.66634 9.33333M7.33301 2H8.66634C11.9801 2 14.6663 4.68629 14.6663 8C14.6663 11.3137 11.9801 14 8.66634 14H3.99967C2.52692 14 1.33301 12.8061 1.33301 11.3333V8C1.33301 4.68629 4.0193 2 7.33301 2Z'
        stroke={props.color || MainColor}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

const SVGMessageSkippedIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGMessageSkippedIcon
