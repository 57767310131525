import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='17'
    height='16'
    viewBox='0 0 17 16'
    fill='none'
  >
    <path
      d='M11.8335 13.6663H5.16683C3.16683 13.6663 1.8335 12.6663 1.8335 10.333V5.66634C1.8335 3.33301 3.16683 2.33301 5.16683 2.33301H11.8335C13.8335 2.33301 15.1668 3.33301 15.1668 5.66634V10.333C15.1668 12.6663 13.8335 13.6663 11.8335 13.6663Z'
      stroke={props.color || '#A3A1B7'}
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.8332 6L9.7465 7.66667C9.05983 8.21333 7.93317 8.21333 7.2465 7.66667L5.1665 6'
      stroke={props.color || '#A3A1B7'}
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

const SVGFilterMyMessages = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGFilterMyMessages
