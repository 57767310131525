import React, { useEffect, useState } from 'react'
import { Form } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { getText, getTextServerError } from '../../../../lang'
import { notifyError, notifySuccess } from '../../../../utils/Notify'
import authActions from '../../../../store/modules/authActions'
import locationActions from '../../../../store/modules/locationActions'
import LoadingSpinner from '../../../../components/LoadingSpinner'
import UserModal from '../../../users/UserModal'
import MatForm from '../../../../components/Form/MatForm'
import MatBlockWithLabel from '../../../../components/MatBlockWithLabel'
import InputFormTextSecondary from '../../../../components/Form/InputFormText/InputFormTextSecondary'
import InputFormEmailSecondary from '../../../../components/Form/InputFormEmail/InputFormEmailSecondary'
import InputFormTextAreaSecondary from '../../../../components/Form/InputFormTextArea/InputFormTextAreaSecondary'
import MatButton from '../../../../components/MatButton'
import MatadorConnectLayout from '../matadorConnect/matadorConnectComponents/MatadorConnectLayout'
import MatadorConnectListTitle from '../matadorConnect/matadorConnectComponents/MatadorConnectListTitle'
import ConfirmPopup from '../../../../components/ConfirmPopup'
import MatSwitch from '../../../../components/MatSwitch'
import './EditLocation.scss'
import GAInfo from '../../ga/GAInfo'

const EditLocation = (props) => {
  const { locationObj, isNewLocation, organization } = props
  const navigate = useNavigate()
  const { _id } = useParams()
  const [form] = Form.useForm()
  const [visible, setVisible] = useState(false)
  const [enabled, setEnabled] = useState(locationObj.enabled)
  const [userModalVisible, setUserModalVisible] = useState(false)
  const user = authActions.getUserData()
  const [GATrackingNumber, setGATrackingNumber] = useState('')

  useEffect(() => {
    props.setFooterButtons && props.setFooterButtons(['save', 'cancel'])
  }, [])

  useEffect(() => {
    setEnabled(locationObj.enabled)
  }, [locationObj])

  useEffect(() => {
    if (!isNewLocation && !locationObj) {
      return navigate('/location/list')
    }
  }, [_id])

  const onFinish = async (values) => {
    const gaValidationMessage = validateGATrackingNumber(values.GATrackingNumber)
    if (gaValidationMessage) {
      notifyError(gaValidationMessage)
      return
    }
    props.setLoading(true)
    const allValues = form.getFieldsValue(true)

    const ga4IdValue =
      allValues.GATrackingNumber && allValues.GATrackingNumber.trim()

    const obj = {
      name: allValues.name,
      friendlyName: allValues.friendlyName,
      address: allValues.address,
      email: allValues.email,
      enabled: enabled,
      GATrackingNumber: ga4IdValue,
    }
    if (!isNewLocation) {
      obj.enabled = enabled
    }

    const result = isNewLocation
      ? await locationActions.saveNew(obj)
      : await locationActions.saveUpdate(obj, locationObj._id)
    if (result.success) {
      notifySuccess(
        isNewLocation
          ? getText('TEXT_LOCATION_WAS_CREATED_SUCCESSFULLY')
          : getText('TEXT_LOCATION_WAS_UPDATED_SUCCESSFULLY')
      )
      // TODO need testing here
      let loc = authActions.getLocation()

      if (result.data._id === (loc && loc._id) || !loc || loc._id === ' ') {
        authActions.setLocation(result.data)
      }
      props.setLocationObj(result.data)
      if (isNewLocation) {
        navigate(`/location/settings/${result.data._id}/edit`, {
          state: {
            locationObj: result.data,
          },
        })
      }
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
    props.setLoading(false)
  }

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields.length > 0) {
      notifyError(errorInfo.errorFields[0].errors[0])
    }
  }

  const validateGATrackingNumber = (gaTrackingNumber) => {
    if (!gaTrackingNumber) {
      return ''
    }
    let gaIds = gaTrackingNumber.split(/[,;]\s*/)
    for (let gaId of gaIds) {
      if (!gaId.match(/^G-[A-Z0-9]{10}$/)) {
        return getText('INVALID_GA_FORMAT')
      }
    }
    if (
      gaTrackingNumber.trim().endsWith(',') ||
      gaTrackingNumber.trim().endsWith(';')
    ) {
      return getText('INVALID_GA_FORMAT_COMMA')
    }
    if (
      (gaTrackingNumber.includes(',') && !gaTrackingNumber.includes(', ')) ||
      (gaTrackingNumber.includes(';') && !gaTrackingNumber.includes('; '))
    ) {
      return getText('INVALID_GA_FORMAT_SPACE')
    }
    return ''
  }

  if (!locationObj._id && !isNewLocation) {
    return <LoadingSpinner />
  }

  return (
    <MatadorConnectLayout className='location-edit-wrapper'>
      <MatadorConnectListTitle
        title={
          isNewLocation
            ? getText('ACTION_CREATE_LOCATION')
            : getText('ACTION_EDIT_LOCATION')
        }
      />
      <MatForm
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{
          name: locationObj.name,
          friendlyName: locationObj.friendlyName,
          address: locationObj.address,
          email: locationObj.email,
          crmType: locationObj.crmType ? locationObj.crmType : 'none',
          GATrackingNumber: locationObj.GATrackingNumber,
        }}
      >
        {(user.isSuperAdmin || user.isAdmin) && !isNewLocation && (
          <ConfirmPopup
            title={getText('TEXT_LOCATION_DISABLING_WARNING')}
            visible={visible}
            placement={'topRight'}
            onVisibleChange={() => {
              setVisible(!visible)
            }}
            okText={getText('WORD_YES')}
            cancelText={getText('WORD_NO')}
            onCancel={() => {
              if (enabled) {
                setEnabled(true)
              }
            }}
            onConfirm={() => {
              setEnabled(false)
            }}
            trigger={
              <MatSwitch
                label={getText('TEXT_LOCATION_IS_ACTIVE')}
                parentClassName={'organization-switch-with-icon-and-text'}
                value={enabled}
                onChange={() => {
                  setEnabled(!enabled)
                  if (!enabled) {
                    form.validateFields()
                  }
                  if (enabled) {
                    setVisible(!visible)
                    setEnabled(true)
                  }
                }}
              />
            }
          />
        )}
        <InputFormTextSecondary
          name='name'
          label={getText('WORD_LOCATION_NAME')}
          allowClear={false}
          required
          errorMessage={getText('ERR_VALIDATION_REQUIRED')}
        />
        {Object.values(locationObj).length ? (
          <InputFormTextSecondary
            name='friendlyName'
            label={getText('WORD_FRIENDLY_LOCATION_NAME')}
            allowClear={false}
          />
        ) : null}
        <InputFormTextAreaSecondary
          name='address'
          label={getText('WORD_LOCATION_ADDRESS')}
          allowClear={false}
          autoSize={false}
          required
          message={getText('ERR_VALIDATION_REQUIRED')}
        />
        <InputFormEmailSecondary
          name='email'
          label={getText('WORD_LOCATION_CRM_EMAIL')}
          placeholder={getText('WORD_EMAIL')}
          allowClear={false}
        />
        <InputFormTextSecondary
          name='GATrackingNumber'
          label={getText('WORD_GA_TRACKING_ID')}
          allowClear={false}
          errorMessage='Please enter a valid Google Analytics 4 ID'
          value={GATrackingNumber}
          onChange={(e) => setGATrackingNumber(e.target.value)}
          placeholder='G-XXXXXXXXXX'
        />
        <GAInfo />
        {!isNewLocation && (
          <MatBlockWithLabel black label={getText('TITLE_USER_LIST')}>
            <div className='manage-users'>
              <MatButton
                onClick={async () => {
                  await authActions.setLocation(locationObj)
                  navigate('/users')
                }}
                htmlType='button'
                buttonText={getText('ACTION_MANAGERS_LIST')}
              />
              <MatButton
                buttonText={getText('ACTION_CREATE_USER')}
                htmlType='button'
                onClick={() => {
                  setUserModalVisible(!userModalVisible)
                }}
              />
            </div>
            {userModalVisible && (
              <UserModal
                userItem={false}
                user={user}
                visible={userModalVisible}
                organization={organization}
                setVisible={setUserModalVisible}
              />
            )}
          </MatBlockWithLabel>
        )}
      </MatForm>
    </MatadorConnectLayout>
  )
}

export default EditLocation
