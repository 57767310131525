import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width='337'
    height='122'
    viewBox='0 0 337 122'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M55.0039 34.3177C56.3051 34.1086 57.6399 34 59 34C72.8071 34 84 45.1929 84 59C84 72.8071 72.8071 84 59 84C57.6399 84 56.3051 83.8914 55.0039 83.6824C55.0297 90.5106 55.2273 94.2873 56.7439 97.2638C58.2779 100.274 60.7256 102.722 63.7362 104.256C67.1588 106 71.6392 106 80.6 106H311.4C320.361 106 324.841 106 328.264 104.256C331.275 102.722 333.722 100.274 335.256 97.2638C337 93.8413 337 89.3608 337 80.4V37.6C337 28.6392 337 24.1587 335.256 20.7362C333.722 17.7256 331.275 15.2779 328.264 13.7439C324.841 12 320.361 12 311.4 12H80.6C71.6392 12 67.1588 12 63.7362 13.7439C60.7256 15.2779 58.2779 17.7256 56.7439 20.7362C55.2273 23.7127 55.0297 27.4895 55.0039 34.3177Z'
      fill='white'
    />
    <g filter='url(#filter0_d_12860_26762)'>
      <circle cx='59' cy='59' r='21' fill='white' />
    </g>
    <path
      d='M56.7517 61.6717C55.974 61.7257 55.1879 61.8367 54.4125 62.0029C52.916 62.3111 51.7187 62.9271 51.2091 63.9506C51.0169 64.3509 50.9203 64.7795 50.9219 65.2134C50.9213 65.6441 51.0172 66.0733 51.2025 66.4659C51.6918 67.4776 52.7496 68.0502 54.178 68.3676L54.4338 68.421C55.1882 68.5912 55.9745 68.7058 56.7661 68.7595C56.8335 68.7793 56.9942 68.7977 57.1696 68.8066L57.3138 68.812C57.388 68.8138 57.4722 68.8142 57.5976 68.8142C58.7354 68.8768 59.9149 68.8586 61.0889 68.7586C61.7146 68.7158 62.3445 68.634 62.9691 68.5141L63.4364 68.4171C64.9788 68.1128 66.1338 67.5341 66.6398 66.467C67.0149 65.6747 67.0149 64.7554 66.64 63.9634C66.1352 62.899 64.995 62.3252 63.4247 62.0017C62.8086 61.8702 62.1826 61.7728 61.5521 61.7107L61.0911 61.6717C59.6474 61.5443 58.1954 61.5443 56.7517 61.6717Z'
      fill='#EA9957'
    />
    <path
      d='M58.9256 48.8496C55.9917 48.8496 53.6133 51.2381 53.6133 54.1845C53.6133 57.131 55.9917 59.5195 58.9256 59.5195C61.8595 59.5195 64.2379 57.131 64.2379 54.1845C64.2379 51.2381 61.8595 48.8496 58.9256 48.8496Z'
      fill='#EA9957'
    />
    <defs>
      <filter
        id='filter0_d_12860_26762'
        x='0'
        y='0'
        width='122'
        height='122'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dx='2' dy='2' />
        <feGaussianBlur stdDeviation='20' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0.32549 0 0 0 0 0.333333 0 0 0 0 0.501961 0 0 0 0.1 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow_12860_26762'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow_12860_26762'
          result='shape'
        />
      </filter>
    </defs>
  </svg>
)

export const SVGCongraWhite = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}
