import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width={props.width || '14'}
      height={props.height || '13'}
      viewBox='0 0 14 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.3381 3.16296H1.17648C0.911278 3.16296 0.696289 3.37795 0.696289 3.64315V11.7468C0.696289 12.012 0.911278 12.227 1.17648 12.227H12.3381C12.6033 12.227 12.8183 12.012 12.8183 11.7468V3.64315C12.8183 3.37795 12.6033 3.16296 12.3381 3.16296Z'
        fill='#4989F5'
      />
      <path
        d='M12.198 3.09497H1.30323C0.965746 3.09497 0.703125 5.70686 0.703125 6.04434L6.66945 12.2271H12.198C12.5366 12.2261 12.8108 11.9519 12.8117 11.6134V3.70849C12.8107 3.37001 12.5365 3.0959 12.198 3.09497Z'
        fill='url(#paint0_linear_1932_15047)'
      />
      <path
        d='M3.46387 4.68092H6.92729V0.135254H3.98247L3.46387 4.68092Z'
        fill='#3C4BA6'
      />
      <path
        d='M10.3902 4.68092H6.92676V0.135254H9.87157L10.3902 4.68092ZM12.8174 0.996942L12.8217 1.01244C12.8207 1.00697 12.8186 1.00189 12.8174 0.996942Z'
        fill='#7BABF7'
      />
      <path
        d='M12.8222 1.01231L12.8179 0.99682C12.7081 0.494012 12.2631 0.135457 11.7484 0.135132H9.87207L10.3903 4.68093H13.8537L12.8222 1.01231Z'
        fill='#3F51B5'
      />
      <path
        d='M1.03652 0.996948L1.03223 1.01244C1.03327 1.00697 1.03535 1.0019 1.03652 0.996948Z'
        fill='#7BABF7'
      />
      <path
        d='M1.03212 1.01231L1.03642 0.99682C1.14622 0.494012 1.59126 0.135457 2.10591 0.135132H3.98241L3.46342 4.68093H0L1.03212 1.01231Z'
        fill='#7BABF7'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.46928 4.67981C3.46928 5.63566 2.6944 6.41054 1.73854 6.41054C0.782694 6.41054 0.0078125 5.63566 0.0078125 4.67981H3.46928Z'
        fill='#709BE0'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.93119 4.67981C6.93119 5.63566 6.15631 6.41054 5.20046 6.41054C4.24461 6.41054 3.46973 5.63566 3.46973 4.67981H6.93119Z'
        fill='#3C4BA6'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.3921 4.67981C10.3921 5.63566 9.61725 6.41054 8.6614 6.41054C7.70555 6.41054 6.93066 5.63566 6.93066 4.67981H10.3921Z'
        fill='#709BE0'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.854 4.67981C13.854 5.63566 13.0792 6.41054 12.1233 6.41054C11.1675 6.41054 10.3926 5.63566 10.3926 4.67981H13.854Z'
        fill='#3C4BA6'
      />
      <path
        d='M12.1227 9.36882C12.1165 9.2864 12.1064 9.21114 12.0919 9.11505H10.204C10.204 9.3791 10.204 9.66724 10.2029 9.93143H11.2966C11.2734 10.0545 11.2261 10.1719 11.1574 10.2766C11.0887 10.3814 10.9999 10.4716 10.8963 10.542C10.8963 10.542 10.8963 10.5374 10.8955 10.5373C10.7616 10.6252 10.6108 10.684 10.4528 10.71C10.2944 10.739 10.1319 10.7383 9.97377 10.708C9.81317 10.6747 9.66111 10.6088 9.52704 10.5143C9.32915 10.374 9.17652 10.179 9.08787 9.95317C9.0811 9.93559 9.07485 9.91789 9.06847 9.89992V9.89796L9.07016 9.89666C8.98411 9.64411 8.98388 9.37021 9.06951 9.11752C9.12982 8.94026 9.22908 8.77876 9.35999 8.64488C9.6671 8.3272 10.126 8.20771 10.549 8.33526C10.7112 8.38501 10.8594 8.47226 10.9817 8.58994L11.3507 8.22094C11.4158 8.15519 11.4834 8.09152 11.546 8.02355C11.3589 7.84903 11.1393 7.71303 10.8997 7.6233C10.4636 7.46518 9.98653 7.461 9.54775 7.61146C9.53247 7.61666 9.51728 7.62209 9.50217 7.62773C9.0288 7.80569 8.63937 8.1547 8.41081 8.60582C8.3301 8.7655 8.27132 8.93534 8.23607 9.11075C8.02707 10.1494 8.66084 11.1718 9.68407 11.4466C10.0186 11.5362 10.3741 11.534 10.7112 11.4574C11.0175 11.3883 11.3007 11.2411 11.5334 11.0302C11.7761 10.8069 11.95 10.5094 12.0411 10.1934C12.1173 9.92561 12.145 9.64634 12.1227 9.36882Z'
        fill='white'
      />
      <defs>
        <linearGradient
          id='paint0_linear_1932_15047'
          x1='0.703125'
          y1='4243.45'
          x2='12884.1'
          y2='4243.45'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.03' stopColor='#4079D8' />
          <stop offset='1' stopColor='#4989F5' />
        </linearGradient>
      </defs>
    </svg>
  )
}

const SVGAiBotBusinesslcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGAiBotBusinesslcon
