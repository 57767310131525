import React from 'react'
import MatCheckbox from '../../../../components/MatCheckbox'
import { getText } from '../../../../lang'
import MatRow from '../../../../components/MatRow'
import { MANAGER, SUPPORT_AGENT } from '../../../../devOptions'

const getTitleOfRestriction = (restriction) => {
  let text = ''
  switch (restriction) {
    case 'nextScheduledDripSequenceMessage':
      text = getText(
        'TEXT_SELECT_ROLES_THAT_WILL_HAVE_OPTION_SKIP_NEXT_SCHEDULED_MESSAGE'
      )
      break
    case 'superhumanActionInConversation':
      text = getText('TEXT_SELECT_USER_ROLE_THAT_CAN_SNOOZE_SUPERHUMAN_REPLY')
      break
    default:
      break
  }
  return text
}

const RestrictionsCheckboxGroup = ({
  onChangeSwitch,
  currentRestriction,
  currentRestrictionName,
}) => {
  return (
    <React.Fragment>
      <p>{getTitleOfRestriction(currentRestrictionName)}</p>
      <MatRow>
        <MatCheckbox
          label={getText('WORD_ROLE_MANAGER')}
          value={currentRestriction.roles.includes(MANAGER)}
          onChange={() => {
            onChangeSwitch(true, MANAGER)
          }}
        />
        <MatCheckbox
          label={getText('WORD_ROLE_SUPPORT')}
          value={currentRestriction.roles.includes(SUPPORT_AGENT)}
          onChange={() => {
            onChangeSwitch(true, SUPPORT_AGENT)
          }}
        />
      </MatRow>
    </React.Fragment>
  )
}

export default RestrictionsCheckboxGroup
