import {
  fetchFromUrlPOSTAsync,
  fetchFromUrlGETAsync,
  fetchFromUrlPUTAsync,
  fetchFromUrlDELETEAsync,
  fetchFromUrlPATCHAsync,
} from '../../utils/UrlHelper'

const urlStories = '/stories'

const fs = {
  getAllList: async (page, perPage, locationId) => {
    const urlParams = []
    if (locationId) {
      urlParams.push({ key: 'locationId', value: locationId })
      urlParams.push({ key: 'page', value: page })
      urlParams.push({ key: 'perPage', value: perPage })
    }
    const result = await fetchFromUrlGETAsync(urlStories, urlParams)
    if (result.success) {
      result.max = result.data.total
      result.data = result.data.stories
    }
    return result
  },

  insert: async (obj) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    const result = await fetchFromUrlPOSTAsync(urlStories, urlParams)
    return result
  },

  update: async (obj, stories_id) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    const result = await fetchFromUrlPUTAsync(
      urlStories + '/' + stories_id,
      urlParams
    )
    return result
  },

  delete: async (id) => {
    const result = await fetchFromUrlDELETEAsync(urlStories + '/' + id, [])
    return result
  },

  reorderStories: async (locationId, stories) => {
    const urlParams = [
      { key: 'locationId', value: locationId },
      { key: 'stories', value: stories },
    ]
    const result = await fetchFromUrlPATCHAsync(urlStories, urlParams)
    return result
  },
}

const loc = {}

const storyActions = Object.assign(fs, loc)

export default storyActions
