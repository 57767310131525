import React from 'react'
import MatButton from '../../../../../../components/MatButton'
import MatRow from '../../../../../../components/MatRow'

const FormButtons = (props) => {
  const {
    prevAction,
    currentFormName,
    cancelFormBtn,
    submitFormBtn,
    disabled,
    onClickSubmit,
    className = '',
  } = props

  return (
    <MatRow
      className={`form-btn-wrapper ${className}`}
      style={{ marginTop: '65px', justifyContent: 'space-between' }}
    >
      <MatButton
        buttonText={cancelFormBtn}
        htmlType={''}
        onClick={() => prevAction(-1)}
        typeButton={'cancel'}
        size={'large'}
        style={{ maxWidth: '200px', width: '100%' }}
      />
      <MatButton
        onClick={onClickSubmit}
        formName={currentFormName || ''}
        buttonText={submitFormBtn}
        htmlType={'submit'}
        size={'large'}
        style={{ maxWidth: '200px', width: '100%' }}
        disabled={disabled}
      />
    </MatRow>
  )
}

export default FormButtons
