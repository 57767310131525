import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='1.66406'
        y='3.33301'
        width='16.6667'
        height='13.3333'
        rx='4'
        stroke={props.color || '#77808D'}
        strokeWidth='1.5'
      />
      <path
        d='M11.6693 8.33333C11.6693 7.41286 10.9231 6.66667 10.0026 6.66667C9.08213 6.66667 8.33594 7.41286 8.33594 8.33333C8.33594 9.25381 9.08213 10 10.0026 10'
        stroke={props.color || '#77808D'}
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M10.0026 9.99967C10.9231 9.99967 11.6693 10.7459 11.6693 11.6663C11.6693 12.5868 10.9231 13.333 10.0026 13.333C9.08213 13.333 8.33594 12.5868 8.33594 11.6663'
        stroke={props.color || '#77808D'}
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M10 5.41699V6.66699'
        stroke={props.color || '#77808D'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10 13.333V14.583'
        stroke={props.color || '#77808D'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

const SVGPayment = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGPayment
