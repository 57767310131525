import React from 'react'
import { getText } from '../../../../lang'
import InputFormNumber from '../../../../components/Form/InputFormNumber'

const MinimumTimeBetweenMessage = (props) => {
  const { selectedMessage, field } = props

  return (
    <div className='aibot-auto-message-descriptions'>
      <div className='ai-bot-message-description-first-part'>
        <span className='aibot-auto-message-descriptions-text'>
          <strong>{getText('TEXT_DONT_SEND_IF_LAST_MESSAGE_WAS')}</strong>
        </span>
        <InputFormNumber
          className='autoreply-follow-up-value'
          initialValue={{
            value:
              selectedMessage &&
              selectedMessage.minimumTimeBetweenMessages &&
              selectedMessage.minimumTimeBetweenMessages.value,
          }}
          name={[field, 'minimumTimeBetweenMessages', 'value']}
          min={3}
          placeholder={3}
          required
        />
        <span className='aibot-auto-message-descriptions-text'>
          <strong>{getText('WORD_DAYS').toLowerCase()}</strong>
        </span>
      </div>
      {props.children}
    </div>
  )
}

export default MinimumTimeBetweenMessage
