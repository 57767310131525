import React, { useState } from 'react'
import { Divider } from 'antd'
import SVGDelete from '../../../../icons/SVG/SVGDelete'
import SVGEdit from '../../../../icons/SVG/SVGEdit'
import { getText, getTextServerError } from '../../../../lang'
import ConfirmPopup from '../../../../components/ConfirmPopup'
import MatRow from '../../../../components/MatRow'
import MatSwitch from '../../../../components/MatSwitch'
import organizationActions from '../../../../store/modules/organizationActions'
import { DAYS, NIGHT } from '../../../../devOptions'
import { notifyError, notifySuccess } from '../../../../utils/Notify'
import SVGArrowCollapse from '../../../../icons/SVG/SVGArrowCollapse'

const DripSequenceListItem = (props) => {
  const { item, onDelete, onEdit, onStatusChange } = props

  const [isCollapsed, setisCollapsed] = useState(true)

  const handleChangeToggle = async () => {
    onStatusChange && onStatusChange()
    const objSend = {
      name: item.name,
      isActive: item.isActive,
      sources: item.sources || [],
      _organization_id: item._organization_id,
      triggeredOn: item.triggeredOn,
      first_message: item.first_message,
      firstMessageDelay: item.firstMessageDelay,
      firstMessageCommunicationChannel: item.firstMessageCommunicationChannel,
      ...(item.firstMessageCommunicationChannel === 'email' && {
        firstMessageSubject: item.firstMessageSubject,
      }),
    }
    if (item.remove_video) {
      objSend.remove_video = item.remove_video
    }
    if (item._video_upload_id) {
      objSend._video_upload_id = item._video_upload_id
    }
    if (item.first_message_media?.length) {
      objSend.first_message_media = item.first_message_media
    }
    // for (const lng in objSend.firstMessageSubject) {
    //   if (!languages.includes(lng)) {
    //     delete objSend.firstMessageSubject[lng]
    //   }
    // }

    if (item.triggeredOn === 'activity') {
      objSend.minimumTimeBetweenMessages = {
        unit: DAYS,
        value: item.minimumTimeBetweenMessages.value,
      }
    }

    const result = await organizationActions.saveUpdateSequence(objSend, item._id)
    if (result.success) {
      notifySuccess(getText('TEXT_SEQUENCE_WAS_SAVED_SUCCESSFULLY'))
    } else {
      onStatusChange && onStatusChange()
      notifyError(getTextServerError(result.errMsg, objSend.name))
    }
  }

  return (
    <MatRow className='ai-bot-list-item-wrapper' flexStart>
      <MatRow flexStart>
        <div className='ai-bot-list-item'>
          <p>{getText('TEXT_NAME_OF_SEQUENCE')}</p>
          <div className='title'>{item.name}</div>
          <Divider className='divider' />
          <div className='details_wrapper'>
            <p className='details_title'>{getText('WORD_TYPE')}</p>
            <p className='details_value'>
              {getText(
                item.type === NIGHT ? 'TEXT_AFTER_HOURS' : 'TEXT_WORKING_HOURS'
              )}
            </p>
          </div>
          <div className='details_wrapper' style={{ marginTop: 12 }}>
            <p className='details_title'>{getText('WORD_STATUS')}</p>
            <p className='details_value'>
              {getText(item.isActive ? 'WORD_ACTIVE' : 'WORD_INACTIVE')}
            </p>
            <MatSwitch value={item.isActive} onChange={handleChangeToggle} />
          </div>
        </div>

        <div className='ai-bot-list-item' style={{ width: '100%' }}>
          {item.triggeredOn === 'touchpoint' ? (
            <p>{getText('WORD_TOUCHPOINTS')}</p>
          ) : (
            <p>{getText('WORD_ACTIVITY')}</p>
          )}
          <div className='info-list-wrapper'>
            {item.sources_names &&
              (isCollapsed
                ? item.sources_names.slice(0, 15)
                : item.sources_names
              ).map((src, index) => {
                return (
                  <div key={index} className='info-item'>
                    {src.name}
                  </div>
                )
              })}
          </div>
        </div>
      </MatRow>
      <MatRow style={{ width: 'auto' }} className='edit-with-delete-btn'>
        <div onClick={onEdit} className='btn-block-item'>
          <SVGEdit color={'#747b95'} width={13} height={13} />
        </div>
        <ConfirmPopup
          title={getText('TEXT_ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_SEQUENCE')}
          placement={'topRight'}
          onConfirm={onDelete}
          okText={getText('WORD_YES')}
          cancelText={getText('WORD_NO')}
          trigger={
            <div className='btn-block-item'>
              <SVGDelete />
            </div>
          }
        />
        {Boolean(item.sources_names?.length > 15) && (
          <SVGArrowCollapse
            style={{
              cursor: 'pointer',
              transform: isCollapsed ? 'rotate(-180deg)' : '',
              marginLeft: 4,
            }}
            onClick={() => {
              setisCollapsed((ov) => !ov)
            }}
          />
        )}
      </MatRow>
    </MatRow>
  )
}

export default DripSequenceListItem
