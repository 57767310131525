import React from "react"

function SVGChat(props) {
  return (
    <svg width={30} height={30} viewBox="0 0 30 30" {...props}>
      <g data-name="Group 7423">
        <g data-name="Group 7283" transform="translate(-722 -341)">
          <circle
            data-name="Ellipse 603"
            cx={15}
            cy={15}
            r={15}
            transform="translate(722 341)"
            fill={props.color ? props.color: "#47a4fe"}
          />
          <g data-name="11 - Chat" fill={props.color ? props.color: "#fff"}>
            <path
              data-name="Path 5796"
              d="M742.766 352.679h-6.043a1.672 1.672 0 00-1.672 1.679v4.029a1.679 1.679 0 001.672 1.679h4.143l1.7 1.276a.316.316 0 00.2.067.363.363 0 00.148-.034.342.342 0 00.188-.3v-1.044a1.665 1.665 0 001.339-1.638v-4.035a1.665 1.665 0 00-1.675-1.679zm-.671 5.372h-4.7a.336.336 0 110-.671h4.7a.336.336 0 110 .671zm0-1.343h-4.7a.336.336 0 110-.671h4.7a.336.336 0 110 .671zm0-1.343h-4.7a.336.336 0 110-.671h4.7a.336.336 0 110 .671z"
            />
            <path
              data-name="Path 5797"
              d="M731.351 354.029h3.062a2.363 2.363 0 01.671-1.339h-3.733a.336.336 0 110-.671h6.379v-.347a1.672 1.672 0 00-1.679-1.672h-5.371a1.672 1.672 0 00-1.679 1.672v3.357a1.672 1.672 0 001.343 1.638v1.047a.342.342 0 00.188.3.363.363 0 00.148.034.316.316 0 00.2-.067l1.7-1.276h1.8v-2.015h-3.029a.333.333 0 110-.665z"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SVGChat
