import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M7.09513 2.44147C5.0243 2.44147 3.34513 4.12038 3.34513 6.19147C3.34513 8.26255 5.0243 9.94147 7.09513 9.94147C9.16597 9.94147 10.8451 8.26255 10.8451 6.19147C10.8451 4.12038 9.16597 2.44147 7.09513 2.44147ZM13.3451 5.7748C12.8851 5.7748 12.5118 6.14788 12.5118 6.60813C12.5118 7.06838 12.8851 7.44147 13.3451 7.44147H17.5118C17.9718 7.44147 18.3451 7.06838 18.3451 6.60813C18.3451 6.14788 17.9718 5.7748 17.5118 5.7748H13.3451ZM12.5118 9.10813C12.0518 9.10813 11.6785 9.48122 11.6785 9.94147C11.6785 10.4017 12.0518 10.7748 12.5118 10.7748H17.5118C17.9718 10.7748 18.3451 10.4017 18.3451 9.94147C18.3451 9.48122 17.9718 9.10813 17.5118 9.10813H12.5118ZM4.23013 11.0613C2.7018 11.692 1.67847 13.2382 1.67847 14.9415V16.6081C1.67847 17.0684 2.0518 17.4415 2.5118 17.4415H11.6785C12.1385 17.4415 12.5118 17.0684 12.5118 16.6081V14.9415C12.5118 13.2783 11.4826 11.7696 9.96014 11.1133C9.74514 11.021 9.49597 11.0181 9.28263 11.1133C8.54513 11.4438 7.8268 11.608 7.09513 11.6081C6.36597 11.6081 5.64513 11.4348 4.8818 11.0873C4.6718 10.9918 4.44346 10.9736 4.23013 11.0613ZM15.0118 12.4415C14.5518 12.4415 14.1785 12.8146 14.1785 13.2748C14.1785 13.7351 14.5518 14.1081 15.0118 14.1081H17.5118C17.9718 14.1081 18.3451 13.7351 18.3451 13.2748C18.3451 12.8146 17.9718 12.4415 17.5118 12.4415H15.0118Z'
      fill='#A3A1B7'
    />
  </svg>
)

const SVGRole = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGRole
