import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='773'
      height='239'
      viewBox='0 0 773 239'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        opacity='0.2'
        d='M79 145H178.214C211.289 145 243.222 132.908 268 111V111C292.778 89.0924 324.711 77 357.785 77H773'
        stroke='url(#paint0_linear_17366_55669)'
        strokeWidth='1.5'
      />
      <circle
        opacity='0.1'
        cx='79'
        cy='146'
        r='79'
        fill='url(#paint1_linear_17366_55669)'
      />
      <path
        d='M79 145H178.214C211.289 145 243.222 132.908 268 111V111C292.778 89.0924 324.711 77 357.785 77H773'
        stroke='url(#paint2_linear_17366_55669)'
        strokeWidth='50'
      />
      <circle cx='79' cy='146' r='65' fill='#8665F7' />
      <circle cx='79' cy='146' r='58' fill='#7854F6' />
      <circle cx='79' cy='146' r='57.5' stroke='white' strokeOpacity='0.3' />
      <path
        d='M77.2878 146.18H80.0718C80.715 146.18 81.2398 145.655 81.2398 145.012V134.868C81.2398 134.225 80.715 133.7 80.0718 133.7H77.2878C76.6446 133.7 76.1198 134.225 76.1198 134.868V145.012C76.1198 145.655 76.6446 146.18 77.2878 146.18ZM77.3998 134.98H79.9598V144.9H77.3998V134.98Z'
        fill='white'
      />
      <path
        d='M84.9678 146.18H87.4318C88.075 146.18 88.5998 145.655 88.5998 145.012V131.988C88.5998 131.345 88.075 130.82 87.4318 130.82H84.9678C84.3246 130.82 83.7998 131.345 83.7998 131.988V145.012C83.7998 145.655 84.3246 146.18 84.9678 146.18ZM85.0798 132.1H87.3198V144.9H85.0798V132.1Z'
        fill='white'
      />
      <path
        d='M69.9278 146.18H72.3918C73.035 146.18 73.5598 145.655 73.5598 145.012V137.748C73.5598 137.105 73.035 136.58 72.3918 136.58H69.9278C69.2846 136.58 68.7598 137.105 68.7598 137.748V145.012C68.7598 145.655 69.2846 146.18 69.9278 146.18ZM70.0398 137.86H72.2798V144.9H70.0398V137.86Z'
        fill='white'
      />
      <path d='M85.0798 132.1H87.3198V144.9H85.0798V132.1Z' fill='white' />
      <path d='M77.3998 134.98H79.9598V144.9H77.3998V134.98Z' fill='white' />
      <path d='M70.0398 137.86H72.2798V144.9H70.0398V137.86Z' fill='white' />
      <path
        opacity='0.15'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M462 202L462 134L462 66L542 66L542 134L542 202L462 202Z'
        fill='url(#paint3_linear_17366_55669)'
      />
      <path
        d='M502 67L502 207'
        stroke='url(#paint4_linear_17366_55669)'
        strokeWidth='50'
      />
      <circle opacity='0.2' cx='502' cy='77' r='45' fill='white' />
      <circle cx='502' cy='77' r='40' fill='#5AB78D' />
      <circle cx='502' cy='77' r='35' fill='#48AF80' />
      <circle cx='502' cy='77' r='34.5' stroke='white' strokeOpacity='0.3' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M505.894 88.1882L511.754 68.9807C512.023 68.0866 511.006 67.0695 510.112 67.339L490.905 73.1992C490.172 73.4145 490.085 74.3761 490.718 75.0095L494.824 79.1297C495.27 79.5758 495.92 79.6937 496.332 79.3881L508.168 70.9252L499.696 82.7517C499.399 83.1733 499.517 83.8227 499.963 84.2688L504.083 88.3747C504.717 89.0081 505.678 88.921 505.894 88.1882Z'
        fill='white'
      />
      <circle cx='502' cy='197' r='40' fill='white' />
      <circle cx='502' cy='197' r='39.5' stroke='#48AF80' strokeOpacity='0.3' />
      <path
        d='M511.567 173.903C508.534 172.647 505.283 172 502 172C498.717 172 495.466 172.647 492.433 173.903C489.4 175.159 486.644 177.001 484.322 179.322C482.001 181.644 480.159 184.4 478.903 187.433C477.647 190.466 477 193.717 477 197C477 200.283 477.647 203.534 478.903 206.567C480.159 209.6 482.001 212.356 484.322 214.678C486.644 216.999 489.4 218.841 492.433 220.097C495.466 221.353 498.717 222 502 222C505.283 222 508.534 221.353 511.567 220.097C514.6 218.841 517.356 216.999 519.678 214.678C521.999 212.356 523.841 209.6 525.097 206.567C526.353 203.534 527 200.283 527 197C527 193.717 526.353 190.466 525.097 187.433C523.841 184.4 521.999 181.644 519.678 179.322C517.356 177.001 514.6 175.159 511.567 173.903Z'
        fill='#48AF80'
      />
      <path
        d='M502 172C505.283 172 508.534 172.647 511.567 173.903C514.6 175.159 517.356 177.001 519.678 179.322C521.999 181.644 523.841 184.4 525.097 187.433C526.353 190.466 527 193.717 527 197C527 200.283 526.353 203.534 525.097 206.567C523.841 209.6 521.999 212.356 519.678 214.678C517.356 216.999 514.6 218.841 511.567 220.097C508.534 221.353 505.283 222 502 222C498.717 222 495.466 221.353 492.433 220.097C489.4 218.841 486.644 216.999 484.322 214.678C482.001 212.356 480.159 209.6 478.903 206.567C477.647 203.534 477 200.283 477 197C477 193.717 477.647 190.466 478.903 187.433C480.159 184.4 482.001 181.644 484.322 179.322C486.644 177.001 489.4 175.159 492.433 173.903C495.466 172.647 498.717 172 502 172Z'
        fill='#48AF80'
      />
      <g opacity='0.6' filter='url(#filter0_f_17366_55669)'>
        <path
          d='M509.654 185.522C507.227 184.517 504.626 184 502 184C499.374 184 496.773 184.517 494.346 185.522C491.92 186.527 489.715 188.001 487.858 189.858C486.001 191.715 484.528 193.92 483.522 196.346C482.517 198.773 482 201.374 482 204C482 206.626 482.517 209.227 483.522 211.654C484.527 214.08 486.001 216.285 487.858 218.142C489.715 219.999 491.92 221.472 494.346 222.478C496.773 223.483 499.374 224 502 224C504.626 224 507.227 223.483 509.654 222.478C512.08 221.472 514.285 219.999 516.142 218.142C517.999 216.285 519.472 214.08 520.478 211.654C521.483 209.227 522 206.626 522 204C522 201.374 521.483 198.773 520.478 196.346C519.472 193.92 517.999 191.715 516.142 189.858C514.285 188.001 512.08 186.528 509.654 185.522Z'
          fill='#48AF80'
        />
        <path
          d='M502 184C504.626 184 507.227 184.517 509.654 185.522C512.08 186.528 514.285 188.001 516.142 189.858C517.999 191.715 519.472 193.92 520.478 196.346C521.483 198.773 522 201.374 522 204C522 206.626 521.483 209.227 520.478 211.654C519.472 214.08 517.999 216.285 516.142 218.142C514.285 219.999 512.08 221.472 509.654 222.478C507.227 223.483 504.626 224 502 224C499.374 224 496.773 223.483 494.346 222.478C491.92 221.472 489.715 219.999 487.858 218.142C486.001 216.285 484.527 214.08 483.522 211.654C482.517 209.227 482 206.626 482 204C482 201.374 482.517 198.773 483.522 196.346C484.528 193.92 486.001 191.715 487.858 189.858C489.715 188.001 491.92 186.527 494.346 185.522C496.773 184.517 499.374 184 502 184Z'
          fill='#48AF80'
        />
      </g>
      <path
        opacity='0.2'
        d='M502 165C506.202 165 510.363 165.828 514.246 167.436C518.128 169.044 521.656 171.401 524.627 174.373C527.599 177.344 529.956 180.872 531.564 184.754C533.172 188.637 534 192.798 534 197C534 201.202 533.172 205.363 531.564 209.246C529.956 213.128 527.599 216.656 524.627 219.627C521.656 222.599 518.128 224.956 514.246 226.564C510.363 228.172 506.202 229 502 229C497.798 229 493.637 228.172 489.754 226.564C485.872 224.956 482.344 222.599 479.373 219.627C476.401 216.656 474.044 213.128 472.436 209.246C470.828 205.363 470 201.202 470 197C470 192.798 470.828 188.637 472.436 184.754C474.044 180.872 476.401 177.344 479.373 174.373C482.344 171.401 485.872 169.044 489.754 167.436C493.637 165.828 497.798 165 502 165L502 165Z'
        stroke='#48AF80'
        strokeWidth='10'
      />
      <defs>
        <filter
          id='filter0_f_17366_55669'
          x='467'
          y='169'
          width='70'
          height='70'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='7.5'
            result='effect1_foregroundBlur_17366_55669'
          />
        </filter>
        <linearGradient
          id='paint0_linear_17366_55669'
          x1='79'
          y1='154'
          x2='773'
          y2='145'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.168185' stopColor='white' stopOpacity='0.1' />
          <stop offset='0.255165' stopColor='white' />
          <stop offset='0.434127' stopColor='white' stopOpacity='0.1' />
          <stop offset='0.616824' stopColor='white' stopOpacity='0.1' />
          <stop offset='0.714906' stopColor='white' />
          <stop offset='0.806682' stopColor='white' stopOpacity='0.1' />
          <stop offset='0.937614' stopColor='white' stopOpacity='0.1' />
          <stop offset='1' stopColor='white' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_17366_55669'
          x1='0'
          y1='146'
          x2='158'
          y2='146'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#7854F6' />
          <stop offset='1' stopColor='#7854F6' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_17366_55669'
          x1='79'
          y1='132'
          x2='790'
          y2='132'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.128848' stopColor='#8665F7' />
          <stop offset='0.31845' stopColor='#6E82CC' />
          <stop offset='0.509316' stopColor='#48AF8A' />
          <stop offset='0.672373' stopColor='#48AF8A' />
          <stop offset='0.878252' stopColor='#F8BA05' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_17366_55669'
          x1='502'
          y1='66'
          x2='502'
          y2='202'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#48AF80' />
          <stop offset='1' stopColor='#48AF80' stopOpacity='0.3' />
        </linearGradient>
        <linearGradient
          id='paint4_linear_17366_55669'
          x1='502.5'
          y1='67'
          x2='502.5'
          y2='207'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#48AF80' stopOpacity='0.2' />
          <stop offset='1' stopColor='#48AF80' stopOpacity='0.1' />
        </linearGradient>
      </defs>
    </svg>
  )
}

export const SVGBotAndRating = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}
