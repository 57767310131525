import React from 'react';
import Icon from '@ant-design/icons';

const SVGIcon = (props) => (
    <svg width={17.164} height={17.164}>
        <path
            data-name="Path 5777"
            d="M4.29 12.873a6.068 6.068 0 118.582 0 6.068 6.068 0 01-8.581 0zm3.984-5.211L6.59 5.976a.433.433 0 00-.613.614l1.685 1.685a.433.433 0 010 .612l-1.685 1.686a.433.433 0 00.613.613L8.274 9.5a.433.433 0 01.616 0l1.685 1.686a.433.433 0 00.613-.613L9.503 8.887a.433.433 0 01-.002-.614l1.685-1.685a.433.433 0 00-.613-.613L8.888 7.66a.433.433 0 01-.614.002z"
            fill={props.color ? props.color : "#a3a6be"}
            fillRule="evenodd"
        />
    </svg>
);

const SVGCloseRound = props => <Icon component={SVGIcon} {...props} />;

export default SVGCloseRound;