import React from 'react';
import Icon from '@ant-design/icons';

const SVGIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="13.981"
        height="14"
        viewBox="0 0 13.981 14"
    >
        <path id="Intersection_1"
            data-name="Intersection 1"
            d="M9.086,17a7.069,7.069,0,0,1-6.009-5.552V8.537A7.069,7.069,0,0,1,8.974,3h2.037A7.066,7.066,0,0,1,10.9,17Zm1.264-5.936,1.962,1.963a.505.505,0,0,0,.715-.715L11.064,10.35a.506.506,0,0,1,0-.715l1.963-1.962a.505.505,0,0,0-.715-.715L10.35,8.922a.506.506,0,0,1-.715,0L7.673,6.959a.5.5,0,0,0-.714.715L8.922,9.635a.506.506,0,0,1,0,.715L6.959,12.312a.5.5,0,0,0,.714.715l1.963-1.963a.506.506,0,0,1,.715,0Z"
            transform="translate(-3.077 -3)"
            fill={props.color ? props.color : "#ff7366"}
        />
    </svg>
);

const SVGClearCircle = props => <Icon component={SVGIcon} {...props} />;

export default SVGClearCircle;