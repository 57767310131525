import React, { useEffect, useState } from 'react'
import { Modal } from 'antd'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import { notifyError, notifySuccess } from '../../../../../utils/Notify'
import questionsActions from '../../../../../store/modules/questionsActions'
import { getText, getTextServerError } from '../../../../../lang'
import LoadingSpinner from '../../../../../components/LoadingSpinner'
import MatList from '../../../../../components/MatList'
import { TABLE_DEFAULT_ROW_NUMBER } from '../../../../../devOptions'
import MatButton from '../../../../../components/MatButton'
import MatadorConnectLayout from '../matadorConnectComponents/MatadorConnectLayout'
import MatadorConnectListTitle from '../matadorConnectComponents/MatadorConnectListTitle'
import MatadorConnectListItem from '../matadorConnectComponents/MatadorConnectListItem'
import QuestionEditModal from './QuestionEditModal'
import './Questions.scss'

const Questions = (props) => {
  const { locationObj, organization } = props
  const [isLoading, setIsLoading] = useState(true)
  const [modalEditOpen, setModalEditOpen] = useState(false)
  const [modalNewOpen, setModalNewOpen] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)
  const [questionsData, setQuestionsData] = useState({
    list: [],
    total: 0,
  })
  const [questionsDataItem, setQuestionsDataItem] = useState()

  const { _id } = useParams()

  useEffect(() => {
    props.setFooterButtons && props.setFooterButtons(['cancel'])
  }, [])

  useEffect(() => {
    getQuestions()
  }, [currentPage])

  const getQuestions = async () => {
    setIsLoading(true)
    const options = { _location_id: _id }
    const result = await questionsActions.getPageList(
      currentPage,
      TABLE_DEFAULT_ROW_NUMBER,
      '',
      options
    )
    if (result.success) {
      setQuestionsData({
        list: result.data,
        total: result.max,
      })
    }
    setIsLoading(false)
  }

  const handleChangeQuestion = (val, item) => {
    if (val === 'edit') {
      setModalEditOpen(true)
      setQuestionsDataItem(item)
    } else if (val === 'delete') {
      Modal.confirm({
        title: getText('CONFIRMATION_DELETE_QUESTION'),
        async onOk() {
          const result = await questionsActions.delete(item._id)
          if (result.success) {
            notifySuccess(getText('SUCCESS_QUESTION_DELETE'))
          } else {
            notifyError(getTextServerError(result.errMsg))
          }
          await getQuestions()
        },
        okText: getText('WORD_YES'),
        cancelText: getText('ACTION_CANCEL'),
        okButtonProps: {
          type: 'primary',
          className: 'mat-btn mat-btn-primary',
        },
        cancelButtonProps: {
          type: 'cancel',
          className: 'mat-btn',
        },
      })
    }
  }

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <MatadorConnectLayout className='livechat-questions-wrapper'>
      <MatadorConnectListTitle
        title={getText('WORD_TOTAL_QUESTIONS')}
        modalButton={
          <QuestionEditModal
            trigger={
              <MatButton
                htmlType={'button'}
                buttonText={'+ ' + getText('ACTION_ADD_QUESTION')}
                onClick={() => {
                  setModalNewOpen(true)
                }}
              />
            }
            locationObj={locationObj}
            organization={organization}
            refreshList={getQuestions}
            modalOpen={modalNewOpen}
            setModalOpen={setModalNewOpen}
          />
        }
      />
      <MatList
        loading={isLoading}
        data={questionsData.list}
        pagination
        paginationCurrentPage={currentPage}
        paginationTotalCount={questionsData.total}
        onPaginationChange={setCurrentPage}
        onPageChange={(pageNumber, totalItems) => {
          setCurrentPage(pageNumber)
          props.onHandleChangePage &&
            props.onHandleChangePage(pageNumber, totalItems)
        }}
        paginationRange={5}
        totalItems={questionsData.total}
        containerClassName='matador-connect-mat-list-wrapper'
        header={
          <div className='matador-connect-mat-list-header'>
            <div className='matador-connect-mat-list-header-item'>
              {getText('WORD_TITLE')}
            </div>
            <div className='matador-connect-mat-list-header-item'>
              {getText('WORD_DATE')}
            </div>
            <div className='matador-connect-mat-list-header-item' />
          </div>
        }
        renderItem={(questionData, index) => (
          <MatadorConnectListItem
            key={questionData.id}
            style={{
              backgroundColor: index % 2 === 0 ? '#F8F9F9' : '#ffffff',
            }}
            item={questionData}
            handleEdit={handleChangeQuestion}
          />
        )}
      />
      {!_.isEmpty(questionsDataItem) && (
        <QuestionEditModal
          locationObj={locationObj}
          organization={organization}
          modalOpen={modalEditOpen}
          setModalOpen={setModalEditOpen}
          questionData={questionsDataItem}
          refreshList={getQuestions}
        />
      )}
    </MatadorConnectLayout>
  )
}

export default Questions
