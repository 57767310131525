import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g id='Iconly/Bold/Profile' opacity='0.5'>
      <g id='Vector'>
        <path
          d='M17.8608 12.3939L17.06 14.3362H18.6616L17.8608 12.3939Z'
          fill='#A3A1B7'
        />
        <path
          d='M10.68 15.4774V16.9543C10.68 18.006 11.5204 18.8608 12.5544 18.8608H18.1952L20.1708 20.8702C20.2544 20.9552 20.3688 21 20.4788 21C20.536 21 20.5932 20.9866 20.6504 20.9642C20.8132 20.8971 20.9188 20.7315 20.9188 20.5525V18.8608H21.1256C22.1596 18.8608 23 18.006 23 16.9543V10.644C23 9.59233 22.1596 8.73754 21.1256 8.73754H16.004V12.4476C16.004 14.1169 14.6664 15.4774 13.0208 15.4774H10.68ZM15.3484 16.1845L15.9952 14.6137V14.6092L17.456 11.0602C17.522 10.8902 17.6848 10.7828 17.8608 10.7828C18.0368 10.7828 18.1996 10.8902 18.2656 11.0602L19.7264 14.6092L19.7308 14.6137L20.3732 16.1845C20.47 16.4128 20.3644 16.6723 20.14 16.7708C20.0828 16.7932 20.0256 16.8021 19.9684 16.8021C19.7968 16.8021 19.634 16.6992 19.5636 16.5291L19.0312 15.2313H16.6904L16.158 16.5291C16.0656 16.7574 15.806 16.8648 15.586 16.7708C15.3616 16.6768 15.256 16.4128 15.3484 16.1845Z'
          fill='#A3A1B7'
        />
        <path
          d='M8.98158 7.81537H7.14238C7.34038 8.21815 7.64398 8.7731 8.06198 9.31909C8.47998 8.7731 8.78358 8.21815 8.98158 7.81537Z'
          fill='#A3A1B7'
        />
        <path
          d='M3.0988 14.5822H3.4068V16.6095C3.4068 16.793 3.5124 16.9541 3.6752 17.0257C3.7324 17.0481 3.7896 17.0571 3.8468 17.0571C3.9612 17.0571 4.0712 17.0123 4.1548 16.9273L6.4648 14.5822H13.0208C14.178 14.5822 15.124 13.6245 15.124 12.4475V5.13922C15.124 3.9622 14.178 3 13.0208 3H3.0988C1.9416 3 1 3.9622 1 5.13922V12.443C1 13.6245 1.9416 14.5822 3.0988 14.5822ZM4.9776 6.92041H7.622V6.02981C7.622 5.78367 7.8156 5.58228 8.062 5.58228C8.304 5.58228 8.502 5.78367 8.502 6.02981V6.92041H11.1464C11.3884 6.92041 11.5864 7.1218 11.5864 7.36794C11.5864 7.61409 11.3884 7.81548 11.1464 7.81548H9.954C9.7648 8.26301 9.338 9.14018 8.656 9.99497C9.184 10.5141 9.822 10.9482 10.5744 11.1138C10.812 11.1675 10.966 11.4047 10.9132 11.6464C10.8692 11.8567 10.6844 11.9999 10.482 11.9999C10.4512 11.9999 10.4204 11.9955 10.3896 11.9865C9.4568 11.7806 8.6824 11.266 8.062 10.6573C7.4372 11.266 6.6672 11.7806 5.7344 11.9865C5.7036 11.9955 5.6684 11.9999 5.6376 11.9999C5.4352 11.9999 5.2548 11.8567 5.2108 11.6464C5.158 11.4047 5.3076 11.1675 5.5452 11.1138C6.302 10.9437 6.94 10.5141 7.4636 9.99497C6.7816 9.14466 6.3592 8.26301 6.1656 7.81548H4.9776C4.7312 7.81548 4.5376 7.61409 4.5376 7.36794C4.5376 7.1218 4.7312 6.92041 4.9776 6.92041Z'
          fill='#A3A1B7'
        />
      </g>
    </g>
  </svg>
)

const SVGLanguageNew = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGLanguageNew
