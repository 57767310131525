import React from 'react'
import { getText } from '../../lang'
import SVGPaymentAmericanExpressIcon from '../../icons/SVG/SVGPaymentAmericanExpressIcon'
import SVGPaymentCancelledIcon from '../../icons/SVG/SVGPaymentCancelledIcon'
import SVGPaymentMasterCardIcon from '../../icons/SVG/SVGPaymentMasterCardIcon'
import SVGPaymentPaidIcon from '../../icons/SVG/SVGPaymentPaidIcon'
import SVGPaymentPenddingIcon from '../../icons/SVG/SVGPaymentPenddingIcon'
import SVGPaymentRefundedIcon from '../../icons/SVG/SVGPaymentRefundedIcon'
import SVGPaymentRejectedIcon from '../../icons/SVG/SVGPaymentRejectedIcon'
import SVGPaymentVisaIcon from '../../icons/SVG/SVGPaymentVisaIcon'

export const getCard = (card) => {
  switch (card.brand) {
    case 'american_express':
      return (
        <div className='with-icon'>
          <SVGPaymentAmericanExpressIcon />
          <span>{card.last4}</span>
        </div>
      )
    case 'master_card':
      return (
        <div className='with-icon'>
          <SVGPaymentMasterCardIcon />
          <span>{card.last4}</span>
        </div>
      )
    default:
      return (
        <div className='with-icon'>
          <SVGPaymentVisaIcon />
          <span>{card.last4}</span>
        </div>
      )
  }
}

export const getStatusFilterIndex = (status) => {
  switch (status) {
    case 'paid':
      return 1
    case 'not_paid':
      return 2
    case 'rejected':
      return 4
    case 'canceled':
      return 5
    default:
      return 3
  }
}

export const getStatus = (status) => {
  switch (status) {
    case 'not_paid':
      return (
        <div className='with-icon pending'>
          <SVGPaymentPenddingIcon />
          <span style={{ color: '#F6A55B' }}>{getText('WORD_PENDING')}</span>
        </div>
      )
    case 'rejected':
      return (
        <div className='with-icon rejected'>
          <SVGPaymentRejectedIcon />
          <span style={{ color: '#EE7B6C' }}>{getText('WORD_REJECTED')}</span>
        </div>
      )
    case 'paid':
      return (
        <div className='with-icon paid'>
          <SVGPaymentPaidIcon />
          <span style={{ color: '#48AF80' }}>{getText('WORD_PAID')}</span>
        </div>
      )
    case 'canceled':
      return (
        <div className='with-icon canceled'>
          <SVGPaymentCancelledIcon color={'#474657'} />
          <span style={{ color: '#474657' }}>{getText('WORD_CANCELED')}</span>
        </div>
      )
    default:
      return (
        <div className='with-icon refunded'>
          <SVGPaymentRefundedIcon />
          <span style={{ color: '#7854F6' }}>{getText('WORD_REFUNDED')}</span>
        </div>
      )
  }
}

export const amountValue = (amount, currency) => {
  return `$ ${Number(amount).toFixed(2)} ${currency}`
}

export const getDepositStatus = (status) => {
  switch (status) {
    case 'not_paid':
      return (
        <div className='status-block pending' style={{ backgroundColor: '#F6A55B' }}>
          <span>{getText('WORD_PENDING')}</span>
        </div>
      )
    case 'rejected':
      return (
        <div
          className='status-block rejected'
          style={{ backgroundColor: '#EE7B6C' }}
        >
          <span>{getText('WORD_REJECTED')}</span>
        </div>
      )
    case 'paid':
      return (
        <div className='status-block paid' style={{ backgroundColor: '#48AF80' }}>
          <span>{getText('WORD_PAID')}</span>
        </div>
      )
    case 'canceled':
      return (
        <div
          className='status-block canceled'
          style={{ backgroundColor: '#474657' }}
        >
          <span>{getText('WORD_CANCELED')}</span>
        </div>
      )
    default:
      return (
        <div
          className='status-block refunded'
          style={{ backgroundColor: '#7854F6' }}
        >
          <span>{getText('WORD_REFUNDED')}</span>
        </div>
      )
  }
}

export function getEmptyResult(status, setData, obj) {
  const statusList = [
    getText('MSG_YOU_DONT_HAVE_ACCESS_TO_GET_PAYMENTS'),
    'You do not have access to list Reports. Please, contact your manager',
  ]
  if (statusList.includes(status)) {
    if (obj && Object.values(obj).length) {
      setData(obj)
    } else {
      setData([])
    }
  }
}
