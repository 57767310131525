import React, { forwardRef, useEffect, useState } from 'react'
import { getText } from '../../lang'
import SelectFormSecondary from '../Form/SelectForm/SelectFormSecondary'

const getDelayOptions = () => {
  const list = [
    { value: '', label: getText('WORD_SELECT_DELAY') },
    { value: 5, label: `5 ${getText('WORD_SECONDS')}` },
    { value: 10, label: `10 ${getText('WORD_SECONDS')}` },
    { value: 15, label: `15 ${getText('WORD_SECONDS')}` },
    { value: 20, label: `20 ${getText('WORD_SECONDS')}` },
    { value: 25, label: `25 ${getText('WORD_SECONDS')}` },
    { value: 30, label: `30 ${getText('WORD_SECONDS')}` },
  ]

  return list
}

const SelectDelay = forwardRef((props, ref) => {
  const [listData, setListData] = useState([])
  const [loadingData, setLoadingData] = useState(true)

  useEffect(() => {
    setLoadingData(true)
    setListData(
      getDelayOptions().map((oo) => {
        return { value: oo.value, label: oo.label }
      })
    )
    setLoadingData(false)
  }, [props.location])

  return (
    <SelectFormSecondary
      ref={ref}
      {...props}
      options={listData}
      loading={loadingData}
      showSearch={false}
    />
  )
})

export default SelectDelay
