import React, { useState } from 'react'
import CheckableTag from 'antd/lib/tag/CheckableTag'
import { getContentMessage } from '../../utils'
import { getText, getTextServerError } from '../../../../../../lang'
import { default as campaignActions } from '../../../../../../store/modules/broadcastingActions'
import InputFormTextArea from '../../../../../../components/Form/InputFormTextArea'
import { notifyError } from '../../../../../../utils/Notify'
import MatButton from '../../../../../../components/MatButton'
import BuildContent from './BuildContent'

const AIContent = ({
  textMessage,
  settextMessage,
  onStateChange,
  organization,
  setAiMessage,
  textAreRef,
  addNameInAIMessenger,
  aiMessage,
  form,
  setGeneratedLoading,
  generatedLoading,
  required,
  setTabValue,
  setAiNoteAvailable,
  tabValue,
}) => {
  const [selectedDynamicTags, setSelectedDynamicTags] = useState([])
  const handleChange = (tag, checked) => {
    const nextSelectedTags = checked
      ? [...selectedDynamicTags, tag]
      : selectedDynamicTags.filter((t) => t !== tag)
    setSelectedDynamicTags(nextSelectedTags)
  }

  const generateAIContent = async () => {
    setGeneratedLoading(true)
    const result = await campaignActions.getAIGeneratedContent(
      aiMessage,
      selectedDynamicTags
    )
    if (result.success) {
      getContentMessage(
        tabValue,
        textMessage,
        settextMessage,
        onStateChange,
        result.data.message
      )
    } else {
      if (result.errCode === 500) {
        setAiNoteAvailable(true)
        setTabValue('build_your_own')
        notifyError(getText('TEXT_AI_MESSAGE_GENERATION_IS_NOT_AVAILABLE'))
      } else {
        notifyError(getTextServerError(result.errMsg))
      }
    }
    setGeneratedLoading(false)
  }

  const listVariables = [
    {
      label: 'customer_first_name',
      value: ' {{customer_first_name}} ',
    },
    {
      label: 'customer_last_name',
      value: ' {{customer_last_name}} ',
    },
    {
      label: 'review_invite_url',
      value: ' {{review_invite_url}} ',
    },
  ]

  return (
    <div className='ai-broadcast-content'>
      <div className='powerfull-text-generated'>
        {getText('WORD_INSTANTLY_CREATE_YOUR_CAMPAIGN_WITH_AI')}
      </div>
      <div
        style={{ color: '#0e0e0e', fontWeight: 'bold' }}
        className='powerfull-text-generated'
      >
        {getText('TEXT_AUTOMATICALLY_USED_BY_AI')}
      </div>
      <div className='list-variables'>
        {[
          {
            label: 'organization_name',
            value: ' {{organization_name}} ',
          },
        ].map((tag) => {
          return (
            <CheckableTag
              style={{ cursor: 'default', opacity: '0.5' }}
              key={tag.value}
              className={'add-variable'}
            >
              {`{{ ${tag.label} }}`}
            </CheckableTag>
          )
        })}
      </div>
      <div
        style={{ color: '#0e0e0e', fontWeight: 'bold' }}
        className='powerfull-text-generated'
      >
        {getText(
          'WORD_THIS_TAG_IS_AUTOMATICALLY_SELECTED_ITS_BEST_PRACTICE_IDENTIFY_YOURSELF'
        )}
      </div>
      <div className='list-variables'>
        {listVariables.map((tag) => {
          const tagValue = tag.value.trim()
          return (
            <CheckableTag
              key={tag.value}
              className={'add-variable'}
              checked={selectedDynamicTags.includes(tagValue)}
              onChange={(checked) => handleChange(tagValue, checked)}
            >
              {`{{ ${tag.label} }}`}
            </CheckableTag>
          )
        })}
      </div>
      <div className='broadcast-ai-generate-with-button'>
        <InputFormTextArea
          name={'aiInputMessage'}
          placeholder={getText('TEXT_WRITE_YOUR_IDEA')}
          autoSize={{ minRows: 1, maxRows: 6 }}
          onChange={(e) => {
            if (e.target.value === '') {
              if (tabValue === 'ai_generated') {
                form.setFieldsValue({
                  inputMessage: {
                    ...textMessage,
                    aiGeneratedText: '',
                  },
                })
              } else {
                form.setFieldsValue({
                  inputMessage: {
                    ...textMessage,
                    buildYourOwnText: '',
                  },
                })
              }
              getContentMessage(
                tabValue,
                textMessage,
                settextMessage,
                onStateChange,
                ''
              )
            }
            setAiMessage(e.target.value)
            onStateChange('aiInputMessage', e.target.value)
          }}
          required={required}
          allowClear={false}
          message={getText('ERROR_PLEASE_FILL_OUT_THE_MESSAGE_TEXT_FIELD')}
        />
        <MatButton
          buttonText={getText('WORD_GENERATE')}
          loading={generatedLoading}
          htmlType={''}
          onClick={generateAIContent}
          typeButton={'radius'}
          size={'small'}
          className='ai-genrate-content-button'
        />
      </div>
      <BuildContent
        listVariables={listVariables}
        textMessage={textMessage}
        settextMessage={settextMessage}
        onStateChange={onStateChange}
        organization={organization}
        addNameInAIMessenger={addNameInAIMessenger}
        textAreRef={textAreRef}
        hideDynamicTags
        showTextarea={
          tabValue === 'ai_generated'
            ? Boolean(textMessage.aiGeneratedText)
            : Boolean(textMessage.buildYourOwnText)
        }
        tabValue={tabValue}
      />
    </div>
  )
}

export default AIContent
