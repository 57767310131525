import React from 'react'
import PageFull from '../../../../components/PageFull'
import './OrganizationNewPage.scss'

const OrganizationLayout = ({ children, className }) => {
  return (
    <PageFull className={`organization-new-page-wrapper ${className || ''}`}>
      {children}
    </PageFull>
  )
}

export default OrganizationLayout
