import * as React from 'react'

function SVGDate(props) {
  return (
    <span className='anticon'>
      <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M6.66406 1.66699V4.16699'
          stroke={props.color || '#77808D'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M13.3359 1.66699V4.16699'
          stroke={props.color || '#77808D'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M2.5 6.91699C2.5 4.70786 4.29086 2.91699 6.5 2.91699H13.5C15.7091 2.91699 17.5 4.70785 17.5 6.91699V14.3337C17.5 16.5428 15.7091 18.3337 13.5 18.3337H6.5C4.29086 18.3337 2.5 16.5428 2.5 14.3337V6.91699Z'
          stroke={props.color || '#77808D'}
          strokeWidth='1.5'
        />
        <path
          d='M2.5 7.5H17.5'
          stroke={props.color || '#77808D'}
          strokeWidth='1.5'
          strokeLinecap='round'
        />
        <path
          d='M10 10V15'
          stroke={props.color || '#77808D'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M12.5 12.5L7.5 12.5'
          stroke={props.color || '#77808D'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </span>
  )
}

export default SVGDate
