import React, { forwardRef, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getText } from '../../../../lang'
import authActions from '../../../../store/modules/authActions'
import statActions from '../../../../store/modules/statActions'
import { notifyError } from '../../../../utils/Notify'
import LoadingSpinner from '../../../../components/LoadingSpinner'
import NpsScore from '../Dashboard/NpsScore'
import SmsHealthScore from '../Dashboard/SmsHealthScore'
import IntentPromotions from '../Dashboard/IntentPromotions'
import BotAndRating from '../Dashboard/BotAndRating'
import { RANGE_PARAMS } from '../../../../utils'
import LineChartDashboard from '../Dashboard/LineChart'
import Automations from '../Dashboard/Automations'
import { SVGNpsGraphic } from '../../../../icons/SVG/SVGNpsGraphic'
import SentInvites from '../Dashboard/NpsScore/SentInvites'
import VideoStats from '../Dashboard/VideoStats'
import MatadorImpact from '../Dashboard/MatadorImpact'
import RenderMarketingSource from '../Dashboard/RenderMarketingSource'
import './index.scss'

const DashboardNew = forwardRef((props, ref) => {
  const { selectedRange, selectedDataType, screenSize, selectCustomRange } = props
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)
  const [dataReporting, setDataReporting] = useState({})
  // const [dataBrainStats, setDataBrainStats] = useState({})

  const menuOpen = useSelector((state) => {
    return state.authReducer.menuOpen
  })

  useEffect(() => {
    const user = authActions.getUserData()
    if (
      !user.isAdmin &&
      !user.isManager &&
      !user.isSuperAdmin &&
      user.accessibleOrganizations &&
      user.accessibleOrganizations.length > 1
    ) {
      navigate('/messages')
    }
  }, [props._location_id])

  useEffect(() => {
    const refreshDataReportingFS = async () => {
      let result
      setIsLoading(true)
      result = await statActions.getDashboardReporting(
        selectedRange,
        props._location_id,
        selectedDataType,
        selectCustomRange
      )
      if (result.success) {
        result.data.touchpoints.sort((a, b) => b.count - a.count)
        setDataReporting(result.data)
      } else {
        notifyError(result.errMsg ? result.errMsg : getText('ERR_GENERAL'))
      }
      setIsLoading(false)
    }

    if (selectedRange === 'custom' && selectCustomRange) {
      refreshDataReportingFS()
    } else if (RANGE_PARAMS.includes(selectedRange)) {
      refreshDataReportingFS()
    }
  }, [selectedRange, selectCustomRange, selectedDataType, props._location_id])

  // useEffect(() => {
  //   const refreshTheBrainStats = async () => {
  //     if (selectedRange === 'custom' && !selectCustomRange) {
  //       // Prevent API call if custom range is not selected
  //       return
  //     }

  //     const result = await statActions.getTheBrainStats(
  //       getStartDateForDashboard(selectedRange).start_date,
  //       getStartDateForDashboard(selectedRange).end_date
  //     )
  //     if (result.success) {
  //       setDataBrainStats(result.data)
  //     }
  //   }
  //   refreshTheBrainStats()
  // }, [selectedRange])

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <div
      className={`${
        screenSize === 'desktop' ? 'dashboard-container' : 'dashboard-container-resp'
      } ${
        screenSize === 'desktop' && menuOpen ? 'dashboard-container-menu-open' : ''
      }`}
    >
      <div className='dashboard-left-part'>
        {screenSize !== 'desktop' && (
          <div className='dashboard-automations'>
            <Automations
              selectedRange={selectedRange}
              _location_id={props._location_id}
              screenSize={screenSize}
              menuOpen={menuOpen}
              reminders={dataReporting.reminders}
            />
          </div>
        )}
        <div className='dashboard-page-header'>
          <span id='dashboard-header'>{getText('TITLE_DASHBOARD')}</span>
        </div>

        <div className='dashboard-page-main-content'>
          <div className='dashboard-nps-sms'>
            <div className='nps-score-block'>
              <div className='icon-with-text'>
                <SVGNpsGraphic />
                <div className='nps-header'>
                  <h3>{getText('WORD_CSI_SCORE')}</h3>
                  <label>{getText('WORD_REPUTATION_MANAGEMENT')}</label>
                </div>
              </div>
              <NpsScore npsScore={dataReporting.NPSStats} screenSize={screenSize} />
              <SentInvites
                text={getText('TEXT_INVITES_SENT')}
                value={dataReporting.invites_sent}
              />
            </div>
            <div className='sms-health-block'>
              <SmsHealthScore score={dataReporting.deliverance_percent || 0} />
            </div>
          </div>

          <div className='dashboard-finger-intent'>
            <div className='finger-block'>
              {/* <Fingerprinting
                _location_id={props._location_id}
                menuOpen={menuOpen}
                screenSize={screenSize}
                newProfileEngagedScore={dataReporting.fingerprint_engagement || 0}
                selectedRange={selectedRange}
                selectCustomRange={selectCustomRange}
              /> */}
              <MatadorImpact
                _location_id={selectedDataType === 'loc' ? props._location_id : ''}
                menuOpen={menuOpen}
                screenSize={screenSize}
                selectedRange={selectedRange}
                selectCustomRange={selectCustomRange}
              />
            </div>
            <div className='intent-based-block'>
              <IntentPromotions
                touchpoints={dataReporting.touchpoints || []}
                menuOpen={menuOpen}
                screenSize={screenSize}
              />
            </div>
          </div>

          <div
            className={`dashboard-bot-work ${
              dataReporting.ai_bot_percentage < 61 ? 'bot-rating-low' : ''
            }`}
          >
            <div className='bot-work-chart'>
              <BotAndRating
                totalLeads={dataReporting.profiles_messaged}
                messagesSent={dataReporting.ai_bot_messages}
                aibotPercentage={dataReporting.ai_bot_percentage}
                menuOpen={menuOpen}
                screenSize={screenSize}
              />
            </div>
          </div>
          <VideoStats
            screenSize={screenSize}
            count_videos_sent={dataReporting.count_videos_sent}
            count_videos_watched={dataReporting.count_videos_watched}
            count_seconds_watched={dataReporting.count_seconds_watched}
          />
          <div className='dashboard-brain-touchpoints'>
            {/* <div className='brain-block'>
              <ConversationBrain
                menuOpen={menuOpen}
                value={dataBrainStats.nr_autosugestions_clicked_by_user || 0}
                title={getText('TEXT_ANSWERED_QUESTIONS')}
                text={getText('CONVERSATION_BRAIN')}
                screenSize={screenSize}
              />
            </div> */}
            <div className='touchpoints-block'>
              <RenderMarketingSource
                screenSize={screenSize}
                touchpoints={dataReporting.touchpoints || []}
              />
            </div>
          </div>

          <div className='dashboard-conversations'>
            <LineChartDashboard
              screenSize={screenSize}
              selectedDataType={selectedDataType}
              _location_id={props._location_id}
              selectedRange={selectedRange}
              selectCustomRange={selectCustomRange}
            />
          </div>
        </div>
      </div>
      {screenSize === 'desktop' ? (
        <div className='dashboard-right-part'>
          <Automations
            selectedRange={selectedRange}
            _location_id={props._location_id}
            screenSize={screenSize}
            menuOpen={menuOpen}
            reminders={dataReporting.reminders}
          />
        </div>
      ) : null}
    </div>
  )
})

export default DashboardNew
