import React, { useState } from 'react'
import { getText } from '../../../../../../lang'
import MatButton from '../../../../../../components/MatButton'
import MatCheckbox from '../../../../../../components/MatCheckbox'
import MatRow from '../../../../../../components/MatRow'
import BroadcastCampaignPage from '../../../../BroadcastCampaignPage'

const Conditions = React.memo((props) => {
  const { onStateChange, onChangeStep, accepted } = props

  const [loading, setLoading] = useState(false)

  return (
    <BroadcastCampaignPage title={getText('TEXT_TERMS_AND_CONDITIONS')}>
      <div className='create-campaign-form-wrapper ant-form-vertical'>
        <MatCheckbox
          label={getText('TEXT_TITLE_OF_TERMS_AND_CONDITIONS')}
          onChange={(e) => {
            onStateChange('accepted', e.target.checked)
          }}
          value={accepted}
        />
        {
          <MatRow style={{ marginTop: '65px', justifyContent: 'space-between' }}>
            <MatButton
              buttonText={getText('WORD_PREVIOUS')}
              htmlType={''}
              onClick={() => {
                onChangeStep(-1)
              }}
              typeButton={'cancel'}
              size={'large'}
              style={{ maxWidth: '200px', width: '100%' }}
            />
            <MatButton
              loading={loading}
              buttonText={getText('ACTION_SCHEDULE')}
              htmlType={''}
              onClick={() => {
                setLoading(true)
                onChangeStep(1)
              }}
              disabled={!accepted}
              size={'large'}
              style={{ maxWidth: '200px', width: '100%' }}
            />
          </MatRow>
        }
      </div>
    </BroadcastCampaignPage>
  )
})

export default Conditions
