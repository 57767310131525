import React, { useEffect, useState } from 'react'
import invitationActions from '../../store/modules/invitationActions'
import { getText, getTextServerError } from '../../lang'
import { TABLE_DEFAULT_ROW_NUMBER } from '../../devOptions'
import PageFull from '../../components/PageFull'
import './Invitation.scss'
import { notifyError, notifySuccess } from '../../utils/Notify'
import MatList from '../../components/MatList'
import InvitationHeader from './InvitationHeader'
import InvitationListItem from './InvitationListItem'

const Invitation = (props) => {
  useEffect(() => {
    props.setPageTitle && props.setPageTitle(getText('TITLE_INVITATIONS'))
  }, [])

  const [page, setPage] = useState(0)
  const [loadingData, setLoadingData] = useState(true)
  const [invitationList, setInvitationList] = useState({
    list: [],
    total: 0,
  })

  useEffect(() => {
    getInvitationList()
  }, [page, props.user.location])

  const getInvitationList = async (options = {}) => {
    const result = await invitationActions.getPageList(
      page,
      TABLE_DEFAULT_ROW_NUMBER,
      options.searchInputValue
    )
    if (result.success) {
      setInvitationList({
        list: result.data,
        total: result.max,
      })
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
    setLoadingData(false)
  }

  return (
    <PageFull className={'invitation-list-wrapper'}>
      <InvitationHeader
        onFiltersChange={(options) => {
          setPage(0)
          getInvitationList(options)
        }}
        onSaveInvitation={() => {
          getInvitationList()
        }}
      />
      <MatList
        grid
        loading={loadingData}
        data={invitationList.list}
        pagination={invitationList.list.length ? true : false}
        defaultPageSize={TABLE_DEFAULT_ROW_NUMBER}
        paginationCurrentPage={page}
        paginationTotalCount={invitationList.total}
        onPaginationChange={setPage}
        renderItem={(item, index) => (
          <InvitationListItem
            key={index + '_' + item._id}
            item={item}
            onCancel={async (item) => {
              const result = await invitationActions.cancelInvite(item._id)
              if (result.success) {
                notifySuccess(getText('NTF_SUCCESS_INVITE_CANCEL'))
                getInvitationList()
              } else {
                notifyError(getTextServerError(result.errMsg))
              }
            }}
          />
        )}
      />
    </PageFull>
  )
}

export default Invitation
