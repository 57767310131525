import React from 'react'
import MessageEmoji from '../MessageEmoji/MessageEmoji'
import MessageAppointment from '../MessageAppointment/MessageAppointment'
import SelectMessageTemplate from '../MessageTemplateAction/SelectMessageTemplate'

const MessageFooterActions = ({
  newMessageValue,
  showEmojiModal,
  msgChangeEmailSMSType,
  currentCursorPosition,
  currentCursorPositionSubject,
  setnewMessageValue,
  conversation,
  setshowEmojiModal,
  isCustomerRedStatus,
  isCustomerYellowStatusAndRestricted,
  isCustomerPhoneNumberValid,
  visibleAppointment,
  setvisibleAppointment,
  smsTemplatesList,
  emailTemplatesList,
  setcurrentCursorPosition,
  setcurrentCursorPositionSubject,
  mentionItem,
  setIsTemplate,
  setuploadFiles,
  showTempletsIcon,
  setFocusOverInput,
  screenSize,
  isAwaitingConsent,
  newEmailSubject,
  setnewEmailSubject,
}) => {
  return (
    <>
      <MessageEmoji
        newMessageValue={newMessageValue}
        showEmojiModal={showEmojiModal}
        currentCursorPosition={currentCursorPosition}
        setnewMessageValue={setnewMessageValue}
        conversation={conversation}
        setshowEmojiModal={setshowEmojiModal}
        isCustomerRedStatus={isCustomerRedStatus}
        isCustomerYellowStatusAndRestricted={isCustomerYellowStatusAndRestricted}
        isCustomerPhoneNumberValid={isCustomerPhoneNumberValid}
        setFocusOverInput={setFocusOverInput}
        setcurrentCursorPosition={setcurrentCursorPosition}
      />
      <MessageAppointment
        visibleAppointment={visibleAppointment}
        conversation={conversation}
        msgChangeEmailSMSType={msgChangeEmailSMSType}
        setvisibleAppointment={setvisibleAppointment}
        isCustomerRedStatus={isCustomerRedStatus}
        isCustomerYellowStatusAndRestricted={isCustomerYellowStatusAndRestricted}
        isCustomerPhoneNumberValid={isCustomerPhoneNumberValid}
        screenSize={screenSize}
        isAwaitingConsent={isAwaitingConsent}
      />

      {Boolean(showTempletsIcon) ? (
        <SelectMessageTemplate
          id={'msg_templates'}
          smsTemplatesList={smsTemplatesList}
          emailTemplatesList={emailTemplatesList}
          msgChangeEmailSMSType={msgChangeEmailSMSType}
          setnewMessageValue={setnewMessageValue}
          newMessageValue={newMessageValue}
          currentCursorPosition={currentCursorPosition}
          setcurrentCursorPosition={setcurrentCursorPosition}
          currentCursorPositionSubject={currentCursorPositionSubject}
          setcurrentCursorPositionSubject={setcurrentCursorPositionSubject}
          mentionItem={mentionItem}
          newEmailSubject={newEmailSubject}
          setnewEmailSubject={setnewEmailSubject}
          setIsTemplate={setIsTemplate}
          setuploadFiles={setuploadFiles}
          isCustomerRedStatus={isCustomerRedStatus}
          conversation={conversation}
          isCustomerYellowStatusAndRestricted={isCustomerYellowStatusAndRestricted}
          isCustomerPhoneNumberValid={isCustomerPhoneNumberValid}
          setFocusOverInput={setFocusOverInput}
        />
      ) : null}
    </>
  )
}

export default MessageFooterActions
