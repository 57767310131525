import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.3565 10.9665L16.3563 10.9664C15.2297 10.3183 13.8918 10.3165 12.775 10.9679C11.6615 11.6175 10.9936 12.7819 10.9936 14.0665V18.5332C10.9936 19.8323 11.6625 20.9828 12.775 21.6317C13.3338 21.9577 13.9411 22.1132 14.5603 22.1132C15.1646 22.1132 15.7849 21.9584 16.3443 21.6325C16.3447 21.6322 16.3451 21.632 16.3456 21.6317L20.2096 19.4066C21.3392 18.7567 22.007 17.5908 22.007 16.2932C22.007 14.994 21.338 13.8436 20.2256 13.1946L20.2231 13.1932L16.3565 10.9665ZM3.12695 15.9998C3.12695 8.91598 8.87643 3.1665 15.9603 3.1665C23.0571 3.1665 28.7936 8.91565 28.7936 15.9998C28.7936 23.0837 23.0441 28.8332 15.9603 28.8332C8.87643 28.8332 3.12695 23.0837 3.12695 15.9998Z'
        fill={props.color || '#48AF80'}
        stroke={props.color || '#48AF80'}
      />
    </svg>
  )
}

const SVGPlayIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGPlayIcon
