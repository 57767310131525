import React from 'react';
import Icon from '@ant-design/icons';

const SVGIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="11.318" height="11.318" viewBox="0 0 11.318 11.318">
        <path id="Path_38" data-name="Path 38" d="M13.659,7a5.659,5.659,0,1,0,5.659,5.659A5.667,5.667,0,0,0,13.659,7ZM12.8,15.278,10.29,12.751l.75-.75,1.777,1.777L16.291,10.3l.75.75Z" transform="translate(-8 -7)" />
    </svg>
);

const SVGChecked = props => <Icon component={SVGIcon} {...props} />;

export default SVGChecked;