import React from 'react'
import SVGPromotionsIcon from '../../../../../icons/SVG/SVGPromotionsIcon'
import { getText } from '../../../../../lang'
import { SVGIntentBased } from '../../../../../icons/SVG/SVGIntentBased'
import SVGPromotionsRectangleBg from '../../../../../icons/SVG/SVGPromotionsRectangleBg'
import PromotionsItem from './IntentPromotionsItem'
import './IntentPromotions.scss'

const conversationGeneratorList = [
  'inbound',
  'matador_connect',
  'Coupon',
  'text us',
  'smart promotions',
  'Subscriber Growth',
  'Incoming call',
]

function IntentPromotions({ menuOpen, screenSize, touchpoints }) {
  const deviceSize = ['tabletPortrait', 'mobile']

  const getPromotionCount = touchpoints.reduce((currentSum, currentNumber) => {
    if (
      conversationGeneratorList.includes(currentNumber._id) ||
      currentNumber._id.startsWith('Matador Connect')
    ) {
      return currentSum + currentNumber.count
    }
    return currentSum
  }, 0)

  return (
    <div className={`promotions${menuOpen ? ' promotions-width' : ''}`}>
      <div className='header'>
        <SVGPromotionsIcon />
        <div className='header-text'>
          <div>{getText('CONVERSATION_GENERATOR')}</div>
          <div className='desc'>
            {getText('NEW_CONVERSATIONS_GENERATED_VIA_INTENT_BASED_WIDGETS')}
          </div>
        </div>
      </div>
      <div className='item-wrapper'>
        {deviceSize.includes(screenSize) ? (
          <div className='promotions'>
            <SVGIntentBased />
            <div className='promotions-text-wrapper'>
              <div className='promotions-number'>{getPromotionCount}</div>
              <div className='promotions-text'>
                {getText('EQUIVALENT_OF')}
                <span>{'$' + (getPromotionCount * 75).toLocaleString()}</span>
                <br />
                {getText('IN_MARKETING_SPEND')}
              </div>
            </div>
          </div>
        ) : (
          <PromotionsItem
            value={getPromotionCount}
            title={
              <>
                {getText('EQUIVALENT_OF')}
                <span>{'$' + (getPromotionCount * 75).toLocaleString()}</span>
                <br />
                {getText('IN_MARKETING_SPEND')}
              </>
            }
            BgComponent={SVGPromotionsRectangleBg}
          />
        )}
      </div>
    </div>
  )
}

export default IntentPromotions
