import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='24' height='24' rx='4' fill='#FFF5EA' />
      <rect
        x='0.5'
        y='0.5'
        width='23'
        height='23'
        rx='3.5'
        stroke={props.color || '#EA9957'}
        strokeOpacity='0.2'
      />
      <path
        d='M15.7913 16.375H13.458'
        stroke={props.color || '#EA9957'}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.625 17.5418V15.2085'
        stroke={props.color || '#EA9957'}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.0935 11.3407C12.0352 11.3348 11.9652 11.3348 11.901 11.3407C10.5127 11.294 9.41018 10.1565 9.41018 8.7565C9.40434 7.32734 10.5652 6.1665 11.9943 6.1665C13.4235 6.1665 14.5843 7.32734 14.5843 8.7565C14.5843 10.1565 13.476 11.294 12.0935 11.3407Z'
        stroke={props.color || '#EA9957'}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.9939 17.7226C10.9323 17.7226 9.87645 17.4543 9.07145 16.9176C7.65978 15.9726 7.65978 14.4326 9.07145 13.4935C10.6756 12.4201 13.3064 12.4201 14.9106 13.4935'
        stroke={props.color || '#EA9957'}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

const SVGFollowedTimesIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGFollowedTimesIcon
