import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M7.4974 12.5003C7.4974 12.5003 8.4349 13.3337 9.9974 13.3337C11.5599 13.3337 12.4974 12.5003 12.4974 12.5003M14.1641 8.33366C14.1641 8.7939 13.791 9.16699 13.3307 9.16699C12.8705 9.16699 12.4974 8.7939 12.4974 8.33366C12.4974 7.87342 12.8705 7.50033 13.3307 7.50033C13.791 7.50033 14.1641 7.87342 14.1641 8.33366ZM18.3307 10.0003C18.3307 14.6027 14.5998 18.3337 9.9974 18.3337C5.39502 18.3337 1.66406 14.6027 1.66406 10.0003C1.66406 5.39795 5.39502 1.66699 9.9974 1.66699C14.5998 1.66699 18.3307 5.39795 18.3307 10.0003ZM7.4974 8.33366C7.4974 8.7939 7.1243 9.16699 6.66406 9.16699C6.20383 9.16699 5.83073 8.7939 5.83073 8.33366C5.83073 7.87342 6.20383 7.50033 6.66406 7.50033C7.1243 7.50033 7.4974 7.87342 7.4974 8.33366Z'
      stroke={props.color || '#77808D'}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

const SVGEmojiNew = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGEmojiNew
