import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.3 11C20.3 16.1362 16.1362 20.3 11 20.3C5.86375 20.3 1.7 16.1362 1.7 11C1.7 5.86375 5.86375 1.7 11 1.7C16.1362 1.7 20.3 5.86375 20.3 11ZM22 11C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 11ZM15.3 11C15.3 13.3748 13.3748 15.3 11 15.3C8.62518 15.3 6.7 13.3748 6.7 11C6.7 8.62518 8.62518 6.7 11 6.7C13.3748 6.7 15.3 8.62518 15.3 11ZM17 11C17 14.3137 14.3137 17 11 17C7.68629 17 5 14.3137 5 11C5 7.68629 7.68629 5 11 5C14.3137 5 17 7.68629 17 11Z'
        fill={props.color || 'black'}
      />
    </svg>
  )
}

const SVGOrgTouchpointIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGOrgTouchpointIcon
