import React, { useEffect, useRef, useState } from 'react'
import { Tooltip } from 'antd'
import './MatToggle.scss'

const MatToggle = (props) => {
  const labelRef = useRef(null)
  const [isOverflowing, setIsOverflowing] = useState(false)

  useEffect(() => {
    if (labelRef.current) {
      setIsOverflowing(labelRef.current.scrollWidth > labelRef.current.clientWidth)
    }
  }, [props.label])

  return props.tooltip || isOverflowing ? (
    <Tooltip title={props.tooltipTitle || props.label}>
      <div
        className={`mat-toggle ${props.className || ''} ${
          props.primary ? 'mat-toggle-primary' : ''
        } ${props.icon ? 'mat-toggle-with-icon' : ''} ${props.value ? ' active' : ''}`}
        onClick={() => {
          if (!props.disabled) {
            props.onChange && props.onChange()
          }
        }}
        style={props.style}
      >
        {props.icon && props.icon}
        <span className='toggle-text' ref={labelRef}>
          {' '}
          {props.label && props.label}
        </span>
        {props.rightIcon && props.rightIcon}
      </div>
    </Tooltip>
  ) : (
    <div
      className={`mat-toggle ${props.className || ''} ${
        props.primary ? 'mat-toggle-primary' : ''
      } ${props.icon ? 'mat-toggle-with-icon' : ''} ${props.value ? ' active' : ''}`}
      onClick={() => {
        if (!props.disabled) {
          props.onChange && props.onChange()
        }
      }}
      style={props.style}
    >
      {props.icon && props.icon}
      <span className='toggle-text' ref={labelRef}>
        {' '}
        {props.label && props.label}
      </span>
      {props.rightIcon && props.rightIcon}
    </div>
  )
}

export default MatToggle
