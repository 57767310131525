import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.36275 14.4504C8.15155 14.4504 7.94035 14.3701 7.77975 14.2084C7.45745 13.8861 7.45745 13.3647 7.77975 13.0424L13.051 7.77117C13.3733 7.44887 13.8946 7.44887 14.2169 7.77117C14.5392 8.09347 14.5392 8.61487 14.2169 8.93717L8.94575 14.2084C8.78515 14.3701 8.57395 14.4504 8.36275 14.4504Z'
        fill={props.color || 'black'}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.6362 14.4536C13.4249 14.4536 13.2137 14.3733 13.0532 14.2116L7.77755 8.93486C7.45525 8.61256 7.45525 8.09116 7.77755 7.76886C8.10095 7.44656 8.62235 7.44656 8.94355 7.76886L14.2192 13.0456C14.5415 13.3679 14.5415 13.8893 14.2192 14.2116C14.0586 14.3733 13.8462 14.4536 13.6362 14.4536Z'
        fill={props.color || 'black'}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.2315 1.65C3.4485 1.65 1.65 3.5563 1.65 6.5076V15.4924C1.65 18.4437 3.4485 20.35 6.2315 20.35H15.7663C18.5504 20.35 20.35 18.4437 20.35 15.4924V6.5076C20.35 3.5563 18.5504 1.65 15.7674 1.65H6.2315ZM15.7663 22H6.2315C2.5036 22 0 19.3842 0 15.4924V6.5076C0 2.6158 2.5036 0 6.2315 0H15.7674C19.4953 0 22 2.6158 22 6.5076V15.4924C22 19.3842 19.4953 22 15.7663 22Z'
        fill={props.color || 'black'}
      />
    </svg>
  )
}

const SVGOrgRestricionsIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGOrgRestricionsIcon
