import * as React from "react"

function SVGUploadSegment(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="60" height="60" viewBox="0 0 60 60"><defs>
      <clipPath id="a"><rect width="60" height="60" transform="translate(512 554)" fill="#fff" /></clipPath>
    </defs>
      <g transform="translate(-512 -554)" clipPath="url(#a)">
        <path d="M536.34,611.106v.529A2.366,2.366,0,0,0,538.705,614h6.6a2.366,2.366,0,0,0,2.366-2.366V611.1Z" fill="#304061" />
        <path d="M532.684,602.527v5.552a3.892,3.892,0,0,0,3.892,3.893H547.44a3.893,3.893,0,0,0,3.893-3.893v-5.552Z" fill="#455783" />
        <path d="M551.407,604.342H532.341a1.087,1.087,0,0,0-.056,2.173h19.122a1.087,1.087,0,0,0,.056-2.173Z" fill="#304061" />
        <path d="M550.953,608.33H533.066a1.087,1.087,0,0,0,0,2.173h17.887a1.087,1.087,0,0,0,0-2.173Z" fill="#304061" />
        <path d="M563.532,573.367a21.652,21.652,0,1,0-38.377,15.888,28.413,28.413,0,0,1,5.595,11.829,2.186,2.186,0,0,0,2.137,1.718h18.282a2.182,2.182,0,0,0,2.127-1.675,33.311,33.311,0,0,1,5.959-12.413A21.58,21.58,0,0,0,563.532,573.367Z" fill="#93bdff" />
        <path d="M547.679,578.084a5.669,5.669,0,1,1,0-.006Z" fill="#14336a" />
        <path d="M540.484,579.852h3.044V602.8h-3.044Z" fill="#14336a" />
      </g>
    </svg>
  )
}

export default SVGUploadSegment
