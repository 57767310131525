import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { notifyError } from '../../../../utils/Notify'
import { getText } from '../../../../lang'
import statActions from '../../../../store/modules/statActions'
import MatList from '../../../../components/MatList'
import UserItem from './UserItem'
import './UserDashboard.scss'

const RANGE_ITEM = 5

const UserDashboard = forwardRef((props, ref) => {
  const { selectedRange, selectedDataType, customDate, selectCustomRange } = props
  const navigate = useNavigate()

  const [currentPage, setCurrentPage] = useState(() =>
    props.urlSearchParams.page ? parseInt(props.urlSearchParams.page) : 0
  )

  const [isLoading, setIsLoading] = useState(true)
  const [performanceData, setPerformanceData] = useState({})
  useImperativeHandle(ref, () => ({
    paginationChange() {
      setCurrentPage(0)
    },
  }))

  useEffect(() => {
    const getUserPerformanceData = async (pageNumber, previousPage = 0) => {
      if (selectedRange === 'custom' && !selectCustomRange) {
        // Prevent API call if custom range is not selected
        return
      }

      setIsLoading(true)
      let locationId = selectedDataType === 'org' ? '' : props._location_id
      const result = await statActions.getUserDashboard(
        selectedRange,
        pageNumber,
        RANGE_ITEM,
        locationId,
        selectCustomRange.start_date,
        selectCustomRange.end_date
      )
      if (result.success) {
        setPerformanceData(result.data)
        navigate(`?type=user_dashboard&page=${pageNumber}`)
      }
      if (!result.success) {
        setCurrentPage(previousPage)
        notifyError(result.errMsg ? result.errMsg : getText('ERR_GENERAL'))
      }
      setIsLoading(false)
    }
    getUserPerformanceData(currentPage)
  }, [
    props._location_id,
    selectedRange,
    selectedDataType,
    currentPage,
    customDate,
    selectCustomRange,
  ])

  const { stats: usersList = [], count_users: totalItems = 0 } = performanceData
  return (
    <MatList
      loading={isLoading}
      data={usersList}
      pagination
      defaultPageSize={RANGE_ITEM}
      paginationCurrentPage={currentPage}
      onPaginationChange={(pageClicked) => setCurrentPage(pageClicked)}
      paginationTotalCount={totalItems}
      renderItem={(item) => {
        return <UserItem key={JSON.stringify(item)} item={item} />
      }}
    />
  )
})

export default UserDashboard
