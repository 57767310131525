import React, { memo, useMemo, useEffect, useState } from 'react'
import messageActions from '../../../../store/modules/messageActions'
import locationActions from '../../../../store/modules/locationActions'
import { notifyError } from '../../../../utils/Notify'
import { getTextServerError } from '../../../../lang'
import MessageTabMain from '../messageTabs/MessageTabMain'
import AiBotSwitchPanel from '../MessageDripSequencePanel/AiBotSwitchPanel'
import MessageConsent from '../MessageConsents/MessageConsent'
import MessageProfileInfo from '../MessageProfileInfo/MessageProfileInfo'
import MessageNPSScoreRate from '../MessageNPSScore/MessageNPSScoreRate'
import CustomerRisk from '../MessageCustomerRisk/CustomerRisk'
import MessageConsentEmail from '../MessageConsents/MessageConsentEmail'
import './MessageRightMain.scss'

const MessageRight = (props) => {
  const {
    conversation,
    onSaveReciver,
    onSaveTags,
    lastActivity,
    screenSize,
    msgChangeEmailSMSType,
    disabled,
    refreshConversation,
    onCloseDrawer,
    isAwaitingConsent,
  } = props

  let receiver
  if (conversation) {
    receiver = conversation.receiver
  }

  const [isNylas, setisNylas] = useState(false)

  useEffect(() => {
    const getLocation = async () => {
      if (conversation && conversation._location_id) {
        let result = await locationActions.getOneLocationByConv_location_id(
          conversation._location_id
        )
        if (result.success) {
          setisNylas(
            result.data.integrationInfo && result.data.integrationInfo.nylas
          )
        } else {
          notifyError(getTextServerError(result.errMsg))
        }
      }
    }
    getLocation()
  }, [conversation._location_id])

  const MessageRightContent = useMemo(() => {
    return (
      conversation && (
        <>
          <div className='message_right_panel--top-side'>
            <div className='message_right_panel--top-side-item'>
              <MessageProfileInfo
                conversation={conversation}
                onSaveReciver={(customer) =>
                  onSaveReciver && onSaveReciver(customer)
                }
                screenSize={screenSize}
                onCloseDrawer={onCloseDrawer}
              />
              <MessageNPSScoreRate conversation={conversation} />
              <CustomerRisk receiver={receiver} />
            </div>
            <AiBotSwitchPanel
              conversation={conversation}
              isNylas={isNylas}
              disabled={disabled}
              onSaveReciver={onSaveReciver}
            />
            {Boolean(receiver && receiver.email) ||
            Boolean(receiver && receiver.phone) ? (
              <div className='message_right_panel--top-side-item'>
                {Boolean(receiver && receiver.phone) && (
                  <MessageConsent
                    unarchiveConversation={async () => {
                      const result = await messageActions.unarchive(conversation._id)
                      if (result.success) {
                        props.removeConversationFromList(result.data.conversation)
                      }
                    }}
                    conversation={conversation}
                    disabled={disabled}
                    onSend={(status) => {
                      conversation.receiver.status = status
                      onSaveReciver && onSaveReciver(conversation.receiver)
                    }}
                  />
                )}
                {Boolean(
                  receiver && receiver.email && isNylas && isNylas.email_address
                ) && <MessageConsentEmail conversation={conversation} />}
              </div>
            ) : null}
          </div>
          <MessageTabMain
            conversation={conversation}
            onSaveReciver={onSaveReciver}
            refreshConversation={refreshConversation}
            onSaveTags={onSaveTags}
            lastActivity={lastActivity}
            screenSize={screenSize}
            msgChangeEmailSMSType={msgChangeEmailSMSType}
            isAwaitingConsent={isAwaitingConsent}
          />
        </>
      )
    )
  }, [
    conversation,
    screenSize,
    msgChangeEmailSMSType,
    receiver,
    refreshConversation,
    isNylas,
  ])

  return MessageRightContent
}

export default memo(MessageRight)
