import * as React from "react"

function SVGChatClose(props) {
    return (
        <svg width={12.902} height={12.44} viewBox="0 0 12.902 12.44" {...props}>
            <g data-name="Group 1972">
                <g
                    data-name="Group 1859"
                    fill={props.colorIcon ? props.colorIcon : "none"}
                    stroke={props.color ? props.color : "#886bff"}
                    strokeLinecap="round"
                    strokeWidth={props.stroke ? props.stroke : 1}
                >
                    <path data-name="Line 303" d="M1.131 1.131l10.64 10.177" />
                    <path data-name="Line 304" d="M1.131 11.308l10.64-10.177" />
                </g>
            </g>
        </svg>
    )
}

export default SVGChatClose;
