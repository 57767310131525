import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width={props.width || '16'}
    height={props.heigth || '16'}
    viewBox='0 0 16 16'
    fill='none'
  >
    <path
      d='M15.9999 6.87329C15.9999 5.89705 15.2565 5.09135 14.3062 4.99273V1.00944C14.3062 0.874012 14.2394 0.747387 14.1276 0.67092C14.016 0.594508 13.8736 0.578098 13.7474 0.627053L5.92856 3.66408H1.65313C1.54434 3.66408 1.43997 3.70734 1.36296 3.78425C1.33162 3.81564 0.590741 4.56544 0.214751 5.69395C-0.140893 6.76127 -0.230378 8.36817 1.36302 9.96206C1.43992 10.039 1.54428 10.0822 1.65313 10.0822H1.74809L4.98552 15.2095C5.06068 15.3285 5.19168 15.4007 5.33241 15.4007H8.68985C8.69029 15.4007 8.69073 15.4007 8.69095 15.4007C8.91756 15.4007 9.10118 15.2171 9.10118 14.9905C9.10118 14.8999 9.07186 14.8162 9.02214 14.7483L6.12405 10.1583L13.7472 13.1243C13.7954 13.143 13.8458 13.1522 13.896 13.1522C13.9775 13.1522 14.0584 13.1279 14.1276 13.0806C14.2394 13.0042 14.3062 12.8774 14.3062 12.742V8.75346C15.2565 8.65495 15.9999 7.84942 15.9999 6.87329ZM1.83314 4.48454H5.59523V9.26182H1.82685C-0.188534 7.11166 1.3791 5.00756 1.83314 4.48454ZM5.55864 14.5803L2.71913 10.0833H5.10629L7.9457 14.5803H5.55864ZM6.41575 4.35507L13.4858 1.60887V12.1422L6.41575 9.39145V4.35507ZM14.3062 7.92441V5.82184C14.8024 5.91449 15.1795 6.35032 15.1795 6.87329C15.1794 7.39614 14.8024 7.83175 14.3062 7.92441Z'
      fill='black'
    />
    <path
      d='M12.5799 7.28598H12.6346C12.8612 7.28598 13.0449 7.10236 13.0449 6.87575C13.0449 6.64913 12.8612 6.46552 12.6346 6.46552H12.5799C12.3533 6.46552 12.1697 6.64913 12.1697 6.87575C12.1697 7.10236 12.3533 7.28598 12.5799 7.28598Z'
      fill='black'
    />
    <path
      d='M10.9937 7.28598H11.0484C11.275 7.28598 11.4586 7.10236 11.4586 6.87575C11.4586 6.64913 11.275 6.46552 11.0484 6.46552H10.9937C10.7671 6.46552 10.5835 6.64913 10.5835 6.87575C10.5835 7.10236 10.7671 7.28598 10.9937 7.28598Z'
      fill='black'
    />
    <path
      d='M9.40748 7.28598H9.46217C9.68878 7.28598 9.8724 7.10236 9.8724 6.87575C9.8724 6.64913 9.68878 6.46552 9.46217 6.46552H9.40748C9.18086 6.46552 8.99725 6.64913 8.99725 6.87575C8.99725 7.10236 9.18086 7.28598 9.40748 7.28598Z'
      fill='black'
    />
    <path
      d='M7.82125 7.28598H7.87595C8.10256 7.28598 8.28618 7.10236 8.28618 6.87575C8.28618 6.64913 8.10256 6.46552 7.87595 6.46552H7.82125C7.59464 6.46552 7.41102 6.64913 7.41102 6.87575C7.41102 7.10236 7.59464 7.28598 7.82125 7.28598Z'
      fill='black'
    />
  </svg>
)

const SVGLocPromotions = (props) => (
  <Icon component={() => <SVGIcon {...props} />} {...props} />
)

export default SVGLocPromotions
