import React from 'react'
import { Form, Checkbox } from 'antd'
import './MatCheckbox.scss'

const MatFormCheckbox = (props) => {
  return (
    <Form.Item
      name={props.name}
      valuePropName='checked'
      fieldKey={props.fieldKey}
      className={`mat-form-item ${props.className || ''}`}
    >
      <Checkbox
        className={`mat-checkbox-wrapper ${
          props.black ? 'mat-checkbox-black' : ''
        } ${props.classeName || ''}`}
        defaultChecked={false}
        disabled={props.disabled}
        onChange={(e) => {
          props.onChange && props.onChange(e.target.checked)
        }}
        style={props.style}
      >
        {props.label}
      </Checkbox>
    </Form.Item>
  )
}

MatFormCheckbox.defaultProps = {
  name: 'booleanCheckBoxValue',
  label: 'checkBox',
  disabled: false,
  defaultChecked: false,
}

export default MatFormCheckbox
