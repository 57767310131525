import * as React from "react"

function SVGNewMessage(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18.892" height="18.892" viewBox="0 0 18.892 18.892">
            <path id="Edit_Square" data-name="Edit Square" d="M13.317,4.536a.7.7,0,0,1,.095,1.385l-.095.006H9.844A3.778,3.778,0,0,0,5.9,9.887l0,.221v7.71c0,2.464,1.443,4.084,3.74,4.177l.211,0h8.2a3.773,3.773,0,0,0,3.948-3.96l0-.222V14.083a.7.7,0,0,1,1.387-.094l.006.094v3.736c0,3.211-2.037,5.468-5.128,5.569l-.217,0h-8.2A5.162,5.162,0,0,1,4.5,18.041l0-.222v-7.71C4.5,6.9,6.542,4.64,9.627,4.539l.217,0Zm3.974.815a2.916,2.916,0,0,1,4.116,0l1.131,1.129a2.9,2.9,0,0,1,0,4.11l-6.975,6.964a2.715,2.715,0,0,1-1.919.794h-3.48a.7.7,0,0,1-.7-.713l.087-3.506a2.706,2.706,0,0,1,.794-1.847ZM16.72,7.887l-5.385,5.377a1.316,1.316,0,0,0-.386.9l-.07,2.792h2.766a1.321,1.321,0,0,0,.836-.3l.1-.088L20,11.159Zm3.7-1.553a1.521,1.521,0,0,0-2.146,0l-.572.57,3.277,3.271.572-.57a1.513,1.513,0,0,0,.1-2.034l-.1-.108Z" transform="translate(-4.5 -4.5)" fill="#fff" />
        </svg>
    )
}

export default SVGNewMessage
