import React from 'react';
import Icon from '@ant-design/icons';
import { MainColor } from '../../utils';

const SVGIcon = (props) => (
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="19" cy="19" r="19" fill="white" />
        <path d="M27.1806 10.7734C27.9912 10.7734 28.6484 10.1162 28.6484 9.30563C28.6484 8.49502 27.9912 7.83789 27.1806 7.83789C26.37 7.83789 25.7129 8.49502 25.7129 9.30563C25.7129 10.1162 26.37 10.7734 27.1806 10.7734Z" fill={MainColor} />
        <path d="M27.4791 13.7097L28.0829 14.7581C28.7909 15.9825 29.2431 17.3378 29.4123 18.7419H24.4555C24.3297 14.8503 23.5204 7.00002 19.0249 7.00002C16.1724 6.99456 13.3973 7.92745 11.1273 9.6549C8.85738 11.3823 7.2186 13.8084 6.46362 16.5592C5.70863 19.31 5.87934 22.2327 6.94945 24.8769C8.01955 27.5211 9.92963 29.7399 12.3853 31.1913C14.8409 32.6427 17.7057 33.2462 20.5382 32.9087C23.3707 32.5711 26.0135 31.3114 28.0593 29.3236C30.1051 27.3357 31.4403 24.7302 31.8591 21.9086C32.2779 19.087 31.757 16.206 30.3768 13.7097H27.4791ZM19.0039 30.4839C17.5026 30.4839 16.2655 26.4539 16.0894 21.2581H21.9184C21.7423 26.4539 20.5052 30.4839 19.0039 30.4839ZM21.9184 18.7419H16.0894C16.2613 13.5461 17.5026 9.51615 19.0039 9.51615C20.5052 9.51615 21.7423 13.5461 21.9184 18.7419ZM13.762 10.921C14.1816 10.6802 14.6174 10.4686 15.0662 10.2878C13.9507 12.9045 13.6446 16.4858 13.5733 18.7419H8.59554C8.65524 18.251 8.74912 17.7647 8.8765 17.2868C9.22931 15.9553 9.84278 14.7071 10.6814 13.6144C11.52 12.5217 12.5671 11.6062 13.762 10.921ZM9.92489 25.2378C9.21749 24.0146 8.76528 22.6608 8.59554 21.2581H13.5733C13.6446 23.51 13.9465 27.0871 15.062 29.7039C12.8969 28.8339 11.0875 27.2608 9.92489 25.2378ZM24.2458 29.0748C23.8261 29.3168 23.3904 29.5297 22.9417 29.7123C24.0571 27.0955 24.3633 23.5142 24.4555 21.2581H29.4123C29.3524 21.7477 29.2585 22.2325 29.1313 22.709C28.7785 24.0405 28.165 25.2888 27.3264 26.3815C26.4878 27.4742 25.4407 28.3896 24.2458 29.0748Z" fill={MainColor} />
    </svg>
);

const SVGOnline = props => <Icon component={SVGIcon} {...props} />;

export default SVGOnline;