const texts = {
  ACTION_UPDATE: 'Update',
  ACTION_SAVE: 'Save',
  ACTION_SAVE_CHANGES: 'Save changes',
  ACTION_CANCEL: 'Cancel',
  ACTION_BACK: 'Back',
  ACTION_CHAT: 'Chat',
  ACTION_DELETE: 'Delete',
  ACTION_EDIT: 'Edit',
  ACTION_SEND: 'Send',
  ACTION_SHOW_LESS: 'Show less',
  ACTION_SHOW_MORE: 'Show more',
  ACTION_OPTIONS: 'Options',
  ACTION_PROFILE: 'Profile',
  ACTION_LOGIN: 'Login',
  ACTION_LOGIN_WITH_FB: 'Login with Facebook',
  ACTION_CONNECT_WHATSAPP_ACCOUNT_VIA_FACEBOOK:
    'Connect WhatsApp account via Facebook',
  ACTION_INTEGRATE_FB_INSTAGRAM_PAGE: 'Integrate Your Facebook and Instagram Pages',
  ACTION_ENABLE_MESSENGER: 'Enable messenger',
  ACTION_LOGOUT: 'Logout',
  ACTION_INVITE: 'Send Invitation',
  ACTION_REVIEW_INVITE: 'Review Invite',
  ACTION_CREATE_USER: 'Create new user',
  ACTION_ADD_USER: 'Create new user',
  ACTION_MANAGERS_LIST: 'Edit users',
  ACTION_ADD_MANAGERS: 'Add managers',
  ACTION_SEND_MAGIC_LINK: 'Send Magic Link',
  ACTION_CHANGE_PASSWORD: 'Change password',
  ACTION_NEW_MESSAGE: 'New Message',
  ACTION_FEEDBACK_SKIP: 'Use a different site',
  ACTION_ARCHIVE: 'Archive',
  ACTION_MARK_AS_READ: 'Mark as Read',
  ACTION_UNARCHIVE: 'Unarchive',
  ACTION_ASSIGN: 'Assign',
  ACTION_ASSIGN_TO: 'Assign to',
  ACTION_UNASSIGN: 'Unassign',
  ACTION_ASSIGN_SELF: 'Assign to Me',
  ACTION_ASSIGNED_SELF: 'Assigned to Me',
  ACTION_ADD_GREETING: 'Add Greeting',
  ACTION_ADD_QUESTION: 'Add Question',
  ACTION_ADD_PROMOTION: 'Add Promotion',
  ACTION_ADD_SPECIAL: 'Add Special',
  ACTION_ADD_ANNOUNCEMENT: 'Add Announcement',
  ACTION_SAVE_QUESTION: 'Save Question',
  ACTION_SAVE_GREETING: 'Save Greeting',
  ACTION_REVIEW_UNSUBSCRIBED: 'Unsubscribed',
  ACTION_SEND_TO_CRM: 'Push to CRM',
  ACTION_CREATE_LOCATION: 'Create Location',
  ACTION_EDIT_LOCATION: 'Edit Location',
  ACTION_INTEGRATE_TOUCHPOINTS: 'Integrate your touchpoints',
  ACTION_REMOVE_IMAGE: 'Remove image',
  ACTION_SELECT_USER: 'Select user',
  ACTION_SELECT_A_USER: 'Select a user',

  WORD_YES: 'Yes',
  WORD_NO: 'No',
  WORD_ALL: 'All',
  WORD_SELF: 'Me',
  WORD_NAME: 'Name',
  WORD_FIRST_NAME: 'First Name',
  WORD_LAST_NAME: 'Last Name',
  WORD_PHONE: 'Phone',
  WORD_CHAT_: 'Phone Number',
  WORD_PHONE_HELP: 'Phone (10 digits)',
  WORD_EMAIL: 'Email',
  WORD_PASSWORD: 'Password',
  WORD_ADDRESS: 'Address',
  WORD_MANAGERS: 'Managers',
  WORD_URL: 'URL',
  WORD_USA: 'USA',
  WORD_COLOR: 'Color',
  WORD_ENABLED: 'Enabled',
  WORD_ENABLE: 'Enable',
  WORD_DISABLE: 'Disable',
  WORD_ACTIVE: 'Active',
  WORD_INACTIVE: 'Inactive',
  LOCATION: 'Location',
  WORD_OR: 'Or',
  WORD_ROLE: 'Role',
  WORD_LOCATION: 'Location',
  WORD_STATUS: 'Status',
  WORD_TYPE: 'Type',
  WORD_ASSIGNED: 'Assigned',
  WORD_UNASSIGNED: 'Unassigned',
  WORD_ASSIGNED_LOC: 'Assigned (My location)',
  WORD_ASSIGNED_EXT: 'Assigned (Other locations)',
  WORD_ASSIGNED_USERS_COUNT: 'Assigned users count',
  WORD_ASSIGNED_USERS: 'Assigned users',
  WORD_SETTINGS: 'Settings',
  WORD_APPEARANCE: 'Appearance',

  WORD_ROLE_ADMIN: 'Admin',
  WORD_ROLE_MANAGER: 'Manager',
  WORD_ROLE_SUPPORT: 'Support Agent',
  WORD_ROLE_SUPER_ADMIN: 'Super Admin',

  WORD_TODAY: 'Today',
  WORD_YESTERDAY: 'Yesterday',
  WORD_PAST_WEEK: 'Last 7 days',
  WORD_CURRENT_MONTH: 'This month',
  WORD_LAST_MONTH: 'Last Month',
  WORD_ALL_TIME: 'All Time',
  WORD_MONTH_TO_DAY: 'Month To Day',
  WORD_TOTAL_ALL_TIME: 'Total All Time',

  WORD_SENT: 'Sent',
  WORD_NOT_SENT: 'Not Sent',
  WORD_RECOMMENDED: 'Recommended',
  WORD_REVIEWED: 'Reviewed',
  WORD_FOLLOWED: 'Followed',
  WORD_INVITE_STATUS: 'Invite status',

  WORD_CANCELLED: 'Cancelled',
  WORD_EMAIL_SENT: 'Message sent',
  WORD_LANGUAGE: 'Language',

  WORD_ALL_USERS: 'All Users',

  WORD_MSG_PREVIEW_EN: 'English Message Preview',
  WORD_MSG_PREVIEW_FR: 'French Message Preview',
  WORD_MSG_PREVIEW_SP: 'Spanish Message Preview',

  WORD_HOME_LOCATION: 'Home Location',
  WORD_ORGANIZATIONS: 'Organizations',

  TEXT_REVERT_AI_DECISION: `Revert a.i’s decision`,
  TEXT_PROFILE_PICTURE_FOR_CLIENTS: 'Users will see this image near your answers.',
  TEXT_ALLOW_USER_RECEIVE_NEWSLETTER: 'Allow user to receive email newsletters',
  TEXT_EDIT_LOCATION_HELP:
    'A language is automatically disabled if there is no image or text content',
  WORD_CONVERSATION_CREATED_AT: 'Conversation created on:',
  WORD_LOCATION_NAME: 'Location Name',
  WORD_LOCATION_ADDRESS: 'Location Address',
  WORD_LOCATION_CRM_EMAIL: 'Location CRM Email',
  WORD_LOCATION_USA_HELP: 'US Location? (Will Send SMS from USA Phone)',
  WORD_REVIEW_SMS_TEMPLATE_TITLE: 'Review Invite SMS Text Templates',
  WORD_REVIEW_EMAIL_TEMPLATE_TITLE: 'Review Invite Email Templates',
  TEXT_REVIEW_TEMPLATE_HELP:
    'You can use {{ customer_name }} and {{ location_name }} in the below templates',
  WORD_REVIEW_TEXT_EN: 'Text Content (English)',
  WORD_REVIEW_TEXT_FR: 'Text Content (French)',
  WORD_REVIEW_TEXT_SP: 'Text Content (Spanish)',
  WORD_CRM_TYPE: 'CRM Type',
  WORD_DEALER_ID: 'Dealer ID',
  WORD_QUOTE_ID: 'Quote ID',
  WORD_DEALER_NUMBER: 'Dealer Number',
  WORD_DEALER_GROUP_ID: 'Dealer group ID',
  WORD_SUBSCRIPTION_ID: 'Subscription ID',
  WORD_ACCOUNT_ID: 'Account ID',
  WORD_SERIAL_NUMBER: 'Serial Number',
  WORD_TO_DO: 'To-do',

  WORD_REVIEW_IMAGE_TITLE: 'Review Invite Poster Images',
  WORD_REVIEW_IMAGE_EN: 'Review Invite Image (English)',
  WORD_REVIEW_IMAGE_FR: 'Review Invite Image (French)',
  WORD_REVIEW_IMAGE_SP: 'Review Invite Image (Spanish)',

  LANG_EN: 'English',
  LANG_FR: 'Français',
  LANG_SP: 'Spanish',

  MONTH_JAN: 'January',
  MONTH_FEB: 'February',
  MONTH_MAR: 'March',
  MONTH_APR: 'April',
  MONTH_MAY: 'May',
  MONTH_JUN: 'June',
  MONTH_JUL: 'July',
  MONTH_AUG: 'August',
  MONTH_SEP: 'September',
  MONTH_OCT: 'October',
  MONTH_NOV: 'November',
  MONTH_DEC: 'December',
  MONTH_JAN_SHORT: 'Jan',
  MONTH_FEB_SHORT: 'Feb',
  MONTH_MAR_SHORT: 'Mar',
  MONTH_APR_SHORT: 'Apr',
  MONTH_MAY_SHORT: 'May',
  MONTH_JUN_SHORT: 'Jun',
  MONTH_JUL_SHORT: 'Jul',
  MONTH_AUG_SHORT: 'Aug',
  MONTH_SEP_SHORT: 'Sep',
  MONTH_OCT_SHORT: 'Oct',
  MONTH_NOV_SHORT: 'Nov',
  MONTH_DEC_SHORT: 'Dec',

  ERR_GENERAL: 'An error happened. Please contact an administrator.',
  ERR_SERVER_NOT_CONNECTED: 'Connection error.',
  ERR_MISSING_INFO:
    'Some information is missing or invalid. Please check each field.',
  ERR_VALIDATION_REQUIRED: 'This field is required.',
  ERR_VALIDATION_MIN_LENGTH: (minLength) =>
    `This field requires at least ${minLength} characters.`,
  ERR_VALIDATION_MAX_LENGTH: (maxLength) =>
    `This field can only contain ${maxLength} characters.`,
  ERR_VALIDATION_EMAIL: 'This email address is invalid.',
  ERR_VALIDATION_CANADIAN_PHONE: "The phone number must start with '1'.",

  ERR_SELECT_TOUCHPOINT: 'Please select at least one touchpoint',

  ERR_INVALID_URLS: 'One or more urls are invalid.',
  ERR_MISSING_CUSTOM_URL_NAMES: 'Names are missing for custom urls.',
  ERR_MISSING_CUSTOM_URL_NAME: 'Name is missing for custom url.',
  ERR_NO_SELECTED_ROLE: 'Please select a role.',
  ERR_NO_SELECTED_LOCATION: 'Please select a location.',
  ERR_NO_PHONE_OR_EMAIL: 'Please enter a phone or an email.',
  ERR_NO_NAME_OR_SURNAME: 'Please enter First name and Last name.',
  ERR_NO_FEEDBACK: 'Please fill out the text field.',
  ERR_PASSWORD_MATCH: 'Both password fields must match.',
  ERR_PASSWORD_SHORT: (minLength) =>
    `The password must be at least ${minLength} characters.`,
  ERR_EMPTY_MESSAGE: 'Please fill out the message text field.',
  ERR_NO_FEEDBACK_URLS: 'No FeedBack URL',
  ERR_NO_ORGANIZATION_NAME_IS_REQUIRED: 'Organization Name is required',
  ERR_NO_ORGANIZATION_LANGUAGE_REQUIRED: 'Please select 2 Organization languages.',
  ERR_NO_ORGANIZATION_TAG_NAME_REQUIRED: 'Organization Tag Name is required',
  ERR_WRONG_OPENING_HOURS: 'Something wrong with working hours',
  ERR_CAMPAIGN_NAME_IS_REQUIRED: 'Campaign name is required',
  ERR_SEQUENCE_NAME_IS_REQUIRED: 'Sequence name is required',
  ERR_PLEASE_FILL_TIME_UNIT:
    'Please fill in the unit value the message should be sent',
  ERR_SAME_TIME_AUTO_REPLY: "You can't set auto-reply messages for the same time",
  INPUT_AT_LEAST_ONE_OF_THE_LANGUAGES: 'Input at least one of the languages',

  NTF_SUCCESS_INVITE: 'Invitation was sent successfully.',
  NTF_SUCCESS_INVITE_CANCEL: 'Invitation cancelled.',
  NTF_SUCCESS_RESET_PASSWORD: 'Password changed with success.',
  NTF_SUCCESS_MAGIC_SEND_EMAIL: 'A link has been sent to you by email.',
  NTF_SUCCESS_MAGIC_SEND_SMS: 'A link has been sent to you by SMS.',
  NTF_SUCCESS_SAVE_CUSTOMER: 'The customer was saved with success.',
  NTF_SUCCESS_DELETE_LOCATION: 'The location was deleted with success.',
  NTF_SUCCESS_SAVE_PROFILE: 'Profile saved with success.',
  NTF_SUCCESS_SAVE_PASSWORD: 'Password saved with success.',
  NTF_SUCCESS_SEND_FEEDBACK: 'Feedback sent with success.',
  NTF_SUCCESS_SEND_TO_CRM: ' Conversation was sent to CRM successfully.',
  NTF_MSG_ARCHIVED: 'The conversation has been successfully archived',
  NTF_MSG_UNARCHIVED: 'The conversation has been successfully unarchived',
  NTF_SUCCESS_MSG_ASSIGN: 'Successfully assigned to this conversation.',
  NTF_SUCCESS_MSG_UNASSIGN: 'Successfully unassigned from this conversation.',

  TOOLTIP_PHONE: '(10 digits with area code)',

  TITLE_DASHBOARD: 'Dashboard',
  TITLE_USER_DASHBOARD: 'User Dashboard',
  TITLE_USER_PERFORMANCE_DATA: 'User performance data',
  TITLE_MATADOR_PERFORMANCE_DATA: 'Matador Connect performance data',

  TITLE_MESSAGES: 'Conversations',
  TITLE_INVITATIONS: 'Reviews',
  TITLE_NPS: 'NPS',
  TITLE_USER_LIST: 'Manage Users',
  TITLE_LOCATION_LIST: 'Manage Locations',
  TITLE_LEADERBOARD: 'Leaderboard',
  TITLE_ORGANIZATIONS: 'Manage Organizations',
  TITLE_LOGOUT: 'Log Out',

  WORD_DASHBOARD_STATS_INVITATION: 'Invitations Sent Stats',
  WORD_DASHBOARD_STATS_RECOMMENDED: 'Recommended Stats',
  WORD_DASHBOARD_STATS_REVIEW: 'Review Stats',
  WORD_DASHBOARD_STATS_FOLLOWED: 'Followed Stats',
  WORD_DASHBOARD_STATS_INCOMING_MSG: 'Incoming messages',
  WORD_DASHBOARD_STATS_OUTGOING_MSG: 'Outgoing messages',

  WORD_INVITATIONS_SUBTITLE: 'Recently Sent',
  TEXT_INVITATIONS_DESC: "Showing the last invitations you've sent",
  TEXT_INVITATIONS_SEARCH: 'Search All Sent Invites...',

  ADD_CONDITION: 'Add new condition',

  TEXT_NO_QUESTIONS: 'There are no questions yet',
  ADD_QUESTION: '+ Add a question',

  TEXT_LEADERBOARD_HELP:
    'As a Manager, you see more than a regular user. They only see Names and Ranks.',

  TITLE_LOGIN_PASSWORD: 'Password Login',
  TEXT_LOGIN_PASSWORD_HELP: 'If you know your password login here',
  TEXT_FORGOT_PASSWORD: 'Forgot your password?',
  TITLE_LOGIN_MAGIC: 'Magic Link Login',
  TEXT_LOGIN_MAGIC_HELP:
    'Receive a link by email or phone that automatically logs you with no password',

  TEXT_VIDEO_CALL_END: 'Video call ended',
  TEXT_INCOMING_CALL: 'Incoming call',
  TEXT_INCOMING_CALL_FROM: 'Incoming call from',

  TITLE_FEEDBACK_NEG: 'Thanks for your feedback',
  TEXT_VARIABLE_REQUIRED_BOTH_LANGUAGE:
    '[missingVariable] is required for both languages. Please fill the [missingLanguage] field',

  TEXT_FEEDBACK_NEG_HELP: "We're really sorry!",
  TEXT_FEEDBACK_NEG_INPUT_PLACEHOLDER: 'Let us know what happened',

  TITLE_FEEDBACK_NEG_CONFIRM: 'Thank you for your feedback',
  TEXT_FEEDBACK_NEG_HELP_CONFIRM:
    'We are continuously working on improving our customer experience. Your feedback matters to us.',

  WORD_SOCIAL_FB: 'Facebook',
  WORD_SOCIAL_GOOGLE: 'Google',
  WORD_SOCIAL_INSTAGRAM: 'Instagram',
  WORD_SOCIAL_YELP: 'Yelp',

  TITLE_FB_MESSENGER: 'Facebook messenger',
  TITLE_IG_MESSENGER: 'Instagram messenger',
  TITLE_GOOGLE_BUSINESS: 'Google business',

  TITLE_FEEDBACK_POS_LIST:
    'To finish your experience, please, choose where you would like to leave your review',

  TITLE_MESSAGE_INVITATION: 'Sent Invitation to Review',

  TITLE_EDIT_CUSTOMER: 'Edit Customer',

  TITLE_DEFAULT_COMMUNICATION_METHOD: 'Default communication method',

  TEXT_NO_RESULT: 'No results.',

  TEXT_USERS_SEARCH: 'Search Users...',

  CONFIRM_SEND_INVITATION: 'Do you want to send this invitation?',

  MSG_OPTION_MY_MESSAGES: 'My Messages',
  MSG_OPTION_UNASSIGNED: 'Unassigned',
  MSG_OPTION_FOLLOW_UPS: 'Follow-ups',
  MSG_OPTION_ARCHIVED: 'Archived',
  MSG_OPTION_MAIN_INBOX: 'Main Inbox',

  MSG_OPTION_SMART_INBOX: 'Smart',

  TITLE_CHAT_SETTINGS: 'Chat Settings',

  WORD_SPECIALS: 'Specials',
  WORD_GREETING: 'Greeting',
  WORD_DISPLAYED: 'Displayed',
  WORD_CONVERSATIONS: 'Conversations',
  WORD_CONVERSATION: 'Conversation',
  WORD_CONVERSION_RATIO: 'Conversion ratio',
  WORD_GREETINGS: 'Greetings',
  WORD_QUESTIONS: 'Questions',
  WORD_TOTAL_GREETINGS: 'Total Greetings',
  WORD_TOTAL_QUESTIONS: 'Total Questions',
  SUCCESS_QUESTION_DELETE: 'Question was deleted successfully.',
  SUCCESS_GREETING_DELETE: 'Greeting was deleted successfully.',
  SUCCESS_PROMOTION_DELETE: 'Promotion was deleted successfully.',
  SUCCESS_SPECIAL_DELETE: 'Special was deleted successfully.',
  SUCCESS_ANNOUNCEMENT_DELETE: 'Announcement was deleted successfully.',
  SUCCESS_PROMOTION_UPDATE: 'Promotion was updated successfully.',
  SUCCESS_SPECIAL_UPDATE: 'Special was updated successfully.',
  SUCCESS_ANNOUNCEMENT_UPDATE: 'Announcement was updated successfully.',
  SUCCESS_GREETING_UPDATE: 'Greeting was updated successfully.',
  SUCCESS_GREETING_SET_DEFAULT: 'Greeting was set as default successfully.',
  CONFIRMATION_SET_DEFAULT_GREETING:
    'Are you sure you want to set this greeting as default?',
  CONFIRMATION_DELETE_VARIABLE:
    'Are you sure you want to delete this [variableToDelete]?',
  CONFIRMATION_DELETE_GREETING: 'Are you sure you want to delete this greeting?',
  CONFIRMATION_DELETE_QUESTION: 'Are you sure you want to delete this question?',

  SUCCESS_TRIGGER_SAVED: 'Trigger saved successfully.',
  WORD_TOTAL_PROMOTIONS: 'Total Promotion',
  WORD_TOTAL_SPECIALS: 'Total Specials',
  WORD_TOTAL_ANNOUNCEMENTS: 'Total Announcements',
  ERROR_END_DATE_AFTER_START_DATE: 'End date must be after start date',
  SUCCESS_APPEARANCE_SAVED: 'Appearance saved successfully.',
  SUCCESS_ANNOUNCEMENT_SAVED: 'Announcement saved successfully.',
  SUCCESS_PROMOTION_SAVED: 'Promotion saved successfully.',
  SUCCESS_SPECIAL_SAVED: 'Special saved successfully.',
  WORD_MOBILE_COUPONS: 'Mobile Coupons',
  WORD_EXIT_INTENT: 'Exit Intent',

  WORD_TOTAL_CONVERSATIONS: 'Total conversations',
  TEXT_FOR_LAST_MONTH: 'For last month',
  TEXT_CONVERSATIONS_FOR_LAST_MONTH: 'Conversations for last 30 days',
  TEXT_COMPARED_FROM_LAST_MONTH: 'Compared from previous 30 days',
  WORD_CLICKS: 'Clicks',

  WORD_USER: 'User',
  WORD_CLIENTS: 'Clients',
  WORD_APPOINTMENTS: 'Appointments',

  WORD_SITE_URL: 'Site URL',
  WORD_PRIMARY: 'Primary',
  WORD_HEADER: 'Header',
  WORD_WIDGET_TAG: 'Widget Tag',
  WORD_WIDGET_CODE: 'Widget Code',
  WORD_WIDGET_POSITION: 'Widget Position',
  WORD_OPEN_WIDGET_CODE:
    'Open Widget Code (add class `matador-open-button` to any HTML element)',
  WORD_CHAT_SETTINGS_WELCOME_MESSAGE: 'Welcome message',
  WORD_CHAT_SETTINGS_AUTO_REPLY_LABEL: 'Auto reply',
  WORD_CHAT_SETTINGS_AUTO_REPLY_PLACEHOLDER: 'Chat  first message',

  TITLE_LIVE_PREVIEW: 'Live Preview',

  TEXT_LABEL_CREATE_OPPORTUNITIES_IN_VIN_SOLUTIONS:
    'Create opportunities in VinSolutions ONLY when a client replies with positive sentiment.',

  WORD_SELECT_ORGANIZATIONS: 'Select organizations',
  WORD_SELECT_LOCATIONS: 'Select locations',
  WORD_SELECT_LANGUAGE: 'Select language',
  WORD_WELCOME_MESSAGE: 'Welcome Message',
  WORD_VISIBILTY: 'Visibility',
  WORD_AUTO_REPLY: 'Auto Reply',
  WORD_AUTO_REPLY_FIRST: 'Auto Reply (first message)',
  WORD_AUTO_REPLY_SECOND: 'Auto Reply (second message)',
  WORD_DISPLAY_GREETING_WHEN: 'Display the greeting when',

  WORD_CURRENT_PAGE_ADDRESS: 'Current page address',
  WORD_URL_STARTS_WITH: 'URL starts with',
  WORD_URL_CONTAINS: 'URL contains',

  WORD_CURRENT_PAGE_TIME: 'Time on the current page',
  TEXT_GREETING_TIME_TRIGGER_START: 'Is more than',
  TEXT_GREETING_TIME_TRIGGER_END: 'seconds',
  WORD_SELECT_QUESTIONS: 'Select questions',

  WORD_QUESTION: 'Question',
  TEXT_HELP_TYPE_QUESTION: 'Type your question here...',
  NTF_SUCCESS_SAVE_CHAT_SETTINGS: 'Success Saved Chat Settings',
  NTF_SUCCESS_SAVE_GREETIONG: 'Success Saved Chat Trigger',
  NTF_NEED_FILL_MORE_FIELDS: 'Need To Fill More Fields',

  WORD_CA_PHONE: 'CA Phone',
  WORD_US_PHONE: 'US Phone',

  WORD_EDIT_ORGANIZATON: 'Edit Organization',
  WORD_CREATE_ORGANIZATON: 'Create Organization',
  ACTION_CREATE_ORGANIZATION: 'Create Organization',

  NTF_CHAT__REQUIRED: 'The Phone field should contain 11 digits.',
  NTF_CHAT__REQUIRED_10: 'The Phone field should contain 10 digits.',
  WORD_ID: 'ID',
  TEXT_CONNECT_YOUR_EMAIL: 'Connect your Email',
  TEXT_DISCONNECT_YOUR_EMAIL: 'Disconnect your email',

  TEXT_CONNECT_YOUR_GMAIL_ACCOUNT: 'Connect your Gmail account',
  TEXT_CONNECT_YOUR_EMAIL_ACCOUNT:
    'You need to connect your email account and give read and send permission to Matador application. We will use this to send email invitations to customers.',
  TEXT_DISCONNECT_YOUR_GMAIL_ACCOUNT: 'Disconnect your Gmail account',
  TEXT_CONNECT_GMAIL: 'Connect Gmail',
  TEXT_CONNECT_YOUR_OFFICE_365_ACCOUNT: 'Connect your Office 365 account',
  TEXT_CONNECT_OFFICE_365: 'Connect Office 365',
  TEXT_DISCONNECT_OFFICE_365: 'Disconnect Office 365',
  WORD_INFO: 'Info',
  WORD_BOOKING: 'Booking',
  WORD_SOURCE: 'Source',
  WORD_TAGS: 'Tags',
  TEXT_APPOINMENT_DATE: 'Appoinment date',
  TEXT_DATE_AND_TIME: 'Date & Time',

  NTF_SUCCESS_SEND_BOOKING_INVITATION:
    'Appointment date with [Customer] was changed to [date] at [time]',
  NTF_SUCCESS_SEND_BOOKING_INVITATION_NEW:
    'Appointment with [Customer] is booked on [date] at [time]',
  NTF_SUCCESS_DELETED_BOOKING_INVITATION:
    'Appointment [date] with [Customer] was canceled',

  MSG_DELETE_BOOKING_INVITATION: 'Are you sure you want to cancel the appointment?',
  MSG_UPDATE_BOOKING_INVITATION: 'Are you sure you want to edit the appointment?',
  TEXT_BOOKED_AN_APPOINTMENT: 'Booked an Appointment',
  TEXT_DRIP_SEQUENCE_AT_WORK: 'Drip Sequence at Work',
  TEXT_THIS_MONTH: 'This month',
  WORD_MTD: 'MTD',
  TEXT_PROFILES_MESSAGED: 'Profiles Messaged',
  TEXT_MESSAGES_SENT: 'Messages Sent',
  TEXT_INVITATIONS_SENT: 'Invitations Sent',
  WORD_ENGAGMENT: 'Engagement',
  TEXT_ENGAGMENT_PERCENTAGE: 'Engagement Percentage',
  TEXT_INVITES_SENT: 'Invites sent',
  TEXT_OPEN_RATE: 'Open Rate',
  WORD_REVIEWS: 'Reviews',
  TEXT_MARKETING_SOURCE: 'Marketing Source',
  TEXT_TOTAL_ACTIVITY: 'Total Activity',
  TEXT_INBOUND_AND_OUTBOUND: 'Inbound & Outbound',
  WORD_APPOINTMENT_TEXT: 'Appointment',
  TEXT_BOOKED_APPOINTMENTS: 'Booked Appointments',
  WORD_ORGANIC: 'Organic',
  WORD_INBOUND: 'Inbound',
  WORD_CALLS: 'Calls',
  TEXT_INBOUND_VALUME: 'inbound volume',
  WORD_MESSAGES_RECEIVED: 'Messages Received',
  WORD_APPOINMENTS_SET: 'Appointments Set',
  WORD_REVIEW_INVITES_SENT: 'Review Invites Sent',
  WORD_NOTES: 'Notes',
  TEXT_ADD_NOTES: 'Add notes',
  TEXT_NEW_NOTE: 'New Note',
  TEXT_EDIT_NOTE: 'Edit Note',
  TEXT_ADD_NEW_NOTES: 'Add New Note',
  TEXT_CALENDAR_INVITE: 'Calendar Invite',
  TEXT_CALENDAR_INVITES_REMINDER: 'Calendar Invites Reminder',
  TEXT_TEXT_REMINDERS: 'Text Reminders',
  TEXT_TEXT_ME: 'Text Me',
  TEXT_MATADOR_CONNECT: 'Matador Connect',

  ERR_NOTE_NAME_IS_REQUIRED: 'Note Content is required',
  ERR_PHONE_IS_REQUIRED: 'Phone is required',
  UPDATE_APP:
    "Hi there, we've made some great upgrades to Matador. To ensure you benefit from all the enhancements of the platform, please log out and re-login.",

  MSG_TEXT_CONTENT_FIELD_IS_REQUIRED: 'Text Content field is required',
  MSG_FIRST_AND_LAST_NAMES_ARE_REQUIRED: 'First and Last names are required.',

  DAILY: 'Daily',
  MONTHLY: 'Monthly',
  WEEKLY: 'Weekly',
  WEEKLY_STATS: 'Weekly',
  MONTHLY_STATS: 'Monthly',

  TEXT_AUTO_DETECTED_LANGUAGE: 'Auto detected language',
  TEXT_AUTO_DETECTED: 'Auto detected',

  TEXT_SEND_SMS_BUTTON_SETTINGS: 'Send SMS button settings',
  LABEL_TITLE: 'Title',
  LABEL_DESCRIPTION: 'Description',
  LABEL_URL_OPTIONAL: 'Url (optional)',
  LABEL_IMAGE_URL: 'Image Url',
  LABEL_START_DATE: 'Start Date',
  LABEL_END_DATE: 'End Date',
  LABEL_TEXT_ON_BUTTON: 'Text on button',
  LABEL_FONT_SIZE: 'Font size',
  LABEL_BORDER_RADIUS: 'Border radius',
  LABEL_HEIGHT: 'Height',
  LABEL_WIDTH: 'Width',
  WORD_HEX: 'Hex',
  LABEL_BACKGROUND_COLOR: 'Background color',
  LABEL_TEXT_COLOR: 'Text color',
  WORD_COPIED: 'Copied!',
  WORD_BUTTON_POSITION: 'Button position',
  WORD_FIXED: 'Fixed',
  WORD_FLOAT: 'Float',
  WORD_BUTTON_MARGIN: 'Button margin',
  WORD_MARGIN_RIGHT: 'Margin right',
  WORD_LIGHT: 'Light',
  LABEL_SHOW_WIDGET_DESKTOP: 'Show widget on Desktop',
  LABEL_SHOW_ORGANIZATION_ADDRESS: 'Display all organization addresses',
  LABEL_TRIGGER_NEW_SESSION: 'Only trigger greeting on new session',
  LABEL_TEXT_TEMPLATE: 'Text template',
  TEXT_ONE_CLICK_CONVERT: 'One click convert',
  LABEL_BORDER_WIDTH: 'Border width',
  LABEL_BORDER_COLOR: 'Border color',
  LABEL_OFFSET_X: 'Offset X',
  LABEL_OFFSET_Y: 'Offset Y',
  LABEL_BLUE: 'Blur',
  LABEL_SHADOW_COLOR: 'Shadow color',
  LABEL_BUTTON_SHADOW: 'Button shadow',

  MSG_CONFIRM_DELETE: 'Are you sure you want to Delete ?',

  TEXT_UNREAD_MSG: 'Unread',

  WORD_GENERAL: 'General',
  TEXT_UPDATE_ORGANIZATION_DETAILS: 'Update organization details',
  TEXT_UPDATE_ORGANIZATION_TAGS: 'Update organization tags',
  LABEL_ORGANIZATION_NAME: 'Organization name',
  LABEL_TWILIO_MESSAGING_SID: 'Messaging Sid',
  TEXT_SET_AS_DEFAULT: 'Set as default',
  WORD_TIME_ZONE: 'Time zone',
  WORD_TOTAL_SEQUENCES: 'Total sequences',
  WORD_ADD_SEQUESNCE: 'Add sequence',
  WORD_CREATE_WORKING_HOURS_SEQUENCE: 'Create working hours sequence',
  WORD_CREATE_AFTER_HOURS_SEQUENCE: 'Create after-hours sequence',
  TEXT_AFTER_HOURS: 'After-Hours',
  TEXT_WORKING_HOURS: 'Working Hours',
  TEXT_NAME_OF_SEQUENCE: 'Name of sequence',
  WORD_PROFILES: 'Profiles',
  WORD_RESPONSE: 'Response',
  TEXT_SEQUENCE_NAME_GOES_HERE: 'Sequence name goes here',
  TEXT_SEQUENCE_NAME: 'Sequence name',
  TEXT_SEQUENCE_STATUS: 'Sequence status',
  WORD_SEQUENCE: 'Sequence',
  TEXT_SEND_FOLLOWUP: 'Send follow-up',
  WORD_DAYS_AFTER: 'days after',
  WORD_FIRST_AUTO_MESSAGE: 'First auto-message',
  TEXT_SHOULD_BE_SENT_IMMEDIATELY_AFTER_RECEIVING_A_LEAD:
    'should be sent immediately after receiving a lead.',
  WORD_ENGLISH_MESSAGE: 'English message',
  WORD_FRENCH_MESSAGE: 'French message',
  WORD_ADD_TAG: 'Add tag',
  WORD_NEW_AUTO_MESSAGE: 'New auto-message',
  WORD_DAYS: 'Days',
  TEXT_FIRST_AUTO_MESSAGE_EN: 'First auto-message EN',
  TEXT_FIRST_AUTO_MESSAGE_FR: 'First auto-message FR',
  MSG_DELETE_AI_BOT_MESSAGE_ITEM: 'Are you sure want to delete the message?',
  WORD_TIME_ZONE_IS_REQUIRED: 'Please Select Time Zone',
  WORD_TRIGGERED_ON: 'Triggered on ...',
  WORD_TOUCHPOINTS: 'Touchpoints',
  WORD_ACTIVITY: 'Activity',
  TEXT_UPDATE_ORGANIZATION_TOUCHPOINTS: 'Update organization touchpoints',
  TEXT_DATE_OF_CREATION: 'Date of creation',
  WORD_CONSENT: 'Consent',
  WORD_USE_ICON: 'Use Icon',
  WORD_USE_ANIMATION: 'Use Animation',
  WORD_ADD_LINK_TO_BODY: 'Add link to body',
  WORD_NEW: 'New',
  TEXT_ORGANIZATION_RESTRICTIONS_SETTINGS: 'Organization restrictions settings',
  WORD_RESTRICTIONS: 'Restrictions',
  WORD_USER_TEMPLATES: 'User Templates',
  WORD_USER_SMS_TEMPLATES: 'User SMS Templates',
  WORD_USER_EMAIL_TEMPLATES: 'User Email Templates',
  WORD_EMAIL_TEMPLATES: 'Email Templates',
  WORD_SMS_TEMPLATES: 'SMS Templates',
  WORD_OPENING_HOURS: 'Opening hours',
  TEXT_ORGANIZATION_TEMPLATES: 'Organization templates',
  LABEL_TEMPLATE_NAME: 'Template name',
  LABEL_TEMPLATE_SUBJECT: 'Template subject',
  LABEL_TEMPLATE_BODY: 'Template body',
  TEXT_SIGN_IN_WITH_GOOGLE: 'Sign in with Google',
  TEXT_SIGN_OUT_WITH_GOOGLE: 'Sign out from Google',
  TITLE_CREATE_NEW_QUESTION: 'Create new question',
  TITLE_EDIT_QUESTION: 'Edit question',
  WORD_NEW_QUESTION: 'New question',
  LABEL_QUESTION_NAME: 'Question name',
  TITLE_CREATE_NEW_GREETING: 'Create new greeting',
  TITLE_EDIT_GREETING: 'Edit greeting',
  TITLE_EDIT_PROMOTION: 'Edit Promotion',
  TITLE_EDIT_SPECIAL: 'Edit Special',
  TITLE_EDIT_ANNOUNCEMENT: 'Edit Announcement',
  TITLE_CREATE_PROMOTION: 'Create new promotion',
  TITLE_CREATE_SPECIAL: 'Create new special',
  TITLE_CREATE_ANNOUNCEMENT: 'Create new announcement',
  WORD_NEW_GREETING: 'New greeting',
  LABEL_QUESTION_MESSAGE: 'Question message',
  LABEL_ANSWER_MESSAGE: 'Answer message',
  TEXT_ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_QUESTION:
    'Are you sure you want to delete this question?',
  WORD_BROADCASTING: 'Broadcasting',
  WORD_AUTOMATIONS: 'Automations',
  WORD_TOTAL_CAMPAIGNS: 'Total campaigns',
  WORD_SEARCH: 'Search ...',
  WORD_SORT: 'Sort by',
  WORD_CREATE_CAMPAIGN: 'Create campaign',
  WORD_CREATED_BY: 'Created by',
  WORD_DATE_TIME: 'Date/time',
  WORD_AUDIENCE: 'Audience',
  WORD_REPLY: 'Reply',
  WORD_OPPORTUNITIES: 'Opportunities',
  WORD_SELECT: 'Select',
  WORD_SELECT_TIME: 'Select time',
  WORD_PLEASE_SELECT_TIME: 'Please select time!',
  WORD_PLEASE_SELECT_DATE: 'Please select date!',
  TEXT_BROADCAST_WILL_BE_SENT:
    'broadcast will be sent according to the specified schedule',
  TEXT_ALL_TIME_ZONES_UNAVAILABLE:
    'All time slots are unavailable, please select another date',
  TEXT_UPLOAD_WITH_EXCEL_FILE: 'Upload CSV or Excel File',

  TEXT_UPLOAD_WITH_EXCEL_FILE_INFO:
    'Upload your list of customers in CSV or Excel file',
  TEXT_CREATE_A_SEGMENT_INFO: 'You create a segment based on all the customers',
  TEXT_UPLOAD_WITH_EXCEL: 'Upload CSV or Excel File',
  WORD_CREATE: 'Create',
  TEXT_UPLOAD_EXCEL_FILE_NOTE:
    "Upload your list of customers in an excel file with 'first name', 'last name', 'phone number', 'email', 'language' columns.",
  UPLOAD_FILE_XLSX: 'Upload file (XLSX)',
  ACTION_APPLY: 'Apply',
  ACTION_SCHEDULE: 'Schedule',
  UPLOAD_IMAGE: 'Upload image',

  WORD_REPLACE: 'Replace',

  WORD_SELECTED_USERS: 'Selected Users',
  WORD_SELECTED: 'Selected',
  WORD_LIST: 'List',
  LABEL_DO_WE_SHOW_IT_ON_MOBILE: 'Do we show it on Mobile?',
  ACTION_SHOW_ON_MOBILES: 'Show on mobiles',
  ACTION_SHOW_ON_DESKTOPS: 'Show on desktops',
  WORD_PHONE_NUMBER: 'Phone number',

  UPLOAD_EXCEL_FILE: 'Choose your audience',
  TEXT_CREATE_CAMPING_TYPE_DESCRIPTION:
    'Start by uploading a list of prospects or segment through the existing database',

  BUILD_CONTENT: 'Build the content',

  TEXT_CREATE_A_SEGMENT: 'Create a Segment ',
  TEXT_CREATE_SEGMENT_DESCRIPTION:
    'Go as granular as you need to target the ideal prospect',
  WORD_LANGUAGES: 'Languages',
  WORD_ASSIGNED_TO: 'Assigned to',

  TEXT_UPLOAD_FILE: 'Upload File',

  TEXT_FILE_UPLOAD_ERROR:
    'The file format is incorrect. Please upload a file in .xls format',

  WORD_NEXT: 'Next',
  WORD_PREVIOUS: 'Previous',

  TEXT_START_YOUR_CAMPAIGN: 'Start your campaign',
  TEXT_NAME_OF_BROADCAST: 'Name of broadcast',
  TEXT_LOCATION_DO_THESE_USERS_BELONG_TO: 'Which location do these users belong to?',
  TEXT_CONSENT_DESCRIPTION:
    'These clients have already been opted in to our messaging services',

  TEXT_LETS_USE_SOME_MACHINE_LEARNING: 'Lets use some machine learning',
  TEXT_DESCRIPTION_OF_USE_SOME_MACHINE_LEARNING:
    'Sentiment analysis is available only for VinSolutions CRM. If the sentiment analysis is inactive, please connect a VinSolutions account on at least one Location of this Organization.',
  TEXT_LABEL_OF_USE_SOME_MACHINE_LEARNING:
    'Would you want to utilize A.I to set rules based on replies?',

  WORD_SCHEDULER: 'Scheduler',
  TEXT_ASSIGN_CAMPAIGN_TO: 'Assign campaign to',
  WORD_DATE: 'Date',
  WORD_TIME: 'Time',
  TEXT_ADD_A_TAG_FOR_BROADCASTED_USERS: 'Add a tag for broadcasted users',

  TEXT_TERMS_AND_CONDITIONS: 'Terms and conditions',
  TEXT_TITLE_OF_TERMS_AND_CONDITIONS:
    'I accept that the client list I will be broadcasting too has given me authority to reach out to them through SMS for any promotional related communications.',

  TOOLTIP_TEXT:
    'This action is not supported for [messanger] messenger conversations just yet',
  TEXT_ENTER_YOUR_QUESTION_HERE: 'Enter your question here.',
  TEXT_CHAT_HELP_START_CONVERSATION: 'Start your conversation below.',
  TEXT_CHAT_HELP_CHOOSE_QUESTION: 'OR choose from a question below:',
  POWERED_BY: 'Powered by',
  USE_AS_SUBJECT_TO: 'Use as subject to',
  TERMS: 'terms',
  TEXT_MESSAGE_FEES_MAY_APPLY: 'Text message fees may apply.',
  TEXT_RECEIVED: 'Received',
  WE_GOT_YOUR_MESSAGE: 'We got your message',
  CHAT_TEXT_ME: 'Text Me',
  MOBILE_PHONE: 'Mobile Phone',
  NUMBER_PROVIDED: 'number provided.',
  MESSAGE_DATA_RATES_APPLY: 'Message/data rates apply',

  MESSAGE_CONSENT_CHANGED:
    '[user] changed the consent status of this customer from [previousStatus] to [newStatus]',

  MATADOR_AI: 'Matador.ai',

  TEXT_ORGANIZATION_BROADCASTING_SETTINGS: 'Organization broadcasting settings',
  FORWARD_NEW_LEADS_TO_CRM: 'Forward new leads to CRM',
  TEXT_LOGGED_ACCOUNT: 'Logged account',

  WORD_PROFILE_INFORMATION: 'Profile information',
  WORD_NOTIFICATION_SETTINGS: 'Notification settings',
  WORD_PROFILE_SETTINGS: 'Profile settings',
  WORD_NOTIFICATIONS: 'Notifications',
  WORD_ADDITIONAL_CONFIGS: 'Additional configs',
  TEXT_NOTIFICATIONS_BEING_ASSIGNED: 'Assigned to a conversation',
  TEXT_SELECTED_CONVERSATIONS_ONLY: 'On selected organizations only',
  TEXT_ASSIGNED_TO_CONVERSATIONS: 'Assigned to a conversation',
  LABEL_HOW_DO_YOU_WANT_TO_RECEIVE_NOTIFICATIONS:
    'How do you want to receive notifications?',
  ACTION_DO_YOU_WANT_TO_RECEIVE_NOTIFICATIONS:
    'Do you want to receive notifications?',

  NTF_PHONE_NUMBER_REQUIRED: 'Please,  enter phone number',
  EMPTY_PHONE_NUMBER_REQUIRED: 'Phone number must contain 10 digits',

  WORD_LAST_SEEN_AT: 'Last seen at:',

  TEXT_HIDE_ADDITIONAL_VISUALS: 'Hide additional visuals',
  TEXT_SHOW_ADDITIONAL_VISUALS: 'Show additional visuals',

  TEXT_CREATE_NEW_COUPON: 'Create new Coupon',
  TEXT_CREATE_NEW_PROMOTION: 'Create a new Promotion',
  TEXT_CREATE_NEW_EXIT_INTENT: 'Create a new Exit Intent',
  TEXT_GROW_SUBSCRIBERS_WITH_MATADOR: 'Grow subscribers with Matador',
  LABEL_POPUP_DELAY: 'Popup delay',
  LABEL_POPUP_TRIGGER: 'Popup trigger',
  LABEL_THEME: 'Theme',
  TEXT_SHOW_ONCE_PER_DAY_TO_EACH_USER: 'Show once per day to each user.',
  TEXT_DRAG_LOGO_HERE_TO_UPLOAD: 'Add a photo to add to the smart pop-up',
  WORD_ATTACHMENT: 'Attachment',
  TEXT_VIEW_THIS_PAGE: 'View this page',
  ARE_YOU_SURE_YOU_WANT_TO_CLOSE_THE_NOTE_THE_NOTE_WILL_NOT_BE_SAVED:
    'Are you sure you want to close the note? The note will not be saved',
  TEXT_GET_10_OFF_YOUR_ORDER: 'Get 10% Off Your Order',
  TEXT_BE_THE_FIRST_TO_KNOW_ABOUT_NEW_PRODUCTS_AND_SPECIAL_DISCOUNTS:
    'Be the first to know about new products and special discounts.',
  TEXT_SWIPE_TO_START: 'Swipe to Start',
  WORD_TERMS_LINK: 'Terms link',
  TEXT_TERMS_LINK_INVALID: 'The Terms Link is invalid',
  WORD_DEFAULT_IMAGE_URL: 'Default Image Url',
  WORD_GA_TRACKING_ID: 'GA Tracking ID',
  TEXT_DRAG_A_FILE_HERE: 'Drag a file here',
  WORD_OR_LOW: 'or',
  WORD_BROWSE: 'browse',
  TEXT_FOR_A_FILE_TO_UPLOAD: 'for a file to upload',

  TEXT_WOULD_YOU_WANT_TO_UTILIZE_AI_TO_SET_RULES_BASED_ON_REPLIES:
    'Would you want to utilize A.I to set rules based on replies?',
  TEXT_ADD_AN_APPOINTMENT_TO_THE_BROADCAST: 'Add an appointment to the broadcast',
  WORD_LAST_MESSAGE: 'Last Message',
  WORD_LAST_MESSAGE_RECEIVED: 'Last message sent/received',
  ERROR_PLEASE_FILL_OUT_THE_MESSAGE_TEXT_FIELD:
    'Please fill out the message text field',
  TEXT_THE_CONVERSATION_MOVED_TO_MY_MESSAGES_SUCCESSFULLY:
    'The conversation moved to My messages successfully',
  TEXT_ANSWERED_QUESTION: 'Answered question',
  TEXT_HOURS_SAVED: 'Hours saved',
  WORD_THE_BRAIN: 'The Brain',
  WORD_DISCUSSED_TOPICS: 'Discussed topics',

  WORD_PRICE: 'Price Inquiry',
  WORD_PAYMENT: 'Payments',
  WORD_TRADES: 'Trade-In Options',
  WORD_SCHEDULE: 'schedule',
  WORD_APPROVAL: 'Approval Inquiry',
  WORD_AVAILABILITY: 'Availability',
  WORD_GENERAL_AVAILABILITY: 'General Availability',
  WORD_DEALER_FEE: 'Dealer Fees',
  WORD_DOWN_PAYMENT: 'Down Payment',
  WORD_FINANCE: 'Financing',
  WORD_GENERAL_FINANCE: 'General Finance',
  WORD_HISTORY_REPORT: 'Vehicle History',
  WORD_NEGOTIATIONS: 'Negotiation Details',
  WORD_SHIPPING: 'Shipping Details',
  WORD_VEHICLE_OPTIONS: 'Vehicle Options',
  WORD_CONFIRMED: 'Confirmation',
  WORD_APPOINTMENT_CONFIRMATION: 'Appointment Confirmation',
  WORD_HOLD_TRANSFER: 'Hold Transfer',
  WORD_HOURS_LOCATION: 'hours location',
  WORD_PROCESS: 'Process',
  WORD_REAPPOINTMENT: 'Reschedule Appointment',
  WORD_VEHICLE_RECOMMENDATIONS: 'Vehicle Recommendations',
  WORD_VEHICLE_QUESTIONS: 'vehicle questions',
  WORD_APPOINTMENT: 'Appointment request',
  WORD_GENERAL_APPOINTMENT_QUERIES: 'General Appointment Queries',
  WORD_RESCHEDULE: 'reschedule',
  'WORD_PROCESS.CALLBACK': 'Callback Request',
  'WORD_FINANCE.ONLINE_PROCESSING': 'Financing process',
  'WORD_HOURS_AND_LOCATION.LOCATION': 'Dealership Location',
  'WORD_PROCESS.NOT_INTERESTED': 'Lack of Interest',
  'WORD_HOURS_AND_LOCATION.WORKING_HOURS': 'Dealership Hours',
  'WORD_FINANCE.BAD_CREDIT': 'Bad credit',
  'WORD_PROCESS.CUSTOMER_HAS_QUERIES': 'Customer Questions',
  'WORD_VEHICLE_OPTIONS.PICTURES': 'Vehicle Photo Requests',
  'WORD_PROCESS.ALREADY_DISCUSSED': 'Previous Discussions',
  'WORD_PROCESS.WHO_IS_THIS': 'Identity Verification',
  'WORD_APPOINTMENT.ALREADY_BOUGHT': 'Post-Purchase Inquiries',
  'WORD_AVAILABILITY.UPDATE_ON_VEHICLE': 'Vehicle Updates',
  'WORD_APPOINTMENT.ALREADY_SCHEDULED': 'Scheduled Appointments',
  'WORD_FINANCE.REQUIRED_DOCUMENTS': 'Required documents',
  'WORD_FINANCE.INSURANCE': 'Insurance query',
  'WORD_APPOINTMENT.CANCEL_APPOINTMENT': 'Cancel Appointment',
  'WORD_APPOINTMENT.SERVICE_APPOINTMENT': 'Service Appointment',
  'WORD_AVAILABILITY.NOT_SURE_OF_VEHICLE': 'Vehicle Uncertainty',
  'WORD_PROCESS.WHO_DO_I_SPEAK_TO': 'Contact Person',
  'WORD_PROCESS.IN_PLACE_WITH_REPRESENTATIVE': 'Process Inquiry',
  'WORD_APPOINTMENT.ALREADY_CANCELLED_APPOINTMENT': 'Cancelled Appointments',
  WORD_UNSUBSCRIBE: 'Unsubscribe',
  'WORD_FINANCE.CREDIT_SCORE': 'User is asking about credit scoring',
  'WORD_FINANCE.ALREADY_SUBMITTED_APPLICATION': 'Submitted Application',
  WORD_TIRE_ROTATION: 'Tire Service',
  WORD_PARTS_AVAILABILITY: 'Parts availability',
  WORD_PART_PRICE: 'Part price',
  WORD_CAR_WASH: 'Car Wash Service',
  WORD_OIL_CHANGE: 'Oil Change Service',
  WORD_FIRST_SEEN: 'First seen',
  WORD_LAST_SEEN: 'Last seen',
  WORD_DAYS_BROWSING: 'Days browsing',
  WORD_PAGES_VIEWED: 'Pages viewed',
  TEXT_CONNECT_CRM_EMAIL_IN_LOCATION_SETTINGS:
    'Connect CRM email in location settings',
  TEXT_ORGANIZATION_IS_ACTIVE: 'Organization is active',
  TEXT_ACCESS_TO_BROADCASTING_FEATURE: 'Access to Broadcasting feature',
  TEXT_INTEGRATE_YOUR_PAYMENT_ACCOUNT: 'Integrate your payment account',
  WORD_CONNECT_PAYMENTS: 'Connect payments',
  TEXT_ARE_YOU_SURE_YOU_WANT_TO_DISCONNECT_PAYMENTS:
    'Are you sure you want to disconnect payments?',
  TEXT_ARE_YOU_SURE_YOU_WANT_TO_DISCONNECT_EMAIL:
    'Are you sure you want to disconnect your email',
  WORD_CONNECT: 'Connect',
  WORD_DISCONNECT: 'Disconnect',
  WORD_DISCONNECT_PAYMENTS: 'Disconnect payments',
  TEXT_ORG_POPUP_MESSAGE: ` Turning off an organization will make messaging impossible and will temporarily disable the account. 
  Are you sure you want to move ahead?`,
  TEXT_ORGANIZATION_WAS_CREATED_SUCCESSFULLY:
    'Organization was created successfully.',
  TEXT_ORGANIZATION_WAS_SAVED_SUCCESSFULLY: 'Organization was saved successfully.',
  TEXT_MATADOR_CONNECT_SAVED_SUCCESSFULLY: 'Matador connect saved successfully.',
  WORD_TAG_NAME_HERE: 'Tag name here',
  WORD_DEFAULT: 'Default',
  WORD_WORKFLOW: 'Workflow',
  WORD_WORKFLOWS: 'Workflows',
  restrictTextingCustomersInPendingStatus:
    'Restrict texting customers in Pending compliance status',
  restrictSendingBroadcastsOverTheWeekend:
    'Restrict sending Broadcasts over the weekend',
  restrictSupportAgentsAccessToAllLocations:
    'Restrict Support Agents access to all locations within the organization',
  restrictManagersAccessToAllLocations:
    'Restrict Managers access to all locations within the organization',
  restrictSupportAgentsAccessToUnassignedFolder:
    'Restrict Support Agents access to "Unassigned" folder',
  restrictSupportAgentsAccessToArchivedFolder:
    'Restrict Support Agents access to conversations in "Archived" folder',
  restrictSupportAgentsAccessToMainInboxFolder:
    'Restrict Support Agent access to "Main Inbox" folder',
  TEXT_RESTRICTION_WAS_CHANGED_SUCCESSFULLY: 'Restriction was Changed successfully.',
  restrictDisplayingPushToCRMButtonToSupportAgents:
    'Restrict displaying "Push to CRM" button to Support Agents',
  calendarInvitationsToUsers: 'Send calendar invitation to assigned users',

  WORD_HOUR: 'Hour',
  WORD_OPEN: 'Open',
  WORD_CLOSED: 'Closed',
  WORD_FROM: 'From',
  WORD_TO: 'To',
  WORD_REFUSED: 'Refused',
  WORD_PENDING: 'Pending',
  WORD_COMPLIANT: 'Compliant',
  TEXT_CONSENT_WAS_SENT_SUCCESSFULLY: 'Consent was sent successfully.',
  WORD_APPROVED: 'Approved',
  TEXT_WE_ARE_UNSURE_IF_THIS_CUSTOMER_IS_COMPLIANT_AWAITING_CONSENT:
    'We are unsure if this customer is compliant. Awaiting consent.',
  TEXT_WE_ARE_UNSURE_IF_THIS_CUSTOMER_IS_COMPLIANT_PLEASE_ASK_FOR_CONSENT:
    'We are unsure if this customer is compliant. Please ask for consent.',
  TEXT_WE_HAVE_RECEIVED_CONSENT_FROM_THIS_CUSTOMER:
    'We have received consent from this customer.',
  TEXT_CUSTOMER_HAS_DECIDED_TO_OPT_OUT_FROM_UPCOMING_COMMUNICATIONS:
    'Customer has decided to opt-out from upcoming communications.',
  TEXT_THE_CUSTOMER_DOES_NOT_WANT_TO_RECEIVE_BROADCASTED_MESSAGES:
    'The customer does not want to receive broadcasted messages.',
  TEXT_ARE_YOU_SURE_YOU_WANT_TO_OPT_OUT_THIS_CONVERSATION:
    'Are you sure you want to opt-out this conversation?',
  WORD_OPT_OUT: 'Opt out',
  WORD_AT: 'at',
  WORD_ASK_CONSENT: 'Ask consent',
  TEXT_ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_SEQUENCE:
    'Are you sure you want to delete this Sequence?',
  WORD_LABEL_TRIGGERED_ON: 'Triggered on',
  WORD_LABEL_TRIGGERED_ON_TOUCHPOINTS: 'Triggered on touchpoint',
  WORD_AFTER_HOUR_MESSAGE: 'After-hour message',
  WORD_FIRST_AUTO_MESSAGE_WL: 'First Auto Message',
  WORD_ADD_NEW_DRIP: 'Add New Drip',
  WORD_THE_AFTER_HOUR_MESSAGE: 'The after-hour message',
  WORD_AFTER: 'after',
  TEXT_PLEASE_SELECT_TRIGGERED_ON_OPTION_FIRST:
    'Please, select "Triggered on" option first.',
  TEXT_CUSTOMER_LAST_SESSION: 'customer last session',
  WORD_MESSAGES_LC: 'messages',
  TEXT_YOU_CAN_USE: 'You can use',
  WORD_CUSTOMER_FIRST_NAME_LC: 'customer_first_name',
  WORD_CUSTOMER_LAST_NAME_LC: 'customer_last_name',
  WORD_ORGANIZATION_NAME_LC: 'organization_name',
  WORD_LOCATION_NAME_LC: 'location_name',
  WORD_TOUCHPOINT_NAME_LC: 'touchpoint',
  WORD_USER_FIRST_NAME_LC: 'user_first_name',
  WORD_USER_LAST_NAME_LC: 'user_last_name',
  WORD_VEHICLE_LC: 'vehicle',
  TEXT_CUSTOMER_ORG_LOC_TOUCH_NAMES:
    'in messages below to insert the customer, location organization name or/and touchpont.',
  ERROR_MESSAGE_PLEASE_INPUT: 'Please input',
  TEXT_UPDATE_ORGANIZATION_DRIP_SEQUENCE_ENABLED:
    'Sequence feature enabled successfully',
  TEXT_UPDATE_ORGANIZATION_DRIP_SEQUENCE_DISABLED:
    'Sequence feature disabled successfully',
  TEXT_SEQUENCE_WAS_DELETED_SUCCESSFULLY: 'Sequence was deleted successfully',
  TEXT_SEQUENCE_WAS_SAVED_SUCCESSFULLY: 'Sequence was saved successfully',
  TEXT_UPDATE_ORGANIZATION_USER_TEMPLATES: 'Update organization User Templates',
  TEXT_UPDATE_ORGANIZATION_USER_SMS_TEMPLATES:
    'Update organization User SMS Templates',
  TEXT_UPDATE_ORGANIZATION_USER_EMAIL_TEMPLATES:
    'Update organization User Email Templates',
  WORD_TOTAL_TEMPLATES: 'Total Templates',
  WORD_ADD_TEMPLATE: 'Add Template',
  WORD_ADD_SMS_TEMPLATE: 'Add SMS Template',
  WORD_ADD_EMAIL_TEMPLATE: 'Add Email Template',
  WORD_NAME_OF_TEMPLATE: 'Name of Template',
  TEXT_TEMPLATE_WAS_DELETED_SUCCESSFULLY: 'Template was deleted successfully',
  TEXT_ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_TEMPLATE:
    'Are you sure you want to delete this Template?',
  WORD_TURN_ON_TOGGLE_WHEN:
    'Turn on the toggle when this specific source has already asked for consent from the customer.',
  WORD_TURN_ON_TOGGLE_IF:
    'Turn on the toggle if you wish all new conversations to be auto forwarded to your CRM.',
  WORD_NO_DATA: 'No Data',
  WORD_TOUCHPOINT_NAME: 'Touchpoint name',
  TEXT_TOUCHPOINT_CHANGED_SUCCESSFULLY: 'Touchpoint changed successfully',
  ERROR_MESSAGE_YOU_DO_NOT_HAVE_ACCESS_TO_ENABLE_THIS_FEATURE:
    'You do not have access to enable/disable this feature.',
  NTF_SUCCESS_DRIP_SEQUENCE_WAS_CHANGED_SUCCESSFULLY:
    'Drip Sequence was Changed successfully',
  TEXT_DRIP_SEQUENCE_WAS_CANCELLED_BY: 'Drip sequence was cancelled by',
  NTF_SUCCESS_AI_BOT_WAS_CHANGED_SUCCESSFULLY:
    'Drip Sequence was Changed successfully',
  NTF_SUCCESS_BROADCASTING_WAS_CHANGED_SUCCESSFULLY:
    'Broadcasting was Changed successfully',
  NTF_SUCCESS_PAYMENT_WAS_CHANGED_SUCCESSFULLY: 'Payments was Changed successfully.',
  WORD_PAYMENTS: 'Payments',
  WORD_PAYMENT_UPF: 'Payment',
  WORD_USERS: 'Users',
  WORD_LOCATIONS: 'Locations',
  WORD_ACCOUNT_SETTINGS: 'Account Settings',
  WORD_MESSAGE: 'Message',
  WORD_SUBJECT: 'Subject',
  WORD_TYPE_HERE: 'Type here...',
  TEXT_YOU_SUCCESSFULLY_DISCONNECTED_YOUR_EMAIL:
    'You successfully disconnected your email.',
  TEXT_EMAIL_DISCONNECT_ERROR: 'Email disconnect error',
  WORD_DISCONNECT_EMAIL: 'Disconnect email',
  TEXT_PLEASE_ENTER_THE_EMAIL_YOU_WANT_TO_CONNECT:
    'Please enter the email you want to connect',
  TEXT_PLEASE_USE_AM_OR_PM_FORMAT: 'Please use AM or PM format',
  TEXT_PLEASE_INPUT_VALID_NUMBER: 'Please input valid number',
  TEXT_YOU_CANNOT_SET_THE_OPENING_HOUR_BEFORE_SEVEN_AM:
    'You cannot set the opening hour before 7 a.m. Please input a value between 7 a.m. and 10 p.m.',
  TEXT_YOU_CANNOT_SET_THE_CLOSING_HOUR_AFTER_TEN_PM:
    'You cannot set the closing hour after 10 p.m. Please input a value between 7 a.m. and 10 p.m.',
  TEXT_PLEASE_ENTER_VALID_MINUTES_FORMAT: 'Please enter valid minutes format',
  WORD_APPOINTMENTS_DATE: 'Appointment dates',
  WORD_CANCELED: 'Canceled',
  WORD_CANCELED_TWO_L: 'Cancelled',
  WORD_BY: 'by',
  TEXT_TYPE_SMS_TEMPLATE: 'Type SMS template',
  TEXT_SMS_TEMPLATE: 'Sms Template',
  TEXT_YOUR_COUPON_SAVED_SUCCESSFULLY: 'Your coupon saved successfully',
  TEXT_YOUR_POPUP_SAVED_SUCCESSFULLY: 'Your popup saved successfully',
  WORD_STORIES: 'Stories',
  WORD_PROMOTIONS: 'Promotions',
  TEXT_TYPE_A_MESSAGE_TO_SMS_TO_THE_CUSTOMER: 'SMS message',
  TEXT_THE_CLIENT_HAS_NOT_GIVEN_SMS_CONSENT:
    'The client refused to receive SMS. Try sending an email',
  TEXT_RESTRICTED_FROM_SENDING_MSG_TO_YELLOW_STATUS:
    "You can't text unconsented customers due to organization restrictions. Try asking for consent or emailing the customer",
  TEXT_SEND_THIS_MESSAGE_LATER: 'Send this message later',
  TEXT_REMIND_ME_TO_FOLLOW_UP: 'Remind me to follow up',
  TEXT_SCHEDULED_MESSAGE_DELETED_SUCCESSFULLY:
    'Scheduled message deleted successfully',
  TEXT_MESSAGE_SCHEDULED_BY: 'Message scheduled by',
  TEXT_EMAIL_SCHEDULED_BY: 'Email scheduled by',
  TEXT_ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_SCHEDULED_MESSAGE:
    'Are you sure you want to delete this scheduled message?',
  TEXT_TYPE_EMAIL_BODY: 'Email message',
  TEXT_FAILED_MESSAGE: 'Failed to send this message. Please try again',
  TEXT_REMINDER_HAS_BEEN_TRIGGERED: 'Please follow up with your client.',
  TEXT_ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_REMINDED_MESSAGE:
    'Are you sure you want to delete this reminded message',
  TEXT_MESSAGE_REMINDED_SUCCESSFULLY: 'Reminder has been created.',
  TEXT_REMINDED_MESSAGE_DELETED_SUCCESSFULLY:
    'Reminded message deleted successfully',
  TEXT_NOTIFICATION_FOR_UNREAD_CONVERSATION: 'Unread conversation',
  TEXT_UNREAD_CONVERSATIONS: 'Unread conversations',
  TEXT_NOTIFICATION_UNREAD_CONVERSATION_ALL_ORG:
    'Notification for unread conversation from all accessible organizations',
  TEXT_PARSE_ALL_EMAILS_ONLY_WHEN_ASSIGN:
    'Parse email only if you are assigned to conversation',
  TEXT_DO_YOU_WANT_TO_RECEIVE_NOTIFICATIONS_WHEN_ASSIGNED_TO_A_CONVERSATION:
    'Do you want to receive notifications when assigned to a conversation?',
  TEXT_RECEIVE_NOTIFICATION_IF_THE_CONVERSATION_IS_UNREAD_FOR:
    'Receive notification if the conversation is unread for',
  WORD_SMS: 'sms',
  WORD_EMAIL_LC: 'email',
  TEXT_SELECTS_AT_LEAST_ONE_FILTER: 'Select at least one filter',
  WORD_PLUS_ADD_IMAGE: '+ Add image',
  TEXT_IN_TEMPLATES_TO_INSERT_THE_CUSTOMER__ORG_INVITE:
    'in templates to insert the customer name, organization name or/and review invite url',
  TEXT_IN_TEMPLATES_TO_INSERT_THE_CUSTOMER__ORG_INVITE_APP_VEHICLE_MAKE:
    'in templates to insert the customer name, organization name, review invite url, appointment date, appointment time, vehicle year, vehicle make and vehicle model',
  WORD_APPOINTMENT_BOOKING: 'Appointment booking',
  TEXT_IF_THE_BROADCAST_MESSAGE_CONTAINS_MORE_THAN_SYMBOLS:
    'if the broadcast message contains more than 160 symbols, it will be sent in several SMS messages to the customers',
  WORD_FILTER: 'Filter',
  WORD_BROADCAST_LIST: 'broadcast list',
  WORD_ASSIGNED_USER: 'Assigned User',
  REMINDER_WAS_SET_TO_FOLLOW_UP_WITH_THIS_CUSTOMER:
    'Reminder was set to follow-up with this customer',
  WORD_SMS_UC: 'SMS',
  WORD_MESSENGER_ID: 'Messenger id',
  TEXT_ARE_YOU_SURE_YOU_WANT_TO_CANCEL_THIS_APPOINTMENT:
    'Are you sure you want to cancel this appointment?',
  TEXT_LOCATION_SELECTED_SUCCESSFULLY: 'Location selected Successfully',
  TEXT_EXCLUDE_CUSTOMERS_WHO_HAVE_BEEN_MESSAGED_IN_THE_LAST:
    'Exclude customers who already received broadcast in the last',
  WORD_TEMPLATE_LC: 'template',
  TEXT_FOR_CREATING_A_BROADCAST: 'for creating a broadcast',
  TEXT_YOU_CAN_USE_THIS: 'You can use this',
  WORD_FOLLOWED_LINK: 'Followed Link',
  WORD_FOLLOWED_TIMES: ' Followed # Times',
  TEXT_THIS_MESSAGE_WILL_NOT_BE_VISIBLE:
    'This message will not be visible in your inbox up until the schedule delivery time',
  TEXT_ARE_YOU_SURE_THIS_APPOINTMENT_IS_FOR: 'Are you sure this appointment is for',
  WORD_PLUS_ADD_NOTE: '+ Add note',
  WORD_CUSTOMER: 'Customer',
  TEXT_TAG_WAS_REMOVED_SUCCESSFULLY: 'Tag was removed successfully',
  TEXT_TAG_SAVED_SUCCESSFULLY: 'Tag saved successfully',
  WORD_REVIEW_INVITE_LINK_LC: 'review invite link',
  WORD_BROADCAST: 'broadcast',
  WORD_BROADCAST_UPPER: 'Broadcast',
  WORD_GROUP: 'Group',
  WORD_SEARCH_USER: 'Search user...',
  WORD_ADD_NEW: 'Add new',
  TEXT_DO_YOU_WANT_TO_RECEIVE_NOTIFICATIONS_ON_UNASSIGNED_CONVERSATIONS:
    'Do you want to receive notifications on unassigned conversations?',
  WORD_PROFILE_NOTIFICATION: 'Profile Notification',
  TEXT_NOTIFICATIONS_FOR_UNASSIGNED_CONVERSATION: 'Unassigned conversation',
  TEXT_UNASSIGNED_CONVERSATIONS: 'Unassigned conversations',
  TEXT_RECEIVE_NOTIFICATION_IF_THE_CONVERSATION_IS_UNASSIGNED_FOR:
    'Receive notification if the conversation is unassigned for',
  TEXT_IMAGE_MUST_SMALLER_THAN: 'Please try to upload the file less than',
  TEXT_YOU_CAN_ONLY_UPLOAD: 'You can only upload',
  WORD_FILE: 'file',
  TEXT_SHOULD_BE_SENT: 'should be sent',
  TEXT_THE_AFTER_HOUR_MESSAGE: 'The after-hour message',
  TEXT_RECEIVING_A_LEAD: 'receiving a lead.',
  TEXT_DELIVERABILITY_SCORE: 'Deliverability Score: ',
  TEXT_MESSAGE_LIKELY_CARRIER_VIOLATION_AND_WILL_HAVE_LOW_DELIVERY_RATE:
    'This Text message is likely a carrier violation and will have a low delivery rate.',
  WORD_SEND_ANYWAY: 'Send Anyway',
  WORD_REVIEW_COPY: 'Review Copy',
  TEXT_CREATE_NEW_DRIP_CAMPAIGN: 'Create new Drip campaign',
  WORD_CAMPAIGN_NAME: 'Campaign name',
  WORD_CHOOSE_TRIGGER: 'Choose Trigger',
  WORD_TYPE_OF_MESSAGE: 'Type of message',
  WORD_DISMISS: 'Dismiss',
  WORD_HOURS: 'hours',
  WORD_MINUTES: 'minutes',
  WORD_SECONDS: 'Seconds',
  WORD_IMMEDIATELY: 'immediately',
  WORD_YEAR: 'year',
  WORD_MAKE: 'make',
  WORD_MODEL: 'model',
  WORD_REVIEW_TITLE: 'Review Invite Email Text Templates',
  WORD_IN_THE_BELOW_TEMPLATES: ' in the below templates.',
  TEXT_CUSTOMER_ORG_LOC_NAMES:
    ' in messages below to insert the customer, location organization name.',
  WORD_LANDING_PAGE_CONTENT: 'Landing page content',
  WORD_APPOINTMENT_DATE_DT: 'appointment_date',
  WORD_APPOINTMENT_TIME_DT: 'appointment_time',
  WORD_LOCATION_NAME_DT: 'location_name',
  TEXT_CONNECT_YOUR_EMAIL_FOR_GETTING_APPOINTMENTS_THERE:
    'Connect your email for getting appointments there',
  TEXT_PROVIDE_AN_OPPORTUNITY_TO_INTERACT_WITH_CUSTOMERS_THROUGH_AN_INSTANT_MESSAGING_SERVICE:
    'Provide an opportunity to interact with customers through an instant messaging service',
  TEXT_LOCATION_WAS_CREATED_SUCCESSFULLY: 'Location was created successfully',
  TEXT_LOCATION_WAS_UPDATED_SUCCESSFULLY: 'Location was updated successfully',
  TEXT_ARE_YOU_SURE_YOU_WANT_TO_DISCONNECT_YOUR_GOOGLE_ACCOUNT:
    'Are you sure you want to disconnect your Google account?',
  TEXT_ARE_YOU_SURE_YOU_WANT_TO_DISCONNECT_YOUR_OUTLOOK_ACCOUNT:
    'Are you sure you want to disconnect your Outlook account?',
  TEXT_CUSTOMER_APP_DATE_TIME_LOC_NAMES:
    'in messages below to insert customer name, appointment date, time and/or location name',
  WORD_API_KEY: 'API key',
  WORD_MATADOR_USER_ID: `Matador's user ID`,
  WORD_CLIENT_ID: 'Client ID',
  LABEL_AUTO_ASSIGN_NEW_TOUCHPOINT:
    'Assign all new created touchpoint to the following sequences:',
  ERROR_SEQUENCES_OF_SAME_TYPE:
    'You’ve already chosen an active sequence. The only other sequence you can chose is after-hours',
  ERROR_AUTO_ASSIGNEMENT_SEQUENCES_LIMIT: "You can't set more than 2 sequences.",
  TEXT_SUCCESS_SEQUENCE_REMOVED:
    'Sequence was unselected. New touchpoints won’t be attached automatically unless you select one again',
  TEXT_SUCCESS_SEQUENCE_ADDED:
    'Sequence was successfully selected. New touchpoints will be automatically attached to it',
  TEXT_SUCCESS_TEXT_ME_BUTTON: 'Text Me Button was updated successfully.',
  TEXT_TEXT_ME_DEFAULT_LANGUAGE_MISSING:
    'Button text or template text for default organization language is missing',
  LABEL_SELECTED_SEQUENCES: 'Selected sequences',
  LABEL_ALL_SEQUENCES: 'All sequences',
  WORD_NONE: 'None',
  WORD_COPY: 'Copy',
  WORD_REMINDED_MESSAGE: 'Reminded message',
  TEXT_FOR_MESSAGING_SID_CONFIRM:
    'This might impact your messaging flow, are you sure you want to move ahead with this change?',
  ERROR_WE_COULDNT_FIND_WHAT_YOURE_LOOKING_FOR:
    'We’re usually a treasure chest of knowledge, but we couldn’t find what you’re looking for.',
  WORD_OH_NO: 'Oh no!',
  TEXT_WRITE_YOUR_MESSAGE: 'Write your message',
  ACTION_CONNECTED_BY: 'Connected by:',
  ACTION_CONNECTION_DATE: 'Connection date:',

  WORD_NOT_LIKELY: 'Not Likely',
  WORD_VERY_LIKELY: 'Very Likely',
  WORD_CSI_SCORE: 'CSI Score',
  WORD_PROMOTERS: 'Promoters',
  WORD_PASSIVE: 'Passive',
  WORD_DETRACTORS: 'Detractors',
  WORD_RESP: 'resp',
  ERROR_MESSAGE_ONLY_IMAGE_ALLOWED: 'Only image allowed',
  LABEL_PHONE_OR_EMAIL: 'Phone or Email',
  TEXT_CONVERSATION_SYNCED_WITH_CRM: 'Conversation synced with CRM',
  ERROR_MESSAGE_IT_IS_IMPOSSIBLE_TO_CHANGE_NUMBER_TO_AUSTRALIAN:
    'It is impossible to change number to Australian',
  ERROR_USE_ONLY_DYNAMIC_TAGS_FROM_THE_LIST_OF_SUGGESTED_ONES:
    'Use only dynamic tags from the list of suggested ones',
  TEXT_DONT_SEND_IF_LAST_MESSAGE_WAS:
    'Dont send if there has been an inbound/outbound message in the last',
  TEXT_VIDEO_DURATION_MUST_SMALLER_THAN:
    'Please try to upload the video less than 1 minute',
  TEXT_PROMOTION_TITLE: 'We want your car!',
  TEXT_PROMOTION_TEXT:
    'Get a free evaluation today. We will always pay more for your vehicle.',
  TEXT_PROMOTION_BUTTON: 'Sell my car',
  TEXT_PROMOTION_TEMPLATE: "Hello I'm interested in selling my car.",
  ERROR_PLEASE_INPUT_THE_ORGANIZATION_NAME: 'Please input the Organization name',
  ERROR_WRONG_PHONE_FORMAT: 'Wrong phone format',
  TOOLTIP_FOR_CHANGE_THE_ORGANIZATION_PLEASE_GO_TO_DASHBOARD_PAGE:
    'For change the organization, please go to dashboard page',
  WORD_LANGUGE: 'Language',
  WORD_SCORE: 'Score',
  WORD_VINSOLUTION_EMAIL: 'VinSolution email',
  ERROR_PLEASE_CHOOSE_TYPE_OF_NOTIFICATION: 'Please, choose type of notification',
  WORD_DEALERSOCKET_USERNAME: 'DealerSocket username',
  WORD_CONNECT_EMAIL: 'Connect Email',
  WORD_PROFILE_EDIT: 'Profile edit',
  ERROR_PLEASE_SELECT_LOCATION_IN_THE_FIELD_WHICH_LOCATION:
    'Please select location in the field "WHICH LOCATION DO THESE USERS BELONG TO?"',
  WORD_AUTO_DETECTED_LANGUAGE_IS: 'Auto detected language is',
  WORD_SENT_CLIENT_VIDEO: 'Sent Client Video',
  WORD_SHOW_VIDEO: 'Show Video',
  WORD_WATCHED_TIMES: ' Watched # Times',
  WORD_INVOICE: 'Invoice',
  WORD_REFERENCE_NUMBER: 'Reference Number',
  WORD_LINK_OPENED: 'Link Opened',
  WARNING_MESSAGE_GOING_BROKEN_UP_SENT_ONE:
    'This text might be sent in multiple messages',
  WORD_TURN_ON: 'Turn on',
  WORD_REMOVE: 'Remove',
  TEXT_AFTER_REMOVING_LOGGED_ACCOUNT_LEADS_MIGHT_NO_LONGER_GO_TO_PLATFORM:
    'After removing logged account leads might no longer go to the platform',
  TEXT_ARE_YOU_SURE_YOU_WANT_TO_REMOVE_EMAIL_FROM_TOUCHPOINTS:
    'ARE YOU SURE YOU WANT TO REMOVE EMAIL FROM TOUCHPOINTS?',
  WORD_TOTAL_INVOICES: 'Total invoices',
  WORD_REJECTED: 'Rejected',
  WORD_NOT_PAID: 'Not Paid',
  WORD_PAID: 'Paid',
  WORD_REFUNDED: 'Refunded',
  WORD_AMOUNT: 'Amount',
  WORD_REFERENCE: 'Reference',
  WORD_NET: 'Net Amount',
  WORD_FEE: "Fee's",
  WORD_REFUND: 'Refund',
  MSG_ARE_YOU_SURE_WANT_TO_REFUND: 'Are you sure want to refund',
  ERROR_YOU_DO_NOT_HAVE_ACCESS_TO_SEND_PAYMENT:
    'You do not have access to send payment. Please, contact your manager.',
  MSG_PAYMENT_REFUND_SUCCESSFULLY: 'Payment successfully refunded',
  WORD_PAYMENT_LIST: 'Payments list',
  WORD_RECEIPT: 'Receipt',
  WORD_INTERESTS: 'Interests',
  MSG_ONLY_IMAGE_OR_PDF_FORMATS_CAN_BE_ADDED:
    'Only Image or PDF formats can be added',
  WORD_PLUS_ADD_IMAGE_OR_PDF: '+ Add Image or PDF',
  WORD_NEW_STATUS: 'New Status',
  ERROR_MSG_MESSAGE_NAME_LENGTH:
    'Message length must be less than or equal to 1400 characters',
  TEXT_SYNCHRONIZED_CRM_INFO: 'Synchronized CRM info',
  WORD_USER_ID: 'User ID',
  WORD_USER_NAME: 'User name',
  WORD_USER_ROLE: 'User role',
  WORD_VEHICLE_OF_INTEREST: 'Vehicle Of Interest',
  ERROR_TEMPLATE_TEXTAREA: 'Please fill in [lang] messages field',
  ERROR_TEMPLATE_TEXTAREA_BODY: 'Please fill in [lang] Template body',
  FINGERPRINTING_AUTOMATION: 'Fingerprinting automation',
  WORD_NOTE: 'Note',
  MSG_NOTE_WAS_CREATED_SUCCESSFULLY: 'Note was created successfully.',
  MSG_YOU_DONT_HAVE_ACCESS_TO_GET_PAYMENTS:
    'You do not have access to get payments. Please, contact your manager',
  THIS_MOUTH: 'This mouth',
  THIS_DAY: 'This day',

  SMS_HEALTH_SCORE: 'SMS health score',
  AUTO_OPTIMIZING: 'Auto-optimizing',
  AVG_INDUSTRY_HEALTHSCORE: 'AVG Industry Healthscore',
  CONVERSATIONS_PER_MONTH: 'Conversations per month',
  AUTOMATIONS: 'Automations',
  ACTIVE: 'Active',
  CONVERSATION_BRAIN: 'Conversation Brain',
  TITLE_MATADOR_CONNECT: 'Matador Connect',
  FINGERPRINTING: 'Fingerprinting',
  ENGAGING_ANONYMOUS_USERS: 'Actively identifying and engaging anonymous users',
  PROFILES_IDENTIFIED: 'Profiles identified',
  EQUIVALENT_OF: 'Equivalent of',
  IN_MARKETING_SPEND: 'In Marketing Spend',
  NEW_PROFILE_ENGAGED: 'New Profile engaged',
  WOO_HOO: 'Woo Hoo',
  CONVERSATION_GENERATOR: 'Conversation Generator',
  NEW_CONVERSATIONS_GENERATED_VIA_INTENT_BASED_WIDGETS:
    'New conversations generated via Intent-Based widgets',
  TEXT_ANSWERED_QUESTIONS: 'Ai-Answered questions',
  WORD_REPUTATION_MANAGEMENT: 'Reputation Management',
  WORD_WOO_HOO: 'Woo-Hoo!',
  WORD_TOTAL_LEADS: 'Total Leads',
  WORD_ENGAGED_CLIENTS: 'Engaged Clients',
  TEXT_AVG_MESSAGE_SENT_PER_USER: 'AVG Message Sent per User',
  WORD_ENGAGEMENT_RATIO: 'Engagement Ratio',
  TEXT_THIS_VIDEO_IS_PROCESSED_BY_THE_SYSTEM:
    'This video is processed by the system.',
  TEXT_WE_WILL_SEND_MESSAGE_AUTOMATICALLY_ONCE_PROCESSING_IS_FINISHED:
    'We will send a message to the customer automatically once the processing is finished',
  MSG_VIDEO_DURATION_MUST_BE_LESS_THAT_3_MINUES:
    'Video Duration must be less that 3 minutes',
  ERROR_EMAIL_CONNECTION_REDIRECT_LINK_ERROR: 'Email connection redirect link error',
  LABEL_PLEASE_ENTER_THE_EMAIL_YOU_WANT_TO_CONNECT:
    'Please enter the email you want to connect',
  WORD_UNKNOWN_ERROR: 'Unknown error',
  TEXT_CUSTOMER_TYPE_CHANGE_TO_NAME: 'Customer [type] changed to [name]',
  TEXT_UPDATE_CUSTOMER_TYPE_CHANGE: 'Update customer [type] to [name]',
  WORD_APPROVED_BY: 'Approved by',
  MSG_VIDEO_PROCESSING_FAILED_IN_ORDER_TO_RETRY_UPLOADING_IT:
    'Video processing failed in order to retry uploading it',
  WORD_SELECT_LOCATION: 'Select Location',
  WORD_SELECT_ORGANIZATION: 'Select organization',
  WORD_LOADING: 'Loading ...',
  TEXT_DRAG_VIDEO_OR_UPLOAD:
    'Drag media file (image or video) in the following box or upload it',
  WORD_EMPTY: 'Empty',
  WORD_DELIVERED: 'Delivered',
  WORD_OPTED_OUT: 'Opted out',
  MSG_ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_BROADCAST_CAMPAIGN:
    'Are you sure you want to delete this broadcast campaign?',
  WORD_QUICK_RESPONSE: 'Quick response',
  WORD_JOIN_A_VIDEO_CALL: ' Join a video call',
  WORD_EXCEL_FILE: 'Excel File',
  WORD_CSV_FILE: 'CSV File',
  WORD_BROADCAST_MESSAGE: 'Broadcast Message',
  TEXT_YOU_ONLY_ABLE_UPLOAD_VIDEO_ONCE_THE_SEQUENCE_HAS_BEEN_SAVED:
    'You will only be able to upload a video or an image once the sequence has been saved',
  WORD_MONTH: 'Month',
  WORD_DAY: 'Day',
  WORD_TRANSACTION: 'Transaction',
  WORD_TOTAL_AMOUNT: 'Total Amount',
  WORD_TOTAL_NET_AMOUNT: 'Total Net Amount',
  WORD_TOTAL_FEES: "Total Fee's",
  TEXT_WE_ALREADY_DISPLAY_STATUS_PAID_OR_REFUND:
    "We already display status paid or refund, so let's implement the same behavior",
  MSG_ARE_YOU_SURE_WANT_TO_CANCEL_PAYMENT:
    'Are you sure want to cancel the payment?',
  TEXT_THE_PAYMNET_CANCELED_SUCCESSFULLY: 'Payment successfully canceled',
  MSG_PAYMENT_SENT_SUCCESSFULLY: 'Payment successfully sent',
  WORD_TITLE: 'Title',
  MSG_ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_STORIES_TYPE:
    'Are you sure you want to delete this [storiesType]?',
  WORD_ADD_NEW_STORY: 'Add New Story',
  WORD_EDIT_STORY: 'Edit Story',
  MSG_STORY_WAS_DELETED_SUCCESSFULLY: 'Story was deleted successfully',
  MSG_STORY_WAS_EDITED_SUCCESSFULLY: 'Story Status Edited Succesfully',
  WORD_THUMBNAIL: 'Thumbnail',
  TEXT_ENTER_STORY_TITLE: 'Enter Story Title',
  TEXT_FOR_OPTIMAL_RESULTS_ENSURE_MEDIA_IS_IN_PORTRAIT_FORMAT:
    'For optimal results, ensure media is in portrait format',
  WORD_IMAGE: 'Image',
  WORD_VIDEO: 'Video',
  ERROR_PLEASE_INPUT_STORY_LANG: 'Please input [language] story title',
  ERROR_THUMBNAIL_URL_IS_REQUIRED: 'Thumbnail url is required',
  ERROR_YOU_NEED_TO_PUT_AT_LEAST_IMAGE_OR_VIDEO_URL:
    'You need to put at least image or video url',
  ERROR_YOU_CANT_UPLOAD_BOTH_VIDEO_AND_IMAGE_REMOVE_ONE_THEM:
    "You can't upload both video and image, remove one of them",
  ERROR_SORRY_YOUR_BROWSER_DOESNT_SUPPORT_EMBEDDED_VIDEOS:
    "Sorry, your browser doesn't support embedded videos.",
  MSG_STORY_ITEM_SAVED_SUCCESSFULLY: 'Story item saved successfully',
  TEXT_YOU_CAN_CREATE_ONLY_4_STORIES: 'You can create only 4 stories',
  MSG_QUESTION_SAVED_SUCCESSFULLY: 'Question saved successfully',
  WORD_CTA_ADD_HTML_CODE:
    'Add HTML code to the place where you want widget to be displayed',
  WORD_CTA_ADD_SCRIPT: 'Add script link to the website',
  WORD_CTA_ADD_STYLE: 'Add style link to the website',
  WORD_CTA_INITIAL_MESSAGE: 'Initial message',
  TEXT_OPEN_EDITOR: 'Open Editor',
  TEXT_OPEN_EDITOR_LIGHT: 'Open Editor Light',
  WORD_AVATAR: 'Avatar',
  WORD_CONSENT_ASKED: 'Consent asked',
  INTEGRATED_CTA: 'Integrated CTA',
  CTA_SAVED_SUCCESSFULLY: 'Integrated CTA saved successfully.',
  WORD_OPTED_IN: 'Opted-In',
  WORD_SELECT_ALL: 'Select All',
  WORD_ARCHIVE_ALL: 'Archive All',
  WORD_MARK_ALL_READ: 'Mark All Read',
  WORD_INTEGRATIONS: 'Integrations',
  TEXT_CRM_SYCHRONIZATION_FLOW: 'CRM Sychronization flow',
  CONVERSATION_ARCHIVED_NOTE:
    'You can’t write a message in the archived folder. Please, unarchive conversation first.',
  WORD_PLEASE_CONTACT_CLIENTS_SUCCESS_TO_ACTIVATE_AUTOMATIONS:
    'You have no automations set up as of yet. Contact your client success agent to start automating your business! We want to save you time and money!',
  WORD_PLEASE_CONTACT_CLIENTS_SUCCESS_TO_ACTIVATE_FINGERPRINTING:
    'Please contact clients success to activate Fingerprinting',
  WORD_MOBILE_CTA: 'Mobile CTA',
  WORD_CTA: 'CTA',
  WORD_POST_PURCHASE: 'Post purchase',
  WORD_DAY_S: 'Day(s)',
  WORD_INTEREST: 'Interest',
  WORD_CONTACT_MADE: 'Contact Made',
  WORD_SALE: 'Sale',
  WORD_SERVICE: 'Service',
  WORD_INQUIRY: 'Inquiry',
  LABEL_SHOW_WIDGET_MOBILE: 'Show widget on mobile',
  WORD_INDUSTRY_AVERAGE_ENGAGEMENT: 'Industry Average Engagement',
  TEXT_THIS_PHONE_NUMBER_IS_LANDLINE_AND_CURRENTLY_NOT_ACCEPTING_SMS_MESSAGES:
    'The destination number is unable to receive this message. Potential reasons could include trying to reach a landline.',
  WORD_COMING_SOON: 'Coming Soon',
  WORD_RESET_PASSWORD: 'Reset Password',
  WORD_FORGOT_PASSWORD: 'Forgot Password',
  TEXT_ENTER_YOUR_EMAIL_OR_PHONE_BELOW_AND_WE_WILL_SEND_YOU_PASSWORD_RESET_INSTRUCTIONS:
    'Enter your email or phone below and we will send you password reset instructions',
  WORD_CONFIRMATION: 'Confirmation',
  TEXT_MESSAGE_HAS_BEEN_SENT_PLEASE_CHECK_YOUR_INBOX:
    ' Message has been sent. Please check your inbox',
  WORD_NEW_PASSWORD: 'New Password',
  WORD_CONFIRM_PASSWORD: 'Confirm Password',
  TEXT_ARE_YOU_SURE_YOU_WANT_TO_CANCEL_THIS_INVITATION:
    'Are you sure you want to cancel this Invitation?',
  WORD_SENT_EMAIL: 'Sent Email',
  WORD_UNIQUE_SENT: 'Unique Sent (All)',
  WORD_RECOMMENDED_YES: 'Recommended (Yes)',
  WORD_CLICKED_TO_REVIEW: 'Clicked To Review',
  WORD_SUCCESS: 'Success',
  TEXT_LOCATION_IS_ACTIVE: 'Location is active',
  TEXT_LOCATION_DISABLING_WARNING:
    'Turning off a location will make changing the location of messages which are assigned to and disable all Managers and Support Agents who belong to it. Are you sure you want to move ahead?',
  WORD_PURCHASE_DATE: 'Purchase Date',
  WORD_LAST_R_O: 'Last R.O',
  WORD_NEXT_APPOINTMENT: 'Next Appointment',
  WORD_ADD_NEW_TEMPLATES: 'Add New Templates',
  TEXT_SEQUENCE_TEMPLATE_WAS_SAVED_SUCCESSFULLY:
    'Sequence Template was saved successfully',
  TEXT_LOCATIONS_SEARCH: 'Search Locations...',
  TEXT_VIDEO_SENT: 'Videos sent',
  TEXT_VIDEO_WATCHED: 'Videos watched',
  TEXT_HOURS_WATCHED: 'Hours watched',
  WORD_SUMMARY: 'Summary',
  WORD_SALES_TODAY: 'Sales Today',
  WORD_SALES_MONTH_TO_DATE: 'Sales Month To Date',
  WORD_SALES_LAST_MONTH: 'Sales Last Month',
  WORD_DEPOSITS_YESTERDAY: 'Deposits Yesterday',
  WORD_DEPOSITS: 'Deposits',
  WORD_REPORTS: 'Reports',
  WORD_CARD: 'Card',
  WORD_TIMELINE: 'Timeline',
  WORD_SCHEDULED_DEPOSITS: 'Scheduled Deposits',
  WORD_COMPLETED_DEPOSITS: 'Completed Deposits',
  WORD_DEPOSIT_DETAILS: 'Deposit Details',
  WORD_EXPORT: 'Export',
  WORD_DEPOSIT_ON: 'Deposit on',
  WORD_DEPOSIT_TO: 'Deposit to',
  WORD_ATB_FINANCIAL: 'ATB Financial',
  WORD_DEPOSIT_SUMMARY: 'Deposit Summary',
  WORD_COUNT: 'Count',
  WORD_GROSS: 'Gross',
  WORD_TOTAL: 'Total',
  WORD_DEPOSIT_BREAKDOWN: 'Deposit Breakdown',
  WORD_DATE_CREATED: 'Date Created',
  WORD_DATE_UPDATED: 'Date Updated',
  WORD_UPDATED_BY: 'Updated by',
  WORD_UPDATED_AT: 'Updated at',
  WORD_DOWNLOAD: 'Download',
  TEXT_INBOUND_CALLS: 'Inbound calls',
  TEXT_YOU_CANT_WRITE_MESSAGE_IN_THE_ARCHIVED_FOLDER:
    "You can't write a message in the archived folder. Please, unarchive conversation first.",
  WORD_VIDEO_STATS: 'Video Stats',
  TEXT_INCOMING_CALLS: 'Incoming Calls',
  WORD_SEARCH_ORGANIZATIONS: 'Search Organizations...',
  WORD_CONVERSATION_NOT_FOUND: 'Conversation not found!',
  WORD_HIGH_RISK: 'High Risk',
  WORD_PHONE_CALL: 'Phone Call',
  WORD_VIDEO_CALL: 'Video Call',
  TEXT_DOWNLOAD_USER_XLS: 'Download user XLS',
  WORD_EDIT_USER: 'Edit User',
  WORD_MANAGE_USERS: 'Manage Users',
  WORD_LOGINS: 'Logins',
  WORD_MAGIC_SMS: 'Magic SMS',
  WORD_MAGIC_EMAIL: 'Magic Email',
  NTF_USER_WAS_CREATED_SUCCESSFULLY: 'User was created successfully',
  NTF_USER_WAS_SAVED_SUCCESSFULLY: 'User was saved successfully',
  TEXT_GOOGLE_BUSINESS_MESSAGES: 'Google Business Messages',
  TEXT_CONNECT_GOOGLE_MY_BUSINESS: 'Connect Google My Business',
  TEXT_DISCONNECT_GOOGLE_MY_BUSINESS: 'Disconnect Google My Business',
  TEXT_GOOGLE_MY_BUSINESS_LIST: 'Google My Business List',
  WORD_SUBMIT: 'Submit',
  TEXT_LOCATION_WAS_UPDATED_SUCCESSFULLY_PLEASE_NOTE:
    'Location was updated successfully. \n Please note, GBM settings changes could take a few hours to update',
  TEXT_BUSINESS_DISCONNECTED_SUCCESSFULLY:
    'Google My Business disconnected successfully',
  MSG_THIS_ACTION_IS_NOT_SUPPORTED_FOR_GOOGLE_BUSINESS_CONVERSATIONS_JUST_YET:
    'This action is not supported for Google business conversations just yet',
  ARE_YOU_SURE_YOU_WANT_TO_CANCEL_CONNECT_GOOGLE_MY_BUSINESS:
    'Are you sure you would like to cancel connect Google My Business process?',
  WORD_AND: 'And',
  WORD_ADD_MORE_CONDITIONS: 'Add more conditions',
  WORD_HIDE_MORE_CONDITIONS: 'Hide more conditions',
  POWERED_BY_AI_TEXT_TEMPERATURE:
    'Powered by AI, our model analyzes client interactions and historical conversion rates to assign a score from very cold to very hot. Use it to prioritize outreach and close deals with the most promising leads.',
  WORD_FRIENDLY_LOCATION_NAME: 'Friendly Location Name',
  LABEL_SHOW_ANSWER_CHAT: 'Show this answer within the chat',
  WORD_PUSH_NOTIFICATIONS: 'Push notifications',
  WORD_ADD_NEW_WORKFLOW: 'Add New Workflow',
  WORD_CHOSE_YOUR_DIFFERENT_TRIGGERS: 'Choose your different trigger',
  WORD_BUILD_YOUR_WORKFLOW: 'Build Your Workflow',
  WORD_BUILD_A_WORKFLOW_BASED_ON_TYPE_OF_RESPONSE_YOU_RECEIVE_FROM_YOUR_BROADCAST_CAMPAIGN:
    'Build a workflow based on type of response you receive from your broadcast campaign',
  WORD_ADD_NAME_FOR_YOUR_WORKFLOW: 'Add Name For Your Workflow',
  WORD_WORKFLOW_NAME: 'Workflow name',
  WORD_DETECT_INTENT: 'Detect intent',
  WORD_WAIT_FOR_REPLY: 'Wait for reply',
  WORD_POSITIVE_REPLY: 'Positive',
  WORD_NEGATIVE_REPLY: 'Negative',
  WORD_ANY_REPLY: 'Any reply',
  WORD_NO_REPLY: 'No reply',
  WORD_ASSIGN_USER: 'Assign User',
  WORD_ADD_A_TAG: 'Add a tag',
  WORD_CREATE_A_NOTE: 'Create a note',
  WORD_TIMES_TRIGGERED: 'Times Triggered',
  TEXT_ARCHIVE_CONVERSATION_YOU_CAN_FIND_ALL_ARCHIVED_EMAILS_HERE:
    "Archive conversation within Matador's platform",
  WORD_YOUR_NOTE: 'Your note',
  WORD_YOUR_SMS: 'Your SMS',
  WORD_PEOPLE_ASSIGNED: 'People Assigned',
  WORD_SELECT_PEOPLE: 'Select People',
  WORD_TAGS_SELECTED: 'Tags Selected',
  WORD_SELECT_TAGS: 'Select Tags',
  WORD_TAG: 'Tag',
  WORD_PAYMENT_NOTIFICATION: 'Payment Notification',
  TEXT_WORKFLOW_WAS_SAVED_SUCCESSFULLY: 'Workflow was saved successfully',
  TEXT_WORKFLOW_WAS_DELETED_SUCCESSFULLY: 'Workflow was deleted successfully',
  TEXT_ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_WORKFLOW:
    'Are you sure you want to delete this Workflow?',
  ERROR_MSG_WORKFLOW: 'Please select at least one intent',
  TEXT_RECEIVER_EMAIL_SWITCH: 'Notify when integration has been disconnected',
  WORD_BUILD_NEW_WORKFLOW: 'Build new Workflow',
  WORD_CHOOSE_EXISTING_WORKFLOW: 'Choose existing Workflow',
  TEXT_NAME_OF_WORKFLOW: 'Name of workflow',
  ERR_MSG_ASSIGN_WORKFLOW: 'Please assign at least 1 user',
  ERR_MSG_TAG_WORKFLOW: 'Please choose at least 1 tag',
  ERR_MSG_NO_REPLY_INTENT: 'Please choose action for “No reply” intent',
  ERR_MSG_INPUT_NUMBER: 'Minimum number must be 1',
  PH_SEND_TO: 'Send to...',
  ERR_MSG_INPUT_NUMBER_MAX: 'Maximum number must be 1',
  TEXT_TEMPLATE_WAS_SAVED_SUCCESSFULLY: 'Template was saved successfully',
  WORD_PUSH_TO_CRM: 'Push to CRM',
  TEXT_CREATE_OPPORTUNITY_IN_CRM: 'Create opportunity in CRM',
  WORD_BUILD_YOUR_OWN: 'Build Your Own',
  WORD_AI_GENERATED: 'AI Generated',
  TEXT_GENERATE_MOST_POWERFUL_CAMPAIGN_WITH_AI:
    'Generate the most powerful campaign with AI',
  TEXT_CHOOSE_DYNAMIC_CONTENT_TO_BE_APPLIED_TEXT:
    'Choose the dynamic content to be applied to the text:',
  WORD_GENERATE: ' Generate',
  TEXT_PLEASE_GENERATE_AI_CONTENT:
    'Please fill out the message field with at least 10 characters description',
  WORD_REGENERATE: ' Regenerate',
  TEXT_WRITE_YOUR_IDEA: ' Write your idea...',
  WORD_SKIP: 'Skip',
  WORD_AI_REPLY: 'AI Reply',
  WORD_AI: 'A.I',
  TEXT_THERE_IS_NO_GENERATED_MESSAGE: 'There is no generated message',
  TEXT_AI_MESSAGE_GENERATION_IS_NOT_AVAILABLE:
    'AI message generation is not available',
  TEXT_EMAIL_IS_NOT_VALID: 'Email is not valid',
  TEXT_CLICK_HERE_TO_UPLOAD_YOUR_CSV_EXCEL_FILE:
    'Click here to upload your CSV or Excel file',
  TEXT_EDIT_BROADCAST_MESSAGE: 'Edit broadcast message',
  TEXT_YOU_CAN_EDIT_CAMPAIGN_ONLY_MINUTES_BEFORE_START:
    'You can edit campaign only 5 minutes before start',
  WORD_CONVERSATION_AI: 'Conversation A.I',
  TEXT_UPDATE_ORGANIZATION_CONVERSATION_AI: 'Update Superhuman configuration',
  LABEL_MONTHLY_SPECIALS: 'Monthly Specials',
  LABEL_PROVIDE_OUR_AI_AN_OVERVIEW_OF_THE_PROMOTIONS_YOUR_ORGANIZATION_IS_RUNNING_THIS_MONTH:
    'Provide our A.I an overview of the promotions your organization is running this month',
  LABEL_EXCLUDE_THIS_INTENT_FROM_BEING_AUTOREPLIED:
    'Exclude this intent from being autoreplied',
  LABEL_SELECT_INTENTS: 'Select Intents',
  STORY_THUMBNAIL_NOTE:
    "This field is used to upload a small image that represents your story. It's recommended to use an image that is relevant to your story, and in the formats of jpeg, jpg, png or gif. Additionally, please ensure that the image is optimised/compressed before uploading to ensure fast loading times for website visitors.",
  STORY_IMAGE_NOTE:
    "This field is used to upload the actual image that will be displayed when someone opens your story. Supported formats: jpeg, jpg, png or gif. Please keep in mind that it's strongly recommended for the image and video to be in portrait mode for optimal display. Additionally, please ensure that the image is optimised/compressed before uploading to ensure fast loading times for website visitors.",
  STORY_VIDEO_NOTE:
    "This field is used to upload a video that will be displayed when someone opens your story. Supported formats: mp4, mov, avi, webm or quicktime. Please keep in mind that the file size limit for video is 20 MB and the video length limit is 3 minutes. It's strongly recommended for the video to be in portrait mode for optimal display. Additionally, please ensure that the video is optimised/compressed before uploading to ensure fast loading times for website visitors.",
  TEXT_APPOINTMENTS_EMAIL_WAS_SUCCESSFULLY_CONNECTED:
    'Appointments email was successfully connected',
  TEXT_APPOINTMENTS_EMAIL_WAS_SUCCESSFULLY_DISCONNECTED:
    'Appointments email was successfully disconnected',
  TEXT_TOUCHPOINTS_EMAIL_WAS_SUCCESSFULLY_CONNECTED:
    'Touchpoints email was successfully connected',
  TEXT_TOUCHPOINTS_EMAIL_WAS_SUCCESSFULLY_DISCONNECTED:
    'Touchpoints email was successfully disconnected',
  TEXT_RECEIVE_EMAILS_FROM_UNHAPPY_CUSTOMERS:
    'Receive emails from "unhappy customers"',
  TEXT_RECEIVE_REPORTS_BY_EMAIL: 'Receive newsletter emails',
  TEXT_RECEIVE_REPORTS_DESCRIPTION: `
  A regularly distributed post that provides statistics on key important features such as Assisted Appointments, Superhuman Impact, Video Submissions, Widget Engagement, and Top Leads Stats.
  Watch for your email:<br />
  Weekly newsletter: Sent on Mondays<br />
  Monthly newsletter: Sent on the first days of the following month`,
  TEXT_EMAILS_FROM_UNHAPPY_CUSTOMERS_CAN_ONLY_BE_RECEIVED_BY_EMAIL:
    'Emails from "unhappy customers" can only be received by email',
  NTF_SUCCESS_BROADCAST_WAS_CHANGED_SUCCESSFULLY:
    'broadcast was changed successfully',
  WORD_CRM: 'CRM',
  NTF_YOU_CANT_EDIT_CANCELED_CAMPAIGN: "You can't edit canceled campaign",
  WORD_VERIFICATION: 'Verification',
  TEXT_RECEIVE_VERIFICATION_CODE_VIA: 'Receive verification code via',
  WORD_VERIFICATION_CODE: 'Verification Code',
  TEXT_VERIFICATION_CODE_FOR_PHONE:
    'Your verification code is sent to [phone] phone number',
  TEXT_VERIFICATION_CODE_FOR_EMAIL:
    'Your verification code is sent to [email] email',
  WORD_RESEND_CODE: 'Resend code?',
  TEXT_DURING_INTEGRATION_SETUP_NO_FB_PAGES_WERE_SELECTED:
    'During integration setup, no FB pages were selected',
  TEXT_SELECT_FACEBOOK_PAGE: 'Select Facebook page to finish integration setup',
  TEXT_SELECT_ACCOUNT_TYPE: 'Select account type',
  TEXT_SELECT_LEADS: 'Select leads (optional)',
  UPDATE_CUSTOMERS_DATA_BROADCAST_PAGE:
    "Update customer's data (first name, last name, email) in Matador based on data from the uploaded file.",
  WORD_LEAD_GEN_WIZARD: 'Lead Gen Wizard',
  WORD_BEFORE_APPOINTMENT: 'before appointment',
  TEXT_DOWNLOAD_DAILY_TOTAL_TRANSACTION_REPORT:
    'Download Daily Total transaction report',
  WORD_CUSTOM: 'Custom',
  WORD_BUILD_YOUR_OWN_BROADCAST: 'BYOB = Build your own broadcast. 😍',
  WORD_INSTANTLY_CREATE_YOUR_CAMPAIGN_WITH_AI:
    'Instantly create your campaign with AI. 🤯',
  WORD_THIS_TAG_IS_AUTOMATICALLY_SELECTED_ITS_BEST_PRACTICE_IDENTIFY_YOURSELF:
    'Choose the dynamic content to be applied to the text:',
  WORD_REFRESH: 'Refresh',
  WORD_REVIEW_RATING_PAGE_TITLE: 'Review Invite Rating page',
  WORD_REVIEW_POSITIVE_FEEDBACK_PAGE_TITLE: 'Review Invite Positive feedback page',
  WORD_REVIEW_POSITIVE_NEGATIVE_PAGE_TITLE: 'Review Invite Negative feedback page',
  ACTION_OPEN_IN_CRM: 'Open in CRM',
  WORD_CAN_DISABLE_SUPER_ADMINS: 'Disabled',
  TEXT_WITH_CALENDAR_INVITE: 'with calendar invite',
  TEXT_MESSAGES_SENT_VIA_SUPER_HUMAN: 'Messages sent via Superhuman',
  TEXT_MATADOR_ASSISTED_APPOINTMENTS: 'Total Matador Assisted Appointments',
  TEXT_MATADOR_IMPACT: 'Matador Impact',
  TEXT_HOW_MATADOR_IS_IMPACT_IN_YOUR_BUSINESS:
    'Automation & A.I R.O.I for your business',
  TEXT_PLEASE_CONTACT_CLEINTS_SUCCESS_TO_ACTIVATE_SUPERHUMAN:
    'Please contact clients success to activate Superhuman',
  TEXT_INVALID_TEMPORARY_VERIFICATION_TOKEN_PROVIDED:
    'Invalid temporary verification token provided',
  SUBSCRIBER_GROWTH: 'Subscriber Growth',
  WORD_PRIMARY_COLOR: 'Primary Color',
  WORD_VARIANT: 'Variant',
  SUBSCRIBER_GROWTH_BUTTON: 'Subscriber Growth is updated successfully.',
  WORD_CREATED: 'Created',
  WORD_UPDATED: 'Updated',
  HOW_WOULD_YOU_LIKE_TO_SEND_THIS_MESSAGE:
    'How would you like to send this message?',
  ACTION_DISCONNECTED_BY: 'Disconnected by:',
  TEXT_ALLOW_SUPERHUMAN_AI_TO_RESPOND_WITHIN_ORGANIZATIONS_WORKING_HOURS:
    "Allow Superhuman A.I to respond within the organization's working hours",
  TEXT_ALLOW_SUPERHUMAN_AI_TO_RESPOND_OUTSIDE_ORGANIZATIONS_WORKING_HOURS:
    "Allow Superhuman A.I to respond outside the organization's working hours",
  TEXT_SUPERHUMAN_ASSISTED_APPOINTMENTS: 'Assisted Appointments',
  WORD_JOIN_A_CALL: 'Join a call',
  TEXT_UPDATE_ORGANIZATION_OPENING_HOURS: 'Update organization opening hours',
  TEXT_DO_NOT_SEND_REMINDER_IF_APPOINTMENT_IS_CREATED_ON_SAME_DAY:
    'Do not send the reminder if the appointment is created on the same day',
  TEXT_SUPERCHARGE_YOUR_WORKFLOW_AND_CONNECT_TOOLS_YOU_AND_YOUR_TEAM_USES_EVERY_DAY:
    'Supercharge your workflow and connect the tools you and your team uses every day.',
  WORD_CONNECT_TO_CRM: 'Connect to CRM',
  WORD_CONNECTED_EMAIL: 'Connected email',
  WORD_CONNECTED_CRM: 'Connected CRM',
  TEXT_TRIGGERS_WHEN_YOU_RECEIVE_NEW_LEAD: 'Triggers when you receive a new lead.',
  TEXT_INTEGRATES_YOUR_DATA_ENHANCING_YOUR_ABILITY_MANAGE_CUSTOMER_INTERACTIONS_RELATIONSHIPS_EFFECTIVELY:
    'Integrates your data and enhances your ability to manage customer interactions and relationships effectively.',
  TEXT_GIVES_POSSIBILITY_HAVE_CONVERSATIONS_WITH_CUSTOMERS_BY_EMAIL:
    'Gives possibility to have conversations with customers by EMAIL.',
  TEXT_MERGES_BOTH_PLATFORMS_INTO_UNIFIED_ONLINE_PRESENCE:
    'Merges both platforms into a unified online presence.',
  TEXT_STREAMLINES_MESSAGE_MANAGEMENT_ACROSS_PLATFORMS:
    'Streamlines Message Management Across Platforms.',
  WORD_PRESS_SENT_BUTTON: 'Use Send button to send',
  WORD_PRESS_ENTER: 'Use Enter button to send',
  DISPLAY_BACKGROUND: 'Display background',
  TEXT_MINUTES_WATCHED: 'Minutes watched',
  MOBILE_DIMENSIONS: 'Mobile Dimensions',
  WORD_FLOW_DESIGNER: 'Custom Connect',
  TEXT_AUTOMATICALLY_USED_BY_AI: 'Automatically used by AI:',
  TEXT_PLEASE_CONTACT_YOUR_ADMINISTRATOR: 'Please contact your Administrator.',
  TEXT_DO_NOT_DISTURB_ME_DURING_NON_WORKING_HOURS:
    'Do not disturb me during non-working hours',
  TEXT_PLEASE_FINISH_INTEGRATION_SETUP: 'Please finish integration setup',
  TEXT_YOU_CANT_TEXT_CUSTOMERS_WHILE_AWAITING_CONSENT:
    'You can’t text customers while awaiting consent.',
  WORD_COMPLIANCE: 'Compliance',
  WORD_IG_MESSENGER: 'IG Messenger',
  WORD_FB_MESSENGER: 'FB Messenger',
  WORD_GOOGLE_BUSINESS_UC: 'Google Business',
  TEXT_WE_ARE_UNSURE_IF_THIS_CUSTOMER_IS_COMPLIANT:
    'We are unsure if this customer is compliant',
  TEXT_THE_CUSTOMER_UNSUBSCRIBED_FROM_EMAIL_CONVERSATION:
    'The customer unsubscribed from Email conversation',
  WORD_REFUSED_AT: 'Refused at',
  TEXT_FORWARD_ALL_LEADS_TO_EMAIL: 'Share a copy of the leads with another service',
  AI_BOT_SUPERHUMAN: 'Superhuman',
  TEXT_AI_BOT_SUPERHUMAN: 'Superhuman AI',
  TEXT_THE_CLIENT_HAS_NOT_GIVEN_EMAIL_CONSENT:
    'This customer unsubscribed from Emails. Please try another communication channel',
  WORD_CONNECTED_PAGE_FB: 'Connected Facebook page',
  WORD_CONNECTED_PAGE_IG: 'Connected Instagram page',
  TEXT_TEXT_TEMPLATE_IS_REQUIRED_FOR_BOTH_LANGUAGES:
    'Text Template is required for both languages',
  WORD_WEEK_TO_DAY: 'Week To Day',
  WORD_WEEK: 'Week',
  WORD_SELECT_DATE_PERIOD: 'Select Date Period',
  restrictAppointmentsOutsideWorkingHours:
    'Restrict scheduling calendar invite for after hours',
  WORD_ARE_YOU_SURE_YOU_WANT_TO_DO_ACTION_CONVERSATION:
    'Are you sure you want to [action]',
  WORD_ARE_YOU_SURE_YOU_WANT_TO_MARK_US_READ_CONVERSATION:
    'Are you sure you want to mark as read',
  WORD_SETTINGS_TRANSFER: 'Settings Transfer',
  WORD_PLEASE_SELECT_DIFFERENT_ORGANIZATIONS:
    'Please select different organizations',
  WORD_SETTINGS_TRANSFERED_SUCCESSFULLY: 'Settings transfered successfully',
  CONFIRMATION_COPY_ORG_SETTINGS_TITLE:
    'Are you sure you want to copy Drip Sequence from [fromOrg]',
  CONFIRMATION_COPY_ORG_SETTINGS_DESCRIPTION:
    'All existing settings of Drip Sequence in [toOrg] will be erased and updated.',
  WORD_ORGANIZATION_RESTRICTION_SETTINGS: 'Organization Restriction Settings',
  WORD_SELECT_SOURCE_ORGANIZATION_COPY_FROM:
    'Select source organization you want to copy from',
  WORD_SELECT_DESTINATION_ORGANIZATION_COPY_TO:
    'Select destination organization you want to copy to',
  WORD_SELECT_SETTINGS_TO_COPY: 'Select settings you want to copy',
  WORD_SELECT_SETTING: 'Select setting',
  WORD_PLEASE_SELECT_SETTING: 'Please select setting',
  WORD_CONVERSATIONS_QUESTION: 'conversation',
  WORD_DOWNLOAD_IN_APPSTORE: 'Download in App Store',
  WORD_DOWNLOAD_IN_GOOGLE_CHROME: 'Download extension for browser',
  WORD_DOWNLOAD_IN_GOOGLE_PLAY: 'Download in Google Play',
  WORD_THE_ONLY_MARKETING_AUTOMATION_PLATFORM:
    'The Only Conversational Marketing Automation Platform For Dealerships',
  WORD_AI_POWERED_CONVERSATIONAL_MARKETING_AUTOMATION:
    'Revolutionise Your Dealership’s Sales Strategy, Customer Experience and Efficiency with AI-Powered Conversational Marketing Automation.',
  WORD_PLEASE_ENTER_YOUR_PHONE_OR_EMAIL: 'Please enter your phone or email',
  WORD_PLEASE_ENTER_YOUR_PASSWORD: 'Please enter your password',
  WORD_PLEASE_ENTER_YOUR_EMAIL: 'Please enter your email',
  WORD_SUPERHUMAN_MESSAGES: 'Superhuman messages',
  restrictDisableAIBotManagers:
    'Restrict disabling Drip Sequence on conversation level for Managers',
  restrictDisableAIBotSupportAgents:
    'Restrict disabling Drip Sequence on conversation level for Support Agents',
  allowParseLeadsWithoutPhone: 'Allow to parse leads without phone number',
  WORD_CREATE_REPORT: 'Create Report',
  WORD_REPORT_NAME: 'Report Name',
  TEXT_TOUCHPOINTS_SEARCH: 'Search Touchpoints...',
  TEXT_LAST_LOGIN_DATE: 'Last login',
  WORD_PERSONAL_INFORMATION: 'Personal Information',
  WORD_ROLE_ACCESS: 'Role/Access',
  WORD_TYPE_A_MESSAGE_TO_SEND_TO_CUSTOMER: 'Type a message to send to the customer',
  MSG_PLEASE_INPUT_PHONE_OR_EMAIL: 'Please input phone or email',
  TEXT_CONNECT_GMAIL_OR_OFFICE_365: 'Connect Gmail or Office 365',
  TEXT_LINK_YOUR_WHATSAPP_BUSINESS_ACCOUNT: 'Link your WhatsApp Business account',
  WORD_REMINDERS: 'Reminders',
  MSG_ENTERED_PHONE_EMAIL_IS_NOT_VALID: 'Entered phone/email is not valid',
  MSG_BROADCAST_MESSAGE_LENGTH_MUST_BE_LESS_THAN_OR_EQUAL_TO_CHARACTERS:
    'broadcast message length must be less than or equal to 1400 characters',
  TEXT_ORGANIZATION_OPENING_HOURS: 'Organization opening hours',
  TEXT_CUSTOM_OPENING_HOURS: 'Custom opening hours',
  MSG_DELETE_CUSTOM_OPENING_HOURS:
    'Are you sure want to delete the custom opening hours?',
  TEXT_CALLS_FORWARDED_TO: 'Forward calls to',
  MSG_OPENING_HOURS_ERROR: 'Please follow format H:MM AM|PM (9:30 AM)',
  WORD_IP_WHITELIST: 'IP Whitelist',
  TEXT_ONLY_SPECIFIC_IP_ADDRESSES_GET_ACCESS_WORKING_THIS_ORGANIZATION_MATADOR:
    'Only devices using these whitelisted IP addresses will be able to access Matador',
  TEXT_PLEASE_INSERT_WHITELIST_IP_ADDRESS: 'Please insert whitelist IP address',
  MSG_DELETE_IP_WHITE_LIST: 'Are you sure want to delete the ip whitelist item?',
  MSG_PLEASE_INSERT_WHITELIST_IP_ADDRESS: 'Please insert whitelist IP address',
  MSG_THE_NUMBER_OF_IP_WHITELIST_CANNOT_BE_MORE_THAN:
    'The number of IP Whitelist cannot be more than 10',
  WORD_LOAD_MORE: 'Load More',
  TEXT_TAGGED_IN_A_CONVERSATION: 'Tagged in a conversation',
  WORD_ADD_NEW_IP: 'Add New IP',
  WORD_CURRENT_LOCATION: 'Current Location',
  WORD_FILTERS: 'Filters',
  WORD_IN_PROGRESS: 'In Progress',
  WORD_EXECUTION_DATE_AND_TIME: 'Execution date and time',
  WORD_WORKFLOW_REPLY: 'Workflow Reply',
  WORD_WORKFLOW_RESPONSE: 'Workflow Response',
  WORD_WORKFLOW_AUTOMATION: 'Automation Workflow',
  WORD_UPLOADED_FILE: 'Uploaded File',
  WORD_SHOW_DETAILS: 'Show Details',
  WORD_HIDE_DETAILS: 'Hide Details',
  TEXT_USE_QUESTION_AS_TOUCHPOINT: 'Use question as touchpoint',
  TEXT_FILTER_BY_STATUS: 'Filter by status',
  WORD_ALL_CAMPAIGNS: 'All campaigns',
  WORD_CREATED_BY_AUTOMATION: 'Created by Automation',
  WORD_CREATED_BY_USER: 'Created by User',
  TITLE_BROADCASTING_LIST: 'Broadcasting List',
  TEXT_DISCONNECTED_DUE_TO_TOKEN_EXPIRATION_OR_PASSWORD_CHANGE:
    'Disconnected due to token expiration or password change',
  TEXT_YOU_DO_NOT_HAVE_ACCESS_TO_THIS_FEATURE_BROADCAST:
    'You do not have access to this feature. Please contact your manager',
  TEXT_CAMPAIGN_WAS_CANCELED_SUCCESSFULLY: 'Campaign was canceled successfully',
  WORD_AFTER_HOURS: 'After Hours ',
  WORD_AGENT_NAME: 'Agent Name',
  ERRORS_INPUT_CONVERSATIONAI: '[name] can’t be empty',
  TEXT_IF_THE_INVENTORY_INTEGRATIONS_IS_ENABLED:
    'If the inventory integrations is enabled?',
  WORD_AGENT_ROLE: 'Agent Role',
  AGENT_ROLE_CANT_BE_EMPTY: `Agent Role can’t be empty`,
  WORD_COMPANY_TYPE: 'Company Type',
  TEXT_GENERAL_TAGGING_CONFIGURATION: 'General Tagging Configuration',
  TEXT_ALLOW_TAGGING_USERS_FOR_EVERY_SUPERHUMAN_MESSAGE:
    'Allow tagging users for every Superhuman message',
  TEXT_INTENT_CONFIGURATION: 'Intent Configuration',
  TEXT_PROVIDE_INSTRUCTIONS_CLARIFY_HOW_YOU_WANT_RESPONSES_INTENT_GENERATED:
    'Provide instructions to clarify how you want responses for this intent to be generated',
  WORD_GENERAL_INSTRUCTIONS: 'General Instructions',
  WORD_INSTRUCTIONS_TO_HUMAN_EXPERT: 'Instructions to human expert',
  WORD_CURRENT_PROMOTIONS: 'Current Promotions',
  WORD_TEXT: 'Text',
  TEXT_WHEN_TAG_USERS: 'When to tag users',
  TEXT_PLEASE_CHOOSE_AT_LEAST_ONE_USER: 'Please choose at least one user',
  MSG_REMOVE_INTENT_ITEM: 'Are you sure you want to remove this item?',
  TEXT_ADD_AT_LEAST_ONE_USER_WHO_WILL_TAGGING:
    'Add at least one user who will be tagging ',
  WORD_FOR_ASSIGNED_CONVERSATION: 'For assigned conversations',
  TEXT_TAG_ONLY_ASSIGNED_USERS: 'Tag only assigned users',
  TEXT_TAG_BOTH: 'Tag both',
  TEXT_TAG_ONLY_USERS_FROM_LIST: 'Tag only users from the list',
  TEXT_UPDATE_ORGANIZATION_SUPERHUMAN:
    'Update organization Superhuman [case] successfully',
  WORD_FILTERED_BY: 'Filtered By',
  WORD_BROADCAST_NAME: 'Broadcast Name',
  restrictUserVoiceCallsFromUsingOrganizationNumbers:
    'Restrict user voice calls from using organization numbers',
  LABEL_SELECT_THE_USERS_TO_TAG: 'Select the users to tag',
  LABEL_CAR_DEALERSHIP: 'car dealership',
  LABEL_SOLAR_PANEL_COMPANY: 'solar panel company',
  ERR_MSG_PLEASE_PROVIDE_DELAY: 'Please provide a delay',
  restrictEditSuperHumanForManagers:
    'Restrict switching Superhuman toggle on the conversation level for Managers',
  restrictEditSuperHumanForSupportAgents:
    'Restrict switching Superhuman toggle on the conversation level for Support Agents',
  TEXT_TYPE_YOUR_INSTRUCTIONS: 'Type your Instructions',
  TEXT_TYPE_YOUR_CURRENT_PROMOTIONS: 'Type your Current Promotions',
  TITLE_DRIP_SEQUENCE: 'Sequences',
  WORD_DRIP_SEQUENCE_SMS: 'SMS Sequence',
  WORD_DRIP_SEQUENCE_EMAIL: 'Email Sequence',
  WORD_DRIP_SEQUENCE_TEMPLATES: 'Sequence Templates',
  TEXT_UPDATE_ORGANIZATION_DRIP_SEQUENCE: 'Update organization Drip Sequences',
  WORD_OFFER_AMOUNT: 'Offer amount',
  TEXT_YOU_CANT_DISABLE_THIS_NOTIFICATION: 'You can’t disable this notification',
  TEXT_SUPER_HUMAN_ASSISTED_APPOINTMENTS: 'Superhuman assisted appointments',
  TEXT_SUPER_HUMAN_REPLIES: 'Superhuman replies',
  TEXT_ASSISTED_APPOINTMENTS: 'Assisted appointments',
  TEXT_COMMUNICATION_CHANNELS: 'Communication channels',
  TEXT_ARCHIVE_MULTIPLE_CONVERSATIONS: 'Archive multiple conversations',
  TEXT_MARK_AS_READ_MULTIPLE_CONVERSATIONS: 'Mark as read multiple conversations',
  SELECT_CONVERSATION_TO_MARK_AS_READ: 'Select conversation(s) to mark as read',
  TEXT_UNARCHIVE_MULTIPLE_CONVERSATIONS: 'Select conversation(s) to unarchive',
  TEXT_SELECT_AN_ACTION_BEFORE_CHOOSING_CONVERSATIONS:
    'Select an action before choosing conversations',
  NTF_MSG_MULTIPLE_UNARCHIVED: 'The conversations have been successfully unarchived',
  SELECT_CONVERSATION_ARCHIVE: 'Select conversation(s) to archive',
  NTF_MSG_MULTIPLE_ARCHIVED: 'The conversations have been successfully archived',
  filter_by_location: 'Filter by location',
  TEXT_SELECT_ROLES_THAT_WILL_HAVE_OPTION_SKIP_NEXT_SCHEDULED_MESSAGE:
    'Select user role(s) that can skip next scheduled message:',
  WORD_WELCOME_TITLE: 'Welcome Title',
  WORD_FIRST_MESSAGE: 'First Message',
  WORD_SECOND_MESSAGE: 'Second Message',
  WORD_CLEAR_ALL: 'Clear all',
  WORD_SELECT_ITEM: 'Select item',
  WORD_PLEASE_SELECT_ITEM: 'Please Select item',
  WORD_ORGANIZATION: 'Organization',
  TEXT_SEND_CALENDAR_INVITATION_ASSIGNED_USERS:
    'Send calendar invitation to assigned users',
  TEXT_AUTO_REPLY_AFTER: 'Auto reply after',
  TEXT_MINUTES_OF_MESSAGE_BEING_UNREAD: 'minutes of a message being unread',
  WORD_SUPERHUMAN_TAGGED: 'Superhuman tagged',
  WORD_YOU: 'You',
  WORD_CLEAR_CONVERSATION: 'Clear Conversation',
  TEXT_GIVE_YOUR_SUPERHUMAN_CONFIGURATION_TRY:
    'Give your Superhuman configuration a try',
  TEXT_SUPERHUMAN_IS_THINKING: 'Superhuman is thinking',
  TEXT_TYPE_MESSAGE_TO_SMS_TO_USER: 'Type a message to SMS to the user',
  WORD_LATER_TODAY: 'Later Today',
  WORD_TOMORROW: 'Tomorrow',
  WORD_NEXT_WEEK: 'Next Week',
  WORD_PICK_TIME_AND_DAY: 'Pick Time and Day',
  WORD_SUNDAY: 'Sunday',
  WORD_MONDAY: 'Monday',
  WORD_TUESDAY: 'Tuesday',
  WORD_WEDNESDAY: 'Wednesday',
  WORD_THURSDAY: 'Thursday',
  WORD_FRIDAY: 'Friday',
  WORD_SATURDAY: 'Saturday',
  TEXT_RECEIVE_NOTIFICATIONS_FROM_ALL_ORGANIZATIONS:
    'Receive notifications from all organizations',
  TEXT_WHAT_LOCATIONS_DO_YOU_WANT_RECEIVE_NOTIFICATIONS_FROM:
    'What locations do you want to receive notifications from?',
  TEXT_PLEASE_ENTER_THE_PERIOD_IN_MINUTES:
    'Please enter the period (in minutes) after which you want to receive notifications for unread messages',
  TEXT_PLEASE_ENTER_THE_PERIOD_IN_MINUTES_BEING_UNASSIGNED_FROM_CONVERSATION:
    'Please enter the period (in minutes) after which you want to receive notifications for being unassigned from conversation',
  TEXT_AT_LEAST_ONE_LOCATION_MUST_BE_SELECTED:
    'At least one location must be selected',
  WORD_SELECT_TYPE: 'Select type',
  TEXT_WHAT_ORGANIZATIONS_DO_YOU_WANT_RECEIVE_NOTIFICATIONS_FROM:
    'What organizations do you want to receive notifications from?',
  TEXT_AT_LEAST_ONE_ORGANIZATION_MUST_BE_SELECTED:
    'At least one organization must be selected',
  THE_TWO_PASSWORDS_THAT_YOU_ENTERED_DO_NOT_MATCH:
    'The two passwords that you entered do not match!',
  TEXT_PROFILE_CHANGE_PASSWORD: 'Profile Change Password',
  TEXT_WHICH_LOCATION_WOULD_YOU_LIKE_CHOOSE_DISPLAYING_QUESTION:
    'Which location would you like to choose for displaying questions?',
  TEXT_CHOOSE_SEVERAL_LOCATIONS_AND_AFTERWARD:
    'Choose several locations, and afterward, you have the option to rearrange them.',
  WORD_ANY: 'Any',
  TEXT_BROADCAST_SEGMENT_TAGS_DETAILS: `"AND" is similar to saying "I want clients who have both of these tags". For instance, "I want to see clients who have the tag 'SUV' and the tag 'Purchased'". In this case, you're looking for clients who have both tags, such as those who are tagged with 'Purchased' and 'SUV'.
  "OR" is akin to saying "I want clients who have either of these tags". For example, "I want to see clients who have the tag 'SUV owner' OR the tag 'truck owner'". Here, you're given a choice between two options, and you're fine with clients having either one.`,
  TEXT_PLEASE_WRITE_ONLY_POSITIVE_NUMBER: 'Please write only positive number',
  TEXT_THE_MAXIMUM_NUMBER_CAN_BE: 'The maximum number can be 999',
  TEXT_PLEASE_WRITE_NUMBER_OF_DAYS: 'Please write number of days',
  WORD_CAMPAIGN: 'Campaign',
  WORD_CONTENT: 'Content',
  WORD_CONDITIONS: 'Conditions',
  WORD_AVAILABLE_STATUSES: 'Available Statuses',
  WORD_STATUSES_FOR_INTEGRATES: 'Statuses for integrate',
  WORD_NEXT_SCHEDULED_MESSAGE: 'Next scheduled message',
  TEXT_SCHEDULED_MESSAGE_SKIPPED_SUCCESSFULLY:
    'Scheduled message skipped successfully',
  MSG_SKIP_SCHEDULED_MESSAGE: 'Are you sure you want to skip the scheduled message?',
  nextScheduledDripSequenceMessage:
    'Allow users to see and skip next scheduled Drip Sequence message',
  WORD_BODY: 'Body',
  WORD_ESTIMATE_PRICE: 'Estimated price',
  WORD_HELP: 'Help',
  WORD_GO_BACK: 'Go back',
  TEXT_EXCLUDE_ARCHIVED_CUSTOMERS_FROM_BROADCAST:
    'EXCLUDE CUSTOMERS IN THE "ARCHIVED" FOLDER FROM BROADCAST',
  MSG_PLEASE_CONFIRM_YOUR_PASSWORD: 'Please confirm your password',
  WORD_ADD_WORKING_HOURS: 'Add Custom Working Hours',
  WORD_ADD_CUSTOM_HOURS: 'Add custom hours',
  WORD_PLEASE_INPUT: 'Please input',
  MSG_PLEASE_INPUT_PASSWORD: 'Please input password',
  MSG_PLEASE_SELECT_DEFAULT_LANGUAGE: 'Please select a default language',
  WORD_CHARACTERS: 'characters',
  TEXT_MUST_BE_MAXIMUM: 'must be maximum',
  TEXT_MUST_BE_MINIMUM: 'must be minimum',
  TEXT_WHAT_REPORTS_DO_YOU_WANT_TO_RECEIVE: 'What stats do you want to receive?',
  TEXT_AT_LEAST_ONE_OPTION_MUST_BE_SELECTED: 'At least one option must be selected',
  TEXT_ADD_DRIP_SEQUENCE: 'Add Sequence Message',
  TEXT_BROADCAST_TIME_ZONE: `Broadcast time zone: Selected in organization's time zone`,
  TEXT_APPOINTMENT_TIME_ZONE: `Appointment time zone: Selected in organization's time zone`,
  TEXT_CLICK_SORT_ASCENDING: 'click to sort ascending',
  TEXT_CLICK_SORT_DESCENDING: 'click to sort descending',
  TEXT_CLICK_SORT_CANCEL: 'click to cancel sorting',
  matador_payment_request_created: 'Payment Request Created',
  payment_request_failed: 'Payment Request Failed',
  payment_request_canceled: 'Payment Request Canceled',
  payment_request_completed: 'Payment Request Completed',
  matador_refund_request_created: 'Payment Refund Request Created',
  MSG_MAXIMUM_DELAY_FOR_AFTER_HOUR_SEQUENCE:
    'Maximum delay for after-hour sequence message is 15 minutes. Please enter time within this limit.',
  MSG_MINIMUM_DELAY_FOR_AFTER_HOUR_SEQUENCE:
    'Minimum delay for after-hour sequence message is 1 minute',
  SKIPPED_SCHEDULED_MESSAGE_CREATED_DRIP_SEQUENCE:
    'skipped scheduled message created by Drip Sequence',
  TEXT_PLEASE_NOTE_THIS_EMAIL_ATTACHED_SEVERAL_OTHER_INTEGRATIONS:
    'Please note! This email is attached to several other integrations.',
  TEXT_IF_YOU_DISCONNECT_THIS_INTEGRATION_ALL_INTEGRATIONS_ABOVE_WILL_BE_DISCONNECTED_TOO:
    'If you disconnect this integration all integrations above will be disconnected too.',
  WORD_SELECT_DELAY: 'Select delay',
  WORD_SELECT_TRIGGER: 'Select Trigger',
  WORD_MINUTE: 'Minute',
  TEXT_RECOMMENDED_FORMATS_JPEG:
    'Recommended formats: JPEG, JPG, PNG, or GIF. Use an aspect ratio of 2.8:1.',
  MSG_ANSWER_IS_REQUIRED:
    'Answer is required for both languages. Please insert the answer in [lang] field',
  MSG_QUESTION_IS_REQUIRED:
    'Question is required for both languages. Please insert the answer in [lang] field',
  PLEASE_USE_CORRECT_DYNAMIC_TAGS: 'Please use correct dynamic tags',
  WORD_TEXT_US_BUTTON: 'Text Us Button',
  WORD_MARGIN_BOTTOM: 'Margin Bottom',
  WORD_SMALL: 'Small',
  WORD_MEDIUM: 'Medium',
  WORD_LARGE: 'Large',
  WORD_CALL_ME_BUTTON: 'Call Me Button',
  WORD_PRIMARY_BACKGROUND_COLOR: 'Primary Background Color',
  TEXT_RECOMMENDED_FOR_RESPONSIVENESS: '100% width (recommended for responsiveness)',
  MSG_YOUR_EMAIL_UPDATED_SUCCESSFULLY: 'Your email updated successfully',
  WORD_SNOOZED: 'Snoozed',
  TEXT_SUPERHUMAN_WILL_BE_REENGAGED_CONVERSATION_AT:
    'Superhuman will be re-engaged to conversation on',
  WORD_REVERT_DECISION: 'Revert Decision',
  TEXT_TAKE_PART_CONVERSATION: 'Takes part in the conversation',
  TEXT_SNOOZE_FOR_HOURS: 'Snooze for {{hours}} hours',
  superhumanActionInConversation:
    'Allow users to see if Superhuman intends to reply in a conversation',
  TEXT_SELECT_USER_ROLE_THAT_CAN_SNOOZE_SUPERHUMAN_REPLY:
    'Select user role(s) that can snooze Superhuman reply:',
  MSG_SNOOZE_SUPERHUMAN: 'Are you sure you want to snooze the superhuman action?',
  TEXT_REACTIVATED_SUPERHUMAN_PARTICIPATION_CONVERSATION:
    'reactivated Superhuman participation in the conversation',
  MSG_STORIES_WERE_REORDERED_SUCCESSFULLY: 'Stories were reordered successfully',
  STORIES_REORDER_DESCRIPTION: 'Drag and drop to reorder stories',
  TEXT_THIS_PHONE_NUMBER_IS_CURRENTLY_NOT_ACCEPTING_SMS_MESSAGES:
    'This number is temporarily unavailable. Please try again later.',
  WORD_QUEUED: 'Queued',
  WORD_FAILED: 'Failed',
  WORD_SELL: 'Sell',
  WORD_BUY: 'Buy',
  TEXT_IT_IS_NOT_POSSIBLE_TO_CREATE_REMINDER:
    'It is not possible to create a reminder for the past time, please select the correct time',
  TEXT_SNOOZED_SUPERHUMAN_FOR_HOURS: 'snoozed Superhuman for 12 hours',
  TEXT_THIS_CUSTOMER_SUBSCRIBED_EMAIL_COMMUNICATION:
    'This customer subscribed to email communication',
  TEXT_THIS_CUSTOMER_UNSUBSCRIBED_EMAIL_COMMUNICATION:
    'This customer unsubscribed from email communication',
  TEXT_AUTOMATIONS_CHANGED_CONSENT_STATUS_THIS_CUSTOMER_PENDING_REFUSED:
    'Automations changed the consent status of this customer from Pending to Refused',
  TEXT_SCHEDULED_TIME_ZONE: `Scheduled message time zone: Selected in organization's time zone`,
  TEXT_REMINDER_TIME_ZONE: `Reminder time zone: Selected in organization's time zone`,
  WORD_DRIP_SEQUENCE_INSTAGRAM: 'Drip Sequence Instagram',
  WORD_DRIP_SEQUENCE_GOOGLE_BUSINESS: 'Drip Sequence Google Business',
  WORD_DRIP_SEQUENCE_FACEBOOK: 'Drip Sequence Facebook',
  WORD_DISABLED: 'Disabled',
  TEXT_SUBJECT_REQUIRED_PLEASE_ENTER_SUBJECT_FOR_YOUR_EMAIL:
    'Subject Required: Please enter a subject for your email',
  WORD_ENGAGED: 'Engaged',
  TEXT_AUTOMATIONS_CHANGED_CONSENT_STATUS_THIS_CUSTOMER_COMPLIANT_OPTED_OUT:
    'Automations changed the consent status of this customer from Compliant to Opted-out',
  TEXT_AUTOMATIONS_CHANGED_CONSENT_STATUS_THIS_CUSTOMER_COMPLIANT_REFUSED:
    'Automations changed the consent status of this customer from Compliant to Refused',
  TEXT_USERNAME_CHANGED_CONSENT_STATUS_THIS_CUSTOMER_COMPLIANT_REFUSED:
    'changed the consent status of this customer from Compliant to Refused',
  TEXT_SUPERHUMAN_ENABLED: 'Superhuman enabled',
  TEXT_SUPERHUMAN_DISABLED: 'Superhuman disabled',
  TEXT_FOLLOW_UP_ENABLED: 'Follow up enabled',
  TEXT_FOLLOW_UP_DISABLED: 'Follow up disabled',
  TEXT_NEW_TOUCHPOINTS: 'New touchpoints',
  TEXT_AUTO_ASSIGN_TO_THE_FOLLOWING_REGULAR_SEQUENCE:
    'Auto-assign to the following regular sequence',
  TEXT_AUTO_ASSIGN_TO_FOLLOWING_AFTER_HOUR_SEQUENCE:
    'Auto-assign to following after-hour sequence',
  TEXT_ENGLISH: 'English',
  TEXT_FRENCH: 'French',
  TEXT_MESSAGE_WAS_DELETED_BY_USER: 'Message was deleted by customer',
  TEXT_PLEASE_TRY_TO_UPLOAD_THE_FILE_LESS_THAN_1_MB:
    'Please try to upload the file less than 1 MB',
  TEXT_APPOINTMENT_IS_BOOKED: 'Appointment is booked',
  TEXT_APPOINTMENT_WAS_UPDATED: 'Appointment was changed',
  TEXT_APPOINTMENT_WAS_CANCELED: 'Appointment was canceled',
  WORD_SEQUENCES: 'Sequences',
  WORD_AI_FOLLOW_UP: 'AI Follow Up',
  TEXT_STORE_IS_OPEN: 'Store is open',
  TEXT_STORE_IS_CLOSED: 'Store is closed',
  WORD_AM: 'AM',
  WORD_PM: 'PM',
  allowFollowUp: 'Enable AI Follow Up',
  TEXT_FOLLOW_UP_TOOLTIP: `AI Follow-Up Automatically re-engages cold conversations. If a customer doesn't respond to your last message for 12 hours, AI Follow-Up will schedule a follow-up message for the ideal date/time. Use this toggle to disable the feature for the entire Organization. Users can always opt-out of AI Follow-Up directly in the conversation as well.`,
  TEXT_ENABLED_BY: 'Enabled by:',
  TEXT_DISABLED_BY: 'Disabled by:',
  TEXT_TYPE_OF_SEQUENCE: 'Type of Sequence',
  TEXT_DISABLE_ALL_SEQUENCES: 'Disable All Sequences',
  TEXT_ENABLE_ALL_SEQUENCES: 'Enable All Sequences',
  TEXT_ARE_YOU_SURE_YOU_WANT_TO_ENABLE_ALL_SEQUENCES_IN_THIS_ORGANIZATION:
    'Are you sure you want to enable all sequences in this organization?',
  TEXT_ARE_YOU_SURE_YOU_WANT_TO_DISABLE_ALL_SEQUENCES_IN_THIS_ORGANIZATION:
    'Are you sure you want to disable all sequences in this organization?',
  TEXT_THIS_ACTION_CANNOT_BE_UNDONE: 'This action cannot be undone',
  TEXT_ALL_SEQUENCES_WERE_ENABLED_SUCCESSFULLY:
    'All sequences were enabled successfully',
  TEXT_ALL_SEQUENCES_WERE_DISABLED_SUCCESSFULLY:
    'All sequences were disabled successfully',
  TEXT_GOOGLE_DISCONTINUED:
    'Google Business Messages has been discontinued as of July 31, 2024. Please contact us for assistance.',
  TEXT_SKIPPED_SCHEDULED_MESSAGE_CREATED:
    'skipped the next scheduled Sequence message',
  WORD_DRIP_SEQUENCE: 'Drip Sequence',
  DISABLE_INPUT: 'Do not display the input field “Type your message”',
  REDIRECT_URL: 'Redirection URL',
  REDIRECT_URL_TOOLTIP:
    'The Redirect URL is designated to navigate the customer directly to a specified URL, bypassing the display of the lead form. While this option is not generally recommended, it may serve beneficial purposes under certain circumstances.',
  INVALID_URL: 'Invalid URL',
  TEXT_THERE_WAS_AN_ISSUE_CONNECTING_YOUR_ACCOUNT_PLEASE_TRY_AGAIN:
    'There was an issue connecting your account, please try again',
  GA_TRACK_PAGE_VIEWS_TITLE: 'Track page views for each widget and lead submissions',
  GA_TRACK_PAGE_VIEWS_PARAGRAPH_1:
    "Our platform allows you to effortlessly monitor page views for each widget. Also, when a new lead is submitted, we'll send a customized event with the following details:",
  GA_CATEGORY: 'Category',
  GA_MATADOR: 'Matador',
  GA_ACTION: 'Action',
  GA_ACTION_DETAILS:
    '"connect" (or mobile_coupon, desktop_coupon, mobile_cta, integrated_cta, exit_intent)',
  GA_LABEL: 'Label',
  GA_SUBMISSION: 'Submission',
  GA_TRACK_PAGE_VIEWS_PARAGRAPH_2:
    "By tracking these events, you'll gain valuable insights into user engagement and conversions through Matador widgets.",
  GA_INFO_ABOUT_GA: 'Info about GA',
  GA_GOOGLE_ANALYTICS_4_TRACKING: 'Google Analytics 4 Tracking',
  POSITION_TEXT: 'Text Position',
  TEXT_REVIEW_INVITE_TYPE: 'Review Invite type',
  TEXT_REVIEW_POSTING_THRESHOLD: 'Review Posting Threshold',
  TEXT_FIVE_STARS: '5 stars',
  TEXT_NPS_SCORE_10_POINTS: 'NPS score (10 points)',
  TEXT_SETS_THE_MINIMUM_RATING_REQUIRED_BEFORE_CUSTOMERS_CAN_POST_THEIR_REVIEW_ON_EXTERNAL_PLATFORMS:
    'Sets the minimum rating required before customers can post their review on external platforms',
  TEXT_TAG_NAMES_SHOULD_NOT_BE_DUPLICATED: 'Tag names should not be duplicated',
  ENABLE_BOLD_TEXT: 'Enable bold text',
  GA_TRACK_PAGE_VIEWS_PARAGRAPH_3:
    "By tracking these events, you'll gain valuable insights into user engagement and conversions through Matador widgets.",
  GA_TRACK_PAGE_VIEWS_PARAGRAPH_4:
    'Additionally, our platform supports the use of multiple Google Analytics 4 (GA4) IDs. You can add multiple GA IDs in the format G-YYYYYYYYYY, G-XXXXXXXXXX, separated by commas. This allows you to track the same events across different GA properties for more detailed analysis.',
  INVALID_GA_FORMAT:
    'Invalid GA4 ID format. Each ID must be in the format G-XXXXXXXXXX with uppercase letters and digits.',
  INVALID_GA_FORMAT_COMMA:
    "It looks like you've ended with a comma or semicolon. Please add another GA code or remove the trailing character.",
  INVALID_GA_FORMAT_SPACE:
    'Please ensure there is a space after each comma or semicolon when entering multiple GA4 IDs.',
  TEXT_PERSONAL_FACEBOOK_ACCOUNT: 'Personal Facebook Account',
}

export default texts
