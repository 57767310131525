import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import authActions from '../../store/modules/authActions'
import SVGDrawerOpenIcon from '../../icons/SVG/SVGDrawerOpenIcon'
import './SiderToggle.scss'

const SiderToggle = () => {
  const menuOpen = useSelector((state) => {
    return state.authReducer.menuOpen
  })

  const openMenu = () => authActions.setMenuOpen(!menuOpen)

  return (
    <div
      className={`header-toggle-icon-container ${menuOpen ? ' icon-rotate ' : ' '}`}
      onClick={openMenu}
    >
      <SVGDrawerOpenIcon />
    </div>
  )
}

export default memo(SiderToggle)
