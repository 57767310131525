import React from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import './Popup.scss'

function PopupPreview(props) {
  const {
    uploadFile,
    titleEn,
    titleFr,
    titleSp,
    titleColor,
    headerEn,
    headerFr,
    headerSp,
    headerColor,
    descriptionEn,
    descriptionFr,
    descriptionSp,
    descriptionColor,
    buttonTextColor,
    termsLink,
    buttonColor,
    buttonEn,
    buttonFr,
    buttonSp,
    defaultImageUrl,
    backgroundColor,
    fieldsLang,
  } = props
  return (
    <>
      <div
        className={'popup_main-container popup_main-container--preview'}
        style={{
          backgroundColor: backgroundColor,
        }}
      >
        <div className='antd-modal-body'>
          <div className='popup_container'>
            <div
              className={
                uploadFile && uploadFile.file
                  ? 'popup_left-side'
                  : 'popup_left-side ps-0 align-items-center'
              }
            >
              <div>
                <p
                  className='popup_subtitle'
                  style={{
                    color: headerColor,
                  }}
                >
                  {fieldsLang === 'en'
                    ? headerEn
                    : fieldsLang === 'fr'
                      ? headerFr
                      : headerSp}
                </p>
                <p
                  className='popup_header'
                  style={{
                    color: titleColor,
                  }}
                >
                  {fieldsLang === 'en'
                    ? titleEn
                    : fieldsLang === 'fr'
                      ? titleFr
                      : titleSp}
                </p>
                <p
                  className='popup_description'
                  style={{
                    color: descriptionColor,
                  }}
                >
                  {fieldsLang === 'en'
                    ? descriptionEn
                    : fieldsLang === 'fr'
                      ? descriptionFr
                      : descriptionSp}
                </p>
                <PhoneInput
                  country={'us'}
                  inputClass='popup_input'
                  buttonClass='popup_input_flag'
                  placeholder='(000) 000-0000'
                />
                <button
                  className='popup_button'
                  style={{
                    background: buttonColor,
                    color: buttonTextColor,
                  }}
                >
                  {fieldsLang === 'en'
                    ? buttonEn
                    : fieldsLang === 'fr'
                      ? buttonFr
                      : buttonSp}
                </button>
                <p className='popup_terms'>
                  I agree to receive news, updates &amp; other promotional
                  information from Matador, and understand that I can withdraw it at
                  any time. <a href={termsLink || ''}>Privacy Policy</a>
                </p>
              </div>
              <div className={'popup_logo d-flex align-items-center'}>
                <p>Powered By</p>
                <img src={defaultImageUrl} alt='logo' className='img-fluid' />
              </div>
            </div>
            {uploadFile && uploadFile.file && (
              <div className='popup_right-side'>
                <img className='img-fluid' src={uploadFile.file} alt='cars' />
                <div className='overlay' style={{ backgroundColor: buttonColor }} />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default PopupPreview
