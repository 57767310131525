import React from "react";
import Icon from "@ant-design/icons";

const SVGIcon = (props) => (
  <svg
    width={props.width || "33"}
    height={props.height || "32"}
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      opacity="0.1"
      x="0.5"
      width="32"
      height="32"
      rx="10"
      fill={props.color || "#FFC800"}
    />
    <path
      d="M13.8848 9.19727C12.021 9.19727 10.5098 10.7083 10.5098 12.5723C10.5098 14.4362 12.021 15.9473 13.8848 15.9473C15.7485 15.9473 17.2598 14.4362 17.2598 12.5723C17.2598 10.7083 15.7485 9.19727 13.8848 9.19727ZM21.7598 13.6973C21.3458 13.6973 21.0098 14.033 21.0098 14.4473V15.1973H20.2598C19.8458 15.1973 19.5098 15.533 19.5098 15.9473C19.5098 16.3615 19.8458 16.6973 20.2598 16.6973H21.0098V17.4473C21.0098 17.8615 21.3458 18.1973 21.7598 18.1973C22.1738 18.1973 22.5098 17.8615 22.5098 17.4473V16.6973H23.2598C23.6738 16.6973 24.0098 16.3615 24.0098 15.9473C24.0098 15.533 23.6738 15.1973 23.2598 15.1973H22.5098V14.4473C22.5098 14.033 22.1738 13.6973 21.7598 13.6973ZM11.3063 16.9551C9.93076 17.5227 9.00977 18.9143 9.00977 20.4473V21.9473C9.00977 22.3615 9.34577 22.6973 9.75977 22.6973H18.0098C18.4238 22.6973 18.7598 22.3615 18.7598 21.9473V20.4473C18.7598 18.9504 17.8335 17.5925 16.4633 17.0019C16.2698 16.9188 16.0455 16.9163 15.8535 17.0019C15.1898 17.2994 14.5433 17.4471 13.8848 17.4473C13.2285 17.4473 12.5798 17.2913 11.8928 16.9785C11.7038 16.8926 11.4983 16.8761 11.3063 16.9551Z"
      fill={props.color || "#FFC800"}
    />
  </svg>
);

const SVGWorkflowPushCRMIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />;
      }}
      {...props}
    />
  );
};

export default SVGWorkflowPushCRMIcon;
