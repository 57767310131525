import React from 'react'
import SelectFormSecondary from '../../../../../components/Form/SelectForm/SelectFormSecondary'
import { getText } from '../../../../../lang'

const getFinalViewTypes = () => {
  return [
    { value: null, label: getText('WORD_SELECT_TYPE') },
    { value: 'Default Reply', label: 'Default Reply' },
    { value: 'Form', label: 'Form' },
  ]
}

const FinalViewOptions = ({ step, index, handleViewTypeChange }) => {
  return (
    <SelectFormSecondary
      name='finalView'
      label='Final View'
      options={getFinalViewTypes()}
      onChange={(e) => {
        handleViewTypeChange(index, e)
      }}
      defaultValue={step.finalView}
      required
    />
  )
}

export default FinalViewOptions
