import React, { forwardRef, useState } from 'react'
import MatSelect from '../../components/MatSelect'

const TabHeader = forwardRef((props, ref) => {
  const [selectedStatesType, setSelectedStatesType] = useState(() =>
    props.urlSearchParams.stats === 'questions'
      ? props.urlSearchParams.stats
      : 'greetings'
  )

  const renderSelectGreetQuest = () => {
    const list_greet_ques = [
      {
        label: 'Greetings',
        value: 'greetings',
      },
      {
        label: 'Questions',
        value: 'questions',
      },
    ]
    const currentSelectedStatesType = list_greet_ques.find(
      (obj) => obj.value === selectedStatesType
    )

    return (
      <div>
        <MatSelect
          secondary
          value={currentSelectedStatesType.label}
          options={list_greet_ques}
          onSelect={(value) => setSelectedStatesType(value)}
        />
      </div>
    )
  }

  const getRightTitle = () => {
    switch (props.title) {
      case 'user_dashboard':
        return null
      case 'chat_dashboard':
        return renderSelectGreetQuest()
      default:
        return null
    }
  }

  const { selectedDataType, selectedRange, selectCustomRange } = props

  return (
    <>
      {getRightTitle() !== null && (
        <div style={{ marginTop: '20px' }} className='dashboard-tab-header'>
          {getRightTitle()}
        </div>
      )}
      {props.children &&
        React.cloneElement(props.children, {
          history: props.history,
          urlSearchParams: props.urlSearchParams,
          _location_id: props._location_id,
          screenSize: props.screenSize,
          selectedStatesType,
          selectedDataType,
          selectedRange,
          selectCustomRange,
        })}
    </>
  )
})

export default TabHeader
