import React from 'react'
import { getText } from '../../../lang'
import SVGAppleIcon from '../../../icons/SVG/SVGAppleIcon'
import SVGExtensionIcon from '../../../icons/SVG/SVGExtensionIcon'
import SVGGooglePlayIcon from '../../../icons/SVG/SVGGooglePlayIcon'
import LoginDownloadInButton from './LoginDownloadInButton'

const LoginPageDownloadButtons = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: 80,
      }}
    >
      <LoginDownloadInButton
        label={getText('WORD_DOWNLOAD_IN_APPSTORE')}
        icon={<SVGAppleIcon />}
        href={'https://apps.apple.com/ca/app/matador-ai/id1642518755'}
      />
      <LoginDownloadInButton
        label={getText('WORD_DOWNLOAD_IN_GOOGLE_PLAY')}
        icon={<SVGGooglePlayIcon />}
        href={'https://play.google.com/store/apps/details?id=com.matador&pli=1'}
      />
      <LoginDownloadInButton
        label={getText('WORD_DOWNLOAD_IN_GOOGLE_CHROME')}
        icon={<SVGExtensionIcon />}
        href={'https://www.matador.ai/extension'}
      />
    </div>
  )
}

export default LoginPageDownloadButtons
