import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width={props.width || '12'}
      height={props.height || '12'}
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.5013 0.75C1.21264 0.75 0.167969 1.79467 0.167969 3.08333V8.91667C0.167969 10.2053 1.21264 11.25 2.5013 11.25H9.5013C10.79 11.25 11.8346 10.2053 11.8346 8.91667V3.08333C11.8346 1.79467 10.79 0.75 9.5013 0.75H2.5013ZM2.74399 3.30262C2.54294 3.16859 2.27131 3.22292 2.13728 3.42396C2.00325 3.625 2.05758 3.89663 2.25862 4.03066L4.46432 5.50113C5.39504 6.12161 6.60756 6.12161 7.53829 5.50113L9.74399 4.03066C9.94503 3.89663 9.99936 3.625 9.86533 3.42396C9.7313 3.22292 9.45967 3.16859 9.25862 3.30262L7.05292 4.77309C6.41611 5.19763 5.58649 5.19763 4.94969 4.77309L2.74399 3.30262Z'
        fill={props.color || '#EA9957'}
      />
    </svg>
  )
}

const SVGAiBotEmailcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGAiBotEmailcon
