import React, { useRef, useState } from 'react'
import { Form } from 'antd'
import { useSelector } from 'react-redux'
import broadcastingActions from '../../store/modules/broadcastingActions'
import { getText, getTextServerError } from '../../lang'
import MatForm from '../../components/Form/MatForm'
import { notifyError, notifySuccess } from '../../utils/Notify'
import MatModal from '../../components/MatModal'
import BuildContent from './CreateCampaign/StepPages/SubPages/Content/BuildContent'

const BroadcastMessageEditModal = ({
  trigger,
  message,
  hasExcel,
  itemId,
  onClose,
}) => {
  const [visible, setVisible] = useState(false)
  const [textMessage, settextMessage] = useState(message)
  const [currentCursorPosition, setCurrentCursorPosition] = useState(message.length)

  const [form] = Form.useForm()
  const textAreRef = useRef()

  const organization = useSelector((state) => {
    return state.authReducer.user.organization
  })

  const checkTextInput = (input, array) => {
    const regex = /{{(.*?)}}/g

    let match
    while ((match = regex.exec(input)) !== null) {
      const value = match[1]

      const exists = array.some((item) => item.value.trim() === `{{${value}}}`)
      if (!exists) {
        return true
      }
    }

    return false
  }

  const listVariables = hasExcel
    ? [
        {
          label: 'customer_first_name',
          value: ' {{customer_first_name}} ',
        },
        {
          label: 'customer_last_name',
          value: ' {{customer_last_name}} ',
        },
        {
          label: 'customer_full_name',
          value: ' {{customer_full_name}} ',
        },
        {
          label: 'organization_name',
          value: ' {{organization_name}} ',
        },
        {
          label: 'review_invite_url',
          value: ' {{review_invite_url}} ',
        },
        {
          label: 'appointment_date',
          value: ' {{appointment_date}} ',
        },
        {
          label: 'appointment_time',
          value: ' {{appointment_time}} ',
        },
        {
          label: 'year',
          value: ' {{year}} ',
        },
        {
          label: 'make',
          value: ' {{make}} ',
        },
        {
          label: 'model',
          value: ' {{model}} ',
        },
        {
          label: 'VIN',
          value: ' {{VIN}} ',
        },
        {
          label: 'documents',
          value: ' {{documents}} ',
        },
        {
          label: 'offer_amount',
          value: ' {{offer_amount}} ',
        },
      ]
    : [
        {
          label: 'customer_first_name',
          value: ' {{customer_first_name}} ',
        },
        {
          label: 'customer_last_name',
          value: ' {{customer_last_name}} ',
        },
        {
          label: 'organization_name',
          value: ' {{organization_name}} ',
        },
        {
          label: 'review_invite_url',
          value: ' {{review_invite_url}} ',
        },
      ]

  const onFinish = async (values) => {
    const existingValue = checkTextInput(
      typeof textMessage === 'object' ? textMessage.buildYourOwnText : textMessage,
      listVariables
    )

    if (existingValue) {
      notifyError(
        getText('ERROR_USE_ONLY_DYNAMIC_TAGS_FROM_THE_LIST_OF_SUGGESTED_ONES')
      )
      return
    }
    const result = await broadcastingActions.updateBroadcastMessage(
      itemId,
      values.inputMessage.buildYourOwnText
    )
    if (result.success) {
      onClose && onClose(values.inputMessage.buildYourOwnText)
      notifySuccess(getText('NTF_SUCCESS_BROADCAST_WAS_CHANGED_SUCCESSFULLY'))
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
    setVisible(false)
  }

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields.length > 0) {
      notifyError(errorInfo.errorFields[0].errors[0])
    }
  }

  const addNameInMessenger = (text) => {
    let previousText =
      typeof textMessage === 'object' ? textMessage.buildYourOwnText : textMessage
    let newText = [
      previousText.slice(0, currentCursorPosition),
      text,
      previousText.slice(currentCursorPosition),
    ].join('')

    setCurrentCursorPosition(currentCursorPosition + text.length)
    settextMessage(newText)
    form.setFieldsValue({ inputMessage: { buildYourOwnText: newText } })
    textAreRef.current &&
      textAreRef.current.autoFocus(currentCursorPosition + text.length)
  }

  return (
    <MatModal
      title={getText('TEXT_EDIT_BROADCAST_MESSAGE')}
      trigger={trigger}
      width={500}
      visible={visible}
      onTrrigerClick={() => {
        setVisible(true)
      }}
      onCancel={() => {
        setVisible(false)
        form.resetFields()
      }}
      centered={false}
      okText={getText('ACTION_SAVE')}
    >
      <MatForm
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{
          inputMessage: {
            buildYourOwnText: message || '',
          },
        }}
      >
        <div className='create-campaign-types create-campaign-types-modal'>
          <div className='create-campaign-form-wrapper'>
            <BuildContent
              textAreRef={textAreRef}
              listVariables={listVariables}
              showTextarea={true}
              organization={organization}
              hideUploadFile={true}
              settextMessage={settextMessage}
              textMessage={textMessage}
              addNameInMessenger={addNameInMessenger}
              setCurrentCursorPosition={setCurrentCursorPosition}
              tabValue={'build_your_own'}
            />
          </div>
        </div>
      </MatForm>
    </MatModal>
  )
}

export default BroadcastMessageEditModal
