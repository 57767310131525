import React, { useEffect, useState } from 'react'
import { Form } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { notifyError, notifySuccess } from '../../../../utils/Notify'
import { getText, getTextServerError } from '../../../../lang'
import MatForm from '../../../../components/Form/MatForm'
import MatButton from '../../../../components/MatButton'
import MatBlockWithLabel from '../../../../components/MatBlockWithLabel'
import LoadingSpinner from '../../../../components/LoadingSpinner'
import OrganizationLayout from '../../../organization/components/organizationNewPage/OrganizationLayout'
import locationActions from '../../../../store/modules/locationActions'
import OrganizationListTitle from '../../../organization/components/organizationNewPage/OrganizationListTitle'
import { getLongName } from '../../../../utils'
import MatSwitchSecondary from '../../../../components/MatSwitch/MatSwitchSecondary'
import CalendarInviteItem from './CalendarInviteItem'
import CalendarInvitePanel from './CalendarInvitePanel'
import './CalendarInvite.scss'

const deviceSize = ['mobile', 'tabletPortrait', 'tablet']

function removeEmptyStrings(obj) {
  for (let prop in obj) {
    if (typeof obj[prop] === 'object') {
      removeEmptyStrings(obj[prop])
    } else if (obj[prop] === '') {
      delete obj[prop]
    }
  }
}

const CalendarInvite = (props) => {
  const { screenSize, organization, locationObj, isNewLocation } = props

  const navigate = useNavigate()
  const { _id } = useParams()

  const [selectedCalendarSequence, setSelectedCalendarSequence] = useState(null)
  const [sequenceId, setSequenceId] = useState('')
  const [doNotSendReminderOnSameDay, setDoNotSendReminderOnSameDay] = useState(true)

  const [form] = Form.useForm()
  const languages = organization.languages

  const getSelectedIndex = !selectedCalendarSequence
    ? 0
    : form
        .getFieldValue('calendar_invite_sequence')
        .findIndex((calendar) => calendar.id === selectedCalendarSequence.id)

  const deviceCheck = deviceSize.includes(screenSize)

  useEffect(() => {
    if (!isNewLocation && !locationObj) {
      return navigate('/location/list')
    }
  }, [_id])

  useEffect(() => {
    props.setFooterButtons && props.setFooterButtons(['save', 'cancel'])
  }, [])

  useEffect(() => {
    const fill = async () => {
      let firstAutoMessage = { text: {}, image: {} }
      let otherAutoMessages = []
      languages.forEach((lang) => {
        firstAutoMessage.text[lang] = locationObj
          ? locationObj.calendarMsg[lang]
          : ''
        firstAutoMessage.image[lang] = locationObj
          ? locationObj.calendarMsgPic[lang]
          : ''
      })

      const completeFirstAutoMessage = {
        ...firstAutoMessage,
        isFirstItem: true,
        delay: firstAutoMessage.time_unit || {
          value: 0,
          unit: 'immediately',
          minutes: 0,
        },
        send_via: 'sms',
        id: new Date().getTime(),
      }
      const result = await locationActions.getCalendarReminderSequence(_id)
      if (result.success) {
        result.data.messages.map((item) => {
          removeEmptyStrings(item.text)
          removeEmptyStrings(item.image)
          return (item.id = item._id || new Date().getTime())
        })
        result.data.messages.sort((a, b) => b.delay.minutes - a.delay.minutes)
        setSequenceId(result.data._id)
        setDoNotSendReminderOnSameDay(result.data.doNotSendReminderOnSameDay)
        otherAutoMessages = [...result.data.messages]
        locationObj.calendar_invite_sequence = [
          completeFirstAutoMessage,
          ...otherAutoMessages,
        ]
        form.setFieldsValue({
          calendar_invite_sequence: [completeFirstAutoMessage, ...otherAutoMessages],
        })
        setSelectedCalendarSequence(completeFirstAutoMessage)
      }
    }
    if (locationObj && locationObj._id) {
      fill()
    }
  }, [locationObj._id])

  const isAnyEmptyField = (autoReplies) => {
    const getMessageNumberError = (index) => {
      const number = index + 1
      switch (number) {
        case 1:
          return 'first'
        case 2:
          return 'second'
        case 3:
          return 'third'
        default:
          return number + 'th'
      }
    }
    let isEmptyField = false

    // checking for empty body message (for fields that are not currently showing on the UI)
    autoReplies.forEach((autoReply, index) => {
      for (const lang in autoReply.text) {
        const cleanContent = autoReply.text[lang].replaceAll(/\s/g, '')
        if (!cleanContent) {
          notifyError(
            `${getLongName(lang)} field is required for ${getMessageNumberError(
              index
            )} auto-reply message.`
          )
          isEmptyField = true
        }
      }
    })

    // checking for messages set at the same time
    const allTimeUnits = autoReplies.map((autoReply) => {
      return autoReply.delay.value + autoReply.delay.unit
    })
    const uniqueTimeUnits = [...new Set(allTimeUnits)]

    if (allTimeUnits.length !== uniqueTimeUnits.length) {
      notifyError(getText('ERR_SAME_TIME_AUTO_REPLY'))
      isEmptyField = true
    }

    return isEmptyField
  }

  const onFinish = async (values) => {
    let result
    if (isAnyEmptyField(values.calendar_invite_sequence)) return

    const [firstMessage = {}, ...otherMessages] = values.calendar_invite_sequence

    const { text, image } = firstMessage

    const objSend = {
      calendarMsg: text,
      calendarMsgPic: image,
    }

    const arMessages = otherMessages.map((message) => {
      delete message.isNewMessage
      delete message.id
      delete message._id
      delete message.loading

      return {
        ...message,
        send_via: message.send_via,
      }
    })

    if (locationObj._id) {
      result = await locationActions.saveCalendarInvite(objSend, locationObj._id)
      const objText = {
        messages: arMessages,
        doNotSendReminderOnSameDay: doNotSendReminderOnSameDay,
        _organization_id: locationObj._organization_id,
        _location_id: locationObj._id,
      }
      await locationActions.saveUpdateSequenceCalendarInviteMessege(
        objText,
        sequenceId
      )

      if (result && result.success) {
        notifySuccess(getText('TEXT_LOCATION_WAS_UPDATED_SUCCESSFULLY'))
        props.setLocationObj(result.data)
      } else {
        notifyError(getTextServerError(result.errMsg))
      }
    }
  }

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields.length > 0) {
      if (errorInfo.errorFields[0].name[0] === 'name') {
        notifyError(getText('ERR_CAMPAIGN_NAME_IS_REQUIRED'))
      } else {
        notifyError(getTextServerError(errorInfo.errorFields[0].errors[0]))
      }
    }
  }

  function renderSelectedAutoReplyPanel(fields) {
    const matchingField = fields.find((field, index) => index === getSelectedIndex)
    if (matchingField) {
      return !selectedCalendarSequence ? (
        <LoadingSpinner style={{ width: '100%' }} />
      ) : (
        <>
          <div className='same-day-reminder-switch'>
            <MatSwitchSecondary
              label={getText(
                'TEXT_DO_NOT_SEND_REMINDER_IF_APPOINTMENT_IS_CREATED_ON_SAME_DAY'
              )}
              value={doNotSendReminderOnSameDay}
              onChange={(value) => {
                setDoNotSendReminderOnSameDay(value)
              }}
            />
          </div>
          <CalendarInvitePanel
            form={form}
            field={matchingField.name}
            selectedCalendarSequence={selectedCalendarSequence}
            setSelectedCalendarSequence={setSelectedCalendarSequence}
            selectedIndex={getSelectedIndex}
            languages={languages}
          />
        </>
      )
    }
  }

  return !selectedCalendarSequence ? (
    <LoadingSpinner />
  ) : (
    <OrganizationLayout
      className={
        'aibot-template-edit-organization-page location-calendar-invites-wrapper'
      }
    >
      <OrganizationListTitle title={getText('TEXT_CALENDAR_INVITE')} />
      <MatForm
        className='create-drip-campaign'
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{}}
      >
        <Form.List name='calendar_invite_sequence'>
          {(fields, { add, remove }) => {
            const fieldList = form.getFieldValue('calendar_invite_sequence')
            return (
              <>
                <div className='auto-reply-items-list-wrapper'>
                  <MatBlockWithLabel className='auto-reply-items-list'>
                    {fields.map((field, index) => {
                      const item = form.getFieldValue('calendar_invite_sequence')[
                        field.name
                      ]
                      return (
                        <>
                          <CalendarInviteItem
                            key={index}
                            index={index}
                            listLength={fieldList.length}
                            item={item}
                            isActive={index === getSelectedIndex}
                            deviceCheck={deviceCheck}
                            onClick={() => {
                              if (item._id) {
                                item.id = item._id
                              }
                              if (!item.id) {
                                item.id = new Date().getTime()
                              }
                              setSelectedCalendarSequence(item)
                            }}
                            onDelete={() => {
                              remove(index)
                              const autoMessagesList = form.getFieldValue(
                                'calendar_invite_sequence'
                              )
                              setSelectedCalendarSequence(autoMessagesList[0])
                            }}
                          />
                          {fieldList.length === 1 && (
                            <MatBlockWithLabel
                              black
                              className='confirmation-and-reminders-item calendar-reminder-first-item'
                              label={getText('WORD_REMINDERS')}
                            />
                          )}
                        </>
                      )
                    })}
                  </MatBlockWithLabel>
                  {fieldList && fieldList.length < 6 && (
                    <MatButton
                      className='add-aibot-reply-item'
                      htmlType={'button'}
                      onClick={() => {
                        const newAutoReply = {
                          delay:
                            fieldList.length < 2
                              ? { unit: 'hours', value: 2 }
                              : { unit: 'days', value: 1 },
                          send_via: 'sms',
                          text: Object.assign(
                            {},
                            ...languages.map((lng) => ({ [lng]: '' }))
                          ),
                          image: Object.assign(
                            {},
                            ...languages.map((lng) => ({ [lng]: '' }))
                          ),
                          isNewMessage: true,
                          id: new Date().getTime(),
                        }
                        add(newAutoReply)
                        setSelectedCalendarSequence(newAutoReply)
                      }}
                      buttonText={
                        <>
                          <div className='plus-circle'>+</div>{' '}
                          {getText('WORD_ADD_NEW_DRIP')}
                        </>
                      }
                    />
                  )}
                </div>
                {renderSelectedAutoReplyPanel(fields)}
              </>
            )
          }}
        </Form.List>
      </MatForm>
    </OrganizationLayout>
  )
}

export default CalendarInvite
