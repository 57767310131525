import React from 'react'
import { Form, Switch } from 'antd'
import MatRow from '../../MatRow'
import './SwitchForm.scss'

const FormItem = (props) => {
  return (
    <Form.Item
      className={`mat-form-item ${props.formClassName || ''}`}
      label={props.label}
      name={props.name}
      layout={props.layout}
      valuePropName='checked'
      hidden={props.hidden}
    >
      <Switch
        disabled={props.disabled}
        className={`mat-switch-secondary ${props.ClassName || ''}`}
        onChange={(e) => {
          props.onChange && props.onChange(e)
        }}
      />
    </Form.Item>
  )
}

const SwtichFormSecondary = (props) => {
  return props.text && !props.hidden ? (
    <MatRow
      style={props.rowStyle}
      className={`switch-row switch-row-secondary ${props.rowClassName || ''}`}
    >
      <FormItem {...props} />
      <div className='text-with-icon'>
        {props.icon && props.icon} {props.text}
      </div>
    </MatRow>
  ) : (
    <FormItem {...props} />
  )
}

SwtichFormSecondary.defaultProps = {
  name: 'booleanSwichValue',
  type: 'boolean',
  layout: 'vertical',
}

export default SwtichFormSecondary
