import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getText } from '../../../lang'
import { formatAmount, formatDateTime } from '../../../utils'
import SVGArrowLeft from '../../../icons/SVG/SVGArrowLeft'
import { TABLE_DEFAULT_ROW_NUMBER } from '../../../devOptions'
import MatTable from '../../../components/MatTable'
import PaymentListItemTitle from '../PaymentListItemTitle'
import PaymentPageLayout from '../PaymentPageLayout'
import DepositDetailsListItems from './DepositDetailsListItems'

const DepositDetails = (props) => {
  const location = useLocation()
  const navigate = useNavigate()
  let depositDetails = location.state

  const [page, setPage] = useState(0)

  const scheduledDepositsColumn = [
    {
      title: getText('WORD_TYPE'),
      dataIndex: 'type',
      sorter: (a, b) => a.type - b.type,
      render: (_, { type }) => {
        return type
      },
    },
    {
      title: getText('WORD_CUSTOMER'),
      dataIndex: 'customer',
      sorter: (a, b) => {
        if (a.customer < b.customer) {
          return -1
        }
        if (a.customer > b.customer) {
          return 1
        }
        return 0
      },
      render: (_, { customer }) => {
        return customer
      },
    },
    {
      title: getText('WORD_AMOUNT'),
      dataIndex: 'amount',
      sorter: (a, b) => a.amount - b.amount,
      render: (_, { amount }) => {
        return <span className='payment-amount'>{formatAmount(amount)}</span>
      },
    },
    {
      title: getText('WORD_FEE'),
      dataIndex: 'fees',
      sorter: (a, b) => a.fees - b.fees,
      render: (_, { fees }) => {
        return formatAmount(fees)
      },
    },
    {
      title: getText('WORD_REFERENCE'),
      dataIndex: 'reference',
      render: (_, { reference }) => {
        return (
          <span className='payment-grey-item'>
            {reference && reference.toUpperCase()}
          </span>
        )
      },
    },
    {
      title: getText('WORD_DATE_CREATED'),
      dataIndex: 'date',
      sorter: (a, b) => {
        if (a._id < b._id) {
          return -1
        }
        if (a._id > b._id) {
          return 1
        }
        return 0
      },
      render: (_, { date }) => {
        return (
          <span className='payment-grey-item'>
            {date ? formatDateTime(date) : ''}
          </span>
        )
      },
    },
  ]

  return (
    <PaymentPageLayout>
      <PaymentListItemTitle
        icon={
          <SVGArrowLeft
            color={'#474657'}
            onClick={() => {
              navigate('/payments?type=deposits')
            }}
          />
        }
        title={getText('WORD_DEPOSIT_DETAILS')}
      />
      <DepositDetailsListItems item={depositDetails} />
      <MatTable
        columns={scheduledDepositsColumn}
        dataSource={[]}
        defaultPageSize={TABLE_DEFAULT_ROW_NUMBER}
        paginationCurrentPage={page}
        paginationTotalCount={4}
        onPaginationChange={setPage}
        withoutPagination
        title={() => getText('WORD_DEPOSIT_BREAKDOWN')}
        scroll={{
          x: 700,
        }}
      />
    </PaymentPageLayout>
  )
}

export default DepositDetails
