import React from 'react'
import { Table, Tooltip } from 'antd'
import { useNavigate } from 'react-router-dom'
import { Textfit } from 'react-textfit'
import broadcastingActions from '../../../store/modules/broadcastingActions'
import { getText } from '../../../lang'
import { formatDateTime, getKValue, getLongName } from '../../../utils'
import SVGArrowDown from '../../../icons/SVG/SVGArrowDown'
import SVGDownloadIconNew from '../../../icons/SVG/SVGDownloadIconNew'
import SVGReviewCheck from '../../../icons/SVG/SVGReviewCheck'
import MatTable from '../../../components/MatTable'
import MatRow from '../../../components/MatRow'
import { TABLE_DEFAULT_ROW_NUMBER } from '../../../devOptions'
import {
  getBroadcastTags,
  getBroadcastAssignedUsers,
  getBroadcastLogicalTags,
  getBroadcastTouchpoints,
  getBroadcastLanguage,
} from './broadcastListUtils'
import BroadcastListDesktopItem from './BroadcastListDesktopItemsComponents/BroadcastListDesktopItem'
import BroadcastListDesktopItemExcel from './BroadcastListDesktopItemsComponents/BroadcastListDesktopItemExcel'
import BroadcastListDesktopItemMessage from './BroadcastListDesktopItemsComponents/BroadcastListDesktopItemMessage'
import BroadcastListDesktopItemTags from './BroadcastListDesktopItemsComponents/BroadcastListDesktopItemTags'
import BroadcastListDesktopItemLogicalTags from './BroadcastListDesktopItemsComponents/BroadcastListDesktopItemLogicalTags'
import BroacastListDesktopItemWorkflow from './BroadcastListDesktopItemsComponents/BroacastListDesktopItemWorkflow'

const BroadcastListDesktop = ({
  loadingData,
  listData,
  page,
  setPage,
  totalCount,
  getStatus,
  onFilterName,
  setListData,
}) => {
  const navigate = useNavigate()

  const columns = [
    {
      title: getText('TEXT_NAME_OF_BROADCAST'),
      dataIndex: 'name',
      width: 200,
      sorter: true,
      render: (_, { name }) => {
        return (
          <div className='info-item-broadcast'>
            <h3>{name}</h3>
          </div>
        )
      },
    },
    {
      title: getText('WORD_STATUS'),
      dataIndex: 'status',
      width: 130,
      render: (_, { status, action_time, canceled, _id }) => {
        return (
          <div className='info-item-broadcast'>
            {getStatus(status, action_time, canceled, _id)}
          </div>
        )
      },
    },
    {
      title: getText('WORD_CREATED_BY'),
      dataIndex: 'who_created',
      render: (_, { who_created }) => {
        return (
          <div className='info-item-broadcast'>
            <span>{Boolean(who_created) && who_created.fullName}</span>
          </div>
        )
      },
    },
    {
      title: getText('WORD_EXECUTION_DATE_AND_TIME'),
      align: 'center',
      dataIndex: 'action_time',
      render: (_, { action_time }) => {
        return (
          <div className='info-item-broadcast center'>
            {/* <span className='white-space-nowrap-item'> */}
            <span> {formatDateTime(action_time)}</span>
            {/* </span> */}
          </div>
        )
      },
    },
    {
      title: getText('WORD_AUDIENCE'),
      dataIndex: 'total_customers_count',
      align: 'center',
      render: (_, { total_customers_count, emptyMessage }) => {
        return (
          <div className='info-item-broadcast center'>
            {emptyMessage ? (
              <Tooltip title={emptyMessage}>
                <h4 className='audience-label-empty'>{getText('WORD_EMPTY')}</h4>
              </Tooltip>
            ) : (
              <h4>{getKValue(total_customers_count)}</h4>
            )}
          </div>
        )
      },
    },
    {
      title: getText('WORD_DELIVERED'),
      dataIndex: 'reached_count',
      align: 'center',
      render: (_, { reached_count }) => {
        return (
          <div className='info-item-broadcast center'>
            <h4>{getKValue(reached_count)}</h4>
          </div>
        )
      },
    },
    {
      title: getText('WORD_REPLY'),
      dataIndex: 'responded_count',
      align: 'center',
      render: (_, item) => {
        const responded_count = item.responded_count
        return (
          <Textfit
            style={{ cursor: 'pointer' }}
            className='info-item-blue center'
            forceSingleModeWidth={true}
            max={14}
            mode='single'
            onClick={() => {
              navigate('/messages?type=all', {
                state: {
                  broadcast: item,
                  reply: true,
                },
              })
            }}
          >
            {getKValue(responded_count)}
          </Textfit>
        )
      },
    },
    {
      title: getText('WORD_OPTED_OUT'),
      dataIndex: 'opted_out_count',
      align: 'center',
      render: (_, { opted_out_count, optedOuts, id, name }) => {
        return (
          <div className='info-item-opted-out center'>
            <h4>{getKValue(opted_out_count)}</h4>
            {optedOuts.length > 0 && (
              <SVGDownloadIconNew
                onClick={async () => {
                  await broadcastingActions.downloadOptedOutCustomer(id, name)
                }}
              />
            )}
          </div>
        )
      },
    },
    {
      title: getText('WORD_OPPORTUNITIES'),
      dataIndex: 'opportunity_count',
      align: 'center',
      render: (_, item) => {
        const opportunity_count = item.opportunity_count
        return (
          <Textfit
            style={{ cursor: 'pointer' }}
            className='info-item-blue center'
            forceSingleModeWidth={true}
            max={14}
            mode='single'
            onClick={() => {
              navigate('/messages?type=all', {
                state: {
                  broadcast: item,
                  opportunities: true,
                },
              })
            }}
          >
            {getKValue(opportunity_count)}
          </Textfit>
        )
      },
    },
    {
      title: getText('WORD_RESPONSE'),
      dataIndex: 'responded_count',
      align: 'center',
      render: (_, { responded_count, reached_count }) => {
        let per = (responded_count / reached_count) * 100
        return (
          <div className='info-item-broadcast center'>
            <div className='percentage-wrapper'>
              <h4>{!isNaN(per) ? parseFloat(per.toFixed(2)) + '%' : '0%'}</h4>
            </div>
          </div>
        )
      },
    },
    Table.EXPAND_COLUMN,
  ]

  return (
    <MatTable
      sticky={{
        offsetHeader: 0,
      }}
      rowKey='_id'
      columns={columns}
      loading={loadingData}
      dataSource={listData}
      className='broadcast-list-table'
      defaultPageSize={TABLE_DEFAULT_ROW_NUMBER}
      paginationCurrentPage={page}
      paginationTotalCount={totalCount}
      onPaginationChange={setPage}
      onChange={(paginate, filter, sort) => {
        if (sort.order === 'ascend' && sort.field === 'name') {
          onFilterName && onFilterName({ sortByName: 'asc' })
        } else if (sort.order === 'descend' && sort.field === 'name') {
          onFilterName && onFilterName({ sortByName: 'desc' })
        } else {
          onFilterName && onFilterName({ sortByName: '' })
        }
      }}
      expandable={{
        expandIcon: ({ expanded, onExpand, record }) =>
          expanded ? (
            <div
              className='broadcast-list-table-expand-icon opened'
              onClick={(e) => onExpand(record, e)}
            >
              <SVGArrowDown />
            </div>
          ) : (
            <div
              onClick={(e) => onExpand(record, e)}
              className='broadcast-list-table-expand-icon'
            >
              <SVGArrowDown />
            </div>
          ),
        expandedRowRender: (row) => {
          return (
            <div className='broadcast-more-info'>
              <MatRow flexStart spaceBetween>
                <BroadcastListDesktopItemMessage
                  status={row.status}
                  action_time={row.action_time}
                  canceled={row.canceled}
                  message={row.message}
                  excelDoc={row.excelDoc}
                  _id={row._id}
                  setListData={setListData}
                  media={row.media}
                />
                {row.location && (
                  <BroadcastListDesktopItem
                    title={getText('WORD_LOCATION')}
                    rowName={row.location.name}
                  />
                )}
                {row.excelDoc && (
                  <BroadcastListDesktopItemExcel
                    title={getText('WORD_UPLOADED_FILE')}
                    rowName={
                      row.excelDoc.endsWith('.csv')
                        ? getText('WORD_CSV_FILE')
                        : getText('WORD_EXCEL_FILE')
                    }
                    href={row.excelDoc}
                  />
                )}
                <BroadcastListDesktopItem
                  title={getText('WORD_OPTED_IN')}
                  rowName={getText(!row.send_consent ? 'WORD_YES' : 'WORD_NO')}
                  icon={
                    !row.send_consent ? (
                      <SVGReviewCheck
                        width={14}
                        height={14}
                        style={{ marginRight: 6 }}
                      />
                    ) : null
                  }
                />
                {getBroadcastLanguage(row) &&
                  getBroadcastLanguage(row).length > 0 && (
                    <BroadcastListDesktopItem
                      title={getText('WORD_LANGUAGE')}
                      rowName={getBroadcastLanguage(row).map((lang) =>
                        getLongName(lang)
                      )}
                    />
                  )}
              </MatRow>
              <MatRow flexStart spaceBetween className='second-row-with-border-top'>
                {Boolean(row.days_to_skip) && (
                  <BroadcastListDesktopItem
                    title={getText(
                      'TEXT_EXCLUDE_CUSTOMERS_WHO_HAVE_BEEN_MESSAGED_IN_THE_LAST'
                    )}
                    rowName={
                      <>
                        {row.days_to_skip || 0} {getText('WORD_DAYS').toLowerCase()}
                      </>
                    }
                  />
                )}
                {getBroadcastTags(row) && getBroadcastTags(row).length > 0 && (
                  <BroadcastListDesktopItemTags
                    title={getText('WORD_TAGS')}
                    arrayList={getBroadcastTags(row)}
                  />
                )}
                {getBroadcastLogicalTags(row) &&
                  getBroadcastLogicalTags(row).length > 0 && (
                    <BroadcastListDesktopItemLogicalTags
                      title={getText('WORD_TAGS')}
                      arrayList={getBroadcastLogicalTags(row)}
                    />
                  )}
                {getBroadcastAssignedUsers(row) &&
                  getBroadcastAssignedUsers(row).length > 0 && (
                    <BroadcastListDesktopItem
                      title={getText('WORD_ASSIGNED_TO')}
                      rowName={getBroadcastAssignedUsers(row).map(
                        (user, index, arr) => {
                          return arr.length - 1 === index
                            ? `${user.fullName}`
                            : `${user.fullName}, `
                        }
                      )}
                    />
                  )}
                {getBroadcastTouchpoints(row) &&
                  getBroadcastTouchpoints(row).length > 0 && (
                    <BroadcastListDesktopItemTags
                      title={getText('WORD_TOUCHPOINTS')}
                      arrayList={getBroadcastTouchpoints(row)}
                    />
                  )}
                {row.workflow && (
                  <BroacastListDesktopItemWorkflow
                    name={row.workflow.name}
                    workflowData={row.workflowData}
                  />
                )}
              </MatRow>
            </div>
          )
        },
      }}
      scroll={{
        x: 1190,
      }}
    />
  )
}

export default BroadcastListDesktop
