import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width={20}
    height={20}
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M17.8629 2.15161C17.4461 1.72379 16.8291 1.56441 16.2539 1.73218L2.84008 5.6329C2.23316 5.80151 1.80299 6.28553 1.68711 6.90042C1.56872 7.52621 1.98223 8.32062 2.52245 8.65281L6.71667 11.2306C7.14684 11.4949 7.70207 11.4286 8.05805 11.0696L12.8608 6.23688C13.1026 5.98522 13.5028 5.98522 13.7445 6.23688C13.9863 6.48015 13.9863 6.87441 13.7445 7.12607L8.93341 11.9596C8.57659 12.3178 8.5099 12.8756 8.77251 13.3085L11.3352 17.5448C11.6353 18.0481 12.1522 18.3333 12.7191 18.3333C12.7858 18.3333 12.8608 18.3333 12.9275 18.3249C13.5778 18.241 14.0947 17.7964 14.2864 17.1673L18.2631 3.77062C18.4381 3.20019 18.2797 2.57943 17.8629 2.15161Z'
      fill='#A3A1B7'
    />
  </svg>
)

const SVGMagicSmsIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGMagicSmsIcon
