import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Menu } from 'antd'
import { getText } from '../../lang'
import SVGSideNavigationChangePassword from '../../icons/SVG/SVGSideNavigationChangePassword'
import SVGSideNavigationNotafSettings from '../../icons/SVG/SVGSideNavigationNotafSettings'
import SVGSideNavigationSettingsIcon from '../../icons/SVG/SVGSideNavigationSettingsIcon'
import SVGSideNavigatorSettingTitleIcon from '../../icons/SVG/SVGSideNavigatorSettingTitleIcon'
import './Profile.scss'

const SideNavigation = (props) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [current, setCurrent] = useState(location.pathname)
  const menuItems = [
    {
      key: '/profile/edit',
      label: getText('WORD_PROFILE_INFORMATION'),
      icon: <SVGSideNavigationSettingsIcon />,
    },
    {
      key: '/profile/change-password',
      label: getText('ACTION_CHANGE_PASSWORD'),
      icon: <SVGSideNavigationChangePassword />,
    },
    {
      key: '/profile/notification',
      label: getText('WORD_NOTIFICATION_SETTINGS'),
      icon: <SVGSideNavigationNotafSettings />,
    },
  ]

  useEffect(() => {
    if (location.pathname !== current) {
      setCurrent(location.pathname)
    }
  }, [location.pathname])

  return (
    <div className='side-menu-layout-wrapper'>
      <strong className='title-with-icon'>
        <div className='icon-wrapper'>{props.icon}</div>
        {props.title}
      </strong>
      <Menu
        className='side-bar-layout'
        mode='inline'
        forceSubMenuRender={true}
        onClick={(e) => {
          setCurrent(e.key)
          navigate(e.key)
        }}
        selectedKeys={[current]}
        items={menuItems}
      />
    </div>
  )
}

const ProfileMain = (props) => {
  return (
    <div className='profile-navigation-page'>
      <SideNavigation
        {...props}
        title={getText('ACTION_PROFILE')}
        icon={<SVGSideNavigatorSettingTitleIcon />}
      />
      <div className='profile-navigation-page-container'>
        {props.children && React.cloneElement(props.children, { ...props })}
      </div>
    </div>
  )
}

export default ProfileMain
