import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_15469_353911)'>
        <path
          d='M18.3337 10.0001V15.0001C18.3337 16.841 16.8413 18.3334 15.0003 18.3334H5.00033C3.15938 18.3334 1.66699 16.841 1.66699 15.0001V5.00008C1.66699 3.15913 3.15938 1.66675 5.00033 1.66675H10.0003M18.3353 6.66675V1.66675M18.3353 1.66675H13.3353M18.3353 1.66675L9.16862 10.8334'
          stroke='#5B66EA'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_15469_353911'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

const SVGMaximizeIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGMaximizeIcon
