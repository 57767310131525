import React, { forwardRef, memo, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getText } from '../../../../lang'
import locationActions from '../../../../store/modules/locationActions'
import MatSelect from '../../../../components/MatSelect'

const MessageSelectLocation = forwardRef((props, ref) => {
  const { idLocation } = props

  const [selectedLocation, setSelectedLocation] = useState()
  const [listData, setListData] = useState([])
  const [loadingData, setLoadingData] = useState(false)
  const [totalRows, setTotalRows] = useState(0)
  const [page, setPage] = useState(0)
  const [perPage] = useState(10)

  let organization = useSelector((state) => {
    return state.authReducer.user.organization
  })

  const loadSelecedLocation = useCallback(async () => {
    if (idLocation) {
      setLoadingData(true)
      let result = await locationActions.getOneLocationByConv_location_id(idLocation)
      setLoadingData(false)
      if (result.success) {
        setSelectedLocation(result.data)
        props.onGetItem && props.onGetItem(result.data)
      }
    } else {
      setSelectedLocation(null)
    }
  }, [idLocation])

  useEffect(() => {
    loadSelecedLocation()
  }, [loadSelecedLocation])

  useEffect(() => {
    const fill = async () => {
      setLoadingData(true)
      let result = await locationActions.getPageList(page, perPage, '', {}, true)
      setLoadingData(false)
      if (result.success && result.data && result.data.length > 0) {
        let list = result.data.map((oo) => {
          return { value: oo._id, label: oo.name, ...oo }
        })
        if (page > 0) {
          list = listData.concat(list)
        }
        setListData(list)
        setTotalRows(result.max)
      }
    }
    !props.disabled && fill()
  }, [page, perPage, organization])

  return (
    <MatSelect
      label={props.label}
      options={listData}
      loading={loadingData}
      onLoadMore={() => {
        if (listData.length !== totalRows) {
          setPage(page + 1)
        }
      }}
      disabled={props.disabled}
      onSelectItem={props.onSelectItem}
      value={selectedLocation ? selectedLocation.name : getText('WORD_LOCATION')}
      getPopupContainer={props.getPopupContainer}
    />
  )
})

export default memo(MessageSelectLocation)
