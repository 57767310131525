import React from 'react'
import { getText } from '../../../lang'
import MatButton from '../../../components/MatButton'
import MatRow from '../../../components/MatRow'

const LoginPageButtons = (props) => {
  const { viewChange, setViewChange, loading } = props

  return (
    <MatRow className='login-page-button-wrapper'>
      <MatButton
        buttonText={getText(viewChange ? 'ACTION_LOGIN' : 'ACTION_SEND_MAGIC_LINK')}
        typeButton={'white'}
        htmlType={'button'}
        onClick={() => {
          setViewChange && setViewChange(!viewChange)
        }}
        className='magic-link-btn'
      />
      <MatButton
        loading={loading}
        buttonText={getText(viewChange ? 'ACTION_SEND_MAGIC_LINK' : 'ACTION_LOGIN')}
        className='login-link-btn'
      />
    </MatRow>
  )
}

export default LoginPageButtons
