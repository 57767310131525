import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width={props.width || '18'}
    height={props.height || '18'}
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M16.8602 1.15198C16.4434 0.724157 15.8265 0.564773 15.2512 0.732546L1.83739 4.63326C1.23048 4.80187 0.800302 5.2859 0.684421 5.90079C0.566039 6.52658 0.979542 7.32098 1.51976 7.65317L5.71398 10.231C6.14416 10.4952 6.69938 10.429 7.05536 10.0699L11.8582 5.23724C12.0999 4.98558 12.5001 4.98558 12.7419 5.23724C12.9836 5.48051 12.9836 5.87478 12.7419 6.12644L7.93072 10.96C7.57391 11.3182 7.50721 11.876 7.76982 12.3089L10.3325 16.5451C10.6327 17.0484 11.1495 17.3337 11.7164 17.3337C11.7831 17.3337 11.8582 17.3337 11.9249 17.3253C12.5751 17.2414 13.092 16.7968 13.2837 16.1676L17.2604 2.77098C17.4354 2.20056 17.277 1.5798 16.8602 1.15198'
      fill={props.color || '#77808D'}
    />
  </svg>
)

const SVGSendMessageNew = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGSendMessageNew
