import React, { useEffect, useRef, useState } from 'react'
import { SketchPicker } from 'react-color'
import MatModal from '../MatModal'
import './MatColorPicker.scss'

const MatColorPicker = (props) => {
  const [isVisible, setIsVisible] = useState(false)
  const colorRef = useRef(props.color)

  useEffect(() => {
    colorRef.current = props.color
  }, [isVisible])

  return (
    <div
      className={`${props.label ? 'mat-form mat-form-item' : ''} ${
        props.black ? 'mat-form-item-black' : ''
      } ${props.className || ''}`}
    >
      {props.label && (
        <div className='ant-col ant-form-item-label'>
          <label>{props.label}</label>
        </div>
      )}
      <MatModal
        trigger={
          props.trigger ? (
            props.trigger
          ) : (
            <div
              className='mat-color-trigger'
              style={{
                backgroundColor: props.color,
              }}
            />
          )
        }
        visible={isVisible}
        onOk={(e) => {
          e.preventDefault()
          setIsVisible(false)
        }}
        width={300}
        onTrrigerClick={(e) => {
          setIsVisible(true)
        }}
        onCancel={(e) => {
          e.preventDefault()
          props.onChangeColor(colorRef.current)
          setIsVisible(false)
        }}
        okText='Set'
      >
        <div className='custom-color-picker'>
          <SketchPicker
            className={'mat-react-color'}
            color={props.color}
            onChangeComplete={(color) => {
              props.onChangeColor(color.hex)
            }}
          />
        </div>
      </MatModal>
    </div>
  )
}

export default MatColorPicker
