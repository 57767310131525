import React from 'react'
import { DatePicker } from 'antd'
import dayjs from 'dayjs'
import localeSP from 'antd/es/date-picker/locale/eu_ES'
import localEN from 'antd/es/date-picker/locale/en_US'
import localeFR from 'antd/es/date-picker/locale/fr_FR'
import 'dayjs/locale/fr'
import 'dayjs/locale/en'
import 'dayjs/locale/es'
import './MatRangePicker.scss'
import authActions from '../../store/modules/authActions'

const { RangePicker } = DatePicker

const MatRangePicker = (props) => {
  const {
    label,
    disabledRight,
    disabledLeft,
    onChange,
    onPanelChange,
    dropdownClassName,
    showNow,
    value,
    separator,
    allowEmpty,
    inputReadOnly,
    onCalendarChange,
    disabledDate,
    placeholder,
  } = props

  const userLang = authActions.getLanguage()

  return (
    <div className='mat-rangepicker-wrapper'>
      {label ? (
        <div className='ant-form-item-label'>
          <label>{label}</label>
        </div>
      ) : null}
      <RangePicker
        className={`mat-date-picker mat-range-picker ${props.className || ''}`}
        allowEmpty={allowEmpty}
        popupClassName={`mat-date-picker-dropdown ${dropdownClassName || ''}`}
        disabled={[disabledLeft, disabledRight]}
        onChange={onChange}
        onPanelChange={onPanelChange}
        locale={
          userLang === 'fr' ? localeFR : userLang === 'sp' ? localeSP : localEN
        }
        disabledDate={disabledDate}
        showNow={showNow}
        value={value}
        separator={separator || '-'}
        inputReadOnly={inputReadOnly}
        onCalendarChange={onCalendarChange}
        placeholder={placeholder}
      />
    </div>
  )
}

MatRangePicker.defaultProps = {
  label: 'Date',
  allowClear: true,
  placeholder: ['YYYY-MM-DD', 'YYYY-MM-DD'],
  mode: '',
  disabledDate: false,
  defaultValue: dayjs(),
}

export default MatRangePicker
