import React, { useEffect, useState, useRef, useCallback } from 'react'
import { VideoCameraOutlined, FilePdfOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { Form } from 'antd/es'
import { getText, getTextServerError } from '../../../../lang'
import { MEDIATYPES_IMG_VIDEO_PDF, getLongName } from '../../../../utils'
import InputFormTextArea from '../../../../components/Form/InputFormTextArea'
import InputFormText from '../../../../components/Form/InputFormText'
import SVGOrgSMSIcon from '../../../../icons/SVG/SVGOrgSMSIcon'
import SVGOrgEmailIcon from '../../../../icons/SVG/SVGOrgEmailIcon'
import SVGUploadExcel from '../../../../icons/SVG/SVGUploadExcel'
import RadioFormButton from '../../../../components/Form/RadioFormButton'
import MatRadioToggle from '../../../../components/MatRadioToggle'
import MatBlockWithLabel from '../../../../components/MatBlockWithLabel'
import AddVariableInMessage from '../../../../components/AddVariableInMessage'
import UploadFileForm from '../../../../components/Form/UploadFileForm'
import { uploadAndFetchPOStFile } from '../../../../utils/UrlHelper'
import { notifyError } from '../../../../utils/Notify'
import LoadingSpinner from '../../../../components/LoadingSpinner'
import SVGClearCircle from '../../../../icons/SVG/SVGClearCircle'
import { useSocket } from '../../../../utils/socket'
import { EMAIL, NIGHT, SMS } from '../../../../devOptions'
import MinimumTimeBetweenMessage from './MinimumTimeBetweenMessage'
import AutoReplyDelay from './AutoReplyDelay'

const AutoReplyPanel = (props) => {
  const {
    selectedAiBotMessage,
    setSelectedAiBotMessage,
    sequenceData,
    languages,
    triggeredOn,
    selectedIndex,
    form,
    field,
    type,
  } = props

  const [selectedLangTab, setSelectedLangTab] = useState(languages[0])
  const [currentCursorPosition, setCurrentCursorPosition] = useState(0)
  const [communicationMethod, setCommunicationMethod] = useState('')
  const textAreaInputRef = useRef()
  const subjectInputRef = useRef()

  const organization = useSelector((state) =>
    state.authReducer.user.organization ? state.authReducer.user.organization : {}
  )

  const getSequenceList = Form.useWatch('auto_reply_items', form)

  useEffect(() => {
    setSelectedLangTab(languages[0])
  }, [])

  useEffect(() => {
    setCommunicationMethod(selectedAiBotMessage.send_via)
  }, [selectedAiBotMessage.id])

  useEffect(() => {
    const messageContent = selectedAiBotMessage
      ? selectedAiBotMessage.text[selectedLangTab] ||
        (selectedAiBotMessage.subject &&
          selectedAiBotMessage.subject[selectedLangTab])
      : ''
    const messageLength = messageContent ? messageContent.length : 0
    setCurrentCursorPosition(messageLength)
  }, [selectedAiBotMessage.id, selectedLangTab])

  const handleClickVariable = (variable, isSubject = false) => {
    const autoMessagesList = form.getFieldValue('auto_reply_items')
    const fieldKey = isSubject ? 'subject' : 'text'
    const currentText = autoMessagesList[selectedIndex][fieldKey][selectedLangTab]
    const newText = [
      currentText && currentText.slice(0, currentCursorPosition),
      variable,
      currentText && currentText.slice(currentCursorPosition),
    ].join('')

    autoMessagesList[selectedIndex][fieldKey][selectedLangTab] = newText
    form.setFieldsValue({ auto_reply_items: autoMessagesList })
    setCurrentCursorPosition(currentCursorPosition + variable.length)

    const inputRef = isSubject ? subjectInputRef : textAreaInputRef
    inputRef.current &&
      inputRef.current.autoFocus(currentCursorPosition + variable.length)
  }

  const radioOptions = [
    {
      label: getText('WORD_SMS_UC'),
      value: SMS,
      icon: (
        <SVGOrgSMSIcon color={communicationMethod === SMS ? '#FFFFFF' : '#5B66EA'} />
      ),
      disabled:
        (sequenceData?.type === NIGHT || type === NIGHT) && selectedIndex === 1,
    },
    {
      label: getText('WORD_EMAIL'),
      value: EMAIL,
      icon: (
        <SVGOrgEmailIcon
          color={communicationMethod === EMAIL ? '#FFFFFF' : '#5B66EA'}
        />
      ),
      disabled:
        (sequenceData?.type === NIGHT || type === NIGHT) && selectedIndex === 1,
    },
  ]

  const listVariables = [
    {
      label: 'customer_first_name',
      value: ' {{customer_first_name}} ',
    },
    {
      label: 'customer_last_name',
      value: ' {{customer_last_name}} ',
    },
    {
      label: 'organization_name',
      value: ' {{organization_name}} ',
    },
    {
      label: 'location_name',
      value: ' {{location_name}} ',
    },
    {
      label: 'touchpoint',
      value: ' {{touchpoint}} ',
    },
    {
      label: 'year',
      value: ' {{year}} ',
    },
    {
      label: 'make',
      value: ' {{make}} ',
    },
    {
      label: 'model',
      value: ' {{model}} ',
    },
    {
      label: 'VIN',
      value: ' {{VIN}} ',
    },
    {
      label: 'trade_year',
      value: ' {{trade_year}} ',
    },
    {
      label: 'trade_make',
      value: ' {{trade_make}} ',
    },
    {
      label: 'trade_model',
      value: ' {{trade_model}} ',
    },
    {
      label: 'trade_VIN',
      value: ' {{trade_VIN}} ',
    },
    {
      label: 'offer_amount',
      value: ' {{offer_amount}} ',
    },
    {
      label: 'price_estimate',
      value: ' {{price_estimate}} ',
    },
  ]

  const langOptions = languages.map((lang) => {
    return {
      label: getLongName(lang),
      value: lang,
    }
  })

  const onReciveAiBotVideo = useCallback(
    (data) => {
      if (data.videoUpload.status === 'VIDEO_PROCESSING_SUCCEEDED') {
        let auto_reply_items = form.getFieldValue('auto_reply_items')
        let find = auto_reply_items.find(
          (item) => item._id === data.videoUpload.targetId
        )
        if (find) {
          Object.assign(find, {
            _video_upload_id: data.videoUpload._id,
            remove_video: false,
            loading: false,
          })
          form.setFieldsValue({ auto_reply_items })
          setSelectedAiBotMessage(find)
        }
      }
    },
    [form]
  )

  useSocket(
    `private-organization=${organization._id}`,
    'VIDEO_UPLOAD_STATUS_CHANGED',
    onReciveAiBotVideo
  )

  return (
    <div className='aibot-reply-item-panel-wrapper'>
      <RadioFormButton
        name={[field, 'send_via']}
        radioOptions={radioOptions}
        size='large'
        label={getText('HOW_WOULD_YOU_LIKE_TO_SEND_THIS_MESSAGE')}
        onChange={(e) => {
          if (
            getSequenceList?.length === 2 &&
            (sequenceData?.type === NIGHT || type === NIGHT) &&
            selectedIndex === 0
          ) {
            form.setFieldValue(
              ['auto_reply_items', 1, 'send_via'],
              e.target.value === SMS ? EMAIL : SMS
            )
          }
          setCommunicationMethod(e.target.value)
        }}
      />
      <MatBlockWithLabel label={getText('TEXT_SEND_FOLLOWUP')}>
        <AutoReplyDelay
          field={field}
          form={form}
          triggeredOn={triggeredOn}
          sequenceData={sequenceData}
          selectedMessage={selectedAiBotMessage}
          selectedIndex={selectedIndex}
          type={type}
        >
          <MatRadioToggle
            size='large'
            className='language-switch'
            value={selectedLangTab}
            onChange={(lang) => setSelectedLangTab(lang)}
            radioOptions={langOptions}
          />
        </AutoReplyDelay>
        {triggeredOn === 'activity' && selectedAiBotMessage.isFirstItem && (
          <MinimumTimeBetweenMessage
            field={field}
            selectedMessage={selectedAiBotMessage}
          />
        )}
      </MatBlockWithLabel>
      <MatBlockWithLabel
        icon={
          communicationMethod === EMAIL ? (
            <SVGOrgEmailIcon color='#5B66EA' />
          ) : (
            <SVGOrgSMSIcon color='#5B66EA' />
          )
        }
        label={
          communicationMethod === EMAIL
            ? getText('WORD_EMAIL')
            : getText('WORD_SMS_UC')
        }
      >
        {communicationMethod === EMAIL && (
          <>
            <InputFormText
              placeholder={getText('WORD_SUBJECT')}
              formStyle={{ marginBottom: '10px' }}
              initialValue={
                selectedLangTab &&
                selectedAiBotMessage.subject &&
                selectedAiBotMessage.subject[selectedLangTab]
                  ? {
                      [selectedLangTab]: selectedAiBotMessage.subject
                        ? selectedAiBotMessage.subject[selectedLangTab]
                        : '',
                    }
                  : ''
              }
              name={[field, 'subject', selectedLangTab]}
              onClick={(e) => setCurrentCursorPosition(e.target.selectionStart)}
              onKeyUp={(e) => setCurrentCursorPosition(e.target.selectionStart)}
              onChange={(e) => setCurrentCursorPosition(e.target.selectionStart)}
            />
            <AddVariableInMessage
              onClick={(variable) => handleClickVariable(variable, true)}
              data={listVariables}
            />
          </>
        )}
        <InputFormTextArea
          ref={textAreaInputRef}
          name={[field, 'text', selectedLangTab]}
          initialValue={{
            [selectedLangTab]: selectedAiBotMessage.text[selectedLangTab],
          }}
          formClassName='with-variables'
          placeholder={getText('WORD_MESSAGE')}
          allowClear={false}
          onClick={(e) => setCurrentCursorPosition(e.target.selectionStart)}
          onKeyUp={(e) => setCurrentCursorPosition(e.target.selectionStart)}
          onChange={(e) => setCurrentCursorPosition(e.target.selectionStart)}
        />
      </MatBlockWithLabel>
      <AddVariableInMessage
        onClick={(variable) => handleClickVariable(variable)}
        data={listVariables}
      />
      {selectedAiBotMessage.loading && (
        <div className='single-video'>
          <LoadingSpinner withoutHeight />
        </div>
      )}
      {(selectedAiBotMessage._video_upload_id && communicationMethod === SMS) ||
      (selectedAiBotMessage.media &&
        selectedAiBotMessage.media.length &&
        communicationMethod === SMS) ? (
        <div className='single-video'>
          <div
            className='img-close'
            onClick={() => {
              let getFieldItem = form
                .getFieldValue('auto_reply_items')
                .find((item) => item._id === selectedAiBotMessage._id)
              if (getFieldItem._video_upload_id) {
                getFieldItem.remove_video = true
                delete getFieldItem._video_upload_id
              }
              if (getFieldItem.media.length > 0) {
                getFieldItem.media = []
              }
              form.setFieldsValue({
                auto_reply_items: form.getFieldValue('auto_reply_items'),
              })
              setSelectedAiBotMessage(getFieldItem)
            }}
          >
            <SVGClearCircle />
          </div>
          {selectedAiBotMessage._video_upload_id ? (
            <VideoCameraOutlined
              style={{ backgroundColor: '#FFF', fontSize: '40px' }}
            />
          ) : selectedAiBotMessage.media[0].mediaContentType ===
            'application/pdf' ? (
            <FilePdfOutlined style={{ backgroundColor: '#FFF', fontSize: '40px' }} />
          ) : (
            <img src={selectedAiBotMessage.media[0].mediaUrl} alt={'upload'} />
          )}
        </div>
      ) : null}
      {!selectedAiBotMessage._video_upload_id &&
      !selectedAiBotMessage.loading &&
      !(selectedAiBotMessage.media && selectedAiBotMessage.media.length) &&
      communicationMethod === SMS ? (
        <UploadFileForm
          onStartUpload={(url) => {
            let auto_reply_items = form.getFieldValue('auto_reply_items')
            let find = auto_reply_items.find((item) => item._id === url._id)
            if (find) {
              Object.assign(find, { loading: true })
              form.setFieldsValue({
                auto_reply_items: auto_reply_items,
              })
              setSelectedAiBotMessage(find)
            }
          }}
          targerType={selectedAiBotMessage.isFirstItem ? 'sequence' : 'autoreply'}
          targetId={selectedAiBotMessage._id}
          className={'big-upload-wrapper'}
          showUploadList={false}
          mediaCount={1}
          onUpload={async (url) => {
            if (url.mediaContentType.includes('video')) {
              let newObj = {
                videoUploadId: url._id,
                status: 'succeeded',
              }
              let resultPost = await uploadAndFetchPOStFile(
                '/videos/process-video',
                newObj
              )
              if (!resultPost.success) {
                notifyError(getTextServerError(resultPost.errMsg))
              }
            } else {
              let auto_reply_items = form.getFieldValue('auto_reply_items')
              let find = auto_reply_items.find((item) => item._id === url._id)
              if (find) {
                Object.assign(find, {
                  loading: false,
                  media: [
                    {
                      mediaContentType: url.mediaContentType,
                      mediaUrl: url.mediaUrl,
                      url: url.url,
                    },
                  ],
                })
                form.setFieldsValue({
                  auto_reply_items: auto_reply_items,
                })
                setSelectedAiBotMessage(find)
              }
            }
          }}
          beforeUploadProps
          onChangeStatus={(string, obj) => {
            let auto_reply_items = form.getFieldValue('auto_reply_items')
            let find = auto_reply_items.find((item) => item._id === obj.targetId)
            if (find) {
              Object.assign(find, { loading: true })
              form.setFieldsValue({
                auto_reply_items: auto_reply_items,
              })
              setSelectedAiBotMessage(find)
            }
          }}
          listType={'text'}
          mediaTypes={MEDIATYPES_IMG_VIDEO_PDF}
          disabled={sequenceData === undefined || !selectedAiBotMessage._id}
          customType={[{ size: 1, type: 'application/pdf' }]}
        >
          <div className='big-upload-text'>
            <div className='big-upload-title'>
              <SVGUploadExcel />
              {sequenceData !== undefined && selectedAiBotMessage._id ? (
                <div className='no-file'>
                  {getText('TEXT_DRAG_A_FILE_HERE')} {getText('WORD_OR_LOW')}{' '}
                  <strong>{getText('WORD_BROWSE')}</strong>{' '}
                  {getText('TEXT_FOR_A_FILE_TO_UPLOAD')}
                </div>
              ) : (
                <div className='no-file'>
                  {getText(
                    'TEXT_YOU_ONLY_ABLE_UPLOAD_VIDEO_ONCE_THE_SEQUENCE_HAS_BEEN_SAVED'
                  )}
                </div>
              )}
            </div>
          </div>
        </UploadFileForm>
      ) : null}
    </div>
  )
}

export default AutoReplyPanel
