import React from 'react'
import { Space, Tooltip } from 'antd'

const BroadcastListMobileItem = ({
  title,
  infoItem,
  icon,
  tooltipMessage,
  tooltipMessageTitle,
}) => {
  return (
    <Space direction='vertical' className='broadcast-space-border-bottom'>
      <span className='info-item-mobile-title'>{title}</span>
      <div className='info-item'>
        {tooltipMessage ? (
          <Tooltip title={tooltipMessage}>
            <h4 className='audience-label-empty'>{tooltipMessageTitle}</h4>
          </Tooltip>
        ) : (
          <h4>
            {icon}
            {infoItem}
          </h4>
        )}
      </div>
    </Space>
  )
}

export default BroadcastListMobileItem
