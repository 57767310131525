import React from 'react'
import { Space } from 'antd'

const BroadcastListMobileItemBlue = ({ title, infoItem, onClick }) => {
  return (
    <Space direction='vertical' className='broadcast-space-border-bottom'>
      <span className='info-item-mobile-title'>{title}</span>
      <h3 className='info-item-blue' onClick={onClick}>
        {infoItem}
      </h3>
    </Space>
  )
}

export default BroadcastListMobileItemBlue
