import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width='12'
    height='10'
    viewBox='0 0 12 10'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M10.5606 0.605469L4.65281 6.51324L1.43941 3.29987L0 4.73928L4.65281 9.39209L12 2.04491L10.5606 0.605469Z'
      fill={props.color || '#A3A6BE'}
    />
  </svg>
)

const SVGSendMessageCheckedIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGSendMessageCheckedIcon
