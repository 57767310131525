import React from 'react'
import Icon from '@ant-design/icons'
import { MainColor } from '../../utils'

const SVGIcon = (props) => (
  <svg
    width={props.width || '16'}
    height={props.height || '16'}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_15470_18439)'>
      <path
        d='M11.333 2.22538C10.3524 1.65814 9.21397 1.3335 7.99967 1.3335C4.31778 1.3335 1.33301 4.31826 1.33301 8.00016C1.33301 11.6821 4.31778 14.6668 7.99967 14.6668C11.6816 14.6668 14.6663 11.6821 14.6663 8.00016C14.6663 7.17928 14.518 6.39305 14.2466 5.66683M4.66634 6.66683L7.01729 8.54759C7.56883 8.98882 8.36877 8.92339 8.84128 8.39838L13.9997 2.66683'
        stroke={props.color || MainColor}
        strokeLinecap='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_15470_18439'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

const SVGMessageConsentIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGMessageConsentIcon
