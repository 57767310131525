import React from 'react'
import Icon from '@ant-design/icons'
import { MainColor } from '../../utils'

const SVGIcon = (props) => {
  return (
    <svg
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.094 7.23333V9.6075C9.094 9.8175 9.07067 10.0158 9.01817 10.1967C8.80234 11.0542 8.09067 11.5908 7.11067 11.5908H5.524L3.76234 12.7633C3.49984 12.9442 3.14984 12.7517 3.14984 12.4367V11.5908C2.55484 11.5908 2.059 11.3925 1.71484 11.0483C1.36484 10.6983 1.1665 10.2025 1.1665 9.6075V7.23333C1.1665 6.125 1.85484 5.36083 2.9165 5.26167C2.99234 5.25583 3.06817 5.25 3.14984 5.25H7.11067C8.30067 5.25 9.094 6.04333 9.094 7.23333Z'
        fill={props.color || MainColor}
      />
      <path
        d='M10.354 9.10008C11.0948 9.10008 11.719 8.85508 12.1507 8.41758C12.5882 7.98592 12.8332 7.36175 12.8332 6.62092V3.64591C12.8332 2.27508 11.7248 1.16675 10.354 1.16675H5.39567C4.02484 1.16675 2.9165 2.27508 2.9165 3.64591V4.08342C2.9165 4.24675 3.04484 4.37508 3.20817 4.37508H7.11067C8.6915 4.37508 9.969 5.65258 9.969 7.23342V8.80842C9.969 8.97175 10.0973 9.10008 10.2607 9.10008H10.354Z'
        fill={props.color || MainColor}
      />
    </svg>
  )
}

const SVGFollowUpIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGFollowUpIcon
