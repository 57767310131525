import React, { useState } from 'react'
import SVGAISummaryIcon from '../../../../../icons/SVG/SVGAISummaryIcon'
import { getText } from '../../../../../lang'
import MatButton from '../../../../../components/MatButton'
import CenterAISummarizeModal from '../CenterMessageModal/CenterAISummarizeModal'

const CenterAISummerize = ({ conversation, aiSummaryMessage }) => {
  const [visible, setVisible] = useState(false)

  return (
    <>
      <CenterAISummarizeModal
        conversation={conversation}
        visible={visible}
        setVisible={setVisible}
      />
      {aiSummaryMessage && (
        <MatButton
          icon={<SVGAISummaryIcon />}
          size={'small'}
          buttonText={getText('WORD_SUMMARY')}
          typeButton={'white'}
          className={'ai_summary_button'}
          onClick={() => {
            setVisible(true)
          }}
          disabled={conversation.kind === 'gbm'}
        />
      )}
    </>
  )
}

export default CenterAISummerize
