import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='50'
    height='50'
    viewBox='0 0 50 50'
    fill='none'
  >
    <circle cx='25' cy='25' r='25' fill='#F1F3FD' />
    <path
      d='M21.3355 26.5087C20.637 26.5087 20.0696 25.9413 20.0696 25.2428C20.0696 24.5463 20.637 23.9789 21.3355 23.9789C22.034 23.9789 22.6015 24.5463 22.6015 25.2428C22.6015 25.9413 22.034 26.5087 21.3355 26.5087Z'
      fill='#3851D3'
    />
    <path
      d='M26.257 26.5087C25.5585 26.5087 24.991 25.9413 24.991 25.2428C24.991 24.5463 25.5585 23.9789 26.257 23.9789C26.9555 23.9789 27.5229 24.5463 27.5229 25.2428C27.5229 25.9413 26.9555 26.5087 26.257 26.5087Z'
      fill='#3851D3'
    />
    <path
      d='M29.9125 25.2428C29.9125 25.9413 30.48 26.5087 31.1785 26.5087C31.877 26.5087 32.4444 25.9413 32.4444 25.2428C32.4444 24.5463 31.877 23.9789 31.1785 23.9789C30.48 23.9789 29.9125 24.5463 29.9125 25.2428Z'
      fill='#3851D3'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.416 25.0176C14.416 18.9215 19.287 13.418 26.021 13.418C32.6061 13.418 37.5827 18.8155 37.5827 24.985C37.5827 32.1504 31.7275 36.5846 25.9993 36.5846C24.1027 36.5846 21.997 36.077 20.2886 35.0704L20.2784 35.0643C19.7092 34.7167 19.5237 34.6693 19.2888 34.7463L19.2687 34.7526L17.085 35.4011C16.5716 35.56 16.0281 35.4491 15.6463 35.0972C15.2543 34.7359 15.1038 34.186 15.2558 33.6379L15.2599 33.6237L15.9857 31.1934C15.9892 31.1816 15.9931 31.1699 15.9972 31.1582C16.0533 30.9994 16.0264 30.8833 15.9888 30.822C15.9821 30.811 15.9757 30.7999 15.9695 30.7886C14.9873 28.9814 14.416 26.9834 14.416 25.0176ZM26.021 14.918C20.1667 14.918 15.916 19.6977 15.916 25.0176C15.916 26.6923 16.4035 28.4407 17.2797 30.0579C17.5739 30.5526 17.5911 31.1334 17.4183 31.6384L16.7304 33.9416L18.8325 33.3173C19.781 33.0117 20.5217 33.4552 21.0551 33.7809C22.5092 34.6363 24.3446 35.0846 25.9993 35.0846C31.0178 35.0846 36.0827 31.2081 36.0827 24.985C36.0827 19.6084 31.7426 14.918 26.021 14.918Z'
      fill='#3851D3'
    />
  </svg>
)

const SVGSmsIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGSmsIcon
