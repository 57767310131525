import React from 'react'
import MatRow from '../../../components/MatRow'

const LeaderboardListFieldItem = (props) => {
  const { fieldValuesList } = props

  return (
    <div className='fields-wrapper'>
      {fieldValuesList &&
        fieldValuesList.map((item, index) => {
          return (
            <MatRow key={index} className={'field-item'} spaceBetween>
              <div className='field-item-left'>
                {item.icon && item.icon}
                {item.label && item.label}
              </div>
              <div className={'field-item-right'}>
                {item.rightItem && item.rightItem}
              </div>
            </MatRow>
          )
        })}
    </div>
  )
}

export default LeaderboardListFieldItem
