import React, { useEffect } from 'react'
import { Divider } from 'antd'
import { getText } from '../../../lang'
import InputFormEmailSecondary from '../../../components/Form/InputFormEmail/InputFormEmailSecondary'
import InputFormPhoneSecondary from '../../../components/Form/InputFormPhone/InputFormPhoneSecondary'

const LoginMagicLink = (props) => {
  const {
    form,
    setMagicLinkEmail,
    setMagicLinkPhone,
    magicLinkPhone,
    magicLinkEmail,
  } = props

  useEffect(() => {
    return () => setMagicLinkEmail('')
  }, [])
  return (
    <>
      <InputFormEmailSecondary
        name='email'
        label={getText('WORD_EMAIL')}
        onChange={(e) => {
          let value = e.target.value
          if (value && magicLinkPhone === '') {
            form.validateFields()
          }
          setMagicLinkEmail && setMagicLinkEmail(value)
        }}
        required={!magicLinkPhone}
        placeholder={getText('WORD_PLEASE_ENTER_YOUR_EMAIL')}
        message={
          magicLinkEmail === ''
            ? getText('WORD_PLEASE_ENTER_YOUR_EMAIL')
            : getText('TEXT_EMAIL_IS_NOT_VALID')
        }
      />
      <Divider style={{ margin: 0 }} orientation='center'>
        {getText('WORD_OR')}
      </Divider>
      <InputFormPhoneSecondary
        name='phone'
        label={getText('WORD_PHONE')}
        value={form.getFieldsValue().phone}
        onChange={(value) => {
          form.setFieldsValue({ phone: value })
          setMagicLinkPhone && setMagicLinkPhone(value)
        }}
        disableDropdown={false}
        initialCountry={'us'}
        required={!magicLinkEmail}
      />
    </>
  )
}

export default LoginMagicLink
