import React, { useEffect, useState } from 'react'
import { Modal } from 'antd'
import { useNavigate } from 'react-router-dom'
import { DEFAULT, NIGHT, TABLE_DEFAULT_ROW_NUMBER } from '../../../../devOptions'
import { getText, getTextServerError } from '../../../../lang'
import organizationActions from '../../../../store/modules/organizationActions'
import { notifyError, notifySuccess } from '../../../../utils/Notify'
import MatList from '../../../../components/MatList'
import OrganizationLayout from '../../components/organizationNewPage/OrganizationLayout'
import OrganizationListItem from '../../components/organizationNewPage/OrganizationListItem'
import OrganizationListTitle from '../../components/organizationNewPage/OrganizationListTitle'
import './DripSequenceTemplate.scss'
import MatButtonWithDropDown from '../../../../components/MatButtonWithDropDown/MatButtonWithDropDown'

const DripSequenceTemplate = (props) => {
  const { organization } = props

  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [perPage, setPerPage] = useState(TABLE_DEFAULT_ROW_NUMBER)
  const [sequencesTemplateData, setSequencesTemplateData] = useState({
    list: [],
    total: 0,
  })

  const isActivitySequencePresent =
    sequencesTemplateData &&
    sequencesTemplateData.list &&
    sequencesTemplateData.list.some((seq) => seq.triggeredOn === 'activity')
  const getSequencesData = async () => {
    setLoading(true)
    const result = await organizationActions.getPageListTemplateSequence(
      page,
      perPage
    )
    if (result.success) {
      setSequencesTemplateData({
        list: result.data,
        total: result.max,
      })
    }
    setLoading(false)
  }

  useEffect(() => {
    if (organization && organization._id) {
      getSequencesData()
    }
  }, [organization, page, perPage])

  const handleStoryEdit = (val, item) => {
    if (val === 'edit') {
      navigate(
        `/organization/settings/${organization._id}/dripsequence/template/edit`,
        {
          state: { sequenceData: item, isActivitySequencePresent },
        }
      )
    } else if (val === 'delete') {
      Modal.confirm({
        title: getText('TEXT_ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_SEQUENCE'),
        async onOk() {
          setLoading(true)
          const result = await organizationActions.deleteSequenceTemplate(item._id)
          if (result.success) {
            getSequencesData()
            notifySuccess(getText('TEXT_SEQUENCE_WAS_DELETED_SUCCESSFULLY'))
          } else {
            notifyError(getTextServerError(result.errMsg))
          }
        },
        okText: getText('WORD_YES'),
        cancelText: getText('ACTION_CANCEL'),
        okButtonProps: {
          type: 'primary',
          className: 'mat-btn mat-btn-primary',
        },
        cancelButtonProps: {
          type: 'cancel',
          className: 'mat-btn',
        },
      })
    }
  }

  return (
    <OrganizationLayout className={'ai-bot-templates-wrapper'}>
      <OrganizationListTitle
        title={getText('WORD_DRIP_SEQUENCE_TEMPLATES')}
        modalButton={
          <MatButtonWithDropDown
            options={[
              {
                label: (
                  <>
                    <span className='not-show'>
                      {getText('WORD_CREATE_WORKING_HOURS_SEQUENCE')}
                    </span>
                    <span className='default'>{getText('WORD_DEFAULT')}</span>
                  </>
                ),
                value: DEFAULT,
              },
              {
                label: (
                  <>
                    <span className='not-show'>
                      {getText('WORD_CREATE_AFTER_HOURS_SEQUENCE')}
                    </span>
                  </>
                ),
                value: NIGHT,
              },
            ]}
            onDropDownChange={(val) => {
              navigate(
                `/organization/settings/${organization._id}/dripsequence/template/create`,
                { state: { isActivitySequencePresent, type: val } }
              )
            }}
            buttonText={getText('WORD_CREATE_WORKING_HOURS_SEQUENCE')}
            onClick={() => {
              navigate(
                `/organization/settings/${organization._id}/dripsequence/template/create`,
                { state: { isActivitySequencePresent, type: DEFAULT } }
              )
            }}
          />
        }
      />
      <MatList
        loading={loading}
        data={sequencesTemplateData.list}
        pagination={true}
        defaultPageSize={TABLE_DEFAULT_ROW_NUMBER}
        paginationCurrentPage={page}
        paginationTotalCount={sequencesTemplateData.total}
        onPaginationChange={setPage}
        onShowSizeChange={setPerPage}
        containerClassName='organization-new-mat-list-wrapper'
        header={
          <div className='organization-new-mat-list-header'>
            <div className='organization-new-mat-list-header-item'>
              {getText('TEXT_NAME_OF_SEQUENCE')}
            </div>
            <div className='organization-new-mat-list-header-item'>
              {getText('WORD_TOUCHPOINTS')}
            </div>
            <div className='organization-new-mat-list-header-item' />
          </div>
        }
        renderItem={(item, index) => {
          return (
            <OrganizationListItem
              key={item._id + '' + index}
              item={item}
              listItem={item.sources_names}
              style={{
                backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#F8F9F9',
              }}
              handleEdit={handleStoryEdit}
            />
          )
        }}
      />
    </OrganizationLayout>
  )
}

export default DripSequenceTemplate
