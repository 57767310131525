import React, { useCallback, useEffect, useState } from 'react'
import { debounce } from 'lodash'
import paymentsActions from '../../store/modules/paymentsAction'
import { getText } from '../../lang'
import SVGSearch from '../../icons/SVG/SVGSearch'
import SVGAppointmentDateIcon from '../../icons/SVG/SVGAppointmentDateIcon'
import InputText from '../../components/InputText'
import MatRow from '../../components/MatRow'
import MatSelect from '../../components/MatSelect'
import MatPickerDate from '../../components/MatPickerDate'
import MatButton from '../../components/MatButton'
import utilDate from '../../utils/utilsDate'

const PaymentListHeader = (props) => {
  const { onFiltersChange, filterRef, options, tabActiveKey } = props
  const [searchInputValue, setSearchInputValue] = useState('')
  const [reportDateValue, setReportDateValue] = useState(
    utilDate.getYesterdayByDayJS()
  )
  const [dataInCSV, setDataInCSV] = useState('')
  const [createReport, setCreateReport] = useState('')
  const debouncedChange = useCallback(
    debounce(
      (options, specialOption) => onFiltersChange(options, specialOption),
      500
    ),
    []
  )

  const handleFitlersChange = (value, stateName, tab) => {
    if (tab === 'reports') {
      debouncedChange(value, tab === 'reports')
    } else {
      if (stateName === 'status') {
        debouncedChange({
          status: value,
          searchInputValue,
          group: options.group,
        })
      } else if (stateName === 'group') {
        debouncedChange({
          status: options.status,
          searchInputValue,
          group: value,
        })
      } else {
        setSearchInputValue(value)
        debouncedChange({
          status: options.status,
          searchInputValue: value,
          group: options.group,
        })
      }
    }
  }

  useEffect(() => {
    filterRef.current.resetFilters = () => {
      setSearchInputValue('')
    }
  }, [])

  useEffect(() => {
    ;(async () => {
      const result =
        await paymentsActions.downloadDailyTotalTransactionReport(reportDateValue)
      setDataInCSV(result.data)
    })()
  }, [reportDateValue])

  return tabActiveKey === 'payments' ? (
    <div className='payment-page-header-right-content'>
      <MatRow style={{ justifyContent: 'flex-end' }}>
        <div className='payment-download-daily-report'>
          <MatPickerDate
            suffixIcon={<SVGAppointmentDateIcon />}
            value={utilDate.getDateByDayJS(reportDateValue)}
            allowClear={false}
            inputReadOnly={true}
            disabledDate={(current) => {
              return current && current > utilDate.subtractDaysByDayJS()
            }}
            onChange={(e, val) => {
              setReportDateValue(val)
            }}
          />
          <a
            href={`data:text/csv;charset=utf-8,${dataInCSV}`}
            rel='noopener noreferrer'
            className='text'
            download={`totalFees-${reportDateValue}.csv`}
          >
            {getText('TEXT_DOWNLOAD_DAILY_TOTAL_TRANSACTION_REPORT')}
          </a>
        </div>
        <div className='inputWithSearch'>
          <SVGSearch />
          <InputText
            className='inputSearch'
            name='searchKeyword'
            placeholder={getText('WORD_SEARCH')}
            value={searchInputValue}
            onChange={(e) => handleFitlersChange(e.target.value, 'searchInputValue')}
          />
        </div>
        <MatSelect
          value={options.status}
          options={[
            { label: getText('WORD_ALL'), value: 'all' },
            { label: getText('WORD_PAID'), value: 'paid' },
            { label: getText('WORD_PENDING'), value: 'not_paid' },
            { label: getText('WORD_REFUNDED'), value: 'refunded' },
            { label: getText('WORD_REJECTED'), value: 'rejected' },
            { label: getText('WORD_CANCELED'), value: 'canceled' },
          ]}
          onSelect={(value) => handleFitlersChange(value, 'status')}
        />
        <MatSelect
          value={options.group}
          options={[
            { label: getText('WORD_NONE'), value: 'none' },
            { label: getText('WORD_DAY'), value: 'date' },
            { label: getText('WORD_MONTH'), value: 'month' },
          ]}
          onSelect={(value) => handleFitlersChange(value, 'group')}
        />
      </MatRow>
    </div>
  ) : tabActiveKey === 'reports' ? (
    <div className='payment-page-header-right-content'>
      <MatRow
        style={{ justifyContent: 'flex-end' }}
        className={'payment-page-header-right-content-reports'}
      >
        <MatSelect
          value={createReport}
          options={[
            { label: getText('WORD_SELECT_DATE_PERIOD'), value: '' },
            { label: getText('WORD_TODAY'), value: 'today' },
            { label: getText('WORD_YESTERDAY'), value: 'yesterday' },
            { label: getText('WORD_WEEK_TO_DAY'), value: 'week' },
            { label: getText('WORD_MONTH_TO_DAY'), value: 'month' },
            { label: getText('WORD_LAST_MONTH'), value: 'last_month' },
          ]}
          onSelect={(value) => setCreateReport(value)}
        />
        <MatButton
          buttonText={getText('WORD_CREATE_REPORT')}
          typeButton={'radius'}
          onClick={() => handleFitlersChange(createReport, 'status', 'reports')}
        />
      </MatRow>
    </div>
  ) : null
}

export default PaymentListHeader
