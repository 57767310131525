import React from 'react'
import { Tabs, Typography } from 'antd'
import MatSwitch from '../../../../../components/MatSwitch'
import { getLongName } from '../../../../../utils'
import { getText } from '../../../../../lang'
import InputFormTextAreaSecondary from '../../../../../components/Form/InputFormTextArea/InputFormTextAreaSecondary'

const { Title } = Typography

const StepHeader = ({
  step,
  index,
  handleShowBackActionChange,
  handleTitleChange,
  handleDescriptionChange,
  organization,
}) => {
  return (
    <div>
      <Title level={4}>Header Settings</Title>
      <MatSwitch
        value={step.showBackAction}
        onChange={(checked) => {
          handleShowBackActionChange(index, checked)
        }}
        label='Show Back Action'
      />
      <br />
      <br />
      <Tabs
        className='mat-tabs'
        items={organization.languages.map((lng, yIndex) => {
          return {
            label: getLongName(lng) + ' ' + getText('WORD_MESSAGES_LC'),
            key: yIndex,
            forceRender: true,
            children: (
              <>
                <InputFormTextAreaSecondary
                  label={getText('LABEL_TITLE')}
                  name={['title', lng]}
                  onChange={(e) => {
                    e.persist()
                    handleTitleChange(index, e.target.value, lng)
                  }}
                  message={getText('ERR_VALIDATION_REQUIRED')}
                  autoSize={false}
                  initialValue={step.title[lng]}
                />

                <InputFormTextAreaSecondary
                  label={getText('LABEL_DESCRIPTION')}
                  name={['description', lng]}
                  onChange={(e) => {
                    e.persist()
                    handleDescriptionChange(index, e.target.value, lng)
                  }}
                  message={getText('ERR_VALIDATION_REQUIRED')}
                  autoSize={false}
                  initialValue={step.description[lng]}
                />
              </>
            ),
          }
        })}
      />
      <hr />
    </div>
  )
}

export default StepHeader
