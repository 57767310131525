import React from 'react'

function SVGInstagram(props) {
  const SVGFile = (
    <svg width={30} height={30} viewBox='0 0 30 30' {...props}>
      <defs>
        <linearGradient
          id='prefix__a'
          x1={0.915}
          y1={0.068}
          x2={0.334}
          y2={0.915}
          gradientUnits='objectBoundingBox'
        >
          <stop offset={0} stopColor={props.color ? props.color : '#ffb140'} />
          <stop offset={0.256} stopColor={props.color ? props.color : '#ff5445'} />
          <stop offset={0.599} stopColor={props.color ? props.color : '#fc2b82'} />
          <stop offset={1} stopColor={props.color ? props.color : '#8e40b7'} />
        </linearGradient>
      </defs>
      <g data-name='Group 7421'>
        <g data-name='Group 7278'>
          <circle
            data-name='Ellipse 603'
            cx={15}
            cy={15}
            r={15}
            fill='url(#prefix__a)'
          />
        </g>
        <path data-name='Rectangle 3504' fill='none' d='M9 9h12v12H9z' />
        <path
          d='M18.923 11.8a.72.72 0 11-.72-.72.72.72 0 01.72.72zM15 17a2 2 0 112-2 2 2 0 01-2 2zm0-5.081A3.081 3.081 0 1018.081 15 3.081 3.081 0 0015 11.919zm0-1.838c1.6 0 1.792.006 2.424.035a3.319 3.319 0 011.114.207 1.859 1.859 0 01.69.449 1.858 1.858 0 01.449.69 3.321 3.321 0 01.207 1.114c.029.633.035.822.035 2.425s-.006 1.792-.035 2.424a3.319 3.319 0 01-.207 1.114 1.987 1.987 0 01-1.139 1.139 3.321 3.321 0 01-1.114.207c-.633.029-.822.035-2.424.035s-1.792-.006-2.425-.035a3.319 3.319 0 01-1.114-.207 1.859 1.859 0 01-.69-.449 1.859 1.859 0 01-.449-.69 3.321 3.321 0 01-.207-1.114c-.028-.633-.034-.825-.034-2.425s.006-1.792.035-2.425a3.319 3.319 0 01.207-1.114 1.859 1.859 0 01.449-.69 1.858 1.858 0 01.69-.449 3.321 3.321 0 011.114-.207c.632-.028.824-.034 2.424-.034zM15 9c-1.629 0-1.834.007-2.474.036a4.4 4.4 0 00-1.456.279 2.94 2.94 0 00-1.063.692 2.94 2.94 0 00-.692 1.063 4.4 4.4 0 00-.279 1.456C9.007 13.166 9 13.371 9 15s.007 1.834.036 2.474a4.4 4.4 0 00.279 1.456 2.94 2.94 0 00.692 1.063 2.941 2.941 0 001.063.692 4.4 4.4 0 001.456.279c.64.029.844.036 2.474.036s1.834-.007 2.474-.036a4.4 4.4 0 001.456-.279 3.068 3.068 0 001.755-1.755 4.4 4.4 0 00.279-1.456c.029-.64.036-.845.036-2.474s-.007-1.834-.036-2.474a4.4 4.4 0 00-.279-1.456 2.941 2.941 0 00-.692-1.063 2.941 2.941 0 00-1.063-.692 4.4 4.4 0 00-1.456-.279C16.834 9.007 16.629 9 15 9z'
          fill={props.color ? props.color : '#fff'}
          fillRule='evenodd'
        />
      </g>
    </svg>
  )
  if (!props.activeIcon) {
    return SVGFile
  }
  return (
    <div className={'mat-user-profile-letters'}>
      {props.activeIcon && <div className='active-icon' />}
      {SVGFile}
    </div>
  )
}

export default SVGInstagram
