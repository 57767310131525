import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='10' cy='10' r='10' fill='#FFF6D7' />
      <path
        d='M10.013 7.02366L10.0134 7.02336L10.0145 7.02257L10.0135 7.02387C10.4841 6.41425 11.2326 5.99659 11.9991 5.99659C13.3489 5.99659 14.4431 7.09059 14.4431 8.44054C14.4431 9.85546 13.6372 11.1904 12.3043 12.4126C11.8494 12.8295 11.3596 13.1953 10.8739 13.5237C10.7036 13.6384 10.5444 13.7424 10.4016 13.829C10.3627 13.8527 10.3296 13.8734 10.3018 13.8909C10.2675 13.9125 10.2411 13.9291 10.2211 13.9401C10.087 14.0147 9.91122 14.0147 9.77715 13.9401C9.7572 13.9291 9.73078 13.9125 9.69645 13.8909C9.66862 13.8734 9.63561 13.8527 9.59664 13.829C9.45389 13.7424 9.29465 13.6384 9.12439 13.5237C8.63862 13.1953 8.14882 12.8295 7.69391 12.4126C6.36107 11.1904 5.55519 9.85546 5.55519 8.44054C5.55519 7.09059 6.64936 5.99659 7.99913 5.99659C8.80781 5.99659 9.55366 6.40363 10.0126 7.02395L10.013 7.02366Z'
        fill='#FFC800'
        stroke='#FFC800'
        strokeWidth='0.001'
      />
    </svg>
  )
}

const SVGInterestIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGInterestIcon
