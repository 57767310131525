import * as React from "react"

function SVGFile(props) {
  return (
    <svg width={23.2} height={29} viewBox="0 0 23.2 29" {...props}>
      <path
        data-name="Path 5939"
        d="M22.528 7.357L15.843.672A2.27 2.27 0 0014.256 0h-12A2.294 2.294 0 000 2.259v24.482A2.254 2.254 0 002.259 29h18.682a2.254 2.254 0 002.259-2.259v-17.8a2.27 2.27 0 00-.672-1.584zm-1.282.549H16.3A1.016 1.016 0 0115.294 6.9V1.984zm-.305 19.781H2.259a.951.951 0 01-.946-.946V2.259a.938.938 0 01.946-.946h11.722V6.9a2.319 2.319 0 002.32 2.32h5.586v17.521a.951.951 0 01-.946.946z"
        fill="#a3a6be"
      />
    </svg>
  )
}

export default SVGFile
