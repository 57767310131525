import React, { Component } from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import { SpringSystem, MathUtil } from 'rebound'

export default class SpringScrollbars extends Component {
  constructor(props, ...rest) {
    super(props, ...rest)
    this.handleSpringUpdate = this.handleSpringUpdate.bind(this)
  }

  componentDidMount() {
    this.springSystem = new SpringSystem()
    this.spring = this.springSystem.createSpring()
    this.spring.addListener({ onSpringUpdate: this.handleSpringUpdate })
  }

  componentWillUnmount() {
    this.springSystem.deregisterSpring(this.spring)
    this.springSystem.removeAllListeners()
    this.springSystem = undefined
    this.spring.destroy()
    this.spring = undefined
  }

  scrollIntoView() {
    return this.refs.scrollbars.getScrollTop()
  }

  getScrollTop() {
    return this.refs.scrollbars.getScrollTop()
  }

  scrollTop(top) {
    return this.refs.scrollbars.scrollTop(top)
  }

  getScrollHeight() {
    return this.refs.scrollbars.getScrollHeight()
  }

  getHeight() {
    return this.refs.scrollbars.getHeight()
  }

  scrollTopAnimated(top) {
    const { scrollbars } = this.refs
    const scrollTop = scrollbars.getScrollTop()
    const scrollHeight = scrollbars.getScrollHeight()

    const val = MathUtil.mapValueInRange(
      top,
      0,
      scrollHeight,
      scrollHeight * 0.2,
      scrollHeight * 0.8
    )
    this.spring.setCurrentValue(scrollTop).setAtRest()
    this.spring.setEndValue(val)
  }

  handleSpringUpdate(spring) {
    const { scrollbars } = this.refs
    const val = spring.getCurrentValue()
    scrollbars.scrollTop(val)
  }

  render() {
    return <Scrollbars {...this.props} ref='scrollbars' />
  }
}
