import React from 'react'
import Icon from '@ant-design/icons'
import { MainColor } from '../../utils'

const SVGIcon = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={props.width || '7.785'}
      height={props.height || '7.784'}
      viewBox='0 0 7.785 7.784'
    >
      <path
        id='Shape_12'
        dataname='Shape 12'
        d='M41,143.406h5.921l-2.72-2.72.691-.686,3.892,3.892-3.892,3.892-.686-.686,2.715-2.72H41Z'
        transform='translate(-41 -140)'
        fill={props.color || MainColor}
      />
    </svg>
  )
}

const SVGArrowRight = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGArrowRight
