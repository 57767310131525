import React, { useState } from 'react'
import { Modal, Button } from 'antd'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import './Popup.scss'
import image from './cars.png'
import logo from './logo.svg'

function Popup({ withImage = false }) {
  const [visible, setVisible] = useState(false)
  return (
    <>
      <Button type='primary' onClick={() => setVisible(true)}>
        Open Modal of 1000px width
      </Button>
      <Modal
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        footer={null}
        closable={false}
        onCancel={() => setVisible(false)}
        width={withImage ? 816 : 489}
        className='popup_main-container'
      >
        <div className='popup_container'>
          <div className='popup_left-side'>
            <div>
              <p className='text-green'>Before you go...</p>
              <p className='popup_header'>We Want Your Car</p>
              <p className='popup_description'>
                Get a free evaluation today. We will always pay more for your
                vehicle.
              </p>
              <PhoneInput
                country={'us'}
                inputClass='popup_input'
                buttonClass='popup_input_flag'
                placeholder='(000) 000-0000'
              />
              <button className='popup_button'>Text Button</button>
              <p className='popup_terms'>
                I agree to receive news, updates &amp; other promotional information
                from Matador, and understand that I can withdraw it at any time.{' '}
                <a href>Privacy Policy</a>
              </p>
            </div>
            <div className={'popup_logo'}>
              <p>Powered by</p>
              <img src={logo} alt='logo' />
            </div>
          </div>
          {withImage && (
            <div className='popup_right-side'>
              <img className='img-fluid' src={image} alt='cars' />
            </div>
          )}
        </div>
      </Modal>
    </>
  )
}

export default Popup
