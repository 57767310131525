import React from 'react'
import SVGCouponPhoneIcon from '../../../../../../icons/SVG/SVGCouponPhoneIcon'
import SVGDrawerBigLogo from '../../../../../../icons/SVG/SVGDrawerBigLogo'
import SVGMatadorSmall from '../../../../../../icons/SVG/SVGMatadorSmall'
import SVGSwipeIcon from '../../../../../../icons/SVG/SVGSwipeIcon'
import { getText } from '../../../../../../lang'
import './CouponPreview.scss'

const CouponPreview = (props) => {
  const {
    theme,
    uploadFile,
    titleLang,
    titleEn,
    titleFr,
    titleSp,
    titleColor,
    descriptionLang,
    descriptionEn,
    descriptionFr,
    descriptionSp,
    descriptionColor,
    buttonBackgroundColor,
    swipeTextColor,
    termsLink,
    buttonLang,
    buttonColor,
    buttonEn,
    buttonFr,
    buttonSp,
    primaryBackgroundColor,
  } = props

  const getFooterTextSection = () => {
    if (titleLang === 'fr') {
      return (
        <>
          {'Assujetti aux'}
          <a
            className='normal-text'
            target='_blank'
            rel='noopener noreferrer'
            href={termsLink ? termsLink : 'https://matador.ai/'}
          >
            {getText('TERMS')}
          </a>
          {' et conditions.'}
        </>
      )
    } else if (titleLang === 'sp') {
      return (
        <>
          {'Sujeto a'}
          <a
            className='normal-text'
            target='_blank'
            rel='noopener noreferrer'
            href={termsLink ? termsLink : 'https://matador.ai/'}
          >
            {getText('TERMS')}
          </a>
          {' y condiciones.'}
        </>
      )
    } else {
      return (
        <>
          {getText('USE_AS_SUBJECT_TO')}
          <a
            className='normal-text'
            target='_blank'
            rel='noopener noreferrer'
            href={termsLink ? termsLink : 'https://matador.ai/'}
          >
            {getText('TERMS')}
          </a>
        </>
      )
    }
  }

  return (
    <div className='phone-icon-wrapper'>
      <div className='phone-icon'>
        <SVGCouponPhoneIcon />
        <div
          className={`phone-under-nav-wrapper`}
          style={{ backgroundColor: primaryBackgroundColor }}
        >
          <div className='nav-header-border'></div>
          <div className='logo-item'>
            {uploadFile && uploadFile.uploadImage ? (
              <img src={uploadFile.uploadImage} alt='upload_image' />
            ) : theme === 'dark' ? (
              <SVGDrawerBigLogo />
            ) : (
              <SVGMatadorSmall width={137} height={27.045} />
            )}
          </div>
          <div className='title-item'>
            {titleLang === 'en' && titleEn && (
              <div className='title' style={{ color: titleColor }}>
                {titleEn}
              </div>
            )}
            {titleLang === 'fr' && titleFr && (
              <div className='title' style={{ color: titleColor }}>
                {titleFr}
              </div>
            )}
            {titleLang === 'sp' && titleSp && (
              <div className='title' style={{ color: titleColor }}>
                {titleSp}
              </div>
            )}
          </div>
          <div className='note-item'>
            {descriptionLang === 'en' && descriptionEn && (
              <div className='note' style={{ color: descriptionColor }}>
                {descriptionEn}
              </div>
            )}
            {descriptionLang === 'fr' && descriptionFr && (
              <div className='note' style={{ color: descriptionColor }}>
                {descriptionFr}
              </div>
            )}
            {descriptionLang === 'sp' && descriptionSp && (
              <div className='note' style={{ color: descriptionColor }}>
                {descriptionSp}
              </div>
            )}
          </div>
          <div className='button-item'>
            <div
              className='swipe-to-start-button'
              style={{ backgroundColor: buttonBackgroundColor }}
            >
              <div className='icon-wrapper'>
                <SVGSwipeIcon color={buttonBackgroundColor} />
              </div>
              <div className='text' style={{ color: swipeTextColor }}>
                {buttonLang === 'en' && buttonEn && (
                  <div className='note' style={{ color: buttonColor }}>
                    {buttonEn}
                  </div>
                )}
                {buttonLang === 'fr' && buttonFr && (
                  <div className='note' style={{ color: buttonColor }}>
                    {buttonFr}
                  </div>
                )}
                {buttonLang === 'sp' && buttonSp && (
                  <div className='note' style={{ color: buttonColor }}>
                    {buttonSp}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='nav-footer'>
            <div className='footer-first-item'>
              {getText('WORD_BY')}
              {theme === 'dark' ? (
                <a
                  href='https://matador.ai/'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='app-name-dark'
                >
                  <SVGDrawerBigLogo />
                </a>
              ) : (
                <a
                  href='https://matador.ai/'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='app-name'
                >
                  <SVGMatadorSmall width={70.21} height={16.24} />
                </a>
              )}
            </div>
            <div className='footer-last-item'>
              {getFooterTextSection()} {getText('TEXT_MESSAGE_FEES_MAY_APPLY')}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CouponPreview
