import React from 'react'
import { Collapse, Space } from 'antd'
import { getText } from '../../../lang'
import { formatAmount, getNameFromFirstLastNames } from '../../../utils'
import SVGInfoIcon from '../../../icons/SVG/SVGInfoIcon'
import SVGArrowDown from '../../../icons/SVG/SVGArrowDown'
import { getCard, getStatus } from '../PaymentPageUtils'
import utilDate from '../../../utils/utilsDate'
import MatButton from '../../../components/MatButton'
import PaymentTimelineModal from './PaymentTimelineModal'

const PanelHeader = ({ item }) => {
  return (
    <div className='payment-panel-header'>
      <div className='payment-panel-header-top'>
        {item.receiver
          ? getNameFromFirstLastNames(
              item.receiver.fullName,
              item.receiver.firstName,
              item.receiver.lastName
            ) || item.receiver.phone
          : ''}{' '}
        {getStatus(item.status)}
      </div>
      <div className='payment-panel-header-bottom'>
        <span className='payment-amount'>{formatAmount(item.amount)}</span>
        <span className='payment-grey-item'>
          {item.updatedAt ? utilDate.getDateTimeForPayment(item.updatedAt) : ''}
        </span>
      </div>
    </div>
  )
}

const PaymentListCollapsePanel = (props) => {
  const { item, confirmRefund, cancelPayment, modalRef } = props

  return !item.transactionlist ? (
    <Collapse
      expandIcon={() => <SVGArrowDown />}
      expandIconPosition={'end'}
      className='payment-collapse'
      bordered={false}
      items={[
        {
          key: item._id,
          label: <PanelHeader item={item} />,
          children: (
            <>
              <Space>
                {getText('WORD_USER')}{' '}
                {item.user
                  ? getNameFromFirstLastNames(
                      item.user.fullName,
                      item.user.firstName,
                      item.user.lastName
                    ) || item.user.phone
                  : ''}
              </Space>
              <Space>
                {getText('WORD_REFERENCE')}{' '}
                {item.referenceNumber && item.referenceNumber.toUpperCase()}
              </Space>
              <Space>
                {getText('WORD_DATE_TIME') +
                  ' ' +
                  getText('WORD_CREATED').toLowerCase()}
                {item.createdAt && utilDate.getDateTimeForPayment(item.createdAt)}
              </Space>
              <Space>
                {getText('WORD_DATE_TIME') +
                  ' ' +
                  getText('WORD_UPDATED').toLowerCase()}
                {item.updatedAt && utilDate.getDateTimeForPayment(item.updatedAt)}
              </Space>
              <Space>
                {getText('WORD_NET')} {formatAmount(item.net_amount || 0)}
              </Space>
              <Space>
                {getText('WORD_FEE')}{' '}
                {formatAmount(item.application_fee_amount || 0)}
              </Space>
              <Space>
                {getText('WORD_CARD')} {getCard('master_card')}
              </Space>
              <Space className='collapse-item-buttons-wrapper'>
                {item.status === 'paid' ? (
                  <MatButton
                    style={{ height: '33px', fontSize: '12px' }}
                    buttonText={getText('WORD_REFUND')}
                    onClick={() => {
                      confirmRefund && confirmRefund(item)
                    }}
                  />
                ) : (
                  ''
                )}
                {item.receipt_urls && item.receipt_urls.length > 0 ? (
                  <MatButton
                    buttonText={getText('WORD_RECEIPT')}
                    typeButton={'white'}
                    style={{ height: '33px' }}
                    onClick={() => {
                      window.open(`${item.receipt_urls[0]}`, '_blank')
                    }}
                  />
                ) : (
                  ''
                )}
                {item.status === 'not_paid' ? (
                  <MatButton
                    style={{ height: '33px', fontSize: '12px' }}
                    buttonText={getText('ACTION_CANCEL')}
                    typeButton={'cancel'}
                    onClick={() => {
                      cancelPayment && cancelPayment(item)
                    }}
                  />
                ) : (
                  ''
                )}
                <PaymentTimelineModal
                  ref={modalRef}
                  item={item}
                  trigger={
                    <div className='pay-info-item'>
                      <SVGInfoIcon />
                    </div>
                  }
                />
              </Space>
            </>
          ),
        },
      ]}
    />
  ) : (
    <>
      <div className='payment-panel-header' style={{ marginBottom: '10px' }}>
        <div className='title'>
          {getText('WORD_TRANSACTION')}: {item._id}
        </div>
        <div className='title'>
          {getText('WORD_TOTAL_AMOUNT')}: {formatAmount(item.totalAmount)}
        </div>
        <div className='title'>
          {getText('WORD_TOTAL_NET_AMOUNT')}: {formatAmount(item.totalNetAmount)}
        </div>
        <div className='title'>
          {getText('WORD_TOTAL_FEES')}: {formatAmount(item.totalFees)}
        </div>
      </div>
      <Collapse
        expandIcon={() => <SVGArrowDown />}
        expandIconPosition={'end'}
        className='payment-collapse'
        bordered={false}
        items={item.transactionlist.map((oldItem) => {
          return {
            key: oldItem._id,
            forceRender: true,
            label: <PanelHeader item={oldItem} />,
            children: (
              <>
                <Space>
                  {getText('WORD_STATUS')} {getStatus(oldItem.status)}
                </Space>
                <Space>
                  {getText('WORD_CUSTOMER')}{' '}
                  {Boolean(oldItem.receiver) &&
                    (oldItem.receiver.fullName || oldItem.receiver.phone)}
                </Space>
                <Space>
                  {getText('WORD_AMOUNT')} {formatAmount(oldItem.amount)}
                </Space>
                <Space>
                  {getText('WORD_DATE_TIME') +
                    ' ' +
                    getText('WORD_CREATED').toLowerCase()}
                  {oldItem.createdAt &&
                    utilDate.getDateTimeForPayment(oldItem.createdAt)}
                </Space>
                <Space>
                  {getText('WORD_DATE_TIME') +
                    ' ' +
                    getText('WORD_UPDATED').toLowerCase()}
                  {oldItem.updatedAt &&
                    utilDate.getDateTimeForPayment(oldItem.updatedAt)}
                </Space>
                <Space>
                  {getText('WORD_REFERENCE')}{' '}
                  {oldItem.referenceNumber && oldItem.referenceNumber.toUpperCase()}
                </Space>
                <Space>
                  {getText('WORD_NET')} {formatAmount(oldItem.net_amount || 0)}
                </Space>
                <Space>
                  {getText('WORD_FEE')}{' '}
                  {formatAmount(oldItem.application_fee_amount || 0)}
                </Space>
                <Space>
                  {getText('WORD_CARD')} {getCard('master_card')}
                </Space>
                <Space className='collapse-item-buttons-wrapper'>
                  {oldItem.status === 'paid' ? (
                    <MatButton
                      style={{ height: '33px', fontSize: '12px' }}
                      buttonText={getText('WORD_REFUND')}
                      onClick={() => {
                        confirmRefund && confirmRefund(oldItem)
                      }}
                    />
                  ) : (
                    ''
                  )}
                  {oldItem.receipt_urls && oldItem.receipt_urls.length > 0 ? (
                    <MatButton
                      buttonText={getText('WORD_RECEIPT')}
                      typeButton={'white'}
                      style={{ height: '33px' }}
                      onClick={() => {
                        window.open(`${oldItem.receipt_urls[0]}`, '_blank')
                      }}
                    />
                  ) : (
                    ''
                  )}
                  {oldItem.status === 'not_paid' ? (
                    <MatButton
                      style={{ height: '33px', fontSize: '12px' }}
                      buttonText={getText('ACTION_CANCEL')}
                      typeButton={'cancel'}
                      onClick={() => {
                        cancelPayment && cancelPayment(oldItem)
                      }}
                    />
                  ) : (
                    ''
                  )}
                  <PaymentTimelineModal
                    ref={modalRef}
                    item={oldItem}
                    trigger={
                      <div className='pay-info-item'>
                        <SVGInfoIcon />
                      </div>
                    }
                  />
                </Space>
              </>
            ),
          }
        })}
      />
    </>
  )
}

export default PaymentListCollapsePanel
