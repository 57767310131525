import React from 'react'
import { Space } from 'antd'
import { formatDateTime } from '../../../../utils'

const BroadcastListMobileHeader = ({
  name,
  status,
  action_time,
  getStatus,
  canceled,
  _id,
}) => {
  return (
    <>
      <Space style={{ marginBottom: 2 }}>
        <div className='info-item-broadcast'>
          <h3>{name}</h3>
        </div>
        <div className='info-item-broadcast'>
          {getStatus(status, action_time, canceled, _id)}
        </div>
      </Space>
      <div className='info-item-broadcast'>
        <span className='white-space-nowrap-item'>
          {formatDateTime(action_time)}
        </span>
      </div>
    </>
  )
}

export default BroadcastListMobileHeader
