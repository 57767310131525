import React from "react";
import Icon from "@ant-design/icons";

const SVGIcon = (props) => {
  return (
    <svg
      width="120"
      height="82"
      viewBox="0 0 120 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 60C40.3333 60 36.6667 1 57 1"
        stroke={props.positivecolor || "#E5E9F2"}
        strokeWidth="2"
      />
      <path
        d="M63 1C83.3333 1 79.6667 60 100 60"
        stroke={props.noreplycolor || "#E5E9F2"}
        strokeWidth="2"
      />
      <rect
        width="2"
        height="59"
        transform="matrix(1 0 0 -1 59 60.5)"
        fill={props.negativecolor || "#E5E9F2"}
      />
      <circle opacity="0.2" cx="60" cy="70" r="12" fill="#E5E9F2" />
      <path
        d="M60.0008 62.8008C56.024 62.8008 52.8008 66.0241 52.8008 70.0008C52.8008 73.9774 56.0241 77.2008 60.0008 77.2008C63.9775 77.2008 67.2008 73.9774 67.2008 70.0008C67.2008 66.0241 63.9775 62.8008 60.0008 62.8008ZM62.2193 67.2171C62.7007 67.2171 63.0984 67.6148 63.0984 68.0961C63.0984 68.5775 62.7007 68.9752 62.2193 68.9752C61.738 68.9752 61.3403 68.5775 61.3403 68.0961C61.3403 67.6148 61.738 67.2171 62.2193 67.2171ZM57.9914 67.2171C58.4728 67.2171 58.8705 67.6148 58.8705 68.0961C58.8705 68.5775 58.4728 68.9752 57.9914 68.9752C57.5101 68.9752 57.1124 68.5775 57.1124 68.0961C57.1124 67.6148 57.5101 67.2171 57.9914 67.2171ZM63.0356 73.1403C62.889 73.224 62.7007 73.1822 62.596 73.0356C62.0309 72.1357 61.0471 71.6125 60.0006 71.6125C58.9332 71.6125 57.9495 72.1566 57.3844 73.0775C57.3216 73.1612 57.2169 73.2241 57.1122 73.2241C57.0494 73.2241 57.0075 73.2032 56.9448 73.1822C56.7982 73.0986 56.7564 72.8892 56.8401 72.7426C57.5308 71.6333 58.7029 70.9845 60.0005 70.9845C61.2772 70.9845 62.4493 71.6333 63.14 72.7009C63.224 72.8473 63.1822 73.0356 63.0356 73.1403Z"
        fill="#E5E9F2"
      />
      <circle opacity="0.2" cx="108" cy="62" r="12" fill="#E5E9F2" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M108.2 55C112.176 55 115.4 58.2236 115.4 62.2C115.4 66.1764 112.176 69.4 108.2 69.4C104.224 69.4 101 66.1764 101 62.2C101 58.2236 104.224 55 108.2 55ZM108.2 63.8098C108.895 63.8098 109.458 64.3719 109.458 65.0653C109.458 65.7588 108.895 66.3209 108.2 66.3209C107.505 66.3209 106.942 65.7587 106.942 65.0653C106.942 64.3718 107.505 63.8098 108.2 63.8098ZM110.253 65.5366V65.3515L111.132 64.3685C111.032 64.3736 110.945 64.3758 110.868 64.3758H110.305V64.1911H111.433V64.3416L110.686 65.1958L110.542 65.3515C110.647 65.3441 110.745 65.3403 110.837 65.3403H111.475V65.5366H110.253L110.253 65.5366ZM111.952 66.6828V66.4977L112.83 65.5147C112.731 65.5198 112.643 65.522 112.566 65.522H112.003V65.3373H113.132V65.4878L112.384 66.342L112.24 66.4977C112.345 66.4903 112.443 66.4865 112.535 66.4865H113.173V66.6828H111.952L111.952 66.6828ZM106.808 60.4217C106.93 60.3446 106.965 60.1858 106.886 60.0672C106.806 59.9484 106.644 59.9147 106.522 59.9919C106.519 59.9939 105.385 60.7235 104.674 59.939C104.578 59.833 104.412 59.8231 104.303 59.9169C104.195 60.0106 104.184 60.1726 104.281 60.2785C105.295 61.3974 106.804 60.4245 106.808 60.4217L106.808 60.4217ZM109.876 59.9794C109.754 59.9023 109.591 59.936 109.512 60.0547C109.433 60.1735 109.467 60.3322 109.589 60.4094C109.594 60.4121 111.083 61.3739 112.116 60.2772C112.214 60.1729 112.207 60.0106 112.1 59.9146C111.993 59.8186 111.827 59.8253 111.728 59.9297C110.999 60.7041 109.879 59.9814 109.876 59.9794L109.876 59.9794Z"
        fill="#E5E9F2"
      />
      <circle opacity="0.1" cx="12" cy="61" r="12" fill="#E5E9F2" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9969 53.7969C8.02446 53.7969 4.79688 57.0248 4.79688 60.9969C4.79688 64.969 8.02446 68.1969 11.9969 68.1969C15.9693 68.1969 19.1969 64.9687 19.1969 60.9969C19.1969 57.0251 15.9693 53.7969 11.9969 53.7969ZM9.67969 58.395C10.2832 58.395 10.7814 58.8915 10.7814 59.4967C10.7814 60.1019 10.284 60.5983 9.67969 60.5983C9.07437 60.5983 8.57283 60.1019 8.57283 59.4967C8.57283 58.8915 9.07517 58.395 9.67969 58.395ZM14.3142 58.395C14.9187 58.395 15.4158 58.8915 15.4158 59.4967C15.4158 60.1019 14.9194 60.5983 14.3142 60.5983C13.7089 60.5983 13.2125 60.1019 13.2125 59.4967C13.2125 58.8915 13.7098 58.395 14.3142 58.395ZM15.2917 62.8433C15.4185 62.8367 15.5378 62.9034 15.5985 63.015C15.6592 63.1264 15.6505 63.2628 15.5762 63.3657C14.7827 64.5922 13.4777 65.4088 11.9969 65.4088C10.5146 65.4088 9.20587 64.592 8.41257 63.3657C8.34769 63.2692 8.33857 63.1455 8.38844 63.0405C8.43832 62.9355 8.5398 62.8644 8.65561 62.8537C8.66773 62.853 8.67974 62.853 8.69186 62.8537C8.80362 62.8555 8.90684 62.9139 8.96595 63.0088C9.65326 64.071 10.7553 64.7468 11.997 64.7468C13.2369 64.7468 14.3357 64.0708 15.023 63.0088C15.0786 62.9115 15.18 62.8492 15.292 62.8433L15.2917 62.8433Z"
        fill="#E5E9F2"
      />
    </svg>
  );
};

const SVGWorkflowAddAction = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />;
      }}
      {...props}
    />
  );
};

export default SVGWorkflowAddAction;
