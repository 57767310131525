import React from 'react'
import { Form, Upload } from 'antd'
import axios from 'axios'
import ImgCrop from 'antd-img-crop'
import { getText, getTextServerError } from '../../../lang'
import {
  uploadAndFetchGETFile,
  uploadAndFetchPOStFile,
} from '../../../utils/UrlHelper'
import { notifyError } from '../../../utils/Notify'

const UploadFileForm = (props) => {
  const {
    onStartUpload,
    targerType,
    targetId,
    onChangeStatus,
    onUpload,
    mediaTypes,
    size,
    beforeUploadProps,
    isPreview,
    modalTitle,
    storiesVideos,
    videoSize,
    customType,
  } = props

  const uploadMedia = (media) => {
    const { file } = media
    let NewFile = {
      url: '',
      mediaUrl: '',
      name: file.name,
      id: targetId || new Date().getTime(),
      uid: new Date().getTime(),
      _id: targetId || new Date().getTime() + '_' + file.name,
      key: new Date().getTime(),
      mediaContentType: file.type,
      status: 'uploading',
      percent: 68,
    }
    if (!file.type.includes('video')) {
      onStartUpload(NewFile)
    }
    const reader = new FileReader()

    // reader.onabort = () => console.log('file reading was aborted')
    // reader.onerror = () => console.log('file reading has failed')
    // eslint-disable-next-line no-loop-func

    if (file.type.includes('video')) {
      reader.onload = async () => {
        window.URL = window.URL || window.webkitURL
        var video = document.createElement('video')
        video.preload = 'metadata'
        video.onloadedmetadata = async function () {
          window.URL.revokeObjectURL(video.src)
          if (video.duration > 180) {
            notifyError(
              getTextServerError(
                getText('MSG_VIDEO_DURATION_MUST_BE_LESS_THAT_3_MINUES')
              )
            )
            return
          } else {
            if (storiesVideos) {
              uploadAndFetchPOStFile('/s3/upload-image', { file }).then((res) => {
                if (res.success) {
                  NewFile.status = 'done'
                  NewFile.url = res.data.url
                  NewFile.mediaUrl = res.data.url
                  NewFile.file = file
                  onUpload(NewFile)
                } else {
                  NewFile.status = 'error'
                  notifyError(getTextServerError(res.errMsg))
                }
              })
            } else {
              let obj = {
                fileName: file.name,
                fileType: file.type,
                targetType: targerType,
                targetId: targetId,
              }
              let result = await uploadAndFetchGETFile('/videos/signed-url', obj)
              if (result.success) {
                onChangeStatus && onChangeStatus('uploading', obj)
                const data = new FormData()
                Object.keys(NewFile).forEach((key) => {
                  data.append(key, NewFile[key])
                })
                const uploadS3 = await axios.put(result.data.signedRequest, file)
                if (uploadS3.status === 200) {
                  NewFile.status = 'done'
                  NewFile.url = uploadS3.config.url
                  NewFile.mediaUrl = uploadS3.config.url
                  NewFile.file = uploadS3.config.data
                  NewFile._id = result.data.videoUploadId
                  onUpload(NewFile)
                  onChangeStatus && onChangeStatus('done', obj)
                } else {
                  let newObj = {
                    videoUploadId: result.data.videoUploadId,
                    status: 'failed',
                  }
                  let resultPost = await uploadAndFetchPOStFile(
                    '/videos/process-video',
                    newObj
                  )
                  if (!resultPost.success) {
                    notifyError(getTextServerError(resultPost.errMsg))
                    return
                  }
                }
              } else {
                NewFile.status = 'error'
                notifyError(getTextServerError(result.errMsg))
              }
            }
          }
        }
        video.src = window.URL.createObjectURL(
          new Blob([file], { type: 'video/mp4' })
        )
      }
    } else {
      reader.onload = () => {
        uploadAndFetchPOStFile('/s3/upload-image', { file }).then((res) => {
          if (res.success) {
            NewFile.status = 'done'
            NewFile.url = res.data.url
            NewFile.mediaUrl = res.data.url
            NewFile.file = file
            onUpload(NewFile)
          } else {
            NewFile.status = 'error'
            notifyError(getTextServerError(res.errMsg))
          }
        })
      }
    }

    reader.readAsArrayBuffer(file)
  }

  const beforeUpload = (file) => {
    let fileSize = size
    let isVideo = false
    const isCorrectType = mediaTypes.includes(file.type)
    if (isCorrectType) {
      if (file.type.includes('video/')) {
        isVideo = true
      }
    }

    if (customType && customType.length) {
      customType.map((item) => {
        if (item.type === file.type) {
          fileSize = item.size
        }
        return item
      })
    }

    if (!isCorrectType) {
      if (props.errMsg) {
        notifyError(props.errMsg)
      } else {
        notifyError(
          `${getText('TEXT_YOU_CAN_ONLY_UPLOAD')} ${props.mediaTypes
            .replaceAll('image/', '')
            .replaceAll('video/', '')
            .replaceAll('application/', '')
            .toUpperCase()} ${getText('WORD_FILE')}!`
        )
      }
    }

    const isCorrectSize = isVideo
      ? videoSize
        ? file.size / 1024 < videoSize * 1000
        : file.size / 1024 < 500000.0
      : file.size / 1024 < fileSize * 1024
    const isMB = file.size / 1024 < 1024 ? 'KB' : 'MB'
    const isSizeKB = isMB === 'MB' ? fileSize : fileSize * 1000
    if (fileSize) {
      if (!isCorrectSize && isCorrectType) {
        notifyError(
          `${getText('TEXT_IMAGE_MUST_SMALLER_THAN')} ${
            isVideo ? (storiesVideos ? videoSize : 300) : isSizeKB
          } ${isMB}`
        )
      }
    }

    let checkUplaod = props.size ? isCorrectSize && isCorrectType : isCorrectType
    return checkUplaod || Upload.LIST_IGNORE
  }

  const onPreview = async (file) => {
    let src = file.url

    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(file.originFileObj)

        reader.onload = () => resolve(reader.result)
      })
    }

    const image = new Image()
    image.src = src
    const imgWindow = window.open(src)
    imgWindow && document.write(image.outerHTML)
  }

  return (
    <Form.Item
      className={`mat-form-item ${props.formClassName || ''}`}
      style={props.formStyle}
      label={props.label}
      name={props.name}
      dependencies={props.dependencies}
      valuePropName={props.valuePropName}
      hasFeedback={props.hasFeedback}
      initialValue={props.initialValue}
      getValueFromEvent={props.getValueFromEvent}
      shouldUpdate={props.shouldUpdate}
    >
      {isPreview ? (
        <ImgCrop
          rotationSlider
          showGrid
          quality={1}
          aspect={3 / 4}
          modalTitle={modalTitle}
          cropperProps={{ zoomWithScroll: 'false' }}
          modalCancel={getText('ACTION_CANCEL')}
        >
          <Upload
            accept={props.accept}
            mediaTypes={props.mediaTypes}
            customRequest={uploadMedia}
            multiple={props.multiple}
            listType={props.listType}
            defaultFileList={props.defaultFileList}
            className={`mat-upload ${props.className || ''}`}
            disabled={props.disabled}
            beforeUpload={beforeUploadProps && beforeUpload}
            maxCount={props.maxCount}
            showUploadList={props.showUploadList}
            size={props.size}
            onPreview={onPreview}
            onChange={(e) => {
              if (e.file.status && e.file.status === 'removed') {
                props.onRemoveItem(e.fileList)
              }
            }}
          >
            {props.children || getText('WORD_PLUS_ADD_IMAGE')}
          </Upload>
        </ImgCrop>
      ) : (
        <Upload
          mediaTypes={props.mediaTypes}
          customRequest={uploadMedia}
          multiple={props.multiple}
          listType={props.listType}
          defaultFileList={props.defaultFileList}
          className={`mat-upload ${props.className || ''}`}
          disabled={props.disabled}
          beforeUpload={beforeUploadProps && beforeUpload}
          maxCount={props.maxCount}
          showUploadList={props.showUploadList}
          size={props.size}
          onChange={(e) => {
            if (e.file.status && e.file.status === 'removed') {
              props.onRemoveItem(e.fileList)
            }
          }}
        >
          {props.children || getText('WORD_PLUS_ADD_IMAGE')}
        </Upload>
      )}
    </Form.Item>
  )
}

UploadFileForm.defaultProps = {
  mediaData: [],
  maxCount: 10,
  multiple: true,
  size: 20,
  listType: 'picture-card',
  mediaTypes:
    'application/pdf, image/jpeg, image/jpg, image/png, image/gif, video/mp4, video/mov, video/avi, video/webm, video/quicktime',
  onUpload: (file) => {},
  onStartUpload: (file) => {},
  onRemoveItem: (file) => {},
}

export default React.memo(UploadFileForm)
