import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { Tooltip } from 'antd'
import SVGStripe from '../../../../icons/SVG/SVGStripe'
import { getText } from '../../../../lang'
import organizationActions from '../../../../store/modules/organizationActions'
import { MainColor } from '../../../../utils'
import ConfirmPopup from '../../../../components/ConfirmPopup'
import MatBlockWithLabel from '../../../../components/MatBlockWithLabel'
import MatButton from '../../../../components/MatButton'

const StripeButton = (props) => {
  const { organization, user } = props

  const [stripeLogged, setStripeLogged] = useState(organization.stripeConnected)

  useEffect(() => {
    window.addEventListener('message', stripeConnectHandler)
  }, [])

  const stripeConnectHandler = async (event) => {
    if (event.data.type === 'stripeAuth') {
      const data = event.data.data
      if (data) {
        organization.featuresConnectingData = data.featuresConnectingData
        setStripeLogged(data.stripeConnected)
      }
    }
  }

  const connectStripe = async () => {
    if (!stripeLogged) {
      const url = await organizationActions.createStripeAccount(organization._id)
      if (url) {
        window.open(url, 'stripeAuth')
      }
    } else {
      const result = await organizationActions.disconnectPayment(organization._id)
      if (result) {
        organization.featuresConnectingData = result.featuresConnectingData
        setStripeLogged(result.stripeConnected)
      }
    }
  }

  return (
    <MatBlockWithLabel label={getText('TEXT_INTEGRATE_YOUR_PAYMENT_ACCOUNT')}>
      {!stripeLogged ? (
        <MatButton
          buttonText={
            <>
              <SVGStripe
                style={{ marginRight: '12px', fill: MainColor }}
                width={20}
                height={20}
              />
              {getText('WORD_CONNECT_PAYMENTS')}
            </>
          }
          style={{
            maxWidth: 'fit-content',
            display: 'flex',
            alignItems: 'center',
            boxShadow: '0 3px 4px 0 rgb(0 0 0 / 25%)',
          }}
          typeButton={'cancel'}
          size='large'
          disabled={
            !user.isSuperAdmin && !organization.enable_payments && !user.isAdmin
          }
          onClick={connectStripe}
          htmlType='button'
        />
      ) : !Boolean(user.isSuperAdmin && user.isAdmin && stripeLogged) ? (
        <Tooltip
          placement='top'
          title={getText('TEXT_PLEASE_CONTACT_YOUR_ADMINISTRATOR')}
        >
          <MatButton
            buttonText={
              <>
                <SVGStripe
                  style={{ marginRight: '12px', fill: MainColor }}
                  width={20}
                  height={20}
                />
                {getText('WORD_DISCONNECT_PAYMENTS')}
              </>
            }
            style={{
              maxWidth: 'fit-content',
              display: 'flex',
              alignItems: 'center',
              boxShadow: '0 3px 4px 0 rgb(0 0 0 / 25%)',
              cursor: 'not-allowed',
            }}
            typeButton={'cancel'}
            size='large'
            disabled={!user.isSuperAdmin && !stripeLogged && !user.isAdmin}
            htmlType='button'
          />
        </Tooltip>
      ) : (
        <ConfirmPopup
          title={getText('TEXT_ARE_YOU_SURE_YOU_WANT_TO_DISCONNECT_PAYMENTS')}
          okText={getText('WORD_DISCONNECT')}
          onConfirm={connectStripe}
          cancelText={getText('WORD_NO')}
          trigger={
            <MatButton
              buttonText={
                <>
                  <SVGStripe
                    style={{ marginRight: '12px', fill: MainColor }}
                    width={20}
                    height={20}
                  />
                  {getText('WORD_DISCONNECT_PAYMENTS')}
                </>
              }
              style={{
                maxWidth: 'fit-content',
                display: 'flex',
                alignItems: 'center',
                boxShadow: '0 3px 4px 0 rgb(0 0 0 / 25%)',
              }}
              typeButton={'cancel'}
              size='large'
              disabled={!user.isSuperAdmin && !stripeLogged && !user.isAdmin}
              htmlType='button'
            />
          }
        />
      )}
      {!_.isEmpty(
        organization.featuresConnectingData &&
          organization.featuresConnectingData.stripe
      ) && (
        <div className='footer-connected-account'>
          <span>
            {getText(
              organization.featuresConnectingData.stripe.isConnected
                ? 'ACTION_CONNECTED_BY'
                : 'ACTION_DISCONNECTED_BY'
            )}
          </span>
          <span>{organization.featuresConnectingData.stripe.userData.fullName}</span>
        </div>
      )}
    </MatBlockWithLabel>
  )
}

export default StripeButton
