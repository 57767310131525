import React from 'react'
import PropTypes from 'prop-types'
import SVGSmsAutomation from '../../../../../icons/SVG/SVGSmsAutomation'
import { getText } from '../../../../../lang'
import SVGSmsAutomationArrowIcon from '../../../../../icons/SVG/SVGSmsAutomationArrowIcon'

function Automation(props) {
  const { item, index, screenSize, selectedRange } = props

  const getColor = (index) => {
    const color = ['#6C5DD3', '#48AF80', '#FABC05', '#7AC6DC'][index % 4]
    return color
  }

  const getAudience = (selectedRange) => {
    if (selectedRange === 'ytd') {
      return item.audience.yesterday
    } else if (selectedRange === 'today') {
      return item.audience.today
    } else if (selectedRange === 'mtd') {
      return item.audience.monthToDay
    } else if (selectedRange === 'last_month') {
      return item.audience.lastMonth
    }
    return item.audience.allTime
  }

  const getOportunities = (selectedRange) => {
    if (!item.opportunties) {
      return 0
    }
    if (selectedRange === 'ytd') {
      return item.opportunties.yesterday
    } else if (selectedRange === 'today') {
      return item.opportunties.today
    } else if (selectedRange === 'mtd') {
      return item.opportunties.monthToDay
    } else if (selectedRange === 'last_month') {
      return item.opportunties.lastMonth
    }
    return item.opportunties.allTime
  }

  function getIcon(size) {
    switch (size) {
      case 'tabletPortrait':
        return null
      case 'mobile':
        return null
      default:
        return <SVGSmsAutomation color={getColor(index)} />
    }
  }
  return (
    <div className='automation'>
      <div className='automation-head'>
        {getIcon(screenSize)}
        <span style={{ color: `${getColor(index)}` }}>{item.name}</span>
      </div>
      <div className='automation-count-wrapper'>
        <div>
          <div className='value'>{getAudience(selectedRange)}</div>
          <div className='action'>{item.message}</div>
        </div>
        <SVGSmsAutomationArrowIcon color={getColor(index)} />
        <div>
          <div className='value'>{getOportunities(selectedRange)}</div>
          <div className='action'>{getText('WORD_OPPORTUNITIES').toLowerCase()}</div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(Automation)

Automation.propTypes = {
  color: PropTypes.string,
  text: PropTypes.string,
  value: PropTypes.number,
  action: PropTypes.string,
}
