import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width='310'
    height='174'
    viewBox='0 0 310 174'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M0 40.5025C0 31.9152 0 27.6215 1.67951 24.2511C3.14378 21.3126 5.54677 18.8369 8.44028 17.2857C11.7591 15.5065 15.9961 15.3802 24.47 15.1275C50.8513 14.3409 91.0422 13.6343 105.75 16.0006C129.679 19.8505 130.264 46.0006 154.5 46.0006C178.736 46.0006 179.321 19.8505 203.25 16.0006C218.007 13.6264 258.841 14.3456 285.513 15.1353C293.996 15.3865 298.238 15.5121 301.558 17.2911C304.452 18.8424 306.855 21.3173 308.32 24.2567C310 27.6274 310 31.9234 310 40.5153V148.401C310 157.361 310 161.842 308.256 165.264C306.722 168.275 304.274 170.723 301.264 172.257C297.841 174.001 293.361 174.001 284.4 174.001H25.6C16.6392 174.001 12.1587 174.001 8.73615 172.257C5.72556 170.723 3.27787 168.275 1.7439 165.264C0 161.842 0 157.361 0 148.401V40.5025Z'
      fill='#EAF6F1'
    />
    <path
      d='M260.03 61.4937L267.262 65.3782L260.282 69.699L260.03 61.4937Z'
      fill='#48AF80'
    />
    <path
      opacity='0.3'
      d='M243.838 146.264L241.456 150.159L239.274 146.149L243.838 146.264Z'
      fill='#48AF80'
    />
    <path
      d='M35.3279 152.08L33.614 157.006L30.2045 153.059L35.3279 152.08Z'
      fill='#F7064E'
    />
    <path
      opacity='0.3'
      d='M26.0869 107.161L28.7683 107.787L26.8857 109.796L26.0869 107.161Z'
      fill='#F7064E'
    />
    <path
      d='M41.8542 57.8547L48.6075 59.6643L43.6638 64.608L41.8542 57.8547Z'
      fill='#FABC05'
    />
    <path
      opacity='0.4'
      d='M47.7499 86.529L45.2427 89.6481L43.7951 85.9173L47.7499 86.529Z'
      fill='#FABC05'
    />
    <circle cx='259' cy='104' r='3' fill='#FABC05' />
    <circle opacity='0.4' cx='282.5' cy='73.5' r='1.5' fill='#FABC05' />
    <circle cx='77' cy='101' r='3' fill='#48AF80' />
    <circle opacity='0.4' cx='68.5' cy='70.5' r='1.5' fill='#48AF80' />
    <circle cx='237' cy='98' r='2' fill='#F7064E' />
    <circle opacity='0.3' cx='282' cy='111' r='1' fill='#F7064E' />
    <circle cx='155' cy='21' r='21' fill='#EAF6F1' />
    <path
      d='M164.414 28.8281C149.267 28.8281 149.379 28.8281 145.586 28.8281C145.262 28.8281 145 29.0902 145 29.4141C145 29.7379 145.262 30 145.586 30H164.414C164.738 30 165 29.7379 165 29.4141C165 29.0902 164.738 28.8281 164.414 28.8281Z'
      fill='#48AF80'
    />
    <path
      d='M150.352 22.9687H146.758C146.434 22.9687 146.172 23.2308 146.172 23.5547V27.6562H150.937V23.5547C150.937 23.2308 150.675 22.9687 150.352 22.9687Z'
      fill='#48AF80'
    />
    <path
      d='M156.211 19.4531H152.695C152.371 19.4531 152.109 19.7152 152.109 20.039V27.6562H156.797V20.039C156.797 19.7152 156.535 19.4531 156.211 19.4531Z'
      fill='#48AF80'
    />
    <path
      d='M164.828 14.8591L160.727 10.1717C160.498 9.94278 160.127 9.94278 159.898 10.1717L155.797 14.8591C155.629 15.0268 155.579 15.2785 155.67 15.4977C155.76 15.7169 155.974 15.8594 156.211 15.8594H157.969V27.6562H162.656V15.8594H164.414C164.651 15.8594 164.865 15.7169 164.955 15.4977C165.046 15.2786 164.996 15.0268 164.828 14.8591Z'
      fill='#48AF80'
    />
  </svg>
)

const SVGPromotionsRectangleBg = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGPromotionsRectangleBg
