import React from 'react'

const texts = {
  WORD_YES: 'Si',
  WORD_NO: 'No',
  ACTION_BACK: 'Regresar',
  ACTION_SEND: 'Enviar',

  TITLE_FEEDBACK_POS: (name) => (
    <>
      Usa <span className='url-name'>{name}</span> <br />
      para dejar un comentario?
    </>
  ),

  TITLE_FB_MESSENGER: 'Facebook messenger',
  TITLE_IG_MESSENGER: 'Instagram messenger',
  TITLE_GOOGLE_BUSINESS: 'Google business',

  TITLE_FEEDBACK_POS_LIST: 'Deja tu comentario en',

  ACTION_FEEDBACK_SKIP: 'Usa un sitio diferente',
  TITLE_FEEDBACK_NEG: 'Gracias por tus comentarios',
  TITLE_FEEDBACK_NEG_CONFIRM: 'Gracias por tus comentarios',

  TEXT_FEEDBACK_NEG_HELP: 'Lo sentimos mucho!',
  TEXT_FEEDBACK_NEG_INPUT_PLACEHOLDER: 'Cuéntanos qué pasó',
  TEXT_FEEDBACK_NEG_HELP_CONFIRM:
    'Estamos trabajando continuamente para mejorar la experiencia de nuestros clientes. Su opinión nos importa.',

  WORD_SHOW_ALL: 'Show All',
  WORD_SHOW_LESS: 'Show Less',

  UPLOAD_EXCEL_FILE: 'Upload Excel File',
  TEXT_CREATE_CAMPING_TYPE_DESCRIPTION:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do',
  BUILD_CONTENT: 'Build the content',

  TEXT_CREATE_A_SEGMENT: 'Create a Segment ',
  TEXT_CREATE_SEGMENT_DESCRIPTION:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor',
  WORD_LANGUAGES: 'Languages',
  WORD_ASSIGNED_TO: 'Assigned to',
  WORD_APPOINTMENT: 'Appointment',

  ACTION_CANCEL: 'Cancel',
  ACTION_APPLY: 'Apply',

  TEXT_UPLOAD_EXCEL_FILE: 'Upload Excel File',
  TEXT_GENERATE_ALTERNATIVE_CONTENT: 'Generate alternative content',

  WORD_NEXT: 'Siguiente',
  WORD_PREVIOUS: 'Previous',

  TEXT_FILE_UPLOAD_ERROR:
    'The file format is incorrect. Please upload a file in .xls format',
  WORD_REPLACE: 'Replace',

  TEXT_START_YOUR_CAMPAIGN: 'Start your campaign',
  TEXT_NAME_OF_BROADCAST: 'Name of broadcast',
  TEXT_LOCATION_DO_THESE_USERS_BELONG_TO: 'Which location do these users belong to?',
  TEXT_CONSENT_DESCRIPTION:
    'These clients have already been opted in to our messaging services',

  TEXT_LETS_USE_SOME_MACHINE_LEARNING: 'Lets use some machine learning',
  TEXT_DESCRIPTION_OF_USE_SOME_MACHINE_LEARNING:
    'Sentiment analysis is available only for VinSolutions CRM. If the sentiment analysis is inactive, please connect a VinSolutions account on at least one Location of this Organization.',
  TEXT_LABEL_OF_USE_SOME_MACHINE_LEARNING:
    'Would you want to utilize A.I to set rules based on replies?',

  WORD_SCHEDULER: 'Scheduler',
  TEXT_ASSIGN_CAMPAIGN_TO: 'Assign campaign to',
  WORD_DATE: 'Date',
  WORD_TIME: 'Time',
  TEXT_ADD_A_TAG_FOR_BROADCASTED_USERS: 'Add a tag for broadcasted users',

  TEXT_TERMS_AND_CONDITIONS: 'Terms and conditions',
  TEXT_TITLE_OF_TERMS_AND_CONDITIONS:
    'I accept that the client list I will be broadcasting too has given me authority to reach out to them through SMS for any promotional related communications.',

  TEXT_LABEL_CREATE_OPPORTUNITIES_IN_VIN_SOLUTIONS:
    'Create opportunities in VinSolutions ONLY when a client replies with positive sentiment.',

  TEXT_ADD_AN_APPOINTMENT_TO_THE_BROADCAST: 'Add an appointment to the broadcast',

  TOOLTIP_TEXT:
    'This action is not supported for [messanger] messenger conversations just yet',

  TITLE_DEFAULT_COMMUNICATION_METHOD: 'Default communication method',
  WORD_NOT_LIKELY: 'No es probable',
  WORD_VERY_LIKELY: 'Muy probable',
  TEXT_PROMOTION_TITLE: '¡Queremos tu vehículo!',
  TEXT_PROMOTION_TEXT:
    'Obtenga una evaluación gratuita hoy. Siempre pagaremos más por su vehículo.',
  TEXT_PROMOTION_BUTTON: 'vender mi auto',
  TEXT_PROMOTION_TEMPLATE: 'Hola estoy interesado en vender mi auto.',
  NTF_SUCCESS_SEND_FEEDBACK: 'Retroalimentación enviada con éxito.',
  ERR_NO_FEEDBACK: 'Por favor, introduzca el texto.',
  CONVERSATION_ARCHIVED_NOTE:
    'You can’t write a message in the archived folder. Please, unarchive conversation first.',
  TEXT_LOCATION_IS_ACTIVE: 'Location is active',
  TEXT_LOCATION_DISABLING_WARNING:
    'Turning off a location will make changing the location of messages which are assigned to and disable all Managers and Support Agents who belong to it. Are you sure you want to move ahead?',
  WORD_LEAD_GEN_WIZARD: 'Lead Gen Wizard',
  DISABLE_INPUT: 'No mostrar el campo de entrada «Escribe tu mensaje»',
  REDIRECT_URL: 'Redirect URL',
  REDIRECT_URL_TOOLTIP:
    'La URL de redirección está designada para navegar al cliente directamente a una URL especificada, omitiendo la visualización del formulario de contacto. Aunque esta opción generalmente no se recomienda, puede ser beneficiosa bajo ciertas circunstancias.',
  INVALID_URL: 'URL inválida',
  GA_TRACK_PAGE_VIEWS_TITLE:
    'Rastrear las vistas de página para cada widget y las presentaciones de leads',
  GA_TRACK_PAGE_VIEWS_PARAGRAPH_1:
    'Nuestra plataforma le permite monitorear fácilmente las vistas de página para cada widget. Además, cuando se envía un nuevo lead, enviaremos un evento personalizado con los siguientes detalles:',
  GA_CATEGORY: 'Categoría',
  GA_MATADOR: 'Matador',
  GA_ACTION: 'Acción',
  GA_ACTION_DETAILS:
    '"connect" (o mobile_coupon, desktop_coupon, mobile_cta, integrated_cta, exit_intent)',
  GA_LABEL: 'Etiqueta',
  GA_SUBMISSION: 'Soumission',
  GA_TRACK_PAGE_VIEWS_PARAGRAPH_2:
    'Al rastrear estos eventos, obtendrá información valiosa sobre el compromiso de los usuarios y las conversiones a través de los widgets de Matador.',
  GA_INFO_ABOUT_GA: 'Información sobre GA',
  GA_GOOGLE_ANALYTICS_4_TRACKING: 'Seguimiento de Google Analytics 4',
  POSITION_TEXT: 'Posición del texto',
  ENABLE_BOLD_TEXT: 'Habilitar texto en negrita',
  GA_TRACK_PAGE_VIEWS_PARAGRAPH_3:
    'Al rastrear estos eventos, obtendrás información valiosa sobre la participación de los usuarios y las conversiones a través de los widgets de Matador.',
  GA_TRACK_PAGE_VIEWS_PARAGRAPH_4:
    'Además, nuestra plataforma admite el uso de múltiples ID de Google Analytics 4 (GA4). Puedes agregar varios ID de GA en el formato G-YYYYYYYYYY, G-XXXXXXXXXX, separados por comas. Esto te permite rastrear los mismos eventos en diferentes propiedades de GA para un análisis más detallado.',
  INVALID_GA_FORMAT:
    'Formato de ID de GA4 inválido. Cada ID debe estar en el formato G-XXXXXXXXXX con letras mayúsculas y dígitos.',
  INVALID_GA_FORMAT_COMMA:
    'Parece que has terminado con una coma o punto y coma. Por favor, añade otro código GA o elimina el último carácter.',
  INVALID_GA_FORMAT_SPACE:
    'Por favor, asegúrate de que hay un espacio después de cada coma o punto y coma al ingresar múltiples ID de GA4.',
}

export default texts
