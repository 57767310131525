import React from 'react'
import { getText } from '../../../../lang'
import MatRow from '../../../../components/MatRow'
import SVGIntentNegativeIcon from '../../../../icons/SVG/SVGIntentNegativeIcon'
import SVGIntentPositiveIcon from '../../../../icons/SVG/SVGIntentPositiveIcon'
import SVGIntentNoReplyIcon from '../../../../icons/SVG/SVGIntentNoReplyIcon'

const WorkflowRenderItem = (props) => {
  const { value, title, icon } = props
  return (
    <div className='workflow-render-item-wrapper'>
      <MatRow className='workflow-render-item-first-row'>
        <MatRow className='workflow-render-item-icon-title'>
          {icon}
          {title}
        </MatRow>
        <p className='workflow-render-item-value'>{value || 0}</p>
      </MatRow>
    </div>
  )
}

const BroacastListDesktopItemWorkflow = ({ name, workflowData }) => {
  return (
    <div className='broadcast-message-row-wrapper-with-margin'>
      <div className='broadcast-message-row'>
        <p className='broadcast-more-info-row-title'>{getText('WORD_WORKFLOW')}</p>
      </div>
      <div className='broadcast-more-info-message-text'>{name}</div>
      <WorkflowRenderItem
        value={workflowData.positiveCounter}
        title={getText('WORD_POSITIVE_REPLY')}
        icon={<SVGIntentPositiveIcon />}
      />
      <WorkflowRenderItem
        value={workflowData.negativeCounter}
        title={getText('WORD_NEGATIVE_REPLY')}
        icon={<SVGIntentNegativeIcon />}
      />
      <WorkflowRenderItem
        value={workflowData.noReplyCounter}
        title={getText('WORD_NO_REPLY')}
        icon={<SVGIntentNoReplyIcon />}
      />
    </div>
  )
}

export default BroacastListDesktopItemWorkflow
