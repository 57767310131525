import React from 'react'
import { Space } from 'antd'
import SVGDownloadIconNew from '../../../../icons/SVG/SVGDownloadIconNew'

const BroadcastListMobileItemExcel = ({ title, infoItem, href }) => {
  return (
    <Space direction='vertical' className='broadcast-space-border-bottom'>
      <span className='info-item-mobile-title'>{title}</span>
      <div className='download-broadcast-row-wrapper info-item'>
        <div className='broadcast-more-info-message-text'>{infoItem}</div>
        <a className='download-link-broadcast' href={href} download={`${href}`}>
          <SVGDownloadIconNew />
        </a>
      </div>
    </Space>
  )
}

export default BroadcastListMobileItemExcel
