import React from "react";
import Icon from "@ant-design/icons";

const SVGIcon = (props) => (
  <svg
    width="40"
    height="17"
    viewBox="0 0 40 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.546393 15.357C1.37494 11.8855 3.11734 8.69895 5.59282 6.12797C8.0683 3.55699 11.1867 1.69527 14.6244 0.735982C18.062 -0.223309 21.6938 -0.245221 25.1428 0.67252C28.5918 1.59026 31.7324 3.41422 34.2387 5.95514L30.8912 9.25709C28.9741 7.31353 26.5719 5.91839 23.9337 5.21641C21.2956 4.51443 18.5177 4.53119 15.8882 5.26495C13.2587 5.99871 10.8734 7.42274 8.97995 9.38928C7.08646 11.3558 5.75369 13.7932 5.11994 16.4486L0.546393 15.357Z"
      fill="url(#paint0_linear_22276_225432)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.3176 14.8072C39.4683 15.3656 39.1056 15.9246 38.5401 16.0457L35.7454 16.6443C35.3131 16.7369 34.8894 16.46 34.7742 16.0331V16.0331C34.659 15.6062 34.8865 15.1554 35.3068 15.0181L38.0235 14.1304C38.5732 13.9507 39.167 14.2488 39.3176 14.8072V14.8072ZM34.1351 5.84607C34.544 6.25516 34.5062 6.91844 34.0763 7.30537L31.952 9.21737C31.6234 9.51317 31.1192 9.48549 30.8066 9.17276V9.17276C30.4941 8.86003 30.4654 8.35471 30.7612 8.02615L32.6738 5.90236C33.0608 5.47256 33.7262 5.43698 34.1351 5.84607V5.84607ZM25.1622 0.677715C25.7211 0.827025 26.0225 1.4202 25.8446 1.97065L24.9671 4.68585C24.8304 5.10888 24.3771 5.33889 23.9476 5.22415V5.22415V5.22415C23.5181 5.1094 23.2399 4.68395 23.3323 4.24911L23.9259 1.45804C24.0462 0.892204 24.6033 0.528406 25.1622 0.677715V0.677715V0.677715ZM14.8072 0.682511C15.3657 0.53184 15.9246 0.894511 16.0458 1.46007L16.6443 4.25472C16.7369 4.68707 16.46 5.11073 16.0331 5.22591V5.22591C15.6062 5.3411 15.1554 5.11365 15.0181 4.69336L14.1304 1.97669C13.9508 1.42691 14.2488 0.833181 14.8072 0.682511V0.682511ZM5.84611 5.86507C6.2552 5.45619 6.91847 5.49393 7.30541 5.92383L9.21741 8.04812C9.5132 8.37676 9.48553 8.88094 9.17279 9.1935V9.1935C8.86006 9.50607 8.35475 9.53479 8.02619 9.23891L5.90239 7.32636C5.47259 6.93932 5.43702 6.27394 5.84611 5.86507V5.86507ZM1.97069 14.1555C1.42023 13.9776 0.827063 14.2791 0.677753 14.838V14.838V14.838C0.528443 15.3968 0.892241 15.954 1.45807 16.0743L4.24914 16.6678C4.68399 16.7603 5.10944 16.4821 5.22419 16.0526V16.0526V16.0526C5.33893 15.6231 5.10892 15.1697 4.68589 15.033L1.97069 14.1555Z"
      fill="#EDF4FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.8047 9.17276C31.1173 9.48549 31.6214 9.51317 31.9501 9.21737L34.0744 7.30537C34.5043 6.91844 34.542 6.25516 34.1331 5.84607V5.84607C33.7243 5.43698 33.0589 5.47256 32.6718 5.90236L30.7593 8.02615C30.4634 8.35471 30.4921 8.86003 30.8047 9.17276V9.17276ZM23.9456 5.22415C24.3751 5.33889 24.8285 5.10888 24.9652 4.68585L25.8427 1.97065C26.0206 1.4202 25.7191 0.827025 25.1602 0.677715V0.677715V0.677715C24.6013 0.528406 24.0442 0.892204 23.9239 1.45804L23.3304 4.24911C23.2379 4.68395 23.5161 5.1094 23.9456 5.22415V5.22415V5.22415ZM16.0312 5.22591C16.4581 5.11073 16.735 4.68707 16.6424 4.25472L16.0438 1.46007C15.9227 0.894511 15.3637 0.53184 14.8053 0.682511V0.682511C14.2469 0.833181 13.9488 1.42691 14.1284 1.97669L15.0162 4.69336C15.1535 5.11365 15.6043 5.3411 16.0312 5.22591V5.22591ZM9.17084 9.1935C9.48357 8.88094 9.51125 8.37676 9.21546 8.04812L7.30346 5.92383C6.91652 5.49393 6.25325 5.45619 5.84416 5.86507V5.86507C5.43507 6.27394 5.47064 6.93932 5.90044 7.32636L8.02423 9.23891C8.3528 9.53479 8.85811 9.50607 9.17084 9.1935V9.1935ZM0.6758 14.838V14.838C0.82511 14.2791 1.41828 13.9776 1.96873 14.1555L4.68394 15.033C5.10696 15.1697 5.33698 15.6231 5.22223 16.0526V16.0526V16.0526C5.10749 16.4821 4.68203 16.7603 4.24719 16.6678L1.45612 16.0743C0.890288 15.954 0.52649 15.3968 0.6758 14.838V14.838Z"
      fill="#E6896B"
    />
    <defs>
      <linearGradient
        id="paint0_linear_22276_225432"
        x1="30.5"
        y1="12.5"
        x2="1"
        y2="14.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F79B7E" />
        <stop offset="1" stopColor="#FFA98E" />
      </linearGradient>
    </defs>
  </svg>
);

const SVGTempHotName = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />;
      }}
      {...props}
    />
  );
};

export default SVGTempHotName;
