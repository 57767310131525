import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { Modal, Space } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import paymentsActions from '../../../store/modules/paymentsAction'
import { getText, getTextServerError } from '../../../lang'
import { formatAmount, getNameFromFirstLastNames } from '../../../utils'
import SVGThreePointVerticalIcon from '../../../icons/SVG/SVGThreePointVerticalIcon'
import SVGInfoIcon from '../../../icons/SVG/SVGInfoIcon'
import { TABLE_DEFAULT_ROW_NUMBER } from '../../../devOptions'
import { notifyError, notifySuccess } from '../../../utils/Notify'
import MatButton from '../../../components/MatButton'
import utilDate from '../../../utils/utilsDate'
import MatDropMenu from '../../../components/MatDropMenu'
import MatTable from '../../../components/MatTable'
import { getCard, getEmptyResult, getStatus } from '../PaymentPageUtils'
import { uploadAndFetchGETFile } from '../../../utils/UrlHelper'
import MatList from '../../../components/MatList'
import PaymentTimelineModal from './PaymentTimelineModal'
import PaymentListCollapsePanel from './PaymentListCollapsePanel'

const deviceSize = ['desktop', 'messageRightDrawer']

const Payments = forwardRef((props, ref) => {
  const { options, screenSize } = props

  let organization = useSelector((state) => {
    return state.authReducer.user.organization
  })

  const modalRef = useRef(null)

  const [page, setPage] = useState(0)
  const [loadingData, setLoadingData] = useState(false)
  const [paymentData, setPaymentData] = useState({
    list: [],
    total: 0,
  })
  const [timelineItem, setTimelineItem] = useState('')
  const [sorting, setSorting] = useState({
    order: 'desc',
    sort: 'updatedAt',
  })

  useImperativeHandle(ref, () => ({
    paginationReset() {
      setPage(0)
    },
  }))

  const getReceiptUrl = (receiptURLS) => {
    const receipts = receiptURLS
    const regex = /.*-payment-.*/g
    for (const u of receipts) {
      if (regex.test(u)) {
        return u
      }
    }
  }

  const fill = useCallback(async () => {
    setLoadingData(true)
    let filterOptions = {
      status: options.status,
      group: options.group,
    }
    const result = await paymentsActions.getPageList(
      page,
      TABLE_DEFAULT_ROW_NUMBER,
      options.searchInputValue,
      filterOptions,
      sorting
    )
    if (result.success) {
      result.data.map(async (item) => {
        let receiptUrlLink
        if (item.receipt_urls && item.receipt_urls.length) {
          const receiptUrl = getReceiptUrl(item.receipt_urls)
          if (receiptUrl) {
            const result = await uploadAndFetchGETFile(
              `/s3/get-payment-receipt?receiptUrl=${receiptUrl}`
            )
            if (result.success) {
              receiptUrlLink = result.data.signedUrl
            }
          } else {
            receiptUrlLink = item.receipt_urls[0]
          }
        }
        Object.assign(item, {
          receipt_urls: receiptUrlLink ? [receiptUrlLink] : [],
        })
      })
      setPaymentData({
        list: result.data,
        total: result.max,
        status: filterOptions.status,
      })
    } else {
      getEmptyResult(result.errMsg, setPaymentData, {
        list: [],
        total: 0,
        status: undefined,
      })
      notifyError(getTextServerError(result.errMsg))
    }
    setLoadingData(false)
  }, [options, page, sorting, organization._id])

  useEffect(() => {
    fill()
  }, [fill])

  const confirmRefund = (item) => {
    const customerName = item.receiver.fullName
      ? item.receiver.fullName
      : item.receiver.phone

    Modal.confirm({
      title: (
        <div>
          {`${getText('MSG_ARE_YOU_SURE_WANT_TO_REFUND')} ${formatAmount(
            item.amount
          )} ${getText('WORD_TO')} ${customerName}?`}
        </div>
      ),
      icon: <ExclamationCircleOutlined />,
      okButtonProps: {
        type: 'primary',
        className: 'mat-btn mat-btn-primary',
      },
      cancelButtonProps: {
        type: 'cancel',
        className: 'mat-btn',
      },
      okText: getText('WORD_YES'),
      cancelText: getText('ACTION_CANCEL'),
      onOk: async () => {
        if (organization.enable_payments) {
          let result = await paymentsActions.refundPayment(item._id)
          if (result.success) {
            notifySuccess(getText('MSG_PAYMENT_REFUND_SUCCESSFULLY'))
            fill()
          } else {
            notifyError(getTextServerError(result.errMsg))
          }
        } else {
          notifyError(getText('ERROR_YOU_DO_NOT_HAVE_ACCESS_TO_SEND_PAYMENT'))
        }
      },
    })
  }

  const cancelPayment = (item) => {
    Modal.confirm({
      title: <div>{`${getText('MSG_ARE_YOU_SURE_WANT_TO_CANCEL_PAYMENT')}`}</div>,
      icon: <ExclamationCircleOutlined />,
      okButtonProps: {
        type: 'primary',
        className: 'mat-btn mat-btn-primary',
      },
      cancelButtonProps: {
        type: 'cancel',
        className: 'mat-btn',
      },
      okText: getText('WORD_YES'),
      cancelText: getText('ACTION_CANCEL'),
      onOk: async () => {
        if (organization.enable_payments) {
          let result = await paymentsActions.cancelPayment(item._id)
          if (result.success) {
            notifySuccess(getText('TEXT_THE_PAYMNET_CANCELED_SUCCESSFULLY'))
            fill()
          } else {
            notifyError(getTextServerError(result.errMsg))
          }
        } else {
          notifyError(getText('ERROR_YOU_DO_NOT_HAVE_ACCESS_TO_SEND_PAYMENT'))
        }
      },
    })
  }

  const paymentSubscriptionOptions = (item) => {
    let list = []

    if (item.status === 'paid') {
      list.push({
        name: getText('WORD_REFUND'),
        value: 'refund',
      })
    }
    if (item.receipt_urls && item.receipt_urls.length > 0) {
      list.push({
        name: getText('WORD_RECEIPT'),
        value: 'receipt',
      })
    }
    if (item.status === 'not_paid') {
      list.push({
        name: getText('ACTION_CANCEL'),
        value: 'cancel',
      })
    }

    list.push({
      name: getText('WORD_TIMELINE'),
      value: 'timeline',
    })

    return list
  }

  const handleEditPayment = (val, singleItem) => {
    if (val === 'refund') {
      confirmRefund(singleItem)
    } else if (val === 'receipt') {
      window.open(`${singleItem.receipt_urls[0]}`, '_blank')
    } else if (val === 'cancel') {
      cancelPayment(singleItem)
    } else if (val === 'timeline') {
      modalRef && modalRef.current.onOpen()
      setTimelineItem(singleItem)
    }
  }

  const columns1 = [
    {
      title: getText('WORD_STATUS'),
      dataIndex: 'status',
      sorter: true,
      showSorterTooltip: {
        title:
          process.env.REACT_APP_ENV === 'STAGE'
            ? 'Click to sort payment status'
            : '',
      },
      render: (_, { status }) => {
        return getStatus(status)
      },
    },
    {
      title: getText('WORD_USER'),
      dataIndex: 'user',
      render: (_, { user }) => {
        if (Boolean(user)) {
          const { fullName, phone, firstName, lastName } = user
          const name = getNameFromFirstLastNames(fullName, firstName, lastName)
          return name || phone
        }
        return null
      },
    },
    {
      title: getText('WORD_CUSTOMER'),
      dataIndex: 'receiver',
      render: (_, { receiver }) => {
        if (Boolean(receiver)) {
          const { fullName, phone, firstName, lastName } = receiver
          const name = getNameFromFirstLastNames(fullName, firstName, lastName)
          return name || phone
        }
        return null
      },
    },
    {
      title: getText('WORD_AMOUNT'),
      dataIndex: 'amount',
      sorter: true,
      render: (_, { amount }) => {
        return (
          <span className='payment-amount white-space-nowrap-item'>
            {formatAmount(amount)}
          </span>
        )
      },
    },
    {
      title: getText('WORD_REFERENCE'),
      dataIndex: 'reference',
      render: (_, { referenceNumber }) => {
        return (
          <span className='payment-grey-item'>
            {referenceNumber && referenceNumber.toUpperCase()}
          </span>
        )
      },
    },
    {
      title: (
        <>
          {getText('WORD_DATE_TIME')} <br />
          {getText('WORD_CREATED').toLowerCase()}
        </>
      ),
      dataIndex: 'createdAt',
      sorter: true,
      render: (_, { createdAt }) => {
        return (
          <span className='payment-grey-item'>
            {createdAt ? utilDate.getDateTimeForPayment(createdAt) : ''}
          </span>
        )
      },
    },
    {
      title: (
        <>
          {getText('WORD_DATE_TIME')} <br />
          {getText('WORD_UPDATED').toLowerCase()}
        </>
      ),
      dataIndex: 'updatedAt',
      sorter:
        options.group === 'none'
          ? true
          : (a, b) =>
              new Date(a.updatedAt.replace('am', '').replace('pm', '')) -
              new Date(b.updatedAt.replace('am', '').replace('pm', '')),
      defaultSortOrder: 'descend',
      render: (_, { updatedAt }) => {
        return (
          <span className='payment-grey-item'>
            {updatedAt ? utilDate.getDateTimeForPayment(updatedAt) : ''}
          </span>
        )
      },
    },
    {
      title: getText('WORD_NET'),
      dataIndex: 'net_amount',
      sorter: true,
      render: (_, { net_amount }) => {
        return (
          <span className='white-space-nowrap-item'>
            {formatAmount(net_amount || 0)}
          </span>
        )
      },
    },
    {
      title: getText('WORD_FEE'),
      dataIndex: 'application_fee_amount',
      sorter: true,
      render: (_, { application_fee_amount }) => {
        return (
          <span className='white-space-nowrap-item'>
            {formatAmount(application_fee_amount || 0)}
          </span>
        )
      },
    },
    {
      title: getText('WORD_CARD'),
      dataIndex: 'card',
      render: (_, { card }) => {
        return card && card.length ? getCard(card[0]) : ''
      },
    },
    {
      title: '',
      dataIndex: 'button',
      render: (_, item) => {
        const subOptions = paymentSubscriptionOptions(item)

        return (
          <Space className='payment-page-buttons-wrapper'>
            {deviceSize.includes(screenSize) ? (
              <>
                {item.status === 'paid' ? (
                  <MatButton
                    style={{
                      width: '73px',
                      height: '33px',
                      fontSize: '12px',
                    }}
                    buttonText={getText('WORD_REFUND')}
                    onClick={() => {
                      confirmRefund(item)
                    }}
                  />
                ) : (
                  ''
                )}
                {item.receipt_urls && item.receipt_urls.length > 0 ? (
                  <MatButton
                    buttonText={getText('WORD_RECEIPT')}
                    typeButton={'white'}
                    style={{ width: '73px', height: '33px' }}
                    onClick={() => {
                      window.open(`${item.receipt_urls[0]}`, '_blank')
                    }}
                  />
                ) : (
                  ''
                )}
                {item.status === 'not_paid' ? (
                  <MatButton
                    style={{
                      width: '73px',
                      height: '33px',
                      fontSize: '12px',
                    }}
                    buttonText={getText('ACTION_CANCEL')}
                    typeButton={'cancel'}
                    onClick={() => {
                      cancelPayment(item)
                    }}
                  />
                ) : (
                  ''
                )}
                <PaymentTimelineModal
                  ref={modalRef}
                  item={item}
                  trigger={
                    <div className='pay-info-item'>
                      <SVGInfoIcon />
                    </div>
                  }
                />
              </>
            ) : (
              subOptions &&
              subOptions.length && (
                <MatDropMenu
                  placement={'bottomRight'}
                  showArrow={false}
                  overlayStyle={{ width: '100px' }}
                  options={subOptions}
                  onChange={(val) => {
                    handleEditPayment(val, item)
                  }}
                  trigger={
                    <div className='payment-page-subscription-icon'>
                      <SVGThreePointVerticalIcon />
                    </div>
                  }
                />
              )
            )}
          </Space>
        )
      },
    },
  ]

  const columns2 = [
    {
      title: getText('WORD_TRANSACTION'),
      dataIndex: 'transaction',
      sorter: false,
      render: (_, { _id }) => {
        return (
          <span className='payment-total-amount' style={{ color: '#0e0e0e' }}>
            {_id}
          </span>
        )
      },
    },
    {
      title: getText('WORD_TOTAL_AMOUNT'),
      dataIndex: 'total_amount',
      sorter: false,
      render: (_, { totalAmount }) => {
        return formatAmount(totalAmount || 0)
      },
    },
    {
      title: getText('WORD_TOTAL_NET_AMOUNT'),
      dataIndex: 'total_net_amount',
      sorter: false,
      render: (_, { totalNetAmount }) => {
        return formatAmount(totalNetAmount || 0)
      },
    },
    {
      title: getText('WORD_TOTAL_FEES'),
      dataIndex: 'total_fees_amount',
      sorter: false,
      render: (_, { totalFees }) => {
        return formatAmount(totalFees || 0)
      },
    },
  ]

  const getSortedData = (sort, paginate) => {
    if (sort.order === 'ascend' && sort.field === 'status') {
      setSorting({ order: 'asc', sort: 'status' })
    } else if (sort.order === 'descend' && sort.field === 'status') {
      setSorting({ order: 'desc', sort: 'status' })
    } else if (sort.order === 'ascend' && sort.field === 'amount') {
      setSorting({ order: 'asc', sort: 'amount' })
    } else if (sort.order === 'descend' && sort.field === 'amount') {
      setSorting({ order: 'desc', sort: 'amount' })
    } else if (sort.order === 'ascend' && sort.field === 'createdAt') {
      setSorting({ order: 'asc', sort: 'createdAt' })
    } else if (sort.order === 'descend' && sort.field === 'createdAt') {
      setSorting({ order: 'desc', sort: 'createdAt' })
    } else if (sort.order === 'ascend' && sort.field === 'updatedAt') {
      setSorting({ order: 'asc', sort: 'updatedAt' })
    } else if (sort.order === 'descend' && sort.field === 'updatedAt') {
      setSorting({ order: 'desc', sort: 'updatedAt' })
    } else if (sort.order === 'ascend' && sort.field === 'net_amount') {
      setSorting({ order: 'asc', sort: 'amount' })
    } else if (sort.order === 'descend' && sort.field === 'net_amount') {
      setSorting({ order: 'desc', sort: 'amount' })
    } else if (sort.order === 'ascend' && sort.field === 'application_fee_amount') {
      setSorting({ order: 'asc', sort: 'amount' })
    } else if (sort.order === 'descend' && sort.field === 'application_fee_amount') {
      setSorting({ order: 'desc', sort: 'amount' })
    } else if (sort.order === 'ascend' && sort.field === 'transaction') {
      setSorting({ order: 'asc', sort: 'createdAt' })
    } else if (sort.order === 'descend' && sort.field === 'transaction') {
      setSorting({ order: 'desc', sort: 'createdAt' })
    } else {
      setSorting(null)
    }
    if (page + 1 === paginate.current) {
      setPage(0)
    }
  }

  return screenSize === 'mobile' ? (
    <MatList
      loading={loadingData}
      data={paymentData.list}
      pagination
      paginationCurrentPage={page}
      paginationTotalCount={paymentData.total}
      onPaginationChange={setPage}
      containerClassName=''
      renderItem={(item) => {
        return (
          <PaymentListCollapsePanel
            key={item._id}
            item={item}
            confirmRefund={confirmRefund}
            cancelPayment={cancelPayment}
            modalRef={modalRef}
            option={options.group}
          />
        )
      }}
    />
  ) : (
    <>
      {options.group === 'none' ? (
        <MatTable
          rowKey='_id'
          columns={columns1}
          dataSource={paymentData.list}
          loading={loadingData}
          defaultPageSize={TABLE_DEFAULT_ROW_NUMBER}
          paginationCurrentPage={page}
          paginationTotalCount={paymentData.total}
          onPaginationChange={setPage}
          scroll={{
            x: 700,
          }}
          onChange={(paginate, filter, sort) => {
            getSortedData(sort, paginate)
          }}
        />
      ) : (
        <MatTable
          rowKey='_id'
          expandable={{
            defaultExpandAllRows: false,
            expandedRowRender: (row) => (
              <MatTable
                rowKey={'_id'}
                loading={loadingData}
                columns={columns1}
                dataSource={row.transactionlist}
                withoutPagination
              />
            ),
          }}
          columns={columns2}
          dataSource={paymentData.list}
          loading={loadingData}
          defaultPageSize={TABLE_DEFAULT_ROW_NUMBER}
          paginationCurrentPage={page}
          paginationTotalCount={paymentData.total}
          onPaginationChange={setPage}
          scroll={{
            x: 700,
          }}
        />
      )}
      <PaymentTimelineModal
        ref={modalRef}
        item={timelineItem}
        trigger={<span style={{ color: 'transparent' }}>-</span>}
      />
    </>
  )
})

export default Payments
