import React, { useState, useEffect, useCallback } from 'react'
import { debounce } from 'lodash'
import organizationActions from '../../../store/modules/organizationActions'
import { getText } from '../../../lang'
import SVGSearch from '../../../icons/SVG/SVGSearch'
import MatList from '../../../components/MatList'
import { TABLE_DEFAULT_ROW_NUMBER } from '../../../devOptions'
import MatButton from '../../../components/MatButton'
import MatSelect from '../../../components/MatSelect'
import PageFull from '../../../components/PageFull'
import MatRow from '../../../components/MatRow'
import InputText from '../../../components/InputText'
import CreateOrganization from '../create'
import OrganizationListItem from './OrganizationListItem'
import './OrganizationList.scss'

const OrganizationsList = (props) => {
  const { user = {}, organization = {} } = props

  const [totalItems, setTotalItems] = useState(0)
  const [listItems, setListItems] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState(true)
  const [enableObj, setEnableObj] = useState({ enabled: selectedStatus })
  const [page, setPage] = useState(0)
  const [perPage, setPerPage] = useState(TABLE_DEFAULT_ROW_NUMBER)
  const [search, setSearch] = useState('')
  const [searchList, setSearchList] = useState('')

  useEffect(() => {
    props.setPageTitle(getText('TITLE_ORGANIZATIONS'))
  }, [])

  const handleChangeStatusFilter = (value) => {
    setSelectedStatus(value)
    const fill = async () => {
      if (value === 'all') {
        setEnableObj({})
      } else {
        setEnableObj({ enabled: value })
      }
    }
    fill()
  }
  const getOrganizationsList = async () => {
    setIsLoading(true)
    let result = await organizationActions.getPageList(
      page,
      perPage,
      searchList,
      enableObj
    )
    if (result.success) {
      setListItems(result.data)
      setTotalItems(result.max)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    getOrganizationsList()
  }, [selectedStatus, searchList, page, perPage])

  const handleSearchFilter = (value) => {
    setSearch(value)
    debouncedChange(value)
  }

  const debouncedChange = useCallback(
    debounce((options) => {
      setPage(0)
      setSearchList(options)
    }, 500),
    []
  )

  return (
    <PageFull className='organization-list-wrapper'>
      <MatRow className='organization-list-header'>
        <MatRow>
          <CreateOrganization
            organization={organization}
            user={user}
            onSave={async () => {
              await getOrganizationsList()
            }}
            trigger={
              <MatButton
                className='create-user-button'
                type='primary'
                buttonText={getText('ACTION_CREATE_ORGANIZATION')}
                style={{ minWidth: 165 }}
              />
            }
          />
          <MatSelect
            dropdownClassName={'organization-dropdown-select'}
            value={selectedStatus}
            onSelectItem={(options) => handleChangeStatusFilter(options.value)}
            options={[
              { label: getText('WORD_ALL'), value: 'all' },
              { label: getText('WORD_ACTIVE'), value: true },
              { label: getText('WORD_INACTIVE'), value: false },
            ]}
          />
        </MatRow>
        <div className='inputWithSearch'>
          <SVGSearch />
          <InputText
            className='inputSearch'
            name='searchKeyword'
            placeholder={getText('WORD_SEARCH_ORGANIZATIONS')}
            value={search}
            onChange={(e) => handleSearchFilter(e.target.value)}
          />
        </div>
      </MatRow>

      <MatList
        grid
        loading={isLoading}
        data={listItems}
        pagination={true}
        paginationCurrentPage={page}
        paginationTotalCount={totalItems}
        onPaginationChange={setPage}
        onShowSizeChange={setPerPage}
        containerClassName='organization-wrapper'
        renderItem={(item, index) => {
          return <OrganizationListItem item={item} key={index + '_' + item._id} />
        }}
      />
    </PageFull>
  )
}

export default OrganizationsList
