import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M6.0026 14.6663H10.0026C13.3359 14.6663 14.6693 13.333 14.6693 9.99967V5.99967C14.6693 2.66634 13.3359 1.33301 10.0026 1.33301H6.0026C2.66927 1.33301 1.33594 2.66634 1.33594 5.99967V9.99967C1.33594 13.333 2.66927 14.6663 6.0026 14.6663Z'
      stroke={props.color || '#77808D'}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7.0625 5.12012H9.88917V7.95345'
      stroke={props.color || '#77808D'}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.89052 5.12012L6.11719 8.89345'
      stroke={props.color || '#77808D'}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4 11.0068C6.59333 11.8735 9.40667 11.8735 12 11.0068'
      stroke={props.color || '#77808D'}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

const SVGOpenInCRMIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGOpenInCRMIcon
