import React, { useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import SVGPhoneCall from '../../../../../icons/SVG/SVGPhoneCall'
import SVGRemindMe from '../../../../../icons/SVG/SVGRemindMe'
import SVGThreePointVerticalIcon from '../../../../../icons/SVG/SVGThreePointVerticalIcon'
import SVGVideo from '../../../../../icons/SVG/SVGVideo'
import { getText } from '../../../../../lang'
import meetActions from '../../../../../store/modules/meetActions'
import MatDropMenu from '../../../../../components/MatDropMenu'
import { sendCustomPhone, sendPhoneCall } from '../../../utils/messageUtils'
import './MessageHeaderMobileButtons.scss'

const MessageCenterHeaderMobileButtons = ({
  conversation,
  refreshConversation,
  onOpenModal,
  phoneDisabled,
  videoDisabled,
}) => {
  const [loading, setLoading] = useState(false)

  const isMobileApp = useSelector((state) => {
    return state.authReducer.isMobileApp
  })

  const organization = useSelector((state) => {
    return state.authReducer.user.organization
  })

  const dropOptions = [
    {
      name: (
        <div className='label-item-wrapper'>
          <SVGRemindMe color={conversation.isNew ? '#a3a6be' : '#77808d'} />
          {getText('TEXT_REMIND_ME_TO_FOLLOW_UP')}
        </div>
      ),
      value: 'remind_me',
    },
    {
      name: (
        <div className='label-item-wrapper'>
          <SVGVideo color={'#77808d'} />
          {getText('WORD_VIDEO_CALL')}
        </div>
      ),
      value: 'video_call',
      disabled: videoDisabled || conversation.kind === 'messenger',
    },
    {
      name: (
        <div className='label-item-wrapper'>
          <SVGPhoneCall color={'#77808d'} />
          {getText('WORD_PHONE_CALL')}
        </div>
      ),
      value: 'phone_call',
      disabled: phoneDisabled || conversation.kind === 'messenger',
    },
  ]

  const handleMessageCenterHeaderDropMenu = async (value) => {
    if (value === 'phone_call') {
      if (
        organization.restrictions.restrictUserVoiceCallsFromUsingOrganizationNumbers
      ) {
        sendCustomPhone(conversation, isMobileApp)
      } else {
        sendPhoneCall(conversation.receiver_phone, conversation)
      }
    } else if (value === 'video_call') {
      let obj = {}
      if (conversation.kind === 'messenger') {
        obj.messengerId = conversation.receiver.messengerId
      } else {
        obj.phone = conversation.receiver.phone.replace('+', '')
      }
      setLoading(true)
      let result = await meetActions.createRoom(obj, conversation)
      setLoading(false)
      if (result.success) {
        refreshConversation(result.data.conversation)
      }
    } else if (value === 'remind_me') {
      onOpenModal && onOpenModal()
    }
  }

  return (
    <MatDropMenu
      bigText
      placement={'bottomRight'}
      showArrow={false}
      className={'header_actions_trigger_dropdown'}
      overlayStyle={{ width: '210px' }}
      options={dropOptions}
      onChange={(value) => {
        handleMessageCenterHeaderDropMenu(value)
      }}
      style={
        conversation.is_archived
          ? { position: 'relative', right: 'auto', top: 'auto' }
          : {}
      }
      disabled={conversation.kind === 'gbm'}
      trigger={
        <div className='message_center_mobile_dropdown'>
          {loading ? (
            <LoadingOutlined style={{ fontSize: 12 }} />
          ) : (
            <SVGThreePointVerticalIcon />
          )}
        </div>
      }
    />
  )
}

export default MessageCenterHeaderMobileButtons
