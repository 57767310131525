import React from 'react'
import { useNavigate } from 'react-router-dom'
import { formatDate, formatTime, MainColor } from '../../../utils'
import SVGSettingsOrg from '../../../icons/SVG/SVGSettingsOrg'
import MatRow from '../../../components/MatRow'
import ProfileLetters from '../../../components/ProfileLetters'

const OrganizationListItemFieldHeader = (props) => {
  const { item } = props

  const navigate = useNavigate()

  const handleSettings = () => {
    navigate(`/organization/settings/${item._id}/general`, {
      state: item,
    })
  }

  return (
    <div className='list-item-field-header'>
      <ProfileLetters name={item.name} withoutIcon />
      <MatRow spaceBetween>
        <div>
          <span className='item-name'> {item.name}</span>
          <span className='created-date'>
            {formatDate(item.createdAt)}&nbsp;{formatTime(item.createdAt)}
          </span>
        </div>

        <div className='edit-icon'>
          <SVGSettingsOrg
            color={MainColor}
            onClick={() => {
              handleSettings()
            }}
          />
        </div>
      </MatRow>
    </div>
  )
}

export default OrganizationListItemFieldHeader
