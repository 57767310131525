import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='8.762'
    height='8.763'
    viewBox='0 0 8.762 8.763'
  >
    <g id='noun_eyedropper_557608' transform='translate(19.55 2)'>
      <g id='Group_9014' data-name='Group 9014' transform='translate(-19.55 -2)'>
        <g id='Group_9013' data-name='Group 9013' transform='translate(0 0)'>
          <path
            id='Path_6925'
            data-name='Path 6925'
            d='M8.306,5.07l-.432-.432.862-.862a1.626,1.626,0,0,0-2.3-2.3l-.862.862-.432-.432a.61.61,0,0,0-.862.862l.432.432L1.549,6.364A6.9,6.9,0,0,0,.69,8.373a.813.813,0,1,0,1.15,1.15,6.907,6.907,0,0,0,2.009-.86L7.012,5.5l.432.432a.61.61,0,0,0,.862-.862ZM3.274,8.089a2.469,2.469,0,0,1-.534.273c-.1.042-.214.084-.331.126-.183.066-.371.128-.553.183-.108.033-.189.057-.232.068L1.417,8.8l.057-.208c.011-.043.035-.123.068-.232.055-.182.117-.37.183-.553.042-.117.085-.228.127-.331a2.456,2.456,0,0,1,.272-.534L5.287,3.776l1.15,1.15L3.274,8.089Z'
            transform='translate(-0.45 -1)'
            fill='#408ff0'
            fillRule='evenodd'
          />
        </g>
      </g>
    </g>
  </svg>
)

const SVGEditColorIcon = (props) => <Icon component={SVGIcon} {...props} />

export default SVGEditColorIcon
