import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={props.width || '18'}
    height={props.height || '20'}
    viewBox='0 0 18 20'
  >
    <path
      id='Calendar'
      d='M19.21,4.48a.753.753,0,0,1,.761.769V6a4.694,4.694,0,0,1,4.577,4.971l.011,8.426c0,3.138-1.968,5.069-5.128,5.074l-7.72.01a4.781,4.781,0,0,1-5.141-5.12l-.011-8.327A4.689,4.689,0,0,1,11.067,6.01V5.26a.76.76,0,1,1,1.52,0v.7l5.865-.008v-.7A.747.747,0,0,1,19.21,4.48Zm.4,14.192H19.6a.833.833,0,0,0-.809.852.83.83,0,1,0,.818-.852Zm-8.135,0a.853.853,0,0,0-.809.861.815.815,0,1,0,.809-.861Zm4.068-.005a.854.854,0,0,0-.809.861.815.815,0,1,0,.809-.861Zm-4.073-3.593a.852.852,0,0,0-.808.861.815.815,0,1,0,.808-.861Zm4.069-.035a.853.853,0,0,0-.809.861.815.815,0,1,0,.809-.861Zm4.068,0a.828.828,0,0,0-.809.84v.011a.813.813,0,0,0,.84.8.825.825,0,0,0-.032-1.649ZM18.454,7.489,12.589,7.5v.809a.76.76,0,1,1-1.52,0v-.77c-1.925.193-2.991,1.324-2.988,3.492v.311l14.948-.02v-.35c-.042-2.149-1.121-3.276-3.056-3.444V8.3a.76.76,0,1,1-1.52,0Z'
      transform='translate(-6.561 -4.479)'
      fill={props.color || '#A3A1B7'}
    />
  </svg>
)

const SVGDateIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGDateIcon
