import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width='22'
    height='22'
    viewBox='0 0 22 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M15.5859 19.25H6.41927C2.7526 19.25 1.83594 18.3333 1.83594 14.6667V7.33333C1.83594 3.66667 2.7526 2.75 6.41927 2.75H15.5859C19.2526 2.75 20.1693 3.66667 20.1693 7.33333V14.6667C20.1693 18.3333 19.2526 19.25 15.5859 19.25Z'
      stroke='#292D32'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.8359 7.33333H17.4193'
      stroke='#292D32'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M13.75 11H17.4167'
      stroke='#292D32'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M15.5859 14.6667H17.4193'
      stroke='#292D32'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7.79198 10.3492C8.70831 10.3492 9.45115 9.60633 9.45115 8.69C9.45115 7.77367 8.70831 7.03083 7.79198 7.03083C6.87565 7.03083 6.13281 7.77367 6.13281 8.69C6.13281 9.60633 6.87565 10.3492 7.79198 10.3492Z'
      stroke='#292D32'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.0026 14.9692C10.8743 13.64 9.8201 12.595 8.49094 12.4758C8.0326 12.43 7.5651 12.43 7.0976 12.4758C5.76844 12.6042 4.71427 13.64 4.58594 14.9692'
      stroke='#292D32'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

const SVGLocReview = (props) => (
  <Icon component={() => <SVGIcon {...props} />} {...props} />
)

export default SVGLocReview
