import React from 'react'
import MatBlockWithLabel from '../../../../components/MatBlockWithLabel'

const OrganizationLayoutBlock = ({ children, label }) => {
  return (
    <MatBlockWithLabel
      labelClassName={'organization-layout-block-label'}
      label={label}
      black
      className='organization-layout-block'
    >
      {children}
    </MatBlockWithLabel>
  )
}

export default OrganizationLayoutBlock
