const utilNumber = {
  getNumberForAbove99: (number) => {
    if (!number || !Number.isInteger(number)) {
      return 0
    }
    return number > 99 ? '99+' : number
  },
}

export default utilNumber
