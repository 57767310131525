import React, { useState } from 'react'
import MatSelectSecondary from '../../../../components/MatSelect/MatSelectSecondary'
import { getText } from '../../../../lang'
import organizationActions from '../../../../store/modules/organizationActions'
import { notifySuccess } from '../../../../utils/Notify'
import { DEFAULT, NIGHT } from '../../../../devOptions'

const TouchPointsItemNew = ({
  sequencesData,
  selectedRegularSequence,
  selectedAfterHourSequence,
  setselectedRegularSequence,
  setselectedAfterHourSequence,
  organizationId,
}) => {
  const [isLoading, setisLoading] = useState(false)

  const handleRemoveSequence = async (type, disableNotification) => {
    setisLoading(true)
    const result =
      await organizationActions.saveUpdateAutoAssignSequencesForNewTouchpoint(
        organizationId,
        'removeSequence',
        type === NIGHT ? selectedAfterHourSequence._id : selectedRegularSequence._id
      )
    if (result.success) {
      if (!disableNotification) {
        notifySuccess(getText('TEXT_SUCCESS_SEQUENCE_REMOVED'))
      }
      if (type === NIGHT) {
        setselectedAfterHourSequence()
      } else {
        setselectedRegularSequence()
      }
    }
    if (!disableNotification) {
      setisLoading(false)
    }
  }

  const handleAddSequence = async (sequence) => {
    setisLoading(true)
    if (sequence.type === NIGHT) {
      if (selectedAfterHourSequence) {
        await handleRemoveSequence(NIGHT, true)
      }
    } else {
      if (selectedRegularSequence) {
        await handleRemoveSequence(DEFAULT, true)
      }
    }
    const result =
      await organizationActions.saveUpdateAutoAssignSequencesForNewTouchpoint(
        organizationId,
        'addSequence',
        sequence._id
      )

    if (result.success) {
      notifySuccess(getText('TEXT_SUCCESS_SEQUENCE_ADDED'))
      if (sequence.type === NIGHT) {
        setselectedAfterHourSequence(sequence)
      } else {
        setselectedRegularSequence(sequence)
      }
    }
    setisLoading(false)
  }

  return (
    <div className='touchpoints-item' style={{ backgroundColor: '#EBF3FF' }}>
      <div className='new-touchpoint-title'>{getText('TEXT_NEW_TOUCHPOINTS')}</div>
      <MatSelectSecondary
        value={selectedRegularSequence}
        options={sequencesData.default}
        label={getText('TEXT_AUTO_ASSIGN_TO_THE_FOLLOWING_REGULAR_SEQUENCE')}
        placeholder={getText('WORD_SELECT_ITEM')}
        loading={isLoading}
        onChangeItem={(e, item) => {
          handleAddSequence(item)
        }}
        onClear={() => {
          if (!isLoading) {
            handleRemoveSequence(DEFAULT)
          }
        }}
        getPopupContainer={() => document.body}
      />
      <MatSelectSecondary
        value={selectedAfterHourSequence}
        options={sequencesData.night}
        loading={isLoading}
        placeholder={getText('WORD_SELECT_ITEM')}
        label={getText('TEXT_AUTO_ASSIGN_TO_FOLLOWING_AFTER_HOUR_SEQUENCE')}
        onChangeItem={(e, item) => {
          handleAddSequence(item)
        }}
        onClear={() => {
          if (!isLoading) {
            handleRemoveSequence(NIGHT)
          }
        }}
        getPopupContainer={() => document.body}
      />
    </div>
  )
}

export default TouchPointsItemNew
