import React, { useEffect, useState } from 'react'
import { getText } from '../../../lang'
import { formatAmount } from '../../../utils'
import { TABLE_DEFAULT_ROW_NUMBER } from '../../../devOptions'
import MatTable from '../../../components/MatTable'

const DepositDetailsListItemDepositSummary = ({ item }) => {
  const [dataSummary, setDataSummary] = useState([])

  useEffect(() => {
    if (item) {
      setDataSummary(
        item.transaction_list &&
          item.transaction_list.data.map((transItem, index) => {
            Object.assign(transItem, {
              key: index,
            })
            return transItem
          })
      )
    }
  }, [item])

  const scheduledDepositSummaryColumn = [
    {
      key: 'empty',
      title: '',
      render: (_, { reporting_category }) => {
        return reporting_category
          ? reporting_category.charAt(0).toUpperCase() + reporting_category.slice(1)
          : ''
      },
    },
    {
      key: 'gross',
      title: getText('WORD_GROSS'),
      sorter: (a, b) => a.net - b.net,
      render: (_, { net }) => {
        return <span className='payment-amount'>{formatAmount(net)}</span>
      },
    },
    {
      key: 'fee',
      title: getText('WORD_FEE'),
      sorter: (a, b) => a.fee - b.fee,
      render: (_, { fee }) => {
        return formatAmount(fee)
      },
    },
    {
      key: 'amount',
      title: getText('WORD_TOTAL'),
      sorter: (a, b) => a.amount - b.amount,
      render: (_, { amount }) => {
        return formatAmount(amount)
      },
    },
  ]

  return (
    <div className='deposit-details-top-info'>
      <MatTable
        columns={scheduledDepositSummaryColumn}
        dataSource={dataSummary}
        loading={false}
        defaultPageSize={TABLE_DEFAULT_ROW_NUMBER}
        paginationCurrentPage={0}
        paginationTotalCount={0}
        onPaginationChange={12}
        withoutPagination
        title={() => getText('WORD_DEPOSIT_SUMMARY')}
        scroll={{
          x: 479,
        }}
      />
    </div>
  )
}

export default DepositDetailsListItemDepositSummary
