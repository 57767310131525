import React from 'react'
import MatButton from '../MatButton'
import './AddVariableInMessage.scss'

const AddVariableInMessage = (props) => {
  const { onClick, data, disabled } = props

  return (
    <h6 className='list-variables'>
      {data.map((msg, index) => (
        <MatButton
          key={index}
          typeButton='text'
          className={`add-variable ${props.black ? 'add-variable-secondary' : ''}`}
          size='small'
          onClick={(e) => {
            e.preventDefault()
            onClick(msg.value)
          }}
          buttonText={`{{ ${msg.label} }}`}
          disabled={disabled}
        />
      ))}
    </h6>
  )
}

export default AddVariableInMessage
