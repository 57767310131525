import React, { forwardRef, useEffect, useState } from 'react'
import SelectFormSecondary from '../../../../../components/Form/SelectForm/SelectFormSecondary'

const getDelayOptions = () => {
  const list = [
    { value: 'new', label: 'New' },
    { value: 'used', label: 'Used' },
    { value: 'service', label: 'Service' },
    { value: 'parts', label: 'Parts' },
  ]
  return list
}

const SelectStatus = forwardRef((props, ref) => {
  const [listData, setListData] = useState([])
  const [loadingData, setLoadingData] = useState(true)

  useEffect(() => {
    setLoadingData(true)
    setListData(
      getDelayOptions().map((oo) => {
        return { value: oo.value, label: oo.label }
      })
    )
    setLoadingData(false)
  }, [props.location])

  return (
    <div>
      <SelectFormSecondary
        ref={ref}
        {...props}
        options={listData}
        loading={loadingData}
        showSearch={false}
        allowClear
      />
    </div>
  )
})

export default SelectStatus
