import React from "react";
import Icon from "@ant-design/icons";
import { MainColor } from "../../utils";

const SVGIcon = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.66406 6.66634C1.66406 4.82539 3.15645 3.33301 4.9974 3.33301H10.8307C12.6717 3.33301 14.1641 4.82539 14.1641 6.66634V13.333C14.1641 15.174 12.6717 16.6663 10.8307 16.6663H4.9974C3.15645 16.6663 1.66406 15.174 1.66406 13.333V6.66634Z"
      stroke={props.color || MainColor}
      strokeWidth="1.5"
    />
    <path
      d="M14.1641 7.77745L15.4482 6.40774C16.4815 5.30548 18.3307 6.03675 18.3307 7.54764V12.4517C18.3307 13.9626 16.4815 14.6939 15.4482 13.5916L14.1641 12.2219V7.77745Z"
      stroke={props.color || MainColor}
      strokeWidth="1.5"
    />
    <path
      d="M10.8307 8.33301C10.8307 9.25348 10.0845 9.99967 9.16406 9.99967C8.24359 9.99967 7.4974 9.25348 7.4974 8.33301C7.4974 7.41253 8.24359 6.66634 9.16406 6.66634C10.0845 6.66634 10.8307 7.41253 10.8307 8.33301Z"
      stroke={props.color || MainColor}
      strokeWidth="1.5"
    />
  </svg>
);

const SVGVideo = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />;
      }}
      {...props}
    />
  );
};

export default SVGVideo;
