import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useMixpanel } from 'react-mixpanel-browser'
import authActions from '../../store/modules/authActions'
import { getText } from '../../lang'
import { moveToLoginPage, setMixpanel } from '../../utils'
import { notifyError } from '../../utils/Notify'

const RedirectMagic = () => {
  const mixpanel = useMixpanel()

  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const token = searchParams.get('token')
  const email = searchParams.get('email')
  const phone = searchParams.get('phone')
  const isNotificationToken = searchParams.get('isNotificationToken')
  const redirect = searchParams.get('redirect')

  useEffect(() => {
    const fill = async () => {
      const result = await authActions.redirectMagic(
        token,
        email,
        phone,
        isNotificationToken
      )
      if (result.success) {
        authActions.storeAuthData(
          result.data.user,
          result.data.token,
          result.data.unread_messages_count
        )

        const user = authActions.getUserData()
        setMixpanel(
          mixpanel,
          'Login via magic link',
          user.createdAt,
          user.fullName,
          user.organization.name,
          user.location.name,
          null,
          user.organization._id,
          user.location._id,
          user._id
        )

        if (redirect) {
          navigate(redirect)
        } else if (user.isSupportAgent) {
          navigate('/messages')
        } else {
          navigate('/dashboard')
        }
      } else {
        notifyError(
          result.errMsg
            ? result.errMsg.startsWith('child')
              ? getText('ERR_GENERAL')
              : result.errMsg
            : getText('ERR_GENERAL')
        )
        moveToLoginPage(navigate)
      }
    }
    fill()
  }, [])

  return <></>
}

export default RedirectMagic
