import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width='44'
    height='44'
    viewBox='0 0 44 44'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect width='44' height='44' rx='22' fill='#5B66EA' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M20.3307 14.5H21.2831C23.9792 14.5 26.2393 16.4061 26.8406 18.9703C26.9431 19.4076 26.9974 19.864 26.9974 20.3333C26.9974 23.555 24.439 26.1667 21.2831 26.1667H17.7987H15.5688C14.5169 26.1667 13.6641 25.2961 13.6641 24.2222V21.3056C13.6641 17.547 16.6488 14.5 20.3307 14.5ZM21.5212 27.8333H18.7125C19.7432 28.8643 21.1547 29.5 22.7117 29.5H26.196H28.426C29.4779 29.5 30.3307 28.6294 30.3307 27.5555V24.6389C30.3307 22.9115 29.7003 21.3345 28.6616 20.1345C28.6632 20.2005 28.6641 20.2668 28.6641 20.3333C28.6641 24.4755 25.4661 27.8333 21.5212 27.8333Z'
      fill='white'
    />
  </svg>
)

const SVGEmptyConversationMobile = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGEmptyConversationMobile
