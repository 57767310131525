import React from 'react';
import Icon from '@ant-design/icons';

const SVGIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="15.562" height="16.5" viewBox="0 0 15.562 16.5">
        <g id="Group_8656" data-name="Group 8656" transform="translate(942.1 -955.2)">
            <path id="Path_6088" data-name="Path 6088" d="M-913.513,979.793a4.1,4.1,0,0,0-4.093-4.093,4.1,4.1,0,0,0-4.093,4.093,3.612,3.612,0,0,0,.126.974,3.821,3.821,0,0,0,.577,1.3c.054.108.126.216.2.343.09.144.162.289.252.415a2.752,2.752,0,0,1,.577,1.641v1.7a.854.854,0,0,0,.721.848,1.616,1.616,0,0,0,1.641,1.5,1.6,1.6,0,0,0,1.641-1.5.868.868,0,0,0,.721-.848v-1.7a2.711,2.711,0,0,1,.577-1.641c.072-.126.162-.271.252-.415.072-.126.144-.234.2-.343a3.821,3.821,0,0,0,.577-1.3A3.612,3.612,0,0,0-913.513,979.793Zm-2.434,5.1h-3.282v-.415a.954.954,0,0,0-.018-.234h3.3c0,.072-.018.144-.018.234v.415Zm-.162,1.425H-919.1a.142.142,0,0,1-.144-.144v-.559h3.282v.559C-915.948,986.249-916.02,986.321-916.11,986.321Zm-1.5,1.479c-.2,0-.7,0-.9-.757h1.821A.864.864,0,0,1-917.607,987.8Zm3.264-7.177a3.355,3.355,0,0,1-.487,1.082c-.072.108-.126.216-.2.343-.09.144-.162.288-.234.4a5.374,5.374,0,0,0-.541,1.064h-3.57a4.557,4.557,0,0,0-.541-1.064c-.072-.126-.162-.253-.234-.4-.072-.126-.144-.252-.2-.343a3.435,3.435,0,0,1-.487-1.1,3.135,3.135,0,0,1-.108-.812,3.379,3.379,0,0,1,3.372-3.372,3.379,3.379,0,0,1,3.372,3.372A7.288,7.288,0,0,1-914.343,980.623Z" transform="translate(-16.721 -16.803)" fill="#f8a326" />
            <path id="Path_6089" data-name="Path 6089" d="M-866.243,996.4h-1.5a.362.362,0,0,0-.361.361.362.362,0,0,0,.361.361h1.5a.362.362,0,0,0,.361-.361A.362.362,0,0,0-866.243,996.4Z" transform="translate(-60.656 -33.771)" fill="#f8a326" />
            <path id="Path_6090" data-name="Path 6090" d="M-877.439,969.085a.356.356,0,0,0,.252-.108l1.064-1.064a.349.349,0,0,0,0-.5.349.349,0,0,0-.5,0l-1.064,1.064a.349.349,0,0,0,0,.5A.356.356,0,0,0-877.439,969.085Z" transform="translate(-52.705 -9.918)" fill="#f8a326" />
            <path id="Path_6091" data-name="Path 6091" d="M-900.639,957.418a.362.362,0,0,0,.361-.361v-1.5a.362.362,0,0,0-.361-.361.362.362,0,0,0-.361.361v1.5A.362.362,0,0,0-900.639,957.418Z" transform="translate(-33.689)" fill="#f8a326" />
            <path id="Path_6092" data-name="Path 6092" d="M-928.928,968.977a.356.356,0,0,0,.252.108.356.356,0,0,0,.253-.108.349.349,0,0,0,0-.5l-1.064-1.064a.349.349,0,0,0-.5,0,.349.349,0,0,0,0,.5Z" transform="translate(-9.836 -9.918)" fill="#f8a326" />
            <path id="Path_6093" data-name="Path 6093" d="M-940.243,996.4h-1.5a.362.362,0,0,0-.361.361.362.362,0,0,0,.361.361h1.5a.362.362,0,0,0,.361-.361A.362.362,0,0,0-940.243,996.4Z" transform="translate(0 -33.771)" fill="#f8a326" />
            <path id="Path_6094" data-name="Path 6094" d="M-928.928,1019.708l-1.064,1.064a.349.349,0,0,0,0,.5.356.356,0,0,0,.252.108.356.356,0,0,0,.252-.108l1.064-1.064a.349.349,0,0,0,0-.5A.349.349,0,0,0-928.928,1019.708Z" transform="translate(-9.836 -52.787)" fill="#f8a326" />
            <path id="Path_6095" data-name="Path 6095" d="M-877.187,1019.708a.349.349,0,0,0-.5,0,.349.349,0,0,0,0,.5l1.064,1.064a.356.356,0,0,0,.253.108.356.356,0,0,0,.252-.108.349.349,0,0,0,0-.5Z" transform="translate(-52.705 -52.787)" fill="#f8a326" />
        </g>
    </svg>
);

const SVGDetectedLanguage = props => <Icon component={SVGIcon} {...props} />;

export default SVGDetectedLanguage;