import React from 'react'
import SVGVideoStatsHeader from '../../../../../icons/SVG/SVGVideoStatsHeader'
import SVGVideoStatsHeaderFirstCircles from '../../../../../icons/SVG/SVGVideoStatsHeaderFirstCircles'
import SVGVideoStatsHeaderThirdCircles from '../../../../../icons/SVG/SVGVideoStatsHeaderThirdCircles'
import SVGVideoStatsHeaderSecondCircles from '../../../../../icons/SVG/SVGVideoStatsHeaderSecondCircles'
import SVGVideoStatsHeaderVideoCircle from '../../../../../icons/SVG/SVGVideoStatsHeaderVideoCircle'
import SVGVideoStatsHeaderTimeCircle from '../../../../../icons/SVG/SVGVideoStatsHeaderTimeCircle'
import { getText } from '../../../../../lang'
import VideoStatsItem from './VideoStatsItem'
import './VideoStats.scss'

const VideoStats = ({
  count_videos_sent,
  count_videos_watched,
  count_seconds_watched,
  screenSize,
}) => {
  const secondsToHms = (d) => {
    d = Number(d)
    const h = Math.floor(d / 3600)
    const m = Math.floor((d % 3600) / 60)
    const s = Math.floor((d % 3600) % 60)

    const hDisplay = h > 0 ? h + 'h ' : ''
    const mDisplay = m > 0 ? m + 'm' : ''
    const sDisplay = s > 0 && m === 0 ? m + 1 + 'm' : 0
    const lastResult = sDisplay || mDisplay
    return d === 0 ? 0 : hDisplay + lastResult
  }

  const getMinute =
    count_seconds_watched === 0
      ? true
      : Math.floor(count_seconds_watched / 3600) === 0
        ? true
        : false

  return (
    <div className={'bot-and-rating video-stats-block-wrapper'}>
      <div className='header'>
        <div className='header-icon'>
          <SVGVideoStatsHeader />
        </div>
        <div className='header-text'>{getText('WORD_VIDEO_STATS')}</div>
      </div>
      <div className='video-stats-items-row'>
        <VideoStatsItem
          screenSize={screenSize}
          icon={<SVGVideoStatsHeaderFirstCircles />}
          color={'blue'}
          value={count_videos_sent || 0}
          title={getText('TEXT_VIDEO_SENT')}
          secondIcon={
            <SVGVideoStatsHeaderVideoCircle
              className={'centered-circle-icon left'}
            />
          }
        />
        <VideoStatsItem
          screenSize={screenSize}
          icon={<SVGVideoStatsHeaderSecondCircles />}
          color={'green'}
          value={count_videos_watched || 0}
          title={getText('TEXT_VIDEO_WATCHED')}
          secondIcon={
            <SVGVideoStatsHeaderTimeCircle className={'centered-circle-icon left'} />
          }
        />
        <VideoStatsItem
          screenSize={screenSize}
          color={'orange'}
          icon={<SVGVideoStatsHeaderThirdCircles />}
          value={secondsToHms(count_seconds_watched || 0)}
          title={
            getMinute
              ? getText('TEXT_MINUTES_WATCHED')
              : getText('TEXT_HOURS_WATCHED')
          }
        />
      </div>
    </div>
  )
}

export default VideoStats
