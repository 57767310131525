import React from 'react'
import DropMeuManagers from './DropMeuManagers'

const AssignManager = (props) => {
  const { conversation, refreshConversation, addToList, screenSize } = props

  return conversation.isNew ? null : (
    <div className='message_page_assign_user_list'>
      <DropMeuManagers
        screenSize={screenSize}
        conversation={conversation}
        refreshConversation={refreshConversation}
        addToList={addToList}
      />
    </div>
  )
}

export default React.memo(AssignManager)
