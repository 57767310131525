import React from 'react'
import { DownloadOutlined } from '@ant-design/icons'
import { getText } from '../../../lang'
import SVGDepositDateIcon from '../../../icons/SVG/SVGDepositDateIcon'
import SVGDepositATBIcon from '../../../icons/SVG/SVGDepositATBIcon'
import MatButton from '../../../components/MatButton'
import { amountValue, getDepositStatus } from '../PaymentPageUtils'
import utilDate from '../../../utils/utilsDate'
import MatRow from '../../../components/MatRow'
import MatBlockWithLabel from '../../../components/MatBlockWithLabel'

const DepositDetailsListItemGeneralInfo = ({ item }) => {
  return (
    <div className='deposit-details-top-info'>
      <div className='payment-details-info-with-export'>
        <div className='amount-with-status'>
          <span className='amount-currency'>
            {amountValue(item.amount, item.currency.toUpperCase())}
          </span>
          {getDepositStatus(item.status)}
        </div>
        <MatButton
          typeButton={'radius'}
          buttonText={getText('WORD_EXPORT')}
          icon={<DownloadOutlined />}
        />
      </div>
      <span className='deposit-fee-payout-info'>{item.description}</span>
      <div className='deposit-date-info'>
        <SVGDepositDateIcon />
        &nbsp;
        {getText('WORD_DEPOSIT_ON')}&nbsp;
        {utilDate.getDateTimeForPayment(item.updatedAt, 'dddd, MMMM Do')}
      </div>
      <MatBlockWithLabel black label={getText('WORD_DEPOSIT_TO')}>
        <MatRow className='deposit-to-block'>
          <SVGDepositATBIcon />
          <div>
            <span className='atb-title'>
              {item.destination && item.destination.bank_name}
            </span>
            <p className='atb-numbers'>
              **** **** **** {item.destination && item.destination.last4}
            </p>
          </div>
        </MatRow>
      </MatBlockWithLabel>
    </div>
  )
}

export default DepositDetailsListItemGeneralInfo
