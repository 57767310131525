import React, { forwardRef, useCallback } from 'react'
import { Button, Empty, Select } from 'antd'
import { CloseCircleFilled, DownOutlined } from '@ant-design/icons'
import { debounce } from 'lodash'
import { getText } from '../../lang'
import LoadingSpinner from '../LoadingSpinner'

const SelectFilter = forwardRef((props, ref) => {
  const debouncedChange = useCallback(
    debounce((options) => props.onSearch && props.onSearch(options), 700),
    []
  )

  return (
    <div className='mat-select-wrapper-secondary'>
      {props.label ? <label className='select-label'>{props.label}</label> : null}
      <Select
        className={`mat-select ${props.className || ''}`}
        ref={ref}
        placeholder={props.placeholder}
        mode={props.mode}
        defaultValue={props.defaultValue}
        popupClassName={
          'mat-form-select-dropdown ' +
          (props.primary ? 'primary ' : '') +
          (props.dropdownClassName || '')
        }
        loading={props.loading}
        disabled={props.disabled}
        onSearch={props.showSearch && debouncedChange}
        showSearch={props.showSearch}
        suffixIcon={props.showArrow || <DownOutlined />}
        allowClear={{
          clearIcon: (
            <Button>
              <CloseCircleFilled />
            </Button>
          ),
        }}
        onClear={props.onClear}
        labelInValue={props.labelInValue}
        value={props.value}
        onDropdownVisibleChange={props.onDropdownVisibleChange}
        getPopupContainer={(trigger) => trigger.parentNode}
        notFoundContent={
          props.loading ? (
            <LoadingSpinner />
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )
        }
        filterOption={
          props.hasOwnProperty('filterOption')
            ? props.filterOption
            : (input, option) => {
                return (
                  option.label
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toString().toLowerCase()) > -1
                )
              }
        }
        onChange={(e) => {
          props.onChange && props.onChange(e)
          if (props.onChangeItem && props.option) {
            if (props.mode === 'multiple') {
              let find = props.option.filter((oo) => {
                return e.includes(oo.value)
              })
              if (find) {
                props.onChangeItem(e, find)
              }
            } else {
              let find = props.option.find((oo) => {
                return oo.value === e
              })
              if (find) {
                props.onChangeItem(e, find)
              }
            }
          }
        }}
        maxTagCount={props.maxTagCount}
        onSelect={(e, ee) => {
          props.onSelect && props.onSelect(e, ee)
          if (props.onSelectItem && props.option) {
            if (props.mode === 'multiple') {
              let find = props.option.filter((oo) => {
                return e.includes(oo.value)
              })
              if (find) {
                props.onSelectItem(e, find)
              }
            } else {
              let find = props.option.find((oo) => {
                return oo.value === e
              })
              if (find) {
                props.onSelectItem && props.onSelectItem(e, find)
              }
            }
          }
        }}
        onPopupScroll={(e) => {
          e.persist()
          props.onPopupScroll && props.onPopupScroll(e)
          if (
            Math.ceil(e.target.scrollTop + e.target.offsetHeight) ===
            e.target.scrollHeight
          ) {
            props.onLoadMore && props.onLoadMore()
          }
        }}
        onDeselect={props.onDeselect}
      >
        {props.option.map((option, index) => {
          return (
            <Select.Option
              key={option.value + '_' + index}
              value={option.value}
              label={option.label}
            >
              {' '}
              {option.label}{' '}
            </Select.Option>
          )
        })}
      </Select>
    </div>
  )
})

SelectFilter.defaultProps = {
  label: '',
  placeholder: getText('WORD_SELECT_ITEM'),
  message: `${getText('WORD_PLEASE_SELECT_ITEM')}!`,
  allowClear: true,
  showSearch: true,
}

export default SelectFilter
