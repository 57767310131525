import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = () => (
  <svg
    width='50'
    height='50'
    viewBox='0 0 50 50'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle opacity='0.1' cx='25' cy='25' r='25' fill='#FABC05' />
    <path
      d='M17.8179 31.1069C17.9607 31.2049 18.5117 31.4784 18.977 30.8906L22.1281 26.2865L25.4628 29.5029C25.6383 29.6702 25.8791 29.76 26.124 29.7314C26.3648 29.7069 26.5852 29.5804 26.7281 29.3804L32.2587 21.4498L32.4587 24.1029C32.5077 24.7763 33.1403 24.8865 33.3526 24.8702C33.8097 24.8335 34.1566 24.4376 34.1199 23.9763L33.7689 19.311C33.7362 18.8539 33.3362 18.5069 32.875 18.5437L28.2097 18.8947C27.7526 18.9274 27.4056 19.3274 27.4423 19.7886C27.475 20.2457 27.8709 20.5886 28.3362 20.5559L30.9893 20.3559L25.9199 27.6212L22.577 24.3967C22.4015 24.2253 22.1566 24.1396 21.9158 24.1682C21.6709 24.1927 21.4505 24.3233 21.3117 24.5274L17.6015 29.9478C17.3403 30.3274 17.4383 30.8457 17.8179 31.1069Z'
      fill='#FABC05'
    />
    <path
      d='M34.1673 33.3347H16.6653V15.8327C16.6653 15.3714 16.2939 15 15.8327 15C15.3714 15 15 15.3714 15 15.8327V34.1673C15 34.6286 15.3714 35 15.8327 35H34.1673C34.6286 35 35 34.6286 35 34.1673C35 33.7061 34.6286 33.3347 34.1673 33.3347Z'
      fill='#FABC05'
    />
  </svg>
)

export const SVGNpsGraphic = (props) => <Icon component={SVGIcon} {...props} />
