import React from "react"

function SVGSent(props) {
  return (
    <svg width={40} height={40} viewBox="0 0 40 40" {...props}>
      <g data-name="Group 7439" transform="translate(-592 -380)">
        <circle
          data-name="Ellipse 624"
          cx={20}
          cy={20}
          r={20}
          transform="translate(592 380)"
          fill={props.color ? props.color: "#00cb85"}
          opacity={0.15}
        />
        <g data-name="Group 7438">
          <path
            data-name="Path 948"
            d="M612.766 397.287a.6.6 0 11-.6-.6.563.563 0 01.6.6zm1.789-.6a.6.6 0 10.6.6.563.563 0 00-.6-.596zm2.385 0a.6.6 0 10.6.6.563.563 0 00-.6-.596zm3.577-1.192v7.154a.6.6 0 01-1.192 0 1.2 1.2 0 00-1.192-1.192h-2.386v1.788a2.392 2.392 0 01-2.385 2.385h-7.154a1.2 1.2 0 00-1.192 1.192.563.563 0 01-.6.6.563.563 0 01-.6-.6v-7.15a2.392 2.392 0 012.392-2.385h2.385v-1.788a2.392 2.392 0 012.385-2.385h7.155a2.392 2.392 0 012.384 2.385zm-5.962 5.961h-3.577a2.392 2.392 0 01-2.385-2.381v-.6h-2.385a1.2 1.2 0 00-1.192 1.192v5.067a2.714 2.714 0 011.192-.3h7.155a1.2 1.2 0 001.192-1.192zm4.768-5.957a1.2 1.2 0 00-1.192-1.192h-7.153a1.2 1.2 0 00-1.193 1.192v3.576a1.2 1.2 0 001.192 1.192h7.155a2.714 2.714 0 011.192.3z"
            fill={props.color ? props.color: "#00cb85"}
            opacity={0.8}
          />
        </g>
      </g>
    </svg>
  )
}

export default SVGSent
