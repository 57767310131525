import React, { forwardRef, useEffect, useState } from 'react'
import { getText } from '../../lang'
import SelectFormSecondary from '../Form/SelectForm/SelectFormSecondary'

const getTriggerOptions = () => {
  const list = [
    { value: '', label: getText('WORD_SELECT_TRIGGER') },
    { value: 'exit-intent', label: getText('WORD_EXIT_INTENT') },
    { value: '5', label: `5 ${getText('WORD_SECONDS')}` },
    { value: '10', label: `10 ${getText('WORD_SECONDS')}` },
    { value: '15', label: `15 ${getText('WORD_SECONDS')}` },
    { value: '20', label: `20 ${getText('WORD_SECONDS')}` },
    { value: '25', label: `25 ${getText('WORD_SECONDS')}` },
    { value: '30', label: `30 ${getText('WORD_SECONDS')}` },
    { value: '60', label: `1 ${getText('WORD_MINUTE').toLowerCase()}` },
  ]

  return list
}

const SelectTrigger = forwardRef((props, ref) => {
  const [listData, setListData] = useState([])
  const [loadingData, setLoadingData] = useState(true)

  useEffect(() => {
    setLoadingData(true)
    setListData(
      getTriggerOptions().map((oo) => {
        return { value: oo.value, label: oo.label }
      })
    )
    setLoadingData(false)
  }, [props.location])

  return (
    <SelectFormSecondary
      ref={ref}
      {...props}
      options={listData}
      loading={loadingData}
      showSearch={false}
    />
  )
})

export default SelectTrigger
