import React from 'react'
import { Checkbox } from 'antd'
import '../Form/MatFormCheckbox/MatCheckbox.scss'

const MatCheckboxSecondary = (props) => {
  const { onChange, label = 'Checkbox', disabled, value, className, style } = props
  return (
    <Checkbox
      className={`mat-checkbox-wrapper mat-checkbox-secondary-wrapper ${
        props.black ? 'mat-checkbox-black' : ''
      } ${className || ''}`}
      onChange={(e) => {
        onChange && onChange(e)
      }}
      disabled={disabled}
      checked={value}
      style={style}
    >
      {label}
    </Checkbox>
  )
}

export default MatCheckboxSecondary
