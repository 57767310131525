import React from 'react'
import MatRadioButtonSecondary from '../../../components/MatRadioButton/MatRadioButtonSecondary'

const LanguageSection = (props) => {
  const { dispatch, getSelectedLanguage, getRolesOptions } = props

  return (
    <>
      <div className='users-page-modal-checkbox-items'>
        <MatRadioButtonSecondary
          radioOptions={getRolesOptions()}
          value={getSelectedLanguage()}
          onChange={(e) => {
            dispatch({ type: 'CHANGE', name: 'language', payload: e.target.value })
          }}
        />
      </div>
    </>
  )
}

export default LanguageSection
