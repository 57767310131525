import React, { useEffect, useState } from 'react'
import { Empty, Carousel } from 'antd'
import { uploadAndFetchGETFile } from '../../../../../utils/UrlHelper'
import './Automations.scss'
import LoadingSpinner from '../../../../../components/LoadingSpinner'
import { SampleNextArrow, SamplePrevArrow } from '../../Dashboard/SampleArrows'
import { getText } from '../../../../../lang'
import AutomationImage from '../../../../../icons/automationgif.gif'
import Automation from './Automation'

const groupSize = 4
const settings = {
  dots: false,
  infinite: true,
  arrows: true,
  speed: 500,
  slidesToShow: 1,
  variableWidth: true,
  slidesToScroll: 1,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
}

const Automations = ({
  screenSize,
  _location_id,
  selectedRange,
  menuOpen,
  reminders,
}) => {
  const [automationData, setAutomationData] = useState([])
  const [loading, setLoading] = useState(true)

  const getAudience = (selectedRange, reminders) => {
    if (selectedRange === 'ytd') {
      return { yesterday: reminders }
    } else if (selectedRange === 'today') {
      return { today: reminders }
    } else if (selectedRange === 'mtd') {
      return { monthToDay: reminders }
    } else if (selectedRange === 'last_month') {
      return { lastMonth: reminders }
    }
    return { allTime: reminders }
  }

  useEffect(() => {
    const fill = async () => {
      const result = await uploadAndFetchGETFile(
        `https://api.automations.matador.ai/v1/locations/${_location_id}/automations`,
        '',
        true
      )
      if (result.success) {
        let list = []
        list = [...result.data.data]
        const reminderObj = {
          message: 'sms sent',
          name: 'Calendar reminder',
          audience: getAudience(selectedRange, reminders),
        }
        list.push(reminderObj)
        setAutomationData(list)
      }
      setLoading(false)
    }
    fill()
  }, [_location_id])

  return (
    <div className='automations'>
      <div className='dashboard-page-header-right'>
        <span id='dashboard-header-right'>{getText('AUTOMATIONS')}</span>
        <div className='dashboard-header-right-button'>
          <span>{getText('WORD_ACTIVE')}</span>
          <span>({(automationData && automationData.length) || 0})</span>
        </div>
      </div>
      <div className='automation-with-image'>
        {automationData && automationData.length > 0 && screenSize === 'desktop' && (
          <div className='aut-img-wrapper'>
            <img src={AutomationImage} alt='Automation' />
          </div>
        )}
        <div className='automation-content'>
          {!loading ? (
            (screenSize === 'desktop' && window.innerWidth > 1580) ||
            (screenSize === 'desktop' && window.innerWidth > 1400 && !menuOpen) ? (
              automationData && automationData.length > 0 ? (
                automationData.map((item, index) => (
                  <Automation
                    key={index}
                    index={index}
                    item={item}
                    screenSize={screenSize}
                    selectedRange={selectedRange}
                  />
                ))
              ) : (
                <Empty
                  style={{
                    width: '100%',
                  }}
                  image={AutomationImage}
                  description={getText(
                    'WORD_PLEASE_CONTACT_CLIENTS_SUCCESS_TO_ACTIVATE_AUTOMATIONS'
                  )}
                />
              )
            ) : automationData && automationData.length > 0 ? (
              <Carousel {...settings}>
                {automationData
                  .map((item, index) => (
                    <Automation
                      key={index}
                      index={index}
                      item={item}
                      screenSize={screenSize}
                      selectedRange={selectedRange}
                    />
                  ))
                  .reduce((r, element, index) => {
                    index % groupSize === 0 && r.push([])
                    r[r.length - 1].push(element)
                    return r
                  }, [])
                  .map((rowContent, index) => {
                    return (
                      <div key={index} className='slider-row'>
                        {rowContent}
                      </div>
                    )
                  })}
              </Carousel>
            ) : (
              <Empty
                style={{
                  width: '100%',
                }}
                image={AutomationImage}
                description={getText(
                  'WORD_PLEASE_CONTACT_CLIENTS_SUCCESS_TO_ACTIVATE_AUTOMATIONS'
                )}
              />
            )
          ) : (
            <LoadingSpinner style={{ width: '100%' }} />
          )}
        </div>
      </div>
    </div>
  )
}

export default Automations
