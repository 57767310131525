import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getText } from '../../../lang'
import SVGUploadExcel from '../../../icons/SVG/SVGUploadExcel'
import SVGUploadSegment from '../../../icons/SVG/SVGUploadSegment'
import MatButton from '../../../components/MatButton'
import MatRow from '../../../components/MatRow'
import BroadcastCampaignPage from '../BroadcastCampaignPage'
import CreateCampaignType from './components/CreateCampaignType'

const CreateCampaign = (props) => {
  const [bgColor, setBgColor] = useState('transparent')
  const navigate = useNavigate()

  const buttonBg = {
    backgroundColor: `${bgColor}`,
    maxWidth: `200px`,
  }

  return (
    <BroadcastCampaignPage
      title={getText('UPLOAD_EXCEL_FILE')}
      subTitle={getText('TEXT_CREATE_CAMPING_TYPE_DESCRIPTION')}
      className='first-create-campaign-page'
    >
      <CreateCampaignType
        icon={<SVGUploadExcel />}
        title={getText('TEXT_UPLOAD_WITH_EXCEL_FILE')}
        description={getText('TEXT_UPLOAD_WITH_EXCEL_FILE_INFO')}
        action={() => {
          navigate('/broadcasting/create-campaign/file-upload')
        }}
        btnName={getText('TEXT_UPLOAD_FILE')}
      />
      <CreateCampaignType
        icon={<SVGUploadSegment />}
        title={getText('TEXT_CREATE_A_SEGMENT')}
        description={getText('TEXT_CREATE_A_SEGMENT_INFO')}
        action={() => {
          navigate('/broadcasting/create-campaign/create-segment')
        }}
        btnName={getText('WORD_CREATE')}
      />
      <MatRow style={{ justifyContent: 'center', marginTop: '30px' }}>
        <MatButton
          buttonText={getText('ACTION_BACK')}
          onClick={() => {
            navigate('/broadcasting/list')
          }}
          typeButton={'cancel'}
          size='large'
          style={buttonBg}
          onMouseEnter={() => setBgColor('#fff')}
          onMouseLeave={() => setBgColor('transparent')}
        />
      </MatRow>
    </BroadcastCampaignPage>
  )
}

export default CreateCampaign
