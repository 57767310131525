import React from 'react'
import { getText } from '../../../lang'
import MatRow from '../../../components/MatRow'
import ProfileLetters from '../../../components/ProfileLetters'
import LeaderboardListFieldItem from './LeaderboardListFieldItem'

const Iconset = {
  send: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='11.476'
      height='11.132'
      viewBox='0 0 11.476 11.132'
    >
      <path
        id='Union_3'
        data-name='Union 3'
        d='M4.019,11.13c.029-.079-.934-2.4-.934-2.4-.188-.451.029-.671.483-.486L4.6,8.67a.712.712,0,0,1,.337,1.085s-.9,1.378-.923,1.378T4.019,11.13ZM8.091,9.437l-1.5-.61c-.454-.183-1.2-.48-1.658-.658l-.157-.061a.56.56,0,0,1-.22-.974L9.189,2.172c.337-.358.294-.4-.09-.1L3.236,6.7a1.73,1.73,0,0,1-1.534.22L.355,6.383C-.1,6.2-.12,5.869.315,5.64L10.885.095c.435-.228.687-.026.557.449L9.16,8.93a.76.76,0,0,1-.743.572A.853.853,0,0,1,8.091,9.437Z'
        transform='translate(0 -0.001)'
      />
    </svg>
  ),
  link: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='11.041'
      height='11.042'
      viewBox='0 0 11.041 11.042'
    >
      <g id='noun_link_965819' transform='translate(-0.009 0.011)'>
        <g
          id='Group_1304'
          data-name='Group 1304'
          transform='translate(0.009 -0.011)'
        >
          <path
            id='Path_1166'
            data-name='Path 1166'
            d='M2.583,26.772a2.576,2.576,0,0,1-1.822-4.4L3.1,20.034a2.576,2.576,0,0,1,3.211-.348,2.5,2.5,0,0,1,.432.348.729.729,0,0,1,0,1.034.754.754,0,0,1-1.034,0,1.1,1.1,0,0,0-1.56,0L1.8,23.416a1.1,1.1,0,0,0,1.562,1.562l1.389-1.391a.184.184,0,0,1,.2-.04,3.1,3.1,0,0,0,1.178.228H6.2a.184.184,0,0,1,.131.315L4.4,26.022a2.559,2.559,0,0,1-1.82.751Z'
            transform='translate(-0.009 -15.73)'
          />
          <path
            id='Path_1167'
            data-name='Path 1167'
            d='M24.259,7.482h-.14a2.563,2.563,0,0,1-.274-.026c-.075-.013-.149-.028-.223-.046l-.109-.029c-.039-.011-.079-.024-.118-.039s-.079-.028-.118-.044-.077-.031-.116-.05a2.554,2.554,0,0,1-.736-.515.734.734,0,0,1,0-1.038.754.754,0,0,1,1.034,0,1.1,1.1,0,0,0,1.56,0l.6-.6.015-.017,1.726-1.724A1.1,1.1,0,0,0,25.8,1.793L24.418,3.176a.184.184,0,0,1-.2.04,3.091,3.091,0,0,0-1.176-.23h-.068a.185.185,0,0,1-.134-.315L24.769.744a2.576,2.576,0,1,1,3.643,3.643L26.07,6.729a2.576,2.576,0,0,1-1.194.677h-.02l-.107.022-.059.011-.127.018H24.5c-.048,0-.09.009-.131.011Z'
            transform='translate(-18.126 0.011)'
          />
        </g>
      </g>
    </svg>
  ),
  recommend: (
    <svg
      id='Livello_2'
      data-name='Livello 2'
      xmlns='http://www.w3.org/2000/svg'
      width='11.059'
      height='11.185'
      viewBox='0 0 11.059 11.185'
    >
      <g id='Livello_1' data-name='Livello 1'>
        <path
          id='Path_1190'
          data-name='Path 1190'
          d='M10.468,0H.59A.592.592,0,0,0,0,.59V7.842a.592.592,0,0,0,.59.59H2.073v2.361a.4.4,0,0,0,.676.276L5.391,8.432h5.077a.592.592,0,0,0,.59-.59V.59A.592.592,0,0,0,10.468,0ZM8.088,3.76l-1.321.961a.169.169,0,0,0-.061.188l.5,1.554a.169.169,0,0,1-.26.188L5.629,5.69a.169.169,0,0,0-.2,0L4.11,6.65a.169.169,0,0,1-.26-.188l.5-1.552a.169.169,0,0,0-.061-.188L2.971,3.76a.169.169,0,0,1,.1-.3H4.7a.169.169,0,0,0,.16-.116l.5-1.554a.169.169,0,0,1,.321,0l.5,1.554a.169.169,0,0,0,.169.116h1.63a.169.169,0,0,1,.1.3Z'
        />
      </g>
    </svg>
  ),
  clicked: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='12.506'
      height='12.52'
      viewBox='0 0 12.506 12.52'
    >
      <g id='Group_1394' data-name='Group 1394' transform='translate(0)'>
        <path
          id='Path_1192'
          data-name='Path 1192'
          d='M30.725,33.987a.469.469,0,0,1,.308-.4l8.151-2.835a.459.459,0,0,1,.586.586l-2.835,8.151a.469.469,0,0,1-.4.308H36.5a.444.444,0,0,1-.4-.247l-1.171-2.2-1.525,1.541a.443.443,0,0,1-.324.139h0a.42.42,0,0,1-.324-.139L31.65,37.777a.444.444,0,0,1-.139-.324.42.42,0,0,1,.139-.324L33.191,35.6l-2.219-1.171A.459.459,0,0,1,30.725,33.987Z'
          transform='translate(-30.724 -27.276)'
        />
        <path
          id='Path_1193'
          data-name='Path 1193'
          d='M14.709,13.739a.458.458,0,0,1,.647.647l-.971.971a.447.447,0,0,1-.647,0,.447.447,0,0,1,0-.647Z'
          transform='translate(-3.783 -12.791)'
        />
        <path
          id='Path_1194'
          data-name='Path 1194'
          d='M35.367,10.392a.46.46,0,0,1-.447.37.2.2,0,0,1-.092-.015.468.468,0,0,1-.37-.539l.277-1.479a.462.462,0,1,1,.909.17Z'
          transform='translate(-27.153 -8.351)'
        />
        <path
          id='Path_1195'
          data-name='Path 1195'
          d='M8.828,34.736l1.479-.277a.462.462,0,0,1,.169.909L9,35.645c-.031,0-.062.015-.092.015a.448.448,0,0,1-.447-.37A.468.468,0,0,1,8.828,34.736Z'
          transform='translate(1.652 -30.429)'
        />
      </g>
    </svg>
  ),
  trophy: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='11.059'
      height='11.884'
      viewBox='0 0 11.059 11.884'
    >
      <g id='noun_success_818025' transform='translate(-5.34 -2)'>
        <path
          id='Path_1191'
          data-name='Path 1191'
          d='M15.83,2.852H14.241V2.335A.335.335,0,0,0,13.905,2H7.832a.335.335,0,0,0-.335.335v.505H5.907a.568.568,0,0,0-.567.567V4.881A2.044,2.044,0,0,0,7.38,6.922h.328A3.38,3.38,0,0,0,9.972,9v1.346H8.96a.345.345,0,0,0-.307.212l-.99,2.133H7.279a.6.6,0,1,0,0,1.2h7.18a.6.6,0,1,0,0-1.2h-.385l-.99-2.133a.345.345,0,0,0-.307-.212H11.765V9a3.38,3.38,0,0,0,2.259-2.061h.333A2.044,2.044,0,0,0,16.4,4.893V3.42A.568.568,0,0,0,15.83,2.852ZM7.38,5.787a.907.907,0,0,1-.906-.906v-.9H7.5V5.787Zm2.685,6.9H8.984l.532-1.146h.548Zm1.9-7.523-.766.766-.312.312a.442.442,0,0,1-.619,0l-.318-.312-.183-.183a.442.442,0,1,1,.619-.619l.183.183.766-.766a.442.442,0,0,1,.619.619Zm3.3-.27a.907.907,0,0,1-.906.906h-.118V3.987h1.024Z'
        />
      </g>
    </svg>
  ),
  calendar: (
    <svg
      id='noun_month_2080327'
      xmlns='http://www.w3.org/2000/svg'
      width='14.086'
      height='14.754'
      viewBox='0 0 14.086 14.754'
    >
      <g id='Group_892' data-name='Group 892'>
        <path
          id='Path_927'
          data-name='Path 927'
          d='M17.047,3.991H16.022V3.447a.947.947,0,1,0-1.895,0v.544H9.344V3.447a.947.947,0,1,0-1.895,0v.544H6.424A1.73,1.73,0,0,0,4.7,5.715V6.6H18.786V5.715A1.733,1.733,0,0,0,17.047,3.991Z'
          transform='translate(-4.7 -2.5)'
        />
        <path
          id='Path_928'
          data-name='Path 928'
          d='M4.7,43.743a1.73,1.73,0,0,0,1.724,1.724H17.062a1.73,1.73,0,0,0,1.724-1.724V35.9H4.7Zm9.241-4.908a.3.3,0,0,1,.3-.3h1.413a.3.3,0,0,1,.3.3v.932a.3.3,0,0,1-.3.3H14.236a.3.3,0,0,1-.3-.3Zm0,2.764a.3.3,0,0,1,.3-.3h1.413a.3.3,0,0,1,.3.3v.932a.3.3,0,0,1-.3.3H14.236a.3.3,0,0,1-.3-.3Zm-3.215-2.764a.3.3,0,0,1,.3-.3h1.413a.3.3,0,0,1,.3.3v.932a.3.3,0,0,1-.3.3H11.021a.3.3,0,0,1-.3-.3Zm0,2.764a.3.3,0,0,1,.3-.3h1.413a.3.3,0,0,1,.3.3v.932a.3.3,0,0,1-.3.3H11.021a.3.3,0,0,1-.3-.3Zm-3.2-2.764a.3.3,0,0,1,.3-.3H9.235a.3.3,0,0,1,.3.3v.932a.3.3,0,0,1-.3.3H7.822a.3.3,0,0,1-.3-.3v-.932Zm0,2.764a.3.3,0,0,1,.3-.3H9.235a.3.3,0,0,1,.3.3v.932a.3.3,0,0,1-.3.3H7.822a.3.3,0,0,1-.3-.3V41.6Z'
          transform='translate(-4.7 -30.713)'
        />
      </g>
    </svg>
  ),
}

const LeaderboardListItem = ({ item }) => {
  return (
    <div className='leaderboard-list-item'>
      <div className='list-item-field-header'>
        <ProfileLetters name={item.name || '+'} withoutIcon />
        <MatRow spaceBetween>
          <div>
            <div className='item-name'> {item.name || '+'}</div>
          </div>
        </MatRow>
      </div>
      <LeaderboardListFieldItem
        fieldValuesList={[
          {
            icon: Iconset.send,
            label: getText('WORD_SENT'),
            rightItem: item.totalNumSent || 0,
          },
          {
            icon: Iconset.send,
            label: getText('WORD_SENT_EMAIL'),
            rightItem: item.totalNumSentEmail || 0,
          },
          {
            icon: Iconset.send,
            label: getText('WORD_UNIQUE_SENT'),
            rightItem: item.totalNumSentUnique || 0,
          },
          {
            icon: Iconset.link,
            label: getText('WORD_FOLLOWED_LINK'),
            rightItem: item.totalNumFollowedLink || 0,
          },
          {
            icon: Iconset.recommend,
            label: getText('WORD_RECOMMENDED_YES'),
            rightItem: item.totalNumRecommended || 0,
          },
          {
            icon: Iconset.clicked,
            label: getText('WORD_CLICKED_TO_REVIEW'),
            rightItem: item.totalNumClickedToReview || 0,
          },
          {
            icon: Iconset.trophy,
            label: getText('WORD_SUCCESS'),
            rightItem: item.totalNumClickedToReviewUnique || 0,
          },
        ]}
      />
    </div>
  )
}

export default LeaderboardListItem
