import React from 'react'
import { getText } from '../../../lang'
import MatCheckbox from '../../../components/MatCheckbox'
import SelectChecked from '../../../components/Form/SelectChecked'
import TreeSelectForm from '../../../components/Form/TreeSelectForm'
import InputFormNumber from '../../../components/Form/InputFormNumber'

const CollapseChildren = ({
  getListOption,
  isCheckBox,
  collapseKey,
  onCheckBoxChange,
  data,
  isCheckBoxEnabled,
  isLoading,
  checkBoxValue,
  isTree,
  isSingle,
  onLoadMore,
  isSecondary,
  isInputNumber,
  showSingleAlways,
  singleDefaultOptions,
}) => {
  const itemDetails = {
    unreadMessage: {
      checkBoxLabel: getText('TEXT_RECEIVE_NOTIFICATIONS_FROM_ALL_ORGANIZATIONS'),
      treeSelectName: ['notifications', 'unreadMessage', 'organizations'],
      treeSelectLabel: getText(
        'TEXT_WHAT_LOCATIONS_DO_YOU_WANT_RECEIVE_NOTIFICATIONS_FROM'
      ),
      treeSelectPlaceholder: getText('WORD_SELECT_LOCATIONS'),
      treeSelectMessage: getText('TEXT_AT_LEAST_ONE_LOCATION_MUST_BE_SELECTED'),
      secondaryPlaceholder: getText('WORD_SELECT_TYPE'),
      secondaryName: ['notifications', 'unreadMessage', 'method'],
      secondaryLabel: getText('LABEL_HOW_DO_YOU_WANT_TO_RECEIVE_NOTIFICATIONS'),
      secondaryMessage: getText('ERROR_PLEASE_CHOOSE_TYPE_OF_NOTIFICATION'),
      inputName: ['notifications', 'unreadMessage', 'period'],
      inputTitle: getText(
        'TEXT_RECEIVE_NOTIFICATION_IF_THE_CONVERSATION_IS_UNREAD_FOR'
      ),
      inputErrorMessage: getText('TEXT_PLEASE_ENTER_THE_PERIOD_IN_MINUTES'),
      inputText: getText('WORD_MINUTES'),
      min: 0,
    },

    newsletterReports: {
      checkBoxLabel: getText('TEXT_RECEIVE_NOTIFICATIONS_FROM_ALL_ORGANIZATIONS'),
      singleSelectName: ['notifications', 'newsletterReports', 'organizations'],
      singleSelectLabel: getText(
        'TEXT_WHAT_ORGANIZATIONS_DO_YOU_WANT_RECEIVE_NOTIFICATIONS_FROM'
      ),
      singlePlaceholder: getText('WORD_SELECT_ORGANIZATIONS'),
      singleMessage: getText('TEXT_AT_LEAST_ONE_ORGANIZATION_MUST_BE_SELECTED'),
      secondaryPlaceholder: getText('WORD_SELECT_TYPE'),
      secondaryName: ['notifications', 'newsletterReports', 'method'],
      secondaryLabel: getText('TEXT_WHAT_REPORTS_DO_YOU_WANT_TO_RECEIVE'),
      secondaryMessage: getText('TEXT_AT_LEAST_ONE_OPTION_MUST_BE_SELECTED'),
    },

    assignToConversation: {
      checkBoxLabel: getText('TEXT_RECEIVE_NOTIFICATIONS_FROM_ALL_ORGANIZATIONS'),
      singleSelectName: ['notifications', 'assignToConversation', 'organizations'],
      singleSelectLabel: getText(
        'TEXT_WHAT_ORGANIZATIONS_DO_YOU_WANT_RECEIVE_NOTIFICATIONS_FROM'
      ),
      singlePlaceholder: getText('WORD_SELECT_ORGANIZATIONS'),
      singleMessage: getText('TEXT_AT_LEAST_ONE_ORGANIZATION_MUST_BE_SELECTED'),

      secondaryPlaceholder: getText('WORD_SELECT_TYPE'),
      secondaryName: ['notifications', 'assignToConversation', 'method'],
      secondaryLabel: getText('LABEL_HOW_DO_YOU_WANT_TO_RECEIVE_NOTIFICATIONS'),
      secondaryMessage: getText('ERROR_PLEASE_CHOOSE_TYPE_OF_NOTIFICATION'),
    },

    taggedInConversation: {
      checkBoxLabel: getText('TEXT_RECEIVE_NOTIFICATIONS_FROM_ALL_ORGANIZATIONS'),
      singleSelectName: ['notifications', 'taggedInConversation', 'organizations'],
      singleSelectLabel: getText(
        'TEXT_WHAT_ORGANIZATIONS_DO_YOU_WANT_RECEIVE_NOTIFICATIONS_FROM'
      ),
      singlePlaceholder: getText('WORD_SELECT_ORGANIZATIONS'),
      singleMessage: getText('TEXT_AT_LEAST_ONE_ORGANIZATION_MUST_BE_SELECTED'),
      secondaryPlaceholder: getText('WORD_SELECT_TYPE'),
      secondaryName: ['notifications', 'taggedInConversation', 'method'],
      secondaryLabel: getText('LABEL_HOW_DO_YOU_WANT_TO_RECEIVE_NOTIFICATIONS'),
      secondaryMessage: getText('ERROR_PLEASE_CHOOSE_TYPE_OF_NOTIFICATION'),
    },

    unassignFromConversation: {
      checkBoxLabel: getText('TEXT_RECEIVE_NOTIFICATIONS_FROM_ALL_ORGANIZATIONS'),
      treeSelectName: ['notifications', 'unassignFromConversation', 'organizations'],
      treeSelectLabel: getText(
        'TEXT_WHAT_LOCATIONS_DO_YOU_WANT_RECEIVE_NOTIFICATIONS_FROM'
      ),
      treeSelectPlaceholder: getText('WORD_SELECT_LOCATIONS'),
      treeSelectMessage: getText('TEXT_AT_LEAST_ONE_LOCATION_MUST_BE_SELECTED'),
      secondaryPlaceholder: getText('WORD_SELECT_TYPE'),
      secondaryName: ['notifications', 'unassignFromConversation', 'method'],
      secondaryLabel: getText('LABEL_HOW_DO_YOU_WANT_TO_RECEIVE_NOTIFICATIONS'),
      secondaryMessage: getText('ERROR_PLEASE_CHOOSE_TYPE_OF_NOTIFICATION'),
      inputName: ['notifications', 'unassignFromConversation', 'period'],
      inputTitle: getText(
        'TEXT_RECEIVE_NOTIFICATION_IF_THE_CONVERSATION_IS_UNREAD_FOR'
      ),
      inputErrorMessage: getText('TEXT_PLEASE_ENTER_THE_PERIOD_IN_MINUTES'),
      inputText: getText('WORD_MINUTES'),
    },
    paymentStatus: {
      checkBoxLabel: getText('TEXT_RECEIVE_NOTIFICATIONS_FROM_ALL_ORGANIZATIONS'),
      singleSelectName: ['notifications', 'paymentStatus', 'organizations'],
      singleSelectLabel: getText(
        'TEXT_WHAT_ORGANIZATIONS_DO_YOU_WANT_RECEIVE_NOTIFICATIONS_FROM'
      ),
      singlePlaceholder: getText('WORD_SELECT_ORGANIZATIONS'),
      singleMessage: getText('TEXT_AT_LEAST_ONE_ORGANIZATION_MUST_BE_SELECTED'),
      secondaryPlaceholder: getText('WORD_SELECT_TYPE'),
      secondaryName: ['notifications', 'paymentStatus', 'method'],
      secondaryLabel: getText('LABEL_HOW_DO_YOU_WANT_TO_RECEIVE_NOTIFICATIONS'),
      secondaryMessage: getText('ERROR_PLEASE_CHOOSE_TYPE_OF_NOTIFICATION'),
    },
    unhappyCustomer: {
      checkBoxLabel: getText('TEXT_RECEIVE_NOTIFICATIONS_FROM_ALL_ORGANIZATIONS'),
      treeSelectName: ['notifications', 'unhappyCustomer', 'organizations'],
      treeSelectLabel: getText(
        'TEXT_WHAT_LOCATIONS_DO_YOU_WANT_RECEIVE_NOTIFICATIONS_FROM'
      ),
      treeSelectPlaceholder: getText('WORD_SELECT_LOCATIONS'),
      treeSelectMessage: getText('TEXT_AT_LEAST_ONE_LOCATION_MUST_BE_SELECTED'),
    },
  }

  return (
    <>
      {isCheckBox && (
        <>
          {data.list.length > 1 && (
            <MatCheckbox
              label={itemDetails[collapseKey].checkBoxLabel}
              className='notification-checkbox'
              disabled={!isCheckBoxEnabled}
              onChange={onCheckBoxChange}
              value={checkBoxValue}
            />
          )}
          {((!checkBoxValue && (isTree || isSingle)) || showSingleAlways) &&
            (isTree ? (
              <TreeSelectForm
                loading={isLoading}
                name={itemDetails[collapseKey].treeSelectName}
                label={itemDetails[collapseKey].treeSelectLabel}
                treeData={data.list || []}
                treeCheckable={true}
                multipleNewStyle
                placeholder={itemDetails[collapseKey].treeSelectPlaceholder}
                message={itemDetails[collapseKey].treeSelectMessage}
                required={isCheckBoxEnabled}
                disabled={!isCheckBoxEnabled}
              />
            ) : isSingle ? (
              <SelectChecked
                loading={isLoading}
                name={itemDetails[collapseKey].singleSelectName}
                label={itemDetails[collapseKey].singleSelectLabel}
                placeholder={itemDetails[collapseKey].singlePlaceholder}
                option={
                  data.list && data.list.length
                    ? data.list
                    : singleDefaultOptions || []
                }
                onLoadMore={onLoadMore}
                message={itemDetails[collapseKey].singleMessage}
                mode='multiple'
                multipleNewStyle
                required={isCheckBoxEnabled}
                disabled={!isCheckBoxEnabled}
              />
            ) : null)}
        </>
      )}
      {Boolean(isSecondary) && (
        <SelectChecked
          placeholder={itemDetails[collapseKey].secondaryPlaceholder}
          name={itemDetails[collapseKey].secondaryName}
          label={itemDetails[collapseKey].secondaryLabel}
          option={getListOption && getListOption()}
          mode='multiple'
          multipleNewStyle
          required={isCheckBoxEnabled}
          disabled={!isCheckBoxEnabled}
          showSearch={false}
          message={itemDetails[collapseKey].secondaryMessage}
        />
      )}
      {Boolean(isInputNumber) && (
        <div className='delay-notification'>
          <p>{itemDetails[collapseKey].inputTitle}</p>
          <InputFormNumber
            name={itemDetails[collapseKey].inputName}
            placeholder={''}
            inputClassName={'delay-input-notification'}
            required={isCheckBoxEnabled}
            disabled={!isCheckBoxEnabled}
            hasFeedback={false}
            errorMessage={itemDetails[collapseKey].inputErrorMessage}
            min={itemDetails[collapseKey].min ?? 1}
          />
          <p>{itemDetails[collapseKey].inputText}</p>
        </div>
      )}
    </>
  )
}

export default CollapseChildren
