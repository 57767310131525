import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='24' height='24' rx='4' fill='#FFF5EA' />
      <rect
        x='0.5'
        y='0.5'
        width='23'
        height='23'
        rx='3.5'
        stroke={props.color || '#EA9957'}
        strokeOpacity='0.2'
      />
      <path
        d='M14.0888 12C14.0888 13.155 13.1554 14.0883 12.0004 14.0883C10.8454 14.0883 9.91211 13.155 9.91211 12C9.91211 10.845 10.8454 9.91162 12.0004 9.91162C13.1554 9.91162 14.0888 10.845 14.0888 12Z'
        stroke={props.color || '#EA9957'}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.9999 16.8241C14.0591 16.8241 15.9782 15.6108 17.3141 13.5108C17.8391 12.6883 17.8391 11.3058 17.3141 10.4833C15.9782 8.38325 14.0591 7.16992 11.9999 7.16992C9.94074 7.16992 8.02158 8.38325 6.68574 10.4833C6.16074 11.3058 6.16074 12.6883 6.68574 13.5108C8.02158 15.6108 9.94074 16.8241 11.9999 16.8241Z'
        stroke={props.color || '#EA9957'}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

const SVGWatchedTimesIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGWatchedTimesIcon
