import React from 'react';


const IconInvitation = {
    settings: (
        <svg xmlns="http://www.w3.org/2000/svg" width="18.555" height="15.181" viewBox="0 0 18.555 15.181">
            <path id="Path_1162" data-name="Path 1162" d="M8.591,3A2.537,2.537,0,0,0,6.219,4.687H1.843a.843.843,0,1,0,0,1.687H6.219a2.511,2.511,0,0,0,4.744,0h7.749a.843.843,0,1,0,0-1.687H10.963A2.537,2.537,0,0,0,8.591,3Zm0,1.687a.843.843,0,1,1-.843.843A.831.831,0,0,1,8.591,4.687Zm5.06,3.374a2.537,2.537,0,0,0-2.372,1.687H1.843a.843.843,0,1,0,0,1.687h9.436a2.511,2.511,0,0,0,4.744,0h2.688a.843.843,0,1,0,0-1.687H16.023A2.537,2.537,0,0,0,13.651,8.06Zm0,1.687a.843.843,0,1,1-.834.891.844.844,0,0,0,0-.1A.825.825,0,0,1,13.651,9.747ZM6.9,13.121a2.538,2.538,0,0,0-2.377,1.7.843.843,0,0,0-.153-.012H1.843a.843.843,0,1,0,0,1.687h2.53a.843.843,0,0,0,.153-.013,2.512,2.512,0,0,0,4.749.013h9.436a.843.843,0,1,0,0-1.687H9.276A2.537,2.537,0,0,0,6.9,13.121Zm0,1.687a.843.843,0,1,1-.843.843A.831.831,0,0,1,6.9,14.808Z" transform="translate(-1 -3)" />
        </svg>
    ),
    email: (
        <svg xmlns="http://www.w3.org/2000/svg" width="11.719" height="8.113" viewBox="0 0 11.719 8.113">
            <g id="noun_Email_1740173" transform="translate(-11 -23)">
                <g id="Group_1302" data-name="Group 1302" transform="translate(11 23)">
                    <path id="Path_1163" data-name="Path 1163" d="M11.9,975.362a.869.869,0,0,0-.479.146l5.075,4.808a.5.5,0,0,0,.732,0l5.066-4.808a.869.869,0,0,0-.479-.146Zm-.845.62A.953.953,0,0,0,11,976.3v6.244a.955.955,0,0,0,.146.512l3.587-3.582Zm11.606,0L19,979.465l3.578,3.587a.954.954,0,0,0,.146-.512V976.3a.952.952,0,0,0-.056-.315Zm-4.1,3.9-.916.869a1.134,1.134,0,0,1-1.559,0l-.916-.864-3.549,3.54a.872.872,0,0,0,.282.047h9.916a.872.872,0,0,0,.282-.047l-3.54-3.544Z" transform="translate(-11 -975.362)" />
                </g>
            </g>
        </svg>
    ),
    phone: (
        <svg xmlns="http://www.w3.org/2000/svg" width="10.863" height="10.862" viewBox="0 0 10.863 10.862">
            <g id="noun_Phone_2236827" transform="translate(-33.254 -33.531)">
                <g id="Group_1303" data-name="Group 1303" transform="translate(33.255 33.531)">
                    <path id="Path_1164" data-name="Path 1164" d="M40.172,41.578c.163-.2.733-1.141,1.589-.856.9.285,3.138,1.059,2.078,2.445-3.545,4.808-14.14-5.827-9.372-9.372,1.385-1.019,2.16,1.182,2.445,2.078s-.611,1.426-.856,1.589C35.445,38.033,39.642,42.189,40.172,41.578Z" transform="translate(-33.255 -33.531)" fillRule="evenodd" />
                </g>
            </g>
        </svg>
    ),
    add: (
        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11">
            <g id="noun_Plus_680243" transform="translate(-10 -10)">
                <path id="Path_1165" data-name="Path 1165" d="M15.5,21A5.5,5.5,0,1,1,21,15.5,5.5,5.5,0,0,1,15.5,21Zm2.005-6H16v-1.5a.5.5,0,0,0-1,0V15h-1.5a.5.5,0,1,0,0,1H15v1.5a.5.5,0,0,0,1,0V16h1.5a.5.5,0,0,0,.5-.5A.508.508,0,0,0,17.505,15Z" fillRule="evenodd" />
            </g>
        </svg>
    ),
    recommend: (
        <svg id="Livello_2" data-name="Livello 2" xmlns="http://www.w3.org/2000/svg" width="11.059" height="11.185" viewBox="0 0 11.059 11.185">
            <g id="Livello_1" data-name="Livello 1">
                <path id="Path_1190" data-name="Path 1190" d="M10.468,0H.59A.592.592,0,0,0,0,.59V7.842a.592.592,0,0,0,.59.59H2.073v2.361a.4.4,0,0,0,.676.276L5.391,8.432h5.077a.592.592,0,0,0,.59-.59V.59A.592.592,0,0,0,10.468,0ZM8.088,3.76l-1.321.961a.169.169,0,0,0-.061.188l.5,1.554a.169.169,0,0,1-.26.188L5.629,5.69a.169.169,0,0,0-.2,0L4.11,6.65a.169.169,0,0,1-.26-.188l.5-1.552a.169.169,0,0,0-.061-.188L2.971,3.76a.169.169,0,0,1,.1-.3H4.7a.169.169,0,0,0,.16-.116l.5-1.554a.169.169,0,0,1,.321,0l.5,1.554a.169.169,0,0,0,.169.116h1.63a.169.169,0,0,1,.1.3Z" />
            </g>
        </svg>
    ),
    waiting: (
        <svg xmlns="http://www.w3.org/2000/svg" width="8.248" height="8.248" viewBox="0 0 8.248 8.248">
            <g id="noun_loading_1935398" transform="translate(-9.974 -129.28)">
                <path id="Path_929" data-name="Path 929" d="M141.084,131.2a.343.343,0,0,1-.347-.347v-1.227a.347.347,0,0,1,.693,0v1.227A.343.343,0,0,1,141.084,131.2Zm0,6.328a.343.343,0,0,1-.347-.347v-1.227a.347.347,0,1,1,.693,0v1.227A.348.348,0,0,1,141.084,137.528Zm3.778-3.778h-1.227a.347.347,0,0,1,0-.693h1.227a.343.343,0,0,1,.347.347A.352.352,0,0,1,144.862,133.751Zm-6.328,0h-1.227a.347.347,0,0,1,0-.693h1.227a.343.343,0,0,1,.347.347A.348.348,0,0,1,138.533,133.751Zm4.353-1.8a.325.325,0,0,1-.243-.1.343.343,0,0,1,0-.492l.866-.866a.348.348,0,1,1,.492.492l-.866.866A.364.364,0,0,1,142.886,131.949Zm-4.471,4.471a.325.325,0,0,1-.243-.1.343.343,0,0,1,0-.492l.866-.866a.348.348,0,0,1,.492.492l-.866.866A.377.377,0,0,1,138.416,136.419Zm5.337,0a.325.325,0,0,1-.243-.1l-.866-.866a.348.348,0,1,1,.492-.492l.866.866a.343.343,0,0,1,0,.492A.352.352,0,0,1,143.753,136.419Zm-4.471-4.471a.325.325,0,0,1-.243-.1l-.873-.866a.348.348,0,0,1,.492-.492l.866.866a.343.343,0,0,1,0,.492A.325.325,0,0,1,139.282,131.949Z" transform="translate(-126.986)" />
            </g>
        </svg>
    ),
    check: (
        <svg xmlns="http://www.w3.org/2000/svg" width="8.716" height="6.459" viewBox="0 0 8.716 6.459">
            <g id="noun_Check_1807528" transform="translate(0 0)">
                <g id="Group_901" data-name="Group 901">
                    <path id="Path_930" data-name="Path 930" d="M31.012,982.329a.645.645,0,0,0-.443.2c-1.594,1.6-3.045,3.173-4.595,4.746l-1.96-1.567a.645.645,0,1,0-.806,1.008l2.418,1.935a.645.645,0,0,0,.861-.05c1.733-1.737,3.291-3.449,5-5.159a.645.645,0,0,0-.474-1.108Z" transform="translate(-22.966 -982.329)" />
                </g>
            </g>
        </svg>
    ),
    checkCircle: (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="#29CC97">
            <g id="noun_checkmark_2193579" transform="translate(-23 -23)">
                <g id="Group_889" data-name="Group 889" transform="translate(23 23)">
                    <path id="Path_925" data-name="Path 925" d="M30,23a7,7,0,1,1-7,7A7,7,0,0,1,30,23Zm-1.505,8.208L33.307,26.4a.54.54,0,0,1,.788,0l.63.612a.571.571,0,0,1,0,.788L28.9,33.622a.571.571,0,0,1-.788,0l-2.818-2.817a.571.571,0,0,1,0-.788l.63-.613a.54.54,0,0,1,.788,0Z" transform="translate(-23 -23)" fillRule="evenodd" />
                </g>
            </g>
        </svg>
    ),
    clearCircle: (
        <svg xmlns="http://www.w3.org/2000/svg" width="13.981" height="14" viewBox="0 0 13.981 14">
            <path id="Intersection_1" data-name="Intersection 1" d="M9.086,17a7.069,7.069,0,0,1-6.009-5.552V8.537A7.069,7.069,0,0,1,8.974,3h2.037A7.066,7.066,0,0,1,10.9,17Zm1.264-5.936,1.962,1.963a.505.505,0,0,0,.715-.715L11.064,10.35a.506.506,0,0,1,0-.715l1.963-1.962a.505.505,0,0,0-.715-.715L10.35,8.922a.506.506,0,0,1-.715,0L7.673,6.959a.5.5,0,0,0-.714.715L8.922,9.635a.506.506,0,0,1,0,.715L6.959,12.312a.5.5,0,0,0,.714.715l1.963-1.963a.506.506,0,0,1,.715,0Z" transform="translate(-3.077 -3)" fill="#ff7366" />
        </svg>
    ),
    chat: (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="13.712" viewBox="0 0 16 13.712">
            <path id="Path_948" data-name="Path 948" d="M10.571,8A.571.571,0,1,1,10,7.428.54.54,0,0,1,10.571,8Zm1.714-.571A.571.571,0,1,0,12.857,8,.54.54,0,0,0,12.286,7.428Zm2.286,0A.571.571,0,1,0,15.143,8,.54.54,0,0,0,14.571,7.428ZM18,6.285v6.856a.571.571,0,0,1-1.143,0A1.146,1.146,0,0,0,15.714,12H13.429v1.714A2.292,2.292,0,0,1,11.143,16H4.286a1.146,1.146,0,0,0-1.143,1.143.54.54,0,0,1-.571.571A.54.54,0,0,1,2,17.141V10.285A2.292,2.292,0,0,1,4.286,8H6.571V6.285A2.292,2.292,0,0,1,8.857,4h6.857A2.292,2.292,0,0,1,18,6.285ZM12.286,12H8.857A2.292,2.292,0,0,1,6.571,9.714V9.142H4.286a1.146,1.146,0,0,0-1.143,1.143v4.857a2.6,2.6,0,0,1,1.143-.286h6.857a1.146,1.146,0,0,0,1.143-1.143Zm4.571-5.714a1.146,1.146,0,0,0-1.143-1.143H8.857A1.146,1.146,0,0,0,7.714,6.285V9.714a1.146,1.146,0,0,0,1.143,1.143h6.857a2.6,2.6,0,0,1,1.143.286Z" transform="translate(-2 -4)" />
        </svg>
    ),
    feedbackTooltip: (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
            <path id="Path_752" data-name="Path 752" d="M6.654.654a6,6,0,1,0,6,6A6,6,0,0,0,6.654.654Zm.254,2.373a.789.789,0,1,1-.789.789A.789.789,0,0,1,6.908,3.027Zm.707,6.979a1.325,1.325,0,0,1-1.4.128,1.013,1.013,0,0,1-.414-.4,1.55,1.55,0,0,1-.164-.975l.078-.619.2-1.613-.553.012a.468.468,0,0,1-.182-.9L6.6,5.076a.655.655,0,0,1,.85.37.647.647,0,0,1,.029.386l-.019.084L6.9,8.348l-.138.608a1.334,1.334,0,0,0-.029.161c-.015.274.374.068.374.068a.483.483,0,0,1,.506.822Z" transform="translate(-0.654 -0.654)" />
        </svg>
    ),
};


export default IconInvitation;