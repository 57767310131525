import React from 'react'
import { Link } from 'react-router-dom'
import { getText } from '../../../lang'
import InputFormPasswordSecondary from '../../../components/Form/InputFormPassword/InputFormPasswordSecondary'
import InputFormTextSecondary from '../../../components/Form/InputFormText/InputFormTextSecondary'

const LoginPage = () => {
  return (
    <>
      <InputFormTextSecondary
        name='emailOrPhone'
        label={getText('LABEL_PHONE_OR_EMAIL')}
        placeholder={getText('WORD_PLEASE_ENTER_YOUR_PHONE_OR_EMAIL')}
        required
        message={getText('MSG_PLEASE_INPUT_PHONE_OR_EMAIL')}
      />
      <InputFormPasswordSecondary
        name='password'
        label={getText('WORD_PASSWORD')}
        placeholder={getText('WORD_PLEASE_ENTER_YOUR_PASSWORD')}
        required
      />
      <Link className='forgot-password-link' to={'/password_request'}>
        {getText('TEXT_FORGOT_PASSWORD')}
      </Link>
    </>
  )
}

export default LoginPage
