import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle r='12' transform='matrix(-1 0 0 1 12 12)' fill='white' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.9866 12.1094L8.41141 9.53415C8.07911 9.20185 8.07911 8.66308 8.41141 8.33077C8.74372 7.99847 9.28249 7.99847 9.61479 8.33077L12.19 10.906L14.7652 8.33077C15.0975 7.99847 15.6363 7.99847 15.9686 8.33077C16.3009 8.66308 16.3009 9.20185 15.9686 9.53415L13.3934 12.1094L15.9686 14.6846C16.3009 15.0169 16.3009 15.5557 15.9686 15.888C15.6363 16.2203 15.0975 16.2203 14.7652 15.888L12.19 13.3128L9.61479 15.888C9.28249 16.2203 8.74372 16.2203 8.41141 15.888C8.07911 15.5557 8.07911 15.0169 8.41141 14.6846L10.9866 12.1094Z'
        fill={props.color || '#9DAAB2'}
      />
    </svg>
  )
}

const SVGStoriesImageCloseIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGStoriesImageCloseIcon
