import React, { useEffect, useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Form } from 'antd'
import './InputFormPhone.scss'

// phone code config
export const ACCEPT_CODE_COUNTRIES = ['us', 'ca', 'au']
const InputFormPhoneSecondary = (props) => {
  const {
    disabled,
    onEnterPress,
    hasFeedback,
    name,
    label,
    placeholder,
    disableDropdown,
    initialCountry,
    onChange,
    autoCompleteCountryCode,
  } = props

  const [phoneNumber, setPhoneNumber] = useState('')
  const [correctPhoneNumber, setCorrectPhoneNumber] = useState('')

  useEffect(() => {
    document.querySelectorAll('.react-phone-input-2 ')[0] &&
      document
        .querySelectorAll('.react-phone-input-2 ')[0]
        .addEventListener('paste', correctPhoneNumberFormat)
  }, [document.querySelectorAll('.react-phone-input-2')])

  const correctPhoneNumberFormat = (event) => {
    let pasteValue = (event.clipboardData || window.clipboardData).getData('text')
    pasteValue = pasteValue.replace(/-|\s/g, '')
    pasteValue = pasteValue.replace('(', '')
    pasteValue = pasteValue.replace(')', '')
    pasteValue = pasteValue.replace('tel:', '')
    pasteValue = pasteValue.replace('+', '')
    if (initialCountry === 'au') {
      if (pasteValue.length <= 11) {
        if (pasteValue.startsWith('04')) {
          pasteValue = `04${pasteValue && pasteValue.slice(2)}`
        } else {
          pasteValue = `04${pasteValue && pasteValue}`
        }
      }
    } else {
      if (pasteValue.length <= 10) {
        pasteValue = `1${pasteValue}`
      }
    }
    setCorrectPhoneNumber(pasteValue)
    setPhoneNumber(pasteValue)
  }

  const keyDownHandler = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      onEnterPress && onEnterPress()
    }
  }

  const handleKeyDown = (event) => {
    if (Boolean(correctPhoneNumber)) {
      return
    }
    if (event.key === 'Enter') {
      event.preventDefault()
      onEnterPress && onEnterPress()
      return
    }
    if (event.key.match(/^\d+$/)) {
      if (!phoneNumber) {
        event.preventDefault()
        if (initialCountry === 'au') {
          setPhoneNumber('04' + event.key)
          onChange('04' + event.key)
        } else {
          setPhoneNumber('+1' + event.key)
          onChange('+1' + event.key)
        }
        const input = event.target
        if (initialCountry === 'au') {
          setTimeout(() => input.setSelectionRange(7, 7), 0)
        } else {
          setTimeout(() => input.setSelectionRange(5, 5), 0)
        }
      }
    }
  }

  const handleChange = (value) => {
    if (correctPhoneNumber) {
      onChange(correctPhoneNumber)
    } else {
      setPhoneNumber(value)
    }
    Boolean(correctPhoneNumber) && setCorrectPhoneNumber('')
  }

  return (
    <Form.Item
      className={`mat-form-item mat-form-item-black ${props.formClassName || ''}`}
      label={label}
      name={name}
      hasFeedback={hasFeedback}
      rules={[
        {
          required: props.required,
          message: props.errorMessage || `Please input ${placeholder || label}!`,
          validator: async (_, value) => {
            if (
              value === '04' ||
              (value === undefined && props.required) ||
              (value === '' && props.required)
            ) {
              return Promise.reject(new Error(props.errorMessage))
            }
          },
        },
      ]}
    >
      <PhoneInput
        country={initialCountry}
        inputClass='react-phone-input-2'
        onlyCountries={ACCEPT_CODE_COUNTRIES}
        areaCodes={{ au: ['+04'] }}
        disabled={disabled}
        disableDropdown={disableDropdown}
        value={phoneNumber}
        placeholder={initialCountry === 'au' ? '(04) 1234 5678' : '1 (702) 123-4567'}
        onChange={
          autoCompleteCountryCode
            ? handleChange
            : (value) => {
                let finalValue
                if (correctPhoneNumber) {
                  finalValue = correctPhoneNumber
                } else {
                  finalValue = value
                }
                onChange(finalValue)
                Boolean(correctPhoneNumber) && setCorrectPhoneNumber('')
              }
        }
        disableCountryCode={initialCountry === 'au'}
        masks={{ au: '(..) .... ....' }}
        dropdownStyle={{ bottom: 35, borderRadius: 4 }}
        inputStyle={{ width: '100%', height: 48 }}
        onKeyDown={autoCompleteCountryCode ? handleKeyDown : keyDownHandler}
        onBlur={props.onBlur}
      />
    </Form.Item>
  )
}

InputFormPhoneSecondary.defaultProps = {
  name: 'text',
  required: false,
  hasFeedback: false,
  allowClear: true,
  autoComplete: 'off',
  length: 11,
  disableDropdown: true,
  onPressEnter: (e) => {
    e.preventDefault()
  },
  onClear: () => {},
}

export default InputFormPhoneSecondary
