import React, { useCallback, useEffect, useMemo } from 'react'
import { CONNECT_URL } from '../../../../../config'

import './Preview.scss'

const ThemePreview = ({
  settings,
  language = 'en',
  locationId,
  theme,
  position = 'relative',
}) => {
  const getLanguage = useMemo(
    () => (language === 'es' ? 'sp' : language),
    [language]
  )

  const sendPreviewSettings = useCallback(() => {
    const getData = () => {
      return {
        ...settings,
        texts: settings.texts[language],
      }
    }

    const iframe = document.getElementById(`iframe-preview-${theme}`)
    if (!iframe) {
      return
    }
    iframe.contentWindow.postMessage(getData(), '*')
  }, [settings, theme, language])

  useEffect(() => {
    sendPreviewSettings()
  }, [sendPreviewSettings])

  useEffect(() => {
    sendPreviewSettings()
  }, [sendPreviewSettings])

  if (!settings || !locationId) {
    return null
  }

  const onIframeLoad = () => {
    sendPreviewSettings()
  }

  const renderIframe = ({ display, variant }) => {
    return (
      <iframe
        title='Matador Connect Preview'
        id={`iframe-preview-${variant}`}
        height={`${settings.height}`}
        width={variant === 'default' ? '350px' : '300px'}
        frameBorder='none'
        onLoad={onIframeLoad}
        src={`${CONNECT_URL}/chat_widget/theme=${variant}/${locationId}/${getLanguage}`}
        style={{ position, display }}
        allow='geolocation'
      ></iframe>
    )
  }

  return (
    <div className='matador-connect-preview-default'>
      {renderIframe({
        display: theme === 'default' ? 'block' : 'none',
        variant: 'default',
      })}
      {renderIframe({
        display: theme === 'light' ? 'block' : 'none',
        variant: 'light',
      })}
    </div>
  )
}

export default ThemePreview
