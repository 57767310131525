import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='240'
      height='424'
      viewBox='0 0 240 424'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M79 72L79 379'
        stroke='url(#paint0_linear_16359_50335)'
        strokeWidth='50'
      />
      <circle
        opacity='0.1'
        cx='79'
        cy='79'
        r='79'
        transform='rotate(90 79 79)'
        fill='url(#paint1_linear_16359_50335)'
      />
      <circle cx='79' cy='79' r='65' fill='#8665F7' />
      <circle cx='79' cy='79' r='58' fill='#7854F6' />
      <circle cx='79' cy='79' r='57.5' stroke='white' strokeOpacity='0.3' />
      <path
        d='M77.2858 71.1798H80.0698C80.713 71.1798 81.2378 70.655 81.2378 70.0118V59.8678C81.2378 59.2246 80.713 58.6998 80.0698 58.6998H77.2858C76.6426 58.6998 76.1178 59.2246 76.1178 59.8678V70.0118C76.1178 70.655 76.6426 71.1798 77.2858 71.1798ZM77.3978 59.9798H79.9578V69.8998H77.3978V59.9798Z'
        fill='white'
      />
      <path
        d='M84.9658 71.1798H87.4298C88.073 71.1798 88.5978 70.655 88.5978 70.0118V56.9878C88.5978 56.3446 88.073 55.8198 87.4298 55.8198H84.9658C84.3226 55.8198 83.7978 56.3446 83.7978 56.9878V70.0118C83.7978 70.655 84.3226 71.1798 84.9658 71.1798ZM85.0778 57.0998H87.3178V69.8998H85.0778V57.0998Z'
        fill='white'
      />
      <path
        d='M69.9258 71.1798H72.3898C73.033 71.1798 73.5578 70.655 73.5578 70.0118V62.7478C73.5578 62.1046 73.033 61.5798 72.3898 61.5798H69.9258C69.2826 61.5798 68.7578 62.1046 68.7578 62.7478V70.0118C68.7578 70.655 69.2826 71.1798 69.9258 71.1798ZM70.0378 62.8598H72.2778V69.8998H70.0378V62.8598Z'
        fill='white'
      />
      <path d='M85.0778 57.0998H87.3178V69.8998H85.0778V57.0998Z' fill='white' />
      <path d='M77.3978 59.9798H79.9578V69.8998H77.3978V59.9798Z' fill='white' />
      <path d='M70.0378 62.8598H72.2778V69.8998H70.0378V62.8598Z' fill='white' />
      <path
        opacity='0.15'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M194 279L73 279L73 199L194 199L194 279Z'
        fill='url(#paint2_linear_16359_50335)'
      />
      <path
        d='M74 239H194'
        stroke='url(#paint3_linear_16359_50335)'
        strokeWidth='50'
      />
      <circle cx='193' cy='239' r='40' fill='white' />
      <circle cx='193' cy='239' r='39.5' stroke='#48AF80' strokeOpacity='0.3' />
      <path
        d='M202.567 215.903C199.534 214.647 196.283 214 193 214C189.717 214 186.466 214.647 183.433 215.903C180.4 217.159 177.644 219.001 175.322 221.322C173.001 223.644 171.159 226.4 169.903 229.433C168.647 232.466 168 235.717 168 239C168 242.283 168.647 245.534 169.903 248.567C171.159 251.6 173.001 254.356 175.322 256.678C177.644 258.999 180.4 260.841 183.433 262.097C186.466 263.353 189.717 264 193 264C196.283 264 199.534 263.353 202.567 262.097C205.6 260.841 208.356 258.999 210.678 256.678C212.999 254.356 214.841 251.6 216.097 248.567C217.353 245.534 218 242.283 218 239C218 235.717 217.353 232.466 216.097 229.433C214.841 226.4 212.999 223.644 210.678 221.322C208.356 219.001 205.6 217.159 202.567 215.903Z'
        fill='#48AF80'
      />
      <path
        d='M193 214C196.283 214 199.534 214.647 202.567 215.903C205.6 217.159 208.356 219.001 210.678 221.322C212.999 223.644 214.841 226.4 216.097 229.433C217.353 232.466 218 235.717 218 239C218 242.283 217.353 245.534 216.097 248.567C214.841 251.6 212.999 254.356 210.678 256.678C208.356 258.999 205.6 260.841 202.567 262.097C199.534 263.353 196.283 264 193 264C189.717 264 186.466 263.353 183.433 262.097C180.4 260.841 177.644 258.999 175.322 256.678C173.001 254.356 171.159 251.6 169.903 248.567C168.647 245.534 168 242.283 168 239C168 235.717 168.647 232.466 169.903 229.433C171.159 226.4 173.001 223.644 175.322 221.322C177.644 219.001 180.4 217.159 183.433 215.903C186.466 214.647 189.717 214 193 214Z'
        fill='#48AF80'
      />
      <g opacity='0.6' filter='url(#filter0_f_16359_50335)'>
        <path
          d='M200.654 227.522C198.227 226.517 195.626 226 193 226C190.374 226 187.773 226.517 185.346 227.522C182.92 228.527 180.715 230.001 178.858 231.858C177.001 233.715 175.528 235.92 174.522 238.346C173.517 240.773 173 243.374 173 246C173 248.626 173.517 251.227 174.522 253.654C175.527 256.08 177.001 258.285 178.858 260.142C180.715 261.999 182.92 263.472 185.346 264.478C187.773 265.483 190.374 266 193 266C195.626 266 198.227 265.483 200.654 264.478C203.08 263.472 205.285 261.999 207.142 260.142C208.999 258.285 210.472 256.08 211.478 253.654C212.483 251.227 213 248.626 213 246C213 243.374 212.483 240.773 211.478 238.346C210.472 235.92 208.999 233.715 207.142 231.858C205.285 230.001 203.08 228.528 200.654 227.522Z'
          fill='#48AF80'
        />
        <path
          d='M193 226C195.626 226 198.227 226.517 200.654 227.522C203.08 228.528 205.285 230.001 207.142 231.858C208.999 233.715 210.472 235.92 211.478 238.346C212.483 240.773 213 243.374 213 246C213 248.626 212.483 251.227 211.478 253.654C210.472 256.08 208.999 258.285 207.142 260.142C205.285 261.999 203.08 263.472 200.654 264.478C198.227 265.483 195.626 266 193 266C190.374 266 187.773 265.483 185.346 264.478C182.92 263.472 180.715 261.999 178.858 260.142C177.001 258.285 175.527 256.08 174.522 253.654C173.517 251.227 173 248.626 173 246C173 243.374 173.517 240.773 174.522 238.346C175.528 235.92 177.001 233.715 178.858 231.858C180.715 230.001 182.92 228.527 185.346 227.522C187.773 226.517 190.374 226 193 226Z'
          fill='#48AF80'
        />
      </g>
      <path
        opacity='0.2'
        d='M193 207C197.202 207 201.363 207.828 205.246 209.436C209.128 211.044 212.656 213.401 215.627 216.373C218.599 219.344 220.956 222.872 222.564 226.754C224.172 230.637 225 234.798 225 239C225 243.202 224.172 247.363 222.564 251.246C220.956 255.128 218.599 258.656 215.627 261.627C212.656 264.599 209.128 266.956 205.246 268.564C201.363 270.172 197.202 271 193 271C188.798 271 184.637 270.172 180.754 268.564C176.872 266.956 173.344 264.599 170.373 261.627C167.401 258.656 165.044 255.128 163.436 251.246C161.828 247.363 161 243.202 161 239C161 234.798 161.828 230.637 163.436 226.754C165.044 222.872 167.401 219.344 170.373 216.373C173.344 213.401 176.872 211.044 180.754 209.436C184.637 207.828 188.798 207 193 207L193 207Z'
        stroke='#48AF80'
        strokeWidth='10'
      />
      <circle opacity='0.2' cx='74' cy='239' r='45' fill='white' />
      <circle cx='74' cy='239' r='40' fill='#5AB78D' />
      <circle cx='74' cy='239' r='35' fill='#48AF80' />
      <circle cx='74' cy='239' r='34.5' stroke='white' strokeOpacity='0.3' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M77.8965 250.188L83.7567 230.981C84.0263 230.087 83.0091 229.07 82.1151 229.339L62.9076 235.199C62.1748 235.415 62.0876 236.376 62.7211 237.01L66.827 241.13C67.2731 241.576 67.9225 241.694 68.3351 241.388L80.1706 232.925L71.6987 244.752C71.402 245.173 71.5199 245.823 71.966 246.269L76.0862 250.375C76.7197 251.008 77.6812 250.921 77.8965 250.188Z'
        fill='white'
      />
      <circle opacity='0.25' cx='79' cy='379' r='45' fill='white' />
      <circle cx='79' cy='379' r='40' fill='#F9C11E' />
      <circle cx='79' cy='379' r='35' fill='#F8BA05' />
      <circle cx='79' cy='379' r='34.5' stroke='white' strokeOpacity='0.3' />
      <path
        d='M90.2328 366.476H78.3921C77.6378 366.476 77.026 367.087 77.026 367.842V369.889C78.0732 370.171 78.9952 370.715 79.7078 371.487C80.925 372.806 81.4752 374.656 81.2988 376.836C81.2837 377.019 81.2576 377.211 81.2254 377.405H83.7877V379.741C83.7877 380.249 84.1257 380.423 84.5387 380.131L88.3848 377.405H90.2331C90.9874 377.405 91.5992 376.793 91.5992 376.039V367.842C91.5989 367.087 90.9868 366.476 90.2328 366.476Z'
        fill='white'
      />
      <path
        d='M75.2956 371.03C75.0568 371.03 74.8115 371.044 74.5606 371.073C72.1257 371.353 70.6714 373.168 70.5138 375.506C70.3547 377.888 71.2379 379.647 72.1767 380.937C72.5877 381.502 73.0187 381.865 72.9526 382.545C72.8753 383.348 72.016 383.572 71.4008 383.819C70.6717 384.112 69.8874 384.557 69.5164 384.762C68.2399 385.467 66.8388 386.316 66.5238 387.477C65.826 390.051 68.1817 390.831 70.1263 391.191C71.7953 391.499 73.6769 391.524 75.2245 391.524C78.0242 391.524 83.0592 391.412 83.8703 389.307C84.1016 388.709 84.0025 387.757 83.8703 387.366C83.3613 385.849 81.3285 384.899 79.8803 384.263C79.3131 384.014 77.7403 383.592 77.5521 382.877C77.2553 381.747 78.3821 380.886 78.8827 379.996C79.4102 379.057 79.8501 377.787 79.9358 376.726C80.2217 373.201 78.2443 371.03 75.2956 371.03Z'
        fill='white'
      />
      <path
        opacity='0.15'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M199 419L78 419L78 339L199 339L199 419Z'
        fill='url(#paint4_linear_16359_50335)'
      />
      <path
        d='M94 379L199 379'
        stroke='url(#paint5_linear_16359_50335)'
        strokeWidth='50'
      />
      <circle cx='198' cy='379' r='40' fill='white' />
      <circle cx='198' cy='379' r='39.5' stroke='#F8BA05' strokeOpacity='0.3' />
      <path
        opacity='0.2'
        d='M198 347C202.202 347 206.363 347.828 210.246 349.436C214.128 351.044 217.656 353.401 220.627 356.373C223.599 359.344 225.956 362.872 227.564 366.754C229.172 370.637 230 374.798 230 379C230 383.202 229.172 387.363 227.564 391.246C225.956 395.128 223.599 398.656 220.627 401.627C217.656 404.599 214.128 406.956 210.246 408.564C206.363 410.172 202.202 411 198 411C193.798 411 189.637 410.172 185.754 408.564C181.872 406.956 178.344 404.599 175.373 401.627C172.401 398.656 170.044 395.128 168.436 391.246C166.828 387.363 166 383.202 166 379C166 374.798 166.828 370.637 168.436 366.754C170.044 362.872 172.401 359.344 175.373 356.373C178.344 353.401 181.872 351.044 185.754 349.436C189.637 347.828 193.798 347 198 347L198 347Z'
        stroke='#F8BA05'
        strokeWidth='10'
      />
      <defs>
        <filter
          id='filter0_f_16359_50335'
          x='158'
          y='211'
          width='70'
          height='70'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='7.5'
            result='effect1_foregroundBlur_16359_50335'
          />
        </filter>
        <filter
          id='filter1_d_16359_50335'
          x='193'
          y='337'
          width='47'
          height='58.4336'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset />
          <feGaussianBlur stdDeviation='2.5' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.972549 0 0 0 0 0.729412 0 0 0 0 0.0196078 0 0 0 0.6 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_16359_50335'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_16359_50335'
            result='shape'
          />
        </filter>
        <linearGradient
          id='paint0_linear_16359_50335'
          x1='79'
          y1='72'
          x2='79'
          y2='386.52'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.219922' stopColor='#8665F7' />
          <stop offset='0.307435' stopColor='#6E82CC' />
          <stop offset='0.390878' stopColor='#48AF8A' />
          <stop offset='0.672373' stopColor='#48AF8A' />
          <stop offset='0.878252' stopColor='#F8BA05' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_16359_50335'
          x1='0'
          y1='79'
          x2='158'
          y2='79'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#7854F6' />
          <stop offset='1' stopColor='#7854F6' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_16359_50335'
          x1='73'
          y1='239'
          x2='194'
          y2='239'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#48AF80' />
          <stop offset='1' stopColor='#48AF80' stopOpacity='0.3' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_16359_50335'
          x1='74'
          y1='238.5'
          x2='194'
          y2='238.5'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#48AF80' stopOpacity='0.2' />
          <stop offset='1' stopColor='#48AF80' stopOpacity='0.1' />
        </linearGradient>
        <linearGradient
          id='paint4_linear_16359_50335'
          x1='78'
          y1='379'
          x2='199'
          y2='379'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F8BA05' />
          <stop offset='1' stopColor='#F8BA05' stopOpacity='0.3' />
        </linearGradient>
        <linearGradient
          id='paint5_linear_16359_50335'
          x1='94'
          y1='378.5'
          x2='199'
          y2='378.5'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F8BA05' stopOpacity='0.1' />
          <stop offset='1' stopColor='#F8BA05' stopOpacity='0.2' />
        </linearGradient>
      </defs>
    </svg>
  )
}

export const SVGBotAndRatingVertical = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}
