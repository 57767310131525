import React, { useEffect, useRef, useState } from 'react'
import { Tabs } from 'antd'
import { getText } from '../../../../lang'
import { MEDIATYPES_ONLY_IMAGE, getLongName } from '../../../../utils'
import AddVariableInMessage from '../../../../components/AddVariableInMessage'
import UploadFileForm from '../../../../components/Form/UploadFileForm'
import InputFormTextAreaSecondary from '../../../../components/Form/InputFormTextArea/InputFormTextAreaSecondary'
import SVGUploadExcel from '../../../../icons/SVG/SVGUploadExcel'
import MatImage from '../../../../components/MatImage'
import LoadingSpinner from '../../../../components/LoadingSpinner'

const CalendarInvitePanelItems = (props) => {
  const {
    title,
    languages,
    variables,
    form,
    formName,
    organization,
    selectedItem,
    setSelectedItem,
    selectedIndex,
  } = props
  const [selectedLangTab, setSelectedLangTab] = useState(languages[0])
  const [currentCursorPosition, setCurrentCursorPosition] = useState(0)
  const textAreaInputRef = useRef()

  useEffect(() => {
    setSelectedLangTab(languages[0])
  }, [])

  const formSelectedName = ['calendar_invite_sequence', ...formName, selectedLangTab]

  useEffect(() => {
    const currentMessageOnScreen = form.getFieldValue(formSelectedName)
    const messageLength = currentMessageOnScreen ? currentMessageOnScreen.length : 0
    setCurrentCursorPosition(messageLength)
  }, [selectedLangTab, selectedIndex])

  const handleClickVariable = (variable) => {
    const currentContent = form.getFieldValue(formSelectedName)
    const newContent = [
      currentContent && currentContent.slice(0, currentCursorPosition),
      variable,
      currentContent && currentContent.slice(currentCursorPosition),
    ].join('')

    form.setFieldValue(formSelectedName, newContent)
    setCurrentCursorPosition(currentCursorPosition + variable.length)
    textAreaInputRef.current &&
      textAreaInputRef.current.autoFocus(currentCursorPosition + variable.length)
  }

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  const getUpdalodData = (obj) => {
    let calendar_invite_sequence = form.getFieldValue('calendar_invite_sequence')
    let find = calendar_invite_sequence.find(
      (item, index) => index === selectedIndex
    )
    const images = { loading: {}, image: {} }
    languages.forEach((lang) => {
      if (!selectedItem.image[lang]) {
        images.image[lang] = obj[lang]
        images.loading[lang] = obj.loading[lang]
      } else if (obj[lang] === '') {
        images.image[lang] = ''
        images.loading[lang] = selectedItem.loading
          ? selectedItem.loading[lang]
          : false
      } else {
        images.image[lang] = selectedItem.image[lang]
        images.loading[lang] = selectedItem.loading
          ? selectedItem.loading[lang]
          : false
      }
    })
    if (find) {
      Object.assign(find, { ...images })
      form.setFieldsValue({
        calendar_invite_sequence: calendar_invite_sequence,
      })
      setSelectedItem(find)
    }
  }

  return (
    <div>
      <h3 className='calendar-invites-title'>{title}</h3>
      <Tabs
        className='mat-tabs'
        onChange={(index) => {
          setSelectedLangTab(organization.languages[index])
        }}
        items={organization.languages.map((lng, index) => {
          return {
            label: getLongName(lng) + ' ' + getText('WORD_MESSAGES_LC'),
            key: index,
            children: (
              <>
                <InputFormTextAreaSecondary
                  ref={textAreaInputRef}
                  name={[...formName, selectedLangTab]}
                  formClassName='with-variables'
                  placeholder={props.placeholder}
                  allowClear={false}
                  onClick={(e) => setCurrentCursorPosition(e.target.selectionStart)}
                  onKeyUp={(e) => setCurrentCursorPosition(e.target.selectionStart)}
                  onChange={(e) => setCurrentCursorPosition(e.target.selectionStart)}
                  required
                />
                <AddVariableInMessage
                  black
                  onClick={handleClickVariable}
                  data={variables}
                />
                {selectedItem.image[selectedLangTab] && (
                  <MatImage
                    url={selectedItem.image[selectedLangTab]}
                    className='image-with-remove-calender'
                    removeImage={() =>
                      getUpdalodData({
                        loading: { [selectedLangTab]: false },
                        [selectedLangTab]: '',
                      })
                    }
                  />
                )}
                {selectedItem.loading && selectedItem.loading[selectedLangTab] && (
                  <div className='single-img'>
                    <LoadingSpinner withoutHeight />
                  </div>
                )}
                {!selectedItem.image[selectedLangTab] &&
                  !Boolean(
                    selectedItem.loading && selectedItem.loading[selectedLangTab]
                  ) && (
                    <UploadFileForm
                      onStartUpload={() =>
                        getUpdalodData({ loading: { [selectedLangTab]: true } })
                      }
                      mediaTypes={MEDIATYPES_ONLY_IMAGE}
                      maxCount={1}
                      showUploadList={false}
                      beforeUploadProps
                      size={20}
                      className={'big-upload-wrapper'}
                      valuePropName='fileList'
                      getValueFromEvent={normFile}
                      onUpload={(file) =>
                        getUpdalodData({
                          loading: { [selectedLangTab]: false },
                          [selectedLangTab]: file.url,
                        })
                      }
                    >
                      <div className='big-upload-text'>
                        <div className='big-upload-title'>
                          <SVGUploadExcel />
                          <div className='no-file'>
                            {getText('TEXT_DRAG_A_FILE_HERE')}{' '}
                            {getText('WORD_OR_LOW')}{' '}
                            <strong>{getText('WORD_BROWSE')}</strong>{' '}
                            {getText('TEXT_FOR_A_FILE_TO_UPLOAD')}
                          </div>
                        </div>
                      </div>
                    </UploadFileForm>
                  )}
              </>
            ),
          }
        })}
      />
    </div>
  )
}

export default CalendarInvitePanelItems
