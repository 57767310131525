import React from "react";
import Icon from "@ant-design/icons";

const SVGIcon = (props) => {
  return (
    <svg
      width="43"
      height="42"
      viewBox="0 0 43 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="21.75" cy="21" r="21" fill="#7854F6" />
      <path
        d="M17.9215 12.6787C17.4613 12.6787 17.0882 13.052 17.0882 13.512C15.2473 13.512 13.7548 15.0045 13.7549 16.8454V17.7304L28.7549 17.6787V16.8454C28.7549 15.0045 27.2625 13.512 25.4215 13.512C25.4215 13.052 25.0485 12.6787 24.5882 12.6787C24.128 12.6787 23.7549 13.052 23.7549 13.512H18.7549C18.7549 13.052 18.3818 12.6787 17.9215 12.6787ZM13.7549 19.3454V25.1787C13.7549 27.0195 15.2473 28.512 17.0882 28.512H25.4215C27.2625 28.512 28.755 27.0195 28.7549 25.1787V19.3454H13.7549ZM17.9215 21.012C18.3818 21.012 18.7549 21.3854 18.7549 21.8454C18.7549 22.3054 18.3818 22.6787 17.9215 22.6787C17.4613 22.6787 17.0882 22.3054 17.0882 21.8454C17.0882 21.3854 17.4613 21.012 17.9215 21.012ZM21.2549 21.012C21.7151 21.012 22.0882 21.3854 22.0882 21.8454C22.0882 22.3054 21.7151 22.6787 21.2549 22.6787C20.7946 22.6787 20.4215 22.3054 20.4215 21.8454C20.4215 21.3854 20.7946 21.012 21.2549 21.012ZM24.5882 21.012C25.0485 21.012 25.4215 21.3854 25.4215 21.8454C25.4215 22.3054 25.0485 22.6787 24.5882 22.6787C24.128 22.6787 23.7549 22.3054 23.7549 21.8454C23.7549 21.3854 24.128 21.012 24.5882 21.012ZM17.9215 23.512C18.3818 23.512 18.7549 23.8854 18.7549 24.3454C18.7549 24.8054 18.3818 25.1787 17.9215 25.1787C17.4613 25.1787 17.0882 24.8054 17.0882 24.3454C17.0882 23.8854 17.4613 23.512 17.9215 23.512ZM21.2549 23.512C21.7151 23.512 22.0882 23.8854 22.0882 24.3454C22.0882 24.8054 21.7151 25.1787 21.2549 25.1787C20.7946 25.1787 20.4215 24.8054 20.4215 24.3454C20.4215 23.8854 20.7946 23.512 21.2549 23.512ZM24.5882 23.512C25.0485 23.512 25.4215 23.8854 25.4215 24.3454C25.4215 24.8054 25.0485 25.1787 24.5882 25.1787C24.128 25.1787 23.7549 24.8054 23.7549 24.3454C23.7549 23.8854 24.128 23.512 24.5882 23.512Z"
        fill="white"
      />
    </svg>
  );
};

const SVGPaymentDepositsYesterday = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />;
      }}
      {...props}
    />
  );
};

export default SVGPaymentDepositsYesterday;
