import React, { memo } from 'react'
import SVGSendInvitationIcon from '../../../../../icons/SVG/SVGSendInvitationIcon'
import MatButton from '../../../../../components/MatButton'
import ContactSendInvitationModal from '../../../right/MessageInvitationModal/ContactSendInvitationModal'

const MessageInvitationModal = (props) => {
  const {
    conversation,
    disabled,
    msgChangeEmailSMSType,
    isCustomerRedStatus,
    isCustomerYellowStatusAndRestricted,
    trigger,
    disableInvitationPhoneNumber,
    onSaveInvitation,
    isCustomerPhoneNumberValid,
  } = props

  return (
    <ContactSendInvitationModal
      conversation={conversation}
      disableInvitationPhoneNumber={disableInvitationPhoneNumber}
      disabled={
        disabled ||
        msgChangeEmailSMSType === 'email' ||
        isCustomerPhoneNumberValid ||
        isCustomerRedStatus ||
        isCustomerYellowStatusAndRestricted ||
        conversation.is_archived
      }
      onSaveInvitation={onSaveInvitation}
      customer={conversation.receiver}
      trigger={
        trigger || (
          <MatButton
            icon={<SVGSendInvitationIcon />}
            disabled={
              msgChangeEmailSMSType === 'email' ||
              isCustomerPhoneNumberValid ||
              isCustomerRedStatus ||
              isCustomerYellowStatusAndRestricted ||
              conversation.is_archived
            }
            className='icon-size only-stroke'
          />
        )
      }
    />
  )
}

export default memo(MessageInvitationModal)
