import React from 'react'
import MatRow from '../../../../components/MatRow'
import ConfirmPopup from '../../../../components/ConfirmPopup'
import MatBlockWithLabel from '../../../../components/MatBlockWithLabel'
import { getText } from '../../../../lang'
import SVGOrgEmailIcon from '../../../../icons/SVG/SVGOrgEmailIcon'
import SVGRemindMe from '../../../../icons/SVG/SVGRemindMe'
import SVGDeleteIcon from '../../../../icons/SVG/SVGDeleteIcon'
import { localizationText } from '../../../../utils'

const CalendarInviteItem = (props) => {
  const { item, isActive, onClick, deviceCheck, onDelete, index } = props

  const getAiBotMessageItemTitle = () => {
    let title = ''
    const { text } = item
    if (text) {
      if (text.en) {
        title = text.en
      } else if (text.fr) {
        title = text.fr
      } else if (text.sp) {
        title = text.sp
      }
    }
    return title.replace(new RegExp('{{source}}', 'g'), '{{touchpoint}}')
  }

  const getAutoReplyItemTitle = () => {
    if (item.delay.unit === 'immediately') {
      return getText('WORD_IMMEDIATELY')
    } else {
      if (item.delay.value) {
        return (
          item.delay.value +
          ' ' +
          localizationText(item.delay.unit) +
          ' ' +
          getText('WORD_BEFORE_APPOINTMENT')
        )
      }
      return item.delay.unit
    }
  }

  return (
    <MatBlockWithLabel
      label={
        item.isFirstItem
          ? getText('WORD_CONFIRMED')
          : index === 1
            ? getText('WORD_REMINDERS')
            : null
      }
      className={`confirmation-and-reminders-item ${
        item.isFirstItem
          ? 'calendar-confirmation-item'
          : index === 1
            ? 'calendar-reminder-first-item'
            : ''
      }`}
      black
    >
      <div
        className={`aibot-reply-item ${isActive ? 'active' : ''}`}
        onClick={onClick}
      >
        <MatRow style={{ overflow: 'hidden' }}>
          <div className='ai-bot-engagement-percent-with-icon'>
            {item.send_via === 'sms' ? (
              <SVGRemindMe color={'#808191'} width={13} height={13} />
            ) : (
              <SVGOrgEmailIcon color={'#808191'} width={13} height={13} />
            )}
          </div>
          <div className='ai-bot-time-unit'>
            {item && item.delay && (
              <div className='time-unit'>{getAutoReplyItemTitle()}</div>
            )}
            <div className='ai-bot-message'>
              {item && item.isFirstItem
                ? getText('WORD_FIRST_AUTO_MESSAGE_WL')
                : getAiBotMessageItemTitle()}
            </div>
          </div>
          {item && !item.isFirstItem && (
            <ConfirmPopup
              title={getText('MSG_DELETE_AI_BOT_MESSAGE_ITEM')}
              placement={!deviceCheck ? 'top' : 'topRight'}
              onConfirm={(e) => {
                e.stopPropagation()
                onDelete()
              }}
              trigger={
                <div className='delete-aibot-reply-item'>
                  <SVGDeleteIcon />
                </div>
              }
              cancelText={getText('WORD_NO')}
              okText={getText('WORD_YES')}
            />
          )}
        </MatRow>
      </div>
    </MatBlockWithLabel>
  )
}

export default CalendarInviteItem
