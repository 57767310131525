import React from 'react'
import { useSelector } from 'react-redux'

const UtcTimezoneMessage = ({ style, title }) => {
  const organizationTimzeZone = useSelector((state) => {
    return state.authReducer.user.organization.timezone
  })

  return (
    <div
      style={{
        fontSize: ' 12px',
        color: '#333333',
        ...style,
      }}
    >
      {title}
      <span style={{ fontWeight: 'bold', color: 'var(--black)' }}>
        {' ' + organizationTimzeZone}
      </span>
    </div>
  )
}

export default UtcTimezoneMessage
