import React, {
  forwardRef,
  useEffect,
  useState,
  useImperativeHandle,
  useCallback,
  memo,
} from 'react'
import { useSelector } from 'react-redux'
import locationActions from '../../../store/modules/locationActions'
import { getText } from '../../../lang'
import SelectFilter from '../../../components/api/SelectFilter'

const LocationFilter = forwardRef((props, ref) => {
  const { onChangeLocation } = props

  const organizationId = useSelector((state) => {
    return state.authReducer.user.organization._id
  })

  const [listData, setListData] = useState([])
  const [loadingData, setLoadingData] = useState(false)
  const [selectedLocation, setSelectedLocation] = useState([])
  const [totalRows, setTotalRows] = useState(0)
  const [page, setPage] = useState(0)
  const [perPage] = useState(50)
  const [open, setOpen] = useState(false)

  useImperativeHandle(ref, () => {
    return {
      resetLocation: () => {
        setSelectedLocation([])
        onChangeLocation([])
      },
    }
  })

  const fillLocation = useCallback(async () => {
    setLoadingData(true)
    let result = await locationActions.getPageList(page, perPage, '', {}, true)
    if (result.success) {
      let list = result.data.map((oo) => {
        return { value: oo._id, label: oo.name, ...oo }
      })
      let oldValue = listData
      if (page > 0) {
        list = oldValue.concat(list)
      }
      setListData(list)
      setTotalRows(result.max)
    }
    setLoadingData(false)
  }, [page, perPage, organizationId])

  useEffect(() => {
    if (open) {
      fillLocation()
    }
  }, [fillLocation, open])

  return (
    <div className='dropdown-with-selected-item'>
      <SelectFilter
        label={getText('WORD_LOCATIONS')}
        placeholder={getText('WORD_SELECT_ITEM')}
        option={listData}
        loading={loadingData}
        value={selectedLocation}
        mode='multiple'
        onChangeItem={(val, items) => {
          onChangeLocation(val)
          setSelectedLocation(val)
        }}
        onLoadMore={() => {
          if (listData.length < totalRows && !loadingData) {
            setPage(page + 1)
          }
        }}
        onDropdownVisibleChange={() => setOpen(true)}
      />
    </div>
  )
})

export default memo(LocationFilter)
