import React, { useEffect, useState } from 'react'
import { getText } from '../../../../lang'
import InputFormNumber from '../../../../components/Form/InputFormNumber'
import SelectChecked from '../../../../components/Form/SelectChecked'
import { DAYS, HOURS, IMMEDIATELY, MINUTES, NIGHT } from '../../../../devOptions'

const AutoReplyDelay = (props) => {
  const {
    selectedMessage,
    field,
    sequenceData,
    triggeredOn,
    form,
    selectedIndex,
    type,
  } = props

  const [isImmediately, setIsImmediately] = useState()

  useEffect(() => {
    if (selectedMessage.isFirstItem && selectedMessage.time_unit) {
      if (selectedMessage.time_unit.unit === IMMEDIATELY) {
        setIsImmediately(true)
      }
    } else if (selectedMessage.isSecondMsg && selectedMessage.time_unit) {
      if (selectedMessage.time_unit.unit === IMMEDIATELY) {
        setIsImmediately(true)
      } else {
        setIsImmediately(false)
      }
    } else {
      setIsImmediately(false)
    }
  }, [selectedMessage])

  const handleDelayChange = (newValue, property) => {
    const autoMessagesList = form.getFieldValue('auto_reply_messages')
    const newTimeUnit = autoMessagesList[selectedIndex].time_unit
    newTimeUnit[property] = newValue

    autoMessagesList[selectedIndex].time_unit = newTimeUnit
    form.setFieldsValue({ auto_reply_messages: autoMessagesList })
  }

  const timeUnitsOptions = [
    ...(sequenceData?.type !== NIGHT && type !== NIGHT
      ? [
          {
            label: getText('WORD_DAYS').toLowerCase(),
            value: DAYS,
          },
          {
            label: getText('WORD_HOURS'),
            value: HOURS,
          },
        ]
      : []),
    {
      label: getText('WORD_MINUTES'),
      value: MINUTES,
    },
    ...(selectedMessage.isFirstItem || selectedMessage.isSecondMsg
      ? [
          {
            label: getText('WORD_IMMEDIATELY'),
            value: IMMEDIATELY,
          },
        ]
      : []),
  ]

  if (!triggeredOn) {
    return (
      <div className='you-can-use' style={{ marginTop: '0px' }}>
        {getText('TEXT_PLEASE_SELECT_TRIGGERED_ON_OPTION_FIRST')}
      </div>
    )
  }

  return (
    <div className='aibot-auto-message-descriptions'>
      <div className='ai-bot-message-description-first-part'>
        <span className='aibot-auto-message-descriptions-text'>
          {selectedMessage.isFirstItem ? (
            <>
              <strong className='bold-text'>
                {getText('WORD_FIRST_AUTO_MESSAGE')}
              </strong>{' '}
              {getText('TEXT_SHOULD_BE_SENT')}
            </>
          ) : (
            getText('TEXT_SEND_FOLLOWUP')
          )}
        </span>
        <>
          <InputFormNumber
            className='autoreply-follow-up-value'
            initialValue={{
              value: selectedMessage?.time_unit?.value,
            }}
            onChange={(value) => handleDelayChange(value, 'value')}
            style={isImmediately ? { display: 'none' } : null}
            name={[field, 'time_unit', 'value']}
            required
            min={!isImmediately && 1}
            max={
              !isImmediately &&
              (sequenceData?.type === NIGHT || type === NIGHT) &&
              15
            }
            maxMessage={
              (sequenceData?.type === NIGHT || type === NIGHT) &&
              getText('MSG_MAXIMUM_DELAY_FOR_AFTER_HOUR_SEQUENCE')
            }
            minMessage={
              (sequenceData?.type === NIGHT || type === NIGHT) &&
              getText('MSG_MINIMUM_DELAY_FOR_AFTER_HOUR_SEQUENCE')
            }
            errorMessage={
              sequenceData?.type === NIGHT || type === NIGHT
                ? getText('MSG_MINIMUM_DELAY_FOR_AFTER_HOUR_SEQUENCE')
                : getText('ERR_PLEASE_FILL_TIME_UNIT')
            }
          />
          <SelectChecked
            onChange={(value) => {
              handleDelayChange(value, 'unit')
              value === IMMEDIATELY
                ? setIsImmediately(true)
                : setIsImmediately(false)
            }}
            className='autoreply-follow-up-unit'
            initialValue={{
              unit: isImmediately ? IMMEDIATELY : selectedMessage?.time_unit?.unit,
            }}
            name={[field, 'time_unit', 'unit']}
            placeholder=''
            label=''
            dropdownStyle={{ width: '100px' }}
            showSearch={false}
            multipleNewStyle
            option={timeUnitsOptions}
            allowClear={false}
            required
            dropdownClassName={'aibot-auto-message-follow-up-dropdown'}
          />
        </>
        <span className='aibot-auto-message-descriptions-text'>
          {getText('WORD_AFTER')}{' '}
          <strong>
            {triggeredOn === 'touchpoint'
              ? getText('TEXT_RECEIVING_A_LEAD')
              : getText('TEXT_CUSTOMER_LAST_SESSION')}
          </strong>
        </span>
      </div>
      {props.children}
    </div>
  )
}

export default AutoReplyDelay
