import React, { forwardRef, memo } from 'react'
import SVGLocation from '../../../../../icons/SVG/SVGLocation'
import { getText } from '../../../../../lang'
import messageActions from '../../../../../store/modules/messageActions'
import { notifyError, notifySuccess } from '../../../../../utils/Notify'
import MessageSelectLocation from '../MessageSelectLocation'

const MessageRightSelectLocations = forwardRef((props, ref) => {
  return (
    <div className='message-info-tab-select-location-div'>
      <SVGLocation />
      <MessageSelectLocation
        idLocation={props.conversation._location_id}
        disabled={props.conversation.isNew}
        onSelectItem={async (item) => {
          let result = await messageActions.selectLocationForConversation(
            props.conversation._id,
            item._id
          )
          if (result.success) {
            notifySuccess(getText('TEXT_LOCATION_SELECTED_SUCCESSFULLY'))
            props.onSelectItem && props.onSelectItem(result.data, false)
          } else {
            notifyError(result.errMsg)
          }
        }}
        getPopupContainer={() => document.body}
      />
    </div>
  )
})

export default memo(MessageRightSelectLocations)
