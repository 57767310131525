export const listVariables = () => {
  return [
    {
      id: 'customer_first_name',
      name: 'customer_first_name',
      label: 'customer_first_name',
      value: '{{customer_first_name}}',
      display: '{{customer_first_name}}',
    },
    {
      id: 'customer_last_name',
      name: 'customer_last_name',
      label: 'customer_last_name',
      value: '{{customer_last_name}}',
      display: '{{customer_last_name}}',
    },
    {
      id: 'customer_full_name',
      name: 'customer_full_name',
      label: 'customer_full_name',
      value: '{{customer_full_name}}',
      display: '{{customer_full_name}}',
    },
    {
      id: 'organization_name',
      name: 'organization_name',
      label: 'organization_name',
      value: '{{organization_name}}',
      display: '{{organization_name}}',
    },
    {
      id: 'location_name',
      name: 'location_name',
      label: 'location_name',
      value: '{{location_name}}',
      display: '{{location_name}}',
    },
    {
      id: 'user_first_name',
      name: 'user_first_name',
      label: 'user_first_name',
      value: '{{user_first_name}}',
      display: '{{user_first_name}}',
    },
    {
      id: 'user_last_name',
      name: 'user_last_name',
      label: 'user_last_name',
      value: '{{user_last_name}}',
      display: '{{user_last_name}}',
    },
    {
      id: 'user_phone',
      name: 'user_phone',
      label: 'user_phone',
      value: '{{user_phone}}',
      display: '{{user_phone}}',
    },
    {
      id: 'user_email',
      name: 'user_email',
      label: 'user_email',
      value: '{{user_email}}',
      display: '{{user_email}}',
    },
    {
      id: 'year',
      name: 'year',
      label: 'year',
      value: '{{year}}',
      display: '{{year}}',
    },
    {
      id: 'make',
      name: 'make',
      label: 'make',
      value: '{{make}}',
      display: '{{make}}',
    },
    {
      id: 'model',
      name: 'model',
      label: 'model',
      value: '{{model}}',
      display: '{{model}}',
    },
    {
      label: 'vin',
      id: 'vin',
      name: 'vin',
      value: ' {{VIN}} ',
      display: ' {{VIN}} ',
    },
    {
      id: 'trade_year',
      name: 'trade_year',
      label: 'trade_year',
      value: '{{trade_year}}',
      display: '{{trade_year}}',
    },
    {
      id: 'trade_make',
      name: 'trade_make',
      label: 'trade_make',
      value: '{{trade_make}}',
      display: '{{trade_make}}',
    },
    {
      id: 'trade_model',
      name: 'trade_model',
      label: 'trade_model',
      value: '{{trade_model}}',
      display: '{{trade_model}}',
    },
    {
      label: 'trade_VIN',
      id: 'trade_VIN',
      name: 'trade_VIN',
      value: '{{trade_VIN}}',
      display: ' {{trade_VIN}} ',
    },
    {
      id: 'touchpoint',
      name: 'touchpoint',
      label: 'touchpoint',
      value: '{{touchpoint}}',
      display: '{{touchpoint}}',
    },
    {
      label: 'offer_amount',
      id: 'offer_amount',
      name: 'offer_amount',
      value: '{{offer_amount}}',
      display: ' {{offer_amount}} ',
    },
    {
      label: 'price_estimate',
      id: 'price_estimate',
      name: 'price_estimate',
      value: '{{price_estimate}}',
      display: ' {{price_estimate}} ',
    },
  ]
}
