import React from "react";
import Icon from "@ant-design/icons";

const SVGIcon = (props) => {
  return (
    <svg
      width="32"
      height="11"
      viewBox="0 0 32 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8443 0.18254L13.7065 10.1775H11.1205L13.2586 0.18254H15.8443ZM26.723 6.6364L28.0839 2.8831L28.8672 6.6364H26.723ZM29.609 10.1775H32L29.9111 0.18254H27.7056C27.2086 0.18254 26.7895 0.470737 26.6041 0.915172L22.7242 10.1775H25.4398L25.9789 8.68481H29.2959L29.609 10.1775ZM22.8588 6.91448C22.8701 4.27665 19.2123 4.13053 19.2368 2.95189C19.2447 2.59377 19.5864 2.21228 20.3332 2.11472C20.7034 2.06706 21.7252 2.0284 22.8833 2.56208L23.3363 0.441738C22.7143 0.21671 21.9138 0 20.9177 0C18.3614 0 16.5632 1.35781 16.5489 3.3037C16.5324 4.74267 17.8334 5.54499 18.8115 6.02427C19.82 6.51411 20.1578 6.82816 20.1529 7.26585C20.1459 7.93644 19.3488 8.2334 18.6065 8.24464C17.3058 8.26488 16.5518 7.89305 15.9509 7.61295L15.4815 9.8041C16.0867 10.0811 17.2017 10.322 18.3563 10.3344C21.0739 10.3344 22.8508 8.99256 22.8588 6.91448ZM12.1495 0.18254L7.95982 10.1775H5.22689L3.165 2.20082C3.04001 1.7103 2.93098 1.53001 2.55084 1.32274C1.92903 0.985086 0.902583 0.669238 0 0.47276L0.0611471 0.18254H4.46099C5.02142 0.18254 5.52565 0.555487 5.65402 1.20112L6.74318 6.9853L9.43295 0.18254H12.1495Z"
        fill="#1434CB"
      />
    </svg>
  );
};

const SVGPaymentVisaIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />;
      }}
      {...props}
    />
  );
};

export default SVGPaymentVisaIcon;
