import React from 'react'

function SVGOrganic(props) {
  const { activeIcon, ...rest } = props

  const SVGFile = (
    <svg width={30} height={30} viewBox='0 0 30 30' {...rest}>
      <g data-name='Group 7280' transform='translate(-722 -212)'>
        <circle
          data-name='Ellipse 604'
          cx={15}
          cy={15}
          r={15}
          transform='translate(722 212)'
          fill={rest.color ? rest.color : '#9676e5'}
        />
        <g data-name='Group 7020'>
          <path
            data-name='Path 5799'
            d='M732.309 232.928l3.381-2.536h3.064c5.811 0 5.811-8.769 0-8.769h-3.487a4.346 4.346 0 00-2.113 8.136zm1.8-7.819a.951.951 0 11-.951.951.947.947 0 01.947-.95zm2.958 0a.951.951 0 11-.951.951.947.947 0 01.948-.95zm2.958 0a.951.951 0 11-.951.951.947.947 0 01.948-.95z'
            fill={rest.color ? rest.color : '#fff'}
            fillRule='evenodd'
          />
        </g>
      </g>
    </svg>
  )
  if (!activeIcon) {
    return SVGFile
  }
  return (
    <div className={'mat-user-profile-letters'}>
      {activeIcon && <div className='active-icon' />}
      {SVGFile}
    </div>
  )
}

export default SVGOrganic
