import React from 'react';
import Icon from '@ant-design/icons';

const SVGIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="198.724" height="151.202" viewBox="0 0 198.724 151.202">
        <defs>
            <filter id="Path_1560" x="0" y="0" width="138.483" height="120.482" filterUnits="userSpaceOnUse">
                <feOffset dy="3" input="SourceAlpha" />
                <feGaussianBlur stdDeviation="3" result="blur" />
                <feFlood floodColor="#4964ae" floodOpacity="0.161" />
                <feComposite operator="in" in2="blur" />
                <feComposite in="SourceGraphic" />
            </filter>
            <filter id="Path_1562" x="60.24" y="30.72" width="138.483" height="120.482" filterUnits="userSpaceOnUse">
                <feOffset dy="3" input="SourceAlpha" />
                <feGaussianBlur stdDeviation="3" result="blur-2" />
                <feFlood floodColor="#566887" floodOpacity="0.161" />
                <feComposite operator="in" in2="blur-2" />
                <feComposite in="SourceGraphic" />
            </filter>
            <linearGradient id="linear-gradient" x1="0.5" y1="1" x2="0.5" gradientUnits="objectBoundingBox">
                <stop offset="0" stopColor="#fff" stopOpacity="0.251" />
                <stop offset="0.54" stopColor="#fff" stopOpacity="0.122" />
                <stop offset="1" stopColor="gray" stopOpacity="0.102" />
            </linearGradient>
        </defs>
        <g id="undraw_typing_jie3" transform="translate(7.433 4.275)">
            <g transform="matrix(1, 0, 0, 1, -7.43, -4.28)" filter="url(#Path_1560)">
                <path id="Path_1560-2" data-name="Path 1560" d="M7.57,8.33H128.053V95.211h-78.2l-7.243,15.6-8.135-15.6H7.57Z" transform="translate(1.43 -2.33)" fill="#4c84ff" />
            </g>
            <rect id="Rectangle_1791" data-name="Rectangle 1791" width="89.761" height="3.841" transform="translate(106.569 31.606) rotate(180)" fill="#fff" opacity="0.3" />
            <rect id="Rectangle_1792" data-name="Rectangle 1792" width="89.761" height="3.841" transform="translate(106.569 40.006) rotate(180)" fill="#fff" opacity="0.3" />
            <rect id="Rectangle_1793" data-name="Rectangle 1793" width="89.761" height="3.841" transform="translate(106.569 48.406) rotate(180)" fill="#fff" opacity="0.3" />
            <rect id="Rectangle_1794" data-name="Rectangle 1794" width="89.761" height="3.841" transform="translate(106.569 56.806) rotate(180)" fill="#fff" opacity="0.3" />
            <g transform="matrix(1, 0, 0, 1, -7.43, -4.28)" filter="url(#Path_1562)">
                <path id="Path_1562-2" data-name="Path 1562" d="M419,156.7H298.52v86.881h78.2l7.243,15.6,8.135-15.6H419Z" transform="translate(-229.28 -119.98)" fill="#fff" />
            </g>
            <circle id="Ellipse_449" data-name="Ellipse 449" cx="8.76" cy="8.76" r="8.76" transform="translate(91.449 67.125)" fill="url(#linear-gradient)" />
            <circle id="Ellipse_450" data-name="Ellipse 450" cx="8.76" cy="8.76" r="8.76" transform="translate(113.29 67.125)" fill="url(#linear-gradient)" />
            <circle id="Ellipse_451" data-name="Ellipse 451" cx="8.76" cy="8.76" r="8.76" transform="translate(135.13 67.125)" fill="url(#linear-gradient)" />
            <circle id="Ellipse_452" data-name="Ellipse 452" cx="7.92" cy="7.92" r="7.92" transform="translate(92.289 67.965)" fill="#4c84ff" />
            <circle id="Ellipse_453" data-name="Ellipse 453" cx="7.92" cy="7.92" r="7.92" transform="translate(114.131 67.965)" fill="#4c84ff" opacity="0.6" />
            <circle id="Ellipse_454" data-name="Ellipse 454" cx="7.92" cy="7.92" r="7.92" transform="translate(135.97 67.965)" fill="#4c84ff" opacity="0.3" />
        </g>
    </svg>

);

const SVGMessageCenterNoData = props => <Icon component={SVGIcon} {...props} />;

export default SVGMessageCenterNoData;