import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = ({ color, background }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='50'
      height='50'
      viewBox='0 0 50 50'
      fill='none'
    >
      <circle cx='25' cy='25' r='25' fill={background ? background : '#EEFDF4'} />
      <path
        d='M18.25 31.5312C18.4572 31.5312 18.6559 31.4489 18.8024 31.3024C18.9489 31.1559 19.0312 30.9572 19.0312 30.75C19.0312 30.4609 19.1094 23.7188 26.0625 23.7188C34.5547 23.7188 34.6562 15.2109 34.6562 15.125C34.6562 14.9178 34.5739 14.7191 34.4274 14.5726C34.2809 14.4261 34.0822 14.3438 33.875 14.3438C33.6678 14.3438 33.4691 14.4261 33.3226 14.5726C33.1761 14.7191 33.0938 14.9178 33.0938 15.125C33.0938 15.4141 33.0156 22.1562 26.0625 22.1562C17.5703 22.1562 17.4688 30.6641 17.4688 30.75C17.4688 30.9572 17.5511 31.1559 17.6976 31.3024C17.8441 31.4489 18.0428 31.5312 18.25 31.5312Z'
        fill={color ? color : '#48AD7F'}
      />
      <path
        d='M34.6562 33.875H15.9062C15.699 33.875 15.5003 33.7927 15.3538 33.6462C15.2073 33.4997 15.125 33.301 15.125 33.0938V14.3438C15.125 14.1365 15.0427 13.9378 14.8962 13.7913C14.7497 13.6448 14.551 13.5625 14.3438 13.5625C14.1365 13.5625 13.9378 13.6448 13.7913 13.7913C13.6448 13.9378 13.5625 14.1365 13.5625 14.3438V33.0938C13.5625 33.7154 13.8094 34.3115 14.249 34.751C14.6885 35.1906 15.2846 35.4375 15.9062 35.4375H34.6562C34.8635 35.4375 35.0622 35.3552 35.2087 35.2087C35.3552 35.0622 35.4375 34.8635 35.4375 34.6562C35.4375 34.449 35.3552 34.2503 35.2087 34.1038C35.0622 33.9573 34.8635 33.875 34.6562 33.875Z'
        fill={color ? color : '#48AD7F'}
      />
    </svg>
  )
}

const SVGChartIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGChartIcon
