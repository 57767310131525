import React from 'react'
import {
  VideoCameraOutlined,
  FileImageOutlined,
  FilePdfOutlined,
} from '@ant-design/icons'
import ConfirmPopup from '../../../../components/ConfirmPopup'
import MatRow from '../../../../components/MatRow'
import { getText } from '../../../../lang'
import SVGOrgSMSIcon from '../../../../icons/SVG/SVGOrgSMSIcon'
import SVGOrgEmailIcon from '../../../../icons/SVG/SVGOrgEmailIcon'
import SVGDelete from '../../../../icons/SVG/SVGDelete'

const AutoReplyItem = (props) => {
  const { item, sequenceData, deviceCheck, isActive, onClick, onDelete } = props

  const getAiBotMessageItemTitle = () => {
    let title = ''
    const { text } = item
    if (text) {
      if (text.en) {
        title = text.en
      } else if (text.fr) {
        title = text.fr
      } else if (text.sp) {
        title = text.sp
      }
    }
    return title.replace(new RegExp('{{source}}', 'g'), '{{touchpoint}}')
  }

  const getColor = (color) => {
    if (color < 20) {
      return 'red'
    } else if (color < 50) {
      return 'orange'
    } else {
      return 'green'
    }
  }

  const getAutoReplyItemTitle = () => {
    if (item.time_unit.unit === 'immediately') {
      return getText('WORD_IMMEDIATELY')
    } else {
      if (item.time_unit.value) {
        return item.time_unit.value + ' ' + item.time_unit.unit
      }
      return item.time_unit.unit
    }
  }

  return (
    <div
      className={`aibot-reply-item ${isActive ? 'active' : ''}`}
      onClick={onClick}
    >
      <MatRow flexStart style={{ overflow: 'hidden' }}>
        <div className='ai-bot-engagement-percent-with-icon'>
          {item.send_via === 'sms' ? (
            <SVGOrgSMSIcon color={'#200E32'} width={18} height={18} />
          ) : (
            <SVGOrgEmailIcon color={'#200E32'} width={18} height={18} />
          )}

          <div
            className={`ai-bot-engagement-percent  
                            ${
                              sequenceData && item && item.isFirstItem
                                ? getColor(
                                    Math.round(
                                      sequenceData.first_message_engagement_percentage
                                    )
                                  )
                                : item.engagement_percentage
                                  ? getColor(item.engagement_percentage.toFixed(0))
                                  : getColor(0)
                            }
                        `}
          >
            {sequenceData && item && item.isFirstItem
              ? Math.round(sequenceData.first_message_engagement_percentage) + '%'
              : item && item.engagement_percentage
                ? `${item && item.engagement_percentage.toFixed(0)}%`
                : '0%'}
          </div>
          <div
            className={`ai-bot-engagement-percent-line
                            ${
                              sequenceData && item && item.isFirstItem
                                ? getColor(
                                    Math.round(
                                      sequenceData.first_message_engagement_percentage
                                    )
                                  )
                                : item && item.engagement_percentage
                                  ? getColor(item.engagement_percentage.toFixed(0))
                                  : getColor(0)
                            }
                        `}
            style={{
              width: `calc(${
                sequenceData && item && item.isFirstItem
                  ? Math.round(sequenceData.first_message_engagement_percentage) +
                    '%'
                  : item && item.engagement_percentage
                    ? item.engagement_percentage.toFixed(0)
                    : 0
              }  - 46px)`,
            }}
          />
        </div>
        <div className='ai-bot-time-unit'>
          {item && item.time_unit && (
            <div className='time-unit'>{getAutoReplyItemTitle()}</div>
          )}
          <div className='ai-bot-message'>
            {sequenceData && sequenceData.type === 'night'
              ? getText('WORD_AFTER_HOUR_MESSAGE')
              : item && item.isFirstItem
                ? getText('WORD_FIRST_AUTO_MESSAGE_WL')
                : getAiBotMessageItemTitle()}
          </div>
        </div>
      </MatRow>
      <div className='video-in-ai-bot-tab'>
        {item._video_upload_id && (
          <div className='video-small-icon'>
            <VideoCameraOutlined
              style={{ backgroundColor: '#FFF', fontSize: '14px' }}
            />
          </div>
        )}
        {item.media && item.media.length ? (
          <div className='video-small-icon'>
            {item.media[0].mediaContentType === 'application/pdf' ? (
              <FilePdfOutlined
                style={{ backgroundColor: '#FFF', fontSize: '14px' }}
              />
            ) : (
              <FileImageOutlined
                style={{ backgroundColor: '#FFF', fontSize: '14px' }}
              />
            )}
          </div>
        ) : null}
        {item && !item.isFirstItem && (
          <ConfirmPopup
            title={getText('MSG_DELETE_AI_BOT_MESSAGE_ITEM')}
            placement={!deviceCheck ? 'top' : 'topRight'}
            onConfirm={(e) => {
              e.stopPropagation()
              onDelete()
            }}
            okText={getText('WORD_YES')}
            cancelText={getText('WORD_NO')}
            trigger={
              <div className='delete-aibot-reply-item'>
                <SVGDelete width={10.83} height={12.7} color={'#FF7366'} />
              </div>
            }
          />
        )}
      </div>
    </div>
  )
}

export default AutoReplyItem
