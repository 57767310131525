import React from 'react'
import LocationCouponTitleTextarea from './LocationCouponTitleTextarea'
import LocationsCouponPopover from './LocationsCouponPopover'

const LocationCouponTitle = (props) => {
  const {
    titleEn,
    titleFr,
    titleSp,
    lang,
    onChangeTitleEn,
    onChangeTitleFr,
    onChangeTitleSp,
    organization,
    onChangeColor,
    titleColor,
    titleFontSize,
    setTitleFontSize,
    visibleTitleEdit,
    setVisibleTitleEdit,
    onChangeLanguage,
  } = props

  return (
    <div className='text-with-edit-icon'>
      {lang === 'en' && (
        <LocationCouponTitleTextarea
          value={titleEn}
          titleColor={titleColor}
          titleFontSize={titleFontSize}
          onChangeTitle={(e) => {
            onChangeTitleEn && onChangeTitleEn(e)
          }}
        />
      )}
      {lang === 'fr' && (
        <LocationCouponTitleTextarea
          value={titleFr}
          titleColor={titleColor}
          titleFontSize={titleFontSize}
          onChangeTitle={(e) => {
            onChangeTitleFr && onChangeTitleFr(e)
          }}
        />
      )}
      {lang === 'sp' && (
        <LocationCouponTitleTextarea
          value={titleSp}
          titleColor={titleColor}
          titleFontSize={titleFontSize}
          onChangeTitle={(e) => {
            onChangeTitleSp && onChangeTitleSp(e)
          }}
        />
      )}
      <LocationsCouponPopover
        organization={organization}
        color={titleColor}
        setColor={(color) => {
          onChangeColor && onChangeColor(color)
        }}
        fontSize={titleFontSize}
        setFontSize={setTitleFontSize}
        visibleEdit={visibleTitleEdit}
        setVisibleEdit={setVisibleTitleEdit}
        whitoutFontSize
        onChangeLanguage={onChangeLanguage}
        // withoutSelect={withoutSelect}
        onlyBackground
      />
    </div>
  )
}

export default LocationCouponTitle
