import React from "react"

function SVGSettingsOrg(props) {
  return (
    <svg width={16} height={15.944} viewBox="0 0 16 15.944" {...props}>
      <g data-name="Group 7517">
        <path
          data-name="Path 5881"
          d="M.571 2.829h2.938a2.286 2.286 0 004.411 0h7.509a.572.572 0 000-1.143H7.92a2.286 2.286 0 00-4.411 0H.571a.572.572 0 100 1.143zm5.143-1.714a1.143 1.143 0 11-1.143 1.143 1.143 1.143 0 011.143-1.143zm9.714 12H7.92a2.286 2.286 0 00-4.411 0H.571a.572.572 0 100 1.143h2.938a2.286 2.286 0 004.411 0h7.509a.572.572 0 000-1.143zm-9.714 1.714a1.143 1.143 0 111.143-1.143 1.143 1.143 0 01-1.143 1.143zm9.715-7.428h-2.938a2.286 2.286 0 00-4.411 0H.571a.572.572 0 100 1.143H8.08a2.286 2.286 0 004.411 0h2.937a.572.572 0 100-1.143zm-5.143 1.714a1.143 1.143 0 111.143-1.143 1.143 1.143 0 01-1.143 1.143z"
          fill={props.color ? props.color : "#fff"}
        />
      </g>
    </svg>
  )
}

export default SVGSettingsOrg
