import React from 'react'
import { Tooltip } from 'antd'
import { getText } from '../../../../lang'
import MatSwitch from '../../../../components/MatSwitch'
import './SwitchWithText.scss'

const SwitchWithText = (props) => {
  const {
    title,
    disabled,
    value,
    isDefault,
    onSetAsDefaultClick,
    onChangeSwitch,
    className,
    noText,
    icon,
    leftIcon,
    style,
    tooltip,
    tooltipText,
  } = props

  return (
    <div
      style={style}
      className={`organization-switch-with-icon-and-text ${className || ''}`}
    >
      <div className={`text-with-icon ${leftIcon ? 'left-icon' : ''}`}>
        {title && (
          <span className={`title ${value ? 'active' : 'not-active'}`}>{title}</span>
        )}
        {icon && (
          <span className={`icon ${value ? 'active' : 'not-active'}`}>{icon}</span>
        )}
      </div>
      <div className='text-with-icon'>
        {!noText && (
          <>
            {!isDefault ? (
              <span
                className={`title ${value ? 'active' : 'not-active'}`}
                onClick={onSetAsDefaultClick}
              >
                {getText('TEXT_SET_AS_DEFAULT')}
              </span>
            ) : (
              <small>{getText('WORD_DEFAULT')}</small>
            )}
          </>
        )}
        {tooltip ? (
          <Tooltip placement='topRight' title={tooltipText}>
            <div />
            <MatSwitch disabled={disabled} value={value} onChange={onChangeSwitch} />
          </Tooltip>
        ) : (
          <MatSwitch disabled={disabled} value={value} onChange={onChangeSwitch} />
        )}
      </div>
    </div>
  )
}

export default SwitchWithText
