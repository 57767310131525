import React from 'react'
import { getText } from '../../../../../lang'
import SVGOvalSmsScore from '../../../../../icons/SVG/SVGOvalSmsScore'

function AutoOptimizing() {
  return (
    <>
      <div className='auto-opt'>
        <div className='auto-opt-icons'>
          <div className='oval-sms-score'>
            <SVGOvalSmsScore className={'anticon-spin'} />
          </div>
        </div>
        <div className='text'> {getText('AUTO_OPTIMIZING')}</div>
      </div>
      <div className='average-text-wrapper'>
        {' '}
        {getText('AVG_INDUSTRY_HEALTHSCORE')}
        <strong>68%</strong>
      </div>
    </>
  )
}

export default AutoOptimizing
