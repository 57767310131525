import React from 'react'

function SVGDragDrop(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='10'
      height='14'
      viewBox='0 0 10 14'
    >
      <g id='Group_1840' data-name='Group 1840' transform='translate(-835 -629)'>
        <circle
          id='Ellipse_416'
          data-name='Ellipse 416'
          cx='1.75'
          cy='1.75'
          r='1.75'
          transform='translate(838.5 634.25) rotate(90)'
          fill='#a3a6be'
        />
        <circle
          id='Ellipse_417'
          data-name='Ellipse 417'
          cx='1.75'
          cy='1.75'
          r='1.75'
          transform='translate(838.5 629) rotate(90)'
          fill='#a3a6be'
        />
        <circle
          id='Ellipse_418'
          data-name='Ellipse 418'
          cx='1.75'
          cy='1.75'
          r='1.75'
          transform='translate(838.5 639.5) rotate(90)'
          fill='#a3a6be'
        />
        <circle
          id='Ellipse_416-2'
          data-name='Ellipse 416'
          cx='1.75'
          cy='1.75'
          r='1.75'
          transform='translate(845 634.25) rotate(90)'
          fill='#a3a6be'
        />
        <circle
          id='Ellipse_417-2'
          data-name='Ellipse 417'
          cx='1.75'
          cy='1.75'
          r='1.75'
          transform='translate(845 629) rotate(90)'
          fill='#a3a6be'
        />
        <circle
          id='Ellipse_418-2'
          data-name='Ellipse 418'
          cx='1.75'
          cy='1.75'
          r='1.75'
          transform='translate(845 639.5) rotate(90)'
          fill='#a3a6be'
        />
      </g>
    </svg>
  )
}

export default SVGDragDrop
