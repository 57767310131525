import React, { useEffect, useState } from 'react'
import { Form } from 'antd'
import OrganizationLayout from '../../components/organizationNewPage/OrganizationLayout'
import { getText, getTextServerError } from '../../../../lang'
import OrganizationListTitle from '../../components/organizationNewPage/OrganizationListTitle'
import MatSwitch from '../../../../components/MatSwitch'
import PageFull from '../../../../components/PageFull'
import MatForm from '../../../../components/Form/MatForm'
import MatButton from '../../../../components/MatButton'
import { notifyError, notifySuccess } from '../../../../utils/Notify'
import organizationActions from '../../../../store/modules/organizationActions'
import authActions from '../../../../store/modules/authActions'
import IPWhiteListItem from './IPWhiteListItem'
import './IPWhiteList.scss'

const IPWhiteList = (props) => {
  const { organization, setLoading } = props

  const [enableIPWhiteList, setEnableIPWhiteList] = useState(
    organization.ipWhitelist.enabled
  )

  const [form] = Form.useForm()

  useEffect(() => {
    props.setFooterButtons && props.setFooterButtons(['save', 'cancel'])
    form.setFieldsValue({
      ipWhitelist: organization.ipWhitelist,
    })
  }, [])

  const onFinish = async (values) => {
    setLoading(true)

    if (values.ipWhitelist.ips.length > 10) {
      setLoading(false)
      return notifyError(
        getText('MSG_THE_NUMBER_OF_IP_WHITELIST_CANNOT_BE_MORE_THAN')
      )
    }

    const ips = values.ipWhitelist.ips.filter((item) => item !== '')

    const obj = {
      ips: ips,
      enabled: enableIPWhiteList,
    }

    const result = await organizationActions.setIPWhiteList(obj, organization._id)

    if (result.success) {
      notifySuccess(
        !organization._id
          ? getText('TEXT_ORGANIZATION_WAS_CREATED_SUCCESSFULLY')
          : getText('TEXT_ORGANIZATION_WAS_SAVED_SUCCESSFULLY')
      )
      organization.ipWhitelist = result.data
      props.setOrganization(organization)
      const currentOrganization = authActions.getOrganization()
      if (organization._id === currentOrganization._id) {
        authActions.setOrganization(organization)
      }
    } else {
      notifyError(getTextServerError(result.errMsg))
    }

    setLoading(false)
  }

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields.length > 0) {
      if ('Please input Please insert whitelist IP address!') {
        notifyError(getText('MSG_PLEASE_INSERT_WHITELIST_IP_ADDRESS'))
      } else {
        notifyError(getTextServerError(errorInfo.errorFields[0].errors[0]))
      }
    }
  }

  return (
    <OrganizationLayout className={'ip-whitelist-wrapper'}>
      <OrganizationListTitle
        title={getText('WORD_IP_WHITELIST')}
        titleNextTo={
          <MatSwitch
            value={enableIPWhiteList}
            onChange={(value) => setEnableIPWhiteList(value)}
          />
        }
        subtitle={getText(
          'TEXT_ONLY_SPECIFIC_IP_ADDRESSES_GET_ACCESS_WORKING_THIS_ORGANIZATION_MATADOR'
        )}
      />
      <PageFull className={'ip-whitelist-body'}>
        <MatForm
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{
            enabled: true,
          }}
        >
          <Form.List name={['ipWhitelist', 'ips']}>
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => {
                  return (
                    <IPWhiteListItem
                      key={index}
                      field={field}
                      onClick={add}
                      form={form}
                      onDelete={() => {
                        remove(index)
                      }}
                    />
                  )
                })}
                <MatButton
                  htmlType={'button'}
                  onClick={() => {
                    add('')
                  }}
                  buttonText={getText('WORD_ADD_NEW_IP')}
                  typeButton={'white'}
                />
              </>
            )}
          </Form.List>
        </MatForm>
      </PageFull>
    </OrganizationLayout>
  )
}

export default IPWhiteList
