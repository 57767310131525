import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24.545'
    height='24.545'
    viewBox='0 0 24.545 24.545'
  >
    <g id='Send_1_' data-name='Send (1)' transform='translate(12.234) rotate(45)'>
      <path
        id='Path_6915'
        data-name='Path 6915'
        d='M16.918.5A1.688,1.688,0,0,0,15.237.068L1.226,4.117a1.667,1.667,0,0,0-1.2,1.316,1.946,1.946,0,0,0,.873,1.82L5.275,9.928a1.139,1.139,0,0,0,1.4-.166l5.017-5.017a.64.64,0,0,1,.923,0,.657.657,0,0,1,0,.923L7.591,10.684a1.141,1.141,0,0,0-.168,1.4l2.677,4.4a1.743,1.743,0,0,0,1.663.809,1.693,1.693,0,0,0,1.419-1.2L17.336,2.184A1.692,1.692,0,0,0,16.918.5Z'
        fill={props.color ? props.color : '#5b66ea'}
      />
      <path
        id='Path_6916'
        data-name='Path 6916'
        d='M6.262,4.528a.655.655,0,0,1,0,.924L5.072,6.64a.653.653,0,0,1-.923-.923l1.189-1.19A.655.655,0,0,1,6.262,4.528Zm-.682-3.3a.655.655,0,0,1,0,.924L4.39,3.342a.653.653,0,0,1-.923-.923l1.189-1.19A.655.655,0,0,1,5.579,1.229ZM2.3.191a.655.655,0,0,1,0,.924L1.114,2.3A.653.653,0,1,1,.191,1.38L1.38.191A.655.655,0,0,1,2.3.191Z'
        transform='translate(0.227 10.4)'
        fill={props.color ? props.color : '#5b66ea'}
        opacity='0.4'
      />
    </g>
  </svg>
)

const SVGSwipeIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGSwipeIcon
