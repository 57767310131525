import * as React from 'react'
import { MainColor } from '../../utils'

function SVGWidgetPositionLeft(props) {
  return (
    <svg
      width='28'
      height='28'
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect x='0.5' y='0.5' width='7' height='7' rx='1.5' stroke='#E5E9F2' />
      <rect x='10.5' y='0.5' width='7' height='7' rx='1.5' stroke='#E5E9F2' />
      <rect x='20.5' y='0.5' width='7' height='7' rx='1.5' stroke='#E5E9F2' />
      <rect x='0.5' y='10.5' width='7' height='7' rx='1.5' stroke='#E5E9F2' />
      <rect x='10.5' y='10.5' width='7' height='7' rx='1.5' stroke='#E5E9F2' />
      <rect x='20.5' y='10.5' width='7' height='7' rx='1.5' stroke='#E5E9F2' />
      <rect y='20' width='8' height='8' rx='2' fill={props.color || MainColor} />
      <rect x='10.5' y='20.5' width='7' height='7' rx='1.5' stroke='#E5E9F2' />
      <rect x='20.5' y='20.5' width='7' height='7' rx='1.5' stroke='#E5E9F2' />
    </svg>
  )
}

export default SVGWidgetPositionLeft
