import React, { useEffect, useMemo } from 'react'
import { CONNECT_URL } from '../../../../config'

const ThemePreview = ({ locationId, settings, language }) => {
  const { variant } = settings
  useEffect(() => {
    sendPreviewSettings()
  }, [settings])

  const iframeHeight = useMemo(() => {
    switch (variant) {
      case 'small':
        return '40px'
      case 'medium':
        return '50px'
      case 'large':
        return '80px'
      default:
        return '80px'
    }
  }, [variant])

  const sendPreviewSettings = () => {
    const iframe = document.getElementById(`iframe-preview-mobile-cta`)
    if (!iframe) {
      return
    }
    iframe.contentWindow.postMessage(getData(), '*')
  }

  const getData = () => {
    return settings
  }

  const onIframeLoad = () => {
    sendPreviewSettings()
  }

  return (
    <div className='matador-connect-preview-default'>
      <iframe
        title='Matador CTA Preview'
        id={`iframe-preview-mobile-cta`}
        height={iframeHeight}
        width='100%'
        frameBorder='none'
        onLoad={onIframeLoad}
        src={`${CONNECT_URL}/mobile-cta/${locationId}/${language}`}
        allow='geolocation'
      ></iframe>
    </div>
  )
}

export default ThemePreview
