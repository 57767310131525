import React, { useEffect, useState } from 'react'
import { Form } from 'antd'
import ProfileLetters from '../../../../../components/ProfileLetters'
import SVGPaymentIcon from '../../../../../icons/SVG/SVGPaymentIcon'
import { getText, getTextServerError } from '../../../../../lang'
import paymentsActions from '../../../../../store/modules/paymentsAction'
import { notifyError, notifySuccess } from '../../../../../utils/Notify'
import MatForm from '../../../../../components/Form/MatForm'
import InputFormNumber from '../../../../../components/Form/InputFormNumber'
import InputFormText from '../../../../../components/Form/InputFormText'
import MatModal from '../../../../../components/MatModal'

const SendRequestPaymentModal = (props) => {
  const { trigger, customer, conversation, disabled } = props

  const [form] = Form.useForm()

  const [visible, setVisible] = useState(false)
  const [saving, setSaving] = useState(false)

  const [fullName, setFullName] = useState('')

  useEffect(() => {
    if (customer) {
      const fullName =
        customer.firstName || customer.lastName
          ? `${customer.firstName} ${customer.lastName}`
          : customer.phone
      setFullName(fullName)
    }
  }, [customer])

  const onFinish = async (values) => {
    if (conversation.kind === 'messenger') {
      values.messengerId = conversation.receiver.messengerId
    } else {
      if (!values.email && customer.email) {
        values.email = customer.email
      }
      values.phone = customer.phone.replace('+', '')
    }
    if (values.email === undefined) {
      delete values.email
    }
    setSaving(true)
    const result = await paymentsActions.createPayment(values, conversation)
    setSaving(false)
    if (result.success) {
      notifySuccess(getText('MSG_PAYMENT_SENT_SUCCESSFULLY'))
      setVisible(false)
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
  }

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields.length > 0) {
      notifyError(errorInfo.errorFields[0].errors[0])
    }
  }

  return (
    <MatModal
      id={'msg_payment'}
      title='Payment'
      trigger={trigger}
      confirmLoading={saving}
      formName='sendPaymentForm'
      visible={visible}
      onTrrigerClick={() => {
        if (!disabled) {
          setVisible(true)
        }
        form.resetFields()
      }}
      onCancel={() => {
        setVisible(false)
      }}
      okText={getText('ACTION_SEND')}
    >
      <div className='field'>
        <div className='platform-form contact-profile-letters-border'>
          <ProfileLetters
            withoutIcon
            className='contact-profile-letters'
            name={fullName}
            color={'#ffffff'}
            medium
          />
          <label className='platform-name'>{fullName}</label>
          <div className='payment-icon'>
            <SVGPaymentIcon />
          </div>
        </div>
      </div>
      <MatForm
        form={form}
        name='sendPaymentForm'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{}}
      >
        <InputFormNumber name='amount' label={getText('WORD_AMOUNT')} required />
        <InputFormText
          name='referenceNumber'
          label={getText('WORD_REFERENCE_NUMBER')}
          required
        />
        <InputFormText name='email' label={getText('WORD_EMAIL')} />
      </MatForm>
    </MatModal>
  )
}

export default SendRequestPaymentModal
