import React from 'react'
import { toast } from 'react-toastify'

const config = {
  closeButton: true,
  closeOnClick: true,
  // pauseOnHover: true,
  // draggable: true,
  position: 'top-right',
  autoClose: 5000,
  theme: 'colored',
}

export const notifySuccess = (text) =>
  toast.success(<div className='notify-div-icon-text'>{text}</div>, config)

export const notifyError = (text) =>
  toast.error(<div className='notify-div-icon-text'>{text}</div>, config)

export const notifywarning = (text) =>
  toast.warning(<div className='notify-div-icon-text'>{text}</div>, config)

export const notifyText = (text) =>
  toast(<div className='notify-div-icon-text'>{text}</div>, config)
