import React from "react";
import Icon from "@ant-design/icons";

const SVGIcon = (props) => {
  return (
    <svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="21" cy="21" r="21" fill="#48AF80" />
      <path
        d="M15.0238 12.7161C14.4697 12.4386 13.8255 12.8519 13.8255 13.4711V26.8044C13.3655 26.8044 12.9922 27.1777 12.9922 27.6377C12.9922 28.0977 13.3655 28.4711 13.8255 28.4711H27.1589C27.6189 28.4711 27.9922 28.0977 27.9922 27.6377C27.9922 27.1777 27.6189 26.8044 27.1589 26.8044V14.3044C27.1589 13.9886 26.9722 13.6902 26.6905 13.5494L25.0238 12.7161C24.7888 12.5986 24.5289 12.5986 24.2939 12.7161L22.9922 13.3669L21.6905 12.7161C21.4555 12.5986 21.1955 12.5986 20.9605 12.7161L19.6589 13.3669L18.3572 12.7161C18.1222 12.5986 17.8622 12.5986 17.6272 12.7161C17.4647 12.7969 16.7664 13.1461 16.3255 13.3669L15.0238 12.7161ZM17.1589 16.8044H20.4922C20.9522 16.8044 21.3255 17.1777 21.3255 17.6377C21.3255 18.0977 20.9522 18.4711 20.4922 18.4711H17.1589C16.6989 18.4711 16.3255 18.0977 16.3255 17.6377C16.3255 17.1777 16.6989 16.8044 17.1589 16.8044ZM23.8255 16.8044C24.2855 16.8044 24.6589 17.1777 24.6589 17.6377C24.6589 18.0977 24.2855 18.4711 23.8255 18.4711C23.3655 18.4711 22.9922 18.0977 22.9922 17.6377C22.9922 17.1777 23.3655 16.8044 23.8255 16.8044ZM17.1589 20.1377H20.4922C20.9522 20.1377 21.3255 20.5111 21.3255 20.9711C21.3255 21.4311 20.9522 21.8044 20.4922 21.8044H17.1589C16.6989 21.8044 16.3255 21.4311 16.3255 20.9711C16.3255 20.5111 16.6989 20.1377 17.1589 20.1377ZM23.8255 20.1377C24.2855 20.1377 24.6589 20.5111 24.6589 20.9711C24.6589 21.4311 24.2855 21.8044 23.8255 21.8044C23.3655 21.8044 22.9922 21.4311 22.9922 20.9711C22.9922 20.5111 23.3655 20.1377 23.8255 20.1377ZM17.1589 23.4711H20.4922C20.9522 23.4711 21.3255 23.8444 21.3255 24.3044C21.3255 24.7644 20.9522 25.1377 20.4922 25.1377H17.1589C16.6989 25.1377 16.3255 24.7644 16.3255 24.3044C16.3255 23.8444 16.6989 23.4711 17.1589 23.4711ZM23.8255 23.4711C24.2855 23.4711 24.6589 23.8444 24.6589 24.3044C24.6589 24.7644 24.2855 25.1377 23.8255 25.1377C23.3655 25.1377 22.9922 24.7644 22.9922 24.3044C22.9922 23.8444 23.3655 23.4711 23.8255 23.4711Z"
        fill="white"
      />
    </svg>
  );
};

const SVGPaymentSalesTodayIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />;
      }}
      {...props}
    />
  );
};

export default SVGPaymentSalesTodayIcon;
