import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={16.515} height={9.873} viewBox="0 0 16.515 9.873" {...props}>
      <g
        data-name="Group 7201"
        fill="none"
        stroke="#00cb85"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.2}
      >
        <path
          data-name="Path 5809"
          d="M.846 9.026l5.023-5.725 3.271 4.44L15.568.847"
        />
        <path data-name="Path 5810" d="M11.835.848h4.08v4.347" />
      </g>
    </svg>
  )
}

export default SvgComponent
