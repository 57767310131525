import {
  fetchFromUrlPOSTAsync,
  fetchFromUrlPUTAsync,
  fetchFromUrlDELETEAsync,
  fetchFromUrlGETAsync,
} from '../../utils/UrlHelper'

const urlTags = '/tags'

const fs = {
  // getPageList: async (start, range, searchKeyword = "", paginationOptions = {}) => {
  //     const urlParams = [
  //         { key: "page", value: start + 1 },
  //         { key: "perPage", value: range },
  //     ];
  //     if (paginationOptions._location_id) {
  //         urlParams.push({ key: "_location_id", value: paginationOptions._location_id });
  //     }
  //     if (paginationOptions.language) {
  //         urlParams.push({ key: "language", value: paginationOptions.language });
  //     }
  //     const result = await fetchFromUrlGETAsync(urlTags, urlParams);
  //     if (result.success) {
  //         result.max = result.data.total_items;
  //         result.data = result.data.questions;
  //     }
  //     return result;
  // },

  // getSingle: async (itemId) => {
  //     const result = await fetchFromUrlGETAsync(urlTags + "/" + itemId, []);
  //     return result;
  // },

  saveNew: async (obj) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    const result = await fetchFromUrlPOSTAsync(urlTags, urlParams)
    return result
  },

  saveUpdate: async (obj, _id) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    const result = await fetchFromUrlPUTAsync(urlTags + '/' + _id, urlParams)
    return result
  },

  delete: async (_id) => {
    const result = await fetchFromUrlDELETEAsync(urlTags + '/' + _id, [])
    return result
  },
  getTagsList: async (start, range, searchKeyword = '') => {
    const urlParams = []

    urlParams.push({ key: 'page', value: Math.ceil(start + 1) })
    urlParams.push({ key: 'perPage', value: range })
    if (searchKeyword) {
      urlParams.push({ key: 'search', value: searchKeyword })
    }
    const result = await fetchFromUrlGETAsync(urlTags, urlParams)

    if (result.success) {
      result.max = result.data.count
      result.data = result.data.data
    }
    return result
  },
}

const loc = {}

const tagsActions = Object.assign(fs, loc)

export default tagsActions
