import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width='17'
    height='4'
    viewBox='0 0 17 4'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.22321 2C4.22321 3.1046 3.33203 4 2.2327 4C1.13337 4 0.242188 3.1046 0.242188 2C0.242188 0.8954 1.13337 0 2.2327 0C3.33203 0 4.22321 0.8954 4.22321 2ZM10.1948 2C10.1948 3.1046 9.3036 4 8.20424 4C7.10488 4 6.21373 3.1046 6.21373 2C6.21373 0.8954 7.10488 0 8.20424 0C9.3036 0 10.1948 0.8954 10.1948 2ZM16.1663 2C16.1663 3.1046 15.2751 4 14.1758 4C13.0764 4 12.1853 3.1046 12.1853 2C12.1853 0.8954 13.0764 0 14.1758 0C15.2751 0 16.1663 0.8954 16.1663 2Z'
      fill={props.color || '#77808D'}
    />
  </svg>
)

const SVGThreePoint = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGThreePoint
