import React, { useEffect } from 'react'
import authActions from '../../../store/modules/authActions'
import { getText } from '../../../lang'
import MatRadioButtonSecondary from '../../../components/MatRadioButton/MatRadioButtonSecondary'
import SelectFromOrganizations from '../../../components/api/SelectFromOrganizations'
import MatTreeSelectSecondary from '../../../components/MatTreeSelect/MatTreeSelectSecondary'

const RoleAccessSection = (props) => {
  const {
    dispatch,
    user,
    handleAccessibleOrganizationsChange,
    userState,
    getSelectedRole,
    getRolesOptions,
    treeData,
    loadingData,
  } = props

  const selectedOrganization = userState.accessibleOrganizations.length
    ? userState.accessibleOrganizations.find(
        (item) => item._id === user.organization._id
      )
    : treeData.length && treeData.find((item) => item._id === user.organization._id)

  useEffect(() => {
    if (selectedOrganization === undefined) {
      dispatch({
        type: 'CHANGE',
        name: '_location_id',
        payload: '',
      })
    }
  }, [selectedOrganization])

  return (
    <>
      <div className='users-page-modal-checkbox-items'>
        <MatRadioButtonSecondary
          radioOptions={getRolesOptions()}
          value={getSelectedRole()}
          onChange={(e) => {
            dispatch({ type: 'SET_ROLE', payload: e.target.value })
          }}
        />
      </div>
      {user.isAdmin || authActions.hasAccessToMultipleOrganizations() ? (
        (user && !user.isSuperAdmin) || !userState.isSuperAdmin ? (
          <SelectFromOrganizations
            secondarySelect
            name='accessibleOrganizations'
            label={getText('WORD_ORGANIZATIONS')}
            placeholder={getText('WORD_SELECT_ORGANIZATIONS')}
            multipleNewStyle
            mode={'multiple'}
            dropdownStyle={{ zIndex: 10000 }}
            onChangeItem={handleAccessibleOrganizationsChange}
            allowClear={false}
          />
        ) : null
      ) : null}
      <MatTreeSelectSecondary
        label={getText('WORD_HOME_LOCATION')}
        loading={loadingData}
        style={{ marginBottom: 25 }}
        value={userState._location_id || getText('WORD_SELECT_LOCATION')}
        placeholder={getText('WORD_SELECT_LOCATION')}
        treeData={treeData}
        treeDefaultExpandAll={true}
        onChange={(item) => {
          dispatch({
            type: 'CHANGE',
            name: '_location_id',
            payload: item,
          })
        }}
      />
    </>
  )
}

export default RoleAccessSection
