import React, { useEffect, useState } from 'react'
import { Image } from 'antd'
import { getText, getTextServerError } from '../../../../../lang'
import SVGDelete from '../../../../../icons/SVG/SVGDelete'
import ConfirmPopup from '../../../../../components/ConfirmPopup'
import SVGEdit from '../../../../../icons/SVG/SVGEdit'
import MatRow from '../../../../../components/MatRow'
import MatSwitch from '../../../../../components/MatSwitch'
import { notifyError, notifySuccess } from '../../../../../utils/Notify'
import { replaceWords } from '../../../../../utils'

const AnnouncementItem = (props) => {
  const { item, onEdit, refreshList, actions, type } = props
  const [status, setStatus] = useState(item.status === 'ACTIVE')

  useEffect(() => {
    setStatus(item.status === 'ACTIVE')
  }, [])

  const handleDelete = async () => {
    const result = await actions.delete(item.id)
    if (result.success) {
      const succesMsg =
        type === 'Special'
          ? getText('SUCCESS_SPECIAL_DELETE')
          : getText('SUCCESS_ANNOUNCEMENT_DELETE')
      notifySuccess(succesMsg)
      refreshList()
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
  }

  const handleSwitchEnabled = async (newValue) => {
    const { id, organizationId, createdAt, status, videoUrl, url, ...newItem } = item
    newItem.status = newValue ? 'ACTIVE' : 'DEACTIVATED'
    const result = await actions.update(newItem, id)
    if (result.success) {
      setStatus((status) => !status)
      const succesMsg =
        type === 'Special'
          ? getText('SUCCESS_SPECIAL_UPDATE')
          : getText('SUCCESS_ANNOUNCEMENT_UPDATE')
      notifySuccess(succesMsg)
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
  }

  return (
    <MatRow className='livechat-announcement-item-wrapper'>
      <MatRow>
        <Image
          className='announcement-img'
          src={item.imageUrl}
          alt='announcement-car'
          preview={{
            mask: 'Preview',
          }}
        />
        <div className='announcement-title'>
          <p>{item.title.en}</p>
          <p>{item.description.en}</p>
        </div>
      </MatRow>
      <MatRow className='announcement-item-actions'>
        <MatSwitch value={status} onChange={handleSwitchEnabled} />
        <div className='btn-block-item' onClick={() => onEdit(item)}>
          <SVGEdit color={'#747b95'} width={13} height={13} />
        </div>
        <ConfirmPopup
          title={replaceWords(
            getText('CONFIRMATION_DELETE_VARIABLE', {
              variableToDelete: type,
            })
          )}
          onConfirm={handleDelete}
          cancelText={getText('ACTION_CANCEL')}
          okText={getText('ACTION_DELETE')}
          trigger={
            <div className='btn-block-item'>
              <SVGDelete />
            </div>
          }
        />
      </MatRow>
    </MatRow>
  )
}

export default AnnouncementItem
