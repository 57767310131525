import React from 'react'
import { getText } from '../../../lang'

const LoginPageHeader = (props) => {
  const { viewChange, customTitle, customText, noCustomText } = props

  return (
    <div className='login-page-text-wrapper'>
      <div className='login-page-title'>
        {customTitle ||
          getText(viewChange ? 'TITLE_LOGIN_MAGIC' : 'TITLE_LOGIN_PASSWORD')}
      </div>
      <div className='login-page-describe'>
        {noCustomText
          ? ''
          : customText ||
            getText(
              viewChange ? 'TEXT_LOGIN_MAGIC_HELP' : 'TEXT_LOGIN_PASSWORD_HELP'
            )}
      </div>
    </div>
  )
}

export default LoginPageHeader
