import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width='27'
    height='21'
    viewBox='0 0 27 21'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M26.9999 5.41614L20.5643 10.1516V20.4834H25.0808C25.5743 20.4881 26.0502 20.3009 26.4083 19.961C26.7664 19.6212 26.9785 19.1555 26.9999 18.6621V5.41614Z'
      fill='#4CAF50'
    />
    <path
      d='M0 5.41614L6.41909 10.1847V20.4999H1.91911C1.42292 20.5046 0.944565 20.315 0.586052 19.9717C0.227538 19.6283 0.017238 19.1584 0 18.6621V5.41614Z'
      fill='#1E88E5'
    />
    <path
      d='M20.5643 2.27023L13.5 7.48582L6.4357 2.27023V10.1681L13.5 15.3837L20.5643 10.1681V2.27023Z'
      fill='#E53935'
    />
    <path
      d='M0 2.96562V5.41612L6.41909 10.1847V2.27021L4.41726 0.796596C3.9391 0.440668 3.35877 0.249005 2.76286 0.250201C2.40299 0.245829 2.04581 0.312717 1.71188 0.447017C1.37794 0.581318 1.07383 0.780379 0.817054 1.03275C0.560277 1.28512 0.355897 1.58581 0.215675 1.91753C0.0754521 2.24925 0.00215464 2.60544 0 2.96562Z'
      fill='#C62828'
    />
    <path
      d='M26.9999 2.96562V5.41612L20.5643 10.1515V2.27021L22.5827 0.796596C23.0608 0.440668 23.6412 0.249005 24.2371 0.250201C24.5969 0.245829 24.9541 0.312717 25.2881 0.447017C25.622 0.581318 25.9261 0.780379 26.1829 1.03275C26.4397 1.28512 26.644 1.58581 26.7843 1.91753C26.9245 2.24925 26.9978 2.60544 26.9999 2.96562Z'
      fill='#FBC02D'
    />
  </svg>
)

const SVGIntegrationGmail = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGIntegrationGmail
