import React from "react";
import Icon from "@ant-design/icons";

const SVGIcon = (props) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99919 0.342773C3.31732 0.342773 0.33252 3.32745 0.33252 7.00946C0.33252 10.6915 3.31732 13.6761 6.99919 13.6761C10.6811 13.6761 13.6659 10.6915 13.6659 7.00946C13.6659 3.32745 10.6811 0.342773 6.99919 0.342773ZM6.99919 3.67611C7.36739 3.67611 7.66585 3.97478 7.66585 4.34278C7.66585 4.71078 7.36739 5.00945 6.99919 5.00945C6.63099 5.00945 6.33252 4.71078 6.33252 4.34278C6.33252 3.97478 6.63099 3.67611 6.99919 3.67611ZM6.99919 5.67612C7.36739 5.67612 7.66585 5.97479 7.66585 6.34279V9.67613C7.66585 10.0441 7.36739 10.3428 6.99919 10.3428C6.63099 10.3428 6.33252 10.0441 6.33252 9.67613V6.34279C6.33252 5.97479 6.63099 5.67612 6.99919 5.67612Z"
        fill="#7854F6"
      />
    </svg>
  );
};

const SVGPaymentRefundedIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />;
      }}
      {...props}
    />
  );
};

export default SVGPaymentRefundedIcon;
