import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = ({ width, height, fill, circlefill }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width || 30}
      height={height || 30}
      viewBox='0 0 30 30'
      fill='none'
    >
      <circle
        cx='15'
        cy='15'
        r='15'
        fill={circlefill || '#F5325B'}
        fillOpacity='0.1'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.7463 9.02244L21.8998 17.9665C22.0132 18.2335 22.0629 18.4507 22.0771 18.6761C22.1054 19.203 21.9211 19.7152 21.5596 20.1093C21.198 20.502 20.7089 20.7339 20.1772 20.762H9.79925C9.57949 20.7487 9.35974 20.6987 9.15416 20.6215C8.12629 20.207 7.63007 19.0407 8.04831 18.0298L13.2373 9.01612C13.4145 8.69927 13.6839 8.42598 14.0171 8.25034C14.9812 7.71571 16.2075 8.06698 16.7463 9.02244ZM15.605 14.9168C15.605 15.254 15.3285 15.5357 14.9882 15.5357C14.648 15.5357 14.3644 15.254 14.3644 14.9168V12.9293C14.3644 12.5914 14.648 12.3181 14.9882 12.3181C15.3285 12.3181 15.605 12.5914 15.605 12.9293V14.9168ZM14.9882 17.9385C14.648 17.9385 14.3644 17.6568 14.3644 17.3202C14.3644 16.9823 14.648 16.7013 14.9882 16.7013C15.3285 16.7013 15.605 16.976 15.605 17.3125C15.605 17.6568 15.3285 17.9385 14.9882 17.9385Z'
        fill={fill || '#130F26'}
      />
    </svg>
  )
}

const SVGAlert = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGAlert
