import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_6891_2860)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.19503 3.03797C8.32162 -1.01266 14.3976 -1.01266 14.5241 3.03797H19.0811V8.6076H17.4355C15.5368 8.6076 15.5368 11.3924 17.4355 11.3924H19.0811V16.962H14.5241C14.3976 21.0127 8.32162 21.0127 8.19503 16.962H3.63807V13.1646C-0.412561 13.038 -0.412561 6.96203 3.63807 6.83544V3.03797H8.19503ZM5.41022 4.81013V8.6076H3.76465C1.86592 8.6076 1.86592 11.3924 3.76465 11.3924H5.41022V15.1899H9.96719V16.8354C9.96719 18.7342 12.752 18.7342 12.752 16.8354V15.1899H17.309V13.1646C13.2583 13.038 13.2583 6.96203 17.309 6.83544V4.81013H12.752V3.16456C12.752 1.26582 9.96719 1.26582 9.96719 3.16456V4.81013H5.41022Z'
        fill='black'
      />
    </g>
    <defs>
      <clipPath id='clip0_6891_2860'>
        <rect width='20' height='20' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

const SVGExtensionIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGExtensionIcon
