import React from 'react'
import PropTypes from 'prop-types'
import { Chart } from 'react-google-charts'
import { getText } from '../../../../../lang'

const PieChart = ({ npsScore, screenSize }) => {
  const bigNumber = npsScore.promotersPercent - npsScore.detractorsPercent
  const isScore = Boolean(
    npsScore.promoters !== 0 || npsScore.passives !== 0 || npsScore.detractors !== 0
  )
  function getSizeForChart(size) {
    switch (size) {
      case 'mobile':
        return { width: '70%', height: '70%' }

      case 'tabletPortrait':
        return { width: '70%', height: '70%' }

      default:
        return { width: '80%', height: '80%' }
    }
  }

  return (
    <div className={`progress-wrapper ${isScore ? '' : 'hide-page-wrapper'}`}>
      <div className={'chart-total-numbers'}>
        <div className={'total-number'}>
          <div
            className='big-number'
            style={{ fontSize: bigNumber === -100 ? 29 : 40 }}
          >
            {bigNumber || 0}
          </div>
          <div className='total'>
            {npsScore.total}
            <span>{getText('WORD_RESP')}</span>
          </div>
        </div>
        {isScore ? (
          <Chart
            width='200px'
            chartType='PieChart'
            className='pie-chart'
            data={[
              ['label', 'value'],
              [getText('WORD_PROMOTERS'), npsScore.promoters],
              [getText('WORD_PASSIVE'), npsScore.passives],
              [getText('WORD_DETRACTORS'), npsScore.detractors],
            ]}
            options={{
              pieHole: 0.5,
              is3D: false,
              legend: 'none',
              sliceVisibilityThreshold: 0,
              colors: ['#7854F6', '#ffc107', '#f12b2c'],
              pieSliceText: 'none',
              chartArea: getSizeForChart(screenSize),
              tooltip: {
                isHtml: true,
                trigger: 'visible',
                textStyle: { fontName: 'Averta CY', fontSize: 12 },
                borderStyle: { backgroundColor: 'red' },
              },
              backgroundColor: {
                fill: 'transparent',
                opacity: 100,
              },
            }}
          />
        ) : (
          <div className='circle-hide'>
            <div className='circle-center-hide' />
          </div>
        )}
      </div>
    </div>
  )
}

PieChart.defaultProps = {
  npsScore: {},
}

PieChart.propTypes = {
  npsScore: PropTypes.instanceOf(Object),
  screenSize: PropTypes.string,
}
export default PieChart
