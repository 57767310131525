import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='80'
      height='80'
      viewBox='0 0 80 80'
      fill='none'
    >
      <circle cx='40' cy='40' r='40' fill='white' />
      <circle cx='40' cy='40' r='39.5' stroke='#F8BA05' strokeOpacity='0.3' />
      <path
        opacity='0.2'
        d='M40 8C44.2023 8 48.3635 8.8277 52.2459 10.4359C56.1283 12.044 59.6559 14.4011 62.6274 17.3726C65.5989 20.3441 67.956 23.8717 69.5641 27.7541C71.1723 31.6366 72 35.7977 72 40C72 44.2023 71.1723 48.3635 69.5641 52.2459C67.956 56.1283 65.5989 59.6559 62.6274 62.6274C59.6559 65.5989 56.1283 67.956 52.2459 69.5641C48.3634 71.1723 44.2023 72 40 72C35.7977 72 31.6365 71.1723 27.7541 69.5641C23.8717 67.956 20.344 65.5989 17.3726 62.6274C14.4011 59.6559 12.044 56.1283 10.4358 52.2459C8.8277 48.3634 8 44.2023 8 40C8 35.7977 8.82771 31.6365 10.4359 27.7541C12.044 23.8717 14.4011 20.344 17.3726 17.3726C20.3441 14.4011 23.8717 12.044 27.7541 10.4358C31.6366 8.8277 35.7977 8 40 8L40 8Z'
        stroke='#F8BA05'
        strokeWidth='10'
      />
    </svg>
  )
}

const SVGEngageRate = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGEngageRate
