import React, { useEffect } from 'react'
import { Form } from 'antd'
import authActions from '../../store/modules/authActions'
import { getText, getTextServerError } from '../../lang'
import InputFormPassword from '../../components/Form/InputFormPassword'
import InputFormPasswordConfirm from '../../components/Form/InputFormPasswordConfirm'
import MatForm from '../../components/Form/MatForm'
import PageFix from '../../components/PageFix'
import { notifyError, notifySuccess } from '../../utils/Notify'

const ProfileChangePassword = (props) => {
  const [form] = Form.useForm()

  useEffect(() => {
    props.setPageTitle && props.setPageTitle(getText('TEXT_PROFILE_CHANGE_PASSWORD'))
    props.setFooterButtons && props.setFooterButtons(['save'])
  }, [])

  const onFinish = async (values) => {
    const result = await authActions.setPassword(
      values.newPassword,
      values.confirmPassword
    )
    if (result.success) {
      if (result.data.token) {
        authActions.storeAuthData(
          result.data.user,
          result.data.token,
          authActions.getUnreadMessages(),
          authActions.getMyMessagesInboxUnreadMessages()
        )
      }
      notifySuccess(getText('NTF_SUCCESS_SAVE_PASSWORD'))
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
  }

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields.length > 0) {
      notifyError(errorInfo.errorFields[0].errors[0])
    }
  }

  return (
    <PageFix title={getText('TEXT_PROFILE_CHANGE_PASSWORD')}>
      <MatForm
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{
          newPassword: '',
          confirmPassword: '',
        }}
      >
        <InputFormPassword
          name='newPassword'
          label={getText('WORD_NEW_PASSWORD')}
          message={`${getText('MSG_PLEASE_INPUT_PASSWORD')}!`}
          autoComplete='new-password'
          required
        />
        <InputFormPasswordConfirm
          name='confirmPassword'
          label={getText('WORD_CONFIRM_PASSWORD')}
          message={`${getText('MSG_PLEASE_CONFIRM_YOUR_PASSWORD')}!`}
          passwordToConfirm='newPassword'
          required
        />
      </MatForm>
    </PageFix>
  )
}

export default ProfileChangePassword
