import * as React from "react"

function SVGBot(props) {
    return (
        <svg width={12.01} height={10.214}>
            <g data-name="Group 7058">
                <path
                    data-name="Path 5804"
                    d="M11.109 6.009h-.3v-.6a1.8 1.8 0 00-1.8-1.8h-2.4V2.241a1.2 1.2 0 10-1.2 0v1.368H3a1.8 1.8 0 00-1.8 1.8v.6H.9a.9.9 0 100 1.8h.3v.6a1.8 1.8 0 001.8 1.8h6a1.8 1.8 0 001.8-1.8v-.6h.3a.9.9 0 100-1.8zm-.9 2.4a1.2 1.2 0 01-1.2 1.2H3a1.2 1.2 0 01-1.2-1.2v-3a1.2 1.2 0 011.2-1.2h6a1.2 1.2 0 011.2 1.2zM4.8 6.909a.9.9 0 11-.9-.9.9.9 0 01.9.9zm4.2 0a.9.9 0 11-.9-.9.9.9 0 01.907.9z"
                    fill={props.color ? props.color : "#a3a6be"}
                />
            </g>
        </svg>
    )
}

export default SVGBot;

