import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width='30'
    height='30'
    viewBox='0 0 30 30'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='15' cy='15' r='15' fill='#EBE5FF' />
    <g clipPath='url(#clip0_16797_62567)'>
      <path
        d='M22.6246 12.5H20.7536L20.2336 11.2C19.6993 9.86344 18.424 9 16.9843 9H13.0155C11.5761 9 10.3005 9.86344 9.76582 11.2L9.24582 12.5H7.3752C7.13114 12.5 6.95207 12.7294 7.01145 12.9659L7.19895 13.7159C7.24051 13.8828 7.39051 14 7.5627 14H8.18989C7.7702 14.3666 7.49989 14.8994 7.49989 15.5V17C7.49989 17.5037 7.69239 17.9584 7.99989 18.3103V20C7.99989 20.5522 8.4477 21 8.99989 21H9.99989C10.5521 21 10.9999 20.5522 10.9999 20V19H18.9999V20C18.9999 20.5522 19.4477 21 19.9999 21H20.9999C21.5521 21 21.9999 20.5522 21.9999 20V18.3103C22.3074 17.9587 22.4999 17.5041 22.4999 17V15.5C22.4999 14.8994 22.2296 14.3666 21.8102 14H22.4374C22.6096 14 22.7596 13.8828 22.8011 13.7159L22.9886 12.9659C23.0477 12.7294 22.8686 12.5 22.6246 12.5ZM11.6227 11.9428C11.8505 11.3734 12.4021 11 13.0155 11H16.9843C17.5977 11 18.1493 11.3734 18.3771 11.9428L18.9999 13.5H10.9999L11.6227 11.9428ZM9.99989 16.9937C9.39989 16.9937 8.99989 16.595 8.99989 15.9969C8.99989 15.3987 9.39989 15 9.99989 15C10.5999 15 11.4999 15.8972 11.4999 16.4953C11.4999 17.0934 10.5999 16.9937 9.99989 16.9937ZM19.9999 16.9937C19.3999 16.9937 18.4999 17.0934 18.4999 16.4953C18.4999 15.8972 19.3999 15 19.9999 15C20.5999 15 20.9999 15.3987 20.9999 15.9969C20.9999 16.595 20.5999 16.9937 19.9999 16.9937Z'
        fill='#7854F6'
      />
    </g>
    <defs>
      <clipPath id='clip0_16797_62567'>
        <rect width='16' height='16' fill='white' transform='translate(7 7)' />
      </clipPath>
    </defs>
  </svg>
)

const SVGVehicleInterestIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGVehicleInterestIcon
