import React from "react"

function SVGTouchpoints(props) {
  return (
    <svg width={13} height={13} viewBox="0 0 13 13" {...props}>
      <g data-name="Group 8119">
        <g data-name="Group 8118">
          <path
            data-name="Path 5882"
            d="M6.5 13a.408.408 0 01-.408-.408V8.669a.408.408 0 01.408-.408 1.761 1.761 0 10-1.192-.465.408.408 0 01-.553.6 2.577 2.577 0 112.153.648v3.125a5.681 5.681 0 10-1.731-.141.408.408 0 01-.189.793 6.5 6.5 0 111.512.177z"
            fill={props.color ? props.color : "#747b95"}
          />
        </g>
      </g>
    </svg>
  )
}

export default SVGTouchpoints
