import React from 'react'

const BroadcastListDesktopItem = ({ title, rowName, icon }) => {
  return (
    <div className='broadcast-message-row-wrapper-with-margin'>
      <div className='broadcast-message-row'>
        <p className='broadcast-more-info-row-title'>{title}</p>
      </div>
      <div className='broadcast-more-info-message-text'>
        {icon}
        {rowName}
      </div>
    </div>
  )
}

export default BroadcastListDesktopItem
