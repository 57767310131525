import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.0006 12.9892C9.04812 12.9892 7.45312 11.4033 7.45312 9.44167C7.45312 7.48 9.04812 5.90334 11.0006 5.90334C12.9531 5.90334 14.5481 7.48917 14.5481 9.45084C14.5481 11.4125 12.9531 12.9892 11.0006 12.9892ZM11.0006 7.27834C9.80896 7.27834 8.82812 8.25 8.82812 9.45084C8.82812 10.6517 9.79979 11.6233 11.0006 11.6233C12.2015 11.6233 13.1731 10.6517 13.1731 9.45084C13.1731 8.25 12.1923 7.27834 11.0006 7.27834Z'
        fill={props.color || '#292D32'}
      />
      <path
        d='M11.0015 20.8633C9.64484 20.8633 8.279 20.35 7.21567 19.3325C4.5115 16.7292 1.52317 12.5767 2.65067 7.63584C3.66817 3.15334 7.58234 1.14584 11.0015 1.14584C11.0015 1.14584 11.0015 1.14584 11.0107 1.14584C14.4298 1.14584 18.344 3.15334 19.3615 7.645C20.4798 12.5858 17.4915 16.7292 14.7873 19.3325C13.724 20.35 12.3582 20.8633 11.0015 20.8633ZM11.0015 2.52084C8.334 2.52084 4.90567 3.94167 3.99817 7.93834C3.00817 12.2558 5.7215 15.9775 8.17817 18.3333C9.764 19.8642 12.2482 19.8642 13.834 18.3333C16.2815 15.9775 18.9948 12.2558 18.0232 7.93834C17.1065 3.94167 13.669 2.52084 11.0015 2.52084Z'
        fill={props.color || '#292D32'}
      />
    </svg>
  )
}

const SVGLocationEditIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGLocationEditIcon
