import React from 'react'
import data from '@emoji-mart/data/sets/14/facebook.json'
import Picker from '@emoji-mart/react'

export const Emoji = ({ isShow, addEmoji, onClickOutside }) => {
  const onEmojiClick = (emoji) => {
    addEmoji(emoji)
  }
  return (
    <div className={`${isShow ? 'emoji-modal' : 'emoji-modal-hide'}`}>
      <Picker
        theme={'light'}
        data={data}
        onEmojiSelect={onEmojiClick}
        onClickOutside={onClickOutside}
        previewPosition={'none'}
        set={'facebook'}
      />
    </div>
  )
}
