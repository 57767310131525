import React, { memo, useContext, useEffect, useState } from 'react'
import { Form } from 'antd'
import { parsePhoneNumber } from 'libphonenumber-js'
import { useSelector } from 'react-redux'
import SVGDetectedLanguage from '../../../../icons/SVG/SVGDetectedLanguage'
import { getText, getTextServerError } from '../../../../lang'
import customerActions from '../../../../store/modules/customerActions'
import { checkFormatPhoneNumber, getLongName } from '../../../../utils'
import { notifyError, notifySuccess } from '../../../../utils/Notify'
import SelectOrgLanguageForm from '../../../../components/api/SelectOrgLanguageForm'
import MatForm from '../../../../components/Form/MatForm'
import InputFormEmail from '../../../../components/Form/InputFormEmail'
import InputFormText from '../../../../components/Form/InputFormText'
import InputFormPhone from '../../../../components/Form/InputFormPhone'
import MatModal from '../../../../components/MatModal'
import MatRow from '../../../../components/MatRow'
import { MessageContext } from '../../main/MessageMain'

const ContactEditModal = (props) => {
  const { trigger, customer, onSuccess, conversation } = props

  const messagePageContext = useContext(MessageContext)

  const organization = useSelector((state) => {
    return state.authReducer.user.organization
  })

  const isMobileApp = useSelector((state) => {
    return state.authReducer.isMobileApp
  })

  const [form] = Form.useForm()

  const [visible, setVisible] = useState(false)
  const [saving, setSaving] = useState(false)

  const [language_auto_detected, setlanguage_auto_detected] = useState('')

  useEffect(() => {
    if (customer) {
      setlanguage_auto_detected(getLongName(customer.language_auto_detected))
      form.setFieldsValue({
        ...customer,
        phone: checkFormatPhoneNumber(customer.phone, true),
      })
    }
  }, [form, conversation, customer])

  useEffect(() => {
    //This we need for mobile backswipe
    if (isMobileApp) {
      const modalItem = document.querySelector('.customer-info-modal')
      if (!messagePageContext.rightBoxVisible) {
        if (modalItem) {
          modalItem.style.opacity = '0'
        }
        setVisible(false)
      } else {
        if (modalItem) {
          modalItem.style.opacity = '1'
        }
      }
    }
  }, [messagePageContext.rightBoxVisible])

  const onFinish = async (values) => {
    delete values.messengerId
    delete values.phone
    if (conversation && conversation.isNew) {
      conversation.receiver.firstName = values.firstName
      conversation.receiver.lastName = values.lastName
      conversation.receiver.email = values.email
      conversation.receiver.language = values.language
      conversation.receiver.fullName = values.firstName + ' ' + values.lastName
      conversation.isSetDataReciver = true

      onSuccess && (await onSuccess(conversation.receiver))
      setVisible(false)
    } else {
      setSaving(true)
      const result = await customerActions.saveUpdate(
        values,
        conversation._receiver_id
      )
      setSaving(false)
      if (result.success) {
        notifySuccess(getText('NTF_SUCCESS_SAVE_CUSTOMER'))
        onSuccess && (await onSuccess(result.data))
        setVisible(false)
      } else {
        notifyError(getTextServerError(result.errMsg))
      }
    }
  }

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields.length > 0) {
      notifyError(errorInfo.errorFields[0].errors[0])
    }
  }

  return (
    <MatModal
      title={getText('TITLE_EDIT_CUSTOMER')}
      formName='formSaveCustomer'
      rootClassName={'customer-info-modal'}
      trigger={trigger}
      confirmLoading={saving}
      forceRender={false}
      visible={visible}
      onTrrigerClick={() => {
        setVisible(true)
      }}
      onCancel={() => {
        setVisible(false)
      }}
      okText={getText('ACTION_SAVE')}
    >
      <MatForm
        form={form}
        name='formSaveCustomer'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <MatRow>
          <InputFormText
            name='firstName'
            label={getText('WORD_FIRST_NAME')}
            required={false}
          />
          <InputFormText
            name='lastName'
            label={getText('WORD_LAST_NAME')}
            required={false}
          />
        </MatRow>
        <MatRow>
          {conversation.kind === 'messenger' ? (
            <InputFormText
              name='messengerId'
              label={getText('WORD_MESSENGER_ID')}
              required
            />
          ) : (
            <InputFormPhone
              initialCountry={
                organization &&
                organization.twilio_number &&
                parsePhoneNumber(organization.twilio_number).country &&
                parsePhoneNumber(organization.twilio_number).country.toLowerCase()
              }
              name='phone'
              label={getText('WORD_PHONE')}
              onChange={(value) => {
                form.setFieldsValue({ phone: value })
              }}
              disabled={true}
              required
              value={form.getFieldsValue().phone}
            />
          )}
          <InputFormEmail
            name='email'
            label={getText('WORD_EMAIL')}
            required={false}
            placeholder={getText('WORD_EMAIL')}
          />
        </MatRow>
        {conversation.kind === 'messenger' && (
          <InputFormPhone
            initialCountry={
              organization.twilio_number &&
              parsePhoneNumber(organization.twilio_number).country &&
              parsePhoneNumber(organization.twilio_number).country.toLowerCase()
            }
            name='additionalPhone'
            label={getText('WORD_PHONE')}
            onChange={(value) => {
              form.setFieldsValue({ additionalPhone: value })
            }}
            value={form.getFieldsValue().additionalPhone}
          />
        )}
        <SelectOrgLanguageForm
          name='language'
          label={getText('WORD_LANGUAGE')}
          required
        />
        {language_auto_detected && (
          <div className='customer-modal-auto-detect-language-div'>
            <SVGDetectedLanguage />
            {`${getText(
              'WORD_AUTO_DETECTED_LANGUAGE_IS'
            )} ${language_auto_detected}`}
          </div>
        )}
      </MatForm>
    </MatModal>
  )
}

export default memo(ContactEditModal)
