import React from 'react'
import { Timeline } from 'antd'
import { formatDateTime } from '../../utils'

const MatTimeline = ({ options, position }) => {
  return (
    <Timeline
      className='mat-timeline'
      items={options.map((timelineItem) =>
        Object.assign({
          children: (
            <>
              {timelineItem.value} <br />
              {formatDateTime(timelineItem.date)}
            </>
          ),
          dot: (
            <div
              style={{
                width: 20,
                height: 20,
                borderRadius: '50%',
              }}
              className={`mat-timeline-circle ${timelineItem.color || ''}`}
            ></div>
          ),
          date: timelineItem.date,
          color: timelineItem.color || 'gray',
          position: position || 'right',
        })
      )}
    />
  )
}

export default MatTimeline
