import React from "react";
import Icon from "@ant-design/icons";

const SVGIcon = (props) => (
  <svg
    width="4"
    height="15"
    viewBox="0 0 4 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.0026 10.8337C2.9231 10.8337 3.66927 11.5799 3.66927 12.5003C3.66927 13.4208 2.9231 14.167 2.0026 14.167C1.0821 14.167 0.335938 13.4208 0.335938 12.5003C0.335938 11.5799 1.0821 10.8337 2.0026 10.8337ZM2.0026 5.83366C2.9231 5.83366 3.66927 6.57982 3.66927 7.50033C3.66927 8.42083 2.9231 9.16699 2.0026 9.16699C1.0821 9.16699 0.335938 8.42083 0.335938 7.50033C0.335938 6.57982 1.0821 5.83366 2.0026 5.83366ZM2.0026 0.833659C2.9231 0.833659 3.66927 1.57983 3.66927 2.50033C3.66927 3.42083 2.9231 4.16699 2.0026 4.16699C1.0821 4.16699 0.335938 3.42083 0.335938 2.50033C0.335938 1.57983 1.0821 0.833659 2.0026 0.833659Z"
      fill="#A4A5A6"
    />
  </svg>
);

const SVGThreePointVerticalIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />;
      }}
      {...props}
    />
  );
};

export default SVGThreePointVerticalIcon;
