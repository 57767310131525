import React, { useState } from 'react'
import { Tooltip } from 'antd'
import { getText } from '../../../../lang'
import {
  CANCELED,
  FINISHED,
  getDiffMinutesForBroadcast,
} from '../broadcastListUtils'
import SVGEditNew from '../../../../icons/SVG/SVGEditNew'
import BroadcastMessageEditModal from '../../BroadcastMessageEditModal'

const BroadcastListDesktopItemMessage = ({
  status,
  action_time,
  canceled,
  message,
  excelDoc,
  _id,
  setListData,
  media,
}) => {
  const [expandedItems, setExpandedItems] = useState([])

  return (
    <div
      className='broadcast-message-row-wrapper-with-margin'
      style={{ marginBottom: 16 }}
    >
      <div className='broadcast-message-row'>
        <p className='broadcast-more-info-row-title'>
          {getText('WORD_BROADCAST_MESSAGE')}
        </p>
        {status === FINISHED ||
        status === CANCELED ||
        getDiffMinutesForBroadcast(action_time) <= 5 ? (
          <Tooltip
            zIndex={10000}
            title={getText(
              canceled
                ? 'NTF_YOU_CANT_EDIT_CANCELED_CAMPAIGN'
                : 'TEXT_YOU_CAN_EDIT_CAMPAIGN_ONLY_MINUTES_BEFORE_START'
            )}
          >
            <SVGEditNew height={16} width={16} style={{ cursor: 'not-allowed' }} />
          </Tooltip>
        ) : (
          <BroadcastMessageEditModal
            message={message}
            hasExcel={excelDoc}
            itemId={_id}
            trigger={
              <SVGEditNew height={16} width={16} style={{ cursor: 'pointer' }} />
            }
            onClose={(updatedMessage) => {
              setListData((oldList) => {
                const find = oldList.find((item) => item._id === _id)
                if (Boolean(find)) {
                  find.message = updatedMessage
                }
                return [...oldList]
              })
            }}
          />
        )}
      </div>
      <div
        className={
          expandedItems.includes(_id)
            ? 'broadcast-more-info-message-more broadcast-info-message-text'
            : 'broadcast-more-info-message-text broadcast-info-message-text'
        }
      >
        {message}
        {expandedItems.includes(_id) && media && media.length > 0 && (
          <div className='broadcast-modal-item-media-image'>
            {<img src={media[0].mediaUrl} alt={media[0].mediaUrl} />}
          </div>
        )}
      </div>
      {message.length >= 88 ? (
        <div
          className='broadcast-more-info-message-show-more'
          onClick={() => {
            setExpandedItems((prevExpandedItems) => {
              if (prevExpandedItems.includes(_id)) {
                return prevExpandedItems.filter((id) => id !== _id)
              } else {
                return [...prevExpandedItems, _id]
              }
            })
          }}
        >
          {expandedItems.includes(_id)
            ? getText('ACTION_SHOW_LESS')
            : getText('ACTION_SHOW_MORE')}
        </div>
      ) : null}
    </div>
  )
}

export default BroadcastListDesktopItemMessage
