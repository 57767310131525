import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width='21'
    height='21'
    viewBox='0 0 21 21'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M20.4071 0.607462C19.8819 0.0706925 19.1046 -0.127839 18.3798 0.0822472L1.4784 4.9657C0.713683 5.17788 0.171661 5.78398 0.0256517 6.5529C-0.123509 7.33652 0.397504 8.33232 1.07818 8.74829L6.3629 11.9752C6.90492 12.3072 7.6045 12.2242 8.05304 11.7746L14.1046 5.72306C14.4092 5.40688 14.9134 5.40688 15.218 5.72306C15.5226 6.02663 15.5226 6.52138 15.218 6.83651L9.15599 12.888C8.70641 13.3376 8.62237 14.0351 8.95326 14.5782L12.1823 19.8829C12.5604 20.5121 13.2117 20.8703 13.926 20.8703C14.01 20.8703 14.1046 20.8703 14.1886 20.8587C15.0079 20.7547 15.6592 20.1969 15.9008 19.4091L20.9114 2.63479C21.1319 1.9205 20.9324 1.14318 20.4071 0.607462Z'
      fill='#5B69E2'
    />
    <path
      opacity='0.4'
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1.06149 15.5545C0.859811 15.5545 0.658128 15.4779 0.504765 15.3235C0.196989 15.0157 0.196989 14.5178 0.504765 14.21L1.9386 12.7751C2.24638 12.4684 2.74533 12.4684 3.05311 12.7751C3.35983 13.0829 3.35983 13.5818 3.05311 13.8896L1.61822 15.3235C1.46486 15.4779 1.26318 15.5545 1.06149 15.5545ZM5.01237 16.8071C4.81069 16.8071 4.60901 16.7304 4.45564 16.576C4.14787 16.2682 4.14787 15.7703 4.45564 15.4625L5.88948 14.0276C6.19726 13.7209 6.69621 13.7209 7.00399 14.0276C7.31071 14.3354 7.31071 14.8344 7.00399 15.1421L5.5691 16.576C5.41574 16.7304 5.21405 16.8071 5.01237 16.8071ZM5.27885 20.555C5.43222 20.7094 5.6339 20.7861 5.83558 20.7861C6.03726 20.7861 6.23895 20.7094 6.39231 20.555L7.8272 19.1212C8.13392 18.8134 8.13392 18.3144 7.8272 18.0067C7.51942 17.6999 7.02047 17.6999 6.71269 18.0067L5.27885 19.4416C4.97108 19.7493 4.97108 20.2472 5.27885 20.555Z'
      fill='#5B69E2'
    />
  </svg>
)

const SVGIntegrationCRM = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGIntegrationCRM
