import React from 'react'

const LocationCouponButtonInput = (props) => {
  const { value, buttonColor, onChangeButton } = props

  return (
    <div className='my-custom-input'>
      <input
        value={value}
        onChange={(e) => {
          onChangeButton && onChangeButton(e)
        }}
        style={{ color: buttonColor }}
      />
    </div>
  )
}

export default LocationCouponButtonInput
