import React, { useState, useEffect } from 'react'
import { Form, Tabs } from 'antd'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useParams } from 'react-router-dom'
import { getLongName, replaceWords } from '../../../../utils'
import { getText, getTextServerError } from '../../../../lang'
import { CONNECT_URL } from '../../../../config'
import MatForm from '../../../../components/Form/MatForm'
import MatBlockWithLabel from '../../../../components/MatBlockWithLabel'
import InputTextarea from '../../../../components/InputTextarea'
import MatButton from '../../../../components/MatButton'
import { notifyError, notifySuccess } from '../../../../utils/Notify'
import MatadorConnectListTitle from '../matadorConnect/matadorConnectComponents/MatadorConnectListTitle'
import InputFormTextSecondary from '../../../../components/Form/InputFormText/InputFormTextSecondary'
import SwtichFormSecondary from '../../../../components/Form/SwitchForm/SwtichFormSecondary'
import MatadorConnectLayout from '../matadorConnect/matadorConnectComponents/MatadorConnectLayout'
import InputFormNumberSecondary from '../../../../components/Form/InputFormNumber/InputFormNumberSecondary'
import MatColorPicker from '../../../../components/MatColorPicker'
import SVGChatEditColorPicker from '../../../../icons/SVG/SVGChatEditColorPicker'
import locationActions from '../../../../store/modules/locationActions'
import LoadingSpinner from '../../../../components/LoadingSpinner'
import UrlConditionManager from '../matadorConnect/settings/UrlConditionManager'
import CtaPreview from './Preview'
import './Settings.scss'
import IntegratedCtaInstructions from './integratedCtaInstructions'

const CopySection = ({ label, text, isCopied, setIsCopy }) => (
  <MatBlockWithLabel label={label} className='text-area-copy-wrapper' black>
    <InputTextarea autoSize={false} value={text} disabled />
    <CopyToClipboard
      text={text}
      onCopy={() => {
        setIsCopy(true)
      }}
    >
      <MatButton
        size='small'
        htmlType='button'
        className='copy-button'
        buttonText={isCopied ? getText('WORD_COPIED') : getText('WORD_COPY')}
      />
    </CopyToClipboard>
  </MatBlockWithLabel>
)

const IntegratedCta = (props) => {
  const { organization } = props
  const { _id } = useParams()
  const languages = organization.languages
  const [selectedLangTab, setSelectedLangTab] = useState(languages[0])
  const [settingTextCopy, setSettingTextCopy] = useState('')
  const [htmlCodeCopied, setHtmlCodeCopied] = useState(false)
  const [scriptCodeCopied, setScriptCodeCopied] = useState(false)
  const [styleCodeCopied, setStyleCodeCopied] = useState(false)
  const [primaryColor, setPrimaryColor] = useState('#5CBF00')
  const [textColor, setTextColor] = useState('white')
  const [width, setWidth] = useState(300)
  const [borderRadius, setBorderRadius] = useState(8)
  const [height, setHeight] = useState(55)
  const [fontSize, setFontSize] = useState(12)
  const [disabledWidth, setDisabledWidth] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [settings, setSettings] = useState()
  const [urls, setUrls] = useState([])

  const [form] = Form.useForm()

  const getData = async () => {
    setIsLoading(true)
    const result = await locationActions.getIntegratedCta(_id)
    if (result.success) {
      setSettings(result.data)
      props.setFooterButtons && props.setFooterButtons(['save', 'cancel'])
      setSelectedLangTab(languages[0])
      setSettingTextCopy(result.data.initialTextMessage)
      setTextColor(result.data.textColor)
      setPrimaryColor(result.data.primaryColor)
      setDisabledWidth(result.data.fullWidth)
      setHeight(result.data.height)
      setWidth(result.data.width)
      setBorderRadius(result.data.borderRadius)
      setFontSize(result.data.fontSize)
      setUrls(result.data.conditions.url)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    getData()
  }, [])

  const onFinish = async (values) => {
    props.setLoading(true)
    const allValues = form.getFieldsValue(true)
    const {
      borderRadius,
      fontSize,
      fullWidth,
      height,
      width,
      textColor,
      primaryColor,
      termsLink,
    } = allValues

    // return notifyError(getText('TEXT_TERMS_LINK_INVALID'))

    let deviceSetting
    if (allValues.showMobile && allValues.showDesktop) {
      deviceSetting = 'ALL'
    } else if (allValues.showDesktop) {
      deviceSetting = 'DESKTOP'
    } else if (allValues.showMobile) {
      deviceSetting = 'MOBILE'
    } else {
      deviceSetting = 'NONE'
    }

    const payload = {
      device: deviceSetting,
      initialTextMessage: settingTextCopy,
      borderRadius,
      fontSize,
      fullWidth,
      height,
      width,
      textColor,
      primaryColor,
      status: 'ACTIVE',
      conditions: {
        url: urls,
      },
      termsLink,
    }

    const result = await locationActions.saveIntegratedCta(payload, _id)
    if (result.success) {
      notifySuccess(getText('CTA_SAVED_SUCCESSFULLY'))
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
    props.setLoading(false)
  }

  const onFinishFailed = (errorInfo) => {
    for (const lng of languages) {
      if (!settingTextCopy[lng].initialTextMessage) {
        const errorText = replaceWords(
          getText('TEXT_VARIABLE_REQUIRED_BOTH_LANGUAGE'),
          {
            missingVariable: getText('WORD_CTA_INITIAL_MESSAGE'),
            missingLanguage: getLongName(lng),
          }
        )
        return notifyError(errorText)
      }
    }
    if (errorInfo.errorFields.length > 0) {
      notifyError(errorInfo.errorFields[0].errors[0])
    }
  }

  const getHtmlCode = () => {
    return `<div class="matador-widget" data-id="${_id}"></div>`
  }

  const getStyleCode = () => {
    return `<link href="${CONNECT_URL}/widget/index.css" rel="stylesheet"></link>`
  }

  const getScriptCode = () => {
    return `<script src="${CONNECT_URL}/widget/index.js"></script>`
  }

  const getInitialTextMessage = (lang) => {
    const data = settingTextCopy.find((x) => x.language === lang)

    if (data) {
      return data.text
    }
    return ''
  }

  if (isLoading || !_id) {
    return <LoadingSpinner />
  }

  const setSettingTextCopyValue = (lang, text) => {
    const index = settingTextCopy.findIndex((x) => x.language === lang)
    const currentData = settingTextCopy

    if (index || index === 0) {
      currentData[index].text = text
      setSettingTextCopy([...currentData])
    }
  }

  return (
    <MatadorConnectLayout className='livechat-settings-wrapper'>
      <MatadorConnectListTitle title={getText('WORD_SETTINGS')} />
      <div className='form-chatboxPreview-wrapper'>
        <MatForm
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{
            initialTextMessage: {
              en: getInitialTextMessage('en'),
              fr: getInitialTextMessage('fr'),
              sp: getInitialTextMessage('sp'),
            },
            showMobile: settings.device === 'ALL' || settings.device === 'MOBILE',
            showDesktop: settings.device === 'ALL' || settings.device === 'DESKTOP',
            height: settings.height,
            width: settings.width,
            borderRadius: settings.borderRadius,
            fontSize: settings.fontSize,
            fullWidth: settings.fullWidth,
            textColor: settings.textColor,
            primaryColor: settings.primaryColor,
            termsLink: settings.termsLink,
          }}
        >
          <Tabs
            className='mat-tabs'
            onChange={(index) => {
              setSelectedLangTab(organization.languages[index])
            }}
            items={organization.languages.map((lng, index) => {
              return {
                label: getLongName(lng) + ' ' + getText('WORD_MESSAGES_LC'),
                key: index,
                forceRender: true,
                children: (
                  <>
                    <InputFormTextSecondary
                      label={getText('WORD_CTA_INITIAL_MESSAGE')}
                      name={['initialTextMessage', lng]}
                      required
                      errorMessage={getText('ERR_VALIDATION_REQUIRED')}
                      onChange={(e) => {
                        e.persist()
                        setSettingTextCopyValue(lng, e.target.value)
                      }}
                    />
                  </>
                ),
              }
            })}
          />
          <InputFormTextSecondary
            label={getText('WORD_TERMS_LINK')}
            name='termsLink'
          />
          <MatBlockWithLabel black label={getText('WORD_VISIBILTY')}>
            <div className='location-settings-switchs-wrapper'>
              <SwtichFormSecondary
                name='showMobile'
                text={getText('LABEL_SHOW_WIDGET_MOBILE')}
              />
              <SwtichFormSecondary
                name='showDesktop'
                text={getText('LABEL_SHOW_WIDGET_DESKTOP')}
              />
            </div>
          </MatBlockWithLabel>

          <MatBlockWithLabel className='color-appearance-wrapper'>
            <div className='color-picker-item-wrapper'>
              <MatColorPicker
                label={getText('WORD_PRIMARY_COLOR')}
                trigger={
                  <div className='appearance-edit-color-wrapper'>
                    {getText('TEXT_OPEN_EDITOR')}
                    <div className='conic-gradient'>
                      <SVGChatEditColorPicker />
                    </div>
                  </div>
                }
                color={primaryColor}
                className='color-pick-item'
                onChangeColor={(newColor) => {
                  setPrimaryColor(newColor)
                  form.setFieldsValue({
                    primaryColor: newColor,
                  })
                }}
              />
              <MatColorPicker
                label={getText('LABEL_TEXT_COLOR')}
                trigger={
                  <div className='appearance-edit-color-wrapper'>
                    {getText('TEXT_OPEN_EDITOR')}
                    <div className='conic-gradient'>
                      <SVGChatEditColorPicker />
                    </div>
                  </div>
                }
                color={textColor}
                className='color-pick-item'
                onChangeColor={(newColor) => {
                  setTextColor(newColor)
                  form.setFieldsValue({
                    textColor: newColor,
                  })
                }}
              />
            </div>
          </MatBlockWithLabel>
          <div className='inputs-appearance-wrapper'>
            <InputFormNumberSecondary
              label={getText('LABEL_WIDTH')}
              name='width'
              formClassName='input-item'
              onChange={(value) => setWidth(value)}
              disabled={disabledWidth}
            />
            <InputFormNumberSecondary
              label={getText('LABEL_HEIGHT')}
              name='height'
              formClassName='input-item'
              onChange={(value) => {
                setHeight(value)
              }}
            />
            <InputFormNumberSecondary
              label={getText('LABEL_FONT_SIZE')}
              name='fontSize'
              formClassName='input-item'
              onChange={(value) => setFontSize(value)}
            />
            <InputFormNumberSecondary
              label={getText('LABEL_BORDER_RADIUS')}
              name='borderRadius'
              formClassName='input-item'
              onChange={(value) => setBorderRadius(value)}
            />
          </div>
          <div>
            <SwtichFormSecondary
              name='fullWidth'
              text={getText('TEXT_RECOMMENDED_FOR_RESPONSIVENESS')}
              onChange={(value) => {
                setDisabledWidth(value)
              }}
            />
          </div>
          <CopySection
            label={getText('WORD_CTA_ADD_HTML_CODE')}
            text={getHtmlCode()}
            isCopied={htmlCodeCopied}
            setIsCopy={setHtmlCodeCopied}
          />
          <CopySection
            label={getText('WORD_CTA_ADD_STYLE')}
            text={getStyleCode()}
            isCopied={styleCodeCopied}
            setIsCopy={setStyleCodeCopied}
          />
          <CopySection
            label={getText('WORD_CTA_ADD_SCRIPT')}
            text={getScriptCode()}
            isCopied={scriptCodeCopied}
            setIsCopy={setScriptCodeCopied}
          />
          <UrlConditionManager urls={urls} setUrls={setUrls} />
          <IntegratedCtaInstructions id={_id} />
        </MatForm>
        <CtaPreview
          title={getInitialTextMessage(selectedLangTab)}
          color={primaryColor}
          textColor={textColor}
          height={height}
          width={disabledWidth ? `350px` : `${width}px`}
          borderRadius={borderRadius}
          fontSize={fontSize}
        />
      </div>
    </MatadorConnectLayout>
  )
}

export default IntegratedCta
