import React from 'react'
import { Space } from 'antd'

const BroadcastListMobileItemPercentage = ({ title, infoItem, showDetails }) => {
  return (
    <Space
      direction='vertical'
      className={showDetails ? 'broadcast-space-border-bottom' : ''}
    >
      <span className='info-item-mobile-title'>{title}</span>
      <div className='info-item'>
        <div className='percentage-wrapper'>
          <h4>{infoItem}</h4>
        </div>
      </div>
    </Space>
  )
}

export default BroadcastListMobileItemPercentage
