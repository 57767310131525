import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width={16}
    height={16}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    style={{ transform: `rotate(${props.rotate}deg)` }}
  >
    <path
      d='M8.00339 7.48279L8.00382 7.48212L8.00283 7.48279L4.38352 9.8908C4.07775 10.094 3.65139 10.0087 3.44821 9.70426C3.24436 9.39915 3.32963 8.97415 3.63474 8.77097L7.62871 6.11363C7.85254 5.96507 8.15367 5.96507 8.3775 6.11363L12.3715 8.77097C12.6766 8.97415 12.7619 9.39915 12.558 9.70426C12.3542 10.0087 11.9285 10.094 11.6227 9.8908L8.00339 7.48279Z'
      fill='#A3A1B7'
      stroke='#A3A1B7'
      strokeWidth={0.001}
    />
  </svg>
)

const SVGArrowCollapse = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGArrowCollapse
