import React from 'react'
import { Space } from 'antd'

const BroadcastListMobileItemLogicalTags = ({ title, arrayList }) => {
  return (
    <Space direction='vertical' className='broadcast-space-border-bottom'>
      <span className='info-item-mobile-title'>{title}</span>
      <div className='info-item tags-more-info-wrapper'>
        {arrayList.map((tag) =>
          tag.map((tagIt) => {
            return (
              <div
                key={tagIt.id}
                className='info-item-blue'
                style={{ width: 'fit-content' }}
              >
                {tagIt.name}
              </div>
            )
          })
        )}
      </div>
    </Space>
  )
}

export default BroadcastListMobileItemLogicalTags
