import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M5.77397 2.44147C4.3673 2.44147 2.49231 4.31646 2.49231 5.72312C2.49231 7.53479 4.36731 10.879 6.24231 12.754C6.44398 12.9556 6.97814 13.4898 7.17981 13.6915C9.05481 15.5665 12.399 17.4415 14.2107 17.4415C15.6173 17.4415 17.4923 15.5665 17.4923 14.1598C17.4923 12.754 15.6173 10.879 14.2107 10.879C13.7423 10.879 12.0465 11.849 11.8673 11.8165C10.3723 11.5465 8.42981 9.55563 8.11731 8.06647C8.07398 7.85897 9.05481 6.19146 9.05481 5.72312C9.05481 4.31646 7.1798 2.44147 5.77397 2.44147Z'
      fill='#A3A1B7'
    />
  </svg>
)

const SVGPhone = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGPhone
