import * as React from "react"

function SVGPaymentIcon(props) {
  return (
    <svg
      width={props.width?props.width: 166.691}
      height={props.height?props.height:171.796}
      viewBox="0 0 166.691 171.796"
      {...props}
    >
      <g data-name="Group 8467" fill={props.color ? props.color: "#fff"}>
        <g data-name="Group 8434" opacity={0.7}>
          <path
            data-name="Path 5726"
            d="M160.312 53.196a.968.968 0 00-1.508-.722l-.016.012a.953.953 0 00-.403.629A15.607 15.607 0 01135.6 64.127l.01.006-29.697-16.46.01.005a15.607 15.607 0 01-2.743-25.157.98.98 0 00.328-.68l.002-.022a.962.962 0 00-1.406-.893 21.592 21.592 0 00-9.043 8.767 21.727 21.727 0 002.912 25.114 28.142 28.142 0 00-15.936 33.614l4.286 14.951a5.955 5.955 0 002.837 3.568l8.225 4.559a5.954 5.954 0 004.53.516l14.945-4.292a28.143 28.143 0 0020.052-31.368 21.71 21.71 0 0022.755-10.85 21.59 21.59 0 002.646-12.309zm-48.465 42.81l-17.08 5.162 3.795-6.847a16.032 16.032 0 01-6.93-9.051l-.105-.37a16.089 16.089 0 1129.589 3.548 15.859 15.859 0 01-9.269 7.557z"
          />
          <path
            data-name="Path 6043"
            d="M81.07 18.01a.5.5 0 00-.846.138l.001.009a.488.488 0 00.016.382 7.995 7.995 0 01-6.331 11.326l-17.287 1.824a7.995 7.995 0 01-8.549-9.75.5.5 0 00-.062-.382v-.009a.493.493 0 00-.86.035 11.064 11.064 0 00-1.215 6.341 11.133 11.133 0 008.608 9.682 14.42 14.42 0 003.197 18.792l6.197 5.013a3.052 3.052 0 002.24.662l4.789-.505a3.05 3.05 0 002.052-1.116l5.011-6.196a14.42 14.42 0 00-.81-19.063 11.126 11.126 0 006.359-11.245 11.063 11.063 0 00-2.51-5.939zM73.32 50.225l-5.649 7.189-.421-3.99a8.214 8.214 0 01-5.57-1.76l-.153-.125a8.244 8.244 0 1113.46-7.212 8.126 8.126 0 01-1.667 5.898z"
          />
          <path
            data-name="Path 6044"
            d="M149.596 5.604a.5.5 0 00-.826.226l.002.009a.488.488 0 00.056.379 7.99 7.99 0 01-5.113 11.916l-16.992 3.613a7.99 7.99 0 01-9.511-8.8.5.5 0 00-.102-.373l-.002-.009a.493.493 0 00-.85.125 11.057 11.057 0 00-.547 6.428 11.126 11.126 0 009.563 8.726 14.411 14.411 0 005.136 18.345l6.68 4.337a3.05 3.05 0 002.296.425l4.707-1a3.049 3.049 0 001.924-1.324l4.335-6.68a14.411 14.411 0 00-2.791-18.863 11.119 11.119 0 005.149-11.839 11.056 11.056 0 00-3.114-5.64zm-4.345 32.828l-4.866 7.733-.834-3.92a8.209 8.209 0 01-5.719-1.17l-.166-.108a8.239 8.239 0 1112.627-8.57 8.121 8.121 0 01-1.042 6.035z"
          />
        </g>
        <g data-name="Group 8435" opacity={0.1}>
          <path
            data-name="Path 5726"
            d="M161.935 67.84a1.363 1.363 0 00-2.12-1.021l-.024.016a1.343 1.343 0 00-.566.886 21.962 21.962 0 01-32.077 15.506l.014.007-41.8-23.168.013.007a21.963 21.963 0 01-3.86-35.411 1.38 1.38 0 00.463-.958v-.03a1.355 1.355 0 00-1.974-1.266 30.392 30.392 0 00-12.732 12.35 30.583 30.583 0 004.1 35.35 39.617 39.617 0 00-22.433 47.314l6.03 21.043a8.382 8.382 0 003.995 5.021l11.576 6.417a8.38 8.38 0 006.375.725l21.041-6.038a39.617 39.617 0 0028.226-44.15 30.563 30.563 0 0032.03-15.275 30.389 30.389 0 003.723-17.325zm-68.219 60.257l-24.042 7.263 5.34-9.636a22.566 22.566 0 01-9.753-12.739l-.15-.52a22.647 22.647 0 1141.65 4.994 22.321 22.321 0 01-13.045 10.638z"
          />
          <path
            data-name="Path 6043"
            d="M50.395 18.312a.7.7 0 00-1.187.194l-.008.016a.687.687 0 00.023.538A11.254 11.254 0 0140.322 35l-24.337 2.568A11.254 11.254 0 013.952 23.843a.707.707 0 00-.088-.536l-.008-.015a.694.694 0 00-1.202.05 15.573 15.573 0 00-1.71 8.925A15.671 15.671 0 0013.06 45.896a20.298 20.298 0 004.501 26.45l8.721 7.057a4.295 4.295 0 003.153.932l6.743-.712a4.294 4.294 0 002.889-1.57l7.053-8.72a20.298 20.298 0 00-1.14-26.828 15.661 15.661 0 008.946-15.829 15.571 15.571 0 00-3.53-8.364zm-10.91 45.346l-7.951 10.12-.593-5.615a11.563 11.563 0 01-7.839-2.478l-.22-.178a11.606 11.606 0 1118.95-10.15 11.438 11.438 0 01-2.343 8.3z"
          />
          <path
            data-name="Path 6044"
            d="M146.851.852a.7.7 0 00-1.16.317l-.006.017a.687.687 0 00.079.532 11.247 11.247 0 01-7.187 16.77l-23.92 5.087a11.247 11.247 0 01-13.39-12.388.707.707 0 00-.143-.524l-.01-.013a.694.694 0 00-1.189.175 15.563 15.563 0 00-.77 9.048 15.661 15.661 0 0013.461 12.284 20.284 20.284 0 007.23 25.82l9.403 6.106a4.292 4.292 0 003.23.598l6.628-1.41a4.291 4.291 0 002.708-1.862l6.101-9.402a20.284 20.284 0 00-3.927-26.545 15.651 15.651 0 007.243-16.665 15.561 15.561 0 00-4.38-7.945zm-6.12 46.206l-6.849 10.886-1.174-5.519a11.556 11.556 0 01-8.05-1.646l-.236-.154a11.599 11.599 0 1117.777-12.06 11.43 11.43 0 01-1.465 8.493z"
          />
        </g>
      </g>
    </svg>
  )
}

export default SVGPaymentIcon
