import React from "react";
import Icon from "@ant-design/icons";

const SVGIcon = (props) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.9991 0.678711C4.39677 0.678711 0.665771 4.40954 0.665771 9.01204C0.665771 13.6145 4.39677 17.3454 8.9991 17.3454C13.6014 17.3454 17.3324 13.6145 17.3324 9.01204C17.3324 4.40954 13.6014 0.678711 8.9991 0.678711ZM8.9991 4.84538C9.45935 4.84538 9.83244 5.21871 9.83244 5.67871C9.83244 6.13871 9.45935 6.51204 8.9991 6.51204C8.53885 6.51204 8.16577 6.13871 8.16577 5.67871C8.16577 5.21871 8.53885 4.84538 8.9991 4.84538ZM8.9991 7.34538C9.45935 7.34538 9.83244 7.71871 9.83244 8.17871V12.3454C9.83244 12.8054 9.45935 13.1787 8.9991 13.1787C8.53885 13.1787 8.16577 12.8054 8.16577 12.3454V8.17871C8.16577 7.71871 8.53885 7.34538 8.9991 7.34538Z"
      fill="#A4A5A6"
    />
  </svg>
);

const SVGInfoIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />;
      }}
      {...props}
    />
  );
};

export default SVGInfoIcon;
