import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.3334 1.33398H4.66671C2.82671 1.33398 1.33337 2.82065 1.33337 4.65398V8.64065V9.30732C1.33337 11.1407 2.82671 12.6273 4.66671 12.6273H5.66671C5.84671 12.6273 6.08671 12.7473 6.20004 12.894L7.20004 14.2207C7.64004 14.8073 8.36004 14.8073 8.80004 14.2207L9.80004 12.894C9.92671 12.7273 10.1267 12.6273 10.3334 12.6273H11.3334C13.1734 12.6273 14.6667 11.1407 14.6667 9.30732V4.65398C14.6667 2.82065 13.1734 1.33398 11.3334 1.33398ZM8.66671 9.16732H4.66671C4.39337 9.16732 4.16671 8.94065 4.16671 8.66732C4.16671 8.39398 4.39337 8.16732 4.66671 8.16732H8.66671C8.94004 8.16732 9.16671 8.39398 9.16671 8.66732C9.16671 8.94065 8.94004 9.16732 8.66671 9.16732ZM11.3334 5.83398H4.66671C4.39337 5.83398 4.16671 5.60732 4.16671 5.33398C4.16671 5.06065 4.39337 4.83398 4.66671 4.83398H11.3334C11.6067 4.83398 11.8334 5.06065 11.8334 5.33398C11.8334 5.60732 11.6067 5.83398 11.3334 5.83398Z'
        fill='#48AF80'
      />
    </svg>
  )
}

const SVGSuperhumanSmsIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGSuperhumanSmsIcon
