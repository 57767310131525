import React from 'react'
import { getText } from '../../../../lang'
import MatBlockWithLabel from '../../../../components/MatBlockWithLabel'
import LanguageItem from './LanguageItem'

const LanguagesSection = (props) => {
  const {
    listLanguage,
    defaultListLanguage,
    defaultLanguage,
    disabled,
    onSetAsDefaultClick,
    onChangeSwitch,
  } = props
  return (
    <MatBlockWithLabel
      label={getText('WORD_LANGUAGE')}
      className={'languages-wrapper'}
    >
      {defaultListLanguage.map((lng, index) => {
        return (
          <LanguageItem
            key={index}
            lng={lng}
            isSelected={listLanguage.includes(lng)}
            isDefault={defaultLanguage === lng}
            disabled={disabled}
            onSetAsDefaultClick={onSetAsDefaultClick}
            onChangeSwitch={onChangeSwitch}
          />
        )
      })}
    </MatBlockWithLabel>
  )
}

export default LanguagesSection
