import React, { useState, useRef } from 'react'
import MatButton from '../MatButton'

const MatLongPressButton = ({
  buttonText,
  onClick,
  onLongPress,
  delay = 500,
  loading,
  disabled,
  className,
  icon,
}) => {
  const [isLongPressTriggered, setIsLongPressTriggered] = useState(false)
  const timeoutRef = useRef(null)

  const handleMouseDown = () => {
    setIsLongPressTriggered(false)
    timeoutRef.current = setTimeout(() => {
      setIsLongPressTriggered(true)
      onLongPress()
    }, delay)
  }

  const handleMouseUp = () => {
    clearTimeout(timeoutRef.current)
    if (!isLongPressTriggered) {
      onClick()
    }
    setIsLongPressTriggered(false)
  }

  return (
    <MatButton
      loading={loading}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onTouchStart={handleMouseDown}
      onTouchEnd={handleMouseUp}
      buttonText={buttonText || ''}
      disabled={disabled}
      className={className}
      icon={icon}
    />
  )
}

export default MatLongPressButton
