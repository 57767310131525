import React, { useEffect, useState } from 'react'
import { Card, Col, Form, Row } from 'antd'
import { useNavigate, useLocation } from 'react-router-dom'
import { useMixpanel } from 'react-mixpanel-browser'
import { useSelector } from 'react-redux'
import appActions from '../../../store/modules/appActions'
import authActions from '../../../store/modules/authActions'
import { getText } from '../../../lang'
import {
  VERIFICATION_RESEND_CODE_TIME,
  emailFormater,
  moveToLoginPage,
  phoneNumberFormater,
} from '../../../utils'
import SVGLoginLogo from '../../../icons/SVG/SVGLoginLogo'
import PageFull from '../../../components/PageFull'
import MatForm from '../../../components/Form/MatForm'
import { notifyError, notifySuccess } from '../../../utils/Notify'
import MatButton from '../../../components/MatButton'
import InputFormText from '../../../components/Form/InputFormText'
import MatRow from '../../../components/MatRow'
import LoginPageIcons from './LoginPageIcons'
import { loginSuccess, setReduxAfterLogin } from './loginUtils'
import LoginPageHeader from './LoginPageHeader'

let deviceSize = ['tabletPortrait', 'mobile']

const LoginVerificationGetCode = (props) => {
  const { screenSize } = props

  const [resendEnabled, setResendEnabled] = useState(false)
  const [countdown, setCountdown] = useState(VERIFICATION_RESEND_CODE_TIME)
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()
  const location = useLocation()

  const mixpanel = useMixpanel()

  const [form] = Form.useForm()

  const isMobileApp = useSelector((state) => {
    return state.authReducer.isMobileApp
  })

  useEffect(() => {
    if (location.state === null) {
      moveToLoginPage(navigate)
    }
  }, [location])

  useEffect(() => {
    let intervalId

    if (!resendEnabled && countdown > 0) {
      intervalId = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1)
      }, 1000)
    }

    return () => {
      clearInterval(intervalId)
    }
  }, [resendEnabled, countdown])

  useEffect(() => {
    if (countdown === 0) {
      setResendEnabled(true)
    }
  }, [countdown])

  const handleResendCode = async () => {
    const resultCode = await authActions.requestVerification(
      location.state.token,
      location.state.method
    )
    if (resultCode.success) {
      setResendEnabled(false)
      setCountdown(VERIFICATION_RESEND_CODE_TIME)
      notifySuccess(
        location.state.method === 'email'
          ? getText('TEXT_VERIFICATION_CODE_FOR_EMAIL').replace(
              '[email]',
              emailFormater(location.state.value)
            )
          : getText('TEXT_VERIFICATION_CODE_FOR_PHONE').replace(
              '[phone]',
              phoneNumberFormater(location.state.value)
            )
      )
    } else {
      notifyError(
        resultCode.errMsg ||
          getText('TEXT_INVALID_TEMPORARY_VERIFICATION_TOKEN_PROVIDED')
      )
    }
  }

  const onFinish = async (values) => {
    setLoading(true)
    const result = await authActions.verifyCode(location.state.token, values.code)
    if (result.success) {
      let version = localStorage.getItem('version')
      if (!Boolean(version)) {
        const { data } = await appActions.getVersion()
        localStorage.setItem('version', JSON.stringify(data))
      }
      setReduxAfterLogin(result.data, mixpanel)
      const resultData = loginSuccess(
        result.data,
        location,
        isMobileApp,
        location.state.method,
        location.state.loginInputValues
      )
      if (typeof resultData === 'object') {
        navigate(resultData.path, { state: resultData.state })
      } else {
        navigate(resultData)
      }
    } else {
      notifyError(
        result.errMsg ||
          getText('TEXT_INVALID_TEMPORARY_VERIFICATION_TOKEN_PROVIDED')
      )
    }
    setLoading(false)
  }

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields.length > 0) {
      notifyError(errorInfo.errorFields[0].errors[0])
    }
  }

  const isDesktop = !deviceSize.includes(screenSize)

  return (
    <PageFull className='login-page-wrapper'>
      <Row className='login-page-main-row'>
        <Col
          className='login-page-main-col'
          style={{
            width: isDesktop ? '59%' : '100%',
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: isDesktop ? 'flex-start' : 'center',
              marginTop: isDesktop ? 0 : 20,
            }}
          >
            <SVGLoginLogo color={'#5B66EA'} height={30} width={129} />
          </div>
          <Card bordered={false} className='login-page-left-card'>
            <LoginPageHeader
              customTitle={getText('WORD_VERIFICATION')}
              customText={
                location.state && location.state.method === 'email'
                  ? getText('TEXT_VERIFICATION_CODE_FOR_EMAIL').replace(
                      '[email]',
                      emailFormater(location.state && location.state.value)
                    )
                  : getText('TEXT_VERIFICATION_CODE_FOR_PHONE').replace(
                      '[phone]',
                      phoneNumberFormater(location.state && location.state.value)
                    )
              }
            />
            <MatForm form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
              <InputFormText
                name='code'
                label={getText('WORD_VERIFICATION_CODE')}
                autoComplete={'one-time-code'}
                required
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    form.submit()
                  }
                }}
              />
              {resendEnabled && (
                <div className='resend-code' onClick={handleResendCode}>
                  {getText('WORD_RESEND_CODE')}
                </div>
              )}
            </MatForm>
            {!resendEnabled && (
              <p style={{ color: '#a3a6be', textAlign: 'center' }}>
                Resend in {countdown} seconds
              </p>
            )}
            <MatRow style={{ justifyContent: 'center', marginTop: '20px' }}>
              <MatButton
                style={{ width: '100px' }}
                htmlType={'button'}
                typeButton={'cancel'}
                buttonText={getText('ACTION_BACK')}
                onClick={() => {
                  if (location.state && location.state.fromSecondPage) {
                    navigate('/login/verification')
                  } else {
                    moveToLoginPage(navigate)
                  }
                }}
              />
              <MatButton
                loading={loading}
                buttonText={getText('ACTION_LOGIN')}
                style={{ width: '120px' }}
              />
            </MatRow>
          </Card>
        </Col>
        {isDesktop ? <LoginPageIcons /> : null}
      </Row>
    </PageFull>
  )
}

export default LoginVerificationGetCode
