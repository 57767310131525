import React from 'react'
import { Radio, Form } from 'antd'
import './RadioFormButton.scss'

const RadioFormButtonSecondary = (props) => {
  const { value, radioOptions, type, buttonStyle, size, disabled } = props

  return (
    <Form.Item
      className={`mat-form-item mat-form-item-black ${props.formClassName || ''}`}
      style={props.formStyle}
      label={props.label}
      name={props.name}
      hasFeedback={props.hasFeedback}
      fieldKey={props.fieldKey}
      size={size}
      rules={[
        {
          required: props.required,
          message: 'error radio button',
          // message: props.errorMessage ? props.errorMessage : `Please input ${props.placeholder ? props.placeholder : props.label}!`,
        },
      ]}
    >
      <Radio.Group
        className={`mat-form-radio-group mat-form-radio-group-secondary ${
          props.className || ''
        }`}
        onChange={props.onChange}
        value={value}
        optionType={type}
        buttonStyle={buttonStyle}
        size={size}
        disabled={disabled}
      >
        {radioOptions.map((option) => (
          <Radio.Button
            value={option.value}
            key={option.value}
            disabled={option.disabled}
          >
            {option.icon}
            <span>{option.label}</span>
          </Radio.Button>
        ))}
      </Radio.Group>
    </Form.Item>
  )
}

RadioFormButtonSecondary.defaultProps = {
  radioOptions: [{ label: 'Radio', value: '1' }],
  type: '',
  buttonStyle: '',
}

export default RadioFormButtonSecondary
