import React, { useState, useEffect, forwardRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { getText } from '../../../../lang'
import statActions from '../../../../store/modules/statActions'
import { notifyError } from '../../../../utils/Notify'
import MatList from '../../../../components/MatList'
import GreetingTabHeader from './GreetingTabHeader'
import GreetingItem from './GreetingItem'
import QuestionItem from './QuestionItem'
import './GreetingsDashboard.scss'

const RANGE_ITEM = 10

const ChatPerformanceData = forwardRef((props, ref) => {
  const { selectedStatesType, selectedRange, selectedDataType } = props
  const navigate = useNavigate()

  const [currentPage, setCurrentPage] = useState(0) // current page user is?

  const [isLoading, setIsLoading] = useState(true)
  const [allData, setAllData] = useState({})
  const [chartData, setChartData] = useState(null) // fetched data

  useEffect(() => {
    getChatPerformanceData()
  }, [selectedRange, selectedStatesType, selectedDataType, props._location_id])

  const getGreetingCharts = async () => {
    let locationId = selectedDataType === 'org' ? '' : props._location_id
    const result = await statActions.getGreetingChart(locationId)

    if (result.success) {
      setChartData(result.data)
    } else {
      notifyError(result.errMsg ? result.errMsg : getText('ERR_GENERAL'))
    }
  }

  const getChatPerformanceData = async (pageClicked = 0) => {
    setIsLoading(true)
    const req = {
      range: selectedRange,
      page: pageClicked + 1,
      perPage: RANGE_ITEM,
      ...(selectedDataType === 'loc' && { _location_id: props._location_id }),
    }

    if (selectedStatesType === 'greetings' && !pageClicked) {
      getGreetingCharts()
    }

    let result =
      selectedStatesType === 'greetings'
        ? await statActions.getGreetingStatsList(req)
        : await statActions.getquestionsStatsList(req)

    if (result.success) {
      setCurrentPage(pageClicked)
      setAllData(result.data)
      navigate(
        `?type=chat_dashboard&page=${pageClicked}&stats=${selectedStatesType}&range=${selectedRange}`
      )
    } else {
      notifyError(result.errMsg ? result.errMsg : getText('ERR_GENERAL'))
    }
    setIsLoading(false)
  }

  const statsType =
    selectedStatesType === 'greetings'
      ? allData.greeting_list
        ? allData.greeting_list.greeting_array
        : []
      : allData.question_array
        ? allData.question_array
        : []

  const totalItems =
    selectedStatesType === 'greetings'
      ? allData.greeting_list
        ? allData.greeting_list.count_greetings
        : 0
      : allData.count_questions
  return (
    <>
      {chartData && selectedStatesType === 'greetings' ? (
        <GreetingTabHeader
          count={allData.count_conversations || 0}
          conversationRatio={allData.conversation_ratio}
          chartData={chartData || []}
        />
      ) : null}
      <MatList
        loading={isLoading}
        data={statsType}
        pagination
        defaultPageSize={RANGE_ITEM}
        paginationCurrentPage={currentPage}
        onPaginationChange={(pageClicked) => getChatPerformanceData(pageClicked)}
        paginationTotalCount={totalItems}
        renderItem={(item) => {
          return selectedStatesType === 'greetings' ? (
            <GreetingItem key={JSON.stringify(item)} item={item} />
          ) : (
            <QuestionItem
              key={JSON.stringify(item)}
              item={item}
              max_click_value={allData.max_click_value ? allData.max_click_value : 0}
            />
          )
        }}
      />
    </>
  )
})

export default ChatPerformanceData
