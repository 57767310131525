import React from 'react'
import { Typography, Divider, Collapse } from 'antd'
const { Title, Text } = Typography

const IntegratedCtaInstructions = ({ id }) => {
  const content = () => {
    return (
      <div>
        <Title level={3}>Integration Instructions</Title>

        <Text>
          To integrate the Matador Integrated CTA widget into your website, follow
          these simple steps. First, locate the section of your website where you
          want the widget to be displayed. It could be on your homepage, a specific
          inventory page, or any other relevant section.
        </Text>

        <Divider />

        <Text strong>Step 1: Add the HTML Code</Text>
        <pre
          style={{ fontSize: 12 }}
        >{`<div class="matador-widget" data-id="${id}"></div>`}</pre>

        <Text>
          Add the above HTML code to the place where you want the widget to be
          displayed on your website.
        </Text>

        <Divider />

        <Text strong>Step 2: Add the Style Link</Text>
        <pre style={{ fontSize: 12 }}>
          {`<link href="https://chat.matador.ai/widget/index.css" rel="stylesheet"></link>`}
        </pre>

        <Text>
          Include the style link in the <code>&lt;head&gt;</code> section of your
          website to ensure proper styling of the Matador widget.
        </Text>

        <Divider />

        <Text strong>Step 3: Add the Script Link</Text>
        <pre style={{ fontSize: 12 }}>
          {`<script src="https://chat.matador.ai/widget/index.js"></script>`}
        </pre>

        <Text>
          Add the script link in the <code>&lt;head&gt;</code> section of your
          website to enable the functionality of the Matador integrated CTA widget.
        </Text>

        <Divider />

        <Text>
          Congratulations! You have successfully integrated the Matador Integrated
          CTA widget into your website. Visitors can now enjoy the convenience of
          engaging with the Matador widgets.
        </Text>

        <Text>
          If you have any questions or encounter any issues during the integration
          process, please contact our support team at{' '}
          <a href='mailto:support@matador.ai'>support@matador.ai</a>. We're here to
          help!
        </Text>
      </div>
    )
  }

  const items = [
    {
      key: '1',
      label: 'Additional Instructions',
      children: content(),
    },
  ]

  return (
    <>
      <Collapse items={items} />
    </>
  )
}

export default IntegratedCtaInstructions
