import React, { useEffect, useState } from 'react'
import { getText, getTextServerError } from '../../../../../lang'
import { notifyError, notifySuccess } from '../../../../../utils/Notify'
import MatadorConnectListTitle from '../matadorConnectComponents/MatadorConnectListTitle'
import MatadorConnectLayout from '../matadorConnectComponents/MatadorConnectLayout'
import connectFlowActions from '../../../../../store/modules/connectFlowActions'
import locationActions from '../../../../../store/modules/locationActions'
import LoadingSpinner from '../../../../../components/LoadingSpinner'
import FlowDesigner from './CustomMatadorConnectDesigner'

const FlowDesignerScreen = (props) => {
  const { locationObj, user } = props

  const [loading, setLoading] = useState(false)
  const [steps, setSteps] = useState([])
  const [locations, setLocations] = useState([])
  const [enabled, setEnabled] = useState(false)

  const transformDataForQuestionsLocations = async (data) => {
    const index = data.findIndex((item) => item.type === 'Questions -> Locations')
    const item = data[index]
    if (item) {
      const transformedItem = {
        ...item,
        questionsAndLocations: [],
      }

      for (const questionAndLocation of item.questionsAndLocations) {
        const locations = await getLocationsByQuestion(questionAndLocation.question)
        const selectedLocations = questionAndLocation.locations.map((element) => ({
          locationId: element.locationId,
          organizationId: element.organizationId,
          questionId: element.questionId,
          name: element.location && element.location.name,
          locationCustomName: element.locationCustomName,
        }))

        transformedItem.questionsAndLocations.push({
          ...questionAndLocation,
          selectedLocations,
          locations,
        })
      }

      data[index] = transformedItem
    }

    return data
  }

  const getLocationsByQuestion = async (question) => {
    const result = await locationActions.getLocationsByQuestion({
      text: question?.question?.en,
      language: 'en',
    })

    const items = result.data

    if (items && items.questions && items.questions.length > 0) {
      return items.questions
    }
  }

  useEffect(() => {
    if (locationObj._id) {
      getData()
      getLocations()
    }
  }, [locationObj._id])

  const getData = async () => {
    setLoading(true)
    let result = await connectFlowActions.getSettings(locationObj._id)
    if (result.success) {
      const dataSteps = await transformDataForQuestionsLocations(result.data.steps)
      setEnabled(result.data.enabled)
      setSteps(dataSteps)
    }
    setLoading(false)
  }

  const getLocations = async () => {
    const result = await locationActions.getPageList(0, 1000, '', {}, true)
    if (result.success) {
      setLocations(result.data)
    }
  }

  const transformLocationData = (data) => {
    const items = []

    for (const location of data.locations) {
      const locationId = location.location._id
      const organizationId = location.organization._id

      const transformedLocation = {
        locationId,
        organizationId,
      }
      if (location.locationCustomName) {
        transformedLocation.locationCustomName = location.locationCustomName
      }

      items.push(transformedLocation)
    }

    return items
  }

  const transformLocationQuestionData = (data) => {
    return {
      locationId: data.locationQuestions.locationId,
      organizationId: data.locationQuestions.organizationId,
    }
  }

  const transformCustomQuestions = (data) => {
    const items = []

    for (const question of data.customQuestions) {
      const locationId = question.locationId || question.location._id
      const organizationId = question.organizationId || question.organization._id
      const questionId = question.questionId || question.question._id

      const transformedQuestion = {
        locationId,
        organizationId,
        questionId,
      }

      items.push(transformedQuestion)
    }

    return items
  }

  const transformQuestionsAndLocations = (item) => {
    return item.questionsAndLocations.map((item) => ({
      language: 'en',
      locations:
        item.selectedLocations &&
        item.selectedLocations.map((location) => ({
          locationCustomName: location.locationCustomName,
          locationId: location.locationId,
          organizationId: location.organizationId,
          questionId: location.questionId,
        })),
      locationId: item.locationId,
      organizationId: item.organizationId,
      questionId: item.questionId,
    }))
  }

  const formatData = (data) => {
    const steps = []
    for (const item of data) {
      const transformedItem = {
        showBackAction: item.showBackAction || false,
        type: item.type,
      }

      if (item.title) {
        transformedItem.title = item.title
      }

      if (item.description) {
        transformedItem.description = item.description
      }

      if (item.type === 'Locations') {
        transformedItem.locations = transformLocationData(item)
      }
      if (item.type === 'Location Questions') {
        transformedItem.locationQuestions = transformLocationQuestionData(item)
      }
      if (item.type === 'Custom Questions') {
        transformedItem.customQuestions = transformCustomQuestions(item)
      }
      if (item.type === 'Final View') {
        transformedItem.finalView = item.finalView
      }
      if (item.type === 'Questions -> Locations') {
        transformedItem.questionsAndLocations = transformQuestionsAndLocations(item)
      }
      steps.push(transformedItem)
    }

    return steps
  }

  const save = async (data) => {
    const steps = formatData(data)
    let result
    try {
      result = await connectFlowActions.update({ enabled, steps }, locationObj._id)
      if (!result.success) {
        notifyError(getTextServerError(result.errMsg))
        return
      }
      notifySuccess(getText('TEXT_MATADOR_CONNECT_SAVED_SUCCESSFULLY'))
    } catch (error) {
      notifyError(getTextServerError(result.errMsg))
    }
  }

  if (loading) {
    return <LoadingSpinner />
  }

  return (
    <MatadorConnectLayout>
      <MatadorConnectListTitle title={getText('WORD_FLOW_DESIGNER')} />
      <FlowDesigner
        token={
          locationObj && locationObj.chatSettings && locationObj.chatSettings._token
        }
        initialData={steps}
        save={save}
        locations={locations}
        user={user}
        enabled={enabled}
        setEnabled={setEnabled}
      />
    </MatadorConnectLayout>
  )
}

export default FlowDesignerScreen
