import React, { memo, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Flex } from 'antd'
import MessageAssignSelector from '../MessageAssignUsersSelector/MessageAssignSelector'
import RemindMePicker from '../MessageReminderAction/RemindMePicker'
import SVGArrowLeft from '../../../../../icons/SVG/SVGArrowLeft'
import ProfileLetters from '../../../../../components/ProfileLetters'
import { getCustomerName, getCustomerPhoneInvlid } from '../../../../../utils'
import SVGMaximizeIcon from '../../../../../icons/SVG/SVGMaximizeIcon'
import MessageVideoCallButton from '../MessageVideoCallAction/MessageVideoCallButton'
import MessageCenterArchiveButton from '../MessageArchiveAction/MessageCenterArchiveButton'
import MessageCenterHeaderMobileButtons from '../MessageHeaderMobileButtons/MessageCenterHeaderMobileButtons'
import MessageCallPhoneButton from '../MessagePhoneCallAction/MessageCallPhoneButton'
import MessageCenterHeaderCRMs from '../MessageCRMAction/MessageCenterHeaderCRMs'
import MessageCenterInterest from '../MessageIntents/MessageCenterInterest'
import { EMAIL, RED, YELLOW } from '../../../../../devOptions'
import CenterAISummerize from '../../centerMessageBody/CenterMessageItems/CenterAISummerize'
import { BROADCAST } from '../../../utils/constants'
import './MessageCenterMain.scss'

const deviceSizeMobile = ['tabletPortrait', 'mobile']
const deviceSizeTablet = ['tabletPortrait', 'mobile', 'tablet']

const MessageCenterHeader = (props) => {
  const {
    conversation,
    disabled,
    refreshConversation,
    addToList,
    msgChangeEmailSMSType,
    unarchiveConversation,
    unAssignConversation,
    messageType,
    removeConversationFromList,
    screenSize,
    showRightBox,
    closeCenterDrawer,
    isAwaitingConsent,
    isBlueExtension,
  } = props

  const [openReminder, setOpenReminder] = useState(false)
  const [uiState, setUIState] = useState({
    isExpandedSize: window.innerWidth > 1400 && window.innerWidth < 1550,
    isTheUIChanged: false,
  })

  const receiver = conversation.receiver

  const receiverName = getCustomerName(conversation)

  let user = useSelector((state) => {
    return state.authReducer.user
  })

  let menuOpen = useSelector((state) => {
    return state.authReducer.menuOpen
  })

  let aiSummary = useSelector((state) => {
    return state.authReducer.aiSummary
  })

  const isCustomerRedStatus = receiver?.status?.state === RED
  const isCustomerYellowStatusAndRestricted =
    receiver?.status?.state === YELLOW &&
    user.organization.restrictions.restrictTextingCustomersInPendingStatus
  const isCustomerPhoneNumberValid = !receiver?.phoneStatus?.valid || false
  const isCustomerPhoneTemporaryBlocked = Boolean(
    getCustomerPhoneInvlid(conversation)
  )

  useEffect(() => {
    const handleResize = () => {
      const isExpanded = window.innerWidth > 1400 && window.innerWidth < 1550
      setUIState({
        isExpandedSize: isExpanded,
        isTheUIChanged: isExpanded && menuOpen,
      })
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [menuOpen])

  return (
    <div className='message_page_center_panel_header'>
      <div
        className={`message_page_center_panel_header--header ${uiState.isTheUIChanged ? 'responsive' : ''}`}
      >
        {!uiState.isTheUIChanged && !deviceSizeTablet.includes(screenSize) ? (
          <React.Fragment>
            <div className='message_page_center_panel_header--header--left'>
              <div className='message_page_center_panel_header--header--left-name-block'>
                <ProfileLetters
                  className='contact-profile-letters medium'
                  name={receiverName}
                  withoutIcon
                />
                <span
                  className='name'
                  onClick={() => {
                    screenSize !== 'desktop' && showRightBox()
                  }}
                >
                  {receiverName}
                </span>
                {screenSize !== 'desktop' ? (
                  <SVGMaximizeIcon
                    className={'show_right_panel'}
                    onClick={showRightBox}
                  />
                ) : null}
                <MessageCenterHeaderCRMs
                  conversation={conversation}
                  addToList={addToList}
                  refreshConversation={refreshConversation}
                  messageType={messageType}
                />
              </div>
            </div>
            <div className='message_page_center_panel_header--header--right'>
              <MessageAssignSelector
                screenSize={screenSize}
                messageType={messageType}
                conversation={conversation}
                removeConversationFromList={removeConversationFromList}
              />
              <RemindMePicker
                unarchiveConversation={unarchiveConversation}
                unAssignConversation={unAssignConversation}
                conversation={conversation}
                addToList={addToList}
                screenSize={screenSize}
              />
              <MessageCallPhoneButton
                conversation={conversation}
                disabled={msgChangeEmailSMSType === EMAIL}
              />
              <MessageVideoCallButton
                conversation={conversation}
                disabled={
                  disabled ||
                  isCustomerPhoneNumberValid ||
                  msgChangeEmailSMSType === EMAIL ||
                  isCustomerRedStatus ||
                  isCustomerYellowStatusAndRestricted ||
                  conversation.is_archived ||
                  isCustomerPhoneTemporaryBlocked ||
                  isAwaitingConsent
                }
                isAwaitingConsent={isAwaitingConsent}
                refreshConversation={refreshConversation}
              />
              <MessageCenterArchiveButton
                conversation={conversation}
                disabled={disabled}
                messageType={messageType}
                removeConversationFromList={removeConversationFromList}
              />
              {messageType !== BROADCAST && !conversation.isNew && (
                <CenterAISummerize
                  conversation={conversation}
                  aiSummaryMessage={aiSummary}
                />
              )}
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className='message_page_center_panel_header--header--left'>
              <Flex>
                {!isBlueExtension && deviceSizeMobile.includes(screenSize) ? (
                  <SVGArrowLeft
                    className='drawer_close_icon'
                    color={'#474656'}
                    onClick={closeCenterDrawer}
                  />
                ) : null}
                <div className='message_page_center_panel_header--header--left-name-block'>
                  <ProfileLetters
                    className='contact-profile-letters medium'
                    name={receiverName}
                    withoutIcon
                  />
                  <span
                    className='name'
                    onClick={() => {
                      screenSize !== 'desktop' && showRightBox()
                    }}
                  >
                    {receiverName}
                  </span>
                </div>
                {screenSize !== 'desktop' ? (
                  <SVGMaximizeIcon
                    className={'show_right_panel'}
                    onClick={showRightBox}
                  />
                ) : null}
              </Flex>
              <MessageCenterHeaderMobileButtons
                refreshConversation={refreshConversation}
                conversation={conversation}
                onOpenModal={() => {
                  setOpenReminder(true)
                }}
                phoneDisabled={msgChangeEmailSMSType === EMAIL}
                videoDisabled={
                  disabled ||
                  isCustomerPhoneNumberValid ||
                  msgChangeEmailSMSType === EMAIL ||
                  isCustomerRedStatus ||
                  isCustomerYellowStatusAndRestricted ||
                  conversation.is_archived ||
                  isCustomerPhoneTemporaryBlocked ||
                  isAwaitingConsent
                }
              />
              <RemindMePicker
                unarchiveConversation={unarchiveConversation}
                unAssignConversation={unAssignConversation}
                conversation={conversation}
                addToList={addToList}
                screenSize={screenSize}
                forMobile
                visibleFromDropMenu={openReminder}
                setOpenReminder={setOpenReminder}
              />
            </div>
            <div className='message_page_center_panel_header--header--right'>
              <MessageAssignSelector
                screenSize={screenSize}
                messageType={messageType}
                conversation={conversation}
                removeConversationFromList={removeConversationFromList}
              />
              <Flex>
                <MessageCenterHeaderCRMs
                  conversation={conversation}
                  addToList={addToList}
                  refreshConversation={refreshConversation}
                  messageType={messageType}
                />
                <MessageCenterArchiveButton
                  conversation={conversation}
                  disabled={disabled}
                  messageType={messageType}
                  removeConversationFromList={removeConversationFromList}
                />
                {messageType !== BROADCAST && !conversation.isNew && (
                  <CenterAISummerize
                    conversation={conversation}
                    aiSummaryMessage={aiSummary}
                  />
                )}
              </Flex>
            </div>
          </React.Fragment>
        )}
      </div>
      <MessageCenterInterest conversation={conversation} />
    </div>
  )
}

export default memo(MessageCenterHeader)
