import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='21'
      height='21'
      viewBox='0 0 21 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.19 1C4.86 1 1 5.35 1 10.18C1 11.72 1.45 13.31 2.24 14.76C2.39 15 2.4 15.3 2.3 15.59L1.69 17.65C1.55 18.15 1.97 18.51 2.44 18.37L4.29 17.82C4.79 17.65 5.19 17.87 5.66 18.15C7 18.94 8.67 19.34 10.17 19.34C14.72 19.34 19.34 15.82 19.34 10.16C19.34 5.28 15.4 1.00999 10.19 1.00999V1Z'
        stroke={props.color || '#0C0D0D'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.20312 13.6302H6.43312L6.95312 12.1402H9.78312L10.3131 13.6302H11.5431L8.95312 6.7002H7.80312L5.21312 13.6302H5.20312ZM7.31312 11.1202L8.35312 8.12019H8.37312L9.42312 11.1202H7.30312H7.31312ZM12.6531 13.6302H13.8331V6.7002H12.6531V13.6302Z'
        fill={props.color || '#0C0D0D'}
      />
    </svg>
  )
}

const SVGOrgConversationAIIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGOrgConversationAIIcon
