import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = () => (
  <svg
    width='29'
    height='32'
    viewBox='0 0 29 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M27.7268 0.208576C27.6393 0.0329331 27.3768 0.0329331 27.2018 0.120755C27.1143 0.120755 27.1143 0.208576 27.1143 0.296398C27.0268 0.38422 27.0268 0.472041 27.1143 0.559863C28.1643 3.72144 26.5019 7.14648 23.4394 8.20034C22.8269 8.37599 22.2144 8.55163 21.5145 8.55163H8.4772C5.15226 8.46381 2.43981 5.74134 2.52731 2.40412C2.52731 1.78937 2.61481 1.17461 2.8773 0.559863C2.8773 0.472041 2.8773 0.38422 2.8773 0.296398C2.70231 0.0329331 2.52731 -0.0548885 2.35231 0.0329331C2.26482 0.0329331 2.26482 0.120755 2.17732 0.120755C1.30233 1.61372 0.777344 3.19451 0.777344 4.86312C0.777344 8.46381 3.0523 11.6254 6.46474 12.7671C3.1398 17.0703 3.48979 23.2178 7.33972 27.082L11.5396 31.2974C11.9771 31.7365 12.5896 32 13.1146 32H16.702C17.3145 32 17.927 31.7365 18.277 31.2974L22.4769 27.082C26.3269 23.2178 26.6769 17.0703 23.3519 12.7671C26.6769 11.6254 28.9518 8.46381 28.9518 4.86312C29.1268 3.19451 28.6893 1.61372 27.7268 0.208576ZM19.4145 23.6569L14.6021 28.575V25.589C13.1146 25.5012 11.6271 24.8865 10.5772 23.8326V23.7448C8.2147 21.2858 8.2147 17.3338 10.6647 14.9626C13.1146 12.5914 17.052 12.5914 19.4145 15.0504C20.552 16.1921 21.1645 17.7729 21.1645 19.3537C21.1645 21.0223 20.552 22.5153 19.4145 23.6569Z'
      fill='white'
    />
  </svg>
)

const SVGDrawerSmallLogo = (props) => <Icon component={SVGIcon} {...props} />

export default SVGDrawerSmallLogo
