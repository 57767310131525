import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import { Input } from 'antd'
import './InputTextarea.scss'

const deviceSize = ['mobile', 'tabletPortrait', 'tablet']

const { TextArea } = Input

const InputTextarea = forwardRef((props, ref) => {
  const inputAreaRef = useRef()

  useImperativeHandle(ref, () => ({
    autoFocus() {
      if (!deviceSize.includes(props.screenSize)) {
        inputAreaRef.current && inputAreaRef.current.focus()
      }
    },
  }))

  return (
    <div className='mat-textarea-wrapper'>
      <TextArea
        className={`mat-textarea mat-textarea-secondary ${props.className || ''}`}
        placeholder={props.label || props.placeholder}
        autoSize={props.autoSize}
        disabled={props.disabled}
        onChange={props.onChange}
        ref={inputAreaRef}
        value={props.value}
        defaultValue={props.defaultValue}
        allowClear={props.allowClear}
        showCount={props.showCount}
        onClick={props.onClick}
        onPressEnter={props.onPressEnter}
        onKeyUp={props.onKeyUp}
        onKeyDown={props.onKeyDown}
        required={props.required}
        size={props.size}
        readOnly={props.readOnly}
        maxLength={props.maxLength}
        onBlur={props.onBlur}
        style={{
          resize: 'none',
        }}
      />
    </div>
  )
})

InputTextarea.defaultProps = {
  name: 'comment',
  placeholder: 'Comment',
  allowClear: true,
  autoSize: { minRows: 2, maxRows: 5 },
  size: 'middle',
  required: false,
  hasFeedback: true,
  autoComplete: 'off',
}

export default InputTextarea
