import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={props.width || '13.729'}
    height={props.height || '12.452'}
    viewBox='0 0 13.729 12.452'
  >
    <path
      id='Message'
      d='M12.5,4a3.806,3.806,0,0,1,3.73,4.036v4.386a4.177,4.177,0,0,1-1.173,3,3.53,3.53,0,0,1-2.547,1.034H6.217a3.528,3.528,0,0,1-2.545-1.034,4.177,4.177,0,0,1-1.173-3V8.036A3.806,3.806,0,0,1,6.23,4Zm0,.958H6.23A2.84,2.84,0,0,0,3.458,8.036v4.386a3.226,3.226,0,0,0,.891,2.317,2.586,2.586,0,0,0,1.87.755H12.5s.006,0,.01,0a2.585,2.585,0,0,0,1.87-.755,3.224,3.224,0,0,0,.891-2.317V8.036A2.84,2.84,0,0,0,12.5,4.958Zm1.006,2.956a.479.479,0,0,1-.07.674L10.6,10.894a1.942,1.942,0,0,1-2.426,0L5.308,8.588a.479.479,0,1,1,.6-.746L8.77,10.149a.986.986,0,0,0,1.228,0l2.833-2.3A.478.478,0,0,1,13.505,7.914Z'
      transform='translate(-2.5 -4)'
      fill={props.color || '#a3a6be'}
      fillRule='evenodd'
    />
  </svg>
)

const SVGEmailSelect = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGEmailSelect
