import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react'
import { debounce } from 'lodash'
import SVGSearch from '../../../../icons/SVG/SVGSearch'
import InputTextSecondary from '../../../../components/InputText/InputTextSecondary'
import { getText } from '../../../../lang'
import './MessageConversationsSearch.scss'

const MessageConversationsSearch = forwardRef(({ disabled, onSearch }, ref) => {
  const [searchKeyword, setsearchKeyword] = useState('')
  const debouncedChange = useCallback(
    debounce((options) => onSearch && onSearch(options), 700),
    []
  )

  useImperativeHandle(ref, () => ({
    editSearchValue(val) {
      setsearchKeyword(val)
    },
  }))

  return (
    <div className='message_conversation_search'>
      <InputTextSecondary
        placeholder={getText('WORD_SEARCH')}
        allowClear
        disabled={disabled}
        value={searchKeyword}
        prefix={<SVGSearch />}
        autoComplete={'off'}
        variant={false}
        onChange={(e) => {
          debouncedChange && debouncedChange(e.target.value)
          setsearchKeyword(e.target.value)
        }}
        onClear={() => {
          debouncedChange && debouncedChange('')
        }}
        onPressEnter={() => {
          debouncedChange && debouncedChange(searchKeyword)
        }}
      />
    </div>
  )
})

export default MessageConversationsSearch
