import React, { useEffect, useState } from 'react'
import locationActions from '../../../../../store/modules/locationActions'
import MatSelectSecondary from '../../../../../components/MatSelect/MatSelectSecondary'
import { getText } from '../../../../../lang'

const FacebookPageForms = (props) => {
  const { account, leadForms, locationId, selectedPage } = props

  const [fbData, setFbData] = useState({
    forms: [],
    cursor: '',
    next: false,
  })
  const [isLoading, setIsLoading] = useState(false)
  const [currentLeadForms, setCurrentLeadForms] = useState([]) // selected forms

  useEffect(() => {
    setCurrentLeadForms(leadForms || [])
    const getFacebookPageForms = async () => {
      setIsLoading(true)
      if (account.isConnected) {
        const result = await locationActions.getFacebookPageForms(locationId)
        if (result.success) {
          const { forms = [], cursor = '', next = false } = result.data
          setFbData({
            forms: forms,
            cursor: cursor,
            next: next,
          })
        }
      }
      setIsLoading(false)
    }
    getFacebookPageForms()
  }, [account.isConnected, selectedPage.id])

  const handleSelectFormClick = async (formId, { isAdded = true }) => {
    setIsLoading(true)
    const formSelected = fbData.forms.find((form) => form.id === formId)
    if (isAdded) {
      const result = await locationActions.selectFacebookPageForm(
        locationId,
        formSelected,
        isAdded
      )
      if (result.success) {
        setCurrentLeadForms([...currentLeadForms, formSelected])
      }
    } else {
      const result = await locationActions.selectFacebookPageForm(
        locationId,
        formSelected,
        isAdded
      )
      if (result.success) {
        const newLeadForms = currentLeadForms.filter(
          (lead) => lead.id !== formSelected.id
        )
        setCurrentLeadForms(newLeadForms)
      }
    }
    setIsLoading(false)
  }

  const handleFbPagesOnScroll = async () => {
    if (!isLoading && fbData.next) {
      setIsLoading(true)
      let result = await locationActions.getFacebookPageForms(
        locationId,
        fbData.next,
        fbData.cursor
      )
      if (result.success) {
        const { forms = [], cursor = '', next = false } = result.data
        setFbData({
          forms: [...fbData.forms, ...forms],
          cursor,
          next,
        })
        setIsLoading(false)
      }
    }
  }

  return fbData.forms.length > 0 ? (
    <MatSelectSecondary
      value={currentLeadForms.map((lead) => ({
        label: lead.name,
        value: lead.id,
      }))}
      options={fbData.forms.map((form) => ({
        label: form.name,
        value: form.id,
      }))}
      placeholder={getText('TEXT_SELECT_LEADS')}
      loading={isLoading}
      onSelect={(formId) => handleSelectFormClick(formId, { isAdded: true })}
      onDeselect={(formId) => handleSelectFormClick(formId, { isAdded: false })}
      onLoadMore={handleFbPagesOnScroll}
      mode='multiple'
      maxTagCount='responsive'
      customOption
      multipleNewStyle
      onClear={() => {
        currentLeadForms.map(async (item) => {
          await locationActions.selectFacebookPageForm(locationId, item, false)
        })
        setCurrentLeadForms([])
      }}
    />
  ) : (
    <div style={{ width: '60%' }} />
  )
}

export default FacebookPageForms
