import React, { useState } from 'react'
import { Typography, Divider, Collapse } from 'antd'
import CopyToClipboard from 'react-copy-to-clipboard'
import { APP_URL } from '../../../../../config'
import MatButton from '../../../../../components/MatButton'
import InputTextarea from '../../../../../components/InputTextarea'
import MatBlockWithLabel from '../../../../../components/MatBlockWithLabel'
import { getText } from '../../../../../lang'

const { Title, Text } = Typography
const CopySection = ({ label, text, isCopied, setIsCopy }) => (
  <MatBlockWithLabel label={label} className='text-area-copy-wrapper' black>
    <InputTextarea autoSize value={text} disabled />
    <CopyToClipboard
      text={text}
      onCopy={() => {
        setIsCopy(true)
      }}
    >
      <MatButton
        size='small'
        htmlType='button'
        className='copy-button'
        buttonText={isCopied ? getText('WORD_COPIED') : getText('WORD_COPY')}
      />
    </CopyToClipboard>
  </MatBlockWithLabel>
)

const SubscriberGrowthInstructions = ({ locationId }) => {
  const [htmlCodeCopied, setHtmlCodeCopied] = useState(false)

  const getHtmlCode = () => {
    return `<!-- Start of Matador Subscriber Growth Script -->
    <script>
        (function(d, t) {
          var g = d.createElement(t),
            s = d.getElementsByTagName(t)[0];
          g.src = '${APP_URL}/_subscriber-growth.js';
          g.async = true;
          s.parentNode.insertBefore(g, s);
          g.onload = function() {
            subscriberGrowth("${locationId}");
          }
        })(document, "script");
    </script>
<!-- End of Matador Subscriber Growth Script -->`
  }

  const content = () => (
    <div>
      <Title level={4}>Include the Following Code on the Website</Title>
      <Text>
        To enable Subscriber Growth Modal on your website, include the following code
        snippet
      </Text>
      <Divider />
      <CopySection
        text={getHtmlCode()}
        isCopied={htmlCodeCopied}
        setIsCopy={setHtmlCodeCopied}
      />

      <pre style={{ maxWidth: '100%', fontSize: '80%' }}>{}</pre>
      <Text>
        This code snippet will load the necessary script for Subscriber Growth Modal
        and initialize it on your website.
      </Text>
      <Divider />
      <Text strong>Trigger Subscriber Growth Modal</Text>
      <br />
      <Text>
        To trigger the Subscriber Growth Modal, add the class{' '}
        <code>subscriber-growth</code> to any HTML element. For example:
        <br />
      </Text>
      <pre style={{ maxWidth: '100%' }}>
        {`<div class="subscriber-growth btn btn-primary">
  I'm interested
</div>`}
      </pre>
      <Text>
        By adding the <code>subscriber-growth</code> class to an HTML element, you'll
        make it a trigger for opening the Subscriber Growth Modal. You can customize
        the element and appearance based on your design requirements.
      </Text>
      <Divider />
      <Text>
        If you encounter any issues or have questions about the implementation
        process, please don't hesitate to contact our support team at{' '}
        <a href='mailto:support@matador.ai'>support@matador.ai</a>. We're here to
        help!
      </Text>
    </div>
  )

  const items = [
    {
      key: '2',
      label: 'Instructions - Trigger Subscriber Growth Modal',
      children: content(),
    },
  ]

  return <Collapse items={items} />
}

export default SubscriberGrowthInstructions
