import React, { useEffect, useRef, useState } from 'react'
import { Form } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import { getText, getTextServerError } from '../../../../lang'
import { notifyError, notifySuccess } from '../../../../utils/Notify'
import organizationActions from '../../../../store/modules/organizationActions'
import InputFormText from '../../../../components/Form/InputFormText'
import MatForm from '../../../../components/Form/MatForm'
import PageFull from '../../../../components/PageFull'
import PageFix from '../../../../components/PageFix'
import OrganizationPage from '../../components/OrganizationPage'
import UploadFiles from '../../../../components/UploadFiles'
import AddVariableInMessage from '../../../../components/AddVariableInMessage'
import SendMessageTab from './SendMessageTab'
import './TemplateEdit.scss'
import { TEMPLATE_TYPES } from '.'

const TemplateEdit = (props) => {
  const { organization, templateType } = props

  const navigate = useNavigate()
  const location = useLocation()

  const [currentCursorPosition, setCurrentCursorPosition] = useState(0)
  const [currentCursorPositionSubject, setCurrentCursorPositionSubject] = useState(0)
  const [selectedTab, setSelectedTab] = useState('')
  const [uploadFiles, setUploadFiles] = useState([])

  const textAreaRef = useRef()
  const textAreaSubjectRef = useRef()
  const [form] = Form.useForm()
  let templateData = location.state && location.state

  useEffect(() => {
    props.setFooterButtons && props.setFooterButtons(['save', 'cancel'])
    setSelectedTab(organization.languages[0])
    const replacedMediaFilds = mediaFieldsReplacer(
      templateData && templateData.media
    )
    setUploadFiles(replacedMediaFilds)
  }, [])

  const addNameInMessage = (word) => {
    let newString = form.getFieldValue('text')[selectedTab]
    let newText = [
      newString && newString.slice(0, currentCursorPosition),
      word,
      newString && newString.slice(currentCursorPosition),
    ].join('')
    form.getFieldValue('text')[selectedTab] = newText
    setCurrentCursorPosition(currentCursorPosition + word.length)
    textAreaRef.current &&
      textAreaRef.current.autoFocus(currentCursorPosition + word.length)
  }

  const addNameInSubject = (word) => {
    let newString = form.getFieldValue('subject')[selectedTab]
    let newText = [
      newString && newString.slice(0, currentCursorPositionSubject),
      word,
      newString && newString.slice(currentCursorPositionSubject),
    ].join('')
    form.getFieldValue('subject')[selectedTab] = newText
    setCurrentCursorPositionSubject(currentCursorPositionSubject + word.length)
    textAreaSubjectRef.current &&
      textAreaSubjectRef.current.autoFocus(
        currentCursorPositionSubject + word.length
      )
  }

  const mediaFieldsReplacer = (data = []) => {
    if (!data || !data.length) {
      return []
    }
    data = data.map((item) => {
      item.url = item.mediaUrl
      item.key = item._id
      item.uid = item._id

      return item
    })
    return data
  }

  const correctRequestMediaDataFields = (data) => {
    return data.map((item) => {
      return {
        mediaUrl: item.url,
        mediaContentType: item.mediaContentType,
      }
    })
  }

  const onUploadMedia = (media) => {
    let currentMedia = uploadFiles.map((item) => {
      if (item.id !== media.id) {
        return item
      }
      return media
    })
    setUploadFiles(currentMedia)
  }

  const onFinish = async (values) => {
    let obj = {}

    let objSend = {
      name: values.name,
      text: values.text,
      type: templateType,
      subject: values.subject,
    }
    objSend.media = correctRequestMediaDataFields(uploadFiles)
    obj._id = templateData && templateData._id

    let result
    if (obj._id) {
      result = await organizationActions.saveUpdateTemplet(
        objSend,
        obj._id,
        organization._id
      )
    } else {
      result = await organizationActions.saveNewTemplet(objSend, organization._id)
    }
    if (result && result.success) {
      notifySuccess(getText('TEXT_TEMPLATE_WAS_SAVED_SUCCESSFULLY'))
      navigate(-1)
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
  }

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields.length > 0) {
      notifyError(errorInfo.errorFields[0].errors[0])
    }
  }

  const listVariables = [
    {
      label: 'customer_first_name',
      value: ' {{customer_first_name}} ',
    },
    {
      label: 'customer_last_name',
      value: ' {{customer_last_name}} ',
    },
    {
      label: 'user_first_name',
      value: ' {{user_first_name}} ',
    },
    {
      label: 'user_last_name',
      value: ' {{user_last_name}} ',
    },
    {
      label: 'organization_name',
      value: ' {{organization_name}} ',
    },
    {
      label: 'user_phone',
      value: ' {{user_phone}} ',
    },
    {
      label: 'user_email',
      value: ' {{user_email}} ',
    },
    ...(templateType === TEMPLATE_TYPES.SMS
      ? [
          {
            label: 'review_invite_url',
            value: ' {{review_invite_url}} ',
          },
        ]
      : []),
    {
      label: 'year',
      value: ' {{year}} ',
    },
    {
      label: 'make',
      value: ' {{make}} ',
    },
    {
      label: 'model',
      value: ' {{model}} ',
    },
    {
      label: 'VIN',
      value: ' {{VIN}} ',
    },
    {
      label: 'trade_year',
      value: ' {{trade_year}} ',
    },
    {
      label: 'trade_make',
      value: ' {{trade_make}} ',
    },
    {
      label: 'trade_model',
      value: ' {{trade_model}} ',
    },
    {
      label: 'trade_VIN',
      value: ' {{trade_VIN}} ',
    },
    {
      label: 'offer_amount',
      value: ' {{offer_amount}} ',
    },
    {
      label: 'price_estimate',
      value: ' {{price_estimate}} ',
    },
  ]

  return (
    <OrganizationPage
      title={getText(
        templateType === TEMPLATE_TYPES.EMAIL
          ? 'WORD_USER_EMAIL_TEMPLATES'
          : 'WORD_USER_SMS_TEMPLATES'
      )}
      style={{ paddingTop: '10px' }}
    >
      <PageFull className='page-edit-wrapper'>
        <PageFix>
          <MatForm
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={{
              name: templateData ? templateData.name : '',
              text: templateData ? templateData.text : {},
              subject: templateData ? templateData.subject : {},
            }}
          >
            <InputFormText
              name='name'
              label={getText('LABEL_TEMPLATE_NAME')}
              required
              maxLength={100}
            />
            <SendMessageTab
              form={form}
              textAreaRef={textAreaRef}
              textAreaSubjectRef={textAreaSubjectRef}
              templateData={templateData}
              templateType={templateType}
              variablesData={listVariables}
              onAddVariableSubjectClick={addNameInSubject}
              languages={organization.languages}
              onChangeTextarea={(e) => {
                setCurrentCursorPosition(e)
              }}
              onClickTextarea={(e) => {
                setCurrentCursorPosition(e)
              }}
              onKeyUpTextarea={(e) => {
                setCurrentCursorPosition(e)
              }}
              onChangeTabs={(lng) => {
                let lang = lng.substring(0, 2).toLowerCase()
                setSelectedTab(lang)
              }}
              onChangeSubject={(e) => {
                setCurrentCursorPositionSubject(e.target.selectionStart)
              }}
              onKeyUpSubject={(e) => {
                setCurrentCursorPositionSubject(e.target.selectionStart)
              }}
              onClickSubject={(e) => {
                setCurrentCursorPositionSubject(e.target.selectionStart)
              }}
            />
            <AddVariableInMessage data={listVariables} onClick={addNameInMessage} />
            {Boolean(templateType === TEMPLATE_TYPES.SMS) && (
              <UploadFiles
                mediaData={uploadFiles}
                onUpload={(media) => onUploadMedia(media)}
                children={getText('WORD_PLUS_ADD_IMAGE_OR_PDF')}
                onStartUpload={(newFile) => {
                  setUploadFiles((oldUploadFile) => {
                    oldUploadFile.push(newFile)
                    return oldUploadFile
                  })
                }}
                mediaTypes={
                  'image/jpeg, image/jpg, image/png, image/gif, application/pdf'
                }
                beforeUploadProps
                errMsg={getText('MSG_ONLY_IMAGE_OR_PDF_FORMATS_CAN_BE_ADDED')}
                onRemoveItem={(uploadFiles) => setUploadFiles(uploadFiles)}
                customType={[{ size: 1, type: 'application/pdf' }]}
                className='template-edit-image-upload'
              />
            )}
          </MatForm>
        </PageFix>
      </PageFull>
    </OrganizationPage>
  )
}

export default TemplateEdit
