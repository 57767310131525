import React from 'react'
import { Popconfirm } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import './ConfirmPopup.scss'
import { getText } from '../../lang'

const ConfirmPopup = (props) => {
  return (
    <Popconfirm
      overlayClassName={`mat-confirm-popup ${props.className}`}
      title={props.title}
      open={props.visible}
      onOpenChange={props.onVisibleChange}
      onConfirm={props.onConfirm}
      onCancel={props.onCancel}
      placement={props.placement}
      okText={props.okText}
      cancelText={props.cancelText}
      getPopupContainer={props.getPopupContainer}
      icon={props.icon}
      okButtonProps={{
        type: 'primary',
        className: 'mat-btn mat-btn-primary',
        size: 'middle',
      }}
      cancelButtonProps={{
        type: 'cancel',
        className: 'mat-btn',
        size: 'middle',
      }}
    >
      {props.trigger}
    </Popconfirm>
  )
}

ConfirmPopup.defaultProps = {
  okText: getText('WORD_YES'),
  cancelText: getText('WORD_NO'),
  placement: 'top',
  icon: <ExclamationCircleOutlined />,
}

export default ConfirmPopup
