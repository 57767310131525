import React from "react";
import Icon from "@ant-design/icons";

const SVGIcon = (props) => (
  <svg
    width={props.width || "32"}
    height={props.height || "32"}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" rx="10" fill={props.color || "#EDF4FE"} />
    <path
      d="M10.2257 22.2812C9.81128 22.2812 9.4564 22.1338 9.16104 21.839C8.86618 21.5436 8.71875 21.1887 8.71875 20.7743V11.3559C8.71875 11.1675 8.75014 10.998 8.81293 10.8473C8.87572 10.6966 8.95735 10.5522 9.05781 10.4141L10.1127 9.13316C10.2131 8.99502 10.3387 8.8913 10.4894 8.82198C10.6401 8.75316 10.8034 8.71875 10.9792 8.71875H20.0208C20.1966 8.71875 20.3599 8.75316 20.5106 8.82198C20.6613 8.8913 20.7869 8.99502 20.8873 9.13316L21.9422 10.4141C22.0426 10.5522 22.1243 10.6966 22.1871 10.8473C22.2499 10.998 22.2812 11.1675 22.2812 11.3559V20.7743C22.2812 21.1887 22.1338 21.5436 21.839 21.839C21.5436 22.1338 21.1887 22.2812 20.7743 22.2812H10.2257ZM10.5271 10.9792H20.4729L19.8325 10.2257H11.1675L10.5271 10.9792ZM15.5 19.7006C15.6005 19.7006 15.6946 19.6848 15.7826 19.6531C15.8705 19.622 15.9521 19.5687 16.0274 19.4934L17.9865 17.5344C18.1246 17.3962 18.1937 17.2204 18.1937 17.0069C18.1937 16.7935 18.1246 16.6177 17.9865 16.4795C17.8483 16.3414 17.6725 16.2723 17.459 16.2723C17.2455 16.2723 17.0697 16.3414 16.9316 16.4795L16.2535 17.1576V14.7465C16.2535 14.533 16.1814 14.354 16.0372 14.2093C15.8926 14.0651 15.7135 13.9931 15.5 13.9931C15.2865 13.9931 15.1077 14.0651 14.9635 14.2093C14.8189 14.354 14.7465 14.533 14.7465 14.7465V17.1576L14.0684 16.4795C13.9303 16.3414 13.7545 16.2723 13.541 16.2723C13.3275 16.2723 13.1517 16.3414 13.0135 16.4795C12.8754 16.6177 12.8063 16.7935 12.8063 17.0069C12.8063 17.2204 12.8754 17.3962 13.0135 17.5344L14.9726 19.4934C15.0479 19.5687 15.1295 19.622 15.2174 19.6531C15.3054 19.6848 15.3995 19.7006 15.5 19.7006Z"
      fill={props.color || "#2761F3"}
    />
  </svg>
);

const SVGWorkflowArchiveConversationIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />;
      }}
      {...props}
    />
  );
};

export default SVGWorkflowArchiveConversationIcon;
