import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='24' height='24' rx='4' fill='#EAF6F1' />
      <rect
        x='0.5'
        y='0.5'
        width='23'
        height='23'
        rx='3.5'
        stroke={props.color || '#48AF80'}
        strokeOpacity='0.2'
      />
      <path
        d='M9.36328 15.7039L11.1716 17.1039C11.4049 17.3373 11.9299 17.4539 12.2799 17.4539H14.4966C15.1966 17.4539 15.9549 16.9289 16.1299 16.2289L17.5299 11.9706C17.8216 11.1539 17.2966 10.4539 16.4216 10.4539H14.0883C13.7383 10.4539 13.4466 10.1623 13.5049 9.75394L13.7966 7.88728C13.9133 7.36228 13.5633 6.77894 13.0383 6.60394C12.5716 6.42894 11.9883 6.66228 11.7549 7.01228L9.36328 10.5706'
        stroke={props.color || '#48AF80'}
        strokeMiterlimit='10'
      />
      <path
        d='M6.38867 15.7044V9.98773C6.38867 9.17106 6.73867 8.87939 7.55534 8.87939H8.13867C8.95534 8.87939 9.30534 9.17106 9.30534 9.98773V15.7044C9.30534 16.5211 8.95534 16.8127 8.13867 16.8127H7.55534C6.73867 16.8127 6.38867 16.5211 6.38867 15.7044Z'
        stroke={props.color || '#48AF80'}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

const SVGFollowedRecIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGFollowedRecIcon
