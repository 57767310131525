import React, { useCallback } from 'react'
import { Form, Tag, TreeSelect } from 'antd'
import './TreeSelectForm.scss'

const { SHOW_PARENT } = TreeSelect

const TreeSelectForm = (props) => {
  const rules = useCallback(() => {
    const rules = []
    if (props.required && !props.disabled) {
      rules.push({ required: props.required, message: props.message })
    }
    return rules
  }, [props.required, props.disabled])

  const tagRender = (propsTag) => {
    const { label, value, closable, onClose } = propsTag
    const onPreventMouseDown = (event) => {
      event.preventDefault()
      event.stopPropagation()
    }
    const selectedTag = props.treeData.find((item) => item.value === value)
    return (
      <Tag
        color={selectedTag ? 'red' : 'blue'}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
      >
        <div className='mat-tag-label'>{label}</div>
      </Tag>
    )
  }

  return (
    <Form.Item
      className={`mat-form-item ${props.formClassName || ''}`}
      label={props.label}
      name={props.name}
      hasFeedback={props.hasFeedback}
      rules={rules()}
    >
      <TreeSelect
        className={`mat-select ${props.className || ''} ${
          props.multipleNewStyle ? 'mat-multiple-select' : ''
        }`}
        treeCheckable={props.treeCheckable}
        allowClear={props.allowClear}
        suffixIcon={props.suffixIcon}
        placeholder={props.placeholder}
        loading={props.loading}
        showCheckedStrategy={SHOW_PARENT}
        disabled={props.disabled}
        getPopupContainer={(trigger) => trigger.parentNode}
        filterTreeNode={
          props.hasOwnProperty('filterOption')
            ? props.filterOption
            : (input, option) => {
                return (
                  option.title
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toString().toLowerCase()) > -1
                )
              }
        }
        treeData={props.treeData}
        tagRender={tagRender}
        onChange={props.onChange}
        defaultValue={props.defaultValue}
        popupClassName={`mat-select-tree-form-dropdown ${
          props.popupClassName || ''
        }`}
      />
    </Form.Item>
  )
}

TreeSelectForm.defaultProps = {
  name: 'select',
  label: 'Select item',
  placeholder: 'Select item',
  allowClear: false,
  suffixIcon: false,
}

export default TreeSelectForm
