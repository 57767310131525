import React from 'react'
import './Preview.scss'
import IconMessages from '../../../../icons/IconMessages'

const CtaPreview = ({
  title,
  color,
  height,
  width,
  borderRadius,
  textColor = '#fff',
  fontSize,
}) => {
  return (
    <div className='cta-preview-container'>
      <div
        className='cta-preview-content'
        style={{
          backgroundColor: color,
          color: textColor,
          height: `${height}px`,
          width: `${width}`,
          borderRadius: `${borderRadius}px`,
          fontSize: `${fontSize}px`,
        }}
      >
        <div className='cta-preview-icon'>{IconMessages.smsIcon(textColor)}</div>
        {title}
      </div>
    </div>
  )
}

export default CtaPreview
