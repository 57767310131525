import React, { useCallback, useEffect, useState } from 'react'
import {
  Scheduler,
  WeekView,
  MonthView,
  Appointments,
  AppointmentTooltip,
  ViewSwitcher,
  Toolbar,
  DateNavigator,
  DayView,
} from '@devexpress/dx-react-scheduler-material-ui'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ViewState } from '@devexpress/dx-react-scheduler'
import appointmentActions from '../../store/modules/appointmentActions'
import authActions from '../../store/modules/authActions'
import { getText } from '../../lang'
import SVGRedirectIcon from '../../icons/SVG/SVGRedirectIcon'
import PageFull from '../../components/PageFull'
import utilDate from '../../utils/utilsDate'
import RenderBookingEdit from '../messages/right/messageTabs/MessageTabBooking/RenderBookingEdit'
import LoadingSpinner from '../../components/LoadingSpinner'
import './Appointment.scss'
import { SUPERHUMAN } from '../../devOptions'

const formatDate = 'YYYY-MM-DD'

const CustomAppointment = ({ classes, ...restProps }) => {
  const { data } = restProps
  return (
    <Appointments.Appointment
      {...restProps}
      style={{
        ...restProps.style,
        backgroundColor:
          data.created_by === SUPERHUMAN ? 'var(--green)' : 'var(--mainColor)',
      }}
    />
  )
}

const Appointment = (props) => {
  const navigate = useNavigate()
  const location = useSelector((state) => {
    return state.authReducer.user.location
  })

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectDate, setSelectDate] = useState('Week')
  const [currentDate, setCurrentDate] = useState(new Date())
  const [startDate, setStartDate] = useState(
    utilDate.getStartOfWeek(currentDate, formatDate)
  )
  const [endDate, setEndDate] = useState(
    utilDate.getEndOfWeek(currentDate, formatDate)
  )
  const [visibleAppointmentTooltip, setVisibleAppointmentTooltip] = useState(false)

  useEffect(() => {
    props.setPageTitle && props.setPageTitle(getText('WORD_APPOINTMENTS'))
  }, [])

  useEffect(() => {
    const fill = async () => {
      setLoading(true)
      const result = await appointmentActions.getAppointmentList(
        location._id,
        startDate,
        endDate
      )
      if (result.success) {
        setData(
          result.data.appointments.map((app) => {
            return Object.assign(app, {
              title: app.customer.fullName || app.customer.phone,
              startDate: new Date(app.eventStart),
              endDate: new Date(app.eventEnd),
              id: app._id,
            })
          })
        )
      }
      setLoading(false)
    }
    if (location && location._id) {
      fill()
    }
  }, [selectDate, location, currentDate])

  const getDate = useCallback(({ currentDate, selectDate }) => {
    const firstDay =
      selectDate === 'Month'
        ? utilDate.beforeDays(
            utilDate.getStartOfMonth(currentDate, formatDate),
            formatDate,
            7
          )
        : utilDate.getStartOfWeek(currentDate, formatDate)
    const lastDay =
      selectDate === 'Month'
        ? utilDate.addDays(
            utilDate.getEndOfMonth(currentDate, formatDate),
            formatDate,
            7
          )
        : utilDate.getEndOfWeek(currentDate, formatDate)
    if (selectDate === 'Day') {
      setStartDate(currentDate)
      setEndDate(currentDate)
    } else {
      setStartDate(firstDay)
      setEndDate(lastDay)
    }
  }, [])

  const Content = ({ children, appointmentData, ...restProps }) => {
    return (
      <AppointmentTooltip.Content {...restProps} appointmentData={appointmentData}>
        <RenderBookingEdit
          overlayClassName={'appointment-page-data-wrapper'}
          placement={'top'}
          fromAppointment
          item={appointmentData}
          conversation={{}}
          onCancel={() => {
            setVisibleAppointmentTooltip(false)
          }}
          onConfirm={(dataAppointment) => {
            setData((oldList) => {
              oldList.map((item) => {
                dataAppointment.appointments.map((comingApp) => {
                  if (item._id === comingApp._id) {
                    item.title = comingApp.customer.fullName
                      ? comingApp.customer.fullName
                      : comingApp.customer.phone
                    item.startDate = new Date(comingApp.eventStart)
                    item.endDate = new Date(comingApp.eventEnd)
                    item.eventStart = comingApp.eventStart
                    item.eventEnd = comingApp.eventEnd
                  }
                  return comingApp
                })
                return item
              })
              return [...oldList]
            })
            setVisibleAppointmentTooltip(false)
          }}
          handleOnDelete={async (item) => {
            setVisibleAppointmentTooltip(false)
            setTimeout(() => {
              setData((oldList) => {
                let newList = oldList.filter((oldItem) => oldItem._id !== item._id)
                return [...newList]
              })
            }, 100)
          }}
        />
      </AppointmentTooltip.Content>
    )
  }

  const Header = ({ children, appointmentData, ...restProps }) => {
    return (
      <AppointmentTooltip.Header {...restProps}>
        <div className='appointment-title' {...restProps}>
          {appointmentData?.customer?.fullName ||
            appointmentData?.customer?.phone ||
            ''}
          <SVGRedirectIcon
            onClick={() => {
              authActions.setConversation(false)
              navigate('/messages', {
                state: {
                  conv: {
                    _id: appointmentData?._conversation_id || '',
                    phone: appointmentData?.customer?.phone || '',
                  },
                },
              })
            }}
          />
        </div>
      </AppointmentTooltip.Header>
    )
  }

  return loading ? (
    <LoadingSpinner />
  ) : (
    <PageFull className={'appointment-page-wrapper'}>
      <div className='calendar'>
        <div className='calendar-wrapper'>
          <Scheduler data={data} locale={authActions.getLanguage()}>
            <ViewState
              currentViewName={selectDate}
              currentDate={currentDate}
              onCurrentViewNameChange={(val) => {
                getDate({ selectDate: val, currentDate })
                setSelectDate(val)
              }}
              onCurrentDateChange={(val) => {
                let dateString = val + ''
                if (dateString.includes('00:00:00')) {
                  let date = new Date(utilDate.addMinutes(val, '', 1))
                  val = date
                }
                getDate({ selectDate, currentDate: val })
                setCurrentDate(val)
              }}
            />
            <WeekView
              startDayHour={8}
              displayName={getText('WORD_WEEK')}
              timeTableCellComponent={({ onDoubleClick, ...restProps }) => {
                return (
                  <WeekView.TimeTableCell {...restProps} onDoubleClick={undefined} />
                )
              }}
            />
            <DayView displayName={getText('WORD_DAY')} startDayHour={8} />
            <MonthView displayName={getText('WORD_MONTH')} />
            <Toolbar />
            <Appointments appointmentComponent={CustomAppointment} />
            <DateNavigator />
            <AppointmentTooltip
              headerComponent={Header}
              contentComponent={Content}
              showCloseButton={true}
              visible={visibleAppointmentTooltip}
              onVisibilityChange={(visible) => {
                setVisibleAppointmentTooltip(visible)
              }}
            />
            <ViewSwitcher />
          </Scheduler>
        </div>
      </div>
    </PageFull>
  )
}

export default Appointment
