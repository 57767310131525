import React from 'react'

function SVGFacebook(props) {
  const { activeIcon, ...rest } = props

  const SVGFile = (
    <svg width={30} height={30} viewBox='0 0 30 30' {...rest}>
      <g data-name='Group 7279' transform='translate(-722 -275)'>
        <circle
          data-name='Ellipse 602'
          cx={15}
          cy={15}
          r={15}
          transform='translate(722 275)'
          fill={rest.color ? rest.color : '#4f62b1'}
        />
        <path
          data-name='Path 5835'
          d='M740.062 289.837h-1.875v6.864h-2.838v-6.864h-1.35v-2.412h1.35v-1.561a2.662 2.662 0 012.864-2.864l2.1.009v2.342h-1.526a.578.578 0 00-.6.658v1.42h2.122z'
          fill={rest.fontColor ? rest.fontColor : '#fff'}
        />
      </g>
    </svg>
  )

  if (!activeIcon) {
    return SVGFile
  }
  return (
    <div className={'mat-user-profile-letters'}>
      {activeIcon && <div className='active-icon' />}
      {SVGFile}
    </div>
  )
}

export default SVGFacebook
