import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Iconly/Light/Chat'>
        <path
          id='IP'
          d='M8.1 14.158C8.1 14.3401 8.14932 14.5057 8.25593 14.6279C8.36467 14.7526 8.51933 14.8163 8.69522 14.8163C8.87092 14.8163 9.02636 14.7528 9.13626 14.6288C9.24437 14.5069 9.29556 14.3412 9.29556 14.158V7.84131C9.29556 7.65813 9.24437 7.49244 9.13626 7.3705C9.02636 7.24654 8.87092 7.18301 8.69522 7.18301C8.51933 7.18301 8.36467 7.24672 8.25593 7.37143C8.14932 7.49368 8.1 7.65923 8.1 7.84131V14.158ZM10.669 14.158C10.669 14.3401 10.7183 14.5057 10.8249 14.6279C10.9336 14.7526 11.0883 14.8163 11.2642 14.8163C11.4401 14.8163 11.5947 14.7526 11.7035 14.6279C11.8101 14.5057 11.8594 14.3401 11.8594 14.158V12.0207H13.347C14.0535 12.0207 14.6528 11.788 15.0759 11.3652C15.499 10.9425 15.7333 10.3423 15.7333 9.63096C15.7333 8.92476 15.4989 8.32364 15.0777 7.89855C14.6564 7.47333 14.0596 7.23596 13.3572 7.23596H11.6531C11.3391 7.23596 11.0813 7.29248 10.9057 7.46108C10.7285 7.63124 10.669 7.88237 10.669 8.18547V14.158ZM12.779 10.9106H11.8645V8.35136H13.3061C13.7078 8.35136 14.0048 8.47232 14.2023 8.67999C14.4008 8.88873 14.5173 9.20478 14.5173 9.63096C14.5173 10.1363 14.3975 10.4363 14.1528 10.6212C13.8959 10.8153 13.4676 10.9106 12.779 10.9106Z'
          fill='#28303F'
          stroke='#28303F'
          strokeWidth='0.3'
        />
        <path
          id='Ellipse 884'
          d='M19.25 9.98116C19.25 14.4813 14.0938 20.1663 11 20.1663C7.90625 20.1663 2.75 14.4813 2.75 9.98116C2.75 5.48106 6.44365 1.83301 11 1.83301C15.5563 1.83301 19.25 5.48106 19.25 9.98116Z'
          stroke='#28303F'
          strokeWidth='1.5'
        />
      </g>
    </svg>
  )
}

const SVGOrgIPWhiteListIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGOrgIPWhiteListIcon
