import React from "react";
import Icon from "@ant-design/icons";

const SVGIcon = (props) => (
  <svg
    width={props.width || "32"}
    height={props.height || "32"}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect opacity="0.1" width="32" height="32" rx="10" fill="#5CB7D2" />
    <path
      d="M23.2457 12.9494C22.8513 12.0082 22.2775 11.1526 21.5564 10.4305C20.8374 9.70709 19.9834 9.13195 19.0427 8.73771C18.0791 8.33189 17.0438 8.12386 15.9982 8.12599H15.963C14.8996 8.13127 13.8712 8.3422 12.9027 8.75705C11.9701 9.15532 11.1241 9.73149 10.4119 10.4533C9.69773 11.1739 9.13056 12.0264 8.74195 12.9635C8.33856 13.938 8.13416 14.9833 8.14078 16.0379C8.14605 17.2578 8.43785 18.469 8.98277 19.5518V22.2236C8.98277 22.6701 9.34488 23.0322 9.78961 23.0322H12.458C13.5458 23.5812 14.7463 23.87 15.9648 23.876H16.0017C17.0529 23.876 18.0707 23.6721 19.0304 23.2731C19.9663 22.8835 20.8172 22.3151 21.5353 21.5996C22.2595 20.8807 22.8291 20.0404 23.2281 19.1035C23.6412 18.1332 23.8521 17.1014 23.8574 16.0361C23.8609 14.9656 23.6535 13.9268 23.2457 12.9494ZM12.4896 16.8447C12.0255 16.8447 11.6476 16.4668 11.6476 16.001C11.6476 15.5352 12.0255 15.1572 12.4896 15.1572C12.9537 15.1572 13.3316 15.5352 13.3316 16.001C13.3316 16.4668 12.9554 16.8447 12.4896 16.8447ZM15.9982 16.8447C15.5341 16.8447 15.1562 16.4668 15.1562 16.001C15.1562 15.5352 15.5341 15.1572 15.9982 15.1572C16.4623 15.1572 16.8402 15.5352 16.8402 16.001C16.8402 16.4668 16.4623 16.8447 15.9982 16.8447ZM19.5068 16.8447C19.0427 16.8447 18.6648 16.4668 18.6648 16.001C18.6648 15.5352 19.0427 15.1572 19.5068 15.1572C19.9709 15.1572 20.3488 15.5352 20.3488 16.001C20.3488 16.4668 19.9709 16.8447 19.5068 16.8447Z"
      fill="#5CB7D2"
    />
  </svg>
);

const SVGWorkflowSMSIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />;
      }}
      {...props}
    />
  );
};

export default SVGWorkflowSMSIcon;
