import React from "react";
import Icon from "@ant-design/icons";

const SVGIcon = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        opacity="0.1"
        cx="12"
        cy="12"
        r="12"
        fill={props.color || "#5BC78B"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9969 4.79688C8.02446 4.79688 4.79688 8.02476 4.79688 11.9969C4.79688 15.969 8.02446 19.1969 11.9969 19.1969C15.9693 19.1969 19.1969 15.9687 19.1969 11.9969C19.1969 8.02505 15.9693 4.79688 11.9969 4.79688ZM9.67969 9.395C10.2832 9.395 10.7814 9.89147 10.7814 10.4967C10.7814 11.1019 10.284 11.5983 9.67969 11.5983C9.07437 11.5983 8.57283 11.1019 8.57283 10.4967C8.57283 9.89147 9.07517 9.395 9.67969 9.395ZM14.3142 9.395C14.9187 9.395 15.4158 9.89147 15.4158 10.4967C15.4158 11.1019 14.9194 11.5983 14.3142 11.5983C13.7089 11.5983 13.2125 11.1019 13.2125 10.4967C13.2125 9.89147 13.7098 9.395 14.3142 9.395ZM15.2917 13.8433C15.4185 13.8367 15.5378 13.9034 15.5985 14.015C15.6592 14.1264 15.6505 14.2628 15.5762 14.3657C14.7827 15.5922 13.4777 16.4088 11.9969 16.4088C10.5146 16.4088 9.20587 15.592 8.41257 14.3657C8.34769 14.2692 8.33857 14.1455 8.38844 14.0405C8.43832 13.9355 8.5398 13.8644 8.65561 13.8537C8.66773 13.853 8.67974 13.853 8.69186 13.8537C8.80362 13.8555 8.90684 13.9139 8.96595 14.0088C9.65326 15.071 10.7553 15.7468 11.997 15.7468C13.2369 15.7468 14.3357 15.0708 15.023 14.0088C15.0786 13.9115 15.18 13.8492 15.292 13.8433L15.2917 13.8433Z"
        fill={props.color || "#5BC78B"}
      />
    </svg>
  );
};

const SVGIntentPositiveIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />;
      }}
      {...props}
    />
  );
};

export default SVGIntentPositiveIcon;
