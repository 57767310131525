import React from 'react'

const BroadcastListDesktopItemTags = ({ title, arrayList }) => {
  return (
    <div className='broadcast-message-row-wrapper-with-margin'>
      <div className='broadcast-message-row'>
        <p className='broadcast-more-info-row-title'>{title}</p>
      </div>
      <div className='tags-more-info-wrapper'>
        {arrayList.map((tag, index) => {
          return (
            <div
              key={index}
              className='info-item-blue'
              style={{ width: 'fit-content' }}
            >
              {tag.name ? tag.name : tag}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default BroadcastListDesktopItemTags
