import React, { useState } from 'react'
import SVGMarkAsRead from '../../../../../icons/SVG/SVGMarkAsRead'
import { getText } from '../../../../../lang'
import messageActions from '../../../../../store/modules/messageActions'
import { notifyError } from '../../../../../utils/Notify'
import MatButton from '../../../../../components/MatButton'
import { ALL, MY_MESSAGES, UNASSIGNED } from '../../../utils/constants'

const MessageMarkAsReadButton = (props) => {
  const { conversation, messageType } = props

  const [saving, setsaving] = useState(false)

  let canMarkAsRead = [ALL, MY_MESSAGES, UNASSIGNED].includes(messageType)

  const handleMarkRead = async () => {
    const unread = conversation.is_unread
    if (!unread) {
      return
    }
    setsaving(true)
    const result = await messageActions.markRead(conversation._id)
    setsaving(false)
    if (!result.success) {
      notifyError(
        result.errMsg
          ? result.errMsg.startsWith('child')
            ? getText('ERR_GENERAL')
            : result.errMsg
          : getText('ERR_GENERAL')
      )
    }
  }

  return (
    conversation &&
    !conversation.isNew &&
    canMarkAsRead && (
      <MatButton
        onClick={handleMarkRead}
        loading={saving}
        className='mark_as_read_btn'
        icon={<SVGMarkAsRead />}
        buttonText={getText('ACTION_MARK_AS_READ')}
      />
    )
  )
}

export default MessageMarkAsReadButton
