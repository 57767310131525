import moment from 'moment'
import utilDate from '../../../utils/utilsDate'

export const FINISHED = 'Finished'
export const CANCELED = 'Canceled'

export function getBroadcastTags(item) {
  let result =
    item.segmentFilters && item.segmentFilters.tags
      ? item.segmentFilters.tags
      : item.excelDoc && item.tags_to_assign && item.tags_to_assign.length > 0
        ? item.tags_to_assign
        : []
  return result
}

export function getBroadcastAssignedUsers(item) {
  let result =
    item.segmentFilters && item.segmentFilters.assigned_to
      ? item.segmentFilters.assigned_to
      : item.users_to_assign && item.users_to_assign.length > 0
        ? item.users_to_assign
        : []
  return result
}

export function getBroadcastLogicalTags(item) {
  let result =
    item.segmentFilters && item.segmentFilters.logicalTags
      ? item.segmentFilters.logicalTags
      : []
  return result
}

export function getBroadcastTouchpoints(item) {
  let result =
    item.segmentFilters && item.segmentFilters.source && item.segmentFilters.source
  return result
}

export function getDiffMinutesForBroadcast(action_time) {
  const actionTime = moment(action_time)
  const currentTime = utilDate.getDateUTC()
  return actionTime.diff(currentTime, 'minutes')
}

export function getBroadcastLanguage(item) {
  let result =
    item.segmentFilters &&
    item.segmentFilters.language &&
    item.segmentFilters.language
  return result
}
