import React from 'react'
import { formatAmount } from '../../../utils'
import SVGAmountIcon from '../../../icons/SVG/SVGAmountIcon'
import SVGArrowDown from '../../../icons/SVG/SVGArrowDown'
import utilDate from '../../../utils/utilsDate'

const DepositsListItemMobileView = ({ item, onClick }) => {
  return (
    <div
      className='deposit-list-item-mobile-view-wrapper'
      onClick={() => {
        onClick && onClick()
      }}
    >
      <div className='deposit-list-item-mobile-info'>
        <span className='payment-amount'>
          <SVGAmountIcon />
          {formatAmount(item.amount)}
        </span>
        <span className='payment-grey-item'>
          {utilDate.getDateTimeForPayment(item.updatedAt)}
        </span>
      </div>
      <SVGArrowDown className={'deposit-arrow-icon'} />
    </div>
  )
}

export default DepositsListItemMobileView
