import React, { memo } from 'react'
// import SVGRectangleIcon from '../../../../../icons/SVG/SVGRectangleIcon'
// import SVGRectangleIconResp from '../../../../../icons/SVG/SVGRectangleIconResp'

const CenterMessageReviewInfoBlock = (props) => {
  const { icon, infoViewTitle, count, noIcon, bodyIcon, active, style, onClick } =
    props

  return (
    <div
      className={`center_message_more_items-separation ${active ? 'active' : ''}`}
      style={style}
      onClick={() => {
        onClick && onClick()
      }}
    >
      <div className='center_message_more_items-separation-icon'>{icon}</div>
      <div className='center_message_more_items-separation-title'>
        {infoViewTitle}
      </div>
      {!noIcon && bodyIcon}
      {noIcon && <span className='text'>{count}</span>}
    </div>
  )
}

export default memo(CenterMessageReviewInfoBlock)
