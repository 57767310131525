import React, { memo } from 'react'
import _ from 'lodash'
import { Carousel } from 'antd'
import { getText } from '../../../../../lang'
import SVGInterestIcon from '../../../../../icons/SVG/SVGInterestIcon'
import SVGArrowRightIcon from '../../../../../icons/SVG/SVGArrowRightIcon'
import SVGArrowLeftIcon from '../../../../../icons/SVG/SVGArrowLeftIcon'
import './MessageIntents.scss'

const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
  <button
    {...props}
    className={
      'slick-next slick-arrow' +
      (currentSlide === slideCount - 1 ? ' slick-disabled' : '')
    }
    aria-hidden='true'
    aria-disabled={currentSlide === slideCount - 1 ? true : false}
    type='button'
  >
    <SVGArrowRightIcon />
  </button>
)
const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
  <button
    {...props}
    className={
      'slick-prev slick-arrow' + (currentSlide === 0 ? ' slick-disabled' : '')
    }
    aria-hidden='true'
    aria-disabled={currentSlide === 0 ? true : false}
    type='button'
  >
    <SVGArrowLeftIcon />
  </button>
)

const carouselSettings = {
  dots: false,
  infinite: false,
  slidesToShow: 6,
  slidesToScroll: 5,
  arrows: false,
  draggable: true,
  variableWidth: true,
  nextArrow: <SlickArrowLeft />,
  prevArrow: <SlickArrowRight />,
  responsive: [
    {
      breakpoint: 1800,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 4,
      },
    },
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 1100,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}

const MessageCenterInterest = (props) => {
  const { conversation } = props

  return !_.isEmpty(conversation.intents) ? (
    <div className='center_message_interests'>
      <SVGInterestIcon />
      <div className='center_message_interests--title'>
        {getText('WORD_INTERESTS')}
      </div>
      <Carousel {...carouselSettings}>
        {conversation.intents.map((obj, index) => {
          return (
            <div key={index} className='info_value'>
              <div className='border_item'>
                {getText(`WORD_${obj.toUpperCase()}`)}
              </div>
            </div>
          )
        })}
      </Carousel>
    </div>
  ) : null
}

export default memo(MessageCenterInterest)
