import React, { useRef, forwardRef, useImperativeHandle } from 'react'
import './InputNumber.scss'

const deviceSize = ['mobile', 'tabletPortrait', 'tablet']

const MatInputNumber = forwardRef((props, ref) => {
  const inputAreaRef = useRef()

  useImperativeHandle(ref, () => ({
    autoFocus() {
      if (!deviceSize.includes(props.screenSize)) {
        inputAreaRef.current && inputAreaRef.current.focus()
      }
    },
  }))

  return (
    <input
      className={`mat-input-number-wrapper mat-custom-input-number-wrapper ${
        props.inputClassName || ''
      }`}
      onChange={props.onChange}
      disabled={props.disabled}
      value={props.value}
      placeholder={props.placeholder ? props.placeholder : props.label}
      maxLength={props.maxLength}
      type='number'
      style={props.style}
    />
  )
})

MatInputNumber.defaultProps = {
  allowClear: true,
  autoComplete: 'off',
  onPressEnter: (e) => {
    e.preventDefault()
  },
  onClear: () => {},
  maxLength: 255,
  minLength: 0,
}

export default MatInputNumber
