import React from 'react'
import SVGNPSScoreGoodIcon from '../../../../icons/SVG/SVGNPSScoreGoodIcon'
import SVGNPSScoreBadIcon from '../../../../icons/SVG/SVGNPSScoreBadIcon'
import './MessageNPSScore.scss'

const MessageNPSScoreRate = ({ conversation }) => {
  const getColor = (perc) => {
    let percentage = perc - 4
    switch (percentage) {
      case -4:
        return '#ee7c6b'
      case 6:
        return '#ef8767'
      case 16:
        return '#f1975f'
      case 26:
        return '#f2a957'
      case 36:
        return '#f4b94f'
      case 46:
        return '#f6cb48'
      case 56:
        return '#ddcc52'
      case 66:
        return '#bccb60'
      case 76:
        return '#9cca6f'
      case 86:
        return '#7ac87d'
      case 96:
        return '#5fc68a'
      default:
        break
    }
  }

  const percentageNPS =
    conversation.invitation_items &&
    conversation.invitation_items[0] &&
    conversation.invitation_items[0].vote_value
      ? conversation.invitation_items[0].vote_value * 10
      : 0

  // useEffect(() => {
  //   if (percentageNPS > 0) {
  //     const element = document.querySelector(
  //       '.extension-profile-info-wrapper .message_center_panel'
  //     )
  //     if (element) {
  //       element.classList.add('nps-score-for-extension')
  //     }
  //   }
  // }, [percentageNPS])

  return percentageNPS > 0 ? (
    <div className='nps-score-rate-wrapper'>
      <SVGNPSScoreBadIcon />
      <div className='nps-line-gradient'>
        <div className='nps-score-circle-wrapper'>
          <div className='nps-score-circle' />
          <div className='nps-score-circle' />
          <div className='nps-score-circle' />
          <div className='nps-score-circle' />
          <div className='nps-score-circle' />
          <div className='nps-score-circle' />
          <div className='nps-score-circle' />
          <div className='nps-score-circle' />
          <div className='nps-score-circle' />
        </div>
        <div
          className='nps-line-circle-wrapper'
          style={{ left: `calc(${percentageNPS}% - 4%)` }}
        >
          <div
            className='nps-line-circle'
            style={{ backgroundColor: getColor(percentageNPS) }}
          ></div>
        </div>
        <div
          className='nps-line-transparent'
          style={{ width: `calc(100% - ${percentageNPS}%)` }}
        ></div>
      </div>
      <SVGNPSScoreGoodIcon />
    </div>
  ) : null
}

export default MessageNPSScoreRate
