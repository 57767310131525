import React from 'react'
import PropTypes from 'prop-types'

const ScoreItem = ({ text, present, className }) => {
  return (
    <div className='score-item'>
      <div className='title'>
        <div className={`circle ${className}`} />
        {text}
      </div>
      <span>{`${present}%`}</span>
    </div>
  )
}

ScoreItem.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  present: PropTypes.number,
}

export default React.memo(ScoreItem)
