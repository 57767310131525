import { getText } from './lang'

let prefix = 'http'
// let socketPrefix = "ws";
if (['PROD', 'STAGE', 'RELEASE'].includes(process.env.REACT_APP_ENV)) {
  prefix = 'https'
  // socketPrefix = "wss";
}

export const APP_URL = _getAppURL()
export const CONNECT_URL = _getConnectURL()
export const API_URL = `${prefix}://${process.env.REACT_APP_DOMAIN}`

function _getAppURL() {
  let url

  switch (process.env.REACT_APP_ENV) {
    case 'PROD':
      url = `${prefix}://app.matador.ai`
      break
    case 'RELEASE':
      url = `${prefix}://release.matador.ai`
      break
    case 'STAGE':
      url = `${prefix}://stg.matador.ai`
      break
    default:
      url = `${prefix}://localhost:3001`
  }
  return url
}

function _getConnectURL() {
  let url

  switch (process.env.REACT_APP_ENV) {
    case 'PROD':
      url = `${prefix}://chat.matador.ai`
      break
    case 'RELEASE':
      url = `${prefix}://chat.stg.matador.ai`
      break
    case 'STAGE':
      url = `${prefix}://chat.stg.matador.ai`
      break
    default:
      url = 'http://localhost:3001'
  }
  return url
}

// Broadcasting page root configs

export const BROADCASTING_TIME_PICKER = {
  START_HOURS: 9,
  END_HOURS: 16,
  INTERVAL_TIME: 15,
}

export const BROADCASTING_SUB_PAGES = (lang) => [
  { title: getText('WORD_CAMPAIGN', lang), description: '', stepNumber: 1 },
  { title: getText('WORD_CONTENT', lang), description: '', stepNumber: 2 },
  { title: getText('WORD_WORKFLOWS', lang), description: '', stepNumber: 3 },
  { title: getText('WORD_SCHEDULER', lang), description: '', stepNumber: 4 },
  { title: getText('WORD_CONDITIONS', lang), description: '', stepNumber: 5 },
]
