import React from 'react'
import Icon from '@ant-design/icons'
import { MainColor } from '../../utils'

const SVGIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={props.width || '10.175'}
    height={props.height || '10.175'}
    viewBox='0 0 10.175 10.175'
  >
    <g id='noun_edit_1751206' transform='translate(-577.581 -275.995)'>
      <g id='Group_17' data-name='Group 17' transform='translate(577.581 275.995)'>
        <path
          id='Path_19'
          data-name='Path 19'
          d='M5.506,9.976l.3-.3L3.11,6.989,9.166.933a1.427,1.427,0,0,1,2.019,0l.673.673a1.427,1.427,0,0,1,0,2.019Zm-.381.374a1.427,1.427,0,0,1-.924.34H2.338a.238.238,0,0,1-.238-.238V8.589a1.427,1.427,0,0,1,.34-.924Z'
          transform='translate(-2.101 -0.515)'
          fill={props.color || MainColor}
          fillRule='evenodd'
        />
      </g>
    </g>
  </svg>
)

const SVGEdit = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGEdit
