import React, { useState } from 'react'
import { Tooltip } from 'antd'
import { getText } from '../../../../lang'
import {
  CANCELED,
  FINISHED,
  getDiffMinutesForBroadcast,
} from '../broadcastListUtils'
import SVGEditNew from '../../../../icons/SVG/SVGEditNew'
import BroadcastMessageEditModal from '../../BroadcastMessageEditModal'

const BroadcastListMobiletemMessage = ({
  status,
  action_time,
  canceled,
  message,
  excelDoc,
  _id,
  setListData,
  media,
}) => {
  const [showMore, setShowMore] = useState(false)

  return (
    <>
      <div className='info-item-row'>
        <span className='info-item-mobile-title'>
          {getText('WORD_BROADCAST_MESSAGE')}
        </span>
        {status === FINISHED ||
        status === CANCELED ||
        getDiffMinutesForBroadcast(action_time) <= 5 ? (
          <Tooltip
            zIndex={10000}
            title={getText(
              canceled
                ? 'NTF_YOU_CANT_EDIT_CANCELED_CAMPAIGN'
                : 'TEXT_YOU_CAN_EDIT_CAMPAIGN_ONLY_MINUTES_BEFORE_START'
            )}
          >
            <SVGEditNew height={16} width={16} style={{ cursor: 'not-allowed' }} />
          </Tooltip>
        ) : (
          <BroadcastMessageEditModal
            message={message}
            hasExcel={excelDoc}
            itemId={_id}
            trigger={
              <SVGEditNew height={16} width={16} style={{ cursor: 'pointer' }} />
            }
            onClose={(updatedMessage) => {
              setListData((oldList) => {
                const find = oldList.find((item1) => item1._id === _id)
                if (Boolean(find)) {
                  find.message = updatedMessage
                }
                return [...oldList]
              })
            }}
          />
        )}
      </div>
      <div className='broadcast-space-border-bottom'>
        <div
          className={
            showMore
              ? 'broadcast-more-info-message-more broadcast-info-message-text'
              : 'broadcast-more-info-message-text broadcast-info-message-text'
          }
        >
          {message}
          {showMore && media && media.length > 0 && (
            <div className='broadcast-modal-item-media-image'>
              {<img src={media[0].mediaUrl} alt={media[0].mediaUrl} />}
            </div>
          )}
        </div>
        {message.length >= 60 ? (
          <div
            className='broadcast-more-info-message-show-more'
            onClick={() => {
              setShowMore(!showMore)
            }}
          >
            {showMore ? getText('ACTION_SHOW_LESS') : getText('ACTION_SHOW_MORE')}
          </div>
        ) : null}
      </div>
    </>
  )
}

export default BroadcastListMobiletemMessage
