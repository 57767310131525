import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width='27'
    height='24'
    viewBox='0 0 27 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M19.8 4H7.8C6.2536 4 5 5.2536 5 6.8V18.8C5 20.3464 6.2536 21.6 7.8 21.6H19.8C21.3464 21.6 22.6 20.3464 22.6 18.8V6.8C22.6 5.2536 21.3464 4 19.8 4Z'
      fill='white'
      stroke='#EFEFF3'
      strokeLinecap='round'
      strokeDasharray='32 40'
    />
    <g clipPath='url(#clip0_3600_2734)'>
      <path
        d='M13 2.32129L9.90137 4.35077V8.77869H12.076C12.3136 8.7807 12.5427 8.70047 12.7151 8.5548C12.8876 8.40917 12.9897 8.20959 13 7.99813V2.32129Z'
        fill='#4CAF50'
      />
      <path
        d='M0 2.32129L3.09067 4.36496V8.78576H0.924016C0.68511 8.78777 0.454791 8.70651 0.282173 8.55939C0.109555 8.41221 0.00829978 8.21083 0 7.99813V2.32129Z'
        fill='#1E88E5'
      />
      <path
        d='M9.90141 0.973145L6.50008 3.2084L3.09875 0.973145V4.35795L6.50008 6.5932L9.90141 4.35795V0.973145Z'
        fill='#E53935'
      />
      <path
        d='M0 1.27102V2.32123L3.09067 4.36491V0.972982L2.12683 0.341433C1.8966 0.188893 1.61719 0.106751 1.33027 0.107264C1.157 0.10539 0.98502 0.134057 0.824239 0.191614C0.663453 0.249171 0.517029 0.334483 0.393396 0.442642C0.269763 0.550801 0.171358 0.679668 0.103844 0.821834C0.0363288 0.963999 0.00103742 1.11665 0 1.27102Z'
        fill='#C62828'
      />
      <path
        d='M13 1.27101V2.32123L9.90137 4.35068V0.972982L10.8732 0.341433C11.1034 0.188893 11.3828 0.106751 11.6698 0.107264C11.843 0.10539 12.015 0.134057 12.1758 0.191614C12.3366 0.249171 12.483 0.334483 12.6066 0.442642C12.7303 0.550801 12.8286 0.679668 12.8962 0.821834C12.9637 0.963999 12.999 1.11665 13 1.27101Z'
        fill='#FBC02D'
      />
    </g>
    <g clipPath='url(#clip1_3600_2734)'>
      <path
        d='M18.7541 10.3257L18.6826 10.3689C18.5688 10.4377 18.4617 10.5146 18.3617 10.5987L18.5679 10.4536H20.3182L20.6364 12.8629L19.0454 14.4538L17.4545 15.5596V16.8347C17.4545 17.7253 17.92 18.5512 18.6819 19.0123L20.3567 20.0261L16.8182 22.0902H16.1357L14.8637 21.3203C14.1018 20.8591 13.6364 20.0333 13.6364 19.1427V14.8549C13.6364 13.964 14.1021 13.138 14.8644 12.6769L18.6826 10.3674C18.7062 10.3531 18.7301 10.3392 18.7541 10.3257Z'
        fill='url(#paint0_radial_3600_2734)'
      />
      <path
        d='M18.7541 10.3257L18.6826 10.3689C18.5688 10.4377 18.4617 10.5146 18.3617 10.5987L18.5679 10.4536H20.3182L20.6364 12.8629L19.0454 14.4538L17.4545 15.5596V16.8347C17.4545 17.7253 17.92 18.5512 18.6819 19.0123L20.3567 20.0261L16.8182 22.0902H16.1357L14.8637 21.3203C14.1018 20.8591 13.6364 20.0333 13.6364 19.1427V14.8549C13.6364 13.964 14.1021 13.138 14.8644 12.6769L18.6826 10.3674C18.7062 10.3531 18.7301 10.3392 18.7541 10.3257Z'
        fill='url(#paint1_linear_3600_2734)'
      />
      <path
        d='M22.5454 15.4084V16.834C22.5454 17.7246 22.0799 18.5504 21.318 19.0116L17.4999 21.3227C16.7188 21.7955 15.747 21.8126 14.9521 21.3739L18.6818 23.6315C19.4921 24.122 20.5077 24.122 21.318 23.6315L25.1362 21.3204C25.8981 20.8592 26.3635 20.0333 26.3635 19.1428V18.113L26.0454 17.6357L22.5454 15.4084Z'
        fill='url(#paint2_radial_3600_2734)'
      />
      <path
        d='M22.5454 15.4084V16.834C22.5454 17.7246 22.0799 18.5504 21.318 19.0116L17.4999 21.3227C16.7188 21.7955 15.747 21.8126 14.9521 21.3739L18.6818 23.6315C19.4921 24.122 20.5077 24.122 21.318 23.6315L25.1362 21.3204C25.8981 20.8592 26.3635 20.0333 26.3635 19.1428V18.113L26.0454 17.6357L22.5454 15.4084Z'
        fill='url(#paint3_linear_3600_2734)'
      />
      <path
        d='M25.1357 12.677L21.3175 10.3674C20.5326 9.8927 19.5553 9.87799 18.7585 10.3233L18.6826 10.3692C17.9203 10.8303 17.4546 11.6563 17.4546 12.5472V15.5648L18.6826 14.822C19.4926 14.3321 20.5075 14.3321 21.3175 14.822L25.1357 17.1315C25.8741 17.5782 26.3343 18.3674 26.3623 19.2262C26.3632 19.1984 26.3637 19.1707 26.3637 19.1428V14.855C26.3637 13.9641 25.8979 13.1381 25.1357 12.677Z'
        fill='url(#paint4_radial_3600_2734)'
      />
      <path
        d='M25.1357 12.677L21.3175 10.3674C20.5326 9.8927 19.5553 9.87799 18.7585 10.3233L18.6826 10.3692C17.9203 10.8303 17.4546 11.6563 17.4546 12.5472V15.5648L18.6826 14.822C19.4926 14.3321 20.5075 14.3321 21.3175 14.822L25.1357 17.1315C25.8741 17.5782 26.3343 18.3674 26.3623 19.2262C26.3632 19.1984 26.3637 19.1707 26.3637 19.1428V14.855C26.3637 13.9641 25.8979 13.1381 25.1357 12.677Z'
        fill='url(#paint5_linear_3600_2734)'
      />
    </g>
    <defs>
      <radialGradient
        id='paint0_radial_3600_2734'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(17.9059 12.7478) rotate(110.528) scale(10.6164 18.5171)'
      >
        <stop offset='0.06441' stopColor='#AE7FE2' />
        <stop offset='1' stopColor='#0078D4' />
      </radialGradient>
      <linearGradient
        id='paint1_linear_3600_2734'
        x1='17.9356'
        y1='21.412'
        x2='16.4209'
        y2='18.7921'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#114A8B' />
        <stop offset='1' stopColor='#0078D4' stopOpacity='0' />
      </linearGradient>
      <radialGradient
        id='paint2_radial_3600_2734'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(15.6822 20.9293) rotate(-8.36717) scale(9.87964 6.52616)'
      >
        <stop offset='0.133928' stopColor='#D59DFF' />
        <stop offset='1' stopColor='#5E438F' />
      </radialGradient>
      <linearGradient
        id='paint3_linear_3600_2734'
        x1='25.2043'
        y1='17.4374'
        x2='23.5811'
        y2='19.7649'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#493474' />
        <stop offset='1' stopColor='#8C66BA' stopOpacity='0' />
      </linearGradient>
      <radialGradient
        id='paint4_radial_3600_2734'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(25.4267 17.7961) rotate(-165.772) scale(7.92998 13.3494)'
      >
        <stop offset='0.0584996' stopColor='#50E6FF' />
        <stop offset='1' stopColor='#436DCD' />
      </radialGradient>
      <linearGradient
        id='paint5_linear_3600_2734'
        x1='17.7651'
        y1='10.3356'
        x2='20.1549'
        y2='10.3356'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#2D3F80' />
        <stop offset='1' stopColor='#436DCD' stopOpacity='0' />
      </linearGradient>
      <clipPath id='clip0_3600_2734'>
        <rect width='13' height='9' fill='white' />
      </clipPath>
      <clipPath id='clip1_3600_2734'>
        <rect width='14' height='14' fill='white' transform='translate(13 10)' />
      </clipPath>
    </defs>
  </svg>
)

const SVGIntegrationTouchpointsMail = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGIntegrationTouchpointsMail
