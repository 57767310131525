import React from 'react'
import { Radio } from 'antd'
import './MatRadioToggle.scss'

function MatRadioToggle(props) {
  const { value, radioOptions, type, buttonStyle, size, disabled } = props
  return (
    <Radio.Group
      className={`mat-radio-toggle ${props.className || ''}`}
      onChange={(e) => {
        props.onChange && props.onChange(e.target.value)
      }}
      value={value}
      optionType={type}
      buttonStyle={buttonStyle}
      size={size}
      disabled={disabled}
    >
      {radioOptions.map((option) => (
        <Radio.Button value={option.value} key={option.value}>
          {option.label}
        </Radio.Button>
      ))}
    </Radio.Group>
  )
}

export default MatRadioToggle
