import React, { useContext, useEffect, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import authActions from '../store/modules/authActions'
import { SharedPusher, NativePusher } from './Pusher'
import { getMobileOperatingSystem } from '.'

const pusherConfig = {
  appKey: process.env.REACT_APP_PUSHER_APP_KEY,
  options: {
    cluster: process.env.REACT_APP_PUSHER_CLUSTER,
    authEndpoint: process.env.REACT_APP_PUSHER_AUTH_ENDPOINT,
    channelAuthorization: {
      endpoint: process.env.REACT_APP_PUSHER_AUTH_ENDPOINT,
    },
    userAuthentication: {
      endpoint: process.env.REACT_APP_PUSHER_AUTH_ENDPOINT,
    },
    // The following commented out parameters is for use with soketi in dev
    // wsHost: '127.0.0.1',
    // httpHost: '127.0.0.1',
    // httpPort: 6001,
    // wsPort: 6001,
    // useTLS: false,
    // forceTLS: false,
    // disableStats: true,
    // enabledTransports: ['ws'],
  },
}

let pusherWorker
if (
  getMobileOperatingSystem() === 'unknown' &&
  window.SharedWorker &&
  !window.platform
) {
  pusherWorker = new window.SharedWorker(
    `/pusher-worker.js?pusherConfig=${encodeURIComponent(
      JSON.stringify(pusherConfig)
    )}`
  )
  pusherWorker.port.start()
}

export const SocketContext = React.createContext(null)

export const SocketProvider = ({ children, value, ...props }) => {
  const accessToken = useSelector((state) =>
    state.authReducer.accessToken ? state.authReducer.accessToken : {}
  )
  const isAuthenticated = useMemo(
    () => Object.keys(accessToken).length !== 0,
    [accessToken]
  )

  const socket = useMemo(() => {
    if (value) return value
    let pusher

    if (pusherWorker) pusher = new SharedPusher(pusherWorker.port)
    else pusher = new NativePusher(pusherConfig)

    return pusher
  }, [])

  useEffect(() => {
    if (isAuthenticated) socket.start()
    else socket.pause()
  }, [isAuthenticated])

  useEffect(() => socket.setToken(accessToken), [accessToken.accessToken])

  return (
    <SocketContext.Provider value={socket} {...props}>
      {children}
    </SocketContext.Provider>
  )
}

const normalizeEvents = (events) => {
  if (typeof events === 'string') {
    return [events.trim()]
  }

  if (Array.isArray(events) && events.every((event) => typeof event === 'string'))
    return events
      .map((event) => event.trim())
      .reduce((events, event) => {
        if (!events.includes(event)) events.push(event)
        return events
      }, [])

  console.error('Invalid event or events', events)
  return []
}

export const useSocket = (channelName, events, callback, shouldListen = true) => {
  const socket = useContext(SocketContext)

  const _channelName = useMemo(() => (channelName || '').trim(), [channelName])
  const _events = useMemo(
    () => normalizeEvents(events),
    [Array.isArray(events) ? events.join(',') : events]
  )
  const [channel, setChannel] = useState(null)

  const isLoggedIn = useSelector((store) => !!store.authReducer.accessToken)

  useEffect(() => {
    if (!socket || !_channelName || !shouldListen || !_events.length || !isLoggedIn)
      return
    setChannel(socket.subscribe(_channelName))
    return () => {
      setChannel(null)
      socket.unsubscribe(_channelName)
    }
  }, [_channelName, isLoggedIn])

  useEffect(() => {
    if (!socket || !channel || !_events.length || !callback) return

    let cb = Array.isArray(events)
      ? (event) => (data) => {
          authActions.setSelfActionTime(data.actionTimestamp)
          callback(event, data)
        }
      : (event) => (data) => {
          authActions.setSelfActionTime(data.actionTimestamp)
          callback(data)
        }

    const callbacks = _events.map((event) => [event, cb(event)])
    for (const [event, callback] of callbacks) {
      channel.bind(event, callback)
    }

    return () => {
      for (const [event, callback] of callbacks) {
        channel.unbind(event, callback)
      }
    }
  }, [channel, _events, callback])

  return useMemo(
    () => ({
      onConnected: (cb) => !!socket && socket.on('pusher_connected', cb),
      onDisconnected: (cb) => !!socket && socket.on('pusher_disconnected', cb),
      allChannels: () => !!socket && socket.allChannels(),
    }),
    [socket]
  )
}
