import React, { useEffect, useState } from 'react'
import { Tooltip } from 'antd'
import { useSelector } from 'react-redux'
import MatBlockWithLabel from '../../../../components/MatBlockWithLabel'
import MatRow from '../../../../components/MatRow'
import MatButton from '../../../../components/MatButton'
import { getText } from '../../../../lang'
import authActions from '../../../../store/modules/authActions'
import MicrosoftAuthAPI from '../../../../components/api/MicrosoftAuth'
import GoogleAuth from '../../../../components/api/GoogleAuth'
import locationActions from '../../../../store/modules/locationActions'
import { notifySuccess } from '../../../../utils/Notify'
import { getGoogleWarningData } from '../../components/locationUtils'
import FeaturesConnectingData from '../../components/FeaturesConnectingData'

const ConnectGmailOrOffice365 = ({ locationObj }) => {
  const [otherIntegrationsData, setOtherIntegrationsData] = useState([])
  const [bookingLoginType, setBookingLoginType] = useState({
    googleLogged: false,
    microsoftLogged: false,
  })

  const user = useSelector((state) => {
    return state.authReducer.user
  })

  const checkConectedAccount = async () => {
    let resultLoginType = await locationActions.checkLoginForInvitationBooking(
      locationObj._id
    )
    if (resultLoginType.success) {
      setBookingLoginType(resultLoginType.data)
    }
  }

  useEffect(() => {
    if (locationObj._id) {
      checkConectedAccount()
    }
  }, [locationObj._id])

  const getConnectedEmail = () => {
    return (
      <>
        {bookingLoginType && bookingLoginType.appointmentEmail && (
          <div className='footer-connected-account'>
            <span>{getText('TEXT_LOGGED_ACCOUNT')}:</span>
            <span>{bookingLoginType.appointmentEmail}</span>
          </div>
        )}
        <FeaturesConnectingData
          featuresConnectingData={
            locationObj.featuresConnectingData &&
            locationObj.featuresConnectingData.calendarInvite
          }
        />
      </>
    )
  }

  return (
    <MatBlockWithLabel
      className='integration-item-footer-item touchpoint-integration'
      black
    >
      {getConnectedEmail()}
      <MatRow className='touchpoint-integration-buttons'>
        {!Boolean(user.isSuperAdmin && user.isAdmin) &&
        bookingLoginType.googleLogged ? (
          <Tooltip
            placement='top'
            title={getText('TEXT_PLEASE_CONTACT_YOUR_ADMINISTRATOR')}
          >
            <MatButton
              htmlType={'button'}
              buttonText={getText('TEXT_DISCONNECT_YOUR_GMAIL_ACCOUNT')}
              style={{ cursor: 'not-allowed' }}
            />
          </Tooltip>
        ) : (
          <>
            <GoogleAuth
              responseGoogle={async (data) => {
                let result = await authActions.authGoogle(data.code, locationObj._id)
                if (result.success) {
                  locationObj.featuresConnectingData.calendarInvite.isConnected = true
                  locationObj.featuresConnectingData.calendarInvite.userData.fullName =
                    user.fullName
                  checkConectedAccount()
                  notifySuccess(
                    getText('TEXT_APPOINTMENTS_EMAIL_WAS_SUCCESSFULLY_CONNECTED')
                  )
                }
              }}
              responseGoogleLogout={async (data) => {
                let result = await authActions.authLogOutGoogle(locationObj._id)
                locationObj.featuresConnectingData.calendarInvite.isConnected = false
                locationObj.featuresConnectingData.calendarInvite.userData.fullName =
                  user.fullName
                if (result.success) {
                  checkConectedAccount()
                  notifySuccess(
                    getText('TEXT_APPOINTMENTS_EMAIL_WAS_SUCCESSFULLY_DISCONNECTED')
                  )
                }
              }}
              disabled={bookingLoginType.microsoftLogged}
              isConnected={bookingLoginType.googleLogged}
              warning={otherIntegrationsData.length}
              warningText={getGoogleWarningData(otherIntegrationsData)}
              onVisibleChange={async (value) => {
                if (value) {
                  const result = await authActions.authGetGoogleIntegrations(
                    locationObj._id
                  )
                  if (result.success) {
                    setOtherIntegrationsData(result.data.otherIntegrationsData)
                  }
                }
              }}
              typeButton={'primary'}
            />
          </>
        )}
        {!Boolean(user.isSuperAdmin && user.isAdmin) &&
        bookingLoginType.microsoftLogged ? (
          <Tooltip
            placement='top'
            title={getText('TEXT_PLEASE_CONTACT_YOUR_ADMINISTRATOR')}
          >
            <MatButton
              htmlType={'button'}
              buttonText={getText('TEXT_DISCONNECT_OFFICE_365')}
              style={{ cursor: 'not-allowed' }}
            />
          </Tooltip>
        ) : (
          <MicrosoftAuthAPI
            idLocation={locationObj._id}
            disabled={bookingLoginType.googleLogged}
            isConnected={bookingLoginType.microsoftLogged}
            onSuccess={(value) => {
              locationObj.featuresConnectingData.calendarInvite.userData.fullName =
                user.fullName
              if (value === 'login') {
                locationObj.featuresConnectingData.calendarInvite.isConnected = true
              } else {
                locationObj.featuresConnectingData.calendarInvite.isConnected = false
              }
              checkConectedAccount()
            }}
            typeButton={'primary'}
          />
        )}
      </MatRow>
    </MatBlockWithLabel>
  )
}

export default ConnectGmailOrOffice365
