import React, { useCallback } from 'react'
import { Form, Select } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { getText } from '../../../lang'
import './SelectChecked.scss'

const SelectCheckedSecondary = (props) => {
  const rules = useCallback(() => {
    const rules = []
    if (props.required && !props.disabled) {
      rules.push({ required: props.required, message: props.message })
    }
    return rules
  }, [props.required, props.disabled])

  return (
    <Form.Item
      className={`mat-form-item mat-form-item-black ${props.formClassName || ''}`}
      label={props.label}
      name={props.name}
      hasFeedback={props.hasFeedback}
      rules={rules()}
      style={props.formStyle}
      hidden={props.hidden}
    >
      <Select
        placeholder={props.placeholder || props.label || 'Select a option '}
        dropdownStyle={props.dropdownStyle}
        mode={props.mode}
        className={`mat-select mat-select-secondary ${props.className || ''} ${
          props.multipleNewStyle ? 'mat-multiple-select' : ''
        }`}
        defaultValue={props.defaultValue}
        popupClassName={`mat-form-select-dropdown ${
          props.primary ? ' primary ' : ' '
        } ${props.dropdownClassName || ''}`}
        loading={props.loading}
        disabled={props.disabled}
        onSearch={props.onSearch}
        optionLabelProp='label'
        showSearch={props.showSearch}
        suffixIcon={props.showArrow || <DownOutlined />}
        allowClear={props.allowClear}
        onClear={props.onClear}
        labelInValue={props.labelInValue}
        getPopupContainer={
          props.getPopupContainer
            ? props.getPopupContainer
            : (trigger) => trigger.parentNode
        }
        onDropdownVisibleChange={props.onDropdownVisibleChange}
        filterOption={
          props.hasOwnProperty('filterOption')
            ? props.filterOption
            : (input, option) => {
                return (
                  ((option && option.label) || '')
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toString().toLowerCase()) > -1
                )
              }
        }
        onChange={(e) => {
          props.onChange && props.onChange(e)
          if (props.onChangeItem && props.option) {
            if (props.mode === 'multiple') {
              let find = props.option.filter((oo) => {
                return e.includes(oo.value)
              })
              if (find) {
                props.onChangeItem(e, find)
              }
            } else {
              let find = props.option.find((oo) => {
                return oo.value === e
              })
              if (find) {
                props.onChangeItem(e, find)
              }
            }
          }
        }}
        maxTagCount={props.maxTagCount}
        onSelect={(e, ee) => {
          props.onSelect && props.onSelect(e, ee)
          if (props.onSelectItem && props.option) {
            if (props.mode === 'multiple') {
              let find = props.option.filter((oo) => {
                if (props.labelInValue) {
                  return e.value === oo.value
                }
                return e.includes(oo.value)
              })
              if (find) {
                props.onSelectItem(e, find)
              }
            } else {
              let find = props.option.find((oo) => {
                if (props.labelInValue) {
                  return oo.value === e.value
                }
                return oo.value === e
              })
              if (find) {
                props.onSelectItem && props.onSelectItem(e, find)
              }
            }
          }
        }}
        onPopupScroll={(e) => {
          e.persist()
          props.onPopupScroll && props.onPopupScroll(e)
          if (
            Math.ceil(e.target.scrollTop + e.target.offsetHeight) ===
            e.target.scrollHeight
          ) {
            props.onLoadMore && props.onLoadMore()
          }
        }}
        onBlur={props.onBlur}
      >
        {props.option.map((option, index) => {
          return (
            <Select.Option
              key={option.value + '_' + index}
              value={option.value}
              label={option.label}
            >
              {' '}
              {option.label}{' '}
            </Select.Option>
          )
        })}
      </Select>
    </Form.Item>
  )
}

SelectCheckedSecondary.defaultProps = {
  name: 'select',
  label: getText('WORD_SELECT'),
  placeholder: getText('WORD_SELECT_ITEM'),
  message: `${getText('WORD_PLEASE_SELECT_ITEM')}!`,
  required: false,
  allowClear: true,
  mode: '',
}

export default SelectCheckedSecondary
