import * as React from "react"

function SVGChatCheck(props) {
  return (
    <svg width={12} height={9.429} viewBox="0 0 12 9.429" {...props}>
      <g data-name="Group 487">
        <path
          data-name="Path 747"
          d="M10.38.003l-.81.826c-1.81 1.849-3.463 3.661-5.2 5.47l-2.044-1.67-.894-.729-1.43 1.821.894.729 2.86 2.331.8.65.727-.741c2.057-2.1 3.9-4.164 5.911-6.217l.81-.826L10.38.003z"
          fill={props.color ? props.color : "#ffffff"}
        />
      </g>
    </svg>
  )
}

export default SVGChatCheck