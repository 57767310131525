import React from 'react'
import { getText } from '../../lang'
import { formatDate, formatTime } from '../../utils'
import SVGLastLoginIcon from '../../icons/SVG/SVGLastLoginIcon'
import SVGEmailNew from '../../icons/SVG/SVGEmailNew'
import SVGPhone from '../../icons/SVG/SVGPhone'
import SVGRole from '../../icons/SVG/SVGRole'
import SVGEditNew from '../../icons/SVG/SVGEditNew'
import SVGInfoUser from '../../icons/SVG/SVGInfoUser'
import ProfileLetters from '../../components/ProfileLetters'
import MatRow from '../../components/MatRow'
import FieldItem from './FieldItem'

const UsersListItem = (props) => {
  const { userItem, openModal } = props

  const getUserType = () => {
    if (userItem.isAdmin && !userItem.isSuperAdmin) {
      return getText('WORD_ROLE_ADMIN')
    } else if (userItem.isSuperAdmin) {
      return getText('WORD_ROLE_SUPER_ADMIN')
    } else if (userItem.isManager) {
      return getText('WORD_ROLE_MANAGER')
    } else if (userItem.isSupportAgent) {
      return getText('WORD_ROLE_SUPPORT')
    }
  }

  return (
    <div className='user-list-item'>
      <MatRow className='user-list-item-header'>
        <ProfileLetters name={userItem.fullName} withoutIcon medium />
        <MatRow className='user-name-edit-row' flexStart>
          <MatRow>
            <div className='username-more-info-btn'>
              <div className='user-list-fullName'>{userItem.fullName}</div>
              <div
                onClick={() => openModal('show', userItem)}
                className='user-more-info-btn'
              >
                <SVGInfoUser />
              </div>
            </div>
            <div
              className='user-list-edit'
              onClick={() => openModal('edit', userItem)}
            >
              <SVGEditNew />
            </div>
          </MatRow>
        </MatRow>
      </MatRow>
      <FieldItem
        icon={<SVGEmailNew />}
        leftText={getText('WORD_EMAIL')}
        rightText={userItem.email}
      />
      <FieldItem
        icon={<SVGPhone />}
        leftText={getText('WORD_PHONE')}
        rightText={userItem.phone}
      />
      <FieldItem
        icon={<SVGRole />}
        leftText={getText('WORD_ROLE')}
        rightText={getUserType()}
      />
      <FieldItem
        icon={<SVGLastLoginIcon />}
        leftText={getText('TEXT_LAST_LOGIN_DATE')}
        style={{ borderBottom: 'none', paddingBottom: '0' }}
        rightText={
          userItem.lastLoginDate ? (
            <>
              {formatDate(userItem.lastLoginDate)}{' '}
              {formatTime(userItem.lastLoginDate)}
            </>
          ) : null
        }
      />
    </div>
  )
}

export default UsersListItem
