import React, { useEffect, useState } from 'react'
import { Image, Space } from 'antd'
import {
  RotateLeftOutlined,
  RotateRightOutlined,
  SwapOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
  DownloadOutlined,
} from '@ant-design/icons'
import './MatImage.scss'
import SVGClearCircle from '../../icons/SVG/SVGClearCircle'

const MatImage = ({
  url,
  flipY,
  flipX,
  rotateLeft,
  rotateRight,
  zoomOut,
  zoomIn,
  download,
  style,
  removeImage,
  className,
}) => {
  const [visible, setVisible] = useState(false)

  const onDownload = () => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.download = 'image.png'
        document.body.appendChild(link)
        link.click()
        URL.revokeObjectURL(url)
        link.remove()
      })
  }

  const messageFromWebView = async (e) => {
    if (JSON.parse(e.data).type === 'backswipe') {
      setVisible(false)
    }
  }

  useEffect(() => {
    if (window.platform === 'android') {
      document.addEventListener('message', messageFromWebView)
    } else if (window.platform === 'ios') {
      window.addEventListener('message', messageFromWebView)
    }
  }, [])

  return (
    <div className={`single-image-with-remove ${className || ''}`}>
      <Image
        className='single-img'
        src={url}
        style={style}
        preview={{
          visible: visible,
          onVisibleChange: (value) => {
            setVisible(value)
          },
          toolbarRender: (
            _,
            {
              transform: { scale },
              actions: {
                onFlipY,
                onFlipX,
                onRotateLeft,
                onRotateRight,
                onZoomOut,
                onZoomIn,
              },
            }
          ) => (
            <Space size={12} className='toolbar-wrapper'>
              {download && <DownloadOutlined onClick={onDownload} />}
              {flipY && <SwapOutlined rotate={90} onClick={onFlipY} />}
              {flipX && <SwapOutlined onClick={onFlipX} />}
              {rotateLeft && <RotateLeftOutlined onClick={onRotateLeft} />}
              {rotateRight && <RotateRightOutlined onClick={onRotateRight} />}
              {zoomOut && (
                <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
              )}
              {zoomIn && (
                <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
              )}
            </Space>
          ),
        }}
      />
      {removeImage && (
        <div className='img-close' onClick={removeImage}>
          <SVGClearCircle />
        </div>
      )}
    </div>
  )
}

export default MatImage
