import React from 'react'
import { Button } from 'antd'

const LoginDownloadInButton = (props) => {
  const { icon, label, href } = props

  return (
    <Button
      className='login-page-download-in-btn'
      icon={icon}
      href={href}
      target='_blank'
    >
      {label}
    </Button>
  )
}

export default LoginDownloadInButton
