import React, { useState } from 'react'
import { Tooltip } from 'antd'
import { useSelector } from 'react-redux'
import customerActions from '../../../../store/modules/customerActions'
import { notifyError, notifySuccess } from '../../../../utils/Notify'
import MatSwitchSecondary from '../../../../components/MatSwitch/MatSwitchSecondary'
import { getText } from '../../../../lang'
import SVGAiBotSmsIcon from '../../../../icons/SVG/SVGAiBotSmsIcon'
import SVGAiBotEmailcon from '../../../../icons/SVG/SVGAiBotEmailcon'
import SVGChecked from '../../../../icons/SVG/SVGChecked'
import SVGAiBotInstalcon from '../../../../icons/SVG/SVGAiBotInstalcon'
import SVGAiBotBusinesslcon from '../../../../icons/SVG/SVGAiBotBusinesslcon'
import SVGAiBotFacebooklcon from '../../../../icons/SVG/SVGAiBotFacebooklcon'
import SVGSuperhuman from '../../../../icons/SVG/SVGSuperhuman'
import SVGFollowUpIcon from '../../../../icons/SVG/SVGFollowUpIcon'
import './MessageDripSequencePanel.scss'
import SVGDrawerSequence from '../../../../icons/SVG/SVGDrawerSequence'
import { MainColor } from '../../../../utils'
import SuperhumanSequence from './SuperhumanSequence'

const AiBotItem = ({
  enable_auto_reply,
  is_engaged,
  disabled,
  organization,
  handleOnChange,
  type,
  saving,
  hidden,
  icon,
  title,
  color,
  className,
}) => {
  return (
    hidden && (
      <div className={`block_right_and_left_sides ${className || ''}`}>
        <div className='block_right_and_left_sides--left-side'>
          <div
            className={
              `block_right_and_left_sides--left-side-icon ${color} ` +
              (enable_auto_reply ? ' active_bot' : ' ')
            }
          >
            {icon}
          </div>
          <div className='block_right_and_left_sides--left-side-label'>
            <span className={enable_auto_reply ? 'active_bot' : 'not_active'}>
              {title}
            </span>
            {is_engaged && (
              <span className={'is_engaged'}>{getText('WORD_ENGAGED')}</span>
            )}
          </div>
        </div>
        <MatSwitchSecondary
          disabled={
            disabled ||
            (organization && organization.enable_auto_reply
              ? !enable_auto_reply
              : true)
          }
          value={enable_auto_reply}
          onChange={(value) => handleOnChange(value, type)}
          loading={saving}
        />
      </div>
    )
  )
}

const AiBotSwitchPanel = (props) => {
  const { conversation, onSaveReciver, disabled, isNylas } = props

  const organization = useSelector((state) => {
    return state.authReducer.user.organization
  })

  const user = useSelector((state) => {
    return state.authReducer.user
  })

  const [saving, setsaving] = useState(false)
  const [savingsuperhuman, setsavingsuperhuman] = useState(false)
  const [followUpSaving, setFollowUpSaving] = useState(false)

  const receiver = conversation.receiver ? conversation.receiver : {}
  const receiver_id = receiver._id
  const disabledForUsersRoles =
    (user.isManager && organization.restrictions.restrictDisableAIBotManagers) ||
    (user.isSupportAgent &&
      organization.restrictions.restrictDisableAIBotSupportAgents)

  const disabledForUsersSuperhuman =
    (user.isManager &&
      organization.restrictions.restrictEditSuperHumanForManagers) ||
    (user.isSupportAgent &&
      organization.restrictions.restrictEditSuperHumanForSupportAgents)

  const enable_auto_reply_sms = receiver?.autoReply?.sms?.enabled
  const enable_auto_reply_email = receiver?.autoReply?.email?.enabled
  const enable_auto_reply_instagram = receiver?.autoReply?.instagram?.enabled
  const enable_auto_reply_gbm = receiver?.autoReply?.gbm?.enabled
  const enable_auto_reply_facebook = receiver?.autoReply?.facebook?.enabled
  const enable_auto_reply_superhuman = receiver?.superhumanConfig?.enabled
  const enable_follow_up = receiver?.followUpConfig?.enabled

  const is_engaged_sms = conversation?.isNew
    ? receiver?.engaged?.sms?.default
    : receiver?.engaged?.sms
  const is_engaged_email = conversation?.isNew
    ? receiver?.engaged?.email?.default
    : receiver?.engaged?.email
  const is_engaged_instagram = receiver?.engaged?.instagram
  const is_engaged_gbm = receiver?.engaged?.gbm
  const is_engaged_facebook = receiver?.engaged?.facebook

  const handleOnChangeSuperhuman = async (value) => {
    if (Boolean(receiver_id)) {
      setsavingsuperhuman(true)
      const result = await customerActions.setSuperhumanToggle(
        { enabled: value },
        receiver_id
      )
      if (result.success) {
        notifySuccess(
          getText(value ? 'TEXT_SUPERHUMAN_ENABLED' : 'TEXT_SUPERHUMAN_DISABLED')
        )
        onSaveReciver &&
          onSaveReciver(
            Object.assign({}, receiver, { superhumanConfig: { enabled: value } })
          )
      } else {
        notifyError(result.errMsg)
      }
    }
    setsavingsuperhuman(false)
  }

  const handleOnChangeFollowUp = async (value) => {
    if (Boolean(receiver_id)) {
      setFollowUpSaving(true)
      const result = await customerActions.setFollowUpUpdate(
        { enabled: value },
        receiver_id
      )
      if (result.success) {
        notifySuccess(
          getText(value ? 'TEXT_FOLLOW_UP_ENABLED' : 'TEXT_FOLLOW_UP_DISABLED')
        )
        onSaveReciver &&
          onSaveReciver(
            Object.assign({}, receiver, { followUpConfig: { enabled: value } })
          )
      } else {
        notifyError(result.errMsg)
      }
    }
    setFollowUpSaving(false)
  }

  const handleOnChange = async (value, type) => {
    if (Boolean(receiver_id)) {
      if (!value) {
        const sms = type === 'sms' ? !enable_auto_reply_sms : enable_auto_reply_sms
        const email =
          type === 'email' ? !enable_auto_reply_email : enable_auto_reply_email
        const instagram =
          type === 'instagram'
            ? !enable_auto_reply_instagram
            : enable_auto_reply_instagram
        const facebook =
          type === 'facebook'
            ? !enable_auto_reply_facebook
            : enable_auto_reply_facebook
        const obj = {
          sms: { enabled: sms },
          email: { enabled: email },
          instagram: { enabled: instagram },
          facebook: { enabled: facebook },
        }
        setsaving(true)
        const result = await customerActions.setAutoReply(
          { autoReply: obj },
          receiver_id
        )
        setsaving(false)
        if (result.success) {
          if (type === 'sms') {
            notifySuccess(
              `${getText('WORD_DRIP_SEQUENCE_SMS')} ${sms ? getText('WORD_ENABLED') : getText('WORD_DISABLED').toLowerCase()}`
            )
          } else if (type === 'email') {
            notifySuccess(
              `${getText('WORD_DRIP_SEQUENCE_EMAIL')} ${email ? getText('WORD_ENABLED') : getText('WORD_DISABLED').toLowerCase()}`
            )
          } else if (type === 'instagram') {
            notifySuccess(
              `${getText('WORD_DRIP_SEQUENCE_INSTAGRAM')} ${instagram ? getText('WORD_ENABLED') : getText('WORD_DISABLED').toLowerCase()}`
            )
          } else if (type === 'facebook') {
            notifySuccess(
              `${getText('WORD_DRIP_SEQUENCE_FACEBOOK')} ${facebook ? getText('WORD_ENABLED') : getText('WORD_DISABLED').toLowerCase()}`
            )
          }
          onSaveReciver && onSaveReciver(result.data)
        } else {
          notifyError(result.errMsg)
        }
      }
    } else {
      notifyError(getText('ERR_GENERAL'))
    }
  }

  const superhumanChannel = organization?.superhumanConfig?.channels

  const getAiBotItem = () => {
    const obj = {
      icon: <SVGAiBotSmsIcon />,
      title: getText('WORD_DRIP_SEQUENCE_SMS'),
      color: 'green',
      enable_auto_reply: enable_auto_reply_sms,
      is_engaged: is_engaged_sms,
      type: 'sms',
    }
    if (conversation.last_source === 'IG messenger') {
      obj.icon = <SVGAiBotInstalcon />
      obj.title = getText('WORD_IG_MESSENGER')
      obj.color = 'orange'
      obj.enable_auto_reply = enable_auto_reply_instagram
      obj.is_engaged = is_engaged_instagram
      obj.type = 'instagram'
    } else if (conversation.kind === 'gbm') {
      obj.icon = <SVGAiBotBusinesslcon />
      obj.title = getText('WORD_GOOGLE_BUSINESS_UC')
      obj.color = 'blue'
      obj.enable_auto_reply = enable_auto_reply_gbm
      obj.is_engaged = is_engaged_gbm
      obj.type = 'gbm'
    } else if (conversation.last_source === 'FB messenger') {
      obj.icon = <SVGAiBotFacebooklcon />
      obj.title = getText('WORD_FB_MESSENGER')
      obj.color = 'blue'
      obj.enable_auto_reply = enable_auto_reply_facebook
      obj.is_engaged = is_engaged_facebook
      obj.type = 'facebook'
    }
    return obj
  }

  const isAiBotTriggeredEver =
    superhumanChannel &&
    (superhumanChannel?.email?.enabled ||
      superhumanChannel?.facebook?.enabled ||
      superhumanChannel?.gbm?.enabled ||
      superhumanChannel?.instagram?.enabled ||
      superhumanChannel?.sms?.enabled)

  const isAIReplyEnabledSuperHuman = organization?.superhumanConfig?.enabled

  const hasEmail = Boolean(receiver?.email && isNylas?.email_address)

  const hideSuperhumanToggle = () => {
    if (conversation.kind === 'gbm' && !superhumanChannel.gbm?.enabled) {
      return true
    } else if (
      conversation.last_source === 'IG messenger' &&
      !superhumanChannel.instagram?.enabled
    ) {
      return true
    } else if (
      conversation.last_source === 'FB messenger' &&
      !superhumanChannel.facebook?.enabled
    ) {
      return true
    } else if (conversation.kind === 'sms') {
      if (!superhumanChannel.email?.enabled && !superhumanChannel.sms?.enabled) {
        return true
      }
      if (
        hasEmail &&
        !superhumanChannel.email?.enabled &&
        !superhumanChannel.sms?.enabled
      ) {
        return true
      }
      if (!superhumanChannel.sms?.enabled && !hasEmail) {
        return true
      }
    }
    return false
  }

  return (
    <div className='message_right_panel--top-side-item'>
      <>
        <div className='block_right_and_left_sides'>
          <div className='block_right_and_left_sides--left-side'>
            <div className={'block_right_and_left_sides--left-side-icon'}>
              <SVGDrawerSequence color={MainColor} width={16} height={16} />
            </div>
            <div className='block_right_and_left_sides--left-side-label'>
              <span className={'not_active'}>{getText('WORD_SEQUENCES')}</span>
            </div>
          </div>
          {conversation && conversation.lastSequence && (
            <Tooltip placement='topRight' title={conversation.lastSequence.name}>
              <div className='after_hour_sequence_ai_bot'>
                <SVGChecked />
                <span className='title'>{conversation.lastSequence.name}</span>
              </div>
            </Tooltip>
          )}
        </div>
        <AiBotItem
          enable_auto_reply={getAiBotItem().enable_auto_reply}
          is_engaged={getAiBotItem().is_engaged}
          disabled={disabled || disabledForUsersRoles}
          organization={organization}
          handleOnChange={handleOnChange}
          type={getAiBotItem().type}
          saving={saving}
          hidden={true}
          icon={getAiBotItem().icon}
          title={getAiBotItem().title}
          color={getAiBotItem().color}
        />
        <AiBotItem
          enable_auto_reply={enable_auto_reply_email}
          is_engaged={is_engaged_email}
          disabled={disabled || disabledForUsersRoles}
          organization={organization}
          handleOnChange={handleOnChange}
          type={'email'}
          saving={saving}
          hidden={hasEmail}
          icon={<SVGAiBotEmailcon />}
          title={getText('WORD_DRIP_SEQUENCE_EMAIL')}
          color={'orange'}
        />
      </>
      {isAIReplyEnabledSuperHuman && isAiBotTriggeredEver ? (
        <SuperhumanSequence
          className={isAiBotTriggeredEver ? 'ai_bot_superhuman_switch' : ''}
          value={enable_auto_reply_superhuman}
          organization={organization}
          disabled={disabled || disabledForUsersSuperhuman}
          handleOnChange={handleOnChangeSuperhuman}
          saving={savingsuperhuman}
          icon={<SVGSuperhuman />}
          title={getText('TEXT_AI_BOT_SUPERHUMAN')}
          color={'blue'}
          hidden={hideSuperhumanToggle()}
          conversation={conversation}
          user={user}
        />
      ) : null}
      {Boolean(organization?.restrictions?.allowFollowUp) && (
        <SuperhumanSequence
          className={'ai_bot_superhuman_switch'}
          value={enable_follow_up}
          organization={organization}
          disabled={disabled}
          handleOnChange={handleOnChangeFollowUp}
          saving={followUpSaving}
          icon={<SVGFollowUpIcon />}
          title={getText('WORD_AI_FOLLOW_UP')}
          conversation={conversation}
          user={user}
          isFollowUp={true}
        />
      )}
    </div>
  )
}

export default AiBotSwitchPanel
