import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width='14'
    height='14'
    viewBox='0 0 14 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_3248_2000)'>
      <ellipse
        cx='6.99996'
        cy='4.08333'
        rx='0.583333'
        ry='0.583333'
        fill='#A3A1B7'
      />
      <path
        d='M6.41663 5.83335H6.99996V9.91669M12.8333 7.00002C12.8333 10.2217 10.2216 12.8334 6.99996 12.8334C3.7783 12.8334 1.16663 10.2217 1.16663 7.00002C1.16663 3.77836 3.7783 1.16669 6.99996 1.16669C10.2216 1.16669 12.8333 3.77836 12.8333 7.00002Z'
        stroke='#A3A1B7'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_3248_2000'>
        <rect width='14' height='14' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

const SVGInfoUser = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGInfoUser
