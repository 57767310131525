import React, { forwardRef, useRef, useImperativeHandle } from 'react'
import { Empty, Form } from 'antd'
import MatBlockWithLabel from '../../../../components/MatBlockWithLabel'
import tagsActions from '../../../../store/modules/tagsActions'
import { notifyError, notifySuccess } from '../../../../utils/Notify'
import { getText, getTextServerError } from '../../../../lang'
import TagItem from './TagItem'

const AddTagsList = forwardRef((props, ref) => {
  const { form, noData, onInitialValuesUpdate } = props

  const addRef = useRef(null)

  useImperativeHandle(ref, () => ({
    add(obj, insertIndex) {
      addRef.current(obj, insertIndex)
    },
  }))

  const handleDeleteTag = async (_id) => {
    const result = await tagsActions.delete(_id)
    if (result.success) {
      notifySuccess(getText('TEXT_ORGANIZATION_WAS_SAVED_SUCCESSFULLY'))
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
    return result
  }

  return (
    <Form.List name='tags_items'>
      {(fields, { add, remove }) => {
        addRef.current = add
        return (
          <div className='tags_items'>
            <MatBlockWithLabel className='tags-list-items'>
              {fields && fields.length > 0 ? (
                fields.map((field, index) => {
                  return (
                    <TagItem
                      key={field.key}
                      field={field}
                      form={form}
                      onInitialValuesUpdate={onInitialValuesUpdate}
                      onDelete={async (item) => {
                        let success = true
                        if (item._id) {
                          const result = await handleDeleteTag(item._id)
                          success &= result.success
                        }
                        if (success) {
                          remove(index)
                        }
                      }}
                    />
                  )
                })
              ) : !noData ? (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              ) : undefined}
            </MatBlockWithLabel>
          </div>
        )
      }}
    </Form.List>
  )
})

export default AddTagsList
