import React, { memo, useEffect, useState } from 'react'
import SVGCustomerEditIcon from '../../../../icons/SVG/SVGCustomerEditIcon'
import SVGCloseTag from '../../../../icons/SVG/SVGCloseTag'
import { checkFormatPhoneNumber, getCustomerName } from '../../../../utils'
import ProfileLettersCustomers from '../../../../components/ProfileLetters/ProfileLettersCustomers'
import ContactEditModal from './ContactEditModal'
import './MessageProfileInfo.scss'

const MessageProfileInfo = (props) => {
  const { conversation, disabled, onSaveReciver, screenSize, onCloseDrawer } = props

  const [receiver, setReceiver] = useState(null)
  const [receiverPhone, setreceiverPhone] = useState('')
  const [receiverName, setreceiverName] = useState('')
  const [receiverEmail, setreceiverEmail] = useState('')
  const [authorName, setauthorName] = useState('')

  useEffect(() => {
    if (conversation) {
      setReceiver({ ...conversation.receiver })
    }
  }, [conversation, conversation.receiver, conversation.receiver?.fullName])

  useEffect(() => {
    if (receiver) {
      let phoneNumber = checkFormatPhoneNumber(receiver.phone)
      setreceiverPhone(phoneNumber)
      setreceiverName(receiver.fullName)
      setreceiverEmail(receiver.email)
    } else {
      setreceiverPhone('')
      setreceiverName('')
      setreceiverEmail('')
    }
  }, [receiver])

  useEffect(() => {
    if (receiver) {
      const authorName = getCustomerName(conversation)

      setauthorName(authorName)
    } else {
      setauthorName('')
    }
  }, [receiverName, receiverPhone])

  return (
    <div className='message_right_panel--customer-info'>
      <div className='message_right_panel--customer-info-letters'>
        {authorName && (
          <ProfileLettersCustomers
            name={authorName}
            temperature={
              conversation.temperature ? conversation.temperature.score : ''
            }
          />
        )}
      </div>
      <div className='message_right_panel--customer-info-details'>
        <div className='message_right_panel--customer-info-details-name'>
          <div className='customer_name'>{authorName}</div>
          {receiver && (
            <ContactEditModal
              conversation={conversation}
              disabled={disabled}
              customer={receiver}
              trigger={<SVGCustomerEditIcon />}
              onSuccess={(data) => {
                onSaveReciver && onSaveReciver(data)
              }}
            />
          )}
          {screenSize === 'mobile' ? (
            <SVGCloseTag
              width={12}
              height={12}
              style={{ marginLeft: '10px' }}
              onClick={onCloseDrawer}
            />
          ) : null}
        </div>
        {receiverPhone && (
          <div className='customer_contact'>
            <span>{receiverPhone || '-'}</span>
          </div>
        )}
        {receiverEmail && (
          <div className='customer_contact'>
            <span>
              <span>{receiverEmail}</span>
            </span>
          </div>
        )}
      </div>
    </div>
  )
}

export default memo(MessageProfileInfo)
