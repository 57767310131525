import React from "react";
import Icon from "@ant-design/icons";

const SVGIcon = (props) => (
  <svg
    width={props.width || "33"}
    height={props.height || "32"}
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      opacity="0.1"
      x="0.5"
      width="32"
      height="32"
      rx="10"
      fill={props.color || "#EA9957"}
    />
    <path
      d="M12.0627 9.19727C10.7787 9.19727 9.74219 10.2338 9.74219 11.5178V14.4705C9.74219 15.0758 10.0647 15.8243 10.4922 16.2518C11.2092 16.9688 16.2109 21.9945 16.2109 21.9938C17.1927 22.9755 18.7917 22.9755 19.7734 21.9938L22.5387 19.2285C23.5204 18.2468 23.5204 16.6478 22.5387 15.666L16.7967 9.94727C16.3692 9.51977 15.6207 9.19727 15.0154 9.19727H12.0627ZM14.2422 12.1973C15.0709 12.1973 15.7422 12.8685 15.7422 13.6973C15.7422 14.526 15.0709 15.1973 14.2422 15.1973C13.4134 15.1973 12.7422 14.526 12.7422 13.6973C12.7422 12.8685 13.4134 12.1973 14.2422 12.1973Z"
      fill={props.color || "#EA9957"}
    />
  </svg>
);

const SVGWorkflowAddTagIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />;
      }}
      {...props}
    />
  );
};

export default SVGWorkflowAddTagIcon;
