import React from "react";
import Icon from "@ant-design/icons";

const SVGIcon = (props) => {
  return (
    <svg
      width="248"
      height="125"
      viewBox="0 0 248 125"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M247.127 81.6242C247.746 84.4319 245.599 85.7648 243.586 86.1881C241.574 86.6113 233.554 86.5878 233.11 84.5719C232.665 82.5561 239.964 79.3112 241.976 78.888C243.989 78.4647 246.508 78.8165 247.127 81.6242Z"
        fill="#7AC6DC"
      />
      <path
        d="M201.395 83.8329C199.247 87.8074 195.472 87.3862 192.77 85.9938C190.069 84.6015 181.04 76.9901 182.582 74.1365C184.125 71.283 195.655 74.2679 198.356 75.6602C201.057 77.0526 203.544 79.8585 201.395 83.8329Z"
        fill="#93FFF9"
      />
      <path
        d="M196.033 1.85832C193.236 -1.10957 189.932 0.215344 187.804 2.12697C185.677 4.03859 179.208 13.0401 181.216 15.171C183.224 17.3019 192.948 11.7552 195.075 9.84354C197.203 7.93192 198.829 4.82622 196.033 1.85832Z"
        fill="#F9CF67"
      />
      <path
        d="M17.7573 50.7538C16.3077 53.0184 18.7138 55.432 21.1654 56.9285C23.617 58.425 28.1537 59.6137 29.1945 57.9878C30.2354 56.3619 27.3862 52.5371 24.9346 51.0406C22.483 49.5441 19.207 48.4892 17.7573 50.7538Z"
        fill="#73D198"
      />
      <path
        d="M41.0239 121.831C38.696 118.499 40.7651 115.656 43.1533 114.064C45.5414 112.473 50.4203 111.672 52.0917 114.064C53.7631 116.456 51.5941 121.135 49.206 122.726C46.8178 124.317 43.3518 125.162 41.0239 121.831Z"
        fill="#FFDC5F"
      />
      <path
        d="M0.981173 86.8361C-0.431692 84.8142 0.824104 83.0884 2.27353 82.1226C3.72295 81.1568 6.68404 80.6709 7.69844 82.1226C8.71285 83.5742 7.39644 86.4137 5.94702 87.3795C4.4976 88.3453 2.39404 88.858 0.981173 86.8361Z"
        fill="#E7647F"
      />
      <path
        d="M225.235 51.6332C227.224 53.1228 226.584 55.1453 225.516 56.505C224.448 57.8647 221.791 59.2297 220.362 58.1603C218.934 57.0908 219.276 53.9919 220.344 52.6322C221.412 51.2725 223.246 50.1437 225.235 51.6332Z"
        fill="#73D198"
      />
      <path
        d="M57.2586 35.9074C55.7253 38.1015 57.0881 39.9743 58.661 41.0223C60.2339 42.0704 63.4472 42.5976 64.548 41.0223C65.6488 39.447 64.2203 36.3657 62.6474 35.3177C61.0745 34.2696 58.7918 33.7133 57.2586 35.9074Z"
        fill="#F9CF67"
      />
    </svg>
  );
};

const SVGVideoStatsHeaderSecondCircles = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />;
      }}
      {...props}
    />
  );
};

export default SVGVideoStatsHeaderSecondCircles;
