import React from "react";
import Icon from "@ant-design/icons";

const SVGIcon = (props) => (
  <svg
    width={props.width || "33"}
    height={props.height || "32"}
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      opacity="0.1"
      x="0.5"
      width="32"
      height="32"
      rx="10"
      fill={props.color || "#2E7EF7"}
    />
    <path
      d="M21.087 8.47232C20.8545 8.43932 20.6212 8.51658 20.4548 8.68308C20.283 8.85483 13.7085 15.4293 13.7085 15.4293C13.5683 15.5696 13.4978 15.7691 13.4978 15.9678V18.2163C13.4978 18.6303 13.8338 18.9663 14.2478 18.9663C14.5283 18.9663 16.215 18.9663 16.4962 18.9663C16.695 18.9663 16.8945 18.8958 17.0348 18.7556C17.0348 18.7556 23.6107 12.1796 23.781 12.0093C23.949 11.8413 24.0263 11.5878 23.9918 11.3531C23.8763 10.5708 23.5455 9.98133 23.0318 9.45633C22.5158 8.92908 21.8857 8.58482 21.087 8.47232ZM12.7485 9.22159C11.0925 9.22159 9.75 10.5641 9.75 12.2201V19.7156C9.75 21.3716 10.3425 22.7141 11.9985 22.7141H20.244C21.9 22.7141 23.2425 21.3716 23.2425 19.7156V14.6561L18.0892 19.8093C17.6602 20.2383 17.0857 20.4656 16.4962 20.4656C16.11 20.4656 14.634 20.4656 14.2478 20.4656C13.0058 20.4656 11.9985 19.4583 11.9985 18.2163V15.9678C11.9985 15.3783 12.2025 14.8038 12.6315 14.3748L17.7848 9.22159H12.7485ZM21.225 10.0361C21.5115 10.1261 21.7402 10.2686 21.954 10.4868C22.1745 10.7118 22.3275 10.9414 22.419 11.2249C22.0935 11.5474 21.7335 11.8976 21.3555 12.2726L20.1675 11.0838C20.5485 10.7081 20.8965 10.3601 21.225 10.0361Z"
      fill={props.color || "#2E7EF7"}
    />
  </svg>
);

const SVGWorkflowCreateNoteIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />;
      }}
      {...props}
    />
  );
};

export default SVGWorkflowCreateNoteIcon;
