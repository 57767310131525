import React from 'react'
import { Space } from 'antd'

const BroadcastListMobileItemTags = ({ title, arrayList }) => {
  return (
    <Space direction='vertical' className='broadcast-space-border-bottom'>
      <span className='info-item-mobile-title'>{title}</span>
      <div className='info-item tags-more-info-wrapper'>
        {arrayList.map((tag) => {
          return (
            <div
              key={tag.id}
              className='info-item-blue'
              style={{ width: 'fit-content' }}
            >
              {tag.name ? tag.name : tag}
            </div>
          )
        })}
      </div>
    </Space>
  )
}

export default BroadcastListMobileItemTags
