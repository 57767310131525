import React, { forwardRef } from 'react'
import { Mention, MentionsInput } from 'react-mentions'
import { MainColor } from '../../utils'
import './InputMentions.scss'

const InputMentionSecondary = forwardRef(
  (
    {
      value,
      onChange,
      userList,
      dynamicTagsList,
      templateList,
      onSelect,
      forceSuggestionsAboveCursor,
      allowSpaceInQuery,
      placeholder,
      disabled,
      sendingMsg,
      onKeyDown,
      onClick,
      onFocus,
      onBlur,
      singleLine,
      className,
    },
    ref
  ) => {
    const getPrefix = () => {
      let list = []
      if (templateList && templateList.length > 0) {
        list.push({ prefix: '#', option: templateList })
      }
      if (dynamicTagsList && dynamicTagsList.length > 0) {
        list.push({ prefix: '{', option: dynamicTagsList })
        list.push({ prefix: '{{', option: dynamicTagsList })
      }
      if (userList && userList.length > 0) {
        list.push({ prefix: '@', option: userList })
      }
      return list
    }

    const getItem = getPrefix()

    return (
      <MentionsInput
        inputRef={ref}
        singleLine={singleLine}
        value={value}
        disabled={disabled}
        onChange={(event, newValue, newPlainTextValue, mentions) => {
          onChange && onChange(newValue, newPlainTextValue, mentions)
        }}
        onKeyDown={onKeyDown}
        forceSuggestionsAboveCursor={forceSuggestionsAboveCursor}
        allowSpaceInQuery={allowSpaceInQuery}
        className={`mat-mention-input-wrapper ${className || ''} ${
          sendingMsg ? 'sending' : ''
        }`}
        placeholder={placeholder}
        onClick={onClick}
        onFocus={onFocus}
        onBlur={onBlur}
      >
        {getItem &&
          getItem.length > 0 &&
          getItem.map((item, index) => {
            return (
              <Mention
                key={index}
                trigger={item.prefix}
                data={item.option}
                appendSpaceOnAdd
                displayTransform={(id, display) => `${display}`}
                onAdd={(id, display) => {
                  onSelect && onSelect(item.prefix, id, display)
                }}
                style={{
                  color: item.prefix === '@' ? MainColor : 'transparent',
                  textShadow:
                    item.prefix === '@'
                      ? '1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white, -1px -1px 1px white'
                      : '',
                }}
                markup={`${item.prefix}[__display__]`}
              />
            )
          })}
      </MentionsInput>
    )
  }
)

InputMentionSecondary.defaultProps = {
  forceSuggestionsAboveCursor: true,
  allowSpaceInQuery: true,
  singleLine: false,
}

export default InputMentionSecondary
