import React, { memo, useEffect, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Layout } from 'antd'
import MainSideBar from '../MainSideBar'
import MainFooter from '../MainFooter'
import MainHeader from '../MainHeader'
import './MainLayout.scss'

const { Content } = Layout

const MainLayout = (props) => {
  const { children, nofooter, isExtension } = props

  const locationPath = useLocation()

  const [screenSize, setScreenSize] = useState(
    window.innerWidth <= 480
      ? 'mobile'
      : window.innerWidth <= 767
        ? 'tabletPortrait'
        : window.innerWidth <= 991
          ? 'tablet'
          : window.innerWidth <= 1190
            ? 'tabletLandscape'
            : window.innerWidth <= 1400
              ? 'messageRightDrawer'
              : 'desktop'
  )
  const [pageTitle, setPageTitle] = useState('')
  const [footerButtons, setFooterButtons] = useState([])
  const [loading, setloading] = useState(false)

  const checkScreenSize = () => {
    let cw = window.innerWidth

    let screen = 'desktop'
    if (cw <= 480) {
      screen = 'mobile'
    } else if (cw <= 767) {
      screen = 'tabletPortrait'
    } else if (cw <= 991) {
      screen = 'tablet'
    } else if (cw <= 1190) {
      screen = 'tabletLandscape'
    } else if (cw <= 1400) {
      screen = 'messageRightDrawer'
    }
    setScreenSize(screen)
  }

  useEffect(() => {
    window.addEventListener('resize', checkScreenSize)
    checkScreenSize()
  }, [])

  const menuOpen = useSelector((state) => {
    return state.authReducer.menuOpen
  })

  const user = useSelector((state) => {
    return state.authReducer.user
  })

  const location = useSelector((state) => {
    return state.authReducer.user.location
  })

  const organization = useSelector((state) => {
    return state.authReducer.user.organization
  })

  const isMobileApp = useSelector((state) => {
    return state.authReducer.isMobileApp
  })

  const MainSideBarContent = useMemo(() => {
    return !isExtension && !isMobileApp && <MainSideBar screenSize={screenSize} />
  }, [screenSize])

  const MainHeaderContent = useMemo(() => {
    return (
      <MainHeader
        screenSize={screenSize}
        pageTitle={pageTitle}
        isExtension={isExtension}
      />
    )
  }, [screenSize, pageTitle, isExtension])

  const MainContent = useMemo(() => {
    const getUrlSearchParams = () => {
      const searchParamsRaw = new URLSearchParams(locationPath.search)
      return Object.fromEntries(searchParamsRaw)
    }

    return (
      <Content
        className={`mat-layout-content ${
          isMobileApp ? 'mat-mobile-layout-content' : ''
        }`}
      >
        {children &&
          React.cloneElement(children, {
            menuOpen: menuOpen,
            user: user,
            isLogined: Boolean(user !== null),
            organization: organization,
            location: location,
            screenSize: screenSize,
            setPageTitle: setPageTitle,
            setFooterButtons: setFooterButtons,
            setLoading: setloading,
            urlSearchParams: getUrlSearchParams(),
          })}
      </Content>
    )
  }, [menuOpen, user, organization, location, screenSize, children, isExtension])

  const MainFooterContent = useMemo(() => {
    return (
      <>
        {!nofooter && <MainFooter footerButtons={footerButtons} loading={loading} />}
      </>
    )
  }, [footerButtons, loading, children])

  return (
    <Layout
      hasSider
      className={`mat-main-layout ${
        isExtension ? 'mat-layout-content-for-extension' : ''
      }`}
    >
      {MainSideBarContent}
      <Layout className='mat-layout'>
        {MainHeaderContent}
        {MainContent}
        {MainFooterContent}
      </Layout>
    </Layout>
  )
}

export default memo(MainLayout)
