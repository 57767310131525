import React from 'react'
import { Popover } from 'antd'

const MatPopover = (props) => {
  return (
    <Popover
      title={props.title}
      trigger={props.trigger || 'click'}
      open={props.visible}
      onOpenChange={props.onVisibleChange}
      overlayClassName={props.overlayClassName}
      placement={props.placement}
      content={props.content}
      destroyTooltipOnHide={props.destroyTooltipOnHide}
    >
      {props.triggerPopover}
    </Popover>
  )
}

export default MatPopover
