import {
  fetchFromUrlDELETEAsync,
  fetchFromUrlGETAsync,
  fetchFromUrlPOSTAsync,
  fetchFromUrlPUTAsync,
} from "../../utils/UrlHelper";

const urlWorkflows = "/workflows";

const fs = {
  getWorkflowList: async (page, perPage, _organization_id) => {
    const urlParams = [
      { key: "_organization_id", value: _organization_id },
      { key: "page", value: page + 1 },
      { key: "perPage", value: perPage },
    ];
    const result = await fetchFromUrlGETAsync(urlWorkflows, urlParams);
    return result;
  },
  createWorkflow: async (name, _organization_id, intents) => {
    const urlParams = [
      { key: "name", value: name },
      { key: "_organization_id", value: _organization_id },
      { key: "actions", value: intents },
    ];
    const result = await fetchFromUrlPOSTAsync(urlWorkflows, urlParams);
    return result;
  },
  editWorkflow: async (name, _id, intents) => {
    const urlParams = [
      { key: "name", value: name },
      { key: "actions", value: intents },
    ];
    const result = await fetchFromUrlPUTAsync(
      urlWorkflows + `/${_id}`,
      urlParams
    );
    return result;
  },
  deleteWorkflow: async (_id) => {
    const urlParams = [{ key: "id", value: _id }];
    const result = await fetchFromUrlDELETEAsync(
      urlWorkflows + `/${_id}`,
      urlParams
    );
    return result;
  },
};

const loc = {};

const workflowActions = Object.assign(fs, loc);

export default workflowActions;
