import React from 'react'
import { Divider, Flex, Form } from 'antd'
import MatRow from '../../../../components/MatRow'
import { getText } from '../../../../lang'
import MatDatePicker from '../../../../components/Form/MatDatePicker'
import SVGDeleteGreyIcon from '../../../../icons/SVG/SVGDeleteGreyIcon'
import ConfirmPopup from '../../../../components/ConfirmPopup'
import utilDate from '../../../../utils/utilsDate'
import SelectForm from '../../../../components/Form/SelectForm'
import CustomTimePicker from '../../../../components/CustomTimePicker/CustomTimePicker'

const CustomWorkingDay = (props) => {
  const { field, form, onDelete, index } = props

  const item = Form.useWatch(['customWorkingHours', field.name], form)

  const [fromTime, fromPeriod] = item?.from?.split(' ') || ''
  const [toTime, toPeriod] = item?.to?.split(' ') || ''

  const [hoursFrom, minutesFrom] = fromTime?.split(':') || ''
  const [hoursTo, minutesTo] = toTime?.split(':') || ''

  if (item) {
    item.date = item?.date
      ? utilDate.getDateByDayJS(item?.date)
      : utilDate.getDateByDayJS()
  }

  if (!item) return null

  return (
    <MatRow className='single-working-day'>
      <MatRow className='single-working-day-row custom-working-days'>
        <span className='days'>
          <MatDatePicker
            name={[field.name, 'date']}
            label={''}
            required={false}
            formStyle={{ marginBottom: 0 }}
          />
        </span>

        <SelectForm
          className={'store_open_state'}
          name={[field.name, 'isOpen']}
          options={[
            {
              label: getText('TEXT_STORE_IS_OPEN'),
              value: true,
            },
            {
              label: getText('TEXT_STORE_IS_CLOSED'),
              value: false,
            },
          ]}
          showSearch={false}
        />
      </MatRow>

      {Boolean(item?.isOpen) && (
        <Flex align='center' className='custom_working_day_flex'>
          <CustomTimePicker
            hourValueInit={hoursFrom}
            minuteValueInit={minutesFrom}
            systemHourInit={fromPeriod}
            index={`${index}c0`}
            onChange={(val) => {
              form.setFieldValue(['customWorkingHours', field.name, 'from'], val)
            }}
          />
          <Divider />
          <CustomTimePicker
            index={`${index}c1`}
            hourValueInit={hoursTo}
            minuteValueInit={minutesTo}
            systemHourInit={toPeriod}
            onChange={(val) => {
              form.setFieldValue(['customWorkingHours', field.name, 'to'], val)
            }}
          />
        </Flex>
      )}
      <ConfirmPopup
        title={getText('MSG_DELETE_CUSTOM_OPENING_HOURS')}
        placement={'topRight'}
        onConfirm={(e) => {
          e.stopPropagation()
          onDelete(item)
        }}
        okText={getText('WORD_YES')}
        cancelText={getText('WORD_NO')}
        trigger={<SVGDeleteGreyIcon className='custom-working-day-delete' />}
      />
    </MatRow>
  )
}

export default CustomWorkingDay
