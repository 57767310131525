import React, { useEffect, useState } from 'react'
import { Modal } from 'antd'
import { useNavigate } from 'react-router-dom'
import { TABLE_DEFAULT_ROW_NUMBER } from '../../../../devOptions'
import { getText, getTextServerError } from '../../../../lang'
import MatList from '../../../../components/MatList'
import OrganizationLayout from '../../components/organizationNewPage/OrganizationLayout'
import OrganizationListItem from '../../components/organizationNewPage/OrganizationListItem'
import OrganizationListTitle from '../../components/organizationNewPage/OrganizationListTitle'
import workflowActions from '../../../../store/modules/workflowActions'
import { notifyError, notifySuccess } from '../../../../utils/Notify'

const Workflow = (props) => {
  const { organization } = props
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [perPage, setPerPage] = useState(TABLE_DEFAULT_ROW_NUMBER)
  const [workflowList, setWorkflowList] = useState({
    list: [],
    total: 0,
  })

  const getWorkflowList = async () => {
    setLoading(true)
    const result = await workflowActions.getWorkflowList(
      page,
      perPage,
      organization._id
    )
    if (result.success) {
      setWorkflowList({
        list: result.data.workflows,
        total: result.data.total_items,
      })
    } else {
      notifyError(result.errMsg)
    }
    setLoading(false)
  }

  useEffect(() => {
    getWorkflowList()
  }, [page])

  const handleEditWorkflow = (val, item) => {
    if (val === 'edit') {
      navigate(`/organization/settings/${organization._id}/workflow/edit`, {
        state: { fromOrgList: true, item },
      })
    } else if (val === 'delete') {
      Modal.confirm({
        title: getText('TEXT_ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_WORKFLOW'),
        async onOk() {
          setLoading(true)
          const result = await workflowActions.deleteWorkflow(item._id)
          if (result.success) {
            getWorkflowList()
            notifySuccess(getText('TEXT_WORKFLOW_WAS_DELETED_SUCCESSFULLY'))
          } else {
            notifyError(getTextServerError(result.errMsg))
          }
          setLoading(false)
        },
        okText: getText('WORD_YES'),
        cancelText: getText('ACTION_CANCEL'),
        okButtonProps: {
          type: 'primary',
          className: 'mat-btn mat-btn-primary',
        },
        cancelButtonProps: {
          type: 'cancel',
          className: 'mat-btn',
        },
      })
    }
  }

  return (
    <OrganizationLayout className={'workflow-list-wrapper'}>
      <OrganizationListTitle
        title={getText('WORD_WORKFLOWS')}
        buttonTitle={'+ ' + getText('WORD_ADD_NEW_WORKFLOW')}
        url={`/organization/settings/${organization._id}/workflow/create`}
      />
      <MatList
        loading={loading}
        data={workflowList.list}
        pagination={true}
        defaultPageSize={TABLE_DEFAULT_ROW_NUMBER}
        paginationCurrentPage={page}
        paginationTotalCount={workflowList.total}
        onPaginationChange={setPage}
        onShowSizeChange={setPerPage}
        containerClassName='organization-new-mat-list-wrapper'
        header={
          <div className='organization-new-mat-list-header'>
            <div className='organization-new-mat-list-header-item'>
              {getText('TEXT_NAME_OF_WORKFLOW')}
            </div>
            <div className='organization-new-mat-list-header-item'>
              {getText('WORD_UPDATED_AT')}
            </div>
            <div className='organization-new-mat-list-header-item'>
              {getText('WORD_UPDATED_BY')}
            </div>
            <div className='organization-new-mat-list-header-item' />
          </div>
        }
        renderItem={(item, index) => {
          return (
            <OrganizationListItem
              key={item._id + '' + index}
              item={item}
              style={{
                backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#F6F7FB',
              }}
              handleEdit={handleEditWorkflow}
              showUpdatedBy
            />
          )
        }}
      />
    </OrganizationLayout>
  )
}

export default Workflow
