import React from 'react'
import { Form, Checkbox } from 'antd'
import './MatFormCheckBoxGroup.scss'

const MatFormCheckboxGroup = (props) => {
  return (
    <Form.Item
      name={props.name}
      className={`mat-form-item ${props.className || ''}`}
      rules={[
        {
          required: props.required,
          message: props.errorMessage || 'This is required',
        },
      ]}
    >
      <Checkbox.Group className='mat-checkbox-group'>
        {props.options.map((option, index) => {
          return (
            <Checkbox
              key={`${option.label}-${index}`}
              className={`mat-checkbox-wrapper`}
              onChange={option.onChange}
              value={option.value}
            >
              {option.label}
            </Checkbox>
          )
        })}
      </Checkbox.Group>
    </Form.Item>
  )
}

MatFormCheckboxGroup.defaultProps = {
  label: 'checkBox',
  disabled: false,
  defaultChecked: false,
}

export default MatFormCheckboxGroup
