import React from 'react'
import Icon from '@ant-design/icons'
import { MainColor } from '../../utils'

const SVGIcon = (props) => (
  <svg
    width={props.width || '16'}
    height={props.height || '16'}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M14.6463 12.2202C14.6463 12.4602 14.593 12.7068 14.4797 12.9468C14.3663 13.1868 14.2197 13.4135 14.0263 13.6268C13.6997 13.9868 13.3397 14.2468 12.933 14.4135C12.533 14.5802 12.0997 14.6668 11.633 14.6668C10.953 14.6668 10.2263 14.5068 9.45967 14.1802C8.69301 13.8535 7.92634 13.4135 7.16634 12.8602C6.39967 12.3002 5.67301 11.6802 4.97967 10.9935C4.29301 10.3002 3.67301 9.5735 3.11967 8.8135C2.57301 8.0535 2.13301 7.2935 1.81301 6.54016C1.49301 5.78016 1.33301 5.0535 1.33301 4.36016C1.33301 3.90683 1.41301 3.4735 1.57301 3.0735C1.73301 2.66683 1.98634 2.2935 2.33967 1.96016C2.76634 1.54016 3.23301 1.3335 3.72634 1.3335C3.91301 1.3335 4.09967 1.3735 4.26634 1.4535C4.43967 1.5335 4.59301 1.6535 4.71301 1.82683L6.25967 4.00683C6.37967 4.1735 6.46634 4.32683 6.52634 4.4735C6.58634 4.6135 6.61967 4.7535 6.61967 4.88016C6.61967 5.04016 6.57301 5.20016 6.47967 5.3535C6.39301 5.50683 6.26634 5.66683 6.10634 5.82683L5.59967 6.3535C5.52634 6.42683 5.49301 6.5135 5.49301 6.62016C5.49301 6.6735 5.49967 6.72016 5.51301 6.7735C5.53301 6.82683 5.55301 6.86683 5.56634 6.90683C5.68634 7.12683 5.89301 7.4135 6.18634 7.76016C6.48634 8.10683 6.80634 8.46016 7.15301 8.8135C7.51301 9.16683 7.85967 9.4935 8.21301 9.7935C8.55967 10.0868 8.84634 10.2868 9.07301 10.4068C9.10634 10.4202 9.14634 10.4402 9.19301 10.4602C9.24634 10.4802 9.29967 10.4868 9.35967 10.4868C9.47301 10.4868 9.55967 10.4468 9.63301 10.3735L10.1397 9.8735C10.3063 9.70683 10.4663 9.58016 10.6197 9.50016C10.773 9.40683 10.9263 9.36016 11.093 9.36016C11.2197 9.36016 11.353 9.38683 11.4997 9.44683C11.6463 9.50683 11.7997 9.5935 11.9663 9.70683L14.173 11.2735C14.3463 11.3935 14.4663 11.5335 14.5397 11.7002C14.6063 11.8668 14.6463 12.0335 14.6463 12.2202Z'
      stroke={props.color || MainColor}
      strokeMiterlimit='10'
    />
  </svg>
)

const SVGMessageIncomingStartIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGMessageIncomingStartIcon
