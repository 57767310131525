import React from 'react'

const LocationCouponDescriptionTextarea = (props) => {
  const { value, descriptionColor, descriptionFontSize, onChangeDescription } = props

  return (
    <div className='my-custom-textarea'>
      <textarea
        value={value}
        rows='2'
        style={{ color: descriptionColor, descriptionFontSize }}
        onChange={(e) => {
          onChangeDescription && onChangeDescription(e)
        }}
      />
    </div>
  )
}

export default LocationCouponDescriptionTextarea
