import React, { memo } from 'react'
import SVGEmailSelect from '../../../../../icons/SVG/SVGEmailSelect'
import SVGSMSSelect from '../../../../../icons/SVG/SVGSMSSelect'
import { getText } from '../../../../../lang'
import MatSelect from '../../../../../components/MatSelect'
import { EMAIL_DEFAULT_OBJECT } from '../../../utils/messageUtils'
import './MessageInputSwitchAction.scss'

const SelectSwitchSMSEmail = (props) => {
  const {
    msgTypeDefault,
    msgChangeEmailSMSType,
    setnewEmailSubject,
    setnewMessageValue,
    setmsgTypeDefault,
    onChangeEmailSMSType,
  } = props

  return (
    <MatSelect
      options={[
        {
          label: (
            <>
              {' '}
              <SVGEmailSelect />
              <span className='not-show'>{getText('WORD_EMAIL')}</span>
              {msgTypeDefault === 'email' ? (
                <span className='default'>{getText('WORD_DEFAULT')}</span>
              ) : (
                ''
              )}
            </>
          ),
          value: 'email',
        },
        {
          label: (
            <>
              <SVGSMSSelect className='msg-icon' />
              <span className='not-show'>{getText('WORD_SMS_UC')}</span>
              {msgTypeDefault === 'phone' ? (
                <span className='default'>{getText('WORD_DEFAULT')}</span>
              ) : (
                ''
              )}
            </>
          ),
          value: 'phone',
        },
      ]}
      value={msgChangeEmailSMSType}
      onSelect={(value) => {
        if (value === 'email') {
          setnewEmailSubject(EMAIL_DEFAULT_OBJECT)
        }
        setnewMessageValue((prevValues) => {
          return prevValues
        })
        setmsgTypeDefault(value)
        onChangeEmailSMSType && onChangeEmailSMSType(value)
      }}
      dropdownClassName={'sms-email-dropdown-wrapper'}
    />
  )
}

export default memo(SelectSwitchSMSEmail)
