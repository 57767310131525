import React from 'react'
import { Dropdown, Tooltip } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import SVGSelected from '../../icons/SVG/SVGSelected'
import './MatDropMenu.scss'

const MatDropMenu = (props) => {
  const items = props.customOption
    ? props.options
    : props.options.map((item, index) => {
        return Object.assign(item, {
          label: (
            <div
              key={index}
              className='drop-item'
              style={{ color: item.tcolor }}
              onClick={() => {
                if (!item.disabled) {
                  props.onChange && props.onChange(item.value, item, index)
                }
              }}
            >
              {item.tooltip ? (
                <Tooltip title={item.tooltip}>
                  <div className='drop-item-icon-with-text'>
                    {item.img && item.img}
                    {item.name && item.name}
                  </div>
                </Tooltip>
              ) : (
                <>
                  <div className='drop-item-icon-with-text'>
                    {item.img && item.img}
                    {item.name && item.name}
                  </div>
                  {item.checked && (
                    <div className='check-wrapper'>
                      <SVGSelected />
                    </div>
                  )}
                  {item.count && (
                    <div className='drop-item-count dfdf'>{item.count}</div>
                  )}
                </>
              )}
            </div>
          ),
        })
      })

  return (
    <Dropdown
      menu={{ items }}
      trigger={['click']}
      placement={props.placement}
      overlayClassName={`mat-drop-menu ${props.bigText ? 'big-text' : ''} ${
        props.overlayClassName || ''
      }`}
      disabled={props.disabled}
      overlayStyle={props.overlayStyle}
      autoAdjustOverflow={props.autoAdjustOverflow}
      getPopupContainer={
        props.getPopupContainer
          ? props.getPopupContainer
          : (trigger) => trigger.parentNode
      }
      dropdownRender={props.dropdownRender}
    >
      <div
        style={props.style}
        className={`mat-dropdown-trigger-container ${props.className || ''}`}
      >
        {props.trigger}
        {props.showArrow && <DownOutlined />}
      </div>
    </Dropdown>
  )
}

MatDropMenu.defaultProps = {
  showArrow: true,
  placeholder: 'Please select',
  allowClear: false,
  renderItem: () => {},
  onChange: () => {},
  options: [],
  autoAdjustOverflow: false,
}

export default MatDropMenu
