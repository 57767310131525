import TagManager from 'react-gtm-module'

function runTagManager() {
    // to keep it once per session, we use sessionStorage to track it
    if (sessionStorage.getItem("tag_already_run")) {
        return
    }

    TagManager.initialize({
        gtmId: process.env.REACT_APP_GTM_ID,
    })

    sessionStorage.setItem("tag_already_run", "true")
}

function removeTagManagerTracker() {
    sessionStorage.removeItem("tag_already_run")
}

export { runTagManager, removeTagManagerTracker }