import Pusher from 'pusher-js'
export class SharedPusher {
  _port
  _channels = {}
  _channelCounts = {}
  _isPaused = false
  _pusherStateSubscriptions = {
    pusher_connected: [],
    pusher_disconnected: [],
    pusher_connection_error: [],
  }

  constructor(port) {
    this._port = port
    this._port.onmessage = async ({ data }) => {
      const { type, payload } = data
      switch (type) {
        case 'new_message':
          const { channel, event, data } = payload
          await this._handleMessage(channel, event, data)
          break
        case 'pusher_connected':
          for (const cb of this._pusherStateSubscriptions.pusher_connected) {
            try {
              await cb()
            } catch (err) {}
          }
          break
        case 'pusher_disconnected':
          for (const cb of this._pusherStateSubscriptions.pusher_disconnected) {
            try {
              await cb()
            } catch (err) {}
          }
          break
        case 'pusher_connection_error':
          for (const cb of this._pusherStateSubscriptions.pusher_connection_error) {
            try {
              await cb(payload)
            } catch (err) {}
          }
          break
        default:
      }
    }
  }

  on(event, callback) {
    if (!this._pusherStateSubscriptions[event]) return
    if (!this._pusherStateSubscriptions[event].find((cb) => cb === callback))
      this._pusherStateSubscriptions[event].push(callback)
    return () => {
      return (this._pusherStateSubscriptions[event] = this._pusherStateSubscriptions[
        event
      ].filter((cb) => cb !== callback))
    }
  }

  async _handleMessage(channel, event, data) {
    if (!this._channels[channel]) return this._unsubscribeFromWorker(channel)
    return this._channels[channel]._handleNewMessage(event, data)
  }

  _subscribeToWorker(channel) {
    if (this._isPaused) return
    this._port.postMessage({ type: 'subscribe', payload: channel })
  }
  subscribe(channel) {
    if (!this._channels[channel]) {
      this._channels[channel] = new PusherChannel(channel)
    }
    this._subscribeToWorker(channel)

    this._channelCounts[channel] = this._channelCounts[channel]
      ? this._channelCounts[channel]
      : 0
    this._channelCounts[channel]++
    return this._channels[channel]
  }

  _unsubscribeFromWorker(channel) {
    this._port.postMessage({ type: 'unsubscribe', payload: channel })
  }
  unsubscribe(channel) {
    this._channelCounts[channel] = this._channelCounts[channel]
      ? this._channelCounts[channel]
      : 0
    if (this._channelCounts[channel] > 0) {
      this._channelCounts[channel]--
      if (this._channelCounts[channel] === 0) {
        delete this._channels[channel]
        delete this._channelCounts[channel]
        this._unsubscribeFromWorker(channel)
      }
    }
  }

  allChannels() {
    return Object.values(this._channels)
  }

  isPaused() {
    return this._isPaused
  }

  pause() {
    if (this._isPaused) return
    for (const channel of Object.keys(this._channels)) {
      this._unsubscribeFromWorker(channel)
    }
    this._isPaused = true
  }

  start() {
    if (!this._isPaused) return
    this._isPaused = false
    for (const channel of Object.keys(this._channels)) {
      this._subscribeToWorker(channel)
    }
  }

  setToken(token = {}) {
    this._port.postMessage({ type: 'update_token', payload: token })
  }
}

export class NativePusher {
  _nativeChannels = {}
  _channels = {}
  _channelCounts = {}
  _isPaused = false
  _token = {}
  _pusherStateSubscriptions = {
    pusher_connected: [],
    pusher_disconnected: [],
    pusher_connection_error: [],
  }

  constructor(pusherConfig) {
    const { appKey, options = {} } = pusherConfig
    this._pusher = new Pusher(appKey, {
      ...options,
      channelAuthorization: {
        ...options.channelAuthorization,
        endpoint:
          (options.channelAuthorization && options.channelAuthorization.endpoint) ||
          options.authEndpoint,
        headersProvider: () =>
          Object.keys(this._token).length
            ? {
                Authorization: `${this._token.tokenType} ${this._token.accessToken}`,
              }
            : {},
      },
      userAuthentication: {
        ...options.userAuthentication,
        endpoint:
          (options.userAuthentication && options.userAuthentication.endpoint) ||
          options.authEndpoint,
        headersProvider: () =>
          Object.keys(this._token).length
            ? {
                Authorization: `${this._token.tokenType} ${this._token.accessToken}`,
              }
            : {},
      },
    })
    this._pusher.connection.bind('state_change', async ({ previous, current }) => {
      switch (current) {
        case 'connected':
          for (const cb of this._pusherStateSubscriptions.pusher_connected) {
            try {
              await cb()
            } catch (err) {}
          }
          break
        case 'disconnected':
          for (const cb of this._pusherStateSubscriptions.pusher_disconnected) {
            try {
              await cb()
            } catch (err) {}
          }
          break
        default:
      }
    })
    this._pusher.connection.bind('error', async (err) => {
      for (const cb of this._pusherStateSubscriptions.pusher_connection_error) {
        try {
          await cb(err)
        } catch (err) {}
      }
    })
  }

  on(event, callback) {
    if (!this._pusherStateSubscriptions[event]) return
    if (!this._pusherStateSubscriptions[event].find((cb) => cb === callback))
      this._pusherStateSubscriptions[event].push(callback)
    return () => {
      return (this._pusherStateSubscriptions[event] = this._pusherStateSubscriptions[
        event
      ].filter((cb) => cb !== callback))
    }
  }

  async _decompress(data) {
    const compressedStream = new Blob(
      [
        new Uint8Array(
          atob(data)
            .split('')
            .map((char) => char.charCodeAt(0))
        ),
      ],
      {
        type: 'application/json',
      }
    ).stream()

    const decompressedStream = compressedStream.pipeThrough(
      // eslint-disable-next-line no-undef
      new DecompressionStream('gzip')
    )

    return new Response(decompressedStream)
      .blob()
      .then((blob) => blob.text())
      .then((str) => JSON.parse(str))
  }

  async _handleMessage(channel, event, data) {
    if (!this._channels[channel]) return this._unsubscribeFromWorker(channel)
    return this._channels[channel]._handleNewMessage(event, data)
  }

  _subscribeToWorker(channel) {
    if (this._isPaused) return
    this._nativeChannels[channel] = this._pusher.subscribe(channel)
    this._nativeChannels[channel].bind_global(async (event, data) => {
      if (event.startsWith('pusher:')) return

      data = await this._decompress(data)
      await this._handleMessage(channel, event, data)
    })
  }

  subscribe(channel) {
    if (!this._channels[channel])
      this._channels[channel] = new PusherChannel(channel)
    this._subscribeToWorker(channel)

    this._channelCounts[channel] = this._channelCounts[channel]
      ? this._channelCounts[channel]
      : 0
    this._channelCounts[channel]++
    return this._channels[channel]
  }

  _unsubscribeFromWorker(channel) {
    this._pusher.unsubscribe(channel)
    if (!this._nativeChannels[channel]) return
    this._nativeChannels[channel].unbind_global()
    delete this._nativeChannels[channel]
  }
  unsubscribe(channel) {
    this._channelCounts[channel] = this._channelCounts[channel]
      ? this._channelCounts[channel]
      : 0
    if (this._channelCounts[channel] > 0) {
      this._channelCounts[channel]--
      if (this._channelCounts[channel] === 0) {
        delete this._channels[channel]
        delete this._channelCounts[channel]
        this._unsubscribeFromWorker(channel)
      }
    }
  }

  allChannels() {
    return Object.values(this._channels)
  }

  isPaused() {
    return this._isPaused
  }

  pause() {
    if (this._isPaused) return
    for (const channel of Object.keys(this._channels)) {
      this._unsubscribeFromWorker(channel)
    }
    this._isPaused = true
  }

  start() {
    if (!this._isPaused) return
    this._isPaused = false
    for (const channel of Object.keys(this._channels)) {
      this._subscribeToWorker(channel)
    }
  }

  setToken(token = {}) {
    if (Object.keys(token).length && !Object.keys(this._token).length) {
      this._pusher.connect()
    } else if (!Object.keys(token).length && Object.keys(this._token).length) {
      this._pusher.disconnect()
    }
    this._token = token

    if (this._pusher.connection.state !== 'connected') return

    this._pusher
      .allChannels()
      .filter(
        (channel) =>
          channel.name.toLowerCase().startsWith('private-') &&
          channel.subscribed === false
      )
      .forEach((channel) => {
        channel.subscribe()
      })
  }
}

class PusherChannel {
  name
  _listenersByEvent = {}
  _globalListeners = []

  constructor(name) {
    this.name = name
  }

  async _handleNewMessage(event, data) {
    for (const listener of this._listenersByEvent[event] || []) {
      try {
        // We clone the event before sending it to each listener because
        // we no longer are limited to one listener per event, and some of the
        // old code mutates the data sent to it
        await listener(JSON.parse(JSON.stringify({ data })).data)
      } catch (err) {
        console.error(
          `A listener for event "${event}" on channel "${this.name}" failed with error`,
          err
        )
      }
    }

    for (const listener of this._globalListeners) {
      try {
        await listener(event, JSON.parse(JSON.stringify({ data })).data)
      } catch (err) {
        console.error(
          `A global listener on channel "${this.name}" failed with error`,
          err
        )
      }
    }
  }

  bind(event, callback) {
    if (!this._listenersByEvent[event]) this._listenersByEvent[event] = []
    this._listenersByEvent[event].push(callback)
  }
  unbind(event, callback) {
    if (callback || !this._listenersByEvent[event])
      this._listenersByEvent[event] = []
    else
      this._listenersByEvent[event] = this._listenersByEvent[event].filter(
        (listener) => listener !== callback
      )
  }

  bind_global(callback) {
    this._globalListeners.push(callback)
  }
  unbind_global(callback) {
    if (callback)
      this._globalListeners = this._globalListeners.filter(
        (listener) => listener !== callback
      )
    else this._globalListeners = []
  }

  unbind_all(callback) {
    if (callback) {
      for (const event of Object.keys(this._listenersByEvent)) {
        this.unbind(event, callback)
      }
      this.unbind_global(callback)
    } else {
      this._listenersByEvent = {}
      this._globalListeners = []
    }
  }
}
