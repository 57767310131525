import React from 'react'
import { getText } from '../../../../lang'
import SwitchForm from '../../../../components/Form/SwitchForm'
import MatBlockWithLabel from '../../../../components/MatBlockWithLabel'
import SVGAiBotInstalcon from '../../../../icons/SVG/SVGAiBotInstalcon'
import SVGAiBotFacebooklcon from '../../../../icons/SVG/SVGAiBotFacebooklcon'
import SVGAiBotEmailcon from '../../../../icons/SVG/SVGAiBotEmailcon'
import MatRow from '../../../../components/MatRow'
import SVGSuperhumanSmsIcon from '../../../../icons/SVG/SVGSuperhumanSmsIcon'

const ConversationAICommunicationChannel = () => {
  return (
    <MatBlockWithLabel
      className={'conversation-ai-communication-channel'}
      label={getText('TEXT_COMMUNICATION_CHANNELS')}
      black
    >
      <MatRow className='conversation-ai-switch-parent'>
        <SwitchForm
          rowStyle={{ margin: '0' }}
          rowClassName='conversation-ai-switch'
          name={['channels', 'sms', 'enabled']}
          icon={<SVGSuperhumanSmsIcon />}
          text={getText('WORD_SMS_UC')}
        />
        <SwitchForm
          rowStyle={{ margin: '0' }}
          rowClassName='conversation-ai-switch'
          name={['channels', 'email', 'enabled']}
          icon={<SVGAiBotEmailcon color={'#5B66EA'} width={16} height={16} />}
          text={getText('WORD_EMAIL')}
        />
      </MatRow>
      <MatRow className='conversation-ai-switch-parent'>
        <SwitchForm
          rowStyle={{ margin: '0' }}
          rowClassName='conversation-ai-switch'
          name={['channels', 'facebook', 'enabled']}
          icon={<SVGAiBotFacebooklcon width={16} height={16} />}
          text={getText('WORD_FB_MESSENGER')}
        />
        <SwitchForm
          rowStyle={{ margin: '0' }}
          rowClassName='conversation-ai-switch'
          name={['channels', 'instagram', 'enabled']}
          icon={<SVGAiBotInstalcon width={16} height={16} />}
          text={getText('WORD_IG_MESSENGER')}
        />
      </MatRow>
    </MatBlockWithLabel>
  )
}

export default ConversationAICommunicationChannel
