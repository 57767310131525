import React from 'react'
import { Tooltip } from 'antd'
import SVGPayment from '../../../../../icons/SVG/SVGPayment'
import MatButton from '../../../../../components/MatButton'
import { msgTooltipText } from '../../../utils/messageUtils'
import SendRequestPaymentModal from './SendRequestPaymentModal'
import './MessagePaymentRequest.scss'

const SendPaymentRequestButton = (props) => {
  const { conversation, customer, disabled, isAwaitingConsent } = props

  return (
    <>
      {conversation.kind !== 'messenger' &&
      conversation.kind !== 'gbm' &&
      conversation.receiver_phone &&
      !isAwaitingConsent ? (
        <SendRequestPaymentModal
          conversation={conversation}
          customer={customer}
          disabled={disabled}
          trigger={
            <MatButton
              icon={<SVGPayment />}
              disabled={disabled}
              className='icon-size only-stroke'
              style={{ paddingLeft: '9px' }}
            />
          }
        />
      ) : (
        <Tooltip title={msgTooltipText(conversation, isAwaitingConsent)}>
          <span
            className={
              !conversation.receiver_phone || isAwaitingConsent
                ? 'tooltip-request-a-payment'
                : ''
            }
            disabled={true}
          >
            <SVGPayment />
          </span>
        </Tooltip>
      )}
    </>
  )
}

export default SendPaymentRequestButton
