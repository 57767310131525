import * as React from 'react'
import { MainColor } from '../../utils'

function SVGMatadorSmall(props) {
  return (
    <svg
      data-name='Group 8189'
      width={props.width || 47.662}
      height={props.heigth || 11.023}
      viewBox='0 0 47.662 11.023'
      {...props}
    >
      <g data-name='Group 2203'>
        <g data-name='Group 2202'>
          <g data-name='Group 2201' fill='#2d2d66'>
            <path
              data-name='Path 5718'
              d='M27.633 8.463v.839a3.607 3.607 0 01-.82.1 1.492 1.492 0 01-1.706-1.612V5.532h-.754v-.858h.754V3.363h1v1.315h1.376v.858h-1.376v2.177c0 .613.339.8.914.8a5.562 5.562 0 00.612-.05z'
            />
            <path
              data-name='Path 5719'
              d='M18.006 2.978v6.324h-1v-3.9l-1.781 2.337h-.336l-1.793-2.344v3.911h-1V2.978h.349l2.611 3.478 2.611-3.478z'
            />
            <path
              data-name='Path 5720'
              d='M23.645 4.675v4.628h-.961v-.594a1.989 1.989 0 01-1.583.7 2.423 2.423 0 010-4.835 1.94 1.94 0 011.621.763v-.66zm-.98 2.319a1.379 1.379 0 00-1.395-1.517 1.516 1.516 0 000 3.025 1.376 1.376 0 001.394-1.509z'
            />
            <path
              data-name='Path 5721'
              d='M32.8 4.675v4.628h-.961v-.594a1.99 1.99 0 01-1.583.7 2.423 2.423 0 010-4.835 1.94 1.94 0 011.621.763v-.66zm-.98 2.319a1.379 1.379 0 00-1.395-1.517 1.515 1.515 0 000 3.025 1.376 1.376 0 001.394-1.509z'
            />
            <path
              data-name='Path 5722'
              d='M32.8 4.675v4.628h-.961v-.594a1.99 1.99 0 01-1.583.7 2.423 2.423 0 010-4.835 1.94 1.94 0 011.621.763v-.66zm-.98 2.319a1.379 1.379 0 00-1.395-1.517 1.515 1.515 0 000 3.025 1.376 1.376 0 001.394-1.509z'
            />
            <path
              data-name='Path 5723'
              d='M38.449 3.055v6.247h-.924v-.66a1.94 1.94 0 01-1.621.763 2.423 2.423 0 010-4.835 1.963 1.963 0 011.546.669V3.055zm-.98 3.938a1.384 1.384 0 00-1.395-1.517 1.515 1.515 0 000 3.025 1.371 1.371 0 001.394-1.508z'
            />
            <path
              data-name='Path 5724'
              d='M39.28 6.984a2.451 2.451 0 112.441 2.422 2.4 2.4 0 01-2.441-2.422zm3.883.009a1.439 1.439 0 10-2.875 0 1.439 1.439 0 102.875 0z'
            />
            <path
              data-name='Path 5725'
              d='M47.661 4.674v.877h-.3a1.241 1.241 0 00-1.357 1.282v2.469h-.99V4.674h.924v.6a1.683 1.683 0 011.348-.65 1.364 1.364 0 01.375.05z'
            />
          </g>
        </g>
      </g>
      <path
        data-name='Path 5726'
        d='M9.336.058a.129.129 0 00-.222.013.127.127 0 00-.006.1 2.075 2.075 0 01-1.94 2.75H2.655A2.075 2.075 0 01.714.174a.13.13 0 00-.006-.1.128.128 0 00-.222-.017A2.871 2.871 0 000 1.66a2.889 2.889 0 001.957 2.733 3.742 3.742 0 00.313 4.936l1.462 1.462a.792.792 0 00.56.232h1.25a.792.792 0 00.56-.232l1.463-1.462a3.742 3.742 0 00.31-4.94A2.887 2.887 0 009.821 1.66 2.87 2.87 0 009.336.058zM6.459 8.16l-1.654 1.7V8.821a2.132 2.132 0 01-1.389-.606l-.036-.036a2.139 2.139 0 113.67-1.495 2.108 2.108 0 01-.591 1.476z'
        fill={MainColor}
      />
    </svg>
  )
}

export default SVGMatadorSmall
