import React from 'react'

const IntegreationItem = ({ icon, title, subTitle, footer }) => {
  return (
    <div className='integration-item-wrapper'>
      <div className='integration-item-header'>
        {icon}
        <div className='integration-item-header-titles'>
          <p>{title}</p>
          <span>{subTitle}</span>
        </div>
      </div>
      <div className='integration-item-footer'>{footer}</div>
    </div>
  )
}

export default IntegreationItem
