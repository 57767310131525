import React from "react";
import Icon from "@ant-design/icons";

const SVGIcon = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.6" filter="url(#filter0_f_19854_72789)">
      <circle cx="10.0067" cy="11.1434" r="3.42857" fill="#546BE2" />
    </g>
    <circle
      cx="10.0056"
      cy="10.0007"
      r="6.85714"
      stroke="url(#paint0_angular_19854_72789)"
      strokeWidth="6"
      strokeDasharray="2 2"
    />
    <circle
      cx="10.0048"
      cy="9.99995"
      r="3.80952"
      fill="url(#paint1_linear_19854_72789)"
    />
    <circle
      opacity="0.3"
      cx="10.0022"
      cy="10.0003"
      r="1.14286"
      fill="#F1F3FD"
    />
    <defs>
      <filter
        id="filter0_f_19854_72789"
        x="1.57812"
        y="2.71484"
        width="16.8594"
        height="16.8574"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="2.5"
          result="effect1_foregroundBlur_19854_72789"
        />
      </filter>
      <radialGradient
        id="paint0_angular_19854_72789"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(10.0056 10.0007) rotate(-90) scale(6.85714)"
      >
        <stop offset="0.0173519" stopColor="#48AF80" />
        <stop offset="0.0262485" stopColor="#48AF80" stopOpacity="0" />
        <stop offset="0.245444" stopColor="#48AF80" stopOpacity="0" />
        <stop offset="0.73009" stopColor="#48AF80" stopOpacity="0.76" />
      </radialGradient>
      <linearGradient
        id="paint1_linear_19854_72789"
        x1="13.8144"
        y1="13.8095"
        x2="6.19531"
        y2="6.19043"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#324BC8" />
        <stop offset="1" stopColor="#778DFD" />
      </linearGradient>
    </defs>
  </svg>
);

const SVGOvalSmsScore = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />;
      }}
      className={props.className}
    />
  );
};

export default SVGOvalSmsScore;
