import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width='17'
    height='16'
    viewBox='0 0 17 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M12.5002 6.84538C12.8987 7.07592 13.1668 7.50681 13.1668 8.00033C13.1668 8.49386 12.8987 8.92475 12.5002 9.15529M12.5002 4.73366C14.0217 5.04251 15.1668 6.38768 15.1668 8.00032C15.1668 9.61296 14.0217 10.9581 12.5002 11.267M5.52231 5.06838L8.36683 2.93596C9.24581 2.27702 10.5002 2.90391 10.5002 4.00214V11.9985C10.5002 13.0967 9.24581 13.7236 8.36683 13.0647L5.52231 10.9323C5.29156 10.7593 5.01094 10.6658 4.72255 10.6658H3.16683C2.43045 10.6658 1.8335 10.0691 1.8335 9.33305V6.6676C1.8335 5.93155 2.43045 5.33487 3.16683 5.33487H4.72255C5.01094 5.33487 5.29156 5.24136 5.52231 5.06838Z'
      stroke={props.color || '#A3A1B7'}
      strokeLinecap='round'
    />
  </svg>
)

const SVGFilterBroadcast = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGFilterBroadcast
