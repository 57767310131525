import { LANGS } from '../../lang'
import { formatPhone, moveToLoginPage } from '../../utils'
import { store } from '../index'
import {
  fetchFromUrlPOSTAsync,
  fetchFromUrlGETAsync,
  //   urlSocket,
  fetchFromUrlDELETEAsync,
  redirect,
  fetchFromUrlPATCHAsync,
} from '../../utils/UrlHelper'
import { getSessionId } from '../../utils/sessionStorage'
import userActions from './userActions'
import organizationActions from './organizationActions'
import {
  SET_ARCHIVED_MSG,
  SET_FCM_TOKEN,
  SET_LANG,
  SET_LAST_ACTION_TIME,
  SET_LOADING_ORGANIZATION,
  SET_MENU_OPEN,
  SET_MOBILE_APP,
  SET_MY_INBOX_UNREAD_MSG,
  SET_NEEDS_ATTENTION_MSG,
  SET_SELF_ACTION_TIME,
  SET_TOKEN,
  SET_UNASSIGNED_MSG,
  SET_UNREAD_MSG,
  SET_USER,
  SET_VEHICLE_INTEREST,
  SET_CONVERSATION,
  SET_AI_SUMMARY,
} from './redux/reducers/authReducer'

const urlAuthLogin = '/auth/login'
const urlAuthLoginMLinkPhone = '/auth/login/phone'
const urlAuthLoginMLinkEmail = '/auth/login/email'
const urlAuthForgotPassword = '/auth/forgot'
const urlAuthResetPassword = '/auth/reset'
const urlAuthRefreshToken = '/auth/refresh-token'
const urlAuthSetPassword = '/auth/set-password'
const urlAuthRedirMagic = '/auth/magic'
const urlAuthGoogle = '/locations/auth/{location_id}/google'
const urlAuthConnectGmail = '/locations/{location_id}/lead/gmail'
const urlAuthConnectFacebook = '/facebook/{location_id}/connect'
const urlOrganizationAuthGoogle = '/organizations/{organization_id}/auth/google'
const urlAuthMicrosoft = '/locations/auth/{location_id}/microsoft'
const urlAuthRequestCode = '/auth/request-code'
const urlAuthVerifyCode = '/auth/verify-code'

const fs = {
  login: async (email, phone, password) => {
    const urlParams = [
      { key: 'email', value: email },
      { key: 'password', value: password },
      { key: 'phone', value: phone },
    ]
    const result = await fetchFromUrlPOSTAsync(urlAuthLogin, urlParams)
    return result
  },

  requestVerification: async (token, method) => {
    const urlParams = [
      { key: 'token', value: token },
      { key: 'method', value: method },
    ]
    const result = await fetchFromUrlPOSTAsync(urlAuthRequestCode, urlParams)
    return result
  },

  verifyCode: async (token, code) => {
    const urlParams = [
      { key: 'token', value: token },
      { key: 'code', value: code },
    ]
    const result = await fetchFromUrlPOSTAsync(urlAuthVerifyCode, urlParams)
    return result
  },

  loginMagicLinkEmail: async (email) => {
    const urlParams = [{ key: 'email', value: email }]
    const result = await fetchFromUrlPOSTAsync(urlAuthLoginMLinkEmail, urlParams)
    return result
  },

  loginMagicLinkPhone: async (phone) => {
    const urlParams = [{ key: 'phone', value: formatPhone(phone, false) }]
    const result = await fetchFromUrlPOSTAsync(urlAuthLoginMLinkPhone, urlParams)
    return result
  },

  logout: async () => {
    return new Promise((resolve, reject) => {
      store.dispatch({ type: SET_FCM_TOKEN, payload: '' })
      store.dispatch({ type: SET_USER, payload: {} })
      store.dispatch({ type: SET_TOKEN, payload: {} })
      resolve({ success: true })
    })
  },

  requestNewPassword: async (email, phone) => {
    const urlParams = [
      { key: 'email', value: email },
      { key: 'phone', value: phone },
    ]
    const result = await fetchFromUrlPOSTAsync(urlAuthForgotPassword, urlParams)
    return result
  },

  resetPassword: async (email, phone, resetToken, newPassword) => {
    const urlParams = [
      { key: 'email', value: email },
      { key: 'phone', value: phone },
      { key: 'resetToken', value: resetToken },
      { key: 'newPassword', value: newPassword },
    ]
    const result = await fetchFromUrlPOSTAsync(urlAuthResetPassword, urlParams)
    return result
  },

  resetPasswordCheck: async (email, phone, resetToken) => {
    const urlParams = [
      { key: 'email', value: email },
      { key: 'phone', value: phone },
      { key: 'token', value: resetToken },
    ]
    const result = await fetchFromUrlGETAsync(urlAuthResetPassword, urlParams)
    return result
  },

  refreshToken: async () => {
    const { email = '', phone = '' } = loc.getUserData()
    const accessToken = loc.getTokenData()

    if (!accessToken.refreshToken) {
      await authActions.logout()
      return {}
    }

    const urlParams = [
      { key: 'email', value: email },
      { key: 'phone', value: phone.replace('+', '') },
      { key: 'refreshToken', value: accessToken.refreshToken },
    ]

    const result = await fetchFromUrlPOSTAsync(urlAuthRefreshToken, urlParams)
    if (result.success) {
      store.dispatch({ type: SET_TOKEN, payload: result.data })
    } else {
      moveToLoginPage(redirect, true)
    }

    return result
  },

  refreshOrganization: async () => {
    try {
      const resultOrg = await organizationActions.getSingle(
        loc.getOrganization()._id
      )
      if (resultOrg.success) {
        let org = resultOrg.data
        const user = store.getState().authReducer.user
        user.organization = org
        await authActions.setUserData(user)
      }
    } catch (err) {
      console.error('Error on location refresh', err)
    }
  },

  setEmptyLocation: async (location) => {
    const user = store.getState().authReducer.user

    user._location_id = location._id
    user.location = location

    await authActions.setUserData(user)
  },

  setOrganization: async (org) => {
    const user = store.getState().authReducer.user
    user._organization_id = org._id
    user.organization = org
    if (user.isSuperAdmin || authActions.hasAccessToMultipleOrganizations()) {
      const result = await userActions.changeSuperAdminOrganization(
        org._id,
        user._id
      )
      if (result.success) {
        result.data.user._organization_id = org._id
        result.data.user.organization = org
        authActions.setUserData(result.data.user)
        authActions.setUnreadMessages(result.data.unread_messages_count)
        authActions.setMyMessagesInboxUnreadMessages(
          result.data.my_messages_inbox_unread_count
        )
        authActions.setArchivedMessages(result.data.archived_messages_count)
        authActions.setUnassignedMessages(result.data.unassigned_messages_count)
        authActions.setNeedsAttentionMessagesCount({
          mainInboxCount: result.data.main_inbox_needs_attention_count,
          myMessagesInbox: result.data.my_messages_inbox_needs_attention_count,
        })
      } else {
        console.error("Couldn't update Organization", result)
      }
    } else {
      const saveObj = {
        accessibleOrganizations: user.accessibleOrganizations,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phone: user.phone,
        isSuperAdmin: user.isSuperAdmin,
        isAdmin: user.isAdmin,
        isManager: user.isManager,
        isSupportAgent: user.isSupportAgent,
        _location_id: user._location_id,
        _organization_id: user._organization_id,
        enabled: user.enabled,
      }
      const result = await userActions.saveUpdate(saveObj, user._id)
      if (result.success) {
        store.dispatch({ type: SET_USER, payload: user })
      } else {
        console.error("Couldn't update Organization", result)
      }
    }
  },

  setLocation: async (obj) => {
    const user = store.getState().authReducer.user
    const result = await userActions.changeLocation(obj._id, user._id)
    if (result.success) {
      await store.dispatch({
        type: SET_USER,
        payload: result.data.user,
      })
    } else {
      console.error("Couldn't update location", result)
    }
  },

  setPassword: async (newPassword, confirmPassword) => {
    const urlParams = [
      { key: 'newPassword', value: newPassword },
      { key: 'confirmPassword', value: confirmPassword },
      { key: 'sessionId', value: getSessionId() },
    ]
    const result = await fetchFromUrlPOSTAsync(urlAuthSetPassword, urlParams)
    return result
  },

  redirectMagic: async (
    magicToken,
    email = null,
    phone = null,
    isNotificationToken = false
  ) => {
    const urlParams = [{ key: 'magicToken', value: magicToken }]

    if (email) {
      urlParams.push({ key: 'email', value: email })
    }
    if (phone) {
      urlParams.push({ key: 'phone', value: phone })
    }

    if (isNotificationToken) {
      urlParams.push({ key: 'isNotificationToken', value: true })
    }

    const result = await fetchFromUrlPOSTAsync(urlAuthRedirMagic, urlParams)
    return result
  },
}

const loc = {
  storeAuthData: (user, token, unread) => {
    store.dispatch({ type: SET_USER, payload: user })
    store.dispatch({ type: SET_TOKEN, payload: token })
    store.dispatch({ type: SET_UNREAD_MSG, payload: unread })
  },

  isLoggedIn: () => {
    const obj = store.getState().authReducer.accessToken
    return Object.keys(obj).length !== 0
  },

  setMenuOpen: (value) => {
    store.dispatch({ type: SET_MENU_OPEN, payload: value })
  },

  getUserData: () => {
    return store.getState().authReducer.user
  },

  setUserData: (data) => {
    store.dispatch({ type: SET_USER, payload: data })
  },

  setTokenData: (value) => {
    store.dispatch({ type: SET_TOKEN, payload: value })
  },

  getTokenData: () => {
    return store.getState().authReducer.accessToken
  },

  getUnreadMessages: () => {
    return store.getState().authReducer.unreadMessages
  },
  setUnreadMessages: (value) => {
    store.dispatch({ type: SET_UNREAD_MSG, payload: value })
  },
  getMyMessagesInboxUnreadMessages: () => {
    return store.getState().authReducer.myMessagesInboxUnreadMessages
  },
  setMyMessagesInboxUnreadMessages: (value) => {
    store.dispatch({ type: SET_MY_INBOX_UNREAD_MSG, payload: value })
  },
  getArchivedMessages: () => {
    return store.getState().authReducer.archivedMessages
  },
  setArchivedMessages: (value) => {
    store.dispatch({ type: SET_ARCHIVED_MSG, payload: value })
  },
  getNeedsAttentionMessagesCount: () => {
    return store.getState().authReducer.needsAttentionMessages
  },
  setNeedsAttentionMessagesCount: (value) => {
    store.dispatch({ type: SET_NEEDS_ATTENTION_MSG, payload: value })
  },

  getUnassignedMessages: () => {
    return store.getState().authReducer.unassignedMessages
  },
  setUnassignedMessages: (value) => {
    store.dispatch({ type: SET_UNASSIGNED_MSG, payload: value })
  },

  getLocation: () => {
    return store.getState().authReducer.user.location
  },

  getOrganization: () => {
    return store.getState().authReducer.user.organization || ''
  },

  getLanguage: () => {
    let lang = store.getState().authReducer?.user?.language || 'en'

    if (!lang || !LANGS.includes(lang)) {
      lang = LANGS[0]
      store.dispatch({ type: SET_LANG, payload: lang })
    }
    return lang
  },

  getLanguageRtl: () => {
    const lang = store.getState().authReducer?.user?.language || 'en'
    return lang === 'fr'
  },

  setLanguage: (lang) => {
    store.dispatch({ type: SET_LANG, payload: lang })
  },

  authGoogle: async (code, locationId) => {
    const urlParams = [{ key: 'code', value: code }]
    return await fetchFromUrlPOSTAsync(
      urlAuthGoogle.replace('{location_id}', locationId),
      urlParams
    )
  },

  authGetGoogleIntegrations: async (locationId) => {
    return await fetchFromUrlGETAsync(
      urlAuthGoogle.replace('{location_id}', locationId),
      []
    )
  },

  connectGmail: async (code, locationId) => {
    const urlParams = [{ key: 'code', value: code }]
    return await fetchFromUrlPOSTAsync(
      urlAuthConnectGmail.replace('{location_id}', locationId),
      urlParams
    )
  },

  connectGmailSecond: async (email, locationId) => {
    const urlParams = [{ key: 'secondaryForwardEmail', value: email }]
    return await fetchFromUrlPATCHAsync(
      urlAuthConnectGmail.replace('{location_id}', locationId),
      urlParams
    )
  },

  connectGmailIntegrations: async (locationId) => {
    return await fetchFromUrlGETAsync(
      urlAuthConnectGmail.replace('{location_id}', locationId),
      []
    )
  },

  disconnectGmail: async (locationId) => {
    return await fetchFromUrlDELETEAsync(
      urlAuthConnectGmail.replace('{location_id}', locationId),
      []
    )
  },

  connectFacebook: async (accountData, locationId) => {
    const urlParams = [{ key: 'account', value: accountData }]
    return await fetchFromUrlPOSTAsync(
      urlAuthConnectFacebook.replace('{location_id}', locationId),
      urlParams
    )
  },

  disconnectFacebook: async (locationId) => {
    return await fetchFromUrlDELETEAsync(
      urlAuthConnectFacebook.replace('{location_id}', locationId),
      []
    )
  },

  organizationAuthGoogle: async (code, organizationId) => {
    const urlParams = [{ key: 'code', value: code }]
    return await fetchFromUrlPOSTAsync(
      urlOrganizationAuthGoogle.replace('{organization_id}', organizationId),
      urlParams
    )
  },

  authLogOutGoogle: async (locationId) => {
    return await fetchFromUrlDELETEAsync(
      urlAuthGoogle.replace('{location_id}', locationId),
      []
    )
  },

  organizationAuthLogOutGoogle: async (organizationId) => {
    return await fetchFromUrlDELETEAsync(
      urlOrganizationAuthGoogle.replace('{organization_id}', organizationId),
      []
    )
  },

  authMicrosoft: async (code, locationId) => {
    const urlParams = [{ key: 'code', value: code }]
    return await fetchFromUrlPOSTAsync(
      urlAuthMicrosoft.replace('{location_id}', locationId),
      urlParams
    )
  },

  authLogOutMicrosoft: async (locationId) => {
    return await fetchFromUrlDELETEAsync(
      urlAuthMicrosoft.replace('{location_id}', locationId),
      []
    )
  },

  getTotalUnreadMsgCount: () => {
    return (
      store.getState().authReducer.myMessagesInboxUnreadMessages +
      store.getState().authReducer.unassignedMessages
    )
  },

  setLastActionTime: (timestamp) => {
    store.dispatch({
      type: SET_LAST_ACTION_TIME,
      payload: timestamp,
    })
  },

  setSelfActionTime: (timestamp) => {
    store.dispatch({
      type: SET_SELF_ACTION_TIME,
      payload: timestamp,
    })
  },

  hasAccessToMultipleOrganizations: () => {
    return (
      store.getState().authReducer.user.accessibleOrganizations &&
      store.getState().authReducer.user.accessibleOrganizations.length > 0
    )
  },

  setIsMobileApp: (timestamp) => {
    store.dispatch({ type: SET_MOBILE_APP, payload: timestamp })
  },

  getIsMobileApp: () => {
    return store.getState().authReducer.isMobileApp
  },

  setFCMToken: (token) => {
    store.dispatch({ type: SET_FCM_TOKEN, payload: token })
  },

  getFCMToken: () => {
    return store.getState().authReducer.fcmToken
  },

  setLoadingOrganization: (bool) => {
    store.dispatch({ type: SET_LOADING_ORGANIZATION, payload: bool })
  },

  getLoadingOrganization: () => {
    return store.getState().authReducer.loadingOrg
  },

  setVehicleOfInterest: (obj) => {
    store.dispatch({ type: SET_VEHICLE_INTEREST, payload: obj })
  },

  getVehicleOfInterest: () => {
    return store.getState().authReducer.vehicleOfInterest
  },

  setConversation: (obj) => {
    store.dispatch({ type: SET_CONVERSATION, payload: obj })
  },

  getConversation: () => {
    return store.getState().authReducer.selectedConversation
  },

  setAISummary: (obj) => {
    store.dispatch({ type: SET_AI_SUMMARY, payload: obj })
  },

  getAISummary: () => {
    return store.getState().authReducer.aiSummary
  },
}

const authActions = Object.assign(fs, loc)

export default authActions
