import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width={props.width || '16'}
    height={props.height || '16'}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M8.84006 2.4008L3.36673 8.19413C3.16006 8.41413 2.96006 8.84746 2.92006 9.14746L2.6734 11.3075C2.58673 12.0875 3.14673 12.6208 3.92006 12.4875L6.06673 12.1208C6.36673 12.0675 6.78673 11.8475 6.9934 11.6208L12.4667 5.82746C13.4134 4.82746 13.8401 3.68746 12.3667 2.29413C10.9001 0.914129 9.78673 1.4008 8.84006 2.4008Z'
      stroke={props.color || '#0E0E0E'}
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7.92676 3.36719C8.21342 5.20719 9.70676 6.61385 11.5601 6.80052'
      stroke={props.color || '#0E0E0E'}
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M2 14.666H14'
      stroke={props.color || '#0E0E0E'}
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

const SVGEditConvIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGEditConvIcon
