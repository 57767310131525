import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Divider } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import MatLongPressButton from '../../../../../components/MatLongPressButton'
import SVGSendMessageNew from '../../../../../icons/SVG/SVGSendMessageNew'
import MatSelect from '../../../../../components/MatSelect'
import { getText, getTextServerError } from '../../../../../lang'
import SVGArrowDown from '../../../../../icons/SVG/SVGArrowDown'
import SVGPressEnterIcon from '../../../../../icons/SVG/SVGPressEnterIcon'
import userActions from '../../../../../store/modules/userActions'
import authActions from '../../../../../store/modules/authActions'
import { notifyError } from '../../../../../utils/Notify'
import { MainColor } from '../../../../../utils'
import './SendMessageButton.scss'

const MatLongPressButtonComponent = ({
  deviceSizes,
  screenSize,
  onClick,
  videoStatusUpload,
  showScheduledMessage,
  sendingMsg,
  sendingMedia,
  setShowScheduledMessage,
  disabled,
  uploadFiles,
  videoUpload,
  newMessageValue,
  newEmailSubject,
  userAdditionalConfigsBtn,
  isShowSendMethodType,
  disableLongPress,
  isLoading,
}) => {
  return (
    <MatLongPressButton
      onLongPress={() => {
        if (deviceSizes.includes(screenSize) && !disableLongPress) {
          setShowScheduledMessage(true)
        }
      }}
      onClick={onClick}
      disabled={
        videoStatusUpload === 'uploading'
          ? true
          : showScheduledMessage ||
            sendingMsg ||
            sendingMedia ||
            disabled ||
            (!uploadFiles.length &&
              !videoUpload.length &&
              !Boolean(newMessageValue.inputMessageForSending) &&
              !newEmailSubject.newValue) ||
            isLoading
      }
      className={`message-center-button-send ${
        isShowSendMethodType ? 'icon-size' : ''
      }`}
      icon={
        isLoading ? (
          <LoadingOutlined style={{ fontSize: 12 }} />
        ) : isShowSendMethodType ? (
          <SVGSendMessageNew color={sendingMsg ? MainColor : '#6c757d'} />
        ) : !userAdditionalConfigsBtn ? (
          <SVGSendMessageNew
            width={16}
            height={16}
            color={sendingMsg ? '#fff' : 'rgba(255, 255, 255, 0.1)'}
          />
        ) : (
          <SVGPressEnterIcon width={16} height={12} />
        )
      }
    />
  )
}

const SendMessageButton = ({
  deviceSizes,
  screenSize,
  conversation,
  setShowScheduledMessage,
  videoStatusUpload,
  showScheduledMessage,
  onClick,
  sendingMsg,
  sendingMedia,
  disabled,
  uploadFiles,
  videoUpload,
  newMessageValue,
  newEmailSubject,
  msgChangeEmailSMSType,
  isAwaitingConsent,
  isLoading,
}) => {
  const userAdditionalConfigsBtn = useSelector((state) => {
    return state.authReducer.user.additionalConfigs.useEnterBtnToSendMessage
  })

  const userId = useSelector((state) => {
    return state.authReducer.user._id
  })

  const isMobileApp = useSelector((state) => {
    return state.authReducer.isMobileApp
  })

  const [selectValuem, setSelectValuem] = useState(
    !userAdditionalConfigsBtn ? 'notSent' : 'sent'
  )

  const isShowSendMethodType =
    msgChangeEmailSMSType === 'email' || isMobileApp || screenSize === 'mobile'

  return isShowSendMethodType ? (
    <MatLongPressButtonComponent
      deviceSizes={deviceSizes}
      screenSize={screenSize}
      onClick={onClick}
      videoStatusUpload={videoStatusUpload}
      showScheduledMessage={showScheduledMessage}
      sendingMsg={sendingMsg}
      setShowScheduledMessage={setShowScheduledMessage}
      disabled={disabled}
      uploadFiles={uploadFiles}
      videoUpload={videoUpload}
      newMessageValue={newMessageValue}
      newEmailSubject={newEmailSubject}
      userAdditionalConfigsBtn={userAdditionalConfigsBtn}
      isShowSendMethodType={isShowSendMethodType}
      disableLongPress={isAwaitingConsent}
      isLoading={isLoading}
    />
  ) : (
    <div
      className='send-button-with-dropdown-wrapper'
      style={{ opacity: isLoading ? 0.5 : 1 }}
    >
      <MatLongPressButtonComponent
        deviceSizes={deviceSizes}
        screenSize={screenSize}
        conversation={conversation}
        onClick={onClick}
        videoStatusUpload={videoStatusUpload}
        showScheduledMessage={showScheduledMessage}
        sendingMsg={sendingMsg}
        sendingMedia={sendingMedia}
        setShowScheduledMessage={setShowScheduledMessage}
        disabled={disabled}
        uploadFiles={uploadFiles}
        videoUpload={videoUpload}
        newMessageValue={newMessageValue}
        newEmailSubject={newEmailSubject}
        userAdditionalConfigsBtn={userAdditionalConfigsBtn}
        msgChangeEmailSMSType={msgChangeEmailSMSType}
        disableLongPress={isAwaitingConsent}
        isLoading={isLoading}
      />
      <Divider type='vertical' />
      <MatSelect
        options={[
          {
            label: (
              <>
                {' '}
                <SVGSendMessageNew width={16} height={16} />
                <span className='not-show'>{getText('WORD_PRESS_SENT_BUTTON')}</span>
                {selectValuem === 'notSent' ? (
                  <span className='default'>{getText('WORD_DEFAULT')}</span>
                ) : (
                  ''
                )}
              </>
            ),
            value: 'notSent',
          },
          {
            label: (
              <>
                <SVGPressEnterIcon />
                <span className='not-show'>{getText('WORD_PRESS_ENTER')}</span>
                {selectValuem === 'sent' ? (
                  <span className='default'>{getText('WORD_DEFAULT')}</span>
                ) : (
                  ''
                )}
              </>
            ),
            value: 'sent',
          },
        ]}
        suffixIcon={<SVGArrowDown color={'#fff'} />}
        placement={'topRight'}
        onChange={async (val) => {
          setSelectValuem(val)
          const result = await userActions.setAdditionalConfigs(
            { useEnterBtnToSendMessage: val === 'notSent' ? false : true },
            userId
          )
          if (result.success) {
            authActions.setUserData(result.data)
          } else {
            notifyError(getTextServerError(result.errMsg))
          }
        }}
      />
    </div>
  )
}

export default SendMessageButton
