import {
  fetchFromUrlPOSTAsync,
  fetchFromUrlGETAsync,
  fetchFromUrlPUTAsync,
  fetchFromUrlDELETEAsync,
} from '../../utils/UrlHelper'

const urlPromotions = '/promotions'

const fs = {
  getAllList: async (locationId) => {
    const urlParams = []
    if (locationId) {
      urlParams.push({ key: 'locationId', value: locationId })
    }
    const result = await fetchFromUrlGETAsync(urlPromotions, urlParams)
    if (result.success) {
      result.max = result.data.total
      result.data = result.data.promotions
    }
    return result
  },

  insert: async (obj) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    const result = await fetchFromUrlPOSTAsync(urlPromotions, urlParams)
    return result
  },

  update: async (obj, promotion_id) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    const result = await fetchFromUrlPUTAsync(
      urlPromotions + '/' + promotion_id,
      urlParams
    )
    return result
  },

  delete: async (locId) => {
    const result = await fetchFromUrlDELETEAsync(urlPromotions + '/' + locId, [])
    return result
  },
}

const loc = {}

const promotionsActions = Object.assign(fs, loc)

export default promotionsActions
