import React from "react";
import Icon from "@ant-design/icons";

const SVGIcon = (props) => {
  return (
    <svg
      width="43"
      height="42"
      viewBox="0 0 43 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="21.5" cy="21" r="21" fill="#3F8CFF" />
      <path
        d="M17.6716 12.6787C17.2116 12.6787 16.8382 13.052 16.8382 13.512C14.9974 13.512 13.5049 15.0045 13.5049 16.8454V17.7304L28.5049 17.6787V16.8454C28.5049 15.0045 27.0124 13.512 25.1716 13.512C25.1716 13.052 24.7982 12.6787 24.3382 12.6787C23.8782 12.6787 23.5049 13.052 23.5049 13.512H18.5049C18.5049 13.052 18.1316 12.6787 17.6716 12.6787ZM13.5049 19.3454V25.1787C13.5049 27.0195 14.9974 28.512 16.8382 28.512H25.1716C27.0124 28.512 28.5049 27.0195 28.5049 25.1787V19.3454H13.5049ZM22.6716 21.012H26.0049C26.4649 21.012 26.8382 21.3854 26.8382 21.8454C26.8382 22.3054 26.4649 22.6787 26.0049 22.6787H22.6716C22.2116 22.6787 21.8382 22.3054 21.8382 21.8454C21.8382 21.3854 22.2116 21.012 22.6716 21.012ZM16.0049 23.512H19.3382C19.7982 23.512 20.1716 23.8854 20.1716 24.3454C20.1716 24.8054 19.7982 25.1787 19.3382 25.1787H16.0049C15.5449 25.1787 15.1716 24.8054 15.1716 24.3454C15.1716 23.8854 15.5449 23.512 16.0049 23.512Z"
        fill="white"
      />
    </svg>
  );
};

const SVGPaymentSalesLastMonth = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />;
      }}
      {...props}
    />
  );
};

export default SVGPaymentSalesLastMonth;
