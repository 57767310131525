import React from 'react'
import moment from 'moment'
import MatDropMenu from '../../../../components/MatDropMenu'
import { getText } from '../../../../lang'
import SVGThreePoint from '../../../../icons/SVG/SVGThreePoint'
import ConfirmPopup from '../../../../components/ConfirmPopup'
import './OrganizationNewPage.scss'
import authActions from '../../../../store/modules/authActions'

const OrganizationListItem = ({
  item,
  handleEdit,
  style,
  greetingDefault,
  handleSetDefault,
  listItem,
  showUpdatedBy,
}) => {
  return (
    <div style={style} className='organization-new-list-one-item-wrapper'>
      <div className='organization-new-image-title'>
        {(item.title && item.title[0].text) || (item.name && item.name)}
      </div>
      <div className='organization-new-list-part'>
        {listItem
          ? listItem.map((item, index) => {
              return (
                <div key={index} className='list-item'>
                  {item.name}
                </div>
              )
            })
          : moment(item.updatedAt)
              .locale(authActions.getLanguage())
              .format('D MMM, YYYY')}
      </div>
      {Boolean(showUpdatedBy) && (
        <div className='organization-new-list-part'>
          {item?.updated_by ? item.updated_by : ''}
        </div>
      )}
      <div className='organization-new-action-container'>
        {greetingDefault && (
          <>
            {item.isDefault ? (
              <p className='organization-new-greeting-default current'>
                {getText('WORD_DEFAULT')}
              </p>
            ) : (
              <ConfirmPopup
                placement={'topRight'}
                title={getText('CONFIRMATION_SET_DEFAULT_GREETING')}
                cancelText={getText('ACTION_CANCEL')}
                okText={getText('TEXT_SET_AS_DEFAULT')}
                onConfirm={() => {
                  handleSetDefault && handleSetDefault(item)
                }}
                trigger={
                  <p
                    className={`organization-new-greeting-default ${
                      item.enabled && 'active'
                    }`}
                  >
                    {getText('TEXT_SET_AS_DEFAULT')}
                  </p>
                }
              />
            )}
          </>
        )}
        <MatDropMenu
          placement={'bottomRight'}
          overlayClassName='organization-new-list-dot-drop-menu'
          showArrow={false}
          overlayStyle={{ width: '100px' }}
          options={[
            {
              name: getText('ACTION_EDIT'),
              value: 'edit',
            },
            {
              name: getText('ACTION_DELETE'),
              value: 'delete',
            },
          ]}
          onChange={(val) => {
            handleEdit && handleEdit(val, item)
          }}
          trigger={
            <div className='organization-new-list-edit-delete'>
              <SVGThreePoint />
            </div>
          }
        />
      </div>
    </div>
  )
}

export default OrganizationListItem
