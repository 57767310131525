import * as React from "react"

function SVGNotPaid(props) {
  return (
    <svg width={31} height={31} viewBox="0 0 31 31" {...props}>
      <g data-name="Icon ionic-ios-close-circle-outline"
         fill={props.color ? props.color: "#fff"}>
        <path
          data-name="Path 5959"
          d="M20.794 19.204l-3.706-3.705 3.705-3.705a1.124 1.124 0 00-1.589-1.589l-3.705 3.705-3.705-3.705a1.124 1.124 0 00-1.589 1.589l3.705 3.705-3.705 3.705a1.086 1.086 0 000 1.589 1.116 1.116 0 001.589 0l3.705-3.705 3.705 3.705a1.129 1.129 0 001.589 0 1.116 1.116 0 00.001-1.589z"
        />
        <path
          data-name="Path 5960"
          d="M15.5 2.087a13.408 13.408 0 11-9.486 3.927A13.325 13.325 0 0115.5 2.087M15.5 0A15.5 15.5 0 1031 15.5 15.5 15.5 0 0015.5 0z"
        />
      </g>
    </svg>
  )
}

export default SVGNotPaid
