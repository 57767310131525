import React from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import SVGThreePoint from '../../../../../icons/SVG/SVGThreePoint'
import MatCheckbox from '../../../../../components/MatCheckbox/index.js'

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

const GreetingsDragable = (props) => {
  const { listQuestions, defaultLanguage } = props
  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }
    const items = reorder(
      listQuestions,
      result.source.index,
      result.destination.index
    )
    props.onChange(items)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='droppable'>
        {(provided, snapshot) => (
          <div
            className='draggable-questions-wrapper'
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {listQuestions.map((item, index) => {
              return (
                <Draggable key={item._id} draggableId={item._id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      className='draggable-question'
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <MatCheckbox
                          label={''}
                          value={item.selected}
                          className=''
                          onChange={() => {
                            const questions = listQuestions
                            questions[index].selected = !questions[index].selected
                            props.onChange(questions)
                          }}
                        />
                        {item.question[defaultLanguage]}
                      </div>
                      <SVGThreePoint />
                    </div>
                  )}
                </Draggable>
              )
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default GreetingsDragable
