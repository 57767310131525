import React from 'react'
import './Payment.scss'

const PaymentListItemTitle = ({ title, icon }) => {
  return (
    <div className='payment-page-list-header'>
      <div className='payment-page-list-header-with-icon'>
        {icon}
        <p>{title}</p>
      </div>
    </div>
  )
}

export default PaymentListItemTitle
