import React, { memo } from 'react'
import InputFormText from '../../../../components/Form/InputFormText'
import WorkflowActionArchiveItem from './WorkflowActionArchiveItem'
import WorkflowActionAssignItem from './WorkflowActionAssignItem'
import WorkflowActionNoteItem from './WorkflowActionNoteItem'
import WorkflowActionSmsItem from './WorkflowActionSmsItem'
import WorkflowActionTagsItem from './WorkflowActionTagsItem'
import WorkflowPushToCRMItem from './WorkflowPushToCRMItem'

const WorkflowActions = ({
  mainField,
  selectedType,
  intentActionsField,
  form,
  onRemove,
}) => {
  const fieldItemName = [
    'actions',
    mainField.name,
    'intents',
    selectedType,
    'actions',
    intentActionsField.name,
  ]
  const fieldItem = form.getFieldValue(fieldItemName)

  return (
    <React.Fragment>
      <InputFormText name={[intentActionsField.name, 'type']} hidden />
      {fieldItem.type === 'send sms' ? (
        <WorkflowActionSmsItem
          fieldItemName={fieldItemName}
          field={intentActionsField}
          form={form}
          onRemove={onRemove}
        />
      ) : fieldItem.type === 'add tags' ? (
        <WorkflowActionTagsItem
          item={fieldItem}
          field={intentActionsField}
          onRemove={onRemove}
          form={form}
        />
      ) : fieldItem.type === 'assign users' ? (
        <WorkflowActionAssignItem
          item={fieldItem}
          field={intentActionsField}
          onRemove={onRemove}
          form={form}
          fieldItemName={fieldItemName}
        />
      ) : fieldItem.type === 'create note' ? (
        <WorkflowActionNoteItem field={intentActionsField} onRemove={onRemove} />
      ) : fieldItem.type === 'archive conversation' ? (
        <WorkflowActionArchiveItem onRemove={onRemove} />
      ) : fieldItem.type === 'send to crm' ? (
        <WorkflowPushToCRMItem onRemove={onRemove} />
      ) : null}
    </React.Fragment>
  )
}

export default memo(WorkflowActions)
