import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Form } from 'antd'
import { getText } from '../../../../../../lang'
import MatForm from '../../../../../../components/Form/MatForm'
import InputFormText from '../../../../../../components/Form/InputFormText'
import MatRow from '../../../../../../components/MatRow'
import MatSwitch from '../../../../../../components/MatSwitch'
import BroadcastCampaignPage from '../../../../BroadcastCampaignPage'
import { notifyError } from '../../../../../../utils/Notify'
import LoadingSpinner from '../../../../../../components/LoadingSpinner'
import SelectChecked from '../../../../../../components/Form/SelectChecked'
import FormButtons from '../FormButtons/FormButtons'

const Campaign = React.memo((props) => {
  const {
    onStateChange,
    consentValue,
    getLocationList,
    handleLoadMoreLocations,
    onChangeStep,
    name,
    _location_id,
  } = props

  const [form] = Form.useForm()
  const [initialState, setInitialState] = useState()

  useEffect(() => {
    let stateObj = { name: name || '' }
    if (!!_location_id) {
      stateObj._location_id = _location_id
    }
    setInitialState(stateObj)
  }, [])

  const onFinish = () => onChangeStep(1)

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields.length > 0) {
      notifyError(errorInfo.errorFields[0].errors[0])
    }
  }
  const organization = useSelector((state) => {
    return state.authReducer.user.organization
  })

  const location = useSelector((state) => {
    return state.authReducer.user.location
  })
  const isSuperAdmin = useSelector((state) => {
    return state.authReducer.user.isSuperAdmin || false
  })
  const isAdmin = useSelector((state) => {
    return state.authReducer.user.isAdmin || false
  })

  const isManager = useSelector((state) => {
    return state.authReducer.user.isManager || false
  })

  const restrictManagersAccessToAllLoc =
    organization && organization.restrictions.restrictManagersAccessToAllLocations

  const hasManyOrganizations = useSelector((state) => {
    return (
      state.authReducer.user.accessibleOrganizations &&
      state.authReducer.user.accessibleOrganizations.length >= 2
    )
  })

  return (
    <BroadcastCampaignPage title={getText('TEXT_START_YOUR_CAMPAIGN')}>
      {!initialState ? (
        <LoadingSpinner />
      ) : (
        <div className='create-campaign-form-wrapper ant-form-vertical'>
          <MatForm
            form={form}
            name='formSaveCustomerComparing'
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={initialState}
            validateTrigger={['onChange']}
          >
            <InputFormText
              label={getText('TEXT_NAME_OF_BROADCAST')}
              onChange={(e) => {
                onStateChange('name', e.target.value)
              }}
              allowClear={() => {
                onStateChange('name', '')
              }}
              name='name'
              required={true}
              maxLength={100}
            />
            <SelectChecked
              name={'_location_id'}
              label={getText('TEXT_LOCATION_DO_THESE_USERS_BELONG_TO')}
              option={
                (!restrictManagersAccessToAllLoc && isManager) ||
                hasManyOrganizations ||
                isSuperAdmin ||
                isAdmin
                  ? getLocationList.locations
                  : [{ label: location.name, value: location._id }]
              }
              onChange={(items) => {
                onStateChange('_location_id', items)
              }}
              placeholder={getText('WORD_SELECT_ITEM')}
              onLoadMore={handleLoadMoreLocations}
              message={getText(
                'ERROR_PLEASE_SELECT_LOCATION_IN_THE_FIELD_WHICH_LOCATION'
              )}
              getPopupContainer={() => document.querySelector('.steps-wrapper')}
              required
            />
          </MatForm>
          <MatRow className='switch-row'>
            <MatSwitch
              label={getText('WORD_CONSENT')}
              value={consentValue}
              onChange={(item) => onStateChange('send_consent', item)}
              switchText={getText('TEXT_CONSENT_DESCRIPTION')}
            />
          </MatRow>

          <FormButtons
            prevAction={onChangeStep}
            currentFormName='formSaveCustomerComparing'
            submitFormBtn={getText('WORD_NEXT')}
            cancelFormBtn={getText('WORD_PREVIOUS')}
          />
        </div>
      )}
    </BroadcastCampaignPage>
  )
})

export default Campaign
