import * as React from "react"

function SVGSuccess(props) {
  return (
    <svg
      width={156.259}
      height={156.259}
      viewBox="0 0 156.259 156.259"
      {...props}
    >
      <g data-name="Group 8159" transform="translate(-11050.371 -8520.371)">
        <circle
          data-name="Ellipse 544"
          cx={78.129}
          cy={78.129}
          r={78.129}
          transform="translate(11050.371 8520.371)"
          fill="#00cb85"
          opacity={0.06}
        />
        <circle
          data-name="Ellipse 545"
          cx={60.904}
          cy={60.904}
          r={60.904}
          transform="translate(11067.596 8537.596)"
          fill="#00cb85"
          opacity={0.06}
        />
        <circle
          data-name="Ellipse 546"
          cx={43.679}
          cy={43.679}
          r={43.679}
          transform="translate(11085.001 8555)"
          fill="#00cb85"
          opacity={0.16}
        />
        <g data-name="Group 7051">
          <path
            data-name="Path 5801"
            d="M11147.568 8583.002a3.07 3.07 0 00-2.11.935c-7.616 7.633-15.769 16.379-23.378 24.1l-10.862-8.946a3.069 3.069 0 10-3.884 4.724l13.044 10.746a3.069 3.069 0 004.124-.192c8.247-8.265 17.2-17.949 25.321-26.088a3.069 3.069 0 00-2.254-5.275z"
            fill="#00cb85"
          />
        </g>
      </g>
    </svg>
  )
}

export default SVGSuccess
