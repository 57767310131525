import React from 'react'
import './MatBlockWithLabel.scss'

const MatBlockWithLabel = (props) => {
  return (
    <div
      style={props.style}
      className={`mat-block-with-label ${props.className || ''}`}
      onClick={props.onClick}
    >
      {props.label && (
        <div
          className={`mat-label ${props.labelClassName || ''} ${
            props.black ? 'black' : ''
          }`}
          style={props.labelStyle}
        >
          {props.icon && <span>{props.icon}</span>}
          <label>{props.label}</label>
          {props.button}
        </div>
      )}
      {props.children}
    </div>
  )
}

export default MatBlockWithLabel
