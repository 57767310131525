import { fetchFromUrlPOSTAsync, fetchFromUrlGETAsync, fetchFromUrlPUTAsync, fetchFromUrlDELETEAsync, fetchFromUrlPATCHAsync } from '../../utils/UrlHelper';

const urlTriggers = "/triggers";

const fs = {

    getPageList: async (start, range, searchKeyword = "", paginationOptions = {}) => {
        const urlParams = [
            { key: "page", value: start + 1 },
            { key: "perPage", value: range },
        ];
        if (paginationOptions._location_id) {
            urlParams.push({ key: "_location_id", value: paginationOptions._location_id });
        }
        if (paginationOptions.language) {
            urlParams.push({ key: "language", value: paginationOptions.language });
        }
        const result = await fetchFromUrlGETAsync(urlTriggers, urlParams);
        if (result.success) {
            result.max = result.data.total_items;
            result.data = result.data.triggers;
        }
        return result;
    },

    // getSingle: async (itemId) => {
    //     const result = await fetchFromUrlGETAsync(urlTriggers + "/" + itemId, []);
    //     return result;
    // },

    saveNew: async (obj) => {
        const urlParams = Object.keys(obj).map(key => { return { key, value: obj[key] } });
        const result = await fetchFromUrlPOSTAsync(urlTriggers, urlParams);
        return result;
    },

    saveUpdate: async (obj, locId) => {
        const urlParams = Object.keys(obj).map(key => { return { key, value: obj[key] } });
        const result = await fetchFromUrlPUTAsync(urlTriggers + "/" + locId, urlParams);
        return result;
    },

    updatePartial: async (obj, _id) => {
        const urlParams = Object.keys(obj).map(key => { return { key, value: obj[key] } });
        const result = await fetchFromUrlPATCHAsync(urlTriggers + "/" + _id, urlParams);
        return result;
    },
    setAsDefault: async (obj, _id) => {
        const urlParams = Object.keys(obj).map(key => { return { key, value: obj[key] } });
        const result = await fetchFromUrlPATCHAsync(urlTriggers + "/" + _id + "/default", urlParams);
        return result;
    },
    delete: async (locId) => {
        const result = await fetchFromUrlDELETEAsync(urlTriggers + "/" + locId, []);
        return result;
    },
};


const loc = {};


const greetingsActions = Object.assign(fs, loc);

export default greetingsActions;
