import React from 'react'
import { getText } from '../../lang'
import { formatDate, formatTime } from '../../utils'
import IconInvitation from '../../icons/IconInvitation'
import MatRow from '../../components/MatRow'
import ProfileLetters from '../../components/ProfileLetters'
import MatButton from '../../components/MatButton'
import ConfirmPopup from '../../components/ConfirmPopup'
import InvitationListFieldItem from './InvitationListFieldItem'

const InvitationListItem = ({ item, onCancel }) => {
  const renderStatus = (item) => {
    let className = 'invite-list-status '
    let text
    let icon
    if (item.cancelled) {
      text = getText('WORD_CANCELLED')
      className += 'danger'
    } else {
      text = getText('WORD_EMAIL_SENT')
      className += 'waiting'
    }
    return <div className={className + (icon ? ' with-icon' : '')}>{text}</div>
  }

  return (
    <div className='invitation-item'>
      <div className='list-item-field-header'>
        <ProfileLetters
          name={
            item.customer
              ? item.customer.fullName
                ? item.customer.fullName
                : '+'
              : '+'
          }
          withoutIcon
        />
        <MatRow spaceBetween>
          <div>
            <div className='item-name'>
              {' '}
              {item.customer ? item.customer.fullName : ''}
            </div>
            <span className='created-date'>
              {formatDate(item.createdAt)}&nbsp;{formatTime(item.createdAt)}
            </span>
          </div>
        </MatRow>
      </div>
      <InvitationListFieldItem
        fieldValuesList={[
          {
            icon: IconInvitation.phone,
            label: getText('WORD_PHONE'),
            rightItem: item.customer ? item.customer.phone : '-',
          },
          {
            icon: IconInvitation.email,
            label: getText('WORD_EMAIL'),
            rightItem: item.customer ? item.customer.email : '-',
          },
          {
            icon: IconInvitation.add,
            label: getText('WORD_INVITE_STATUS'),
            rightItem: renderStatus(item),
          },
          {
            icon: IconInvitation.recommend,
            label: getText('WORD_RECOMMENDED'),
            rightItem: item.tracking.would_recommend
              ? IconInvitation.checkCircle
              : IconInvitation.clearCircle,
          },
        ]}
      />
      <ConfirmPopup
        title={getText('TEXT_ARE_YOU_SURE_YOU_WANT_TO_CANCEL_THIS_INVITATION')}
        onConfirm={() => {
          onCancel && onCancel(item)
        }}
        trigger={
          <MatButton
            disabled={item.cancelled}
            buttonText={getText(item.cancelled ? 'WORD_CANCELLED' : 'ACTION_CANCEL')}
            typeButton={'cancel'}
          />
        }
        cancelText={getText('WORD_NO')}
        okText={getText('WORD_YES')}
      />
    </div>
  )
}

export default InvitationListItem
