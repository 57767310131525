import React from 'react'
import './Button.scss'

function Button({ className, text, handleClick }) {
  return (
    <button
      className={`default-class-btn ${className ? className : ''}`}
      onClick={handleClick}
    >
      {text}
    </button>
  )
}

export default Button
