import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width='30'
    height='30'
    viewBox='0 0 30 30'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='15' cy='15' r='15' fill={props.color || '#EEFDF4'} />
    <path
      d='M9 11.6662L14.63 7.91288C14.7396 7.83979 14.8683 7.80078 15 7.80078C15.1317 7.80078 15.2604 7.83979 15.37 7.91288L21 11.6662V20.9995C21 21.1764 20.9298 21.3459 20.8047 21.471C20.6797 21.596 20.5101 21.6662 20.3333 21.6662H9.66667C9.48986 21.6662 9.32029 21.596 9.19526 21.471C9.07024 21.3459 9 21.1764 9 20.9995V11.6662ZM15 14.3329C15.3536 14.3329 15.6928 14.1924 15.9428 13.9424C16.1929 13.6923 16.3333 13.3532 16.3333 12.9995C16.3333 12.6459 16.1929 12.3068 15.9428 12.0567C15.6928 11.8067 15.3536 11.6662 15 11.6662C14.6464 11.6662 14.3072 11.8067 14.0572 12.0567C13.8071 12.3068 13.6667 12.6459 13.6667 12.9995C13.6667 13.3532 13.8071 13.6923 14.0572 13.9424C14.3072 14.1924 14.6464 14.3329 15 14.3329Z'
      fill={props.color || '#48AF80'}
    />
  </svg>
)

const SVGContactTags = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGContactTags
