import React, { useState } from 'react'
import { redirect } from '../../utils/UrlHelper'
import MatModal from '../MatModal'
import MatButton from '../MatButton'
import MatRow from '../MatRow'

const LocationWasEmpty = () => {
  const [open, setOpen] = useState(true)

  return (
    <MatModal noTrigger hideFooter visible={open} closable={false}>
      <MatRow style={{ flexDirection: 'column', padding: '15px 0' }}>
        <h2
          style={{
            marginRight: '0',
            textAlign: 'center',
            marginBottom: '20px',
          }}
        >
          You don't have a location. Please create location
        </h2>
        <MatButton
          buttonText={'Create Location'}
          onClick={() => {
            redirect('/location/settings/new')
            setOpen(false)
          }}
        />
      </MatRow>
    </MatModal>
  )
}

export default LocationWasEmpty
