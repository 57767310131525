import React from 'react'
import LocationCouponButtonInput from './LocationCouponButtonInput'
import LocationsCouponPopover from './LocationsCouponPopover'

const LocationCouponButton = (props) => {
  const {
    buttonEn,
    buttonFr,
    buttonSp,
    lang,
    onChangeButtonEn,
    onChangeButtonFr,
    onChangeButtonSp,
    organization,
    onChangeButtonColor,
    buttonColor,
    onChangeLanguage,
    visibleButtonTextEdit,
    setVisibleButtonTextEdit,
  } = props

  return (
    <div className='text-with-edit-icon'>
      {lang === 'en' && (
        <LocationCouponButtonInput
          value={buttonEn}
          buttonColor={buttonColor}
          onChangeButton={(e) => {
            onChangeButtonEn && onChangeButtonEn(e)
          }}
        />
      )}
      {lang === 'fr' && (
        <LocationCouponButtonInput
          value={buttonFr}
          buttonColor={buttonColor}
          onChangeButton={(e) => {
            onChangeButtonFr && onChangeButtonFr(e)
          }}
        />
      )}
      {lang === 'sp' && (
        <LocationCouponButtonInput
          value={buttonSp}
          buttonColor={buttonColor}
          onChangeButton={(e) => {
            onChangeButtonSp && onChangeButtonSp(e)
          }}
        />
      )}
      <LocationsCouponPopover
        organization={organization}
        color={buttonColor}
        setColor={(color) => {
          onChangeButtonColor && onChangeButtonColor(color)
        }}
        visibleEdit={visibleButtonTextEdit}
        setVisibleEdit={setVisibleButtonTextEdit}
        whitoutFontSize
        onChangeLanguage={onChangeLanguage}
        onlyBackground
        // withoutSelect={withoutSelect}
      />
    </div>
  )
}

export default LocationCouponButton
