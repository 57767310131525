import React from "react";
import Icon from "@ant-design/icons";

const SVGIcon = (props) => {
  return (
    <svg
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="60"
        cy="60"
        r="55"
        fill="#FFEEDA"
        stroke="white"
        strokeWidth="10"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60.0025 44.1875C58.1427 44.1875 56.3455 44.4455 54.6437 44.9269C53.8798 45.1429 53.0853 44.6988 52.8693 43.9349C52.6532 43.1709 53.0973 42.3765 53.8613 42.1604C55.8152 41.6078 57.8755 41.3125 60.0025 41.3125C62.1295 41.3125 64.1898 41.6078 66.1437 42.1604C66.9077 42.3765 67.3518 43.1709 67.1357 43.9349C66.9197 44.6988 66.1252 45.1429 65.3613 44.9269C63.6595 44.4455 61.8623 44.1875 60.0025 44.1875ZM60.0026 79.1667C51.005 79.1667 43.7109 71.8726 43.7109 62.875C43.7109 53.8774 51.005 46.5833 60.0026 46.5833C69.0002 46.5833 76.2943 53.8774 76.2943 62.875C76.2943 64.5426 76.0437 66.1517 75.5782 67.6667H67.8359C65.6268 67.6667 63.8359 69.4575 63.8359 71.6667V78.7131C62.6066 79.0096 61.323 79.1667 60.0026 79.1667ZM60.0026 52.8125C60.7965 52.8125 61.4401 53.4561 61.4401 54.25V61.343C62.2994 61.8401 62.8776 62.7692 62.8776 63.8333C62.8776 65.4212 61.5904 66.7083 60.0026 66.7083C58.4148 66.7083 57.1276 65.4212 57.1276 63.8333C57.1276 62.7692 57.7058 61.8401 58.5651 61.343V54.25C58.5651 53.4561 59.2087 52.8125 60.0026 52.8125ZM76.4706 72.3826C76.9581 71.7559 76.8452 70.8528 76.2185 70.3654C75.5918 69.878 74.6887 69.9909 74.2013 70.6175L70.9934 74.7419C70.8226 74.9615 70.5014 74.99 70.2946 74.8039L68.6309 73.3066C68.0408 72.7755 67.1319 72.8233 66.6008 73.4134C66.0697 74.0035 66.1175 74.9125 66.7076 75.4436L68.3713 76.9409C69.8187 78.2435 72.0673 78.044 73.2628 76.507L76.4706 72.3826Z"
        fill="url(#paint0_linear_21058_207824)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_21058_207824"
          x1="45.1291"
          y1="41.3125"
          x2="84.9754"
          y2="65.8452"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EA9957" />
          <stop offset="1" stopColor="#F86140" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const SVGVideoStatsHeaderTimeCircle = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />;
      }}
      {...props}
    />
  );
};

export default SVGVideoStatsHeaderTimeCircle;
