import React from "react";
import Icon from "@ant-design/icons";

const SVGIcon = (props) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.00944 0.333008C3.32744 0.333008 0.342773 3.31767 0.342773 6.99966C0.342773 10.6817 3.32744 13.6663 7.00944 13.6663C10.6914 13.6663 13.6761 10.6817 13.6761 6.99966C13.6761 3.31767 10.6914 0.333008 7.00944 0.333008ZM9.67611 4.99967C9.84677 4.99967 10.0248 5.057 10.1554 5.187C10.4154 5.44767 10.4154 5.885 10.1554 6.14566L7.65544 8.62499C6.8881 9.39165 5.71544 9.277 5.11344 8.37434L4.44678 7.37434C4.24278 7.06834 4.3281 6.64167 4.63477 6.437C4.94077 6.233 5.36744 6.31832 5.5721 6.62499L6.23877 7.62499C6.37344 7.82699 6.54611 7.859 6.71745 7.687L9.19678 5.187C9.32744 5.057 9.50544 4.99967 9.67611 4.99967Z"
        fill="#48AF80"
      />
    </svg>
  );
};

const SVGPaymentPaidIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />;
      }}
      {...props}
    />
  );
};

export default SVGPaymentPaidIcon;
