import React from 'react'
import SVGMessagesTemplateIcon from '../../../../../icons/SVG/SVGMessagesTemplateIcon'
import messageActions from '../../../../../store/modules/messageActions'
import { notifyError } from '../../../../../utils/Notify'
import { EMAIL } from '../../../../../devOptions'
import { getTextServerError } from '../../../../../lang'
import TemplateSelect from './TemplateSelect'
import './MessageTemplateAction.scss'

const SelectMessageTemplate = (props) => {
  const {
    smsTemplatesList,
    emailTemplatesList,
    setnewMessageValue,
    newMessageValue,
    currentCursorPosition,
    currentCursorPositionSubject,
    mentionItem,
    setIsTemplate,
    setuploadFiles,
    isCustomerRedStatus,
    isCustomerYellowStatusAndRestricted,
    isCustomerPhoneNumberValid,
    onLoadMore,
    suffixIcon,
    setcurrentCursorPosition,
    setcurrentCursorPositionSubject,
    id,
    setFocusOverInput,
    conversation,
    newEmailSubject,
    setnewEmailSubject,
    msgChangeEmailSMSType,
  } = props

  const subStringMessageSubject = (selectedItem, oldMessage) => {
    return `${oldMessage.substring(
      0,
      currentCursorPositionSubject
    )} ${selectedItem} ${oldMessage.substring(currentCursorPositionSubject)}`
  }
  const subStringMessageText = (selectedItem, oldMessage) => {
    return `${oldMessage.substring(
      0,
      currentCursorPosition
    )} ${selectedItem} ${oldMessage.substring(currentCursorPosition)}`
  }

  const replaceDynamicTagsText = (changedText, changedSubject) => {
    const letterForNewValue = subStringMessageText(
      changedText,
      newMessageValue.inputMessageForTags
    )
    const letterForPlainText = subStringMessageText(
      changedText,
      newMessageValue.inputMessageForSending
    )

    if (changedSubject) {
      const letterForNewValueSubject = subStringMessageSubject(
        changedSubject,
        newEmailSubject.newValue
      )
      const letterForPlainTextSubject = subStringMessageSubject(
        changedSubject,
        newEmailSubject.newPlainTextValue
      )
      setnewEmailSubject({
        newValue: letterForNewValueSubject,
        newPlainTextValue: letterForPlainTextSubject,
      })
      setcurrentCursorPositionSubject(letterForPlainTextSubject.length)
    }

    setnewMessageValue({
      inputMessageForTags: letterForNewValue,
      inputMessageForSending: letterForPlainText,
    })

    setcurrentCursorPosition(letterForPlainText.length)

    mentionItem && mentionItem.current && mentionItem.current.focus()
    setFocusOverInput(true)
  }

  return (
    <TemplateSelect
      id={id}
      dropdownStyle={{ width: 200 }}
      options={
        msgChangeEmailSMSType === EMAIL ? emailTemplatesList : smsTemplatesList
      }
      suffixIcon={suffixIcon || <SVGMessagesTemplateIcon />}
      className='mat-template-select'
      disabled={
        isCustomerPhoneNumberValid ||
        isCustomerRedStatus ||
        isCustomerYellowStatusAndRestricted ||
        conversation.kind === 'gbm'
      }
      onSelect={async (e, ee) => {
        const subject = ee?.subject || ''
        if (conversation.isNew) {
          replaceDynamicTagsText(ee.replacedText, ee.replacedSubject)
        } else {
          const result = await messageActions.messagePreviewDynamicTags(
            ee.subvalue,
            conversation._id,
            subject
          )
          if (result.success) {
            replaceDynamicTagsText(result.data.message, result.data.subject)
          } else {
            notifyError(getTextServerError(result.errMsg))
          }
        }
      }}
      onSelectItem={(item, ee) => {
        setIsTemplate(true)
        if (item?.media?.length && !ee.subject) {
          setuploadFiles((oldList) => {
            oldList.push(...item.media)
            return [...oldList]
          })
        }
      }}
      onLoadMore={onLoadMore}
    />
  )
}

export default SelectMessageTemplate
