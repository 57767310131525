import React, { useEffect, useState } from 'react'
import { Carousel, Tag } from 'antd'
import { useSelector } from 'react-redux'
import SVGArrowCollapse from '../../../../icons/SVG/SVGArrowCollapse'
import { getText } from '../../../../lang'
import SVGFilterMyMessages from '../../../../icons/SVG/SVGFilterMyMessages'
import SVGFilterArchived from '../../../../icons/SVG/SVGFilterArchived'
import SVGFilterMainInbox from '../../../../icons/SVG/SVGFilterMainInbox'
import SVGFilterBroadcast from '../../../../icons/SVG/SVGFilterBroadcast'
import SVGFilterUnassigned from '../../../../icons/SVG/SVGFilterUnassigned'
import {
  ALL,
  ARCHIVED,
  BROADCAST,
  MY_MESSAGES,
  UNASSIGNED,
} from '../../utils/constants'
import './MessageTypeList.scss'

const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => {
  return (
    <button {...props} type='button'>
      <SVGArrowCollapse style={{ transform: 'rotate(90deg)' }} />
    </button>
  )
}
const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => {
  return (
    <button {...props} type='button'>
      <SVGArrowCollapse style={{ transform: 'rotate(-90deg)' }} />
    </button>
  )
}

const MessageTypesList = ({ screenSize, onChange, messageType }) => {
  const [listType, setlistType] = useState([])
  const [initialSlide, setInitialSlide] = useState(0)
  const [loading, setLoading] = useState(true)

  const carouselSettings = {
    dots: false,
    infinite: screenSize !== 'tabletPortrait',
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    draggable: true,
    variableWidth: true,
    focusOnSelect: true,
    nextArrow: <SlickArrowLeft />,
    prevArrow: <SlickArrowRight />,
    initialSlide: initialSlide,
  }

  let unreadMessages = useSelector((state) => {
    return state.authReducer.unreadMessages
  })

  let myMessagesInboxUnreadMessages = useSelector((state) => {
    return state.authReducer.myMessagesInboxUnreadMessages
  })

  let archivedMessages = useSelector((state) => {
    return state.authReducer.archivedMessages
  })

  let unassignedMessages = useSelector((state) => {
    return state.authReducer.unassignedMessages
  })

  let organization = useSelector((state) => {
    return state.authReducer.user.organization
  })

  let user = useSelector((state) => {
    return state.authReducer.user
  })

  useEffect(() => {
    const getFilterList = () => {
      const {
        restrictSupportAgentsAccessToUnassignedFolder,
        restrictSupportAgentsAccessToMainInboxFolder,
      } = organization.restrictions || {}

      let list = [
        ...(Boolean(
          !restrictSupportAgentsAccessToMainInboxFolder || !user.isSupportAgent
        )
          ? [
              {
                name: getText('MSG_OPTION_MAIN_INBOX'),
                img: <SVGFilterMainInbox />,
                value: ALL,
              },
            ]
          : []),
        {
          name: getText('MSG_OPTION_MY_MESSAGES'),
          count: myMessagesInboxUnreadMessages || true,
          img: <SVGFilterMyMessages />,
          value: MY_MESSAGES,
        },
        {
          name: getText('MSG_OPTION_ARCHIVED'),
          img: <SVGFilterArchived />,
          value: ARCHIVED,
        },
        ...(Boolean(!user.isSupportAgent)
          ? [
              {
                name: getText('WORD_BROADCAST_UPPER'),
                img: <SVGFilterBroadcast />,
                value: BROADCAST,
              },
            ]
          : []),
        ...(Boolean(
          !restrictSupportAgentsAccessToUnassignedFolder || !user.isSupportAgent
        )
          ? [
              {
                name: getText('MSG_OPTION_UNASSIGNED'),
                count: unassignedMessages || true,
                img: <SVGFilterUnassigned />,
                value: UNASSIGNED,
              },
            ]
          : []),
      ]

      return list
    }
    const list = getFilterList()
    setlistType(list)

    const slideIndex = list.findIndex((item) => item.value === messageType)
    setInitialSlide(slideIndex !== -1 ? slideIndex : 0)
    setLoading(false)
  }, [
    myMessagesInboxUnreadMessages,
    unreadMessages,
    unassignedMessages,
    archivedMessages,
  ])

  return loading ? null : (
    <Carousel className='message_type--carousel' {...carouselSettings}>
      {listType?.map((item, index) => {
        return (
          <Tag.CheckableTag
            key={index}
            className='message_type-item'
            checked={item.value === messageType}
            onClick={() => {
              onChange && onChange(item.value)
            }}
          >
            {item.img}
            {item.name}
            {item.count && (
              <div
                className='message_type-item--count'
                style={
                  typeof item.count !== 'number'
                    ? { backgroundColor: 'var(--thridyTextColor)' }
                    : {}
                }
              >
                {typeof item.count === 'number' ? item.count : 0}
              </div>
            )}
          </Tag.CheckableTag>
        )
      })}
    </Carousel>
  )
}

export default MessageTypesList
