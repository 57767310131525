import React from 'react'
import MatRow from '../../../../components/MatRow'
import { getText } from '../../../../lang'
import SwitchForm from '../../../../components/Form/SwitchForm'

const ConversationAITagUsersSwitch = () => {
  return (
    <MatRow className={'conversation-ai-two-switchs'}>
      <div className='title'>{getText('TEXT_WHEN_TAG_USERS')}</div>
      <MatRow flexEndCol className={'conversation-ai-two-switchs-wrapper'}>
        <SwitchForm
          name={['tagging_configuration', 'tag_within_hours']}
          text={getText('WORD_OPENING_HOURS')}
        />
        <SwitchForm
          name={['tagging_configuration', 'tag_outside_hours']}
          text={getText('WORD_AFTER_HOURS')}
        />
      </MatRow>
    </MatRow>
  )
}

export default ConversationAITagUsersSwitch
