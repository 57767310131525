import React from 'react'
import MatRow from '../../../../components/MatRow'
import './OrganizationPage.scss'

const OrganizationPage = (props) => {
  const { children, title, subtitle, switchBox, style, className, headerRight } =
    props

  return (
    <div className={`organization-page ${className || ''}`}>
      <div
        className='organization-page-header'
        style={{
          flexDirection: 'row',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div>
          {title && <div className='title'>{title}</div>}
          <MatRow>
            {subtitle && <div className='subtitle'>{subtitle}</div>}
            {switchBox && switchBox}
          </MatRow>
        </div>
        {headerRight && headerRight}
      </div>
      <div style={style} className='organization-page-body'>
        {children}
      </div>
    </div>
  )
}

export default OrganizationPage
