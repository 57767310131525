import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='90'
      height='237'
      viewBox='0 0 90 237'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        opacity='0.15'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5 187L5.00001 51L85 51L85 187L5 187Z'
        fill='url(#paint0_linear_14418_40689)'
      />
      <path
        d='M45 67L45 207'
        stroke='url(#paint1_linear_14418_40689)'
        strokeWidth='50'
      />
      <circle opacity='0.25' cx='45' cy='77' r='45' fill='white' />
      <circle cx='45' cy='77' r='40' fill='#F9C11E' />
      <circle cx='45' cy='77' r='35' fill='#F8BA05' />
      <circle cx='45' cy='77' r='34.5' stroke='white' strokeOpacity='0.3' />
      <path
        d='M56.2337 64.4756H44.3931C43.6388 64.4756 43.027 65.0874 43.027 65.8417V67.8888C44.0742 68.1713 44.9962 68.7145 45.7088 69.487C46.926 70.8064 47.4762 72.6561 47.2998 74.8356C47.2847 75.019 47.2586 75.2105 47.2264 75.4051H49.7887V77.7414C49.7887 78.2485 50.1267 78.4227 50.5397 78.1306L54.3857 75.4054H56.234C56.9883 75.4054 57.6001 74.7933 57.6001 74.0393V65.842C57.5999 65.0874 56.9878 64.4756 56.2337 64.4756Z'
        fill='white'
      />
      <path
        d='M41.2966 69.0298C41.0577 69.0298 40.8125 69.0441 40.5616 69.0729C38.1267 69.3529 36.6724 71.1681 36.5147 73.5056C36.3557 75.8875 37.2388 77.6471 38.1777 78.937C38.5887 79.5015 39.0196 79.8647 38.9535 80.5445C38.8763 81.3481 38.0169 81.5721 37.4018 81.8191C36.6727 82.1122 35.8884 82.5569 35.5174 82.7618C34.2409 83.4674 32.8397 84.3158 32.5247 85.477C31.827 88.051 34.1826 88.8308 36.1272 89.1909C37.7963 89.4995 39.6779 89.5236 41.2255 89.5236C44.0252 89.5236 49.0601 89.4116 49.8713 87.3065C50.1026 86.7093 50.0035 85.7573 49.8713 85.3664C49.3623 83.8488 47.3295 82.8987 45.8813 82.2626C45.314 82.0137 43.7413 81.592 43.5531 80.8774C43.2563 79.7471 44.383 78.8858 44.8837 77.9959C45.4112 77.0574 45.8511 75.7873 45.9367 74.7261C46.2226 71.2012 44.2453 69.0295 41.2966 69.0298Z'
        fill='white'
      />
      <defs>
        <linearGradient
          id='paint0_linear_14418_40689'
          x1='45'
          y1='51'
          x2='45'
          y2='187'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F8BA05' />
          <stop offset='1' stopColor='#F8BA05' stopOpacity='0.3' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_14418_40689'
          x1='45.5'
          y1='67'
          x2='45.5'
          y2='207'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F8BA05' stopOpacity='0.1' />
          <stop offset='1' stopColor='#F8BA05' stopOpacity='0.2' />
        </linearGradient>
      </defs>
    </svg>
  )
}

export const SVGBotAndRatingYellow = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}
