import React, { useState, useEffect } from 'react'
import organizationActions from '../../../store/modules/organizationActions'
import authActions from '../../../store/modules/authActions'
import { getText, getTextServerError } from '../../../lang'
import { checkFormatPhoneNumber, MainColor } from '../../../utils'
import SVGAiBotRobotIcon from '../../../icons/SVG/SVGAiBotRobotIcon'
import IconOrganization from '../../../icons/IconOrganization'
import SVGBroadcasting from '../../../icons/SVG/SVGBroadcasting'
import SVGOrgPaymentIcon from '../../../icons/SVG/SVGOrgPaymentIcon'
import { notifyError, notifySuccess } from '../../../utils/Notify'
import OrganizationFieldItem from '../components/organizationFieldItem'
import SwitchWithText from '../components/SwitchWithText'
import OrganizationListItemFieldHeader from './OrganizationListItemFieldHeader'

const OrganizationListItem = (props) => {
  const { item } = props

  const [aiBotEnable, setAiBotEnable] = useState(item.enable_auto_reply)
  const [broadcasEnable, setBroadcasEnable] = useState(item.enable_broadcast)
  const [paymentsEnable, setPaymentsEnable] = useState(item.enable_payments)

  const user = authActions.getUserData()

  const handleOnChangeAiBoth = async () => {
    if (!user.isSuperAdmin) {
      notifyError(
        getText('ERROR_MESSAGE_YOU_DO_NOT_HAVE_ACCESS_TO_ENABLE_THIS_FEATURE')
      )
      return
    }
    const obj = {
      enable_auto_reply: !item.enable_auto_reply,
    }
    let result = await organizationActions.setAutoReply(obj, item._id)
    if (result.success) {
      setAiBotEnable(!aiBotEnable)
      notifySuccess(getText('NTF_SUCCESS_AI_BOT_WAS_CHANGED_SUCCESSFULLY'))
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
  }

  const handleOnChangeBroadcasting = async () => {
    if (!user.isSuperAdmin) {
      notifyError(
        getText('ERROR_MESSAGE_YOU_DO_NOT_HAVE_ACCESS_TO_ENABLE_THIS_FEATURE')
      )
      return
    }
    const obj = {
      enable_broadcast: !item.enable_broadcast,
    }
    let result = await organizationActions.setBroadcasting(obj, item._id)
    if (result.success) {
      setBroadcasEnable(!broadcasEnable)
      notifySuccess(getText('NTF_SUCCESS_BROADCASTING_WAS_CHANGED_SUCCESSFULLY'))
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
  }

  const handleOnChangePayments = async () => {
    if (!user.isSuperAdmin && !user.accessibleOrganizations.length) {
      notifyError(
        getText('ERROR_MESSAGE_YOU_DO_NOT_HAVE_ACCESS_TO_ENABLE_THIS_FEATURE')
      )
      return
    }

    const obj = {
      enable_payments: !item.enable_payments,
    }
    let result = await organizationActions.setSwitchPaymentsStatus(obj, item._id)
    if (result.success) {
      if (!obj.enable_payments) {
        await organizationActions.disconnectPayment(item._id)
      }
      setPaymentsEnable(!paymentsEnable)
      notifySuccess(getText('NTF_SUCCESS_PAYMENT_WAS_CHANGED_SUCCESSFULLY'))
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
  }

  useEffect(() => {
    setAiBotEnable(item.enable_auto_reply)
    setBroadcasEnable(item.enable_broadcast)
  }, [item])

  return (
    <div className='organization-item'>
      <OrganizationListItemFieldHeader item={item} />
      <OrganizationFieldItem
        fieldValuesList={[
          {
            icon: IconOrganization.phone,
            label: getText('WORD_PHONE'),
            rightItem: checkFormatPhoneNumber(item.twilio_number),
          },
          {
            icon: IconOrganization.organization,
            label: getText('WORD_ID'),
            rightItem: item._id,
          },
        ]}
      />

      <SwitchWithText
        title={getText('TITLE_DRIP_SEQUENCE')}
        value={aiBotEnable}
        onChangeSwitch={handleOnChangeAiBoth}
        icon={<SVGAiBotRobotIcon color={aiBotEnable ? MainColor : ''} />}
        className='organization-item-switch'
        leftIcon
        noText
      />
      <SwitchWithText
        title={getText('WORD_BROADCASTING')}
        value={broadcasEnable}
        onChangeSwitch={handleOnChangeBroadcasting}
        icon={<SVGBroadcasting color={broadcasEnable ? MainColor : ''} />}
        className='organization-item-switch'
        leftIcon
        noText
      />
      <SwitchWithText
        title={getText('WORD_PAYMENTS')}
        value={paymentsEnable}
        onChangeSwitch={handleOnChangePayments}
        icon={
          <SVGOrgPaymentIcon color={paymentsEnable ? MainColor : ''} width='17px' />
        }
        className='organization-item-switch'
        leftIcon
        noText
      />
    </div>
  )
}

export default OrganizationListItem
