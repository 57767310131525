import {
  fetchFromUrlPOSTAsync,
  fetchFromUrlGETAsync,
  fetchFromUrlPUTAsync,
  fetchFromUrlDELETEAsync,
} from '../../utils/UrlHelper'

const urlQuestions = '/questions'

const fs = {
  getPageList: async (start, range, searchKeyword = '', paginationOptions = {}) => {
    const urlParams = [
      { key: 'page', value: start + 1 },
      { key: 'perPage', value: range },
    ]
    if (paginationOptions._location_id) {
      urlParams.push({ key: '_location_id', value: paginationOptions._location_id })
    }
    const result = await fetchFromUrlGETAsync(urlQuestions, urlParams)
    if (result.success) {
      result.max = result.data.total_items
      result.data = result.data.questions
    }
    return result
  },
  getAllList: async (_location_id) => {
    // const urlParams = [
    //     { key: "page", value: start + 1 },
    //     { key: "perPage", value: range },
    // ];
    const urlParams = []
    if (_location_id) {
      urlParams.push({ key: '_location_id', value: _location_id })
    }
    const result = await fetchFromUrlGETAsync(urlQuestions, urlParams)
    if (result.success) {
      result.max = result.data.total_items
      result.data = result.data.questions
    }
    return result
  },

  // getSingle: async (itemId) => {
  //     const result = await fetchFromUrlGETAsync(urlQuestions + "/" + itemId, []);
  //     return result;
  // },

  insert: async (obj) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    const result = await fetchFromUrlPOSTAsync(urlQuestions, urlParams)
    return result
  },

  update: async (obj, question_id) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    const result = await fetchFromUrlPUTAsync(
      urlQuestions + '/' + question_id,
      urlParams
    )
    return result
  },

  delete: async (locId) => {
    const result = await fetchFromUrlDELETEAsync(urlQuestions + '/' + locId, [])
    return result
  },
}

const loc = {}

const questionsActions = Object.assign(fs, loc)

export default questionsActions
