import {
  fetchFromUrlDELETEAsync,
  fetchFromUrlGETAsync,
  fetchFromUrlPATCHAsync,
  fetchFromUrlPOSTAsync,
  fetchFromUrlPUTAsync,
} from '../../utils/UrlHelper'

const urlLocations = '/locations'
const urlFacebookPages = '/facebook/{location_id}/pages'
const urlFacebookPageForms = '/facebook/{location_id}/forms'
const urlFacebookAccountPages = '/facebook/{location_id}/account-pages'
const urlFacebookBusinessAccounts = '/facebook/{location_id}/business-accounts'
const urlFacebookBusinessAccountPages = '/facebook/{location_id}/business-pages'
const urlCoupon = '/coupons/location'
const urlPopup = '/popups/location'
const urlIntegratedCta = '/cta/location'
const urlCalendarReminderSequence = '/calender-reminders/locations'
const urlCalendarReminderSequenceMessage = '/calender-reminders'
const subscriberGrowth = '/subscriber-growth/location'
const locationsByQuestion = '/chat-designers/questions'

const fs = {
  getPageList: async (
    start,
    range,
    searchKeyword = '',
    paginationOptions = {},
    enabled
  ) => {
    const urlParams = [
      { key: 'page', value: start + 1 },
      { key: 'perPage', value: range },
    ]

    if (enabled !== 'all') {
      urlParams.push({ key: 'enabled', value: enabled })
    }

    if (searchKeyword !== '') {
      urlParams.push({ key: 'search', value: searchKeyword })
    }

    const result = await fetchFromUrlGETAsync(urlLocations, urlParams)
    if (result.success) {
      result.max = result.data.total_items
      result.data = result.data.locations
    }
    return result
  },

  getSingle: async (itemId, includeFacebook = false) => {
    const urlParams = includeFacebook
      ? [{ key: 'includeFacebook', value: includeFacebook }]
      : []
    const result = await fetchFromUrlGETAsync(
      urlLocations + '/' + itemId,
      urlParams,
      10000
    )
    return result
  },

  saveNew: async (obj) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    const result = await fetchFromUrlPOSTAsync(urlLocations, urlParams)
    return result
  },

  saveUpdate: async (obj, locId) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    const result = await fetchFromUrlPUTAsync(urlLocations + '/' + locId, urlParams)
    return result
  },

  saveCRMIntegrationConfigs: async (obj, locationId) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })

    return fetchFromUrlPUTAsync(
      `${urlLocations}/${locationId}/crm-integration`,
      urlParams
    )
  },

  saveLocationReviewInvite: async (obj, locId) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    const result = await fetchFromUrlPATCHAsync(
      urlLocations + '/' + locId + '/edit-invite',
      urlParams
    )
    return result
  },

  delete: async (locId) => {
    const result = await fetchFromUrlDELETEAsync(urlLocations + '/' + locId, [])
    return result
  },

  checkLoginForInvitationBooking: async (locId) => {
    const result = await fetchFromUrlGETAsync(
      urlLocations + '/auth/' + locId + '/invitation',
      []
    )
    return result
  },

  saveTextMeButton: async (obj, locId) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    const result = await fetchFromUrlPOSTAsync(
      urlLocations + '/' + locId + '/textmedata',
      urlParams
    )
    return result
  },

  getTextMeButton: async (locId) => {
    if (!locId) {
      return {}
    }

    const result = await fetchFromUrlGETAsync(
      urlLocations + '/' + locId + '/textmedata',
      []
    )
    return result
  },

  deleteTextMeButton: async (locId) => {
    const result = await fetchFromUrlDELETEAsync(
      urlLocations + '/' + locId + '/textmedata',
      []
    )
    return result
  },
  getOneLocationByConv_location_id: async (locId) => {
    const result = await fetchFromUrlGETAsync(urlLocations + '/' + locId, [])
    return result
  },

  getVinSolutionUsers: async (locationId, filterObject = []) => {
    const urlParams = Object.keys(filterObject).map((key) => {
      return { key, value: filterObject[key] }
    })
    return await fetchFromUrlGETAsync(
      urlLocations + '/' + locationId + '/vinsolution/users',
      urlParams
    )
  },

  getFacebookPages: async (locationId, next = false, cursor = '') => {
    const urlParams = next
      ? [
          { key: 'next', value: next },
          { key: 'cursor', value: cursor },
        ]
      : []
    return await fetchFromUrlGETAsync(
      urlFacebookPages.replace('{location_id}', locationId),
      urlParams
    )
  },

  getFacebookAccountPages: async (locationId) => {
    return await fetchFromUrlGETAsync(
      urlFacebookAccountPages.replace('{location_id}', locationId),
      []
    )
  },

  getFacebookBusinessAccounts: async (locationId) => {
    return await fetchFromUrlGETAsync(
      urlFacebookBusinessAccounts.replace('{location_id}', locationId),
      []
    )
  },

  getFacebookBusinessAccountPages: async (locationId, idBusiness) => {
    return await fetchFromUrlGETAsync(
      `${urlFacebookBusinessAccountPages.replace('{location_id}', locationId)}/${idBusiness}`,
      []
    )
  },

  setFacebookPages: async (page, locationId) => {
    const urlParams = Object.keys(page).map((key) => {
      return { key, value: page[key] }
    })
    return await fetchFromUrlPUTAsync(
      urlFacebookPages.replace('{location_id}', locationId),
      urlParams
    )
  },

  switchFacebookMessenger: async (locationId, enabled) => {
    const urlParams = [{ key: 'enabled', value: enabled }]
    return await fetchFromUrlPATCHAsync(
      urlFacebookPages.replace('{location_id}', locationId),
      urlParams
    )
  },

  getFacebookPageForms: async (locationId, next = false, cursor = '') => {
    const urlParams = next
      ? [
          { key: 'next', value: next },
          { key: 'cursor', value: cursor },
        ]
      : []
    return await fetchFromUrlGETAsync(
      urlFacebookPageForms.replace('{location_id}', locationId),
      urlParams
    )
  },

  selectFacebookPageForm: async (locationId, form, isAdded) => {
    const urlParams = Object.keys(form).map((key) => {
      return { key, value: form[key] }
    })
    urlParams.push({ key: 'isAdded', value: isAdded })
    return await fetchFromUrlPUTAsync(
      urlFacebookPageForms.replace('{location_id}', locationId),
      urlParams
    )
  },

  saveCalendarInvite: async (obj, locId) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    const result = await fetchFromUrlPATCHAsync(
      urlLocations + '/' + locId + '/calendar-invite-message',
      urlParams
    )
    return result
  },

  getCoupon: async (locationId) => {
    const urlParams = []
    return await fetchFromUrlGETAsync(urlCoupon + '/' + locationId, urlParams)
  },

  saveCoupon: async (obj, locationId) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    return await fetchFromUrlPUTAsync(urlCoupon + '/' + locationId, urlParams)
  },

  getPopup: async (locationId) => {
    const urlParams = []
    return await fetchFromUrlGETAsync(urlPopup + '/' + locationId, urlParams)
  },

  savePopup: async (obj, locationId) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    return await fetchFromUrlPUTAsync(urlPopup + '/' + locationId, urlParams)
  },

  saveIntegratedCta: async (obj, locId) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    const result = await fetchFromUrlPUTAsync(
      urlIntegratedCta + '/' + locId,
      urlParams
    )
    return result
  },

  getIntegratedCta: async (locId) => {
    const result = await fetchFromUrlGETAsync(urlIntegratedCta + '/' + locId, [])
    return result
  },

  getCalendarReminderSequence: async (locationId) => {
    const urlParams = [{ key: 'location_id', value: locationId }]
    return await fetchFromUrlGETAsync(
      urlCalendarReminderSequence + '/' + locationId,
      urlParams
    )
  },

  saveUpdateSequenceCalendarInviteMessege: async (obj, id) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    return fetchFromUrlPUTAsync(
      urlCalendarReminderSequenceMessage + '/' + id,
      urlParams
    )
  },

  getSubscriberGrowth: async (locationId) => {
    const urlParams = []
    return await fetchFromUrlGETAsync(subscriberGrowth + '/' + locationId, urlParams)
  },

  saveSubscriberGrowth: async (obj, locationId) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    return await fetchFromUrlPUTAsync(subscriberGrowth + '/' + locationId, urlParams)
  },

  getLocationsByQuestion: async ({ text, language }) => {
    const urlParams = [
      {
        key: 'text',
        value: text,
      },
      {
        key: 'language',
        value: language,
      },
    ]

    const result = await fetchFromUrlPOSTAsync(locationsByQuestion, urlParams)
    return result
  },
}

const loc = {}

const locationActions = Object.assign(fs, loc)

export default locationActions
