import React, { useRef, forwardRef, useImperativeHandle } from 'react'
import { Input } from 'antd'
import './InputText.scss'

const deviceSize = ['mobile', 'tabletPortrait', 'tablet']

const InputTextSecondary = forwardRef((props, ref) => {
  const inputAreaRef = useRef()

  useImperativeHandle(ref, () => ({
    autoFocus() {
      if (!deviceSize.includes(props.screenSize)) {
        inputAreaRef.current && inputAreaRef.current.focus()
      }
    },
  }))

  return (
    <Input
      className={`mat-input-affix-wrapper mat-input-affix-secondary-wrapper ${
        props.className || ''
      }`}
      ref={ref}
      id={props.id}
      style={props.style}
      prefix={props.prefix}
      placeholder={props.placeholder ? props.placeholder : props.label}
      allowClear={props.allowClear}
      disabled={props.disabled}
      onPressEnter={props.onPressEnter}
      value={props.value}
      autoComplete={props.autoComplete}
      onChange={(e) => {
        if (e.target.value === '') {
          props.onClear()
        }
        props.onChange && props.onChange(e)
      }}
      onClick={props.onClick}
      onBlur={props.onBlur}
      maxLength={props.maxLength}
    />
  )
})

InputTextSecondary.defaultProps = {
  allowClear: true,
  autoComplete: 'off',
  onPressEnter: (e) => {
    e.preventDefault()
  },
  onClear: () => {},
  maxLength: 255,
  minLength: 0,
}

export default InputTextSecondary
