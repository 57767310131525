import React, { useEffect } from 'react'
import { Collapse, Form } from 'antd'
import _ from 'lodash'
import userActions from '../../store/modules/userActions'
import authActions from '../../store/modules/authActions'
import { getText, getTextServerError } from '../../lang'
import {
  checkAustralianPhoneNumber,
  checkFormatPhoneNumber,
  getInitialCountry,
} from '../../utils'
import IconOrganization from '../../icons/IconOrganization'
import PageFix from '../../components/PageFix'
import MatForm from '../../components/Form/MatForm'
import InputFormEmail from '../../components/Form/InputFormEmail'
import InputFormText from '../../components/Form/InputFormText'
import { notifyError, notifySuccess } from '../../utils/Notify'
import InputFormPhone from '../../components/Form/InputFormPhone'
import { DEALER_SOCKET, PBS, ELEADS } from '../../config/constants'
import OrganizationFieldItem from '../organization/components/organizationFieldItem'
import SelectForm from '../../components/Form/SelectForm'

const ProfileEdit = (props) => {
  const location = authActions.getLocation() || {}
  const [form] = Form.useForm()

  useEffect(() => {
    props.setPageTitle && props.setPageTitle(getText('WORD_PROFILE_INFORMATION'))
    props.setFooterButtons && props.setFooterButtons(['save'])
    userActions.refreshUserFS().catch()
  }, [])

  const onFinish = async (values) => {
    if (!values.phone && !values.email) {
      notifyError(getText('ERR_NO_PHONE_OR_EMAIL'))
      return
    }

    if (values.phone && values.phone.startsWith('04')) {
      values.phone = `61${values.phone.slice(1)}`
    }

    if (
      values.phone &&
      values.phone.substring(0, 1) !== '1' &&
      values.phone.substring(0, 2) !== '61'
    ) {
      notifyError(getTextServerError(getText('ERROR_WRONG_PHONE_FORMAT')))
      return
    }

    const obj = {
      isSuperAdmin: props.user.isSuperAdmin,
      isAdmin: props.user.isAdmin,
      isManager: props.user.isManager,
      isSupportAgent: props.user.isSupportAgent,
      _location_id: props.user._location_id,
      enabled: props.user.enabled,
      accessibleOrganizations: props.user.accessibleOrganizations,
      firstName: values.firstName,
      lastName: values.lastName,
      phone: values.phone,
      email: values.email,
      notifications: props.user.notifications,
      language: values.language,
    }

    const notificationNames = [
      'unreadMessage',
      'assignToConversation',
      'unassignFromConversation',
      'paymentStatus',
    ]

    if (!values.phone) {
      notificationNames.forEach((notification) => {
        obj.notifications[notification].method = props.user.notifications[
          notification
        ].method.filter((nt) => nt !== 'phone')

        if (obj.notifications[notification].method.length === 0) {
          obj.notifications[notification].enabled = false
        }
      })
    }

    if (!values.email) {
      notificationNames.forEach((notification) => {
        obj.notifications[notification].method = props.user.notifications[
          notification
        ].method.filter((nt) => nt !== 'email')
        if (obj.notifications[notification].method.length === 0) {
          obj.notifications[notification].enabled = false
        }
      })
    }

    Object.assign(obj, {
      crmInfo: {
        dealerSocket: {
          username: values.dsUsername,
        },
        eLeads: {
          id: '',
          fullName: '',
          ...(props.user.crmInfo.eLeads || {}),
        },
        pbs: {
          EmployeeRef: '',
          Name: '',
          Primary: false,
          Role: '',
          ...(props.user.crmInfo.pbs || {}),
        },
        activix: {
          agent_id: '',
          ...(props.user.crmInfo.activix || {}),
        },
      },
    })

    const result = await userActions.saveUpdate(obj, props.user._id)
    if (result.success) {
      authActions.setUserData(result.data.user)
      // form.resetFields()
      notifySuccess(getText('NTF_SUCCESS_SAVE_PROFILE'))
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
  }

  const onFinishFailed = ({ values }) => {
    if (
      !values.firstName ||
      values.firstName.match(/^ *$/) ||
      !values.lastName ||
      values.lastName.match(/^ *$/)
    ) {
      notifyError(getText('ERR_NO_NAME_OR_SURNAME'))
    }
  }

  const handleEmailChange = (e) => {
    let value = e.target.value
    if (value !== '' && form.getFieldError('phone').length) {
      form.validateFields()
    }
  }

  const RenderCrmInfo = () => {
    if (!props.user || !props.user.crmInfo) {
      return <></>
    }

    const { dealerSocket, eLeads, pbs } = props.user.crmInfo

    let userId = ''
    let userName = ''
    let userRole = ''

    const fieldsList = []

    switch (location.crmType) {
      case DEALER_SOCKET: {
        userName = dealerSocket.username
        break
      }

      case ELEADS: {
        userId = eLeads && eLeads.id
        userName = eLeads && eLeads.fullName
        break
      }

      case PBS: {
        userId = pbs.EmployeeRef
        userName = pbs.Name
        userRole = pbs.Role
        break
      }
      default:
        break
    }

    if (userId) {
      fieldsList.push({
        icon: IconOrganization.organization,
        label: getText('WORD_USER_ID'),
        rightItem: userId,
      })
    }

    if (userName) {
      fieldsList.push({
        icon: IconOrganization.organization,
        label: getText('WORD_USER_NAME'),
        rightItem: userName,
      })
    }

    if (userRole) {
      fieldsList.push({
        icon: IconOrganization.organization,
        label: getText('WORD_USER_ROLE'),
        rightItem: userRole,
      })
    }

    const items = [
      {
        key: '1',
        label: getText('TEXT_SYNCHRONIZED_CRM_INFO'),
        children: <OrganizationFieldItem fieldValuesList={fieldsList} />,
      },
    ]

    return (
      (userId || userName || userRole) && (
        <Collapse
          className='create-user-notification-collapse'
          items={items}
          ghost
        />
      )
    )
  }

  return (
    <PageFix title={getText('WORD_PROFILE_EDIT')}>
      <MatForm
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{
          firstName: props.user ? props.user.firstName : '',
          lastName: props.user ? props.user.lastName : '',
          email: props.user ? props.user.email : '',
          phone: !_.isEmpty(props.user)
            ? props.user.phone
              ? checkFormatPhoneNumber(props.user.phone, true)
              : checkAustralianPhoneNumber(props.user.phone)
            : '',
          dsUsername:
            (props.user &&
              props.user.crmInfo &&
              props.user.crmInfo.dealerSocket &&
              props.user.crmInfo.dealerSocket.username) ||
            '',
          language: props.user ? props.user.language : 'en',
        }}
      >
        <InputFormText
          name='firstName'
          label={getText('WORD_FIRST_NAME')}
          required
        />
        <InputFormText name='lastName' label={getText('WORD_LAST_NAME')} required />
        <InputFormEmail
          name='email'
          label={getText('WORD_EMAIL')}
          placeholder={getText('WORD_EMAIL')}
          onChange={handleEmailChange}
        />
        <InputFormPhone
          autoCompleteCountryCode
          name='phone'
          initialCountry={getInitialCountry(props.user.phone)}
          label={getText('WORD_PHONE')}
          onChange={(value) => {
            form.setFieldsValue({ phone: value })
          }}
        />

        <SelectForm
          label={getText('WORD_LANGUAGE')}
          name={'language'}
          options={[
            {
              value: 'en',
              label: getText('TEXT_ENGLISH'),
            },
            {
              value: 'fr',
              label: getText('TEXT_FRENCH'),
            },
          ]}
          showSearch={false}
        />

        {location.crmType === DEALER_SOCKET && (
          <InputFormText
            name='dsUsername'
            label={getText('WORD_DEALERSOCKET_USERNAME')}
          />
        )}
        <RenderCrmInfo />
      </MatForm>
    </PageFix>
  )
}

export default ProfileEdit
