import React from 'react'
import { Tooltip } from 'antd'
import SVGInfoIconOrange from '../../icons/SVG/SVGInfoIconOrange'
import './InactiveBlock.scss'

const InactiveBlock = ({ tooltipTitle, additionalStyles }) => {
  return (
    <Tooltip title={tooltipTitle}>
      <div className='inactive_block' style={additionalStyles}>
        <p>Inactive</p>
        <SVGInfoIconOrange />
      </div>
    </Tooltip>
  )
}

export default InactiveBlock
