import React, { memo } from 'react'
import { motion } from 'framer-motion'
import SVGBroadcastingIcon from '../../../../../icons/SVG/SVGBroadcastingIcon'
import SVGReplyIcon from '../../../../../icons/SVG/SVGReplyIcon'
import { getText } from '../../../../../lang'
import { formatDateTime, MainColor } from '../../../../../utils'
import ProfileLetters from '../../../../../components/ProfileLetters'
import CenterRenderImage from '../CenterRenderImage'
import { MESSAGE_BLOCK_ANIMATIONS } from '../../../utils/messageUtils'
import MessageMarkAsReadButton from '../../centerMessageHeader/MessageMarkAsReadButton/MessageMarkAsReadButton'
import { AI_BOT, BOT, RECEIVED, SEQUENCE } from '../../../utils/constants'
import SVGDrawerSequence from '../../../../../icons/SVG/SVGDrawerSequence'

const CenterEmail = (props) => {
  const { message, addSubject, conversation, messageListType, lastNotSentMessage } =
    props

  const getPosterName = () => {
    if (
      message.type === BOT &&
      (message.whoSentThis_name === AI_BOT || message.whoSentThis_name === SEQUENCE)
    ) {
      return getText('WORD_SEQUENCE')
    }
    return message.whoSentThis_name || conversation.receiver.fullName || message.from
  }

  const posterName = getPosterName()

  return (
    <motion.div
      className={`message email_message ${
        message.type === 'received' ? ' left ' : ' right '
      }`}
      {...MESSAGE_BLOCK_ANIMATIONS}
      layout
    >
      <div className='message--inner-item'>
        <div className='post_info email'>
          <div className='post_info'>
            {message.broadcast_id && (
              <div style={{ marginLeft: 10, display: 'flex', alignItems: 'center' }}>
                <SVGBroadcastingIcon color={'#A3A6BE'} />
              </div>
            )}
            {Boolean(
              message.type === BOT &&
                (message.whoSentThis_name === AI_BOT ||
                  message.whoSentThis_name === SEQUENCE)
            ) && (
              <SVGDrawerSequence
                color={MainColor}
                height={16}
                width={16}
                style={{ marginLeft: 10 }}
              />
            )}
            <span className='author post_info-ellipsis'>{posterName}</span>

            {message.source_name && (
              <span className='incoming_lead_type'>{message.source_name}</span>
            )}
            <span className='phone post_info-ellipsis'>{message.from}</span>
          </div>
          <span
            onClick={() => {
              addSubject(message)
            }}
            className='phone email_reply '
          >
            <SVGReplyIcon />
            {getText('WORD_REPLY')}
          </span>
        </div>
        <div className='post'>
          <div className='post_email'>
            <span className='post_email_subject'>{getText('WORD_SUBJECT')}</span>
            <h6 className='post_email_subject-body'>{message.subject}</h6>
          </div>
          <div className='post_email-body'>{message.body}</div>
          {message.media.length ? (
            <div>
              <div className='message--inner-item--images'>
                <CenterRenderImage
                  images={message.media}
                  dataLength={message.media.length}
                />
              </div>
            </div>
          ) : null}
        </div>
        <div className='date'>{formatDateTime(message.createdAt)}</div>
        <div className='message--inner-item--buttons'>
          {lastNotSentMessage?._id === message._id &&
            lastNotSentMessage.type === RECEIVED &&
            conversation.is_unread && (
              <MessageMarkAsReadButton
                conversation={conversation}
                messageType={messageListType}
              />
            )}
        </div>
      </div>
      <ProfileLetters
        withoutIcon
        className='medium'
        name={posterName}
        customeName={posterName === getText('WORD_SEQUENCE') ? 'SQ' : false}
      />
    </motion.div>
  )
}

export default memo(CenterEmail)
