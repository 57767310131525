import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width={props.width || '12'}
    height={props.height || '8'}
    viewBox='0 0 12 8'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M10.6686 0C10.4917 0 10.3222 0.070238 10.1971 0.195262C10.0721 0.320287 10.0019 0.489856 10.0019 0.666667V3.33333C10.0019 3.51014 9.93165 3.67971 9.80662 3.80474C9.6816 3.92976 9.51203 4 9.33522 4H2.94188L3.80855 3.14C3.93409 3.01446 4.00461 2.8442 4.00461 2.66667C4.00461 2.48913 3.93409 2.31887 3.80855 2.19333C3.68302 2.0678 3.51275 1.99727 3.33522 1.99727C3.15768 1.99727 2.98742 2.0678 2.86188 2.19333L0.861884 4.19333C0.80119 4.25674 0.753614 4.3315 0.721884 4.41333C0.655205 4.57564 0.655205 4.75769 0.721884 4.92C0.753614 5.00183 0.80119 5.0766 0.861884 5.14L2.86188 7.14C2.92386 7.20248 2.99759 7.25208 3.07883 7.28593C3.16007 7.31977 3.24721 7.3372 3.33522 7.3372C3.42323 7.3372 3.51036 7.31977 3.5916 7.28593C3.67284 7.25208 3.74658 7.20248 3.80855 7.14C3.87104 7.07802 3.92063 7.00429 3.95448 6.92305C3.98832 6.84181 4.00575 6.75467 4.00575 6.66667C4.00575 6.57866 3.98832 6.49152 3.95448 6.41028C3.92063 6.32904 3.87104 6.25531 3.80855 6.19333L2.94188 5.33333H9.33522C9.86565 5.33333 10.3744 5.12262 10.7494 4.74755C11.1245 4.37247 11.3352 3.86377 11.3352 3.33333V0.666667C11.3352 0.489856 11.265 0.320287 11.14 0.195262C11.0149 0.070238 10.8454 0 10.6686 0Z'
      fill={props.color || '#77808D'}
    />
  </svg>
)

const SVGPressEnterIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGPressEnterIcon
