import React from "react";
import Icon from "@ant-design/icons";

const SVGIcon = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        opacity="0.2"
        cx="12"
        cy="12"
        r="12"
        fill={props.color || "#F6D063"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2 5C16.1765 5 19.4 8.2236 19.4 12.2C19.4 16.1764 16.1765 19.4 12.2 19.4C8.22351 19.4 5 16.1764 5 12.2C5 8.2236 8.22351 5 12.2 5V5ZM12.2 13.8098C12.8948 13.8098 13.4581 14.3719 13.4581 15.0653C13.4581 15.7588 12.8948 16.3209 12.2 16.3209C11.5052 16.3209 10.9419 15.7587 10.9419 15.0653C10.9419 14.3718 11.5052 13.8098 12.2 13.8098ZM14.2534 15.5366V15.3515L15.1322 14.3685C15.0324 14.3736 14.9445 14.3758 14.8682 14.3758H14.3052V14.1911H15.4334V14.3416L14.6862 15.1958L14.542 15.3515C14.6467 15.3441 14.7452 15.3403 14.837 15.3403H15.475V15.5366H14.2533L14.2534 15.5366ZM15.9516 16.6828V16.4977L16.8304 15.5147C16.7306 15.5198 16.6427 15.522 16.5664 15.522H16.0034V15.3373H17.1316V15.4878L16.3844 16.342L16.2402 16.4977C16.3449 16.4903 16.4434 16.4865 16.5352 16.4865H17.1732V16.6828H15.9515L15.9516 16.6828ZM10.8083 10.4217C10.9301 10.3446 10.9647 10.1858 10.8856 10.0672C10.8064 9.94844 10.6436 9.91472 10.5218 9.99187C10.5185 9.99392 9.38549 10.7235 8.67425 9.93895C8.57812 9.83301 8.41201 9.82306 8.30335 9.91689C8.19467 10.0106 8.18447 10.1726 8.28072 10.2785C9.29501 11.3974 10.8039 10.4245 10.8084 10.4217L10.8083 10.4217ZM13.8758 9.97941C13.754 9.90226 13.5911 9.93598 13.512 10.0547C13.4329 10.1735 13.4675 10.3322 13.5893 10.4094C13.5936 10.4121 15.0829 11.3739 16.1159 10.2772C16.2144 10.1729 16.2075 10.0106 16.1005 9.91459C15.9934 9.81858 15.827 9.82533 15.7285 9.92968C14.999 10.7041 13.8789 9.98145 13.8756 9.97939L13.8758 9.97941Z"
        fill={props.color || "#F9C735"}
      />
    </svg>
  );
};

const SVGIntentNoReplyIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />;
      }}
      {...props}
    />
  );
};

export default SVGIntentNoReplyIcon;
