import React from 'react'
import MatButton from '../../../../components/MatButton'
import ConfirmPopup from '../../../../components/ConfirmPopup'
import { getText } from '../../../../lang'
import { notifyError, notifySuccess } from '../../../../utils/Notify'
import messageActions from '../../../../store/modules/messageActions'
import SVGArchiveNewIcon from '../../../../icons/SVG/SVGArchiveNewIcon'
import { MULTIPLE_CONVERSATIONS_TYPE_OBJECT } from '../../utils/messageUtils'
import {
  ALL,
  ARCHIVED,
  MARK_AS_READ,
  MY_MESSAGES,
  UNASSIGNED,
} from '../../utils/constants'
import SVGMarkAsRead from '../../../../icons/SVG/SVGMarkAsRead'
import './MultipleActions.scss'

const MessageLeftMultipleActionsConversation = (props) => {
  const {
    selectMultipleConversationsType,
    setselectMultipleConversationsType,
    setIsLoading,
    setUnreadOnly,
    paginationOptionsForAPI,
    messageType,
  } = props

  const isArchive =
    selectMultipleConversationsType.selectButtonActionsType === ARCHIVED

  const handleAction = async () => {
    setIsLoading(true)
    let result
    const conversationIds = selectMultipleConversationsType.selectAll
      ? selectMultipleConversationsType.exceptConversationsIds
      : selectMultipleConversationsType.archiveConversationsIds

    if (isArchive) {
      result = await (selectMultipleConversationsType.selectAll
        ? messageActions.archiveAllConversation(
            conversationIds,
            paginationOptionsForAPI
          )
        : messageActions.archiveAll(conversationIds))
    } else if (
      selectMultipleConversationsType.selectButtonActionsType === MARK_AS_READ
    ) {
      result = await (selectMultipleConversationsType.selectAll
        ? messageActions.unreadAllConversation(
            selectMultipleConversationsType.exceptConversationsIds,
            paginationOptionsForAPI
          )
        : messageActions.readAll(selectMultipleConversationsType.unreadIds))
    } else if (messageType === ARCHIVED) {
      result = await (selectMultipleConversationsType.selectAll
        ? messageActions.unArchiveAllConversation(
            selectMultipleConversationsType.exceptConversationsIds,
            paginationOptionsForAPI
          )
        : messageActions.unarchiveAll(
            selectMultipleConversationsType.unArchiveConversationIds
          ))
    }

    if (result.success) {
      if (isArchive) {
        if (selectMultipleConversationsType.archiveConversationsIds.length > 1) {
          notifySuccess(getText('NTF_MSG_MULTIPLE_ARCHIVED'))
        } else {
          notifySuccess(getText('NTF_MSG_ARCHIVED'))
        }
      } else if (messageType === ARCHIVED) {
        if (selectMultipleConversationsType.unArchiveConversationIds.length > 1) {
          notifySuccess(getText('NTF_MSG_MULTIPLE_UNARCHIVED'))
        } else {
          notifySuccess(getText('NTF_MSG_UNARCHIVED'))
        }
      }
      setselectMultipleConversationsType(MULTIPLE_CONVERSATIONS_TYPE_OBJECT)
    } else {
      notifyError(result.errMsg)
    }
    setIsLoading(false)
  }

  const titleConversationNumber = selectMultipleConversationsType.selectAll
    ? selectMultipleConversationsType.archivedConversationsTotalCount
    : selectMultipleConversationsType.archiveConversationsIds.length

  const titleUnreadIdsConversationNumber = selectMultipleConversationsType.selectAll
    ? selectMultipleConversationsType.unreadConversationsTotalCount
    : selectMultipleConversationsType.unreadIds.length

  const titleUnarchiveConversationNumber =
    messageType === ARCHIVED && selectMultipleConversationsType.selectAll
      ? selectMultipleConversationsType.unArchivedConversationsTotalCount
      : selectMultipleConversationsType.unArchiveConversationIds.length

  const countToShow = isArchive
    ? titleConversationNumber
    : titleUnreadIdsConversationNumber

  const isDisabled = !Boolean(countToShow)

  const multipleConversationButtonObject = () => {
    switch (messageType) {
      case ALL:
      case MY_MESSAGES:
      case UNASSIGNED:
        return [
          {
            textButton: getText('TEXT_ARCHIVE_MULTIPLE_CONVERSATIONS'),
            style: {
              color: '#fff',
              backgroundColor: '#E05D4C',
            },
            type: ARCHIVED,
            icon: <SVGArchiveNewIcon color={'#fff'} />,
          },
          {
            textButton: getText('TEXT_MARK_AS_READ_MULTIPLE_CONVERSATIONS'),
            style: {
              color: '#fff',
              backgroundColor: '#48AF80',
            },
            type: MARK_AS_READ,
            icon: <SVGMarkAsRead width={20} height={21} color={'#fff'} />,
          },
        ]
      default:
        break
    }
  }

  const multipleConversationButton = multipleConversationButtonObject()
  const selectedConvForActions =
    selectMultipleConversationsType.archiveConversationsIds.length ||
    selectMultipleConversationsType.unArchiveConversationIds.length

  return (
    <div className='archive_or_all_read_wrapper'>
      {selectMultipleConversationsType.selectButtonActionsType === '' &&
      messageType !== ARCHIVED ? (
        <>
          <h6 style={{ fontSize: 14 }}>
            {getText('TEXT_SELECT_AN_ACTION_BEFORE_CHOOSING_CONVERSATIONS')}
          </h6>
          {multipleConversationButton.length
            ? multipleConversationButton.map((item, index) => {
                return (
                  <MatButton
                    key={index}
                    icon={item.icon}
                    style={item.style}
                    buttonText={item.textButton}
                    typeButton={'cancel'}
                    htmlType={'button'}
                    onClick={() => {
                      if (item.type === MARK_AS_READ) {
                        setUnreadOnly(true)
                      }
                      setselectMultipleConversationsType({
                        ...selectMultipleConversationsType,
                        selectButtonActionsType: item.type,
                      })
                    }}
                  />
                )
              })
            : null}
        </>
      ) : (
        <ConfirmPopup
          onConfirm={handleAction}
          title={`${getText(
            'WORD_ARE_YOU_SURE_YOU_WANT_TO_DO_ACTION_CONVERSATION'
          ).replace(
            '[action]',
            messageType === ARCHIVED
              ? getText('ACTION_UNARCHIVE').toLowerCase()
              : isArchive
                ? getText('ACTION_ARCHIVE').toLowerCase()
                : getText('ACTION_MARK_AS_READ').toLowerCase()
          )} 
              ${
                messageType === ARCHIVED
                  ? titleUnarchiveConversationNumber
                  : countToShow
              } ${getText('WORD_CONVERSATIONS_QUESTION')}${
                selectedConvForActions > 1 ? 's?' : '?'
              }`}
          cancelText={getText('ACTION_CANCEL')}
          okText={getText('WORD_YES')}
          trigger={
            [ALL, UNASSIGNED, MY_MESSAGES].includes(messageType) ? (
              <>
                {selectMultipleConversationsType.selectButtonActionsType ===
                ARCHIVED ? (
                  <MatButton
                    icon={
                      <SVGArchiveNewIcon color={isDisabled ? '#a3a6be' : '#fff'} />
                    }
                    style={{
                      color: isDisabled ? '#a3a6be' : '#fff',
                      backgroundColor: selectMultipleConversationsType
                        .archiveConversationsIds.length
                        ? '#E05D4C'
                        : '#E5E9F2',
                    }}
                    buttonText={
                      selectMultipleConversationsType.archiveConversationsIds.length
                        ? `${getText(
                            'ACTION_ARCHIVE'
                          )} ${titleConversationNumber} ${getText(
                            'WORD_CONVERSATIONS_QUESTION'
                          )}${
                            selectMultipleConversationsType.archiveConversationsIds
                              .length > 1
                              ? 's'
                              : ''
                          }`
                        : `${getText('SELECT_CONVERSATION_ARCHIVE')}`
                    }
                    typeButton={'cancel'}
                    disabled={isDisabled}
                  />
                ) : (
                  <MatButton
                    icon={
                      <SVGMarkAsRead
                        width={20}
                        height={21}
                        color={
                          selectMultipleConversationsType.unreadIds.length === 0
                            ? '#a3a6be'
                            : '#fff'
                        }
                      />
                    }
                    style={{
                      color:
                        selectMultipleConversationsType.unreadIds.length === 0
                          ? '#a3a6be'
                          : '#fff',
                      backgroundColor: selectMultipleConversationsType.unreadIds
                        .length
                        ? '#48AF80'
                        : '#E5E9F2',
                    }}
                    buttonText={
                      selectMultipleConversationsType.unreadIds.length
                        ? `${getText(
                            'ACTION_MARK_AS_READ'
                          )} ${titleUnreadIdsConversationNumber} ${getText(
                            'WORD_CONVERSATIONS_QUESTION'
                          )}${
                            selectMultipleConversationsType.unreadIds.length > 1
                              ? 's'
                              : ''
                          }`
                        : `${getText('SELECT_CONVERSATION_TO_MARK_AS_READ')}`
                    }
                    typeButton={'cancel'}
                    disabled={isDisabled}
                  />
                )}
              </>
            ) : (
              <MatButton
                icon={
                  <SVGArchiveNewIcon
                    color={!titleUnarchiveConversationNumber ? '#a3a6be' : '#fff'}
                  />
                }
                style={{
                  color: !titleUnarchiveConversationNumber ? '#a3a6be' : '#fff',
                  backgroundColor: selectMultipleConversationsType
                    .unArchiveConversationIds.length
                    ? '#E05D4C'
                    : '#E5E9F2',
                }}
                buttonText={
                  selectMultipleConversationsType.unArchiveConversationIds.length
                    ? `${getText(
                        'ACTION_UNARCHIVE'
                      )} ${titleUnarchiveConversationNumber} ${getText(
                        'WORD_CONVERSATIONS_QUESTION'
                      )}${
                        selectMultipleConversationsType.unArchiveConversationIds
                          .length > 1
                          ? 's'
                          : ''
                      }`
                    : `${getText('TEXT_UNARCHIVE_MULTIPLE_CONVERSATIONS')}`
                }
                typeButton={'cancel'}
                disabled={!titleUnarchiveConversationNumber}
              />
            )
          }
        />
      )}
    </div>
  )
}

export default MessageLeftMultipleActionsConversation
