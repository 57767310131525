import React from 'react'
import MatButton from '../../../../components/MatButton'
import SVGEditConvIcon from '../../../../icons/SVG/SVGEditConvIcon'

const MessageMutilpleActionButton = ({
  selectMultipleConversationsType,
  setselectMultipleConversationsType,
  setUnreadOnly,
}) => {
  return (
    <MatButton
      className={'conversation_edit_button'}
      typeButton={'white'}
      icon={<SVGEditConvIcon />}
      onClick={() => {
        setselectMultipleConversationsType({
          ...selectMultipleConversationsType,
          convEdit: !selectMultipleConversationsType.convEdit,
        })
      }}
    />
  )
}

export default MessageMutilpleActionButton
