import React from 'react'
import PageFull from '../../components/PageFull'
import './Payment.scss'

const PaymentPageLayout = ({ children, className }) => {
  return (
    <PageFull className={`payment-page-wrapper ${className || ''}`}>
      {children}
    </PageFull>
  )
}

export default PaymentPageLayout
