import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width='18'
    height='12'
    viewBox='0 0 18 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M16.1502 0.0130129C15.9922 0.0144612 15.836 0.0470965 15.6907 0.109046C15.5453 0.170996 15.4136 0.261041 15.3032 0.374013L6.53716 9.02801L2.69216 5.58301C2.57873 5.46813 2.44329 5.37732 2.29394 5.31601C2.14459 5.25471 1.98441 5.22416 1.82298 5.22622C1.66155 5.22827 1.50219 5.26288 1.35445 5.32797C1.20671 5.39305 1.07362 5.48728 0.963156 5.60501C0.736041 5.84736 0.61141 6.16812 0.615324 6.50024C0.619238 6.83235 0.751393 7.15009 0.984156 7.38701C1.01577 7.41981 1.04915 7.45086 1.08416 7.48001L5.77016 11.68C5.99792 11.8855 6.29573 11.996 6.60242 11.9887C6.9091 11.9815 7.20137 11.8571 7.41916 11.641L16.9942 2.19301C17.2313 1.96058 17.3695 1.64548 17.3797 1.31357C17.39 0.98166 17.2715 0.658634 17.0492 0.412013C16.936 0.285775 16.7974 0.184949 16.6424 0.116175C16.4875 0.0474015 16.3197 0.0122419 16.1502 0.0130129Z'
      fill={props.color || 'white'}
    />
    <path
      d='M6.57687 11.9871C6.28125 11.9861 5.99638 11.8761 5.77687 11.6781L1.08787 7.47905C1.05262 7.44983 1.01922 7.41844 0.987875 7.38505C0.755243 7.14846 0.623144 6.83106 0.61923 6.49928C0.615316 6.1675 0.739889 5.84707 0.966875 5.60505C1.07734 5.48732 1.21043 5.39309 1.35817 5.32801C1.50591 5.26292 1.66527 5.22831 1.82669 5.22626C1.98812 5.22421 2.14831 5.25475 2.29766 5.31605C2.447 5.37736 2.58245 5.46817 2.69587 5.58305L6.53687 9.02605L15.3049 0.372053C15.4197 0.255415 15.5572 0.1635 15.7088 0.101961C15.8605 0.0404229 16.0232 0.010562 16.1868 0.0142149C16.3504 0.0178679 16.5116 0.0549577 16.6604 0.123203C16.8091 0.191449 16.9424 0.289407 17.0519 0.411053C17.2742 0.657824 17.3927 0.980894 17.3826 1.31289C17.3726 1.64489 17.2347 1.9602 16.9979 2.19305L7.42287 11.6391C7.19961 11.8605 6.89834 11.9855 6.58387 11.9871H6.57687Z'
      fill={props.color || 'white'}
    />
  </svg>
)

const SVGConvCheckedIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGConvCheckedIcon
