import React from 'react'
import PropTypes from 'prop-types'
import { getText } from '../../../../../lang'
import ScoreItem from './ScoreItem'
import PieChart from './PieChart'
import './NpsScore.scss'

const NpsScore = (props) => {
  const { npsScore } = props

  return (
    <div className='nps-score-content'>
      <PieChart {...props} />
      <div className='nps-score-right'>
        <div className='nps-score-value-wrapper'>
          <div className='nps-score'>
            <ScoreItem
              text={getText('WORD_PROMOTERS')}
              present={(npsScore && npsScore.promotersPercent) || 0}
              className='green'
            />
            <ScoreItem
              text={getText('WORD_PASSIVE')}
              present={(npsScore && npsScore.passivesPercent) || 0}
              className='red'
            />
            <ScoreItem
              text={getText('WORD_DETRACTORS')}
              present={(npsScore && npsScore.detractorsPercent) || 0}
              className='yellow'
            />
          </div>
        </div>
      </div>
    </div>
  )
}

NpsScore.propTypes = {
  nspScore: PropTypes.instanceOf(Object),
  invitesSent: PropTypes.number,
}

export default NpsScore
