import React from 'react'
import { Tooltip } from 'antd'
import { getText } from '../../../lang'
import SVGInfoIcon from '../../../icons/SVG/SVGInfoIcon'
import SwitchForm from '../../../components/Form/SwitchForm'

const CollapseTitle = ({
  title,
  name,
  switchDisabled,
  onChange,
  onClick,
  isToolTip,
  infoBlockText,
  isMobile,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <div
        style={{
          flexDirection: 'row',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <p style={{ marginRight: infoBlockText ? 5 : 0 }}>{getText(title)}</p>
        {infoBlockText && (
          <Tooltip
            placement='topRight'
            title={
              <span
                dangerouslySetInnerHTML={{
                  __html: infoBlockText,
                }}
              />
            }
            overlayInnerStyle={isMobile ? { width: 200 } : {}}
          >
            <div style={{ width: 20, height: 20 }}>
              <SVGInfoIcon />
            </div>
          </Tooltip>
        )}
      </div>
      <div onClick={(e) => onClick && onClick(e)}>
        {Boolean(isToolTip) ? (
          <Tooltip
            placement='topRight'
            title={getText('TEXT_YOU_CANT_DISABLE_THIS_NOTIFICATION')}
          >
            <div />
            <SwitchForm
              name={name}
              text={''}
              disabled={switchDisabled}
              onChange={(value) => onChange && onChange(value)}
            />
          </Tooltip>
        ) : (
          <SwitchForm
            name={name}
            text={''}
            disabled={switchDisabled}
            onChange={(value) => onChange && onChange(value)}
          />
        )}
      </div>
    </div>
  )
}

export default CollapseTitle
