import React, { useEffect, useState } from 'react'
import { getText } from '../../../../lang'
import SVGWorkflowAddAction from '../../../../icons/SVG/SVGWorkflowAddAction'
import MatDropMenu from '../../../../components/MatDropMenu'
import SVGCrossIcon from '../../../../icons/SVG/SVGCrossIcon'
import SVGWorkflowSMSIcon from '../../../../icons/SVG/SVGWorkflowSMSIcon'
import SVGWorkflowAssignUserIcon from '../../../../icons/SVG/SVGWorkflowAssignUserIcon'
import SVGWorkflowAddTagIcon from '../../../../icons/SVG/SVGWorkflowAddTagIcon'
import SVGWorkflowCreateNoteIcon from '../../../../icons/SVG/SVGWorkflowCreateNoteIcon'
import SVGWorkflowArchiveConversationIcon from '../../../../icons/SVG/SVGWorkflowArchiveConversationIcon'
import SVGWorkflowPushCRMIcon from '../../../../icons/SVG/SVGWorkflowPushCRMIcon'

const getClassName = (reply) => {
  switch (reply) {
    case 0:
      return 'positive-reply'
    case 1:
      return 'negative-reply'
    case 2:
      return 'no-reply'
    default:
      return 'negative-reply'
  }
}

const getColor = (reply) => {
  switch (reply) {
    case 0:
      return '#5BC78B'
    case 1:
      return '#EE7B6C'
    case 2:
      return '#F9C735'
    default:
      return '#EE7B6C'
  }
}

const WorkflowAddAction = ({ type, onAddAction, fieldItemActions }) => {
  const listAction = [
    {
      name: getText('WORD_SMS_UC'),
      value: 'send sms',
      img: <SVGWorkflowSMSIcon />,
    },
    {
      name: getText('ACTION_ARCHIVE'),
      value: 'archive conversation',
      img: <SVGWorkflowArchiveConversationIcon />,
    },
    {
      name: getText('WORD_ASSIGN_USER'),
      value: 'assign users',
      img: <SVGWorkflowAssignUserIcon />,
    },
    {
      name: getText('WORD_ADD_A_TAG'),
      value: 'add tags',
      img: <SVGWorkflowAddTagIcon />,
    },
    {
      name: getText('WORD_CREATE_A_NOTE'),
      value: 'create note',
      img: <SVGWorkflowCreateNoteIcon />,
    },
    {
      name: getText('WORD_PUSH_TO_CRM'),
      value: 'send to crm',
      img: <SVGWorkflowPushCRMIcon />,
    },
  ]

  const [menuList, setMenuList] = useState(listAction)

  const filterList = (list) => {
    const newArray = list.filter((item1) => {
      return fieldItemActions.every((item2) => {
        if (item2.type === 'archive conversation') {
          return (
            item1.value !== 'send sms' &&
            item1.value !== 'assign users' &&
            item1.value !== 'archive conversation'
          )
        } else {
          return item1.value !== item2.type
        }
      })
    })
    if (newArray.length) {
      setMenuList(newArray)
    }
  }

  useEffect(() => {
    if (type === 0) {
      filterList(listAction)
    } else if (type === 1) {
      filterList(listAction)
    } else if (type === 2) {
      filterList(listAction)
    }
  }, [type, fieldItemActions])

  return (
    <div className='add-workflow-action-wrapper'>
      <SVGWorkflowAddAction
        positivecolor={type === 0 ? '#5BC78B' : '#E5E9F2'}
        negativecolor={type === 1 ? '#EE7B6C' : '#E5E9F2'}
        noreplycolor={type === 2 ? '#F9C735' : '#E5E9F2'}
      />
      <div className={`workflow-add-action-place ${getClassName(type)}`}>
        <MatDropMenu
          options={menuList || []}
          autoAdjustOverflow={true}
          getPopupContainer={() =>
            document.querySelector('.workflow-edit-organization-page')
          }
          className={getClassName(type)}
          trigger={<SVGCrossIcon color={getColor(type)} width={20} height={20} />}
          overlayClassName={'workflow-drop-overlay'}
          showArrow={false}
          placement={'bottom'}
          onChange={(val) => {
            if (val === 'send sms') {
              onAddAction({
                type: val,
                config: { message: '' },
              })
            } else if (val === 'archive conversation') {
              onAddAction({ type: val })
            } else if (val === 'assign users') {
              onAddAction({
                type: val,
                config: { users_to_assign: [] },
              })
            } else if (val === 'add tags') {
              onAddAction({
                type: val,
                config: { tags: [], tag_ids: [] },
              })
            } else if (val === 'create note') {
              onAddAction({ type: val, config: { text: '' } })
            } else if (val === 'send to crm') {
              onAddAction({ type: val })
            }
          }}
        />
      </div>
    </div>
  )
}

export default WorkflowAddAction
