import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width='12'
    height='12'
    viewBox='0 0 12 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M12 8L8 11.9973L0.668 12C0.491657 12.0007 0.322246 11.9314 0.19699 11.8073C0.071735 11.6831 0.000882063 11.5143 0 11.338V0.662C0 0.296667 0.296667 0 0.662 0H11.338C11.7033 0 12 0.304 12 0.668V8ZM10.6667 1.33333H1.33333V10.6667H6.66667V7.33333C6.66669 7.17004 6.72664 7.01244 6.83514 6.89042C6.94365 6.76839 7.09317 6.69044 7.25533 6.67133L7.33333 6.66667L10.6667 6.666V1.33333ZM10.114 7.99933L8 8V10.1127L10.114 7.99933Z'
      fill='#77808D'
    />
  </svg>
)

const SVGNote = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGNote
