import React from 'react'

const LocationCouponTitleTextarea = (props) => {
  const { value, titleColor, titleFontSize, onChangeTitle } = props

  return (
    <div className='my-custom-textarea'>
      <textarea
        value={value}
        style={{ color: titleColor, fontSize: titleFontSize }}
        rows='1'
        onChange={(e) => {
          onChangeTitle && onChangeTitle(e)
        }}
      />
    </div>
  )
}

export default LocationCouponTitleTextarea
