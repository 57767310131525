import React, { useCallback } from 'react'
import { TimePicker, Form } from 'antd'
import './MatTimePicker.scss'
import { getText } from '../../../lang'

const MatTimePicker = (props) => {
  const {
    format,
    disabledTime,
    minuteStep,
    hideDisabledOptions,
    panelRender,
    value,
    isOpenTimePicker,
    onOpen,
    disabled,
    use12Hours,
    onBlur,
    onFocus,
    inputReadOnly,
    onClick,
    placeholder,
  } = props

  const rules = useCallback(() => {
    const rules = []
    if (props.required && !props.disabled) {
      rules.push({ required: props.required, message: props.message })
    }
    return rules
  }, [props.required, props.disabled])

  return (
    <Form.Item
      label={props.label}
      name={props.name}
      hasFeedback={props.hasFeedback}
      rules={rules()}
      className={`mat-form-item ${props.className || ''}`}
      style={props.formStyle}
    >
      <TimePicker
        open={isOpenTimePicker}
        allowClear={props.allowClear}
        showNow={false}
        format={format}
        disabledTime={() => disabledTime}
        minuteStep={minuteStep}
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        inputReadOnly={inputReadOnly}
        hideDisabledOptions={hideDisabledOptions}
        renderExtraFooter={panelRender}
        onOpenChange={onOpen}
        use12Hours={use12Hours}
        onBlur={onBlur}
        onFocus={onFocus}
        onClick={onClick}
        className={`mat-time-picker ${props.className || ''}`}
        popupStyle={props.popupStyle}
        style={props.style}
        size={props.size}
        popupClassName={`mat-time-picker-dropdown ${props.popupClassName || ''}`}
      />
    </Form.Item>
  )
}

MatTimePicker.defaultProps = {
  format: 'HH:mm',
  disabledTime: [],
  minuteStep: '10',
  hideDisabledOptions: false,
  onSelect: (e) => {
    console.log(e, 'item select')
  },
  placeholder: getText('WORD_SELECT_TIME'),
  label: '',
  message: getText('WORD_PLEASE_SELECT_TIME'),
  required: true,
  allowClear: true,
  mode: '',
  use12Hours: true,
  size: 'middle',
}

export default MatTimePicker
