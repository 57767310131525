import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='12'
      height='14'
      viewBox='0 0 12 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.6669 12.3333H1.33353C1.15672 12.3333 0.987151 12.4036 0.862127 12.5286C0.737102 12.6536 0.666864 12.8232 0.666864 13C0.666864 13.1768 0.737102 13.3464 0.862127 13.4714C0.987151 13.5964 1.15672 13.6667 1.33353 13.6667H10.6669C10.8437 13.6667 11.0132 13.5964 11.1383 13.4714C11.2633 13.3464 11.3335 13.1768 11.3335 13C11.3335 12.8232 11.2633 12.6536 11.1383 12.5286C11.0132 12.4036 10.8437 12.3333 10.6669 12.3333ZM1.33353 11H1.39353L4.17353 10.7467C4.47806 10.7163 4.76289 10.5821 4.9802 10.3667L10.9802 4.36665C11.2131 4.12063 11.3389 3.79233 11.3302 3.45368C11.3214 3.11503 11.1788 2.79366 10.9335 2.55999L9.10686 0.73332C8.86846 0.509384 8.55606 0.380893 8.22908 0.372288C7.90211 0.363683 7.58338 0.475566 7.33353 0.686653L1.33353 6.68665C1.11804 6.90396 0.983867 7.18879 0.953531 7.49332L0.666864 10.2733C0.657884 10.371 0.670554 10.4694 0.703972 10.5616C0.73739 10.6538 0.790733 10.7374 0.860198 10.8067C0.922491 10.8684 0.996369 10.9173 1.07759 10.9505C1.15882 10.9837 1.24579 11.0005 1.33353 11ZM8.1802 1.66665L10.0002 3.48665L8.66686 4.78665L6.8802 2.99999L8.1802 1.66665Z'
        fill='white'
      />
    </svg>
  )
}

const SVGChatEditColorPicker = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGChatEditColorPicker
