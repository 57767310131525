import React, { useCallback } from 'react'
import { Progress, Tooltip } from 'antd'
import { getText } from '../../lang'
import SVGTempColdIcon from '../../icons/SVG/SVGTempColdIcon'
import SVGTempHotIcon from '../../icons/SVG/SVGTempHotIcon'
import './ProfileLetters.scss'

const alphabeticArray = [
  '+',
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
]

const ProfileLettersCustomers = ({ name, className, activeIcon, temperature }) => {
  const getColor = useCallback(() => {
    const first = name ? name[0].toUpperCase() : ''
    if (first) {
      let index = alphabeticArray.indexOf(first)
      const color = ['blue', 'red', 'green', 'yellow'][index % 4]
      return color
    }
    return 'blue'
  }, [name])

  const getPercentOfTemperature = useCallback(
    (status) => {
      const text = status.replace('_', ' ')
      let bgColor = '#EDF4FF'
      let txtColor = '#0049C6'
      switch (status) {
        case 'very_cold':
          return {
            percent: 20,
            trailColor: bgColor,
            strokeColor: txtColor,
            text: (
              <Tooltip
                title={getText('POWERED_BY_AI_TEXT_TEMPERATURE')}
                color={bgColor}
                overlayInnerStyle={{
                  borderRadius: '8px',
                  fontSize: '12px',
                  color: txtColor,
                }}
                trigger={['hover', 'click']}
              >
                <span style={{ color: txtColor }}>
                  <SVGTempColdIcon />
                  {text}
                </span>
              </Tooltip>
            ),
          }
        case 'cold':
          txtColor = '#3F8CFF'
          return {
            percent: 40,
            trailColor: bgColor,
            strokeColor: txtColor,
            text: (
              <Tooltip
                title={getText('POWERED_BY_AI_TEXT_TEMPERATURE')}
                color={bgColor}
                overlayInnerStyle={{
                  borderRadius: '8px',
                  fontSize: '12px',
                  color: txtColor,
                }}
                trigger={['hover', 'click']}
              >
                <span style={{ color: txtColor }}>
                  <SVGTempColdIcon color={txtColor} />
                  {text}
                </span>
              </Tooltip>
            ),
          }
        case 'warm':
          bgColor = '#FFF8F0'
          txtColor = '#EA9957'
          return {
            percent: 60,
            trailColor: bgColor,
            strokeColor: txtColor,
            text: (
              <Tooltip
                title={getText('POWERED_BY_AI_TEXT_TEMPERATURE')}
                color={bgColor}
                overlayInnerStyle={{
                  borderRadius: '8px',
                  fontSize: '12px',
                  color: txtColor,
                }}
                trigger={['hover', 'click']}
              >
                <span style={{ color: txtColor }}>
                  <SVGTempHotIcon color={txtColor} />
                  {text}
                </span>
              </Tooltip>
            ),
          }
        case 'hot':
          bgColor = '#FFEEE8'
          txtColor = '#E06F4C'
          return {
            percent: 80,
            trailColor: bgColor,
            strokeColor: txtColor,
            text: (
              <Tooltip
                title={getText('POWERED_BY_AI_TEXT_TEMPERATURE')}
                color={bgColor}
                overlayInnerStyle={{
                  borderRadius: '8px',
                  fontSize: '12px',
                  color: txtColor,
                }}
                trigger={['hover', 'click']}
              >
                <span style={{ color: txtColor }}>
                  <SVGTempHotIcon color={txtColor} />
                  {text}
                </span>
              </Tooltip>
            ),
          }
        case 'very_hot':
          bgColor = '#FFEEE8'
          txtColor = '#D44431'
          return {
            percent: 100,
            trailColor: bgColor,
            strokeColor: txtColor,
            text: (
              <Tooltip
                title={getText('POWERED_BY_AI_TEXT_TEMPERATURE')}
                color={bgColor}
                overlayInnerStyle={{
                  borderRadius: '8px',
                  fontSize: '12px',
                  color: txtColor,
                }}
                trigger={['hover', 'click']}
              >
                <span style={{ color: txtColor }}>
                  <SVGTempHotIcon color={txtColor} />
                  {text}
                </span>
              </Tooltip>
            ),
          }
        default:
          break
      }
    },
    [temperature]
  )

  return temperature ? (
    <Progress
      className='mat-user-profile-letter-progress'
      strokeWidth={6}
      size={56}
      strokeColor={getPercentOfTemperature(temperature).strokeColor}
      trailColor={getPercentOfTemperature(temperature).trailColor}
      type='circle'
      percent={getPercentOfTemperature(temperature).percent}
      format={() => (
        <div className={`mat-user-profile-letters-customer ${temperature}`}>
          {getPercentOfTemperature(temperature).text}
        </div>
      )}
    />
  ) : (
    <>
      <div
        className={`mat-user-profile-letters-customer-wrapper ${getColor()} ${
          className || ''
        } `}
      >
        <div className='mat-user-profile-letters-customer'>
          {activeIcon && <div className='active-icon' />}
          {typeof name === 'string'
            ? name &&
              name
                .toUpperCase()
                .split(' ')
                .map((word) => word.substring(0, 1))
                .slice(0, 2)
            : Array.isArray(name)
              ? name.map((word) => {
                  return word ? word.toUpperCase().substring(0, 1).slice(0, 2) : null
                })
              : null}
        </div>
      </div>
    </>
  )
}

export default ProfileLettersCustomers
