import React from 'react'
import { CloseOutlined } from '@ant-design/icons'
import { getText } from '../../../../lang'
import SVGWorkflowArchiveConversationIcon from '../../../../icons/SVG/SVGWorkflowArchiveConversationIcon'
import MatRow from '../../../../components/MatRow'

const WorkflowActionArchiveItem = ({ field, onRemove }) => {
  return (
    <div className='workflow-action-item-wrapper'>
      <MatRow flexStart className='workflow-action-item-row'>
        <div className='workflow-action-item-icon' style={{ color: '#2761F3' }}>
          <SVGWorkflowArchiveConversationIcon width={48} height={48} />
          {getText('ACTION_ARCHIVE')}
        </div>
        <div className='workflow-action-item-textarea-wrapper'>
          <div className='workflow-action-delay'>
            <div className='detect-intent-wait-for-reply'>
              <span className='title'>
                {getText(
                  'TEXT_ARCHIVE_CONVERSATION_YOU_CAN_FIND_ALL_ARCHIVED_EMAILS_HERE'
                )}
              </span>
            </div>
          </div>
        </div>
      </MatRow>
      <CloseOutlined
        style={{ color: '#77808d', fontSize: '10px' }}
        className='workflow-remove-icon'
        onClick={() => {
          onRemove && onRemove()
        }}
      />
    </div>
  )
}

export default WorkflowActionArchiveItem
