import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={props.width || '15.586'}
    height={props.height || '13.358'}
    viewBox='0 0 15.586 13.358'
  >
    <g id='Group_8700' dataname='Group 8700' transform='translate(-599 -388)'>
      <g id='Group_7438' dataname='Group 7438' transform='translate(599 388)'>
        <path
          id='Path_948'
          dataname='Path 948'
          d='M10.35,7.9a.557.557,0,1,1-.557-.557A.526.526,0,0,1,10.35,7.9Zm1.67-.557a.557.557,0,1,0,.557.557A.526.526,0,0,0,12.02,7.339Zm2.227,0A.557.557,0,1,0,14.8,7.9.526.526,0,0,0,14.246,7.339Zm3.34-1.113v6.679a.557.557,0,0,1-1.113,0,1.116,1.116,0,0,0-1.113-1.113H13.133v1.67a2.233,2.233,0,0,1-2.227,2.226H4.227A1.116,1.116,0,0,0,3.113,16.8a.526.526,0,0,1-.557.557A.526.526,0,0,1,2,16.8V10.122A2.233,2.233,0,0,1,4.227,7.9H6.453V6.226A2.233,2.233,0,0,1,8.68,4h6.68A2.233,2.233,0,0,1,17.586,6.226ZM12.02,11.792H8.68A2.233,2.233,0,0,1,6.453,9.566V9.009H4.227a1.116,1.116,0,0,0-1.113,1.113v4.731a2.534,2.534,0,0,1,1.113-.278h6.68a1.116,1.116,0,0,0,1.113-1.113Zm4.453-5.566a1.116,1.116,0,0,0-1.113-1.113H8.68A1.116,1.116,0,0,0,7.566,6.226V9.566A1.116,1.116,0,0,0,8.68,10.679h6.68a2.534,2.534,0,0,1,1.113.278Z'
          transform='translate(-2 -4)'
          fill={props.color || '#a3a6be'}
        />
      </g>
    </g>
  </svg>
)

const SVGSMSSelect = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGSMSSelect
