import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width={20}
    height={20}
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.00008 2.5C3.15913 2.5 1.66675 3.99238 1.66675 5.83333V14.1667C1.66675 16.0076 3.15913 17.5 5.00008 17.5H15.0001C16.841 17.5 18.3334 16.0076 18.3334 14.1667V5.83333C18.3334 3.99238 16.841 2.5 15.0001 2.5H5.00008ZM5.34677 6.1466C5.05957 5.95513 4.67152 6.03274 4.48005 6.31994C4.28858 6.60715 4.36619 6.99519 4.6534 7.18666L7.8044 9.28733C9.134 10.1737 10.8662 10.1737 12.1958 9.28733L15.3468 7.18666C15.634 6.99519 15.7116 6.60715 15.5201 6.31994C15.3286 6.03274 14.9406 5.95513 14.6534 6.1466L11.5024 8.24727C10.5927 8.85375 9.4075 8.85375 8.49777 8.24727L5.34677 6.1466Z'
      fill='#A3A1B7'
    />
  </svg>
)

const SVGMagicEmailIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGMagicEmailIcon
