import React from 'react'
import { useNavigate } from 'react-router-dom'
import MatButton from '../../../../../components/MatButton'
import './MatadorConnectComponents.scss'

const MatadorConnectListTitle = ({
  title,
  url,
  disabled,
  buttonTitle,
  disabledButtonInfo,
  modalButton,
  subTitle,
}) => {
  const navigate = useNavigate()
  return (
    <div className='matador-connect-list-header'>
      <p>
        {title}{' '}
        {subTitle && (
          <>
            <br />
            <span className='subtitle'>{subTitle}</span>
          </>
        )}
      </p>
      <div className='matador-connect-list-right-part'>
        {disabled && <div style={{ marginRight: '5px' }}>{disabledButtonInfo}</div>}
        {modalButton || (
          <MatButton
            htmlType={'button'}
            buttonText={buttonTitle}
            onClick={() => {
              navigate(url, '', {})
            }}
            disabled={disabled}
          />
        )}
      </div>
    </div>
  )
}

export default MatadorConnectListTitle
