import React, { useCallback, useEffect, useMemo } from 'react'
import { CONNECT_URL } from '../../../../../config'

// const CONNECT_URL = 'http://localhost:3001'

const FlowPreview = ({
  settings = { language: 'en' },
  language = 'en',
  token,
  theme = 'default',
  position = 'relative',
  steps,
}) => {
  const getLanguage = useMemo(
    () => (language === 'es' ? 'sp' : language),
    [language]
  )

  const sendPreviewSettings = useCallback(() => {
    const iframe = document.getElementById(`iframe-preview-${theme}`)
    if (!iframe) {
      return
    }
    const data = {
      type: 'MATADOR-FLOW-DESIGNER-UPDATE',
      steps,
    }

    iframe.contentWindow.postMessage(data, '*')
  }, [theme, steps])

  useEffect(() => {
    sendPreviewSettings()
  }, [sendPreviewSettings])

  useEffect(() => {
    sendPreviewSettings()
  }, [sendPreviewSettings])

  if (!token) {
    return null
  }

  const onIframeLoad = () => {
    sendPreviewSettings()
  }

  return (
    <div className='matador-connect-preview-default'>
      <iframe
        title='Matador Connect Preview'
        id={`iframe-preview-default`}
        height={`600px`}
        width='400px'
        frameBorder='none'
        onLoad={onIframeLoad}
        src={`${CONNECT_URL}/wiz_widget/theme=default/${token}/${getLanguage}`}
        style={{ position }}
        allow='geolocation'
      ></iframe>
    </div>
  )
}

export default FlowPreview
