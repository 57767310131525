import React from 'react'
import './PageFix.scss'

const PageFix = (props) => {
  return (
    <div className={`my-page ${props.className || ''}`}>
      {props.title && (
        <div className='righ-content-my-layout'>
          <div className='righ-content-title'>{props.title}</div>
        </div>
      )}
      {props.children}
    </div>
  )
}

export default PageFix
