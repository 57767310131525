import React from 'react'
import { Typography, Divider, Collapse } from 'antd'
import { getText } from '../../../../../lang'
import InstructionsVideo from './assets/instructions_matador_custom_action.mov'
const { Title, Text } = Typography
const MatadorConnectInstructions = () => {
  const content = () => (
    <div>
      <Title level={3}>Opening Matador Connect</Title>
      <Text>
        To open Matador Connect by clicking on any HTML element, follow these steps:
      </Text>
      <Divider />
      <Text strong>Add the Class and Vehicle Properties</Text>
      <pre style={{ maxWidth: '100%' }}>
        {`<button
    class="matador-custom-action btn"
    data-mileage="69901"
    data-stock="2204"
    data-model="Sonic"
    data-year="2015"
    data-make="Chevrolet"
    data-vin="xyz"
>
  I'm interested
</button>`}
      </pre>
      <Text>
        Add the class <code>matador-custom-action</code> to any HTML element that you
        want to use as a trigger to open Matador Connect. This could be a button, a
        link, or any other suitable element. Additionally, include the desired
        vehicle properties using the available options:
      </Text>
      <ul className='pl-3 mt-3'>
        <li>
          <code>data-mileage</code> - The mileage of the vehicle;
        </li>
        <li>
          <code>data-stock</code> - The stock number of the vehicle;
        </li>
        <li>
          <code>data-model</code> - The model of the vehicle;
        </li>
        <li>
          <code>data-year</code> - The year of the vehicle;
        </li>
        <li>
          <code>data-make</code> - The make of the vehicle;
        </li>
        <li>
          <code>data-vin</code> - The VIN (Vehicle Identification Number) of the
          vehicle.
        </li>
      </ul>
      <Text>
        In the example above, a <code>button</code> element is used with the class{' '}
        <code>matador-custom-action</code> and various vehicle properties specified.
        You can customize the HTML element and properties based on your needs.
      </Text>
      <Divider />
      <Text strong>Matador Connect Behavior</Text>
      <br />
      <Text>
        When a user clicks on the HTML element with the specified class and vehicle
        properties, Matador Connect will be automatically opened. It will create a
        new question, combining the provided vehicle properties. For example, if the
        user clicks on the button with the properties in the previous step, Matador
        Connect may display a question like:
        <br />
        <br />
        <code>"I'm interested in this 2015 Chevrolet xyz."</code>
        <br />
        <br />
        The user can then submit their phone number, and our system will create a
        vehicle of interest based on the provided data within the messaging
        functionality.
      </Text>
      <Divider />
      <Text>
        Upon implementation, the visual representation of this solution can be
        observed in the video provided below:
      </Text>
      <video controls style={{ maxWidth: '100%' }}>
        <source src={InstructionsVideo} />
        {getText('ERROR_SORRY_YOUR_BROWSER_DOESNT_SUPPORT_EMBEDDED_VIDEOS')}
      </video>
      <Divider />
      <Text>
        If you have any questions or encounter any issues during the implementation
        process, please contact our support team at{' '}
        <a href='mailto:support@matador.ai'>support@matador.ai</a>. We're here to
        assist you!
      </Text>
    </div>
  )

  const items = [
    {
      key: '1',
      label: 'Instructions - Open Matador Connect by clicking any HTML element',
      children: content(),
    },
  ]

  return <Collapse items={items} />
}
export default MatadorConnectInstructions
