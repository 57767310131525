import React from 'react'
import { getKValue } from '../../../../utils'
import { getText } from '../../../../lang'
import ProfileLetters from '../../../../components/ProfileLetters'

const UserItem = ({ item }) => {
  const appointmentCount = getKValue(item.count_appointments)

  const secondsToHms = (d) => {
    d = Number(d)
    const h = Math.floor(d / 3600)
    const m = Math.floor((d % 3600) / 60)
    const s = Math.floor((d % 3600) % 60)

    const hDisplay = h > 0 ? h + 'h ' : ''
    const mDisplay = m > 0 ? m + 'm' : ''
    const sDisplay = s > 0 && m === 0 ? m + 1 + 'm' : 0
    const lastResult = sDisplay || mDisplay
    return d === 0 ? 0 : hDisplay + lastResult
  }

  return (
    <div className='user-item-wrapper'>
      <div className='info-item'>
        <ProfileLetters name={item.user_name} withoutIcon className='medium' />
        <div className='user-item-info'>
          <p>{getText('WORD_USER')}</p>
          <h5>{item.user_name}</h5>
        </div>
      </div>
      <div className='info-item'>
        <p>{getText('WORD_CLIENTS')}</p>
        <h5>{getKValue(item.count_clients)}</h5>
      </div>
      <div className='info-item'>
        <p>{getText('TEXT_MESSAGES_SENT')}</p>
        <h5>{getKValue(item.count_messages_sent)}</h5>
      </div>
      <div className='info-item'>
        <p>{getText('TEXT_INVITATIONS_SENT')}</p>
        <h5>{getKValue(item.count_invitations)}</h5>
      </div>
      <div className='info-item'>
        <p>{getText('WORD_APPOINTMENTS')}</p>
        <h5>{appointmentCount === -1 ? 'N/A' : appointmentCount}</h5>
      </div>
      <div className='info-item'>
        <p>{getText('TEXT_VIDEO_SENT')}</p>
        <h5>{getKValue(item.count_videos_sent || 0)}</h5>
      </div>
      <div className='info-item'>
        <p>{getText('TEXT_VIDEO_WATCHED')}</p>
        <h5>{getKValue(item.count_videos_watched || 0)}</h5>
      </div>
      <div className='info-item'>
        <p>{getText('TEXT_HOURS_WATCHED')}</p>
        <h5>{secondsToHms(item.count_seconds_watched || 0)}</h5>
      </div>
    </div>
  )
}

export default UserItem
