import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='40'
    height='39.999'
    viewBox='0 0 40 39.999'
  >
    <g id='Image_2_1_' data-name='Image 2 (1)' transform='translate(-4.836 -4.816)'>
      <path
        id='Path_6917'
        data-name='Path 6917'
        d='M44.835,30.221v2.767c0,.459-.02.922-.06,1.382-.561,6.318-4.95,10.447-11.283,10.447H16.177a11,11,0,0,1-7.856-2.927,9.181,9.181,0,0,1-1.9-2.464c.661-.8,1.4-1.684,2.124-2.566,1.222-1.464,2.4-2.909,3.146-3.85,1.1-1.364,4.008-4.953,8.036-3.268a14.382,14.382,0,0,1,2.2,1.243c1.623,1.083,2.3,1.4,3.447.782a8.922,8.922,0,0,0,2.946-3.429c.461-.744.922-1.464,1.423-2.125C31.929,23.363,35.3,22.6,38.1,24.285a21.087,21.087,0,0,1,3.728,2.986c.241.241.481.463.7.684C42.831,28.256,43.833,29.258,44.835,30.221Z'
        fill='#408ff0'
      />
      <path
        id='Path_6918'
        data-name='Path 6918'
        d='M33.513,4.816H16.179c-6.794,0-11.343,4.752-11.343,11.828V32.988a12.871,12.871,0,0,0,1.583,6.436c.661-.8,1.4-1.684,2.124-2.569,1.222-1.462,2.4-2.908,3.146-3.848,1.1-1.363,4.008-4.953,8.036-3.268a14.378,14.378,0,0,1,2.2,1.243c1.623,1.083,2.3,1.4,3.447.78a8.893,8.893,0,0,0,2.946-3.429c.461-.742.922-1.462,1.423-2.123C31.93,23.364,35.3,22.6,38.1,24.286a21.083,21.083,0,0,1,3.727,2.986c.241.241.481.463.7.684.3.3,1.3,1.3,2.3,2.266V16.645C44.836,9.569,40.287,4.816,33.513,4.816Z'
        fill='#408ff0'
        opacity='0.4'
      />
      <path
        id='Path_6919'
        data-name='Path 6919'
        d='M23.744,18.409a5.161,5.161,0,1,1-5.162-5.163A5.208,5.208,0,0,1,23.744,18.409Z'
        fill='#408ff0'
      />
    </g>
  </svg>
)

const SVGUploadImage = (props) => <Icon component={SVGIcon} {...props} />

export default SVGUploadImage
