import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M6.55351 2.82403L6.55398 2.82355C6.60128 2.77625 6.66454 2.75 6.73043 2.75C6.73758 2.75 6.74524 2.75071 6.75543 2.75287C6.76038 2.75392 6.76627 2.75538 6.77338 2.75748C6.80202 2.77118 6.82984 2.78132 6.85577 2.78884C6.8805 2.80228 6.90158 2.81826 6.91688 2.83355L10.6232 6.53985C10.7267 6.6493 10.7177 6.8056 10.6269 6.89645C10.5756 6.94774 10.515 6.97 10.4504 6.97C10.3859 6.97 10.3253 6.94774 10.274 6.89645L7.83398 4.45645L6.98043 3.60289V4.81V21C6.98043 21.1339 6.86429 21.25 6.73043 21.25C6.59657 21.25 6.48043 21.1339 6.48043 21V4.8V3.59289L5.62688 4.44645L3.17688 6.89645C3.08214 6.99118 2.91872 6.99118 2.82398 6.89645C2.72932 6.80178 2.72925 6.63854 2.82377 6.54377C2.82384 6.5437 2.82391 6.54362 2.82398 6.54355L6.55351 2.82403Z'
      fill='#0E0E0E'
      stroke='white'
    />
    <path
      d='M17.5202 19.2V20.4071L18.3738 19.5536L20.8238 17.1036C20.9185 17.0088 21.0819 17.0088 21.1767 17.1036C21.2675 17.1944 21.2765 17.3507 21.173 17.4602L17.4567 21.1764C17.4094 21.2237 17.3461 21.25 17.2802 21.25C17.2669 21.25 17.2476 21.2475 17.2244 21.2412C17.1968 21.2281 17.17 21.2184 17.1449 21.2112C17.1202 21.1977 17.0991 21.1817 17.0838 21.1664L13.3738 17.4564C13.279 17.3617 13.279 17.1983 13.3738 17.1036C13.4685 17.0088 13.6319 17.0088 13.7267 17.1036L16.1667 19.5436L17.0202 20.3971V19.19V3C17.0202 2.86614 17.1364 2.75 17.2702 2.75C17.4041 2.75 17.5202 2.86614 17.5202 3V19.2Z'
      fill='#0E0E0E'
      stroke='white'
    />
  </svg>
)

const SVGDrawerWorkflow = (props) => <Icon component={SVGIcon} {...props} />

export default SVGDrawerWorkflow
