import * as React from "react"

function SVGLargeGraph(props) {
  return (
    <svg width={670} height={220} viewBox="0 0 670 160" {...props}>
      <defs>
        <linearGradient
          id="prefix__a"
          x1={0.5}
          x2={0.5}
          y2={0.569}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#677fe1" stopOpacity={0.769} />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
      </defs>
      <path
        data-name="Path 6068"
        d="M-21351.293-525.689s-70.332 40.5-121.656 48.076c-51.3 7.574-53.684-18.276-93.133-13.172s-32.555 33.094-76.023 37.539-51.809-19.428-82.687-24.367-21.383 10.373-37.07 5.927-11.875-25.355-38.945-19.1c-27.09 6.256-29.961 40.667-66.539 42.808s-47.992-44.619-86.484-32.929-67.461 77.712-67.461 79.688v35.529h670z"
        transform="translate(22021.293 525.689)"
        opacity={0.14}
        fill="url(#prefix__a)"
      />
    </svg>
  )
}

export default SVGLargeGraph
