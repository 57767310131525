import React from "react";
import Icon from "@ant-design/icons";

const SVGIcon = (props) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 0C5.61553 0 4.26216 0.410543 3.11101 1.17971C1.95987 1.94888 1.06266 3.04213 0.532846 4.32122C0.00303298 5.6003 -0.13559 7.00777 0.134506 8.36563C0.404603 9.7235 1.07129 10.9708 2.05026 11.9497C3.02922 12.9287 4.2765 13.5954 5.63437 13.8655C6.99224 14.1356 8.3997 13.997 9.67879 13.4672C10.9579 12.9373 12.0511 12.0401 12.8203 10.889C13.5895 9.73785 14 8.38447 14 7C14 5.14348 13.2625 3.36301 11.9497 2.05025C10.637 0.737498 8.85652 0 7 0V0ZM3 8C2.80222 8 2.60888 7.94135 2.44443 7.83147C2.27998 7.72159 2.15181 7.56541 2.07612 7.38268C2.00044 7.19996 1.98063 6.99889 2.01922 6.80491C2.0578 6.61093 2.15304 6.43275 2.2929 6.29289C2.43275 6.15304 2.61093 6.0578 2.80491 6.01921C2.99889 5.98063 3.19996 6.00043 3.38269 6.07612C3.56541 6.15181 3.72159 6.27998 3.83147 6.44443C3.94135 6.60888 4 6.80222 4 7C4 7.26522 3.89465 7.51957 3.70711 7.70711C3.51957 7.89464 3.26522 8 3 8ZM7 8C6.80222 8 6.60888 7.94135 6.44443 7.83147C6.27998 7.72159 6.15181 7.56541 6.07612 7.38268C6.00044 7.19996 5.98063 6.99889 6.01922 6.80491C6.0578 6.61093 6.15304 6.43275 6.2929 6.29289C6.43275 6.15304 6.61093 6.0578 6.80491 6.01921C6.99889 5.98063 7.19996 6.00043 7.38269 6.07612C7.56541 6.15181 7.72159 6.27998 7.83147 6.44443C7.94135 6.60888 8 6.80222 8 7C8 7.26522 7.89465 7.51957 7.70711 7.70711C7.51957 7.89464 7.26522 8 7 8V8ZM11 8C10.8022 8 10.6089 7.94135 10.4444 7.83147C10.28 7.72159 10.1518 7.56541 10.0761 7.38268C10.0004 7.19996 9.98063 6.99889 10.0192 6.80491C10.0578 6.61093 10.153 6.43275 10.2929 6.29289C10.4327 6.15304 10.6109 6.0578 10.8049 6.01921C10.9989 5.98063 11.2 6.00043 11.3827 6.07612C11.5654 6.15181 11.7216 6.27998 11.8315 6.44443C11.9414 6.60888 12 6.80222 12 7C12 7.26522 11.8946 7.51957 11.7071 7.70711C11.5196 7.89464 11.2652 8 11 8Z"
        fill="#F7A55B"
      />
    </svg>
  );
};

const SVGPaymentPenddingIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />;
      }}
      {...props}
    />
  );
};

export default SVGPaymentPenddingIcon;
