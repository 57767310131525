import * as React from "react"

function SVGWarningIcon(props) {
  return (
    <svg width={props.width ? props.width : 127} height={props.height ? props.height : 127} viewBox="0 0 127 127" {...props}>
      <g data-name="Group 8341" transform="translate(-897 -278)">
        <circle
          data-name="Ellipse 672"
          cx={63.5}
          cy={63.5}
          r={63.5}
          transform="translate(897 278)"
          fill={props.colorRGBAFirst ? `${props.colorRGBA + ",0.04)"}` : "rgba(255,222,90,0.04)"}
        />
        <circle
          data-name="Ellipse 673"
          cx={49.5}
          cy={49.5}
          r={49.5}
          transform="translate(911 292)"
          fill={props.colorRGBA ? `${props.colorRGBA + ",0.06)"}` : "rgba(255,222,90,0.06)"}
        />
        <circle
          data-name="Ellipse 674"
          cx={35.5}
          cy={35.5}
          r={35.5}
          transform="translate(925 306)"
          fill={props.colorRGBA ? `${props.colorRGBA + ",0.08)"}` : "rgba(255,222,90,0.08)"}
        />
        <g data-name="Group 1676">
          <path
            data-name="Path 1464"
            d="M936.478 323.71l22.834-11.499a2.379 2.379 0 012.19 0l22.942 11.609a2.56 2.56 0 011.369 2.135v24.695c0 6.078-2.574 11.17-7.009 15.605-4.27 4.217-10.239 7.721-17.466 10.789a2.212 2.212 0 01-1.862 0c-7.228-3.066-13.2-6.571-17.467-10.787-4.435-4.437-7.009-9.529-7.009-15.607v-24.695a2.465 2.465 0 011.478-2.245zm23.929 27.542a3.614 3.614 0 11-3.614 3.614 3.61 3.61 0 013.614-3.614zm0-3.614a2.517 2.517 0 01-2.407-2.464l-1.2-10.842c-.219-1.916 1.752-3.669 3.614-3.669s3.833 1.752 3.614 3.669l-1.2 10.842a2.517 2.517 0 01-2.409 2.464zm0-30.608l-20.588 10.404v23.216c0 4.654 2.026 8.651 5.585 12.156 3.614 3.614 8.761 6.68 15 9.418 6.187-2.738 11.389-5.8 14.948-9.418 3.559-3.5 5.585-7.5 5.585-12.156v-23.216l-20.53-10.404z"
            fill={props.color ? props.color : "#ffde5a"}
          />
        </g>
      </g>
    </svg>
  )
}

export default SVGWarningIcon
