import React, { useState, useMemo } from 'react'
import Slider from 'react-slick'
import { useSelector } from 'react-redux'
import { SampleNextArrow, SamplePrevArrow } from '../SampleArrows'
import SVGChartIcon from '../../../../../icons/SVG/SVGChartIcon'
import { getText } from '../../../../../lang'
import TouchPointItems from '../TouchPointItems'
import { MainColor } from '../../../../../utils'

const RenderMarketingSource = ({ screenSize, touchpoints }) => {
  const [activeTouchpoint, setActiveTouchpoint] = useState(null)

  const menuOpen = useSelector((state) => {
    return state.authReducer.menuOpen
  })

  const screens = ['mobile', 'tabletPortrait']
  const groupSize = screens.includes(screenSize) ? 3 : 4

  const settings = useMemo(
    () => ({
      infinite: true,
      arrows: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      adaptiveHeight: true,
    }),
    []
  )

  const mappedTouchpoints = useMemo(
    () =>
      touchpoints
        ? touchpoints
            .map((ms) => (
              <TouchPointItems
                className='touch-point-item'
                width={72}
                strokeWidth={10}
                status='success'
                isCircle
                key={ms._id}
                title={ms.name}
                count={ms.count}
                percent={ms.percentage}
                colorProgress={MainColor}
                activeTouchpoint={activeTouchpoint === ms._id}
              />
            ))
            .reduce((r, element, index) => {
              index % groupSize === 0 && r.push([])
              r[r.length - 1].push(element)
              return r
            }, [])
            .map((rowContent, index) => (
              <div key={index} className='slider-row'>
                {rowContent}
              </div>
            ))
        : [],
    [touchpoints, groupSize, activeTouchpoint]
  )

  return (
    <div
      className={`touch-points-list ${menuOpen ? 'touch-points-list-width' : ''}`}
    >
      <div className='touch-points-header'>
        <SVGChartIcon />
        <h3 onClick={() => setActiveTouchpoint(null)}>
          {getText('WORD_TOUCHPOINTS')}
        </h3>
      </div>
      <Slider {...settings}>{mappedTouchpoints}</Slider>
    </div>
  )
}

export default RenderMarketingSource
