import React from "react";
import Icon from "@ant-design/icons";

const SVGIcon = (props) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_22276_225747)">
      <path
        d="M6 1V1.5M6 10.5V11M9.53554 2.46447L9.18198 2.81802M2.81802 9.18198L2.46447 9.53553M11 6H10.5M1.5 6H1M9.53552 9.53553L9.18197 9.18198M2.81802 2.81802L2.46447 2.46447M9 6C9 7.65685 7.65685 9 6 9C4.34315 9 3 7.65685 3 6C3 4.34315 4.34315 3 6 3C7.65685 3 9 4.34315 9 6Z"
        stroke={props.color || "#E06F4C"}
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_22276_225747">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const SVGTempHotIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />;
      }}
      {...props}
    />
  );
};

export default SVGTempHotIcon;
