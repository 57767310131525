import React from 'react';
import Icon from '@ant-design/icons';

const SVGIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="13.807" height="13.806" viewBox="0 0 13.807 13.806">
        <g id="settings" transform="translate(-0.194 1.998)">
            <g id="settings-2" data-name="settings" transform="translate(0.194 -2)">
                <path id="Path_5615" data-name="Path 5615" d="M13.112,5.472,11.79,5.207a5.219,5.219,0,0,0-.232-.556l.749-1.124A.862.862,0,0,0,12.2,2.439l-.83-.83A.862.862,0,0,0,10.281,1.5l-1.124.749A5.216,5.216,0,0,0,8.6,2.018L8.336.7A.862.862,0,0,0,7.49,0H6.316A.863.863,0,0,0,5.47.7L5.205,2.018a5.217,5.217,0,0,0-.556.231L3.526,1.5a.863.863,0,0,0-1.089.108l-.83.83A.863.863,0,0,0,1.5,3.527l.749,1.124a5.173,5.173,0,0,0-.231.557L.694,5.472A.863.863,0,0,0,0,6.318V7.492a.863.863,0,0,0,.694.846L2.016,8.6a5.217,5.217,0,0,0,.231.556L1.5,10.283a.862.862,0,0,0,.108,1.089l.83.83a.862.862,0,0,0,1.089.108l1.124-.749a5.089,5.089,0,0,0,.557.232l.264,1.322a.863.863,0,0,0,.846.694H7.49a.863.863,0,0,0,.846-.694L8.6,11.792a5.22,5.22,0,0,0,.556-.232l1.124.749A.862.862,0,0,0,11.37,12.2l.83-.83a.863.863,0,0,0,.108-1.089l-.749-1.124A5.132,5.132,0,0,0,11.79,8.6l1.322-.264a.863.863,0,0,0,.694-.846V6.318A.863.863,0,0,0,13.112,5.472ZM11.621,7.757a.862.862,0,0,0-.645.563,4.381,4.381,0,0,1-.193.463.861.861,0,0,0,.058.855l.749,1.124-.83.83-1.124-.749a.864.864,0,0,0-.854-.059,4.363,4.363,0,0,1-.464.194.862.862,0,0,0-.562.645L7.49,12.946H6.316l-.264-1.323a.862.862,0,0,0-.563-.645,4.388,4.388,0,0,1-.464-.193.861.861,0,0,0-.855.058l-1.124.749-.83-.83.749-1.124a.864.864,0,0,0,.059-.854,4.36,4.36,0,0,1-.193-.464.863.863,0,0,0-.645-.562L.864,7.492V6.318l1.323-.264a.862.862,0,0,0,.645-.563,4.3,4.3,0,0,1,.193-.464.861.861,0,0,0-.059-.855L2.217,3.049l.83-.83,1.124.749a.864.864,0,0,0,.854.059,4.353,4.353,0,0,1,.464-.193.862.862,0,0,0,.563-.645L6.316.865H7.49l.264,1.323a.862.862,0,0,0,.563.645,4.292,4.292,0,0,1,.463.193.863.863,0,0,0,.855-.059l1.124-.749.83.83L10.84,4.172a.865.865,0,0,0-.059.854,4.355,4.355,0,0,1,.194.464.862.862,0,0,0,.645.563l1.322.265V7.492Z" transform="translate(0 0)" fill="#2d2d66" />
                <path id="Path_5616" data-name="Path 5616" d="M12.223,9a3.223,3.223,0,1,0,3.223,3.223A3.224,3.224,0,0,0,12.223,9Zm0,6.044a2.82,2.82,0,1,1,2.82-2.82A2.821,2.821,0,0,1,12.223,15.045Z" transform="translate(-5.32 -5.32)" fill="#2d2d66" />
                <path id="Path_5617" data-name="Path 5617" d="M13.842,12a1.842,1.842,0,1,0,1.842,1.842A1.841,1.841,0,0,0,13.842,12Zm0,3.224a1.381,1.381,0,1,1,1.381-1.381A1.382,1.382,0,0,1,13.842,15.225Z" transform="translate(-6.939 -6.938)" fill="#2d2d66" />
            </g>
        </g>
    </svg>
);

const SVGSideNavigationSettingsIcon = props => <Icon component={SVGIcon} {...props} />;

export default SVGSideNavigationSettingsIcon;