import React, { forwardRef, useEffect, useState } from 'react'
import momentTZ from 'moment-timezone'
import SelectForm from '../Form/SelectForm'

const getTimeZoneOptions = async () => {
  const timeZonesList = await momentTZ.tz.names()
  return timeZonesList
}

const getTimeZoneList = async (timezome) => {
  let list = timezome.map((item) => {
    return {
      label: item,
      value: item,
    }
  })
  return list
}

const SelectTimeZone = forwardRef((props, ref) => {
  const [listTimeZone, setListTimeZone] = useState([])
  const [loadingData, setLoadingData] = useState(true)

  useEffect(() => {
    setLoadingData(true)
    const fill = async () => {
      await searchTimeZone()
    }
    if (props.organization && props.organization._id) {
      fill()
    }
    setLoadingData(false)
  }, [props.organization])

  const searchTimeZone = async () => {
    let listTimeZone = await getTimeZoneOptions()
    let stateValue = {}
    stateValue = {
      listTimeZone: await getTimeZoneList(listTimeZone),
      maxManagers: listTimeZone.length,
    }
    setListTimeZone(stateValue)
  }

  return (
    <SelectForm
      ref={ref}
      {...props}
      options={listTimeZone.listTimeZone || []}
      loading={loadingData}
      showSearch={props.showSearch}
    />
  )
})

export default SelectTimeZone
