import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.3333 12.8337C19.3459 12.8337 20.1667 12.0128 20.1667 11.0003C20.1667 9.9878 19.3459 9.16699 18.3333 9.16699C17.3208 9.16699 16.5 9.9878 16.5 11.0003C16.5 12.0128 17.3208 12.8337 18.3333 12.8337Z'
        stroke='#292D32'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.3333 5.49967C19.3459 5.49967 20.1667 4.67886 20.1667 3.66634C20.1667 2.65382 19.3459 1.83301 18.3333 1.83301C17.3208 1.83301 16.5 2.65382 16.5 3.66634C16.5 4.67886 17.3208 5.49967 18.3333 5.49967Z'
        stroke='#292D32'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.3333 20.1667C19.3459 20.1667 20.1667 19.3459 20.1667 18.3333C20.1667 17.3208 19.3459 16.5 18.3333 16.5C17.3208 16.5 16.5 17.3208 16.5 18.3333C16.5 19.3459 17.3208 20.1667 18.3333 20.1667Z'
        stroke='#292D32'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.66927 12.8337C4.68179 12.8337 5.5026 12.0128 5.5026 11.0003C5.5026 9.9878 4.68179 9.16699 3.66927 9.16699C2.65675 9.16699 1.83594 9.9878 1.83594 11.0003C1.83594 12.0128 2.65675 12.8337 3.66927 12.8337Z'
        stroke='#292D32'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.5 11H16.5'
        stroke='#292D32'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.5026 3.66699H12.8359C11.0026 3.66699 10.0859 4.58366 10.0859 6.41699V15.5837C10.0859 17.417 11.0026 18.3337 12.8359 18.3337H16.5026'
        stroke='#292D32'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

const SVGLocationIntegrationIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGLocationIntegrationIcon
