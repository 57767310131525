export const SET_MANUAL_CONSENT_EDIT = 'SET_MANUAL_CONSENT_EDIT'
export const SET_LAST_CONSENT_EDIT = 'SET_LAST_CONSENT_EDIT'

const initialState = {
  manualConsentEdit: {},
  lastConsentEdit: {},
}

const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MANUAL_CONSENT_EDIT:
      return { ...state, manualConsentEdit: action.payload }
    case SET_LAST_CONSENT_EDIT:
      return { ...state, lastConsentEdit: action.payload }
    default:
      return state
  }
}

export default customerReducer
