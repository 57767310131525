import React, { forwardRef, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getLongName } from '../../utils'
import SelectForm from '../Form/SelectForm'

const SelectOrgLanguageForm = forwardRef((props, ref) => {
  const [listData, setListData] = useState([])
  const [loadingData, setLoadingData] = useState(true)

  const organization = useSelector((state) => {
    return state.authReducer.user.organization
  })

  useEffect(() => {
    setLoadingData(true)
    setListData(
      organization &&
        organization.languages.map((oo) => {
          return { value: oo, label: getLongName(oo) }
        })
    )
    setLoadingData(false)
  }, [organization])

  return (
    <SelectForm
      ref={ref}
      {...props}
      options={listData}
      loading={loadingData}
      showSearch={false}
      className='select-org-language-form-select'
      formStyle={{ marginBottom: 5 }}
    />
  )
})

export default SelectOrgLanguageForm
