import React from 'react'
import PropTypes from 'prop-types'
import SVGNpsScoreInvite from '../../../../../icons/SVG/SVGNpsScoreInvite'

export default function SentInvites({ text, value }) {
  return (
    <div className='send-invites'>
      <span>
        <SVGNpsScoreInvite />
        {text}
      </span>
      <span>{value}</span>
    </div>
  )
}

SentInvites.propTypes = {
  text: PropTypes.string,
  value: PropTypes.number,
}
