import React from 'react'
import { Space } from 'antd'
import { getText } from '../../../../lang'
import SVGCloseTag from '../../../../icons/SVG/SVGCloseTag'
import './MessageFilterView.scss'

const MessageLeftFilterView = ({ onClick, statuses }) => {
  const getStatusName = () => {
    const { matadorImpacts, broadcastFilter, opportunities } = statuses
    if (matadorImpacts.superhuman_assisted_appointments)
      return getText('TEXT_SUPER_HUMAN_ASSISTED_APPOINTMENTS')
    if (matadorImpacts.superhuman_replies) return getText('TEXT_SUPER_HUMAN_REPLIES')
    if (matadorImpacts.assisted_appointments)
      return getText('TEXT_ASSISTED_APPOINTMENTS')

    const list = []
    if (broadcastFilter.name) {
      list.push({
        title: getText('WORD_BROADCAST_NAME'),
        description: broadcastFilter.name,
      })
    }
    if (opportunities) {
      list.push({
        title: getText('WORD_FILTERED_BY'),
        description: getText('WORD_OPPORTUNITIES'),
      })
    }
    return list
  }

  const statusName = getStatusName()

  return (
    <div className='message_page_broadcast_filter'>
      <SVGCloseTag
        style={{
          cursor: 'pointer',
          position: 'absolute',
          right: '10px',
          top: '17px',
        }}
        color='#A3A6BE'
        width={10}
        height={10}
        onClick={onClick}
      />
      {Array.isArray(statusName) ? (
        statusName.map((item, index) => (
          <Space key={index} size={4}>
            <span>{item.title}:</span>
            <span>{item.description}</span>
          </Space>
        ))
      ) : (
        <Space size={4}>
          <span>{getText('WORD_FILTERED_BY')}:</span>
          <span>{statusName}</span>
        </Space>
      )}
    </div>
  )
}

export default MessageLeftFilterView
