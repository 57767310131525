import React, { useState } from 'react'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getText } from '../../../lang'
import MatButton from '../../../components/MatButton'
import MatPickerDate from '../../../components/MatPickerDate'
import MatRow from '../../../components/MatRow'

const LeaderboardHeader = ({ onChangeFilter }) => {
  const navigate = useNavigate()
  const [date, setDate] = useState('')

  const location = useSelector((state) => {
    return state.authReducer.user.location
  })

  const customDateFilter = () => {
    const filterTimeOptions = [{ name: getText('WORD_ALL_TIME'), year: -1 }]

    for (let i = 0; i < 13; i++) {
      let date = moment().subtract(i, 'month').startOf('month')
      let obj = {}
      obj.name = date.format('MMM YYYY')
      obj.month = date.month()
      obj.year = date.year()
      filterTimeOptions.push(obj)
    }

    return filterTimeOptions
  }

  return (
    <MatRow className={'leaderboard-list-header-wrapper'}>
      <div className='leaderboard-list-header-left'>
        <div className='left-title'>{location.name}</div>
      </div>
      <MatRow className={'leaderboard-list-header-right'}>
        <MatButton
          buttonText={getText('TITLE_INVITATIONS')}
          onClick={() => {
            navigate('/invitation/list')
          }}
        />
        <MatPickerDate
          placeholder={date || getText('WORD_ALL_TIME')}
          inputReadOnly={true}
          panelRender={() => (
            <div className='leaderboard-date-picker'>
              {customDateFilter().map((item, index) => {
                return (
                  <div
                    key={index}
                    className='leaderboard-date-picker-item'
                    onMouseDown={() => {
                      setDate(item.name)
                      onChangeFilter && onChangeFilter(item)
                    }}
                  >
                    {item.name}
                  </div>
                )
              })}
            </div>
          )}
        />
      </MatRow>
    </MatRow>
  )
}

export default LeaderboardHeader
