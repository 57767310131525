import React from 'react'
import { getText } from '../../lang'
import { formatDate, formatTime } from '../../utils'
import SVGEditNew from '../../icons/SVG/SVGEditNew'
import SVGEmailNew from '../../icons/SVG/SVGEmailNew'
import SVGPhone from '../../icons/SVG/SVGPhone'
import SVGLocationNew from '../../icons/SVG/SVGLocationNew'
import SVGLastLoginIcon from '../../icons/SVG/SVGLastLoginIcon'
import SVGMagicSmsIcon from '../../icons/SVG/SVGMagicSmsIcon'
import SVGMagicEmailIcon from '../../icons/SVG/SVGMagicEmailIcon'
import SVGRole from '../../icons/SVG/SVGRole'
import MatRow from '../../components/MatRow'
import MatModal from '../../components/MatModal'
import ProfileLetters from '../../components/ProfileLetters'
import FieldItem from './FieldItem'

const ShowUserModal = (props) => {
  const { userItem, openModal, visible, setVisible } = props

  const getUserType = () => {
    if (userItem.isAdmin && !userItem.isSuperAdmin) {
      return getText('WORD_ROLE_ADMIN')
    } else if (userItem.isSuperAdmin) {
      return getText('WORD_ROLE_SUPER_ADMIN')
    } else if (userItem.isManager) {
      return getText('WORD_ROLE_MANAGER')
    } else if (userItem.isSupportAgent) {
      return getText('WORD_ROLE_SUPPORT')
    }
  }

  return (
    <MatModal
      noTrigger
      visible={visible}
      forceRender={false}
      onCancel={() => setVisible(false)}
      hideFooter
      className='users-page-edit-modal'
    >
      <div className='users-page-show-more-header'>
        <ProfileLetters name={userItem.fullName} />
        <MatRow
          className='users-page-show-more-header-item'
          style={{ flexDirection: 'column' }}
        >
          <MatRow>
            <div className='user-list-fullName'>{userItem.fullName}</div>
            <div
              className='user-list-edit'
              onClick={() => openModal('edit', userItem)}
            >
              <SVGEditNew height={16} width={16} />
            </div>
          </MatRow>
          <div className='user-list-created-date'>
            {formatDate(userItem.createdAt)}&nbsp;
            {formatTime(userItem.createdAt)}
          </div>
        </MatRow>
      </div>
      <div className='users-page-shown-list-body'>
        <FieldItem
          icon={<SVGEmailNew />}
          leftText={getText('WORD_EMAIL')}
          rightText={userItem.email}
        />
        <FieldItem
          icon={<SVGPhone />}
          leftText={getText('WORD_PHONE')}
          rightText={userItem.phone}
        />
        <FieldItem
          icon={<SVGLocationNew />}
          leftText={getText('WORD_CURRENT_LOCATION')}
          rightText={userItem.location ? userItem.location.name : '-'}
        />
        <FieldItem
          icon={<SVGRole />}
          leftText={getText('WORD_ROLE')}
          rightText={getUserType()}
        />
        <FieldItem
          icon={<SVGLastLoginIcon />}
          leftText={getText('WORD_LOGINS')}
          rightText={userItem.count_login}
        />
        <FieldItem
          icon={<SVGMagicSmsIcon />}
          leftText={getText('WORD_MAGIC_SMS')}
          rightText={userItem.count_magic_sms}
        />
        <FieldItem
          icon={<SVGMagicEmailIcon />}
          style={{ borderBottom: 'none' }}
          leftText={getText('WORD_MAGIC_EMAIL')}
          rightText={userItem.count_magic_email}
        />
      </div>
    </MatModal>
  )
}

export default ShowUserModal
