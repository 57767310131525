import {
  fetchFromUrlDELETEAsync,
  fetchFromUrlGETAsync,
  fetchFromUrlPOSTAsync,
} from '../../utils/UrlHelper'
import { pushTempUrlParamsForConversaction } from './meetActions'

// const urlPayments = "/payments/create/";
const urlPaymentsList = '/payments'
const urlDeposits = '/payments/deposits'
const urlReports = '/payments/reports'

const fs = {
  // createPayment: async (obj, phoneNumber, conv ) => {
  //     const urlParams = Object.keys(obj).map(key => { return { key, value: obj[key] } });
  //     pushTempUrlParamsForConversaction(conv, urlParams);

  //     phoneNumber = phoneNumber.replace("+", "");
  //     const result = await fetchFromUrlPOSTAsync(urlPayments + phoneNumber, urlParams);
  //     return result;
  // },

  createPayment: async (obj, conv) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    pushTempUrlParamsForConversaction(conv, urlParams)
    const result = await fetchFromUrlPOSTAsync(urlPaymentsList, urlParams)
    return result
  },

  getPaymentsSummary: async () => {
    const result = await fetchFromUrlGETAsync(urlPaymentsList + '/summary', [])
    return result
  },
  getDepositsSummary: async () => {
    const result = await fetchFromUrlGETAsync(urlDeposits + '/summary', [])
    return result
  },
  getPageList: async (
    start,
    range,
    searchKeyword = '',
    paginationOptions = {},
    sorting = {}
  ) => {
    if (paginationOptions.status === 'all') {
      delete paginationOptions.status
    }

    let urlParams = []
    if (paginationOptions.status !== 'all') {
      urlParams.push({ key: 'status', value: paginationOptions.status })
    }
    if (paginationOptions.group !== 'none') {
      urlParams.push({ key: 'group', value: paginationOptions.group })
    }
    if (sorting !== null) {
      urlParams.push({ key: 'order', value: sorting.order })
      urlParams.push({ key: 'sort', value: sorting.sort })
    }
    urlParams.push({ key: 'page', value: start + 1 })
    urlParams.push({ key: 'perPage', value: range })

    if (searchKeyword) {
      urlParams.push({ key: 'search', value: searchKeyword })
    }

    const result = await fetchFromUrlGETAsync(urlPaymentsList, urlParams)

    if (result.success) {
      result.max = result.data.total_items
      result.data = result.data.items
    }
    return result
  },

  getDepositsList: async (start, range) => {
    let urlParams = []
    urlParams.push({ key: 'page', value: start + 1 })
    urlParams.push({ key: 'perPage', value: range })
    const result = await fetchFromUrlGETAsync(urlDeposits + '/list', urlParams)
    return result
  },

  refundPayment: async (itemId) => {
    // const urlParams = Object.keys(obj).map(key => { return { key, value: obj[key] } });
    const result = await fetchFromUrlPOSTAsync(
      urlPaymentsList + `/${itemId}/refund`,
      []
    )
    return result
  },
  cancelPayment: async (itemId) => {
    const result = await fetchFromUrlDELETEAsync(urlPaymentsList + `/${itemId}`, [])
    return result
  },
  getPaymentsTimeline: async (payId) => {
    const result = await fetchFromUrlGETAsync(
      `${urlPaymentsList}/${payId}/timeline`,
      []
    )
    return result
  },
  downloadDailyTotalTransactionReport: async (date) => {
    let urlParams = []
    urlParams.push({ key: 'startDate', value: date })
    const fileContent = await fetchFromUrlGETAsync(
      `/${urlPaymentsList}/transaction-report`,
      urlParams
    )
    return fileContent
  },
  getPaymentReportList: async (start, range) => {
    let urlParams = []
    urlParams.push({ key: 'page', value: start + 1 })
    urlParams.push({ key: 'perPage', value: range })
    const result = await fetchFromUrlGETAsync(urlReports, urlParams)
    return result
  },
  getPaymentReportListByDate: async (obj) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    const newUrl = `${urlReports}?date=${obj.date}&page=${obj.page + 1}&perPage=${
      obj.perPage
    }`
    const result = await fetchFromUrlPOSTAsync(newUrl, urlParams)
    return result
  },
  downloadPaymentReportItem: async (_id) => {
    const result = await fetchFromUrlPOSTAsync(urlReports + `/${_id}`, [])
    return result
  },
  deletePaymentReportItem: async (_id) => {
    const result = await fetchFromUrlDELETEAsync(urlReports + `/${_id}`, [])
    return result
  },
}

const loc = {}

const paymentsActions = Object.assign(fs, loc)

export default paymentsActions
