import React from 'react'
import { Divider } from 'antd'
import InputFormNumberSecondary from '../../../../components/Form/InputFormNumber/InputFormNumberSecondary'
import { getText } from '../../../../lang'
import SwitchForm from '../../../../components/Form/SwitchForm'

const ConversationAIAutoReply = ({ title, name, switchName, enabledSuperhuman }) => {
  return (
    <div className='conversation-ai-auto-reply'>
      <span className='title'>{title}</span>
      {title && <Divider type='vertical' />}
      {getText('TEXT_AUTO_REPLY_AFTER')}
      <InputFormNumberSecondary
        name={name}
        min={1}
        placeholder={1}
        required={enabledSuperhuman}
        errorMessage={getText('ERR_MSG_PLEASE_PROVIDE_DELAY')}
        disabled={!enabledSuperhuman}
      />
      {getText('TEXT_MINUTES_OF_MESSAGE_BEING_UNREAD')}
      <SwitchForm name={switchName} text={''} disabled={!enabledSuperhuman} />
    </div>
  )
}

export default ConversationAIAutoReply
