import React from 'react'
import { DatePicker } from 'antd'
import dayjs from 'dayjs'
import localeSP from 'antd/es/date-picker/locale/eu_ES'
import localEN from 'antd/es/date-picker/locale/en_US'
import localeFR from 'antd/es/date-picker/locale/fr_FR'
import 'dayjs/locale'
import '../Form/MatDatePicker/MatDatePicker.scss'
import authActions from '../../store/modules/authActions'

const MatPickerDate = (props) => {
  const { onChange } = props

  const userLang = authActions.getLanguage()

  return (
    <div className='mat-form-item'>
      {props.label && (
        <div className='ant-col ant-form-item-label'>
          <label>{props.label}</label>
        </div>
      )}
      <DatePicker
        allowClear={props.allowClear}
        onChange={onChange}
        className={`mat-date-picker ${props.className || ''}`}
        popupStyle={props.popupStyle}
        style={props.style}
        locale={
          userLang === 'fr' ? localeFR : userLang === 'sp' ? localeSP : localEN
        }
        value={props.value}
        suffixIcon={props.suffixIcon}
        size={props.size}
        inputReadOnly={props.inputReadOnly}
        panelRender={props.panelRender}
        placeholder={props.placeholder}
        popupClassName={`mat-date-picker-dropdown ${props.dropdownClassName || ''}`}
        disabledDate={props.disabledDate}
        disabled={props.disabled}
      />
    </div>
  )
}

MatPickerDate.defaultProps = {
  onChange: (e) => {},
  label: '',
  allowClear: true,
  mode: '',
  placeholder: 'YYYY-MM-DD',
  disabledDate: false,
  defaultValue: dayjs(),
  size: 'middle',
}

export default MatPickerDate
