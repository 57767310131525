import React, { useEffect, useRef, useState } from 'react'
import { Form, Tabs, Tooltip } from 'antd'
import { getContentMessage } from '../../utils'
import SVGAlert from '../../../../../../icons/SVG/SVGAlert'
import { getText } from '../../../../../../lang'
import { default as broadcastingActions } from '../../../../../../store/modules/broadcastingActions'
import { notifyError } from '../../../../../../utils/Notify'
import MatForm from '../../../../../../components/Form/MatForm'
import LoadingSpinner from '../../../../../../components/LoadingSpinner'
import BroadcastCampaignPage from '../../../../BroadcastCampaignPage'
import FormButtons from '../FormButtons/FormButtons'
import YourOwnContent from './YourOwnContent'
import AIContent from './AIContent'
import './Content.scss'

const Content = (props) => {
  const {
    onStateChange,
    inputMessage,
    onChangeStep,
    uploadExcel,
    alternativeContent,
    organization,
    aiInputMessage,
  } = props

  const [form] = Form.useForm()
  const textAreRef = useRef()

  const [loading, setloading] = useState(true)
  const [loadingDeliverability, setLoadingDeliverability] = useState(false)
  const [nextBtnClicked, setNextBtnClicked] = useState(false)
  const [recommendations, setRecommendations] = useState([])
  const [deliverabilityScore, setDeliverabilityScore] = useState(0)
  const [textMessage, settextMessage] = useState(
    inputMessage || {
      aiGeneratedText: '',
      buildYourOwnText: '',
    }
  )
  const [currentCursorPosition, setCurrentCursorPosition] = useState(0)
  const [aiMessage, setAiMessage] = useState(aiInputMessage || '')
  const [tabValue, setTabValue] = useState('ai_generated')
  const [generatedLoading, setGeneratedLoading] = useState(false)
  const [aiNoteAvailable, setAiNoteAvailable] = useState(false)

  useEffect(() => {
    if (inputMessage) {
      form.setFieldsValue({ inputMessage: inputMessage })
    }
    setloading(false)
  }, [form, inputMessage])

  const onFinish = async (values) => {
    if (tabValue === 'ai_generated') {
      if (!Boolean(values.inputMessage)) {
        notifyError(getText('TEXT_PLEASE_GENERATE_AI_CONTENT'))
        return
      }
    }
    if (
      (values.inputMessage.aiGeneratedText &&
        values.inputMessage.aiGeneratedText.includes('{{review_invite_url}}')) ||
      (values.inputMessage.buildYourOwnText &&
        values.inputMessage.buildYourOwnText.includes('{{review_invite_url}}'))
    ) {
      onStateChange('send_review_invite', true)
    } else {
      onStateChange('send_review_invite', false)
    }
    setLoadingDeliverability(true)
    let result = await broadcastingActions.getDeliverabilityScore(
      tabValue === 'ai_generated'
        ? textMessage.aiGeneratedText
        : textMessage.buildYourOwnText
    )
    setLoadingDeliverability(false)
    if (result.success) {
      if (result.data.show_to_user) {
        setNextBtnClicked(true)
        setRecommendations(result.data.recommendations)
        setDeliverabilityScore(result.data.deliverability_score)
      } else onChangeStep(1)
    }
  }

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields.length > 0) {
      notifyError(errorInfo.errorFields[0].errors[0])
    }
  }

  const ShowDeliverabilityScore = () => {
    const getColor = () => {
      if (deliverabilityScore >= 80) {
        return '#388e3c'
      } else if (deliverabilityScore >= 70 && deliverabilityScore < 80) {
        return '#f9a825'
      } else {
        return '#F12B2C'
      }
    }

    return (
      <div className='deliverability-score'>
        <div className='deliverability'>
          <SVGAlert fill={getColor()} circlefill={getColor()} />
          <div className='deliverability-score-header'>
            <div className='deliverability-score-title'>
              {getText('TEXT_DELIVERABILITY_SCORE')}
              <span
                style={{ color: getColor() }}
                className='deliverability-score-number'
              >
                {deliverabilityScore} %
              </span>
            </div>
            <div className='text'>
              {getText(
                'TEXT_MESSAGE_LIKELY_CARRIER_VIOLATION_AND_WILL_HAVE_LOW_DELIVERY_RATE'
              )}
            </div>
          </div>
        </div>
        <ul className='recomendation-list'>
          {recommendations.map((recommendation, index) => (
            <li key={index} className='recomendations'>
              <div className='text'> {recommendation}</div>
            </li>
          ))}
        </ul>
        <FormButtons
          className='deliverability-score-btn-wrapper'
          onClickSubmit={() => {
            onChangeStep(1)
            if (tabValue === 'ai_generated') {
              onStateChange('inputMessage', {
                ...textMessage,
                activeTab: tabValue,
              })
            } else {
              onStateChange('inputMessage', {
                ...textMessage,
                activeTab: tabValue,
              })
            }
          }}
          prevAction={() => setNextBtnClicked(false)}
          submitFormBtn={getText('WORD_SEND_ANYWAY')}
          cancelFormBtn={getText('WORD_REVIEW_COPY')}
        />
      </div>
    )
  }

  const listVariables =
    uploadExcel && tabValue === 'build_your_own'
      ? [
          {
            label: 'customer_first_name',
            value: ' {{customer_first_name}} ',
          },
          {
            label: 'customer_last_name',
            value: ' {{customer_last_name}} ',
          },
          {
            label: 'customer_full_name',
            value: ' {{customer_full_name}} ',
          },
          {
            label: 'organization_name',
            value: ' {{organization_name}} ',
          },
          {
            label: 'review_invite_url',
            value: ' {{review_invite_url}} ',
          },
          {
            label: 'year',
            value: ' {{year}} ',
          },
          {
            label: 'make',
            value: ' {{make}} ',
          },
          {
            label: 'model',
            value: ' {{model}} ',
          },
          {
            label: 'VIN',
            value: ' {{VIN}} ',
          },
          {
            label: 'documents',
            value: ' {{documents}} ',
          },
        ]
      : [
          {
            label: 'customer_first_name',
            value: ' {{customer_first_name}} ',
          },
          {
            label: 'customer_last_name',
            value: ' {{customer_last_name}} ',
          },
          {
            label: 'organization_name',
            value: ' {{organization_name}} ',
          },
          {
            label: 'review_invite_url',
            value: ' {{review_invite_url}} ',
          },
        ]

  const addNameInMessenger = (text) => {
    let previousText =
      tabValue === 'ai_generated'
        ? textMessage.aiGeneratedText
        : textMessage.buildYourOwnText
    let newText = [
      previousText.slice(0, currentCursorPosition),
      text,
      previousText.slice(currentCursorPosition),
    ].join('')

    setCurrentCursorPosition(currentCursorPosition + text.length)
    getContentMessage(tabValue, textMessage, settextMessage, onStateChange, newText)
    form.setFieldsValue({ inputMessage: newText })
    textAreRef.current &&
      textAreRef.current.autoFocus(currentCursorPosition + text.length)
  }

  const items = [
    {
      key: 'ai_generated',
      label: aiNoteAvailable ? (
        <Tooltip
          className='ai-generated-tooltip'
          title={getText('TEXT_AI_MESSAGE_GENERATION_IS_NOT_AVAILABLE')}
          destroyTooltipOnHide={true}
          trigger={['click', 'hover']}
        >
          <div className='new'>{getText('WORD_NEW')}</div>{' '}
          {getText('WORD_AI_GENERATED')}
        </Tooltip>
      ) : (
        <>
          <div className='new'>{getText('WORD_NEW')}</div>{' '}
          {getText('WORD_AI_GENERATED')}
        </>
      ),
      children: (
        <AIContent
          textMessage={textMessage}
          settextMessage={settextMessage}
          textAreRef={textAreRef}
          onStateChange={onStateChange}
          organization={organization}
          setAiMessage={setAiMessage}
          aiMessage={aiMessage}
          setGeneratedLoading={setGeneratedLoading}
          generatedLoading={generatedLoading}
          form={form}
          required={tabValue === 'ai_generated'}
          setTabValue={setTabValue}
          tabValue={tabValue}
          setAiNoteAvailable={setAiNoteAvailable}
        />
      ),
    },
    {
      key: 'build_your_own',
      label: getText('WORD_BUILD_YOUR_OWN'),
      children: (
        <YourOwnContent
          textAreRef={textAreRef}
          listVariables={listVariables}
          alternativeContent={alternativeContent}
          textMessage={textMessage}
          settextMessage={settextMessage}
          onStateChange={onStateChange}
          organization={organization}
          addNameInMessenger={addNameInMessenger}
          currentCursorPosition={currentCursorPosition}
          setCurrentCursorPosition={setCurrentCursorPosition}
          tabValue={tabValue}
        />
      ),
    },
  ]

  return (
    <BroadcastCampaignPage title={getText('BUILD_CONTENT')}>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className='create-campaign-form-wrapper ant-form-vertical'>
          <MatForm
            form={form}
            name='formSaveContent'
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={{
              inputMessage: inputMessage ? inputMessage : '',
              aiInputMessage: aiInputMessage || '',
            }}
          >
            <Tabs
              className='build-your-content-tab'
              activeKey={tabValue}
              items={items}
              onChange={(val) => {
                setTabValue(val)
              }}
            />
            <div className='deliverability-score-content'>
              {loadingDeliverability ? (
                <LoadingSpinner />
              ) : (
                nextBtnClicked && <ShowDeliverabilityScore />
              )}
            </div>
          </MatForm>
          <FormButtons
            disabled={nextBtnClicked || generatedLoading}
            prevAction={onChangeStep}
            currentFormName='formSaveContent'
            submitFormBtn={getText('WORD_NEXT')}
            cancelFormBtn={getText('WORD_PREVIOUS')}
          />
        </div>
      )}
    </BroadcastCampaignPage>
  )
}

export default Content
