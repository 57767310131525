import React, { memo } from 'react'
import { Layout } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import { getText } from '../../lang'
import MatButton from '../../components/MatButton'
import './MainFooter.scss'

const { Footer } = Layout

const MainFooter = (props) => {
  const location = useLocation()
  const navigate = useNavigate()
  const handleCancel = () => {
    const currentPath = location.pathname
    if (currentPath.includes('location/settings')) {
      if (
        currentPath.includes('livechat') &&
        (currentPath.includes('edit') || currentPath.includes('add'))
      ) {
        // example : livechat-announcement/special/edit
        // OR livechat-greetings/add
        const indexOfLastTrail = currentPath.lastIndexOf('/')
        return navigate(currentPath.substring(0, indexOfLastTrail))
      }
      return navigate('/location/list')
    }
    navigate(-1)
  }

  return (
    <Footer className='mat-footer'>
      {props.footerButtons.includes('cancel') && (
        <MatButton
          buttonText={getText('ACTION_CANCEL')}
          typeButton={'cancel'}
          htmlType={'button'}
          onClick={handleCancel}
        />
      )}
      {props.footerButtons.includes('save') && (
        <MatButton
          loading={props.loading}
          buttonText={getText('ACTION_SAVE_CHANGES')}
        />
      )}
      {props.footerButtons.includes('copy') && (
        <MatButton loading={props.loading} buttonText={getText('WORD_COPY')} />
      )}
    </Footer>
  )
}

export default memo(MainFooter)
