import React, { useEffect, useRef, useState } from 'react'
import userActions from '../../store/modules/userActions'
import { getText } from '../../lang'
import { TABLE_DEFAULT_ROW_NUMBER } from '../../devOptions'
import MatList from '../../components/MatList'
import PageFull from '../../components/PageFull'
import UsersPageHeader from './UsersPageHeader'
import UsersListItem from './UsersListItem'
import UserModal from './UserModal'
import ShowUserModal from './ShowUserModal'
import './Users.scss'

const Users = (props) => {
  const { user = {}, organization = {} } = props
  const [usersData, setUsersData] = useState({ list: [], total: 0 })
  const [isLoading, setIsLoading] = useState(true)
  const [isEditModalVisible, setIsEditModalVisible] = useState(false)
  const [isShowModalVisible, setIsShowModalVisible] = useState(false)
  const [selectedUserItem, setSelectedUserItem] = useState(false)
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState('')
  const [searchList, setSearchList] = useState({
    enabled: 'true',
  })
  const filterRef = useRef({})
  const defaultLocation = user._default_location_id

  useEffect(() => {
    props.setPageTitle && props.setPageTitle(getText('WORD_MANAGE_USERS'))
  }, [])

  const getUsersList = (options = {}) => {
    if (!options.fromSavingUser) {
      setIsLoading(true)
    }

    userActions
      .getPageList(page, TABLE_DEFAULT_ROW_NUMBER, searchList, defaultLocation)
      .then((result) => {
        result.success &&
          setUsersData({
            list: result.data,
            total: result.max,
          })
      })

    setIsLoading(false)
  }

  const openModal = (modal, userItem) => {
    modal === 'edit' ? setIsEditModalVisible(true) : setIsShowModalVisible(true)
    setSelectedUserItem(userItem)
  }

  useEffect(() => {
    getUsersList()
  }, [page, JSON.stringify(searchList), defaultLocation, organization._id])

  return (
    <PageFull className='users-page-wrapper'>
      <UsersPageHeader
        user={user}
        onFiltersChange={(options) => {
          setPage(0)
          setSearchList(options)
        }}
        openModal={openModal}
        organization={organization._id}
        filterRef={filterRef}
        search={search}
        usersData={usersData}
        onSearch={(searchLetter) => {
          setSearch(searchLetter)
        }}
      />
      <MatList
        grid
        loading={isLoading}
        data={usersData.list}
        pagination
        paginationCurrentPage={page}
        paginationTotalCount={usersData.total}
        onPaginationChange={setPage}
        renderItem={(userItem) => (
          <UsersListItem
            key={userItem._id}
            userItem={userItem}
            openModal={openModal}
          />
        )}
      />
      {isEditModalVisible && (
        <UserModal
          visible={isEditModalVisible}
          setVisible={setIsEditModalVisible}
          userItem={selectedUserItem}
          user={user}
          organization={organization}
          onSave={(id) => {
            if (id === undefined) {
              filterRef.current.resetFilters()
            }
            getUsersList({ fromSavingUser: true })
          }}
        />
      )}
      {isShowModalVisible && (
        <ShowUserModal
          visible={isShowModalVisible}
          setVisible={setIsShowModalVisible}
          userItem={selectedUserItem}
          openModal={openModal}
        />
      )}
    </PageFull>
  )
}

export default Users
