import React, { memo, useEffect, useState } from 'react'
import { CloseOutlined } from '@ant-design/icons'
import SVGWorkflowAddTagIcon from '../../../../icons/SVG/SVGWorkflowAddTagIcon'
import { getText } from '../../../../lang'
import MatRow from '../../../../components/MatRow'
import SelectFromTagsList from '../../../../components/api/SelectFromTagsList'

const WorkflowActionTagsItem = ({ onRemove, field, item, form }) => {
  const [selectedTagList, setSelectedTagList] = useState(
    item.config.tag_ids.length ? item.config.tag_ids : []
  )

  useEffect(() => {
    if (item?.config?.tags?.length) {
      const tagsList = item.config.tags.map((tag) =>
        Object.assign(tag, {
          label: tag.label || tag.name,
          value: tag.value || tag._id,
          key: tag.value || tag._id,
        })
      )
      if (tagsList) {
        setSelectedTagList(tagsList)
        form.setFieldValue([field.name, 'config', 'tags'], tagsList)
      }
    }
  }, [item?.config?.tags])

  return (
    <div className='workflow-action-item-wrapper'>
      <MatRow flexStart className='workflow-action-item-row'>
        <div className='workflow-action-item-icon' style={{ color: '#EA9957' }}>
          <SVGWorkflowAddTagIcon width={48} height={48} />
          {getText('WORD_TAG')}
        </div>
        <div className='workflow-action-item-textarea-wrapper'>
          <SelectFromTagsList
            secondarySelect
            name={[field.name, 'config', 'tags']}
            label={''}
            placeholder={getText('WORD_SELECT_TAGS')}
            message={getText('ERR_MSG_TAG_WORKFLOW')}
            dropdownStyle={{ zIndex: 10000 }}
            required
            onChange={(val) => setSelectedTagList(val)}
            mode='multiple'
            multipleNewStyle
            labelInValue
          />
          <div className='bottom-text'>
            {selectedTagList.length} {getText('WORD_TAGS_SELECTED')}
          </div>
        </div>
      </MatRow>
      <CloseOutlined
        style={{ color: '#77808d', fontSize: '10px' }}
        className='workflow-remove-icon'
        onClick={() => {
          onRemove && onRemove()
        }}
      />
    </div>
  )
}

export default memo(WorkflowActionTagsItem)
