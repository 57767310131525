import React from 'react'
import { Switch } from 'antd'
import './MatSwitch.scss'

const MatSwitchSecondary = (props) => {
  return (
    <div
      className={`mat-switch-wrapper mat-switch-black ${
        props.parentClassName || ''
      }`}
    >
      {props.label && (
        <div className='switch-label'>
          {props.icon && props.icon} {props.label ? props.label : ''}
        </div>
      )}
      <Switch
        ref={props.ref}
        defaultChecked={props.defaultValue}
        disabled={props.disabled}
        checked={props.value}
        onChange={props.onChange}
        loading={props.loading}
        className={`mat-switch-secondary ${props.className || ''}`}
        checkedChildren={props.checkedChildren}
        unCheckedChildren={props.unCheckedChildren}
      />
    </div>
  )
}

export default MatSwitchSecondary
