import React, { useState } from 'react'
import { Image } from 'antd'
import { PictureOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { getText } from '../../../lang'
import IconLocation from '../../../icons/IconLocation.js'
import { urlImgServer } from '../../../utils/UrlHelper'
import MatButton from '../../../components/MatButton'

function LocationListItem(props) {
  const { item, languages } = props
  const navigate = useNavigate()
  const [isAddressDropdownOpen, setIsAddressDropdownOpen] = useState(false)

  const handleEdit = () => {
    navigate(`/location/settings/${item._id}/edit`)
  }

  // const handleDelete = async () => {
  //   let defaultData = {
  //     id: ' ',
  //     name: 'No Locations',
  //     address: 'No Locations',
  //     _id: ' ',
  //   }
  //   const result = await locationActions.delete(item._id)
  //   if (result.success) {
  //     if (authActions.getLocation()._id === item._id) {
  //       if (props.allItems.length === 1) {
  //         await authActions.setEmptyLocation(defaultData)
  //       } else {
  //         let id = item._id === props.allItems[0]._id ? props.allItems[1]._id : props.allItems[0]._id
  //         const resultLocation = await locationActions.getSingle(id)
  //         if (resultLocation.success) {
  //           await authActions.setLocation(resultLocation.data)
  //         } else {
  //           await authActions.setEmptyLocation(defaultData)
  //         }
  //       }
  //     }
  //     notifySuccess(getText('NTF_SUCCESS_DELETE_LOCATION'))
  //   } else {
  //     notifyError(getTextServerError(result.errMsg))
  //   }
  // }

  const getRightIcon = (link) => {
    switch (link.name) {
      case 'Facebook':
        return IconLocation.urlIconFacebook
      case 'Google':
        return IconLocation.urlIconGoogle
      case 'Yelp':
        return IconLocation.urlIconYelp
      default:
        return (
          <div className='custom-url-icon' style={{ backgroundColor: link.color }}>
            <span>{link.name.toUpperCase().substring(0, 1)}</span>
          </div>
        )
    }
  }
  return (
    <div className='location-card'>
      <h6>{item.name}</h6>
      <h6>ID: {item.id}</h6>
      <div className='sms-images'>
        {languages.map((lang, index) => {
          return item[`pic_${lang}`] ? (
            <Image
              key={lang + index}
              src={
                item[`pic_${lang}`].startsWith('http')
                  ? item[`pic_${lang}`]
                  : urlImgServer(item[`pic_${lang}`])
              }
            />
          ) : (
            <div className='no-image' key={lang + index}>
              <PictureOutlined style={{ fontSize: '42px', color: '#a3a6be' }} />
            </div>
          )
        })}
      </div>
      <div className='fields-wrapper'>
        <div className='field-item'>
          <label className='field-label'>
            {IconLocation.email}
            {getText('WORD_ADDRESS')}
          </label>
          <div>
            {item.address ? (
              <>
                <div
                  className='show-more-action'
                  onClick={() => setIsAddressDropdownOpen((oldState) => !oldState)}
                >
                  <p>
                    {!isAddressDropdownOpen
                      ? getText('ACTION_SHOW_MORE')
                      : getText('ACTION_SHOW_LESS')}
                  </p>
                  <div className={`${isAddressDropdownOpen ? 'open' : ''}`}>
                    {IconLocation.expandArrow}
                  </div>
                </div>
                <div
                  className={`content-address ${
                    isAddressDropdownOpen ? 'open' : ''
                  }`}
                >
                  {item.address}
                </div>
              </>
            ) : (
              '-'
            )}
          </div>
        </div>

        <div className='field-item'>
          <label className='field-label'>
            {IconLocation.recommend}
            {getText('WORD_URL')}
          </label>
          <div className='item-urls'>
            {item.urls.map((link, index) => {
              return (
                <React.Fragment key={index}>
                  {link.name === 'Facebook' ||
                  link.name === 'Google' ||
                  link.name === 'Yelp' ? (
                    <a href={link.url} target='_blank' rel='noopener noreferrer'>
                      {getRightIcon(link)}
                    </a>
                  ) : (
                    link.color && (
                      <a href={link.url} target='_blank' rel='noopener noreferrer'>
                        <div
                          className='custom-url-icon'
                          style={{ backgroundColor: link.color }}
                        >
                          <span>{link.name.toUpperCase().substring(0, 1)}</span>
                        </div>
                      </a>
                    )
                  )}
                </React.Fragment>
              )
            })}

            {item.url_custom && (
              <a
                href={item.url_custom}
                target='_blank'
                rel='noopener noreferrer'
                style={{ fill: item.url_custom_color }}
              >
                {IconLocation.urlIconCustom}
              </a>
            )}
          </div>
        </div>
      </div>
      {/* We need temporarily hide the button Delete from the location.
        In the future after some adjustments with location, we will unhide this button again. */}
      {/* <ConfirmPopup
        title={`Are you sure you want to delete this location?`}
        onConfirm={handleDelete}
        cancelText={getText('ACTION_CANCEL')}
        okText={getText('ACTION_DELETE')}
        trigger={
          <MatButton buttonText={getText("ACTION_DELETE")}/>
        }
      /> */}
      <MatButton
        className='edit-location-button'
        onClick={handleEdit}
        buttonText={getText('ACTION_EDIT')}
      />
    </div>
  )
}

export default LocationListItem
