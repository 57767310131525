import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = () => (
  <svg
    width='138'
    height='32'
    viewBox='0 0 138 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M79.819 24.4884V26.9474C79.0315 27.123 78.244 27.2109 77.4565 27.2109C74.4816 27.2109 72.5566 25.8057 72.5566 22.5563V15.9697H70.3691V13.5107H72.5566V9.73438H75.444V13.5107H79.3815V15.9697H75.444V22.2929C75.444 24.0493 76.4065 24.5762 78.069 24.5762C78.594 24.664 79.2065 24.5762 79.819 24.4884Z'
      fill='white'
    />
    <path
      d='M51.9958 8.60547V26.9602H49.1083V15.6312L43.9459 22.3935H42.9834L37.821 15.6312V26.9602H34.9336V8.60547H35.9836L43.5084 18.705L51.0333 8.60547H51.9958Z'
      fill='white'
    />
    <path
      d='M68.2788 13.5302V26.9669H65.5664V25.2104C64.4289 26.5278 62.7664 27.3182 61.0165 27.2303C57.2541 27.2303 54.4541 24.2444 54.4541 20.2046C54.4541 16.1648 57.1666 13.1789 61.0165 13.1789C62.8539 13.0911 64.6039 13.9693 65.7414 15.3744V13.5302H68.2788ZM65.4789 20.2924C65.4789 17.2187 63.4664 15.9014 61.454 15.9014C59.004 15.9892 57.1666 18.0969 57.3415 20.4681C57.429 22.7514 59.2665 24.5079 61.454 24.5957C63.4664 24.5957 65.4789 23.2784 65.4789 20.2924Z'
      fill='white'
    />
    <path
      d='M94.7902 13.5241V26.9608H91.9902V25.2044C90.8527 26.5217 89.1903 27.3121 87.4403 27.2243C83.6779 27.2243 80.8779 24.2384 80.8779 20.1986C80.8779 16.1588 83.5904 13.1729 87.4403 13.1729C89.1903 13.1729 90.9402 13.9632 92.0777 15.4562V13.5241H94.7902ZM91.9027 20.2864C91.9027 17.2126 89.8903 15.8953 87.8778 15.8953C85.4278 15.9831 83.5904 18.0909 83.7654 20.462C83.8529 22.7454 85.6903 24.5018 87.8778 24.5897C89.8903 24.5897 91.9027 23.2723 91.9027 20.2864Z'
      fill='white'
    />
    <path
      d='M94.7902 13.5241V26.9608H91.9902V25.2044C90.8527 26.5217 89.1903 27.3121 87.4403 27.2243C83.6779 27.2243 80.8779 24.2384 80.8779 20.1986C80.8779 16.1588 83.5904 13.1729 87.4403 13.1729C89.1903 13.1729 90.9402 13.9632 92.0777 15.4562V13.5241H94.7902ZM91.9027 20.2864C91.9027 17.2126 89.8903 15.8953 87.8778 15.8953C85.4278 15.9831 83.5904 18.0909 83.7654 20.462C83.8529 22.7454 85.6903 24.5018 87.8778 24.5897C89.8903 24.5897 91.9027 23.2723 91.9027 20.2864Z'
      fill='white'
    />
    <path
      d='M111.06 8.87305V26.9643H108.435V25.0322C107.297 26.5252 105.547 27.3156 103.71 27.2278C99.8599 27.2278 97.1475 24.2418 97.1475 20.202C97.1475 16.1622 99.8599 13.1763 103.71 13.1763C105.372 13.0885 107.035 13.7911 108.172 15.1084V8.87305H111.06ZM108.26 20.2899C108.26 17.2161 106.247 15.8988 104.235 15.8988C101.785 15.9866 99.9474 18.0943 100.122 20.4655C100.21 22.7489 102.047 24.5053 104.235 24.5931C106.16 24.5931 108.26 23.2758 108.26 20.2899Z'
      fill='white'
    />
    <path
      d='M113.419 20.1993C113.331 16.2473 116.481 13.0857 120.419 12.9979C124.356 12.9101 127.506 16.0716 127.594 20.0236C127.681 23.9756 124.531 27.1372 120.594 27.225H120.506C116.656 27.3128 113.506 24.239 113.419 20.3749C113.419 20.3749 113.419 20.2871 113.419 20.1993ZM124.706 20.2871C124.794 18.0037 123.044 15.9838 120.769 15.896C118.494 15.8082 116.481 17.5646 116.394 19.848C116.394 20.0236 116.394 20.1114 116.394 20.2871C116.306 22.5704 118.056 24.5025 120.331 24.5903C122.606 24.6782 124.531 22.9217 124.619 20.6384C124.706 20.4627 124.706 20.3749 124.706 20.2871Z'
      fill='white'
    />
    <path
      d='M137.653 13.5383V16.0851H136.778C134.678 16.0851 132.841 17.3146 132.841 19.7736V26.975H129.953V13.5383H132.578V15.2947C133.541 14.0652 134.941 13.3626 136.516 13.4504C136.953 13.3626 137.303 13.4504 137.653 13.5383Z'
      fill='white'
    />
    <path
      d='M26.9495 0.208576C26.862 0.0329331 26.5995 0.0329331 26.4245 0.120755C26.337 0.120755 26.337 0.208576 26.337 0.296398C26.2495 0.38422 26.2495 0.472041 26.337 0.559863C27.387 3.72144 25.7245 7.14648 22.6621 8.20034C22.0496 8.37599 21.4371 8.55163 20.7371 8.55163H7.69985C4.37492 8.46381 1.66247 5.74134 1.74997 2.40412C1.74997 1.78937 1.83747 1.17461 2.09996 0.559863C2.09996 0.472041 2.09996 0.38422 2.09996 0.296398C1.92496 0.0329331 1.74997 -0.0548885 1.57497 0.0329331C1.48747 0.0329331 1.48747 0.120755 1.39997 0.120755C0.52499 1.61372 0 3.19451 0 4.86312C0 8.46381 2.27496 11.6254 5.68739 12.7671C2.36246 17.0703 2.71245 23.2178 6.56238 27.082L10.7623 31.2974C11.1998 31.7365 11.8123 32 12.3373 32H15.9247C16.5372 32 17.1497 31.7365 17.4997 31.2974L21.6996 27.082C25.5495 23.2178 25.8995 17.0703 22.5746 12.7671C25.8995 11.6254 28.1745 8.46381 28.1745 4.86312C28.3495 3.19451 27.912 1.61372 26.9495 0.208576ZM18.6371 23.6569L13.8247 28.575V25.589C12.3373 25.5012 10.8498 24.8865 9.79981 23.8326V23.7448C7.43736 21.2858 7.43736 17.3338 9.88731 14.9626C12.3373 12.5914 16.2747 12.5914 18.6371 15.0504C19.7746 16.1921 20.3871 17.7729 20.3871 19.3537C20.3871 21.0223 19.7746 22.5153 18.6371 23.6569Z'
      fill='white'
    />
  </svg>
)

const SVGDrawerBigLogo = (props) => <Icon component={SVGIcon} {...props} />

export default SVGDrawerBigLogo
