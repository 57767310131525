import React, { memo, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { formatDateTime } from '../../../../utils'
import { getText } from '../../../../lang'
import SVGAiBotEmailcon from '../../../../icons/SVG/SVGAiBotEmailcon'

const MessageConsentEmail = (props) => {
  const { conversation, noConsentsBottom } = props
  const manualConsentEdit = useSelector(
    (state) => state?.customerReducer?.manualConsentEdit || {}
  )

  const [statusToSend, setStatusToSend] = useState('')
  const [cl, setCl] = useState('')

  let { unsubscribed, receiver = {} } = conversation || {}
  let {
    status: { email: consentInfo },
  } = receiver

  const handleConsentChange = useCallback(() => {
    let lastEditConsentByUser = manualConsentEdit

    const { data = {} } = lastEditConsentByUser

    const { newStatus, previousStatus } = data

    if (consentInfo?.state === 'red' && previousStatus && previousStatus !== 'red') {
      setStatusToSend(previousStatus)
    }

    if (statusToSend === 'red' && previousStatus && previousStatus === 'red') {
      setStatusToSend(newStatus)
    }

    if (consentInfo?.state !== 'red') {
      setStatusToSend('red')
    }
  }, [consentInfo?.state, statusToSend, manualConsentEdit])

  useEffect(() => {
    if (consentInfo?.state === 'yellow') {
      setCl(' yellow')
    } else if (consentInfo?.state === 'green') {
      setCl(' green')
    } else if (consentInfo?.state === 'red') {
      setCl(' red')
    }
  }, [consentInfo?.state])

  useEffect(() => {
    handleConsentChange()
  }, [handleConsentChange])

  if (!receiver.status.email) return ''

  const canChangeConsent = !unsubscribed && consentInfo.state !== 'red'

  const getConsentInfo = () => {
    if (consentInfo.state === 'green') {
      return (
        <div
          className={`consent_info_buttons ${consentInfo.state === 'green' ? cl : ''}`}
        >
          <p>{getText('WORD_APPROVED')}</p>
        </div>
      )
    } else if (consentInfo.state === 'yellow') {
      return null
    } else if (consentInfo.state === 'red') {
      return (
        <div
          className={`consent_info_buttons ${consentInfo.state === 'red' ? cl : ''}`}
        >
          <p>{getText('WORD_OPTED_OUT')}</p>
        </div>
      )
    }
  }

  return (
    consentInfo && (
      <div className='message_contest_wrapper_block'>
        <div className='message_contest_wrapper_block--body'>
          <div className='block_right_and_left_sides'>
            <div className='block_right_and_left_sides--left-side'>
              <div className={'block_right_and_left_sides--left-side-icon orange'}>
                <SVGAiBotEmailcon />
              </div>
              <div className='block_right_and_left_sides--left-side-label'>
                <span className={'not_active'}>{getText('WORD_EMAIL')}</span>
              </div>
            </div>
            <div className='arc-wrapper'>{getConsentInfo()}</div>
          </div>
          {!noConsentsBottom && (
            <>
              {
                <div
                  className={`message_contest_wrapper_block--body-info ${
                    !canChangeConsent ? 'message-center' : ''
                  }`}
                >
                  <div>
                    <div className='message_contest_wrapper_block--body-info-text'>
                      {
                        consentInfo.state === 'yellow' ? (
                          consentInfo.consentSent ? (
                            <p>
                              {getText(
                                'TEXT_WE_ARE_UNSURE_IF_THIS_CUSTOMER_IS_COMPLIANT'
                              )}
                            </p>
                          ) : (
                            <p>
                              {getText(
                                'TEXT_WE_ARE_UNSURE_IF_THIS_CUSTOMER_IS_COMPLIANT'
                              )}
                            </p>
                          )
                        ) : consentInfo.state === 'green' ? (
                          <p>
                            {getText(
                              'TEXT_WE_HAVE_RECEIVED_CONSENT_FROM_THIS_CUSTOMER'
                            )}
                          </p>
                        ) : (
                          consentInfo.state === 'red' && (
                            // conversation.unsubscribed ? (
                            <p>
                              {getText(
                                'TEXT_THE_CUSTOMER_UNSUBSCRIBED_FROM_EMAIL_CONVERSATION'
                              )}
                              <h6>
                                {`${getText('WORD_REFUSED_AT')}: ` +
                                  formatDateTime(consentInfo.unsubscribedAt)}
                              </h6>
                            </p>
                          )
                        )
                        // ) : (
                        //   <p>
                        //     {getText(
                        //       'TEXT_THE_CUSTOMER_DOES_NOT_WANT_TO_RECEIVE_BROADCASTED_MESSAGES'
                        //     )}
                        //   </p>
                        // )
                      }
                      {consentInfo.state === 'green' ? (
                        <h6>
                          {`${getText('WORD_APPROVED')} ${getText('WORD_AT')}: ` +
                            (consentInfo.activatedAt
                              ? formatDateTime(consentInfo.activatedAt)
                              : '')}
                        </h6>
                      ) : consentInfo.state === 'red' ? undefined : (
                        consentInfo.state === 'yellow' &&
                        consentInfo.consentSent &&
                        consentInfo.consentSentDate && (
                          <h6>
                            {`${getText('WORD_SENT')} ${getText('WORD_AT')}: ` +
                              formatDateTime(consentInfo.consentSentDate)}
                          </h6>
                        )
                      )}
                    </div>
                    {consentInfo.state === 'yellow' &&
                      !consentInfo.consentSent &&
                      consentInfo.consentSentDate && (
                        <div className='contest-info-message'>
                          <h6>
                            {`${getText('WORD_SENT')} ${getText('WORD_AT')}: ` +
                              formatDateTime(consentInfo.consentSentDate)}
                          </h6>
                        </div>
                      )}
                  </div>
                </div>
              }
            </>
          )}
        </div>
      </div>
    )
  )
}

export default memo(MessageConsentEmail)
