import React, { useState } from 'react'
import { parsePhoneNumber } from 'libphonenumber-js'
import { Form } from 'antd'
import organizationActions from '../../../store/modules/organizationActions'
import { getText, getTextServerError } from '../../../lang'
import { checkAustralianPhoneNumber } from '../../../utils'
import { notifyError, notifySuccess } from '../../../utils/Notify'
import MatForm from '../../../components/Form/MatForm'
import InputFormText from '../../../components/Form/InputFormText'
import MatModal from '../../../components/MatModal'
import InputFormPhone from '../../../components/Form/InputFormPhone'
import LanguagesSection from '../settings/general/LanguagesSection'
import AddTagsList from '../settings/tags/AddTagsList'
import './CreateOrganizationModal.scss'

const languages = ['en', 'fr', 'sp']

const CreateOrganization = (props) => {
  const { trigger, organization, user, onSave } = props

  const [visible, setVisible] = useState(false)
  const [saving, setSaving] = useState(false)
  const [listLanguage, setListLanguage] = useState([])
  const [defaultLanguage, setDefaultLanguage] = useState('')
  const [deletedTagsState, setDeletedTagsState] = useState([])

  const [form] = Form.useForm()

  const onFinish = async (values) => {
    setSaving(true)
    let listNewTags =
      values.tags_items &&
      values.tags_items.filter((tag) => {
        delete tag.tmpId
        return !tag._id
      })
    if (values.twilio_number.startsWith('04')) {
      values.twilio_number = `61${values.twilio_number.slice(1)}`
    }
    const obj = {
      name: values.nameOrganization,
      twilio_number: values.twilio_number,
      messagingServiceSid: values.messagingServiceSid,
      defaultLanguage: defaultLanguage,
      languages: listLanguage,
    }
    if (listNewTags) {
      obj.newTags = listNewTags
    }

    if (obj.twilio_number.length < 11) {
      notifyError(
        getText(
          obj.twilio_number.startsWith('61')
            ? 'NTF_CHAT__REQUIRED_10'
            : 'NTF_CHAT__REQUIRED'
        )
      )
      setSaving(false)
      return
    }

    if (
      obj.twilio_number.substring(0, 1) !== '1' &&
      obj.twilio_number.substring(0, 2) !== '61'
    ) {
      notifyError(getTextServerError(getText('ERROR_WRONG_PHONE_FORMAT')))
      return
    }

    if (deletedTagsState.length > 0) {
      obj.removeTags = deletedTagsState
    }

    const result = await organizationActions.saveNew(obj)
    setSaving(false)
    if (result.success) {
      onSave && onSave()
      notifySuccess(getText('TEXT_ORGANIZATION_WAS_CREATED_SUCCESSFULLY'))
      setVisible(false)
    } else notifyError(getTextServerError(result.errMsg))
  }

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields.length > 0) {
      notifyError(errorInfo.errorFields[0].errors[0])
    }
  }

  return (
    <MatModal
      trigger={trigger}
      title={getText('WORD_CREATE_ORGANIZATON')}
      formName={'formSaveOrganization_'}
      confirmLoading={saving}
      visible={visible}
      forceRender={false}
      onTrrigerClick={() => {
        setVisible(true)
        form.resetFields()
      }}
      onCancel={() => {
        setVisible(false)
      }}
      okText={getText('WORD_CREATE')}
      className='create-organization'
    >
      <MatForm
        className='organization-create-modal'
        form={form}
        name={'formSaveOrganization_'}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{
          twilio_number: checkAustralianPhoneNumber(organization.twilio_number),
        }}
      >
        <InputFormText
          name='nameOrganization'
          label={getText('WORD_NAME')}
          required
          errorMessage={getText('ERROR_PLEASE_INPUT_THE_ORGANIZATION_NAME')}
        />
        {/*<InputFormText*/}
        {/*  name='messagingServiceSid'*/}
        {/*  label={getText('LABEL_TWILIO_MESSAGING_SID')}*/}
        {/*/>*/}
        <InputFormPhone
          autoCompleteCountryCode
          initialCountry={
            organization.twilio_number &&
            parsePhoneNumber(organization.twilio_number).country &&
            parsePhoneNumber(organization.twilio_number).country.toLowerCase()
          }
          name='twilio_number'
          label={getText('WORD_PHONE')}
          onChange={(value) => {
            form.setFieldsValue({ twilio_number: value })
          }}
          required
        />

        <LanguagesSection
          defaultListLanguage={
            user.isSuperAdmin || user.isAdmin ? languages : listLanguage
          }
          defaultLanguage={defaultLanguage}
          listLanguage={listLanguage}
          disabled={!user.isSuperAdmin && !user.isAdmin}
          onSetAsDefaultClick={(lng) => {
            setDefaultLanguage(lng)
            form && form.setFieldsValue({ defaultLanguage: lng })
          }}
          onChangeSwitch={(lng) => {
            let list = listLanguage
            list.includes(lng)
              ? (list = list.filter((lang) => {
                  return lang !== lng
                }))
              : list.push(lng)
            setListLanguage(list)
          }}
        />

        <AddTagsList
          form={form}
          onSubmit={false}
          setDeletedTagsState={setDeletedTagsState}
          listTagsToDelete={deletedTagsState}
          noData
        />
      </MatForm>
    </MatModal>
  )
}

export default CreateOrganization
