import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import organizationActions from '../../../store/modules/organizationActions'
import { getText } from '../../../lang'
import LoadingSpinner from '../../../components/LoadingSpinner'
import OrganizationMenu from './OrganizationMenu'
import './OrganizationNavigation.scss'

const OrganizationNavigation = (props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { _id } = useParams()

  const [org, setOrg] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const organizationIsInState = location.state && location.state.organization

  const scrollParentRef = useRef(null)

  useEffect(() => {
    props.setPageTitle && props.setPageTitle(getText('WORD_EDIT_ORGANIZATON'))
  }, [])

  useEffect(() => {
    const getOrganizationFromParamsId = async () => {
      if (_id) {
        setIsLoading(true)
        const result = await organizationActions.getSingle(_id)
        if (result.success) {
          setOrg(result.data)
        } else {
          navigate('/organization/list')
        }
      }
    }
    if (organizationIsInState) {
      setOrg(organizationIsInState)
    } else {
      getOrganizationFromParamsId()
    }
    setIsLoading(false)
  }, [_id])

  return (
    <div className='org-navigation-page'>
      <OrganizationMenu {...props} id={_id} />
      <div className='org-navigation-page-container' ref={scrollParentRef}>
        {!isLoading && org._id ? (
          props.children &&
          React.cloneElement(props.children, {
            ...props,
            organization: org,
            setOrganization: setOrg,
            scrollParentRef: scrollParentRef,
          })
        ) : (
          <LoadingSpinner />
        )}
      </div>
    </div>
  )
}

export default OrganizationNavigation
