import React, { forwardRef, useState, useImperativeHandle, memo } from 'react'
import { getText } from '../../../lang'
import MatSelectSecondary from '../../../components/MatSelect/MatSelectSecondary'

const NpsScoreFilter = forwardRef((props, ref) => {
  const { onChangeNpsScore } = props

  useImperativeHandle(ref, () => {
    return {
      resetNpsScore: () => {
        setListNpsScoreSelected([])
        onChangeNpsScore('')
      },
    }
  })

  const [listNpsScoreSelected, setListNpsScoreSelected] = useState([])

  return (
    <div className='dropdown-with-selected-item'>
      <MatSelectSecondary
        label={getText('WORD_CSI_SCORE')}
        placeholder={getText('WORD_SELECT_ITEM')}
        options={[
          {
            label: 'Detractors',
            value: 'DETRACTORS',
          },
          {
            label: 'Passive',
            value: 'PASSIVE',
          },
          {
            label: 'Promoters',
            value: 'PROMOTERS',
          },
        ]}
        showSearch={false}
        value={listNpsScoreSelected}
        onChangeItem={(val) => {
          setListNpsScoreSelected(val)
          onChangeNpsScore(val)
        }}
        onClear={() => {
          setListNpsScoreSelected([])
          onChangeNpsScore('')
        }}
      />
    </div>
  )
})

export default memo(NpsScoreFilter)
