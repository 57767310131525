import React from 'react'
import MatList from '../../../components/MatList'
import BroadcastListMobileCollapsePanel from './BroadcastListMobileCollapsePanel'

const BroadcastListMobile = (props) => {
  const {
    loadingData,
    listData,
    page,
    totalCount,
    setPage,
    getStatus,
    setListData,
  } = props
  return (
    <MatList
      loading={loadingData}
      data={listData}
      pagination
      paginationCurrentPage={page}
      paginationTotalCount={totalCount}
      onPaginationChange={setPage}
      containerClassName=''
      renderItem={(item) => {
        return (
          <BroadcastListMobileCollapsePanel
            key={item._id}
            item={item}
            getStatus={getStatus}
            setListData={setListData}
          />
        )
      }}
    />
  )
}

export default BroadcastListMobile
