import React, { forwardRef, useEffect, useState } from 'react'
import { getText } from '../../lang'
import SelectFormSecondary from '../Form/SelectForm/SelectFormSecondary'

const getThemeOptions = () => {
  const list = [{ value: 'light', label: getText('WORD_LIGHT') }]

  return list
}

const SelectTheme = forwardRef((props, ref) => {
  const [listData, setListData] = useState([])
  const [loadingData, setLoadingData] = useState(true)

  useEffect(() => {
    setLoadingData(true)
    setListData(
      getThemeOptions().map((oo) => {
        return { value: oo.value, label: oo.label }
      })
    )
    setLoadingData(false)
  }, [props.location])

  return (
    <SelectFormSecondary
      ref={ref}
      {...props}
      options={listData}
      loading={loadingData}
      showSearch={false}
    />
  )
})

export default SelectTheme
