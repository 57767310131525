import React, { useEffect, useState } from 'react'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Flex, Tooltip } from 'antd'
import { getText, getTextServerError } from '../../../../lang'
import OrganizationPage from '../../components/OrganizationPage'
import { notifyError, notifySuccess } from '../../../../utils/Notify'
import PageFix from '../../../../components/PageFix'
import organizationActions from '../../../../store/modules/organizationActions'
import SwitchWithText from '../../components/SwitchWithText'
import MatBlockWithLabel from '../../../../components/MatBlockWithLabel'
import LoadingSpinner from '../../../../components/LoadingSpinner'
import authActions from '../../../../store/modules/authActions'
import RestrictionsCheckboxGroup from './RestrictionsCheckboxGroup'
import './Restrictions.scss'

const userRoles = [
  'nextScheduledDripSequenceMessage',
  'superhumanActionInConversation',
]

const sequence = [
  'restrictTextingCustomersInPendingStatus',
  'restrictSendingBroadcastsOverTheWeekend',
  'restrictManagersAccessToAllLocations',
  'restrictSupportAgentsAccessToAllLocations',
  'restrictSupportAgentsAccessToMainInboxFolder',
  'restrictSupportAgentsAccessToUnassignedFolder',
  'restrictSupportAgentsAccessToArchivedFolder',
  'calendarInvitationsToUsers',
  'restrictDisplayingPushToCRMButtonToSupportAgents',
  'restrictAppointmentsOutsideWorkingHours',
  'restrictDisableAIBotManagers',
  'restrictDisableAIBotSupportAgents',
  'allowParseLeadsWithoutPhone',
  'restrictUserVoiceCallsFromUsingOrganizationNumbers',
  'restrictEditSuperHumanForManagers',
  'restrictEditSuperHumanForSupportAgents',
  'nextScheduledDripSequenceMessage',
  'superhumanActionInConversation',
  'allowFollowUp',
]

const Restrictions = ({ organization }) => {
  const [loading, setLoading] = useState(true)
  const [restrictions, setRestrictions] = useState({
    canMessageYellow: false,
    canSendBroadcastsOnWeekends: false,
    accessUnassignedFolder: false,
    accessArchiveFolder: true,
    accessMainInboxFolder: false,
    showSendToCrmButton: false,
    restrictManagToAllLoc: false,
    restrictSupToAllLoc: false,
    calenderInvitation: false,
    parseLeadsWithoutPhone: false,
    restrictAppointmentsOutsideWorkingHours: true,
    restrictDisableAIBotManagers: false,
    restrictDisableAIBotSupportAgents: true,
    restrictUserVoiceCallsFromUsingOrganizationNumbers: true,
    restrictEditSuperHumanForManagers: false,
    restrictEditSuperHumanForSupportAgents: false,
    allowFollowUp: false,
  })

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await organizationActions.getRestrictions(organization._id)
        if (result.success) {
          delete result.data.useEnterBtnToSendMessage
          const sortedObj = {}
          sequence.forEach((key) => {
            if (result.data.hasOwnProperty(key)) {
              sortedObj[key] = result.data[key]
            }
          })
          setRestrictions(sortedObj)
        }
      } catch (error) {
        notifyError(getTextServerError(error.message))
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [])

  const handleSwitchChange = async (key, value, newCheckboxValue = []) => {
    const obj = {
      [key]: userRoles.includes(key)
        ? {
            allow: value,
            roles: newCheckboxValue,
          }
        : value,
    }
    try {
      const result = await organizationActions.setRestrictions(obj, organization._id)
      if (result && result.success) {
        notifySuccess(getText('TEXT_RESTRICTION_WAS_CHANGED_SUCCESSFULLY'))
        const updatedUserData = authActions.getUserData()
        updatedUserData.organization.restrictions = result.data
        authActions.setUserData(updatedUserData)
      } else {
        notifyError(getTextServerError(result.errMsg))
      }
    } catch (error) {
      notifyError(getTextServerError(error.message))
    }
  }

  return !loading ? (
    <OrganizationPage
      title={getText('WORD_RESTRICTIONS')}
      subtitle={getText('TEXT_ORGANIZATION_RESTRICTIONS_SETTINGS')}
    >
      <PageFix>
        <MatBlockWithLabel className={'languages-wrapper'}>
          {Object.entries(restrictions).map(([key, value]) => {
            return (
              <MatBlockWithLabel className='restriction-item' key={key}>
                <SwitchWithText
                  title={
                    key === 'allowFollowUp' ? (
                      <Flex align='center' gap={4}>
                        {getText(key)}{' '}
                        <Tooltip title={getText('TEXT_FOLLOW_UP_TOOLTIP')}>
                          <InfoCircleOutlined />
                        </Tooltip>
                      </Flex>
                    ) : (
                      getText(key)
                    )
                  }
                  value={typeof value === 'object' ? value.allow : value}
                  noText
                  onChangeSwitch={(newValue) => {
                    setRestrictions((prevState) => ({
                      ...prevState,
                      [key]: userRoles.includes(key)
                        ? { allow: newValue, roles: prevState[key].roles }
                        : newValue,
                    }))
                    handleSwitchChange(key, newValue)
                  }}
                />
                {userRoles.includes(key) &&
                  (typeof value === 'object' ? value.allow : value) && (
                    <RestrictionsCheckboxGroup
                      key={key + value}
                      currentRestrictionName={key}
                      currentRestriction={restrictions[key]}
                      onChangeSwitch={(newValue, newCheckboxValue) => {
                        setRestrictions((prevState) => {
                          const oldRoles = prevState[key].roles
                          let exists = false

                          const filteredRoles = oldRoles.filter((role) => {
                            if (role === newCheckboxValue) {
                              exists = true
                            }
                            return role !== newCheckboxValue
                          })
                          if (!exists) {
                            filteredRoles.push(newCheckboxValue)
                          }
                          prevState[key].roles = filteredRoles
                          handleSwitchChange(key, newValue, filteredRoles)
                          return { ...prevState }
                        })
                      }}
                    />
                  )}
              </MatBlockWithLabel>
            )
          })}
        </MatBlockWithLabel>
      </PageFix>
    </OrganizationPage>
  ) : (
    <LoadingSpinner />
  )
}

export default Restrictions
