import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M17.4666 14.8667L16.1083 15.325C15.7333 15.45 15.4333 15.7417 15.3083 16.125L14.8499 17.4833C14.4583 18.6583 12.8083 18.6333 12.4416 17.4583L10.8999 12.5C10.5999 11.5167 11.5083 10.6 12.4833 10.9083L17.4499 12.45C18.6166 12.8167 18.6333 14.475 17.4666 14.8667Z'
      fill='#A3A1B7'
    />
    <path
      d='M17.5 9.58332C17.5 9.94165 17.475 10.3 17.4334 10.65C17.4 10.9 17.1417 11.05 16.9 10.975L12.8584 9.71665C11.9584 9.44165 11 9.67498 10.3334 10.3333C9.66671 11 9.42504 11.975 9.70838 12.875L10.9584 16.9C11.0334 17.1417 10.875 17.4 10.625 17.4333C10.275 17.475 9.93338 17.5 9.58338 17.5C5.13338 17.5 1.55004 13.8416 1.66671 9.35832C1.78338 5.22498 5.22504 1.78332 9.35838 1.66665C13.8417 1.54998 17.5 5.13332 17.5 9.58332Z'
      fill='#A3A1B7'
    />
  </svg>
)

const SVGLastLoginIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGLastLoginIcon
