import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { TABLE_DEFAULT_ROW_NUMBER } from '../../../../devOptions'
import { getText, getTextServerError } from '../../../../lang'
import organizationActions from '../../../../store/modules/organizationActions'
import { notifyError, notifySuccess } from '../../../../utils/Notify'
import MatButton from '../../../../components/MatButton'
import MatList from '../../../../components/MatList'
import PageFull from '../../../../components/PageFull'
import OrganizationPage from '../../components/OrganizationPage'
import TemplateListItem from './TemplateListItem'

export const TEMPLATE_TYPES = {
  SMS: 'sms',
  EMAIL: 'email',
}

const Templates = (props) => {
  const { organization, templateType } = props

  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [perPage, setPerPage] = useState(TABLE_DEFAULT_ROW_NUMBER)
  const [totalCount, setTotalCount] = useState(0)
  const [listData, setListData] = useState([])

  useEffect(() => {
    const fill = async () => {
      if (organization && organization._id) {
        getListData()
      }
    }
    fill()
  }, [organization, page, perPage, templateType])

  const getListData = async () => {
    setLoading(true)
    let result = await organizationActions.getPageListTemplets(
      page,
      perPage,
      organization._id,
      '',
      templateType
    )
    if (result.success) {
      setListData(result.data)
      setTotalCount(result.max)
      setLoading(false)
    }
  }

  return (
    <OrganizationPage
      title={getText(
        templateType === TEMPLATE_TYPES.EMAIL
          ? 'WORD_USER_EMAIL_TEMPLATES'
          : 'WORD_USER_SMS_TEMPLATES'
      )}
      subtitle={getText(
        templateType === TEMPLATE_TYPES.EMAIL
          ? 'TEXT_UPDATE_ORGANIZATION_USER_EMAIL_TEMPLATES'
          : 'TEXT_UPDATE_ORGANIZATION_USER_SMS_TEMPLATES'
      )}
    >
      <PageFull>
        <div className='broadcast-list-new-layout-wrapper'>
          <div className='broadcast-list-header'>
            <div className='left-content'>
              <p>{getText('WORD_TOTAL_TEMPLATES')}</p>
              <span>{totalCount}</span>
            </div>
            <div className='right-content'>
              <MatButton
                buttonText={getText(
                  templateType === TEMPLATE_TYPES.EMAIL
                    ? 'WORD_ADD_EMAIL_TEMPLATE'
                    : 'WORD_ADD_SMS_TEMPLATE'
                )}
                onClick={() => {
                  navigate(
                    `/organization/settings/${organization._id}/template/${templateType}/create`
                  )
                }}
              />
            </div>
          </div>
        </div>
        <MatList
          loading={loading}
          data={listData}
          pagination={true}
          defaultPageSize={perPage}
          paginationCurrentPage={page}
          paginationTotalCount={totalCount}
          onPaginationChange={setPage}
          onShowSizeChange={setPerPage}
          containerClassName={'user-template-org-wrapper'}
          renderItem={(item, index) => {
            return (
              <TemplateListItem
                key={index}
                item={item}
                onEdit={(item) => {
                  navigate(
                    `/organization/settings/${organization._id}/template/${templateType}/edit`,
                    { state: item }
                  )
                }}
                onDelete={async () => {
                  setLoading(true)
                  let result = await organizationActions.deleteTemplet(item._id)
                  if (result.success) {
                    notifySuccess(getText('TEXT_TEMPLATE_WAS_DELETED_SUCCESSFULLY'))
                    getListData()
                    setLoading(false)
                  } else {
                    notifyError(getTextServerError(result.errMsg))
                  }
                }}
              />
            )
          }}
        />
      </PageFull>
    </OrganizationPage>
  )
}

export default Templates
