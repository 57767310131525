import React from "react";
import Icon from "@ant-design/icons";

const SVGIcon = () => (
  <svg
    width="14"
    height="11"
    viewBox="0 0 14 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.74902 2.7502V1.5577C5.74902 0.890195 4.93902 0.552695 4.46652 1.0252L1.02402 4.4677C0.954498 4.53708 0.899328 4.6195 0.861698 4.71023C0.824058 4.80096 0.804688 4.89822 0.804688 4.99645C0.804688 5.09467 0.824058 5.19193 0.861698 5.28266C0.899328 5.37339 0.954498 5.45581 1.02402 5.5252L4.46652 8.9677C4.93902 9.4402 5.74902 9.1027 5.74902 8.4352V7.1752C9.49899 7.1752 12.124 8.3752 13.999 11.0002C13.249 7.2502 10.999 3.5002 5.74902 2.7502Z"
      fill="white"
    />
  </svg>
);

const SVGMessagesAIReplyIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />;
      }}
      {...props}
    />
  );
};

export default SVGMessagesAIReplyIcon;
