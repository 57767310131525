import React, { memo } from 'react'
import _ from 'lodash'
import { FilePdfOutlined, VideoCameraOutlined } from '@ant-design/icons'
import SVGClearCircle from '../../../../../icons/SVG/SVGClearCircle'
import SVGFile from '../../../../../icons/SVG/SVGFile'
import SVGHeicIcon from '../../../../../icons/SVG/SVGHeicIcon'
import './RenderMediaPreview.scss'

const RenderMediaPreview = (props) => {
  const { files, videoUpload, handleDeleteFile } = props

  let filesList = []
  if (files.length > 0) {
    filesList.push(...files)
  }
  if (!_.isEmpty(videoUpload)) {
    filesList.push(...videoUpload)
  }
  const defineClassName = (file) => {
    if (file.type) {
      if (file.type.slice(file.type.length - 3, file.type.length) === 'pdf') {
        return 'single-pdf '
      }
      return ' '
    }
    if (file.mediaContentType) {
      if (
        file.mediaContentType.slice(
          file.mediaContentType.length - 3,
          file.mediaContentType.length
        ) === 'pdf'
      ) {
        return 'single-pdf '
      }
      return ' '
    }
    return ' invalid-file'
  }

  return filesList.length ? (
    <div className='send-message-img'>
      <div className={`${filesList.length ? 'upload-files' : 'upload-files-hide'}`}>
        {filesList.map((file, index) => {
          return (
            <label
              key={index + file._id || index + file.id}
              className={`${defineClassName(file)} single-img`}
            >
              <label className='img-close' onClick={() => handleDeleteFile(file)}>
                <SVGClearCircle />
              </label>
              {file.status === 'upload' ? (
                <center>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/dots.gif`}
                    width='200'
                    height='200'
                    alt=''
                  />
                </center>
              ) : file.mediaContentType &&
                file.mediaContentType.startsWith('image/heic') ? (
                <SVGHeicIcon />
              ) : (file.mediaContentType &&
                  file.mediaContentType.startsWith('image/')) ||
                (file.type && file.type.startsWith('image/')) ? (
                <img src={file.mediaUrl} alt='upload img' />
              ) : (file.mediaContentType &&
                  file.mediaContentType.slice(
                    file.mediaContentType.length - 3,
                    file.mediaContentType.length
                  ) === 'pdf') ||
                (file.type &&
                  file.type.slice(file.type.length - 3, file.type.length) ===
                    'pdf') ? (
                <FilePdfOutlined style={{ backgroundColor: '#FFF' }} />
              ) : file.mediaContentType &&
                file.mediaContentType.startsWith('video/') ? (
                <div className='single-video'>
                  <VideoCameraOutlined
                    style={{ backgroundColor: '#FFF', fontSize: '40px' }}
                  />
                </div>
              ) : (
                <SVGFile style={{ width: '50px', height: '50px' }} />
              )}
            </label>
          )
        })}
      </div>
    </div>
  ) : null
}

export default memo(RenderMediaPreview)
