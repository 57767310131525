import {
  fetchFromUrlPOSTAsync,
  fetchFromUrlGETAsync,
  fetchFromUrlPUTAsync,
  fetchFromUrlDELETEAsync,
} from '../../utils/UrlHelper'

const urlFlow = '/chat-designers/location'

const fs = {
  getSettings: async (locationId) => {
    const result = await fetchFromUrlGETAsync(urlFlow + '/' + locationId, [])
    return result
  },

  insert: async (obj) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    const result = await fetchFromUrlPOSTAsync(urlFlow, urlParams)
    return result
  },

  update: async (obj, locationId) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    const result = await fetchFromUrlPUTAsync(urlFlow + '/' + locationId, urlParams)
    return result
  },

  delete: async (id) => {
    const result = await fetchFromUrlDELETEAsync(urlFlow + '/' + id, [])
    return result
  },
}

const loc = {}

const connectFlowActions = Object.assign(fs, loc)

export default connectFlowActions
