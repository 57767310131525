import React from 'react'

function SVGBroadcasting(props) {
  return (
    <svg
      width={props.width ? props.width : 13.961}
      height={props.height ? props.height : 16.956}
      viewBox='0 0 13.961 16.956'
    >
      <path
        data-name='Path 5913'
        fill={props.color ? props.color : '#a3a6be'}
        d='M6.228 16.956V8.615a1.8 1.8 0 111.507 0v8.341zM2.392 11.57a.471.471 0 00.02-.643 6.039 6.039 0 119.139 0 .471.471 0 10.709.62 6.98 6.98 0 10-10.555 0 .471.471 0 00.687.021zm7.44-1.74a.473.473 0 00.7-.042 4.531 4.531 0 10-7.111 0 .472.472 0 00.705.047.467.467 0 00.043-.619 3.589 3.589 0 115.617 0 .466.466 0 00.045.614z'
      />
    </svg>
  )
}

export default SVGBroadcasting
