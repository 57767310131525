import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='17'
      height='39'
      viewBox='0 0 17 39'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.7237 19.4806C16.9353 21.3891 15.4506 22.1333 14.1146 22.2815C12.7786 22.4296 7.57162 21.8826 7.41967 20.5123C7.26771 19.1421 12.2283 17.4675 13.5643 17.3193C14.9003 17.1712 16.5121 17.572 16.7237 19.4806Z'
        fill='#7AC6DC'
      />
      <path
        d='M10.869 35.9051C10.3785 37.743 8.81771 37.917 7.59788 37.5914C6.37805 37.2659 2.02184 34.9573 2.37403 33.6378C2.72621 32.3182 7.65343 32.4874 8.87326 32.813C10.0931 33.1386 11.3596 34.0672 10.869 35.9051Z'
        fill='#E7647F'
      />
      <path
        d='M8.46177 1.85863C7.31324 0.428804 5.80878 0.931548 4.80786 1.73553C3.80695 2.53951 0.666715 6.43104 1.49131 7.45762C2.31591 8.4842 6.79307 6.25709 7.79399 5.4531C8.7949 4.64912 9.61029 3.28846 8.46177 1.85863Z'
        fill='#F9CF67'
      />
    </svg>
  )
}

export const SVGWooHooIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}
