import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import { List } from 'antd'
import { Scrollbars } from 'react-custom-scrollbars'
import { LoadingOutlined } from '@ant-design/icons'
import SpringScrollbars from './SpringScrollBars'

const ListLoadMoreTop = forwardRef((props, ref) => {
  const InfiniteScrollRef = useRef()

  useImperativeHandle(ref, () => ({
    focus: () => {
      InfiniteScrollRef.current.focus()
    },
    scrollToTop: () => {
      if (InfiniteScrollRef.current) {
        InfiniteScrollRef.current.scrollIntoView({ behavior: 'smooth' })
      }
    },
    scrollToBottom: () => {
      setTimeout(() => {
        if (InfiniteScrollRef.current) {
          if (props.isAnimated) {
            InfiniteScrollRef.current.scrollTopAnimated(
              InfiniteScrollRef.current.getScrollHeight()
            )
          } else {
            InfiniteScrollRef.current.scrollToBottom()
          }
        }
      }, 150)
    },
    changeScrollTopPosition: (position) => {
      InfiniteScrollRef.current.scrollTop(position)
    },
  }))

  const spinerLoading = {
    spinning: props.loading,
    indicator: <LoadingOutlined style={{ fontSize: 24 }} />,
  }

  return (
    <>
      {Boolean(props.isAnimated) ? (
        <SpringScrollbars
          ref={InfiniteScrollRef}
          page={props.page}
          onScrollStop={() => {
            let isBottomLarge = InfiniteScrollRef.current.getScrollTop() <= 100
            if (
              props.data.length < props.totalDataCount &&
              !props.loading &&
              isBottomLarge
            ) {
              props.onLoadMore()
            }
          }}
          renderTrackHorizontal={(props) => (
            <div {...props} className='track-horizontal' />
          )}
          style={{ width: '100%', overscrollBehavior: 'auto' }}
          className={`mat-scrollbar ${props.className || ''}`}
        >
          <List
            loading={spinerLoading}
            header={props.header}
            footer={props.footer}
            renderItem={props.renderItem}
            dataSource={props.data}
            locale={props.locale}
            className={`mat-list-wrapper ${props.listClass || ''}`}
          />
        </SpringScrollbars>
      ) : (
        <Scrollbars
          ref={InfiniteScrollRef}
          onScrollStop={() => {
            let isBottomLarge = InfiniteScrollRef.current.getScrollTop() <= 100
            if (
              props.data.length < props.totalDataCount &&
              !props.loading &&
              isBottomLarge
            ) {
              props.onLoadMore()
            }
          }}
          renderTrackHorizontal={(props) => (
            <div {...props} className='track-horizontal' />
          )}
          style={{ width: '100%', overscrollBehavior: 'auto' }}
          className={`mat-scrollbar ${props.className || ''}`}
        >
          <List
            loading={spinerLoading}
            header={props.header}
            footer={props.footer}
            renderItem={props.renderItem}
            dataSource={props.data}
            locale={props.locale}
            className={`mat-list-wrapper ${props.listClass || ''}`}
          />
        </Scrollbars>
      )}
    </>
  )
})

export default ListLoadMoreTop
