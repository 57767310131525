import * as React from "react"

function SVGProfileBorderDashed(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="87.535" height="87.535" viewBox="0 0 87.535 87.535">
            <g id="Ellipse_173" data-name="Ellipse 173" fill="none" stroke={props.color ? props.color : "grey"} strokeLinejoin="bevel" strokeWidth="3" strokeDasharray="2 6" opacity="0.23">
                <circle cx="43.768" cy="43.768" r="43.768" stroke="none" />
                <circle cx="43.768" cy="43.768" r="42.268" fill="none" />
            </g>
        </svg>
    )
}

export default SVGProfileBorderDashed;

