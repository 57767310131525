import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import authActions from '../../store/modules/authActions'
import { getText } from '../../lang'
import SVGError from '../../icons/SVG/SVGError'
import MatButton from '../MatButton'
import './ErrorBox.scss'

const ErrorBox = () => {
  const user = authActions.getUserData()
  const navigate = useNavigate()

  return (
    <div className='mat-error-box'>
      <div className='error-image-wrapper'>
        <SVGError />
      </div>
      <div className='error-box-header'>
        <h2>{getText('WORD_OH_NO')}</h2>
        <h4>{getText('ERROR_WE_COULDNT_FIND_WHAT_YOURE_LOOKING_FOR')}</h4>
      </div>
      <div className='actions'>
        <Link
          to={
            Object.values(user).length ? navigate('/messages') : navigate('/login')
          }
        >
          <MatButton
            style={{ width: '220px' }}
            buttonText={
              Object.values(user).length
                ? getText('TITLE_MESSAGES')
                : getText('WORD_GO_BACK')
            }
          />
        </Link>
      </div>
    </div>
  )
}

export default ErrorBox
