import React from 'react'
import { Popover } from 'antd'
import SVGEditInputIcon from '../../../../../icons/SVG/SVGEditInputIcon'
import SVGEditColorIcon from '../../../../../icons/SVG/SVGEditColorIcon'
import SelectOrgLanguageForm from '../../../../../components/api/SelectOrgLanguageForm'
import InputFormNumber from '../../../../../components/Form/InputFormNumber'
import InputFormText from '../../../../../components/Form/InputFormText'
import { getText } from '../../../../../lang'
import MatColorPicker from '../../../../../components/MatColorPicker'

const LocationsCouponPopover = (props) => {
  const {
    organization,
    color,
    setColor,
    fontSize,
    setFontSize,
    visibleEdit,
    setVisibleEdit,
    selectName,
    colorName,
    fontsizeName,
    onlyBackground,
    whitoutFontSize,
    onChangeLanguage,
    withoutSelect,
  } = props

  const parseToHex = (color) => {
    if (color.length <= 7) {
      return color
    }
    const rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(',')
    const hex = `#${(
      (1 << 24) +
      (parseInt(rgba[0]) << 16) +
      (parseInt(rgba[1]) << 8) +
      parseInt(rgba[2])
    )
      .toString(16)
      .slice(1)}`
    return hex
  }

  return (
    <Popover
      content={
        <>
          {!onlyBackground && !withoutSelect && (
            <div className='language-wrapper'>
              <SelectOrgLanguageForm
                name={selectName}
                defaultValue={organization.defaultLanguage}
                label=''
                required
                organization={organization}
                onChange={(lng) => {
                  onChangeLanguage && onChangeLanguage(lng)
                }}
              />
            </div>
          )}
          <div className='popup-picker-color'>
            <InputFormText
              name={colorName}
              label={''}
              placeholder={color ? color : getText('WORD_HEX')}
            />
            <MatColorPicker
              trigger={
                <div
                  className='color-trigger'
                  style={{
                    backgroundColor: color,
                    border: '1px solid black',
                    borderRadius: '50%',
                  }}
                />
              }
              color={color}
              className='picker-color'
              onChangeColor={(newColor) => {
                setColor(parseToHex(newColor))
              }}
            />
          </div>
          {!onlyBackground && !whitoutFontSize && (
            <div className='font-size-wrapper'>
              <InputFormNumber
                name={fontsizeName}
                label={''}
                onChange={(fontsize) => {
                  setFontSize(fontsize)
                }}
                placeholder={fontSize ? fontSize : getText('LABEL_FONT_SIZE')}
              />
            </div>
          )}
        </>
      }
      trigger='click'
      placement='topRight'
      overlayClassName='edit-drop-wrapper'
      open={visibleEdit}
      onOpenChange={() => {
        setVisibleEdit(!visibleEdit)
      }}
    >
      {onlyBackground ? (
        <div className='color-edit'>
          <SVGEditColorIcon />
        </div>
      ) : (
        <SVGEditInputIcon />
      )}
    </Popover>
  )
}

export default LocationsCouponPopover
