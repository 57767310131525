import React, { useState, useEffect, useReducer } from 'react'
import { Form, Tabs, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { notifyError, notifySuccess } from '../../../../utils/Notify'
import locationActions from '../../../../store/modules/locationActions'
import authActions from '../../../../store/modules/authActions'
import { getText, getTextServerError } from '../../../../lang'
import { getLongName, MainColor } from '../../../../utils'
import { APP_URL } from '../../../../config'
import MatButton from '../../../../components/MatButton'
import MatForm from '../../../../components/Form/MatForm'
import MatColorPicker from '../../../../components/MatColorPicker'
import MatFormCheckbox from '../../../../components/Form/MatFormCheckbox'
import InputTextarea from '../../../../components/InputTextarea'
import MatBlockWithLabel from '../../../../components/MatBlockWithLabel'
import LoadingSpinner from '../../../../components/LoadingSpinner'
import MatadorConnectLayout from '../matadorConnect/matadorConnectComponents/MatadorConnectLayout'
import MatadorConnectListTitle from '../matadorConnect/matadorConnectComponents/MatadorConnectListTitle'
import InputFormTextAreaSecondary from '../../../../components/Form/InputFormTextArea/InputFormTextAreaSecondary'
import InputFormTextSecondary from '../../../../components/Form/InputFormText/InputFormTextSecondary'
import './TextMe.scss'
import UrlConditionManager from '../matadorConnect/settings/UrlConditionManager'
import InputFormNumberSecondary from '../../../../components/Form/InputFormNumber/InputFormNumberSecondary'
import TextMePreview from './Preview'
import SelectVariant from './SelectVariant'

const reducerTextMe = (state, action) => {
  if (!action.type) {
    return { ...state, ...action }
  }
  switch (action.type) {
    case 'INITIAL_STATE':
      return { ...action.payload }
    default:
      break
  }
}

const TextMe = (props) => {
  const { locationObj } = props
  const locationId = locationObj._id
  const organization = authActions.getOrganization()
  const defaultOrgLang = organization.defaultLanguage
  const [isLoading, setIsLoading] = useState(true)
  const [selectedLangTab, setSelectedLangTab] = useState(organization.languages[0])
  const [currentlyCopied, setCurrentlyCopied] = useState('')
  const [textMeState, dispatch] = useReducer(reducerTextMe, {})
  const [form] = Form.useForm()
  const [addLinkToBody, setAddLinkToBody] = useState()
  const [displayBackground, setDisplayBackground] = useState('')
  const [variant, setVariant] = useState('')
  const [urls, setUrls] = useState([])

  useEffect(() => {
    props.setFooterButtons && props.setFooterButtons(['save', 'cancel'])
    setSelectedLangTab(organization.languages[0])
    const getButtonData = async () => {
      setIsLoading(true)
      const result = await locationActions.getTextMeButton(locationId)

      const buttonData = result.data
      setAddLinkToBody(buttonData.textMeButton.addLinkToBody)
      setDisplayBackground(buttonData.displayBackground)
      setVariant(buttonData.variant)

      setUrls(buttonData.conditions.url)

      const initialState = {
        addLinkToBody: buttonData.textMeButton.addLinkToBody,
        templateText: buttonData.textMeButton.templateText,
        textMeButton: {
          backgroundColor: getHexColor(
            buttonData.textMeButton.backgroundColor || MainColor
          ),
          textColor: getHexColor(buttonData.textMeButton.textColor),
          addLinkToBody: buttonData.textMeButton.addLinkToBody,
          name: buttonData.textMeButton.name,
        },
        callMeButton: {
          backgroundColor: getHexColor(
            buttonData.callMeButton.backgroundColor || MainColor
          ),
          textColor: getHexColor(buttonData.callMeButton.textColor),
          name: buttonData.callMeButton.name,
        },
        active: buttonData.active,
        primaryBackgroundColor: getHexColor(
          buttonData.primaryBackgroundColor || MainColor
        ),
        variant: buttonData.variant,
        marginBottom: buttonData.marginBottom,
        displayBackground: buttonData.displayBackground,
      }
      dispatch({ type: 'INITIAL_STATE', payload: initialState })
      setIsLoading(false)
    }
    if (locationId) {
      getButtonData()
    }
  }, [locationId])

  const getHexColor = (color) => {
    if (!color) {
      return '#FFF'
    }
    if (color.length <= 7) {
      return color
    }
    const rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(',')
    const hex = `#${(
      (1 << 24) +
      (parseInt(rgba[0]) << 16) +
      (parseInt(rgba[1]) << 8) +
      parseInt(rgba[2])
    )
      .toString(16)
      .slice(1)}`
    return hex
  }

  const onFinish = async (values) => {
    props.setLoading(true)
    const allValues = form.getFieldsValue(true)

    if (!allValues.templateText || !allValues.templateText[defaultOrgLang]) {
      return notifyError(getText('TEXT_TEXT_ME_DEFAULT_LANGUAGE_MISSING'))
    }

    const newStyle = {
      active: true,
      primaryBackgroundColor: allValues.primaryBackgroundColor,
      callMeButton: {
        backgroundColor: allValues.callMeButton.backgroundColor,
        textColor: allValues.callMeButton.textColor,
        name: allValues.callMeButton.name,
      },
      textMeButton: {
        backgroundColor: allValues.textMeButton.backgroundColor,
        textColor: allValues.textMeButton.textColor,
        addLinkToBody: allValues.addLinkToBody,
        templateText: allValues.templateText,
        name: allValues.textMeButton.name,
      },
      variant: allValues.variant,
      displayBackground: allValues.displayBackground,
      conditions: {
        url: urls,
      },
      marginBottom: allValues.marginBottom,
    }
    const result = await locationActions.saveTextMeButton(newStyle, locationId)
    if (result.success) {
      notifySuccess(getText('TEXT_SUCCESS_TEXT_ME_BUTTON'))
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
    props.setLoading(false)
  }

  const widgetBodyText = `<!-- Start of Matador Call Script -->
  <script>
      (function(d, t) {
        var g = d.createElement(t),
          s = d.getElementsByTagName(t)[0];
        g.src = '${APP_URL}/_call.js';
        g.async = true;
        s.parentNode.insertBefore(g, s);
        g.onload = function() {
          matadorTextMeWidget("${locationId}");
        }
      })(document, "script");
  </script>
<!-- End of Matador Call Script -->`

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields.length > 0) {
      notifyError(errorInfo.errorFields[0].errors[0])
    }
  }

  if (isLoading || !locationId) {
    return <LoadingSpinner />
  }
  return (
    <MatadorConnectLayout className='text-me-wrapper'>
      <MatadorConnectListTitle title={getText('WORD_MOBILE_CTA')} />
      <div className='form-button-wrapper'>
        <MatForm
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={textMeState}
        >
          <MatBlockWithLabel black label={getText('WORD_TEXT_US_BUTTON')} />
          <Tabs
            className='mat-tabs'
            onChange={(index) => {
              setSelectedLangTab(organization.languages[index])
            }}
            items={organization.languages.map((lng, index) => {
              return {
                label: getLongName(lng) + ' ' + getText('WORD_MESSAGES_LC'),
                key: index,
                forceRender: true,
                children: (
                  <>
                    <InputFormTextAreaSecondary
                      label={getText('LABEL_TEXT_TEMPLATE')}
                      name={['templateText', lng]}
                      onChange={(e) => {
                        e.persist()
                        dispatch({
                          templateText: {
                            ...textMeState.templateText,
                            [lng]: e.target.value,
                          },
                        })
                      }}
                      required
                      message={getText(
                        'TEXT_TEXT_TEMPLATE_IS_REQUIRED_FOR_BOTH_LANGUAGES'
                      )}
                      autoSize={false}
                    />
                  </>
                ),
              }
            })}
          />

          <div className='row'>
            <div className='col'>
              <SelectVariant
                name='variant'
                label={getText('WORD_VARIANT')}
                required
                onChange={(e) => {
                  setVariant(e)
                }}
              />
            </div>

            <div className='col'>
              <div className='d-flex'>
                <InputFormNumberSecondary
                  label={getText('WORD_MARGIN_BOTTOM')}
                  name='marginBottom'
                  formClassName='input-item'
                  onChange={(value) => {
                    dispatch({ marginBottom: value })
                  }}
                  required
                />
                <Tooltip title='Adjust this value to set the distance (in pixels) from the bottom of the page to the mobile CTA.'>
                  <InfoCircleOutlined style={{ marginLeft: 8 }} />
                </Tooltip>
              </div>
            </div>
          </div>

          <hr />
          <MatBlockWithLabel black label={getText('WORD_CALL_ME_BUTTON')} />
          <InputFormTextSecondary
            label={getText('WORD_TITLE')}
            name={['callMeButton', 'name', selectedLangTab]}
            defaultValue={textMeState.callMeButton.name[selectedLangTab]}
            required
            onChange={(e) => {
              const newName = e.target.value
              dispatch({
                callMeButton: {
                  ...textMeState.callMeButton,
                  name: {
                    ...textMeState.callMeButton.name,
                    [selectedLangTab]: newName,
                  },
                },
              })
            }}
          />
          <div style={{ display: 'flex' }}>
            <MatColorPicker
              black
              label={getText('LABEL_BACKGROUND_COLOR')}
              color={textMeState.callMeButton.backgroundColor}
              className='color-pick-text-me'
              onChangeColor={(newColor) => {
                dispatch({
                  callMeButton: {
                    ...textMeState.callMeButton,
                    backgroundColor: newColor,
                  },
                })
                form.setFieldsValue({
                  callMeButton: {
                    ...textMeState.callMeButton,
                    backgroundColor: newColor,
                  },
                })
              }}
            />
            <MatColorPicker
              black
              label={getText('LABEL_TEXT_COLOR')}
              color={textMeState.callMeButton.textColor}
              className='color-pick-text-me'
              onChangeColor={(newColor) => {
                dispatch({
                  callMeButton: {
                    ...textMeState.callMeButton,
                    textColor: newColor,
                  },
                })
                form.setFieldsValue({
                  callMeButton: {
                    ...textMeState.callMeButton,
                    textColor: newColor,
                  },
                })
              }}
            />
          </div>
          <hr />
          <MatBlockWithLabel black label={getText('WORD_TEXT_US_BUTTON')} />
          <InputFormTextSecondary
            label={getText('WORD_TITLE')}
            name={['textMeButton', 'name', selectedLangTab]}
            defaultValue={textMeState.textMeButton.name[selectedLangTab]}
            required
            onChange={(e) => {
              const newName = e.target.value
              dispatch({
                textMeButton: {
                  ...textMeState.textMeButton,
                  name: {
                    ...textMeState.textMeButton.name,
                    [selectedLangTab]: newName,
                  },
                },
              })
            }}
          />
          <div style={{ display: 'flex' }}>
            <MatColorPicker
              black
              label={getText('LABEL_BACKGROUND_COLOR')}
              color={textMeState.textMeButton.backgroundColor}
              className='color-pick-text-me'
              onChangeColor={(newColor) => {
                dispatch({
                  textMeButton: {
                    ...textMeState.textMeButton,
                    backgroundColor: newColor,
                  },
                })
                form.setFieldsValue({
                  textMeButton: {
                    ...textMeState.textMeButton,
                    backgroundColor: newColor,
                  },
                })
              }}
            />
            <MatColorPicker
              black
              label={getText('LABEL_TEXT_COLOR')}
              color={textMeState.textMeButton.textColor}
              className='color-pick-text-me'
              onChangeColor={(newColor) => {
                dispatch({
                  textMeButton: {
                    ...textMeState.textMeButton,
                    textColor: newColor,
                  },
                })
                form.setFieldsValue({
                  textMeButton: {
                    ...textMeState.textMeButton,
                    textColor: newColor,
                  },
                })
              }}
            />
          </div>
          <div className='checkbox-style-wrapper d-flex'>
            <MatFormCheckbox
              black
              label={getText('WORD_ADD_LINK_TO_BODY')}
              name='addLinkToBody'
              className='checkbox-item'
              onChange={(e) => {
                setAddLinkToBody(e)
              }}
            />

            <MatFormCheckbox
              black
              label={getText('DISPLAY_BACKGROUND')}
              name='displayBackground'
              className='checkbox-item'
              onChange={(e) => {
                setDisplayBackground(e)
              }}
            />
          </div>
          <hr />
          <div style={{ display: 'flex' }}>
            <MatColorPicker
              black
              label={getText('WORD_PRIMARY_BACKGROUND_COLOR')}
              color={textMeState.primaryBackgroundColor}
              className='color-pick-text-me'
              onChangeColor={(newColor) => {
                dispatch({
                  primaryBackgroundColor: newColor,
                })
                form.setFieldsValue({
                  primaryBackgroundColor: newColor,
                })
              }}
            />
          </div>
          <hr />

          <MatBlockWithLabel
            black
            label={getText('WORD_WIDGET_CODE')}
            className='text-area-copy-wrapper'
          >
            <InputTextarea autoSize={true} defaultValue={widgetBodyText} disabled />
            <CopyToClipboard
              text={widgetBodyText}
              onCopy={() => setCurrentlyCopied('widgetBody')}
            >
              <MatButton
                size='small'
                htmlType='button'
                className='copy-button'
                buttonText={
                  currentlyCopied === 'widgetBody'
                    ? getText('WORD_COPIED')
                    : getText('WORD_COPY')
                }
              />
            </CopyToClipboard>
          </MatBlockWithLabel>

          <UrlConditionManager urls={urls} setUrls={setUrls} />
        </MatForm>
        <TextMePreview
          locationId={locationId}
          settings={{ ...textMeState, displayBackground, variant, addLinkToBody }}
          language={selectedLangTab}
        />
      </div>
    </MatadorConnectLayout>
  )
}

export default TextMe
