import React from 'react'
import { Form, Input } from 'antd'
import { getText } from '../../../lang'

const InputFormEmail = (props) => {
  return (
    <Form.Item
      className={`mat-form-item ${props.formClassName || ''}`}
      label={props.label}
      name={props.name}
      hasFeedback={props.hasFeedback}
      rules={[
        { required: props.required, message: props.message, type: props.type },
      ]}
    >
      <Input
        className={'mat-input-affix-wrapper'}
        type={props.type}
        placeholder={props.placeholder || props.label}
        allowClear={props.allowClear}
        disabled={props.disabled}
        autoComplete={props.autoComplete}
        size={props.size}
        onChange={(e) => {
          props.onChange && props.onChange(e)
        }}
      />
    </Form.Item>
  )
}

InputFormEmail.defaultProps = {
  name: 'email',
  placeholder: getText('WORD_EMAIL'),
  required: false,
  hasFeedback: false,
  allowClear: true,
  minLength: 9,
  type: 'email',
  autoComplete: 'new-email',
  size: 'middle',
}

export default InputFormEmail
