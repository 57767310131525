import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from 'antd'
import { Progress } from 'react-sweet-progress'
import SVGBackgroundEllipse from '../../../../icons/SVG/SVGBackgroundEllipse'
import ProfileNumbers from '../../../../components/ProfileLetters/ProfileNumbers'

const TouchPointItems = ({
  activeTouchpoint,
  strokeWidth,
  className,
  percent,
  status,
  title,
  width,
  count,
}) => {
  return (
    <div className='touch-points-row'>
      <div className='marketing-source'>
        <div className='circle-background' />
        <div className='ellipse'>
          <SVGBackgroundEllipse />
        </div>
        <div className='icon'>
          <ProfileNumbers
            name={`${percent}%`}
            withoutIcon
            activeIcon={activeTouchpoint}
            className='medium'
          />
        </div>

        <div className='progress-wrapper'>
          <Progress
            symbolClassName='hide-default-icon'
            width={width}
            status={status}
            strokeWidth={strokeWidth}
            type='circle'
            percent={percent / 2}
            className={className}
            theme={{
              success: {
                color: '#48AF80',
              },
              active: {
                color: '#fbc630',
              },
              default: {
                color: '#fbc630',
              },
            }}
          />
        </div>
      </div>
      <Tooltip overlayInnerStyle={{ color: 'black' }} title={title} color={'white'}>
        <div className='icon-title'>{title}</div>
      </Tooltip>
      <span className='progress-value'>{count}</span>
    </div>
  )
}

TouchPointItems.propTypes = {
  activeTouchpoint: PropTypes.bool,
  iconID: PropTypes.string,
  strokeWidth: PropTypes.number,
  colorProgress: PropTypes.string,
  className: PropTypes.string,
  isCircle: PropTypes.bool,
  percent: PropTypes.number,
  status: PropTypes.string,
  title: PropTypes.string,
  width: PropTypes.number,
}

export default TouchPointItems
