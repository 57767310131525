import React, { useEffect } from 'react'
import { Form, Tabs } from 'antd'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import promotionsActions from '../../../../../store/modules/promotionsActions'
import announcementsActions from '../../../../../store/modules/announcementsActions'
import { notifyError, notifySuccess } from '../../../../../utils/Notify'
import { getText, getTextServerError } from '../../../../../lang'
import { getLongName, replaceWords } from '../../../../../utils'
import PageFull from '../../../../../components/PageFull'
import PageFix from '../../../../../components/PageFix'
import MatForm from '../../../../../components/Form/MatForm'
import InputFormText from '../../../../../components/Form/InputFormText'
import InputFormTextArea from '../../../../../components/Form/InputFormTextArea'
import MatDatePicker from '../../../../../components/Form/MatDatePicker'

const AnnouncementEdit = (props) => {
  const navigate = useNavigate()
  const locationId = props.locationObj._id
  const languages = props.organization.languages
  const item = props.location.state && props.location.state.item
  const type = props.location.pathname
    .substring(props.location.pathname.lastIndexOf('/') + 1)
    .includes('special')
    ? 'Special'
    : 'Announcement'
  const isNewAnnouncement = !item
  const actions = type === 'Special' ? promotionsActions : announcementsActions
  const [form] = Form.useForm()
  const typeUppercase = type.toUpperCase()

  useEffect(() => {
    props.setFooterButtons && props.setFooterButtons(['save', 'cancel'])
  }, [])

  useEffect(() => {
    if (isNewAnnouncement && props.location.pathname.includes('edit')) {
      navigate(`/location/settings/${locationId}/livechat-announcements`)
    }
  }, [props.location.pathname])

  const onFinish = async (values) => {
    const allValues = form.getFieldsValue(true)
    const payload = {
      locationId: locationId,
      title: allValues.title,
      description: allValues.description,
      status: (item && item.status) || 'ACTIVE',
      startDate: allValues.startDate,
      endDate: allValues.endDate,
      imageUrl: allValues.imageUrl,
      ...(allValues.url && { url: allValues.url }),
    }
    if (Date.parse(allValues.startDate) > Date.parse(allValues.endDate)) {
      return notifyError(getText('ERROR_END_DATE_AFTER_START_DATE'))
    }
    // This is necessary as to avoid sending empty string as title or description for a language that is not in the organizatioon languages, they come from the BE from the beginning
    for (const lng in payload.title) {
      if (!languages.includes(lng)) {
        delete payload.title[lng]
        delete payload.description[lng]
      }
    }
    if (!isNewAnnouncement) {
      // api edit
      const result = await actions.update(payload, item.id)
      if (!result.success) {
        return notifyError(getTextServerError(result.errMsg))
      }
      const successMsg = getText(`SUCCESS_${typeUppercase}_SAVED`)
      notifySuccess(successMsg)
    } else {
      const result = await actions.insert(payload)
      if (!result.success) {
        return notifyError(result.errMsg)
      }
      const successMsg = getText(`SUCCESS_${typeUppercase}_SAVED`)
      notifySuccess(successMsg)
    }
    navigate(`/location/settings/${locationId}/livechat-announcements`)
  }

  const onFinishFailed = (errorInfo) => {
    const newItem = errorInfo.values
    for (const lng of languages) {
      if (!newItem.title[lng]) {
        const errorText = replaceWords(
          getText('TEXT_VARIABLE_REQUIRED_BOTH_LANGUAGE'),
          {
            missingVariable: getText('LABEL_TITLE'),
            missingLanguage: getLongName(lng),
          }
        )
        return notifyError(errorText)
      }
    }
    for (const lng of languages) {
      if (!newItem.description[lng]) {
        const errorText = replaceWords(
          getText('TEXT_VARIABLE_REQUIRED_BOTH_LANGUAGE'),
          {
            missingVariable: getText('LABEL_DESCRIPTION'),
            missingLanguage: getLongName(lng),
          }
        )
        return notifyError(errorText)
      }
    }
    if (errorInfo.errorFields.length > 0) {
      notifyError(errorInfo.errorFields[0].errors[0])
    }
  }

  return (
    <PageFull>
      <PageFix
        title={
          isNewAnnouncement
            ? getText(`TITLE_CREATE_${typeUppercase}`)
            : getText(`TITLE_EDIT_${typeUppercase}`)
        }
      >
        <MatForm
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{
            title: {
              en: item && item.title ? item.title.en : '',
              sp: item && item.title ? item.title.sp : '',
              fr: item && item.title ? item.title.fr : '',
            },
            description: {
              en: item && item.description ? item.description.en : '',
              sp: item && item.description ? item.description.sp : '',
              fr: item && item.description ? item.description.fr : '',
            },
            url: (item && item.url) || '',
            imageUrl: (item && item.imageUrl) || '',
            startDate: (item && moment(item.startDate)) || '',
            endDate: (item && moment(item.endDate)) || '',
          }}
        >
          <Tabs
            className='mat-tabs'
            items={languages.map((lng, index) => {
              return {
                label: getLongName(lng),
                key: index,
                forceRender: true,
                children: (
                  <>
                    <InputFormText
                      label={getText('LABEL_TITLE')}
                      name={['title', lng]}
                      required
                      errorMessage={getText('ERR_VALIDATION_REQUIRED')}
                    />
                    <InputFormTextArea
                      label={getText('LABEL_DESCRIPTION')}
                      name={['description', lng]}
                      required
                      message={getText('ERR_VALIDATION_REQUIRED')}
                    />
                  </>
                ),
              }
            })}
          />
          <InputFormText label={getText('LABEL_URL_OPTIONAL')} name='url' />
          <InputFormText
            label={getText('LABEL_IMAGE_URL')}
            name='imageUrl'
            required
            errorMessage={getText('ERR_VALIDATION_REQUIRED')}
          />
          <MatDatePicker
            label={getText('LABEL_START_DATE')}
            name='startDate'
            format='YYYY-MM-DD'
            required
            message={getText('ERR_VALIDATION_REQUIRED')}
          />
          <MatDatePicker
            label={getText('LABEL_END_DATE')}
            name='endDate'
            format='YYYY-MM-DD'
            required
            message={getText('ERR_VALIDATION_REQUIRED')}
          />
        </MatForm>
      </PageFix>
    </PageFull>
  )
}

export default AnnouncementEdit
