import React, { useEffect, useState } from 'react'
import { Card, Button, Form, Typography } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { getText } from '../../../../../lang'
import SelectFormSecondary from '../../../../../components/Form/SelectForm/SelectFormSecondary'
import MatButton from '../../../../../components/MatButton'
import organizationActions from '../../../../../store/modules/organizationActions'
import { getUserRole } from '../../../../../utils'
import authActions from '../../../../../store/modules/authActions'
import MatSwitch from '../../../../../components/MatSwitch'
import { notifyError } from '../../../../../utils/Notify'
import locationActions from '../../../../../store/modules/locationActions'
import StepHeader from './StepHeader'
import StepOptions from './StepOptions'
import FlowPreview from './FlowPreview'
const { Title } = Typography

function transformData(data) {
  return data.map((item) => {
    const newItem = {}
    Object.keys(item).forEach((key) => {
      if (key === 'name') {
        newItem['title'] = item[key]
      } else if (key === '_id') {
        newItem['value'] = item[key]
        newItem['key'] = item[key]
      } else if (key === 'locations') {
        newItem['children'] = transformData(item[key])
      } else {
        newItem[key] = item[key]
      }
    })
    return newItem
  })
}

const getTypes = () => {
  return [
    { value: null, label: getText('WORD_SELECT_TYPE') },
    { value: 'Locations', label: 'Locations' },
    { value: 'Questions -> Locations', label: 'Questions -> Locations' },
    { value: 'Custom Questions', label: 'Custom Questions' },
    { value: 'Final View', label: 'Final View' },
  ]
}

const FlowDesigner = ({ save, initialData, user, enabled, setEnabled, token }) => {
  const [steps, setSteps] = useState([])
  const [treeData, setTreeData] = useState([])
  const [treePage, setTreePage] = useState(0)
  const [isDataTree, setIsDataTree] = useState(true)
  const [organizations, setOrganizations] = useState([])
  const organization = authActions.getOrganization()

  useEffect(() => {
    setSteps(initialData)
  }, [initialData])

  useEffect(() => {
    const fill = async () => {
      const result = await organizationActions.getOrganizationsLocationsList(
        treePage,
        100,
        '',
        {
          enabled: true,
        },
        false,
        getUserRole(
          user.isSuperAdmin,
          user.isAdmin,
          user.isManager,
          user.isSupportAgent
        ),
        user._location_id,
        true
      )
      if (result.success) {
        const list = transformData(result.data)
        const newTreeData = treePage > 0 ? [...treeData.list, ...list] : list
        setTreeData({
          list: newTreeData,
          total: result.max,
        })
        setOrganizations([...organizations, ...result.data])
        if (newTreeData.length < result.max) {
          setTreePage(treePage + 1)
        }
      }
      setIsDataTree(false)
    }
    fill()
  }, [treePage])

  const getSelectedLocationIds = (step) => {
    if (step.locations) {
      return step.locations.map((location) => location.location._id)
    }
  }

  const addStep = () => {
    setSteps([
      ...steps,
      {
        showBackAction: false,
        title: '',
        description: '',
      },
    ])
  }

  const removeStep = (index) => {
    const updatedSteps = [...steps]
    updatedSteps.splice(index, 1)
    setSteps(updatedSteps)
  }

  const handleStepTypeChange = (index, value) => {
    const updatedSteps = [...steps]
    updatedSteps[index].type = value

    if (value === 'Location Questions') {
      updatedSteps[index].locationQuestions = {
        locationId: null,
        organizationId: null,
      }
    } else if (value === 'Final View') {
      updatedSteps[index].finalView = null
    } else if (value === 'Locations') {
      updatedSteps[index].locations = []
    }
    setSteps(updatedSteps)
  }

  const getOrganizationIfByLocationId = (locationId) => {
    const organization = treeData.list.find((item) => {
      return item.children.find((tag) => tag.id === locationId)
    })
    if (organization) {
      return organization.id
    }
  }

  const handleSelectedLocationChange = (index, locationId) => {
    const updatedSteps = [...steps]
    updatedSteps[index].locationQuestions = {
      locationId,
      organizationId: getOrganizationIfByLocationId(locationId),
    }
    setSteps(updatedSteps)
  }

  const handleViewTypeChange = (index, value) => {
    const updatedSteps = [...steps]
    updatedSteps[index].finalView = value
    setSteps(updatedSteps)
  }

  const handleLocationReorder = (index) => (result) => {
    if (!result.destination) return

    const updatedSteps = [...steps]
    const locations = updatedSteps[index].locations
    const [reorderedLocation] = locations.splice(result.source.index, 1)
    locations.splice(result.destination.index, 0, reorderedLocation)

    setSteps(updatedSteps)
  }

  const handleShowBackActionChange = (index, checked) => {
    const updatedSteps = [...steps]
    updatedSteps[index].showBackAction = checked
    setSteps(updatedSteps)
  }

  const handleTitleChange = (index, value, language) => {
    const updatedSteps = [...steps]
    if (!updatedSteps[index].title) {
      updatedSteps[index].title = {}
    }
    updatedSteps[index].title[language] = value
    setSteps(updatedSteps)
  }

  const handleDescriptionChange = (index, value, language) => {
    const updatedSteps = [...steps]
    if (!updatedSteps[index].description) {
      updatedSteps[index].description = {}
    }
    updatedSteps[index].description[language] = value
    setSteps(updatedSteps)
  }

  const handleLocationChange = (index, locationId, customName, language) => {
    const updatedSteps = [...steps]
    const stepToUpdate = updatedSteps[index]

    const locationIndex = stepToUpdate.locations.findIndex(
      (item) => item.location._id === locationId
    )

    if (locationIndex === -1) {
      return
    }

    if (!stepToUpdate.locations[locationIndex].locationCustomName) {
      stepToUpdate.locations[locationIndex].locationCustomName = {}
    }

    stepToUpdate.locations[locationIndex].locationCustomName[language] = customName

    setSteps(updatedSteps)
  }

  const findLocationsByIds = (idsToFind) => {
    const result = []

    for (let item of treeData.list) {
      for (let tag of item.children) {
        if (idsToFind.includes(tag.id)) {
          result.push({
            location: {
              _id: tag.id,
              name: tag.title,
            },
            organization: {
              _id: item.id,
              name: item.title,
            },
          })
        }
      }
    }

    return result
  }

  const addSelectedLocations = (index, value) => {
    const updatedSteps = [...steps]
    updatedSteps[index].selectedLocationIds = value
    updatedSteps[index].locations = findLocationsByIds(value)
    setSteps(updatedSteps)
  }

  const handleCustomQuestionsChange = (index, value) => {
    if (!value) return

    const updatedSteps = [...steps]
    updatedSteps[index].customQuestions = value
    setSteps(updatedSteps)
  }

  //
  const getLocationsByQuestion = async ({ text, language }) => {
    const result = await locationActions.getLocationsByQuestion({ text, language })
    if (result.success) {
      return result.data
    }
  }

  const handleQuestionChange = async (selectedQuestions, stepIndex) => {
    const updatedSteps = [...steps]

    const data = selectedQuestions.map((item) => JSON.parse(item))

    for (let index = 0; index < data.length; index++) {
      const element = data[index]
      if (!element.locations) {
        const items = await getLocationsByQuestion({
          text: element.question,
          language: 'en',
        })
        if (items && items.questions && items.questions.length > 0) {
          data[index].locations = items.questions
        }
      }
    }

    updatedSteps[stepIndex].questionsAndLocations = data
    setSteps(updatedSteps)
  }

  const handleLocationUpdate = (
    questionId,
    locationId,
    customName,
    language,
    stepIndex
  ) => {
    const updatedSteps = [...steps]
    const stepsToUpdate = updatedSteps[stepIndex]

    const questionsAndLocations = stepsToUpdate.questionsAndLocations

    const index = questionsAndLocations.findIndex(
      (item) => item.questionId === questionId
    )

    if (index === -1) {
      return
    }

    const locationIndex = questionsAndLocations[index].selectedLocations.findIndex(
      (item) => item.locationId === locationId
    )

    debugger
    if (locationIndex === -1) {
      return
    }

    if (
      !questionsAndLocations[index].selectedLocations[locationIndex]
        .locationCustomName
    ) {
      questionsAndLocations[index].selectedLocations[
        locationIndex
      ].locationCustomName = {}
    }

    questionsAndLocations[index].selectedLocations[locationIndex].locationCustomName[
      language
    ] = customName

    setSteps(updatedSteps)
  }

  const handleQuestionLocationChange = (selectedLocations, question, stepIndex) => {
    const updatedSteps = [...steps]
    const stepsToUpdate = updatedSteps[stepIndex]
    const questionsAndLocations = stepsToUpdate.questionsAndLocations

    const selectedLocationsArray =
      selectedLocations && selectedLocations.map((item) => JSON.parse(item))

    const items = questionsAndLocations
    const item = items.find((x) => x.questionId === question.questionId)

    if (item) {
      item.selectedLocations = selectedLocationsArray
      stepsToUpdate.questionsAndLocations = items
    }

    setSteps(updatedSteps)
  }

  const handleQuestionLocationReorder = (index) => (result, stepIndex) => {
    if (!result.destination) return
    const updatedSteps = [...steps]
    const stepsToUpdate = updatedSteps[stepIndex]
    const questionsAndLocations = stepsToUpdate.questionsAndLocations

    const updatedQuestionsAndLocations = Array.from(questionsAndLocations)
    const locations = Array.from(questionsAndLocations[index].locations)
    const [reorderedLocation] = locations.splice(result.source.index, 1)
    locations.splice(result.destination.index, 0, reorderedLocation)

    updatedQuestionsAndLocations[index].locations = locations
    setSteps(updatedSteps)
  }

  const renderMainContentSettings = ({ step, index }) => {
    return (
      <div className='main-content-item-wrapper'>
        <Title level={4}>Main Content Settings</Title>
        <SelectFormSecondary
          name='mainContentType'
          label={getText('WORD_TYPE')}
          initialValue={step.type}
          options={getTypes()}
          onChange={(e) => {
            handleStepTypeChange(index, e)
          }}
          required
        />

        <StepOptions
          step={step}
          index={index}
          treeData={treeData}
          isDataTree={isDataTree}
          handleSelectedLocationChange={handleSelectedLocationChange}
          handleViewTypeChange={handleViewTypeChange}
          addSelectedLocations={addSelectedLocations}
          getSelectedLocationIds={getSelectedLocationIds}
          handleLocationReorder={handleLocationReorder}
          handleCustomQuestionsChange={handleCustomQuestionsChange}
          organizations={organizations}
          handleLocationChange={handleLocationChange}
          organization={organization}
          getLocationsByQuestion={getLocationsByQuestion}
          handleQuestionChange={handleQuestionChange}
          handleLocationUpdate={handleLocationUpdate}
          handleQuestionLocationChange={handleQuestionLocationChange}
          handleQuestionLocationReorder={handleQuestionLocationReorder}
        />
      </div>
    )
  }

  const displayErrorMessage = (index, message) => {
    notifyError(`Step ${index + 1}: ${message}`)
  }

  const validateForm = () => {
    for (let index = 0; index < steps.length; index++) {
      const step = steps[index]

      if (!step.type) {
        displayErrorMessage(index, 'Please select type for Main Consent Settings')
        return false
      }

      if (step.type === 'Custom Questions') {
        if (!step.customQuestions || step.customQuestions.length === 0) {
          displayErrorMessage(index, 'Please select at least 1 question')
          return false
        }
      } else if (step.type === 'Final View') {
        if (!step.finalView) {
          displayErrorMessage(index, 'Please select View type')
          return false
        }
      } else if (step.type === 'Locations') {
        if (step.locations) {
          // update
          if (step.locations.length === 0) {
            displayErrorMessage(index, 'Please select at least 1 location')
            return false
          }
        } else {
          // add
          if (!step.selectedLocationIds) {
            displayErrorMessage(index, 'Please select at least 1 location')
            return false
          }
        }
      } else if (step.type === 'Questions -> Locations') {
        if (!step.questionsAndLocations || step.questionsAndLocations.length === 0) {
          displayErrorMessage(index, 'Please select at least 1 question')
          return false
        }

        for (let i = 0; i < step.questionsAndLocations.length; i++) {
          const item = step.questionsAndLocations[i]

          if (!item.selectedLocations || item.selectedLocations.length === 0) {
            displayErrorMessage(
              index,
              'Please select at least 1 location for each question'
            )
            return false
          }
        }
      }
    }

    return true
  }

  const saveChanges = () => {
    const isValid = validateForm()
    if (isValid) {
      save(steps)
    }
  }

  return (
    <div className='d-flex flex-column flex-md-row'>
      <div className='col col-md-6 mx-auto'>
        <div className='d-flex gap-2 mb-5'>
          <MatButton buttonText='Add Step' onClick={addStep} />
          <MatButton buttonText='Save changes' onClick={saveChanges} />
        </div>

        <MatSwitch
          label='Enable custom Matador Connect'
          parentClassName={'organization-switch-with-icon-and-text'}
          value={enabled}
          onChange={() => {
            setEnabled(!enabled)
          }}
        />

        {steps.map((step, index) => (
          <Card
            title={`Step ${index + 1}`}
            key={index}
            style={{ marginTop: '10px', position: 'relative' }}
            className='mb-4'
            extra={
              <Button
                type='text'
                shape='circle'
                onClick={() => removeStep(index)}
                icon={<CloseOutlined />}
              />
            }
          >
            <Form layout='vertical'>
              <StepHeader
                step={step}
                index={index}
                handleShowBackActionChange={handleShowBackActionChange}
                handleTitleChange={handleTitleChange}
                handleDescriptionChange={handleDescriptionChange}
                organization={organization}
              />
              {renderMainContentSettings({ step, index })}
            </Form>
          </Card>
        ))}
      </div>
      <div className='w-100 w-md-50 text-center pt-5'>
        <FlowPreview token={token} steps={steps} />
      </div>
    </div>
  )
}

export default FlowDesigner
