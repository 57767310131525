import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Collapse, Space } from 'antd'
import broadcastingActions from '../../../store/modules/broadcastingActions'
import { getText } from '../../../lang'
import { getKValue } from '../../../utils'
import SVGArrowDown from '../../../icons/SVG/SVGArrowDown'
import SVGDownloadIconNew from '../../../icons/SVG/SVGDownloadIconNew'
import SVGReviewCheck from '../../../icons/SVG/SVGReviewCheck'
import MatButton from '../../../components/MatButton'
import {
  getBroadcastTags,
  getBroadcastAssignedUsers,
  getBroadcastLogicalTags,
  getBroadcastTouchpoints,
} from './broadcastListUtils'
import BroadcastListMobileHeader from './BroadcastListMobileItemsComponents/BroadcastListMobileHeader'
import BroadcastListMobileItem from './BroadcastListMobileItemsComponents/BroadcastListMobileItem'
import BroadcastListMobileItemBlue from './BroadcastListMobileItemsComponents/BroadcastListMobileItemBlue'
import BroadcastListMobileItemOptedOut from './BroadcastListMobileItemsComponents/BroadcastListMobileItemOptedOut'
import BroadcastListMobileItemPercentage from './BroadcastListMobileItemsComponents/BroadcastListMobileItemPercentage'
import BroadcastListMobiletemMessage from './BroadcastListMobileItemsComponents/BroadcastListMobiletemMessage'
import BroadcastListMobileItemExcel from './BroadcastListMobileItemsComponents/BroadcastListMobileItemExcel'
import BroadcastListMobileItemWorkflow from './BroadcastListMobileItemsComponents/BroadcastListMobileItemWorkflow'
import BroadcastListMobileItemTags from './BroadcastListMobileItemsComponents/BroadcastListMobileItemTags'
import BroadcastListMobileItemLogicalTags from './BroadcastListMobileItemsComponents/BroadcastListMobileItemLogicalTags'

const BroadcastListMobileCollapsePanel = (props) => {
  const { item, getStatus, setListData } = props
  let per = (item.responded_count / item.reached_count) * 100

  const navigate = useNavigate()

  const [showDetails, setShowDetails] = useState(false)

  return (
    <Collapse
      expandIcon={(expanded) =>
        expanded.isActive ? (
          <div className='broadcast-list-table-expand-icon opened'>
            <SVGArrowDown />
          </div>
        ) : (
          <SVGArrowDown />
        )
      }
      expandIconPosition={'end'}
      bordered={false}
      className='broadcast-list-mobile-collapse'
      items={[
        {
          key: item._id,
          label: (
            <BroadcastListMobileHeader
              name={item.name}
              getStatus={getStatus}
              status={item.status}
              action_time={item.action_time}
              canceled={item.canceled}
              _id={item._id}
            />
          ),
          children: (
            <Space direction='vertical' style={{ width: '100%' }}>
              <BroadcastListMobileItem
                title={getText('WORD_CREATED_BY')}
                infoItem={Boolean(item.who_created) && item.who_created.fullName}
              />
              <BroadcastListMobileItem
                title={getText('WORD_AUDIENCE')}
                infoItem={getKValue(item.total_customers_count)}
                tooltipMessage={item.emptyMessage}
                tooltipMessageTitle={getText('WORD_EMPTY')}
              />
              <BroadcastListMobileItem
                title={getText('WORD_DELIVERED')}
                infoItem={getKValue(item.reached_count)}
              />
              <BroadcastListMobileItemBlue
                title={getText('WORD_REPLY')}
                infoItem={getKValue(item.responded_count)}
                onClick={() => {
                  navigate('/messages?type=all', {
                    state: {
                      broadcast: item,
                      reply: true,
                    },
                  })
                }}
              />
              <BroadcastListMobileItemOptedOut
                title={getText('WORD_OPTED_OUT')}
                infoItem={getKValue(item.opted_out_count)}
                download={
                  item.optedOuts.length > 0 && (
                    <SVGDownloadIconNew
                      onClick={async () => {
                        await broadcastingActions.downloadOptedOutCustomer(
                          item.id,
                          item.name
                        )
                      }}
                    />
                  )
                }
              />
              <BroadcastListMobileItemBlue
                title={getText('WORD_OPPORTUNITIES')}
                infoItem={getKValue(item.opportunity_count)}
                onClick={() => {
                  navigate('/messages?type=all', {
                    state: {
                      broadcast: item,
                      opportunities: true,
                    },
                  })
                }}
              />
              <BroadcastListMobileItemPercentage
                title={getText('WORD_RESPONSE')}
                showDetails={showDetails}
                infoItem={!isNaN(per) ? parseFloat(per.toFixed(2)) + '%' : '0%'}
              />
              {showDetails ? (
                <Space direction='vertical' style={{ width: '100%' }}>
                  <BroadcastListMobiletemMessage
                    status={item.status}
                    action_time={item.action_time}
                    canceled={item.canceled}
                    message={item.message}
                    excelDoc={item.excelDoc}
                    _id={item._id}
                    setListData={setListData}
                    media={item.media}
                  />
                  <BroadcastListMobileItem
                    title={getText('WORD_LOCATION')}
                    infoItem={item.location.name}
                  />
                  {item.excelDoc && (
                    <BroadcastListMobileItemExcel
                      title={getText('WORD_UPLOADED_FILE')}
                      infoItem={
                        item.excelDoc.endsWith('.csv')
                          ? getText('WORD_CSV_FILE')
                          : getText('WORD_EXCEL_FILE')
                      }
                      href={item.excelDoc}
                    />
                  )}
                  <BroadcastListMobileItem
                    title={getText('WORD_OPTED_IN')}
                    infoItem={getText(!item.send_consent ? 'WORD_YES' : 'WORD_NO')}
                    icon={
                      !item.send_consent ? (
                        <SVGReviewCheck
                          width={14}
                          height={14}
                          style={{ marginRight: 6 }}
                        />
                      ) : null
                    }
                  />
                  {Boolean(item.days_to_skip) && (
                    <BroadcastListMobileItem
                      title={getText(
                        'TEXT_EXCLUDE_CUSTOMERS_WHO_HAVE_BEEN_MESSAGED_IN_THE_LAST'
                      )}
                      infoItem={
                        <>
                          {item.days_to_skip || 0}{' '}
                          {getText('WORD_DAYS').toLowerCase()}
                        </>
                      }
                    />
                  )}
                  {getBroadcastTags(item) && getBroadcastTags(item).length > 0 && (
                    <BroadcastListMobileItemTags
                      title={getText('WORD_TAGS')}
                      arrayList={getBroadcastTags(item)}
                    />
                  )}
                  {getBroadcastLogicalTags(item) &&
                    getBroadcastLogicalTags(item).length > 0 && (
                      <BroadcastListMobileItemLogicalTags
                        title={getText('WORD_TAGS')}
                        arrayList={getBroadcastLogicalTags(item)}
                      />
                    )}
                  {getBroadcastAssignedUsers(item) &&
                    getBroadcastAssignedUsers(item).length > 0 && (
                      <BroadcastListMobileItem
                        title={getText('WORD_ASSIGNED_TO')}
                        infoItem={getBroadcastAssignedUsers(item).map(
                          (user, index, arr) => {
                            return arr.length - 1 === index
                              ? `${user.fullName}`
                              : `${user.fullName}, `
                          }
                        )}
                      />
                    )}
                  {getBroadcastTouchpoints(item) &&
                    getBroadcastTouchpoints(item).length > 0 && (
                      <BroadcastListMobileItemTags
                        title={getText('WORD_TOUCHPOINTS')}
                        arrayList={getBroadcastTouchpoints(item)}
                      />
                    )}
                  {item.workflow && (
                    <BroadcastListMobileItemWorkflow
                      name={item.workflow.name}
                      workflowData={item.workflowData}
                    />
                  )}
                </Space>
              ) : null}
              <MatButton
                buttonText={
                  showDetails
                    ? getText('WORD_HIDE_DETAILS')
                    : getText('WORD_SHOW_DETAILS')
                }
                className='broadcast-mobile-show-details-btn'
                onClick={() => setShowDetails(!showDetails)}
              />
            </Space>
          ),
        },
      ]}
    />
  )
}

export default BroadcastListMobileCollapsePanel
