import React, { useState } from 'react'
import MatButton from '../../../../../components/MatButton'
import SVGMessagesAIReplyIcon from '../../../../../icons/SVG/SVGMessagesAIReplyIcon'
import { getText } from '../../../../../lang'
import messageActions from '../../../../../store/modules/messageActions'
import { notifyError } from '../../../../../utils/Notify'

const deviceSizes = ['tablet', 'tabletPortrait', 'mobile']

const AIGeneratedButton = ({
  conversation,
  newMessageValue,
  setnewMessageValue,
  currentCursorPosition,
  setcurrentCursorPosition,
  screenSize,
  disabled,
  tooltip,
  tooltipTitle,
}) => {
  const [loading, setloading] = useState(false)

  const subStringMessageText = (message, oldMessage) => {
    return `${oldMessage.substring(
      0,
      currentCursorPosition
    )} ${message} ${oldMessage.substring(currentCursorPosition)}`
  }

  return (
    <MatButton
      icon={<SVGMessagesAIReplyIcon />}
      size={'small'}
      tooltip={tooltip}
      tooltipTitle={tooltipTitle}
      buttonText={
        deviceSizes.includes(screenSize)
          ? getText('WORD_AI')
          : getText('WORD_AI_REPLY')
      }
      style={{
        marginRight: '4px',
        backgroundColor: disabled || tooltip ? '#a3a6be' : '#3F8CFF',
        padding: deviceSizes.includes(screenSize) ? '7px 10px 7px 7px' : '15px',
      }}
      loading={loading}
      disabled={disabled || tooltip}
      onClick={async () => {
        setloading(true)
        const result = await messageActions.getAIReplyMessage(conversation._id)
        if (result.success) {
          if (result.data === '') {
            notifyError(getText('TEXT_THERE_IS_NO_GENERATED_MESSAGE'))
          } else {
            const letterForNewValue = subStringMessageText(
              result.data.message,
              newMessageValue.inputMessageForTags
            )
            const letterForPlainText = subStringMessageText(
              result.data.message,
              newMessageValue.inputMessageForSending
            )
            setnewMessageValue({
              inputMessageForTags: letterForNewValue,
              inputMessageForSending: letterForPlainText,
            })
            setcurrentCursorPosition(letterForPlainText.length)
          }
        } else {
          notifyError(result.errMsg)
        }
        setloading(false)
      }}
    />
  )
}

export default AIGeneratedButton
