import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width='14'
    height='14'
    viewBox='0 0 14 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M7.0026 13.6654C3.3206 13.6654 0.335938 10.6807 0.335938 6.9987C0.335938 3.3167 3.3206 0.332031 7.0026 0.332031C10.6846 0.332031 13.6693 3.3167 13.6693 6.9987C13.6693 10.6807 10.6846 13.6654 7.0026 13.6654ZM7.0026 12.332C8.41709 12.332 9.77365 11.7701 10.7738 10.7699C11.774 9.76974 12.3359 8.41319 12.3359 6.9987C12.3359 5.58421 11.774 4.22766 10.7738 3.22746C9.77365 2.22727 8.41709 1.66536 7.0026 1.66536C5.58812 1.66536 4.23156 2.22727 3.23137 3.22746C2.23117 4.22766 1.66927 5.58421 1.66927 6.9987C1.66927 8.41319 2.23117 9.76974 3.23137 10.7699C4.23156 11.7701 5.58812 12.332 7.0026 12.332ZM7.66927 6.9987H10.3359V8.33203H6.33594V3.66536H7.66927V6.9987Z'
      fill='#77808D'
    />
  </svg>
)

const SVGBooking = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGBooking
