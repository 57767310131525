import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_14958_56686)'>
      <path
        d='M13.3335 8.66634V3.99967C13.3335 2.52692 12.1396 1.33301 10.6668 1.33301H5.3335C3.86074 1.33301 2.66683 2.52692 2.66683 3.99967V8.66634M14.6668 10.6663V12.6663C14.6668 13.7709 13.7714 14.6663 12.6668 14.6663H3.3335C2.22893 14.6663 1.3335 13.7709 1.3335 12.6663V10.6663C1.3335 9.56177 2.22893 8.66634 3.3335 8.66634H4.00016C4.41984 8.66634 4.81502 8.86393 5.06683 9.19967L5.86683 10.2663C6.37044 10.9378 7.16081 11.333 8.00016 11.333C8.83952 11.333 9.62988 10.9378 10.1335 10.2663L10.9335 9.19967C11.1853 8.86393 11.5805 8.66634 12.0002 8.66634H12.6668C13.7714 8.66634 14.6668 9.56177 14.6668 10.6663Z'
        stroke={props.color || '#A3A1B7'}
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_14958_56686'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

const SVGFilterArchived = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGFilterArchived
