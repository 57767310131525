import React from 'react'

const IconLocation = {
  edit: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='13'
      height='13'
      viewBox='0 0 13 13'
    >
      <g id='noun_edit_1751206' transform='translate(-577.581 -275.995)'>
        <g id='Group_17' data-name='Group 17' transform='translate(577.581 275.995)'>
          <path
            id='Path_19'
            data-name='Path 19'
            d='M6.451,12.6l.378-.378L3.39,8.786l7.737-7.737a1.824,1.824,0,0,1,2.579,0l.86.86a1.824,1.824,0,0,1,0,2.579Zm-.486.478a1.824,1.824,0,0,1-1.181.434H2.4a.3.3,0,0,1-.3-.3V10.831A1.824,1.824,0,0,1,2.535,9.65Z'
            transform='translate(-2.101 -0.515)'
            fillRule='evenodd'
          />
        </g>
      </g>
    </svg>
  ),
  expandArrow: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='7.741'
      height='7.741'
      viewBox='0 0 7.741 7.741'
    >
      <path
        id='Shape_12'
        data-name='Shape 12'
        d='M41,143.387h5.888l-2.7-2.7.687-.682,3.871,3.871-3.871,3.87-.682-.682,2.7-2.7H41Z'
        transform='translate(147.741 -41) rotate(90)'
        fill='#5b66ea'
      />
    </svg>
  ),
  email: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='11.719'
      height='8.113'
      viewBox='0 0 11.719 8.113'
      fill='#cdcfe0'
    >
      <g id='noun_Email_1740173' transform='translate(-11 -23)'>
        <g id='Group_1302' data-name='Group 1302' transform='translate(11 23)'>
          <path
            id='Path_1163'
            data-name='Path 1163'
            d='M11.9,975.362a.869.869,0,0,0-.479.146l5.075,4.808a.5.5,0,0,0,.732,0l5.066-4.808a.869.869,0,0,0-.479-.146Zm-.845.62A.953.953,0,0,0,11,976.3v6.244a.955.955,0,0,0,.146.512l3.587-3.582Zm11.606,0L19,979.465l3.578,3.587a.954.954,0,0,0,.146-.512V976.3a.952.952,0,0,0-.056-.315Zm-4.1,3.9-.916.869a1.134,1.134,0,0,1-1.559,0l-.916-.864-3.549,3.54a.872.872,0,0,0,.282.047h9.916a.872.872,0,0,0,.282-.047l-3.54-3.544Z'
            transform='translate(-11 -975.362)'
          />
        </g>
      </g>
    </svg>
  ),
  phone: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='10.863'
      height='10.862'
      viewBox='0 0 10.863 10.862'
    >
      <g id='noun_Phone_2236827' transform='translate(-33.254 -33.531)'>
        <g
          id='Group_1303'
          data-name='Group 1303'
          transform='translate(33.255 33.531)'
        >
          <path
            id='Path_1164'
            data-name='Path 1164'
            d='M40.172,41.578c.163-.2.733-1.141,1.589-.856.9.285,3.138,1.059,2.078,2.445-3.545,4.808-14.14-5.827-9.372-9.372,1.385-1.019,2.16,1.182,2.445,2.078s-.611,1.426-.856,1.589C35.445,38.033,39.642,42.189,40.172,41.578Z'
            transform='translate(-33.255 -33.531)'
            fillRule='evenodd'
          />
        </g>
      </g>
    </svg>
  ),
  add: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='11'
      height='11'
      viewBox='0 0 11 11'
      fill='#cdcfe0'
    >
      <g id='noun_Plus_680243' transform='translate(-10 -10)'>
        <path
          id='Path_1165'
          data-name='Path 1165'
          d='M15.5,21A5.5,5.5,0,1,1,21,15.5,5.5,5.5,0,0,1,15.5,21Zm2.005-6H16v-1.5a.5.5,0,0,0-1,0V15h-1.5a.5.5,0,1,0,0,1H15v1.5a.5.5,0,0,0,1,0V16h1.5a.5.5,0,0,0,.5-.5A.508.508,0,0,0,17.505,15Z'
          fillRule='evenodd'
        />
      </g>
    </svg>
  ),
  recommend: (
    <svg
      id='Livello_2'
      data-name='Livello 2'
      xmlns='http://www.w3.org/2000/svg'
      width='11.059'
      height='11.185'
      viewBox='0 0 11.059 11.185'
      fill='#cdcfe0'
    >
      <g id='Livello_1' data-name='Livello 1'>
        <path
          id='Path_1190'
          data-name='Path 1190'
          d='M10.468,0H.59A.592.592,0,0,0,0,.59V7.842a.592.592,0,0,0,.59.59H2.073v2.361a.4.4,0,0,0,.676.276L5.391,8.432h5.077a.592.592,0,0,0,.59-.59V.59A.592.592,0,0,0,10.468,0ZM8.088,3.76l-1.321.961a.169.169,0,0,0-.061.188l.5,1.554a.169.169,0,0,1-.26.188L5.629,5.69a.169.169,0,0,0-.2,0L4.11,6.65a.169.169,0,0,1-.26-.188l.5-1.552a.169.169,0,0,0-.061-.188L2.971,3.76a.169.169,0,0,1,.1-.3H4.7a.169.169,0,0,0,.16-.116l.5-1.554a.169.169,0,0,1,.321,0l.5,1.554a.169.169,0,0,0,.169.116h1.63a.169.169,0,0,1,.1.3Z'
        />
      </g>
    </svg>
  ),
  checkCircle: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='#29CC97'
    >
      <g id='noun_checkmark_2193579' transform='translate(-23 -23)'>
        <g id='Group_889' data-name='Group 889' transform='translate(23 23)'>
          <path
            id='Path_925'
            data-name='Path 925'
            d='M30,23a7,7,0,1,1-7,7A7,7,0,0,1,30,23Zm-1.505,8.208L33.307,26.4a.54.54,0,0,1,.788,0l.63.612a.571.571,0,0,1,0,.788L28.9,33.622a.571.571,0,0,1-.788,0l-2.818-2.817a.571.571,0,0,1,0-.788l.63-.613a.54.54,0,0,1,.788,0Z'
            transform='translate(-23 -23)'
            fillRule='evenodd'
          />
        </g>
      </g>
    </svg>
  ),
  clearCircle: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='13.981'
      height='14'
      viewBox='0 0 13.981 14'
    >
      <path
        id='Intersection_1'
        data-name='Intersection 1'
        d='M9.086,17a7.069,7.069,0,0,1-6.009-5.552V8.537A7.069,7.069,0,0,1,8.974,3h2.037A7.066,7.066,0,0,1,10.9,17Zm1.264-5.936,1.962,1.963a.505.505,0,0,0,.715-.715L11.064,10.35a.506.506,0,0,1,0-.715l1.963-1.962a.505.505,0,0,0-.715-.715L10.35,8.922a.506.506,0,0,1-.715,0L7.673,6.959a.5.5,0,0,0-.714.715L8.922,9.635a.506.506,0,0,1,0,.715L6.959,12.312a.5.5,0,0,0,.714.715l1.963-1.963a.506.506,0,0,1,.715,0Z'
        transform='translate(-3.077 -3)'
        fill='#ff7366'
      />
    </svg>
  ),
  urlIconFacebook: (
    <svg
      id='Group_1809'
      data-name='Group 1809'
      xmlns='http://www.w3.org/2000/svg'
      width='20.855'
      height='20.855'
      viewBox='0 0 20.855 20.855'
    >
      <circle
        id='Ellipse_93'
        data-name='Ellipse 93'
        cx='10.428'
        cy='10.428'
        r='10.428'
        fill='#3b5998'
      />
      <path
        id='Path_932'
        data-name='Path 932'
        d='M43.833,28.554H41.972V35.37H39.153V28.554H37.812v-2.4h1.341v-1.55A2.644,2.644,0,0,1,42,21.763l2.088.009V24.1H42.57a.574.574,0,0,0-.6.653v1.41h2.107Z'
        transform='translate(-30.783 -17.718)'
        fill='#f4f7fc'
      />
    </svg>
  ),
  urlIconGoogle: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='21'
      height='21'
      viewBox='0 0 21 21'
    >
      <g id='Group_1810' data-name='Group 1810' transform='translate(-456 -546)'>
        <circle
          id='Ellipse_373'
          data-name='Ellipse 373'
          cx='10.5'
          cy='10.5'
          r='10.5'
          transform='translate(456 546)'
          fill='#f4f7fc'
        />
        <g id='Group_1065' data-name='Group 1065' transform='translate(460 550)'>
          <path
            id='Path_950'
            data-name='Path 950'
            d='M14.641,10.047a7.151,7.151,0,0,0-3.926,3.429,5.778,5.778,0,0,0-.587,1.76,6.942,6.942,0,0,0,.587,4.467,6.656,6.656,0,0,0,1.9,2.3,6.357,6.357,0,0,0,2.527,1.263,7.157,7.157,0,0,0,3.52.045,6.166,6.166,0,0,0,4.6-4.377,7.7,7.7,0,0,0,.18-3.655h-6.5v2.707h3.745a3.15,3.15,0,0,1-1.4,2.121,3.747,3.747,0,0,1-1.534.587,5.182,5.182,0,0,1-1.67,0,3.905,3.905,0,0,1-1.534-.677A4.144,4.144,0,0,1,12.972,17.9a3.986,3.986,0,0,1,0-2.707,4.641,4.641,0,0,1,.993-1.624,3.637,3.637,0,0,1,2.076-1.128,3.886,3.886,0,0,1,2.031.09,4.435,4.435,0,0,1,1.489.857l1.263-1.263.677-.677a7.075,7.075,0,0,0-2.211-1.4A7.439,7.439,0,0,0,14.641,10.047Z'
            transform='translate(-9.989 -9.675)'
            fill='#f4f7fc'
          />
          <g
            id='Group_1061'
            data-name='Group 1061'
            transform='translate(0.727 0.005)'
          >
            <path
              id='Path_951'
              data-name='Path 951'
              d='M15.526,10.054a7.133,7.133,0,0,1,4.648.045,6.346,6.346,0,0,1,2.211,1.4l-.677.677-1.263,1.263a3.313,3.313,0,0,0-1.489-.857,4.4,4.4,0,0,0-2.031-.09,4.688,4.688,0,0,0-2.076,1.128,4.062,4.062,0,0,0-.993,1.624c-.767-.587-1.489-1.173-2.256-1.76A7.151,7.151,0,0,1,15.526,10.054Z'
              transform='translate(-11.6 -9.687)'
              fill='#ea4335'
            />
          </g>
          <g id='Group_1062' data-name='Group 1062' transform='translate(0 3.802)'>
            <path
              id='Path_952'
              data-name='Path 952'
              d='M10.129,19.86a8.953,8.953,0,0,1,.587-1.76c.767.587,1.489,1.173,2.256,1.76a3.986,3.986,0,0,0,0,2.707c-.767.587-1.489,1.173-2.256,1.76A6.98,6.98,0,0,1,10.129,19.86Z'
              transform='translate(-9.989 -18.1)'
              fill='#fbbc05'
            />
          </g>
          <g
            id='Group_1063'
            data-name='Group 1063'
            transform='translate(6.909 5.606)'
          >
            <path
              id='Path_953'
              data-name='Path 953'
              d='M25.3,22.1h6.5a7.7,7.7,0,0,1-.18,3.655,6.432,6.432,0,0,1-1.76,2.888c-.722-.587-1.444-1.128-2.211-1.715a3.428,3.428,0,0,0,1.4-2.121H25.3Q25.3,23.454,25.3,22.1Z'
              transform='translate(-25.3 -22.1)'
              fill='#4285f4'
            />
          </g>
          <g
            id='Group_1064'
            data-name='Group 1064'
            transform='translate(0.727 8.269)'
          >
            <path
              id='Path_954'
              data-name='Path 954'
              d='M11.6,29.76c.767-.587,1.489-1.173,2.256-1.76a4.144,4.144,0,0,0,1.579,2.121,4.688,4.688,0,0,0,1.534.677,5.179,5.179,0,0,0,1.67,0,4.515,4.515,0,0,0,1.534-.587c.722.587,1.444,1.128,2.211,1.715a6.633,6.633,0,0,1-2.843,1.489,7.144,7.144,0,0,1-3.52-.045A7.01,7.01,0,0,1,13.5,32.106,8.509,8.509,0,0,1,11.6,29.76Z'
              transform='translate(-11.6 -28)'
              fill='#34a853'
            />
          </g>
        </g>
      </g>
    </svg>
  ),
  urlIconYelp: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20.855'
      height='20.855'
      viewBox='0 0 20.855 20.855'
    >
      <g id='iconfinder_social-26_1591841' transform='translate(-5 -5)'>
        <circle
          id='Ellipse_97'
          data-name='Ellipse 97'
          cx='10.428'
          cy='10.428'
          r='10.428'
          transform='translate(5 5)'
          fill='#cb2727'
        />
        <g
          id='Group_1066'
          data-name='Group 1066'
          transform='translate(11.652 10.393)'
        >
          <path
            id='Path_955'
            data-name='Path 955'
            d='M36.4,50.146a.406.406,0,0,0,.287-.393.436.436,0,0,0-.261-.444l-.467-.193a11.169,11.169,0,0,0-1.751-.7.345.345,0,0,0-.309.179,3.682,3.682,0,0,0-.174,1.635,1.333,1.333,0,0,0,.1.422.349.349,0,0,0,.3.2,9.6,9.6,0,0,0,1.616-.5Z'
            transform='translate(-33.705 -43.75)'
            fill='#f4f7fc'
          />
          <path
            id='Path_956'
            data-name='Path 956'
            d='M42.18,58.434a.425.425,0,0,0-.5.114s-.326.393-.327.393a11.477,11.477,0,0,0-1.195,1.477.316.316,0,0,0-.017.146.388.388,0,0,0,.09.206,3.451,3.451,0,0,0,1.9.711.353.353,0,0,0,.282-.223,11.043,11.043,0,0,0,.033-1.717s0-.7,0-.709A.41.41,0,0,0,42.18,58.434Z'
            transform='translate(-38.651 -51.419)'
            fill='#f4f7fc'
          />
          <path
            id='Path_957'
            data-name='Path 957'
            d='M53.63,43.785l-.009.014a4.944,4.944,0,0,1,.5-.135,11.8,11.8,0,0,0,1.821-.482.356.356,0,0,0,.145-.33.074.074,0,0,0,0-.014,3.564,3.564,0,0,0-1.128-1.7.345.345,0,0,0-.359.014,10.744,10.744,0,0,0-1.018,1.366l-.414.571a.411.411,0,0,0,0,.488A.424.424,0,0,0,53.63,43.785Z'
            transform='translate(-48.598 -38.127)'
            fill='#f4f7fc'
          />
          <path
            id='Path_958'
            data-name='Path 958'
            d='M39.947,28.543a.36.36,0,0,0-.273-.254,4.673,4.673,0,0,0-2.385.681.37.37,0,0,0-.105.345c.047.1,2.09,3.366,2.09,3.366.3.5.549.42.629.395s.325-.1.3-.683C40.171,31.713,39.97,28.684,39.947,28.543Z'
            transform='translate(-36.37 -28.273)'
            fill='#f4f7fc'
          />
          <path
            id='Path_959'
            data-name='Path 959'
            d='M54.789,56.642s-.652-.219-.66-.223a.4.4,0,0,0-.459.148.441.441,0,0,0-.044.516l.263.434a12.568,12.568,0,0,0,1.014,1.61.336.336,0,0,0,.353.038,3.5,3.5,0,0,0,1.252-1.618.361.361,0,0,0-.12-.342A10.86,10.86,0,0,0,54.789,56.642Z'
            transform='translate(-48.96 -49.878)'
            fill='#f4f7fc'
          />
        </g>
      </g>
    </svg>
  ),
}
export default IconLocation
