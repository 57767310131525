import { combineReducers, configureStore } from '@reduxjs/toolkit'
import {
  persistStore,
  persistReducer,
  // FLUSH,
  // REHYDRATE,
  // PAUSE,
  // PERSIST,
  // PURGE,
  // REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web and AsyncStorage for react-native
import authReducer from './modules/redux/reducers/authReducer'
import customerReducer from './modules/redux/reducers/customerReducer'
import messageReducer from './modules/redux/reducers/messageReducer'

const reducers = combineReducers({
  authReducer,
  customerReducer,
  messageReducer,
})

const persistConfig = {
  key: 'root',
  storage,
}
const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false, // [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
    }),
})

export const persistor = persistStore(store)
