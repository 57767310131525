import moment from 'moment'
import { getText } from '../../../../lang'

export const DaysCheck = (workingDays) => {
  const days = Object.entries(workingDays)
  let errorMessage = ''

  for (const [day, { from, to }] of days) {
    const validRegexp = /^(0?[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$/

    const isFromValid = validRegexp.test(from)
    const isToValid = validRegexp.test(to)

    if (!isFromValid || !isToValid) {
      errorMessage = `Please enter working hours in format H:MM (P|A)M at ${day}`
      break
    }

    let [fromHour, fromMinute] = moment(from, ['h:mm A']).format('HH:mm').split(':')
    let [toHour, toMinute] = moment(to, ['h:mm A']).format('HH:mm').split(':')

    if (fromMinute.length !== 2 || toMinute.length !== 2) {
      errorMessage = getText('TEXT_PLEASE_ENTER_VALID_MINUTES_FORMAT')
      break
    }

    fromHour = +fromHour
    fromMinute = +fromMinute
    toHour = +toHour
    toMinute = +toMinute

    if (fromHour > 23 || toHour > 23 || fromHour < 0 || toHour < 0) {
      errorMessage = getText('ERR_WRONG_OPENING_HOURS')
      break
    }

    if (fromHour > toHour) {
      errorMessage = getText('ERR_WRONG_OPENING_HOURS')
      break
    }
  }

  return errorMessage
}
