import React from 'react'
import LocationCouponDescriptionTextarea from './LocationCouponDescriptionTextarea'
import LocationsCouponPopover from './LocationsCouponPopover'

const LocationCouponDescription = (props) => {
  const {
    descriptionEn,
    descriptionFr,
    descriptionSp,
    lang,
    onChangedescriptionEn,
    onChangedescriptionFr,
    onChangedescriptionSp,
    organization,
    onChangeDescriptionColor,
    descriptionColor,
    descriptionFontSize,
    setdescriptionFontSize,
    visibleDescriptionEdit,
    setVisibleDescriptionEdit,
    onChangeLanguage,
  } = props

  return (
    <div className='text-with-edit-icon'>
      {lang === 'en' && (
        <LocationCouponDescriptionTextarea
          value={descriptionEn}
          descriptionColor={descriptionColor}
          descriptionFontSize={descriptionFontSize}
          onChangeDescription={(e) => {
            onChangedescriptionEn && onChangedescriptionEn(e)
          }}
        />
      )}
      {lang === 'fr' && (
        <LocationCouponDescriptionTextarea
          value={descriptionFr}
          descriptionColor={descriptionColor}
          descriptionFontSize={descriptionFontSize}
          onChangeDescription={(e) => {
            onChangedescriptionFr && onChangedescriptionFr(e)
          }}
        />
      )}
      {lang === 'sp' && (
        <LocationCouponDescriptionTextarea
          value={descriptionSp}
          descriptionColor={descriptionColor}
          descriptionFontSize={descriptionFontSize}
          onChangeDescription={(e) => {
            onChangedescriptionSp && onChangedescriptionSp(e)
          }}
        />
      )}
      <LocationsCouponPopover
        organization={organization}
        color={descriptionColor}
        setColor={(color) => {
          onChangeDescriptionColor && onChangeDescriptionColor(color)
        }}
        fontSize={descriptionFontSize}
        setFontSize={setdescriptionFontSize}
        visibleEdit={visibleDescriptionEdit}
        setVisibleEdit={setVisibleDescriptionEdit}
        whitoutFontSize
        onChangeLanguage={onChangeLanguage}
        // withoutSelect={withoutSelect}
        onlyBackground
      />
    </div>
  )
}

export default LocationCouponDescription
