import React from 'react'

const BroadcastListDesktopItemLogicalTags = ({ title, arrayList }) => {
  return (
    <div className='broadcast-message-row-wrapper-with-margin'>
      <div className='broadcast-message-row'>
        <p className='broadcast-more-info-row-title'>{title}</p>
      </div>
      <div className='tags-more-info-wrapper'>
        {arrayList.map((tag) =>
          tag.map((tagIt) => {
            return (
              <div
                key={tagIt.id}
                className='info-item-blue'
                style={{ width: 'fit-content' }}
              >
                {tagIt.name}
              </div>
            )
          })
        )}
      </div>
    </div>
  )
}

export default BroadcastListDesktopItemLogicalTags
