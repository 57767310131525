import React, { useCallback } from 'react'
import { DatePicker, Form } from 'antd'
import dayjs from 'dayjs'
import localeSP from 'antd/es/date-picker/locale/eu_ES'
import localEN from 'antd/es/date-picker/locale/en_US'
import localeFR from 'antd/es/date-picker/locale/fr_FR'
import authActions from '../../../store/modules/authActions'
import { getText } from '../../../lang'
import 'dayjs/locale'
import './MatDatePicker.scss'

const MatDatePicker = (props) => {
  const { onChange } = props

  const userLang = authActions.getLanguage()

  const rules = useCallback(() => {
    const rules = []
    if (props.required && !props.disabled) {
      rules.push({ required: props.required, message: props.message })
    }
    return rules
  }, [props.required, props.disabled])

  return (
    <Form.Item
      label={props.label}
      name={props.name}
      hasFeedback={props.hasFeedback}
      rules={rules()}
      className={`mat-form-item ${props.className || ''}`}
      style={props.formStyle}
    >
      <DatePicker
        allowClear={props.allowClear}
        onChange={onChange}
        className={`mat-date-picker ${props.className || ''}`}
        popupStyle={props.popupStyle}
        format={props.format}
        locale={
          userLang === 'fr' ? localeFR : userLang === 'sp' ? localeSP : localEN
        }
        style={props.style}
        size={props.size}
        popupClassName={`mat-date-picker-dropdown ${props.dropdownClassName || ''}`}
        disabledDate={props.disabledDate}
        disabled={props.disabled}
        placeholder={props.placeholder}
        message={props.message}
      />
    </Form.Item>
  )
}

MatDatePicker.defaultProps = {
  onChange: (e) => {},
  name: 'datepicker',
  label: '',
  message: getText('WORD_PLEASE_SELECT_DATE'),
  required: true,
  allowClear: true,
  mode: '',
  placeholder: 'YYYY-MM-DD',
  disabledDate: false,
  defaultValue: dayjs(),
  size: 'middle',
}

export default MatDatePicker
