import React from "react";
import Icon from "@ant-design/icons";

const SVGIcon = (props) => (
  <svg
    width="21"
    height="17"
    viewBox="0 0 21 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.01416 0.998047C1.35726 0.998047 0.0141602 2.34105 0.0141602 3.99805V4.99805H20.0142V3.99805C20.0142 2.34105 18.6711 0.998047 17.0142 0.998047H3.01416ZM0.0141602 6.99805V13.998C0.0141602 15.655 1.35726 16.998 3.01416 16.998H17.0142C18.6711 16.998 20.0142 15.655 20.0142 13.998V6.99805H0.0141602ZM5.01416 8.99805H7.01416C7.56646 8.99805 8.01416 9.44605 8.01416 9.99805V11.998C8.01416 12.55 7.56646 12.998 7.01416 12.998H5.01416C4.46186 12.998 4.01416 12.55 4.01416 11.998C4.01416 11.748 4.01416 10.248 4.01416 9.99805C4.01416 9.44605 4.46186 8.99805 5.01416 8.99805Z"
      fill="#0049C6"
    />
  </svg>
);

const SVGAmountIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />;
      }}
      {...props}
    />
  );
};

export default SVGAmountIcon;
