import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='6.148'
    height='6.135'
    viewBox='0 0 6.148 6.135'
  >
    <path
      id='Edit_Square'
      data-name='Edit Square'
      d='M12.915,7.3a.86.86,0,0,0-1.219,0l-.365.37a.145.145,0,0,0,0,.2l.03.029.134.133.271.27.329.329c.069.069.114.115.118.12a.369.369,0,0,1,.1.25.359.359,0,0,1-.359.359.342.342,0,0,1-.239-.1l-.909-.9a.118.118,0,0,0-.163,0l-2.6,2.6a.98.98,0,0,0-.288.675l-.033,1.29a.254.254,0,0,0,.071.185.259.259,0,0,0,.185.076H9.258a1.007,1.007,0,0,0,.7-.288l3.662-3.673a.864.864,0,0,0,0-1.213Z'
      transform='translate(-7.724 -7.051)'
      fill='#fff'
    />
  </svg>
)

const SVGEditInputIcon = (props) => <Icon component={SVGIcon} {...props} />

export default SVGEditInputIcon
