import React from 'react'
import Icon from '@ant-design/icons'
import { MainColor } from '../../utils'

const SVGIcon = (props) => (
  <svg
    width={props.width || '16'}
    height={props.height || '16'}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M7.99967 13.6668H4.66634C2.66634 13.6668 1.33301 12.6668 1.33301 10.3335V5.66683C1.33301 3.3335 2.66634 2.3335 4.66634 2.3335H11.333C13.333 2.3335 14.6663 3.3335 14.6663 5.66683V7.66683M11.333 6.00016L9.24634 7.66683C8.55967 8.2135 7.433 8.2135 6.74634 7.66683L4.66634 6.00016M12.9928 11.3202H11.0062M11.9995 10.3468V12.3402M14.6663 11.3335C14.6663 11.5468 14.6397 11.7535 14.5863 11.9535C14.5263 12.2202 14.4197 12.4802 14.2797 12.7068C13.8197 13.4802 12.973 14.0002 11.9997 14.0002C11.313 14.0002 10.693 13.7401 10.2263 13.3135C10.0263 13.1401 9.853 12.9335 9.71967 12.7068C9.473 12.3068 9.33301 11.8335 9.33301 11.3335C9.33301 10.6135 9.61968 9.95351 10.0863 9.47351C10.573 8.97351 11.253 8.66683 11.9997 8.66683C12.7863 8.66683 13.4997 9.00684 13.9797 9.55351C14.4063 10.0268 14.6663 10.6535 14.6663 11.3335Z'
      stroke={props.color || MainColor}
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

const SVGMessageSubscribeIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGMessageSubscribeIcon
