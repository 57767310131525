import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={props.width || '20'}
    height={props.height || '20'}
    viewBox='0 0 20 20'
  >
    <path
      id='Time_Circle'
      data-name='Time Circle'
      d='M14.094,4.578a10,10,0,1,1-10,10A10,10,0,0,1,14.094,4.578Zm-.35,4.93a.749.749,0,0,0-.75.75v5.05a.751.751,0,0,0,.37.64l3.92,2.34a.765.765,0,0,0,.39.11.742.742,0,0,0,.64-.37.753.753,0,0,0-.26-1.03l-3.56-2.12v-4.62A.749.749,0,0,0,13.744,9.508Z'
      transform='translate(-4.095 -4.578)'
      fill={props.color || '#A3A1B7'}
    />
  </svg>
)

const SVGTimeIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGTimeIcon
