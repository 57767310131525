import React, { useMemo, useState } from 'react'
import { Collapse, Drawer } from 'antd'
import { getText } from '../../../../../lang'
import SVGDateIcon from '../../../../../icons/SVG/SVGDateIcon'
import SVGTimeIcon from '../../../../../icons/SVG/SVGTimeIcon'
import MatButton from '../../../../../components/MatButton'
import SVGArrowLeft from '../../../../../icons/SVG/SVGArrowLeft'
import SVGCalendarTick from '../../../../../icons/SVG/SVGCalendarTick'
import SVGChatClose from '../../../../../icons/SVG/SVGChatClose'
import MatRow from '../../../../../components/MatRow'
import { formatDateAppoinment } from '../../../../../utils'
import utilDate from '../../../../../utils/utilsDate'
import { AUTO_REPLY, EMAIL, GENERATED, SMS } from '../../../utils/constants'
import ConfirmPopup from '../../../../../components/ConfirmPopup'
import './CenterMessageDripSequence.scss'

const CenterSkipScheduledMessage = ({
  screenSize,
  message,
  conversation,
  onSkip,
  disabled,
}) => {
  const [open, setOpen] = useState(false)

  const isMobileView = useMemo(() => {
    return screenSize === 'mobile'
  }, [screenSize])

  const getTitle = () => {
    if (message.type === AUTO_REPLY) {
      return getText('TITLE_DRIP_SEQUENCE')
    } else if (message.type === GENERATED) {
      return getText('WORD_AI_FOLLOW_UP')
    }
  }

  const getMessageTitle = () => {
    if (message.type === AUTO_REPLY || message.type === GENERATED) {
      if (message.send_via === SMS) {
        return getText('WORD_SMS_UC')
      }
      if (message.send_via === EMAIL) {
        return getText('WORD_BODY')
      }
    }
    return ''
  }

  const getMessage = () => {
    if (message.type === AUTO_REPLY) {
      return message.text[conversation.receiver.language]
    }
    if (message.type === GENERATED) {
      return message.message
    }
    return ''
  }

  const getEmailText = () => {
    if (message.type === AUTO_REPLY) {
      if (message.send_via === EMAIL) {
        return message.subject[conversation.receiver.language]
      }
    }
    return ''
  }

  const emailText = getEmailText()
  const messageText = getMessage()

  const items = useMemo(() => {
    return [
      {
        key: '1',
        label: (
          <div className='label-wrapper'>
            <div className='label-and-icon-wrapper'>
              {Boolean(isMobileView) && <SVGCalendarTick />}
              <span
                className={
                  isMobileView ? 'collapse-title-text-mobile' : 'collapse-title-text'
                }
              >
                {!Boolean(isMobileView)
                  ? getText('WORD_NEXT_SCHEDULED_MESSAGE')
                  : getTitle()}
              </span>
              {!Boolean(isMobileView) && (
                <div className='scheduled-message-type'>{getTitle()}</div>
              )}
              {Boolean(!isMobileView) && <SVGDateIcon width={16} height={16} />}
              <span
                style={{ marginRight: 8 }}
                className={isMobileView ? 'date-time-text-mobile' : 'date-time-text'}
              >
                {formatDateAppoinment(message.action_time)}
              </span>
              {Boolean(!isMobileView) && <SVGTimeIcon width={16} height={16} />}
              <span className='date-time-text'>
                {utilDate.getDayJSDate(message.action_time).format('hh:mm a')}
              </span>
            </div>
            {Boolean(!isMobileView) && (
              <ConfirmPopup
                title={getText('MSG_SKIP_SCHEDULED_MESSAGE')}
                okText={getText('WORD_YES')}
                cancelText={getText('WORD_NO')}
                placement={'topRight'}
                onCancel={() => {}}
                onConfirm={(e) => {
                  e.stopPropagation()
                  onSkip && onSkip()
                }}
                trigger={
                  <MatButton
                    disabled={disabled}
                    typeButton={'gmail'}
                    size='small'
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                    buttonText={getText('WORD_SKIP')}
                  />
                }
              />
            )}
          </div>
        ),
        children: (
          <div className='children-wrapper'>
            {Boolean(emailText) && (
              <div className='title-wrapper'>
                <span className='title'>{getText('WORD_SUBJECT')}:</span>
                <span className='value'>{emailText}</span>
              </div>
            )}
            <div className='title-wrapper'>
              <span
                className='title'
                style={{ minWidth: message.send_via === SMS ? 30 : '' }}
              >
                {getMessageTitle()}:
              </span>
              <span className='value'>{messageText}</span>
            </div>
            {Boolean(isMobileView) && (
              <div className='children-footer-mobile'>
                <SVGDateIcon width={16} height={16} />
                <span style={{ marginRight: 8 }} className='date-time-text'>
                  {formatDateAppoinment(message.action_time)}
                </span>
                <SVGTimeIcon width={16} height={16} />
                <span className='date-time-text'>
                  {utilDate.getDayJSDate(message.action_time).format('hh:mm a')}
                </span>
                <MatRow>
                  <MatButton
                    typeButton={'white'}
                    onClick={() => {
                      setOpen(false)
                    }}
                    buttonText={getText('ACTION_CANCEL')}
                  />
                  <ConfirmPopup
                    title={getText('MSG_SKIP_SCHEDULED_MESSAGE')}
                    placement={'topRight'}
                    onCancel={() => {}}
                    onConfirm={(e) => {
                      e.stopPropagation()
                      setOpen(false)
                      onSkip && onSkip()
                    }}
                    okText={getText('WORD_YES')}
                    cancelText={getText('WORD_NO')}
                    trigger={
                      <MatButton
                        disabled={disabled}
                        typeButton={'gmail'}
                        onClick={(e) => {
                          e.stopPropagation()
                        }}
                        buttonText={getText('WORD_SKIP')}
                      />
                    }
                  />
                </MatRow>
              </div>
            )}
          </div>
        ),
        onClick: () => {
          if (isMobileView) {
            setOpen(true)
          }
        },
      },
    ]
  }, [isMobileView])

  return (
    <div className='center-sms-drip-wrapper'>
      <Collapse
        className='center-sms-drip-collapse'
        expandIcon={(expanded) =>
          expanded.isActive ? (
            <div style={{ transform: 'rotate(90deg)' }}>
              <SVGArrowLeft />
            </div>
          ) : (
            <div style={{ transform: 'rotate(-90deg)' }}>
              <SVGArrowLeft />
            </div>
          )
        }
        expandIconPosition={'end'}
        bordered={false}
        items={items}
        collapsible={isMobileView ? 'disabled' : '-'}
      />
      <Drawer
        title={getText('TITLE_DRIP_SEQUENCE')}
        placement={'bottom'}
        push={false}
        height={'auto'}
        destroyOnClose={true}
        onClose={() => {
          setOpen(false)
        }}
        closeIcon={<SVGChatClose color={'#A3A6BE'} stroke={2} />}
        open={open}
        className='skip-scheduled-message-drawer'
      >
        <div className='message_center_panel '>
          <div className='center-sms-drip-wrapper'>
            <div className='center-sms-drip-collapse'>
              {items.map((item, index) => {
                return <React.Fragment key={index}>{item.children}</React.Fragment>
              })}
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  )
}

export default CenterSkipScheduledMessage
