import React, { useEffect, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Form, Tabs } from 'antd'
import locationActions from '../../../../../store/modules/locationActions'
import { getText, getTextServerError } from '../../../../../lang'
import { getLongName } from '../../../../../utils'
import SVGUploadImage from '../../../../../icons/SVG/SVGUploadImage'
import SVGSwipeIcon from '../../../../../icons/SVG/SVGSwipeIcon'
import { notifyError, notifySuccess } from '../../../../../utils/Notify'
import { APP_URL } from '../../../../../config'
import MatButton from '../../../../../components/MatButton'
import MatForm from '../../../../../components/Form/MatForm'
import MatRow from '../../../../../components/MatRow'
import UploadFiles from '../../../../../components/UploadFiles'
import SelectDelay from '../../../../../components/api/SelectDelay'
import MatBlockWithLabel from '../../../../../components/MatBlockWithLabel'
import InputTextarea from '../../../../../components/InputTextarea'
import MatadorConnectLayout from '../../matadorConnect/matadorConnectComponents/MatadorConnectLayout'
import LoadingSpinner from '../../../../../components/LoadingSpinner'
import MatadorConnectListTitle from '../../matadorConnect/matadorConnectComponents/MatadorConnectListTitle'
import SwtichFormSecondary from '../../../../../components/Form/SwitchForm/SwtichFormSecondary'
import InputFormTextSecondary from '../../../../../components/Form/InputFormText/InputFormTextSecondary'
import InputFormTextAreaSecondary from '../../../../../components/Form/InputFormTextArea/InputFormTextAreaSecondary'
import SVGWidgetPositionRight from '../../../../../icons/SVG/SVGWidgetPositionRight'
import SVGWidgetPositionLeft from '../../../../../icons/SVG/SVGWidgetPositionLeft'
import MatColorPicker from '../../../../../components/MatColorPicker'
import UrlConditionManager from '../../matadorConnect/settings/UrlConditionManager'
import LocationCouponDescription from './LocationCouponDescription'
import LocationCouponTitle from './LocationCouponTitle'
import LocationsCouponPopover from './LocationsCouponPopover'
import LocationCouponButton from './LocationCouponButton'
import CouponPreview from './couponPreview/CouponPreview'
import './MobileCoupon.scss'

const LocationsCoupon = (props) => {
  const { organization, locationObj: location } = props

  const [form] = Form.useForm()

  const [loading, setLoiading] = useState(true)
  const [couponData, setCouponData] = useState([])

  const [primaryBackgroundColor, setPrimaryBackgroundColor] = useState('')

  const [buttonEn, setButtonEn] = useState('')
  const [buttonFr, setButtonFr] = useState('')
  const [buttonSp, setButtonSp] = useState('')
  const [buttonColor, setButtonColor] = useState('')
  const [visibleButtonTextEdit, setVisibleButtonTextEdit] = useState('')

  const [buttonBackgroundColor, setButtonBackgroundColor] = useState('')
  const [visibleButtonEdit, setVisibleButtonEdit] = useState(false)

  const [uploadFile, setUploadFile] = useState({
    fileUploadPage: true,
    uploadImage: '',
  })
  const [selectedLanguage, setSelectedLanguage] = useState('')
  const currentLanguage = selectedLanguage
    ? organization.languages[selectedLanguage]
    : organization.defaultLanguage

  const [titleEn, setTitleEn] = useState('')
  const [titleFr, setTitleFr] = useState('')
  const [titleSp, setTitleSp] = useState('')
  const [titleColor, setTitleColor] = useState('')
  const [visibleTitleEdit, setVisibleTitleEdit] = useState(false)

  const [descriptionEn, setDescriptionEn] = useState('')
  const [descriptionFr, setDescriptionFr] = useState('')
  const [descriptionSp, setDescriptionSp] = useState('')
  const [descriptionColor, setDescriptionColor] = useState('')
  const [visibleDescriptionEdit, setVisibleDescriptionEdit] = useState(false)
  const [copied, setCopied] = useState(false)

  const [showOnDesktop, setShowOnDesktop] = useState()
  const [widgetPosition, setWidgetPosition] = useState()
  const [urls, setUrls] = useState([])

  useEffect(() => {
    props.setFooterButtons && props.setFooterButtons(['save', 'cancel'])
  }, [])

  useEffect(() => {
    const fill = async () => {
      let result = await locationActions.getCoupon(location._id)
      for (let index = 0; index < organization.languages.length; index++) {
        const element = organization.languages[index]
        if (element === organization.defaultLanguage) {
          setSelectedLanguage(index)
        }
      }

      if (result.success) {
        setCouponData(result.data)
        setLoiading(false)
      }
    }

    if (location && location._id) {
      fill()
    }
  }, [location, location._id])

  const checkIfFreshInstance = () => {
    return (
      !couponData ||
      !couponData.title ||
      (couponData.title &&
        couponData.title.length === 0 &&
        (!couponData.description ||
          (couponData.description && couponData.description.length === 0)))
    )
  }

  useEffect(() => {
    const isFreshInstance = checkIfFreshInstance()

    setButtonColor(couponData.buttonColor)
    setButtonBackgroundColor(couponData.backgroundColor)
    setPrimaryBackgroundColor(couponData.primaryBackgroundColor)
    setUploadFile({ uploadImage: couponData.logoImageUrl })
    setTitleColor(couponData.titleColor)
    setDescriptionColor(couponData.descriptionColor)

    if (isFreshInstance) {
      setUploadFile({ uploadImage: couponData.defaultImageUrl })
    }

    if (couponData && couponData.title) {
      couponData.title.forEach((item) => {
        if (item.language === 'en') {
          setTitleEn(item.text)
        }
        if (item.language === 'fr') {
          setTitleFr(item.text)
        }
        if (item.language === 'sp') {
          setTitleSp(item.text)
        }
        return item
      })
    } else if (isFreshInstance) {
      setTitleEn(getText('TEXT_PROMOTION_TITLE', 'en'))
      setTitleFr(getText('TEXT_PROMOTION_TITLE', 'fr'))
      setTitleSp(getText('TEXT_PROMOTION_TITLE', 'sp'))
    }

    if (couponData && couponData.description) {
      couponData.description.forEach((item) => {
        if (item.language === 'en') {
          setDescriptionEn(item.text)
        }
        if (item.language === 'fr') {
          setDescriptionFr(item.text)
        }
        if (item.language === 'sp') {
          setDescriptionSp(item.text)
        }
        return item
      })
    } else if (isFreshInstance) {
      setDescriptionEn(getText('TEXT_PROMOTION_TEXT', 'en'))
      setDescriptionFr(getText('TEXT_PROMOTION_TEXT', 'fr'))
      setDescriptionSp(getText('TEXT_PROMOTION_TEXT', 'sp'))
    }

    if (couponData && couponData.buttonTexts) {
      couponData.buttonTexts.forEach((item) => {
        if (item.language === 'en') {
          setButtonEn(item.text)
        }
        if (item.language === 'fr') {
          setButtonFr(item.text)
        }
        if (item.language === 'sp') {
          setButtonSp(item.text)
        }
        return item
      })
    } else if (isFreshInstance) {
      setButtonEn(getText('TEXT_PROMOTION_BUTTON', 'en'))
      setButtonFr(getText('TEXT_PROMOTION_BUTTON', 'fr'))
      setButtonSp(getText('TEXT_PROMOTION_BUTTON', 'sp'))
    }

    let list = couponData.smsTemplate

    // For Default Text from API
    // list = organization.languages.map((tmp) => {
    //   let find =
    //     couponData &&
    //     couponData.smsTemplate &&
    //     couponData.smsTemplate.find((txt) => {
    //       return txt.language === tmp
    //     })
    //   return find
    //     ? find
    //     : {
    //       language: tmp,
    //       text: undefined,
    //     }
    // })

    // For Manual Default Text
    if (isFreshInstance) {
      list = organization.languages.map((tmp) => {
        return {
          language: tmp,
          text: getText('TEXT_PROMOTION_TEMPLATE', tmp),
        }
      })
    } else {
      list = organization.languages.map((tmp) => {
        let find =
          couponData &&
          couponData.smsTemplate &&
          couponData.smsTemplate.find((txt) => {
            return txt.language === tmp
          })
        return find
          ? find
          : {
              language: tmp,
              text: undefined,
            }
      })
    }

    setShowOnDesktop(couponData.showOnDesktop)
    setWidgetPosition(couponData.widgetPosition)

    if (couponData && couponData.conditions) {
      setUrls(couponData.conditions.url)
    }

    const initialValues = {
      logoImageUrl: couponData.logoImageUrl,
      appearanceDelaySeconds: couponData.appearanceDelaySeconds,
      primaryBackgroundColor: couponData.primaryBackgroundColor,
      showOncePerDayOption: couponData.showOncePerDayOption,
      showOnMobile: couponData.showOnMobile,
      showOnDesktop: couponData.showOnDesktop,
      widgetPosition: couponData.widgetPosition,
      backgroundColor: couponData.backgroundColor,
      buttonColor: couponData.buttonColor,
      titleColor: couponData.titleColor,
      descriptionColor: couponData.descriptionColor,
      termsConditionsLink: couponData.termsConditionsLink,
      smsTemplate: list,
    }
    form.setFieldsValue(initialValues)
  }, [couponData])

  const onFinish = async (values) => {
    props.setLoading(true)
    delete values.numberValue
    delete values.text
    values.logoImageUrl = uploadFile.uploadImage
    values.buttonColor = buttonColor
    values.backgroundColor = buttonBackgroundColor
    values.titleColor = titleColor
    values.descriptionColor = descriptionColor
    values.primaryBackgroundColor = primaryBackgroundColor
    values.title = []
    if (organization.languages.includes('en')) {
      values.title.push({
        language: 'en',
        text: titleEn,
      })
    }
    if (organization.languages.includes('fr')) {
      values.title.push({
        language: 'fr',
        text: titleFr,
      })
    }
    if (organization.languages.includes('sp')) {
      values.title.push({
        language: 'sp',
        text: titleSp,
      })
    }
    values.description = []
    if (organization.languages.includes('en')) {
      values.description.push({
        language: 'en',
        text: descriptionEn,
      })
    }
    if (organization.languages.includes('fr')) {
      values.description.push({
        language: 'fr',
        text: descriptionFr,
      })
    }
    if (organization.languages.includes('sp')) {
      values.description.push({
        language: 'sp',
        text: descriptionSp,
      })
    }
    values.buttonTexts = []
    if (organization.languages.includes('en')) {
      values.buttonTexts.push({
        language: 'en',
        text: buttonEn,
      })
    }
    if (organization.languages.includes('fr')) {
      values.buttonTexts.push({
        language: 'fr',
        text: buttonFr,
      })
    }
    if (organization.languages.includes('sp')) {
      values.buttonTexts.push({
        language: 'sp',
        text: buttonSp,
      })
    }
    let smsTemplateItems = values.smsTemplate.filter((it) => {
      return it.text
    })
    let titleItems = values.title.filter((it) => {
      return it.text
    })
    let descriptionItems = values.description.filter((it) => {
      return it.text
    })
    let buttonItems = values.buttonTexts.filter((it) => {
      return it.text
    })
    if (smsTemplateItems) {
      values.smsTemplate = smsTemplateItems
    }
    if (titleItems) {
      values.title = titleItems
    }
    if (descriptionItems) {
      values.description = descriptionItems
    }
    if (buttonItems) {
      values.buttonTexts = buttonItems
    }
    values.widgetPosition = widgetPosition
    values.conditions = {
      url: urls,
    }

    let result = await locationActions.saveCoupon(values, location._id)
    if (result.success) {
      notifySuccess(getText('TEXT_YOUR_COUPON_SAVED_SUCCESSFULLY'))
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
    props.setLoading(false)
  }

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields.length > 0) {
      notifyError(errorInfo.errorFields[0].errors[0])
    }
  }

  const WidgetPreview = (language) => {
    return (
      <div
        className={`coupon-widget-wrapper`}
        style={{ backgroundColor: primaryBackgroundColor }}
      >
        <MatColorPicker
          trigger={
            <div
              className='color-trigger'
              style={{
                backgroundColor: primaryBackgroundColor,
                border: '1px solid black',
                height: 20,
                width: 20,
                right: 0,
                borderRadius: '50%',
              }}
            />
          }
          color={primaryBackgroundColor}
          className='picker-color'
          onChangeColor={(newColor) => {
            setPrimaryBackgroundColor(newColor)
          }}
        />
        <UploadFiles
          onUpload={(media) => {
            setUploadFile({
              fileUploadPage: true,
              uploadImage: media.url,
              uploadImageFile: media.file,
            })
          }}
          mediaCount={1}
          children={
            <>
              {uploadFile.uploadImage ? (
                <div className='file-name'>{uploadFile.uploadImage}</div>
              ) : (
                <div className='no-file'>
                  {getText('TEXT_DRAG_LOGO_HERE_TO_UPLOAD')}
                </div>
              )}
              {uploadFile.uploadImage ? (
                <div className='upload-image-preview'>
                  <img src={uploadFile.uploadImage} alt={'logo'} />
                </div>
              ) : (
                <SVGUploadImage />
              )}
            </>
          }
          acceptImage={true}
          accept={'.png, .jpg, .svg'}
          mediaTypes={'image/jpeg, image/jpg, image/png,'}
          beforeUploadProps
          errMsg={getText('TEXT_FILE_UPLOAD_ERROR')}
        />
        <LocationCouponTitle
          titleEn={titleEn}
          onChangeTitleEn={(e) => {
            setTitleEn(e.target.value)
          }}
          titleFr={titleFr}
          onChangeTitleFr={(e) => {
            setTitleFr(e.target.value)
          }}
          titleSp={titleSp}
          onChangeTitleSp={(e) => {
            setTitleSp(e.target.value)
          }}
          onChangeColor={(color) => {
            setTitleColor(color)
          }}
          titleColor={titleColor}
          visibleTitleEdit={visibleTitleEdit}
          setVisibleTitleEdit={setVisibleTitleEdit}
          lang={language}
          organization={organization}
        />
        <LocationCouponDescription
          descriptionEn={descriptionEn}
          onChangedescriptionEn={(e) => {
            setDescriptionEn(e.target.value)
          }}
          descriptionFr={descriptionFr}
          onChangedescriptionFr={(e) => {
            setDescriptionFr(e.target.value)
          }}
          descriptionSp={descriptionSp}
          onChangedescriptionSp={(e) => {
            setDescriptionSp(e.target.value)
          }}
          onChangeDescriptionColor={(color) => {
            setDescriptionColor(color)
          }}
          descriptionColor={descriptionColor}
          visibleDescriptionEdit={visibleDescriptionEdit}
          setVisibleDescriptionEdit={setVisibleDescriptionEdit}
          lang={language}
          organization={organization}
        />
        <div className='button-with-edit-icon'>
          <div
            className='swipe-to-start-button'
            style={{ backgroundColor: buttonBackgroundColor }}
          >
            <div className='icon-wrapper'>
              <SVGSwipeIcon color={buttonBackgroundColor} />
            </div>
            <div className='input-text-with-edit-icon'>
              <LocationCouponButton
                buttonEn={buttonEn}
                onChangeButtonEn={(e) => {
                  setButtonEn(e.target.value)
                }}
                buttonFr={buttonFr}
                onChangeButtonFr={(e) => {
                  setButtonFr(e.target.value)
                }}
                buttonSp={buttonSp}
                onChangeButtonSp={(e) => {
                  setButtonSp(e.target.value)
                }}
                onChangeButtonColor={(color) => {
                  setButtonColor(color)
                }}
                buttonColor={buttonColor}
                visibleButtonTextEdit={visibleButtonTextEdit}
                setVisibleButtonTextEdit={setVisibleButtonTextEdit}
                lang={language}
                organization={organization}
              />
            </div>
          </div>
          <LocationsCouponPopover
            onlyBackground
            color={couponData.backgroundColor}
            setColor={setButtonBackgroundColor}
            visibleEdit={visibleButtonEdit}
            setVisibleEdit={setVisibleButtonEdit}
          />
        </div>
      </div>
    )
  }

  const getCode = () => {
    let defaultCode = `<!-- Start of Matador Coupon Script --> 
    <script>
      (function(d,t) {
        var g=d.createElement(t),s=d.getElementsByTagName(t)[0];
        g.src=src="${APP_URL}/_coupon.js";
        g.async = true;
        s.parentNode.insertBefore(g,s);
        g.onload=function(){
            matadorCouponWidget("${location._id}");
        }
      })(document,"script");
    </script>
    <!-- End of Matador Coupon Script -->`

    return defaultCode
  }

  return !loading ? (
    <MatadorConnectLayout className={'location-coupon-wrapper'}>
      <MatadorConnectListTitle title={getText('TEXT_CREATE_NEW_PROMOTION')} />
      <div className='sub-secondary-menu'>
        <div className='left-content'>
          <div className='left-content-block'>
            <MatForm
              form={form}
              formName='formCreateCoupon'
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <MatRow flexStart>
                <SelectDelay
                  name='appearanceDelaySeconds'
                  label={getText('LABEL_POPUP_DELAY')}
                  location={location}
                  required={false}
                />
              </MatRow>
              <SwtichFormSecondary
                name='showOncePerDayOption'
                text={getText('TEXT_SHOW_ONCE_PER_DAY_TO_EACH_USER')}
              />
              <MatBlockWithLabel black label={getText('WORD_VISIBILTY')}>
                <SwtichFormSecondary
                  name='showOnMobile'
                  text={getText('LABEL_SHOW_WIDGET_MOBILE')}
                />
                <SwtichFormSecondary
                  name='showOnDesktop'
                  text={getText('LABEL_SHOW_WIDGET_DESKTOP')}
                  onChange={(value) => setShowOnDesktop(value)}
                />
              </MatBlockWithLabel>
              {showOnDesktop && (
                <div style={{ display: 'flex' }}>
                  <div
                    className={`widget-position ${
                      widgetPosition === 'right' ? ' active' : ''
                    } `}
                    style={{
                      cursor: 'pointer',
                      border:
                        widgetPosition === 'right'
                          ? '1px solid #5B66EA'
                          : '1px solid transparent',
                    }}
                    onClick={() => setWidgetPosition('right')}
                  >
                    <SVGWidgetPositionRight />
                  </div>
                  <div
                    style={{
                      cursor: 'pointer',
                      marginLeft: 15,
                      border:
                        widgetPosition === 'left'
                          ? '1px solid #5B66EA'
                          : '1px solid transparent',
                    }}
                    onClick={() => setWidgetPosition('left')}
                  >
                    <SVGWidgetPositionLeft />
                  </div>
                </div>
              )}
              <Tabs
                onChange={(item) => {
                  setSelectedLanguage(+item)
                }}
                defaultActiveKey={`${selectedLanguage}`}
                style={{ paddingTop: 20, overflow: 'visible' }}
                items={organization.languages.map((lng, index) => {
                  return {
                    label: getLongName(lng),
                    key: index,
                    children: (
                      <div style={{ marginTop: 30 }}>
                        {WidgetPreview(lng)}
                        <Form.List name={'smsTemplate'}>
                          {(languages) => {
                            return (
                              <div key={selectedLanguage}>
                                {languages.map((field, index) => {
                                  return index !== selectedLanguage ? null : (
                                    <div key={index}>
                                      <InputFormTextAreaSecondary
                                        name={[field.name, 'text']}
                                        fieldKey={[field.key, 'text']}
                                        placeholder={getText(
                                          'TEXT_TYPE_SMS_TEMPLATE'
                                        )}
                                        label={getText('TEXT_SMS_TEMPLATE')}
                                        autoSize={{
                                          minRows: 2,
                                          maxRows: 3,
                                        }}
                                      />
                                    </div>
                                  )
                                })}
                              </div>
                            )
                          }}
                        </Form.List>
                      </div>
                    ),
                  }
                })}
              />
              <MatRow>
                <InputFormTextSecondary
                  name='termsConditionsLink'
                  label={getText('WORD_TERMS_LINK')}
                  required
                />
              </MatRow>
              <MatRow>
                <MatBlockWithLabel
                  black
                  label={getText('WORD_WIDGET_CODE')}
                  className={'create-coupon-textarea-with-clipboard'}
                >
                  <div className='create-coupon-textarea-container'>
                    <InputTextarea
                      className='create-coupon-textarea-autosize'
                      value={getCode()}
                      disabled={true}
                      placeholder=''
                      autoSize={{ minRows: 4, maxRows: 20 }}
                    />
                    <CopyToClipboard text={getCode()} onCopy={() => setCopied(true)}>
                      <MatButton
                        htmlType='button'
                        className='copy-button'
                        size='small'
                        buttonText={
                          copied ? getText('WORD_COPIED') : getText('WORD_COPY')
                        }
                      />
                    </CopyToClipboard>
                  </div>
                </MatBlockWithLabel>
              </MatRow>
              <UrlConditionManager urls={urls} setUrls={setUrls} />
            </MatForm>
          </div>
        </div>
        <div className='right-content'>
          <div className='matador-connect-preview-default'>
            <CouponPreview
              uploadFile={uploadFile}
              titleLang={currentLanguage}
              titleEn={titleEn}
              titleFr={titleFr}
              titleSp={titleSp}
              titleColor={titleColor}
              descriptionLang={currentLanguage}
              descriptionEn={descriptionEn}
              descriptionFr={descriptionFr}
              descriptionSp={descriptionSp}
              descriptionColor={descriptionColor}
              buttonBackgroundColor={buttonBackgroundColor}
              buttonLang={currentLanguage}
              buttonEn={buttonEn}
              buttonFr={buttonFr}
              buttonSp={buttonSp}
              buttonColor={buttonColor}
              primaryBackgroundColor={primaryBackgroundColor}
            />
          </div>
        </div>
      </div>
    </MatadorConnectLayout>
  ) : (
    <LoadingSpinner />
  )
}

export default LocationsCoupon
