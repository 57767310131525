import React from 'react'
import PropTypes from 'prop-types'

function PromotionsItem({ value, title, color, BgComponent }) {
  return (
    <div className='promotions-item'>
      <div className='promotions-bg'>
        <BgComponent />
        <div className='promotions-value-wrap'>
          <div className='promotions-value' style={{ color }}>
            {value}
          </div>
          <div className='promotions-title' style={{ color }}>
            {title}
          </div>
        </div>
      </div>
    </div>
  )
}

PromotionsItem.propTypes = {
  color: PropTypes.string,
  title: PropTypes.object,
  value: PropTypes.number,
  BgComponent: PropTypes.func,
  extraTitle: PropTypes.string,
}

export default React.memo(PromotionsItem)
