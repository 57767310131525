import React from 'react'
import SVGEngageRate from '../../../../../icons/SVG/SVGEngageRate'
import { SVGBotAndRating } from '../../../../../icons/SVG/SVGBotAndRating'
import { SVGBotAndRatingYellow } from '../../../../../icons/SVG/SVGBotAndRatingYellow'
import { SVGBotAndRatingVertical } from '../../../../../icons/SVG/SVGBotAndRatingVertical'
import { getText } from '../../../../../lang'
import ProgressChartRate from './ProgressChartRate'

const ChartContent = ({ screenSize, totalLeads, messagesSent, aibotPercentage }) => {
  function getSize(size) {
    switch (size) {
      case 'tabletPortrait':
        return (
          <div className='bot-rating-center'>
            <div className='bot-rating-container'>
              <div className='chart-content'>
                <SVGBotAndRatingVertical />
              </div>
              <div className='total-loads'>
                <span>{getText('WORD_TOTAL_LEADS')}</span>
              </div>
              <div className='new-lead-count'>
                <span>{totalLeads}</span>
              </div>
              <div className='messages-sent'>
                <span>{getText('TEXT_MESSAGES_SENT')}</span>
              </div>
              <div className='message-sent-count'>
                <span>{messagesSent}</span>
              </div>
              <div className='engaged-clients'>
                <span>{getText('WORD_ENGAGED_CLIENTS')}</span>
              </div>
              <div className='avg-per-user-count'>
                {messagesSent !== 0
                  ? parseFloat(messagesSent / totalLeads).toFixed(2)
                  : 0}
              </div>
              <div className='avg-m-per-user'>
                <span>{getText('TEXT_AVG_MESSAGE_SENT_PER_USER')}</span>
              </div>
              <div className='engagement-ratio-count'>
                <span>{Math.floor(totalLeads * (aibotPercentage / 100))}</span>
              </div>
              <div className='engagement-ratio'>
                <span>{getText('WORD_ENGAGEMENT_RATIO')}</span>
              </div>
              <div className='bot-yellow-block'>
                <div className='avg-messages'>
                  <div className='rate-pie-chart'>
                    <div className='percent-val'>{`${aibotPercentage}%`}</div>
                    <ProgressChartRate
                      percent={aibotPercentage}
                      screenSize={screenSize}
                    />
                  </div>
                </div>
              </div>
              {aibotPercentage > 60 && (
                <div className='industry-average-engagement-wrapper'>
                  <div className='industry-average-engagement'>
                    <span>{getText('WORD_INDUSTRY_AVERAGE_ENGAGEMENT')}</span>
                    <span>52%</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        )
      case 'mobile':
        return (
          <div className='bot-rating-center'>
            <div className='bot-rating-container'>
              <div className='chart-content'>
                <SVGBotAndRatingVertical />
              </div>
              <div className='total-loads'>
                <span>{getText('WORD_TOTAL_LEADS')}</span>
              </div>
              <div className='new-lead-count'>
                <span>{totalLeads}</span>
              </div>
              <div className='messages-sent'>
                <span>{getText('TEXT_MESSAGES_SENT')}</span>
              </div>
              <div className='message-sent-count'>
                <span>{messagesSent}</span>
              </div>
              <div className='engaged-clients'>
                <span>{getText('WORD_ENGAGED_CLIENTS')}</span>
              </div>
              <div className='avg-per-user-count'>
                {messagesSent !== 0
                  ? parseFloat(messagesSent / totalLeads).toFixed(2)
                  : 0}
              </div>
              <div className='avg-m-per-user'>
                <span>{getText('TEXT_AVG_MESSAGE_SENT_PER_USER')}</span>
              </div>
              <div className='engagement-ratio-count'>
                <span>{Math.floor(totalLeads * (aibotPercentage / 100))}</span>
              </div>
              <div className='engagement-ratio'>
                <span>{getText('WORD_ENGAGEMENT_RATIO')}</span>
              </div>
              <div className='bot-yellow-block'>
                <div className='avg-messages'>
                  <div className='rate-pie-chart'>
                    <div className='percent-val'>{`${aibotPercentage}%`}</div>
                    <ProgressChartRate
                      percent={aibotPercentage}
                      screenSize={screenSize}
                    />
                  </div>
                </div>
              </div>
              {aibotPercentage > 60 && (
                <div className='industry-average-engagement-wrapper'>
                  <div className='industry-average-engagement'>
                    <span>{getText('WORD_INDUSTRY_AVERAGE_ENGAGEMENT')}</span>
                    <span>52%</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        )
      default:
        return (
          <div className='bot-rating-container'>
            <div className='chart-content'>
              <SVGBotAndRating />
            </div>
            <div className='new-lead-count'>
              <span>{totalLeads}</span>
            </div>
            <div className='total-loads'>
              <span>{getText('WORD_TOTAL_LEADS')}</span>
            </div>
            <div className='message-sent-count'>
              <span>{messagesSent}</span>
            </div>
            <div className='messages-sent'>
              <span>{getText('TEXT_MESSAGES_SENT')}</span>
            </div>
            <div className='engaged-clients'>
              <span>{getText('WORD_ENGAGED_CLIENTS')}</span>
            </div>
            <div className='avg-m-per-user'>
              <span>{getText('TEXT_AVG_MESSAGE_SENT_PER_USER')}</span>
            </div>
            <div className='avg-per-user-count'>
              {messagesSent !== 0
                ? parseFloat(messagesSent / totalLeads).toFixed(2)
                : 0}
            </div>
            <div className='engagement-ratio-count'>
              <span>{Math.floor(totalLeads * (aibotPercentage / 100))}</span>
            </div>
            <div className='engagement-ratio'>
              <span>{getText('WORD_ENGAGEMENT_RATIO')}</span>
            </div>
            <div className='bot-yellow-block'>
              <SVGBotAndRatingYellow />
              <div className='avg-messages'>
                <SVGEngageRate />
                <div className='rate-pie-chart'>
                  <div className='percent-val'>{`${aibotPercentage}%`}</div>
                  <ProgressChartRate
                    percent={aibotPercentage}
                    screenSize={screenSize}
                  />
                </div>
              </div>
            </div>
            {aibotPercentage > 60 && (
              <div className='industry-average-engagement-wrapper'>
                <div className='industry-average-engagement'>
                  <span>{getText('WORD_INDUSTRY_AVERAGE_ENGAGEMENT')}</span>
                  <span>52%</span>
                </div>
              </div>
            )}
          </div>
        )
    }
  }
  return getSize(screenSize)
}

export default ChartContent
