import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import authActions from '../../store/modules/authActions'
import { getText } from '../../lang'
import MatSelect from '../../components/MatSelect'
import LocationWasEmpty from '../../components/api/LocationWasEmpty'
import MatRangePicker from '../../components/MatRangePicker/MatRangePicker'
import UserDashboard from './tabs/UserDashboard/index'
import ChatPerformanceData from './tabs/ChatPerformanceData/index'
import TabHeader from './TabHeader'
import DashboardNew from './tabs/DashboardNew'
import './DashboardMain.scss'

const DashboardMain = (props) => {
  const navigate = useNavigate()
  const userPageRef = useRef(null)
  const user = authActions.getUserData()

  const [dashboardTab, setDashboardTab] = useState(() => {
    if (props.urlSearchParams) {
      const directUrlType = props.urlSearchParams.type
      if (directUrlType === 'chat_dashboard' || directUrlType === 'user_dashboard') {
        return directUrlType
      }
    }
    return 'main_dashboard'
  })

  const [selectedDataType, setSelectedDataType] = useState('org') // or 'loc'
  const [selectedRange, setSelectedRange] = useState('all_time') // or 'yesterday', or 'today'...
  const [selectCustomRange, setSelectCustomRange] = useState('')

  const RenderSelectOrgLoc = () => {
    const list_org_loc = [
      {
        label: getText('WORD_ORGANIZATION'),
        value: 'org',
      },
      {
        label: getText('WORD_LOCATION'),
        value: 'loc',
      },
    ]
    let sel_org_loc = list_org_loc.find((obj) => obj.value === selectedDataType)

    return (
      (user.isSuperAdmin || user.isAdmin) && (
        <div className='dashboard-filter-select'>
          <MatSelect
            secondary
            value={sel_org_loc.label}
            options={list_org_loc}
            onSelect={(value) => {
              userPageRef &&
                userPageRef.current &&
                userPageRef.current.paginationChange()
              setSelectedDataType(value)
            }}
          />
        </div>
      )
    )
  }

  const RenderSelectRange = () => {
    let list_periods = [
      {
        label: getText('WORD_ALL_TIME'),
        value: 'all_time',
      },
      {
        label: getText('WORD_YESTERDAY'),
        value: 'ytd',
      },
      {
        label: getText('WORD_TODAY'),
        value: 'today',
      },
      {
        label: getText('WORD_MONTH_TO_DAY'),
        value: 'mtd',
      },
      {
        label: getText('WORD_LAST_MONTH'),
        value: 'last_month',
      },
      {
        label: getText('WORD_CUSTOM'),
        value: 'custom',
      },
    ]
    const selectedRangeLabel = list_periods.find(
      (obj) => obj.value === selectedRange
    )

    return (
      <div className='dashboard-filter-select'>
        <MatSelect
          secondary
          value={selectedRangeLabel.label}
          options={list_periods}
          onSelect={(value) => {
            if (value !== 'custom') {
              setSelectCustomRange('')
            }
            userPageRef &&
              userPageRef.current &&
              userPageRef.current.paginationChange()
            setSelectedRange(value)
          }}
        />
      </div>
    )
  }

  useEffect(() => {
    props.setPageTitle(getText('TITLE_DASHBOARD'))
  }, [])

  useEffect(() => {
    const hubspotElement = document.getElementById(
      'hubspot-messages-iframe-container'
    )

    return () => {
      if (hubspotElement) {
        hubspotElement.style.visibility = 'hidden'
      }
    }
  }, [])

  useEffect(() => {
    navigate(`?type=${dashboardTab}`)
  }, [dashboardTab])

  const renderSelectedDashboardType = () => {
    if (
      !authActions.getLocation() ||
      (authActions.getLocation() && authActions.getLocation()._id === ' ')
    ) {
      return <LocationWasEmpty />
    }
    switch (dashboardTab) {
      case 'user_dashboard':
        return (
          <UserDashboard ref={userPageRef} selectCustomRange={selectCustomRange} />
        )
      case 'chat_dashboard':
        return <ChatPerformanceData />
      default:
        return <DashboardNew />
    }
  }

  const changeTab = (tab) => {
    setDashboardTab(tab)
    resetValues()
  }

  const resetValues = () => {
    setSelectedRange('all_time')
    setSelectCustomRange('')
  }

  return (
    <div
      className={`dashboard-page-wrapper ${
        props.menuOpen ? '' : 'side-menu-closedV2'
      }`}
    >
      <div className='dashboard-tabs-wrap'>
        <div className='dashboard-tabs-title'>
          <div
            onClick={() => changeTab('main_dashboard')}
            className={`tab-title ${
              dashboardTab === 'main_dashboard' ? 'active' : ''
            }`}
          >
            {getText('TITLE_DASHBOARD')}
          </div>
          {user && !user.isSupportAgent && (
            <>
              <div
                onClick={() => {
                  if (selectedRange === 'custom') {
                    setSelectedRange('all_time')
                    setSelectCustomRange('')
                  }
                  changeTab('user_dashboard')
                }}
                className={`tab-title ${
                  dashboardTab === 'user_dashboard' ? 'active' : ''
                }`}
              >
                {getText('WORD_USERS')}
              </div>
              {/* <div
                onClick={() => setDashboardTab('chat_dashboard')}
                className={`tab-title ${
                  dashboardTab === 'chat_dashboard' ? 'active' : ''
                }`}
              >
                {getText('TITLE_MATADOR_CONNECT')}
              </div> */}
            </>
          )}
        </div>
        <div className='dashboard-header-buttons-wrapper'>
          <div className='dashboard-header-buttons'>
            <RenderSelectOrgLoc />
            <RenderSelectRange />
          </div>
          {selectedRange === 'custom' && (
            <MatRangePicker
              label={''}
              onChange={(date) => {
                let obj = {}
                date &&
                  date.map((item, index) => {
                    return (obj[index === 0 ? 'start_date' : 'end_date'] =
                      item.format(
                        index === 0
                          ? 'YYYY-MM-DDT00:00:00.000[Z]'
                          : 'YYYY-MM-DDT23:59:59.999[Z]'
                      ))
                  })
                if (obj.start_date && obj.end_date) {
                  setSelectCustomRange(obj)
                }
              }}
              disabledDate={(current) => {
                return current && current > moment().subtract(1, 'day').endOf('day')
              }}
            />
          )}
        </div>
      </div>

      <div className='header-line' />
      <TabHeader
        selectedDataType={selectedDataType}
        selectedRange={selectedRange}
        title={dashboardTab}
        urlSearchParams={props.urlSearchParams}
        history={props.history}
        _location_id={authActions.getLocation() && authActions.getLocation()._id}
        screenSize={props.screenSize}
        selectCustomRange={selectCustomRange}
      >
        {renderSelectedDashboardType()}
      </TabHeader>
    </div>
  )
}

export default DashboardMain
