export const CALL = 'call'
export const CONSENT_EDIT = 'consent_edit'
export const REMINDER_BOT = 'reminder_bot'
export const OUTGOING_CALL = 'outgoing_call'
export const OUTGOING_CALL_LINK = 'outgoing_call_link'
export const PAYMENT = 'payment'
export const ASSIGN = 'assign'
export const ARCHIVE_STATUS = 'archiveStatus'
export const MATADOR_CONNECT = 'matador_connect'
export const NOTE = 'note'
export const MY_MESSAGES = 'my-messages'
export const ALL = 'all'
export const ARCHIVED = 'archived'
export const UNASSIGNED = 'unassigned'
export const MAIN_INBOX = 'main inbox'
export const BROADCAST = 'broadcast'
export const HOUR = 'hour'
export const RECOMMENDATIONS = 'recommendations'
export const REMINDER = 'reminder'
export const MARK_AS_READ = 'mark_as_read'
export const UNARCHIVED = 'unarchived'
export const CONVERSATION_CREATED = 'conversation_created'

export const FACEBOOK = 'facebook'
export const INSTAGRAM = 'instagram'
export const ORGANIC = 'organic'
export const CALLS = 'calls'
export const EMAIL = 'email'
export const EXPORT = 'export'
export const REJECTED = 'REJECTED'
export const ACCEPTED = 'ACCEPTED'
export const BUBBLE = 'bubble'
export const TAG_USER = 'tag_user'
export const BOT_CANCELLED = 'bot_cancelled'
export const RECEIVED = 'received'
export const SENT = 'sent'
export const BOT = 'bot'
export const AI_REPLY = 'ai_reply'
export const AI_BOT = 'AI Bot'
export const SEQUENCE = 'Sequence'

export const SUBSCRIBE = 'subscribe_to_messages'
export const UNSUBSCRIBE = 'unsubscribe_from_messages'
export const VOICE_OUTGOING_CALL_FINISHED = 'voice_outgoing_call_finished'
export const VOICE_OUTGOING_CALL_INITIATED = 'voice_outgoing_call_initiated'
export const SMS = 'sms'
export const SKIP_SCHEDULED_MESSAGE = 'skip_scheduled_message'
export const AUTO_REPLY = 'auto_reply'
export const PHONE = 'phone'
export const MESSENGER = 'messenger'
export const GOOGLE_BUSSINESS = 'gbm'

export const REACTIVATE = 'reactivate'
export const SNOOZE = 'snooze'

export const DELETED = 'deleted'
export const UPDATED = 'updated'
export const CONSENT = 'consent'
export const GENERATED = 'GENERATED'
export const FOLLOW_UP = 'follow_up'
