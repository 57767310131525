import React, { useEffect, useState } from 'react'
import { getText } from '../../../../lang'
import InputFormNumber from '../../../../components/Form/InputFormNumber'
import SelectChecked from '../../../../components/Form/SelectChecked'

const CalendarInviteDelay = (props) => {
  const { selectedMessage, field, form, selectedIndex } = props

  const [isImmediately, setIsImmediately] = useState()

  useEffect(() => {
    if (selectedMessage.isFirstItem && selectedMessage.delay) {
      if (selectedMessage.delay.unit === 'immediately') {
        setIsImmediately(true)
      }
    } else {
      setIsImmediately(false)
    }
  }, [selectedMessage])

  const handleDelayChange = (newValue, property) => {
    const autoMessagesList = form.getFieldValue('calendar_invite_sequence')
    const newTimeUnit = autoMessagesList[selectedIndex].delay
    newTimeUnit[property] = newValue

    autoMessagesList[selectedIndex].delay = newTimeUnit
    form.setFieldsValue({ calendar_invite_sequence: autoMessagesList })
  }

  const timeUnitsOptions = [
    {
      label: getText('WORD_DAYS').toLowerCase(),
      value: 'days',
    },
    {
      label: getText('WORD_HOURS'),
      value: 'hours',
    },
    {
      label: getText('WORD_MINUTES'),
      value: 'minutes',
    },
    ...(selectedMessage.isFirstItem
      ? [
          {
            label: getText('WORD_IMMEDIATELY'),
            value: 'immediately',
          },
        ]
      : []),
  ]

  return (
    <div className='aibot-auto-message-descriptions'>
      <div className='ai-bot-message-description-first-part'>
        <span className='aibot-auto-message-descriptions-text'>
          {selectedMessage.isFirstItem ? (
            <>
              <strong className='bold-text'>
                {getText('WORD_FIRST_AUTO_MESSAGE')}
              </strong>{' '}
              {getText('TEXT_SHOULD_BE_SENT')}
            </>
          ) : (
            getText('TEXT_SEND_FOLLOWUP')
          )}
        </span>
        <InputFormNumber
          className='autoreply-follow-up-value'
          initialValue={{
            value: selectedMessage.delay && selectedMessage.delay.value,
          }}
          onChange={(value) => handleDelayChange(value, 'value')}
          style={isImmediately ? { display: 'none' } : null}
          name={[field, 'delay', 'value']}
          required
          min={!isImmediately && 1}
          errorMessage={getText('ERR_PLEASE_FILL_TIME_UNIT')}
        />
        <SelectChecked
          onChange={(value) => {
            handleDelayChange(value, 'unit')
            value === 'immediately'
              ? setIsImmediately(true)
              : setIsImmediately(false)
          }}
          className='autoreply-follow-up-unit'
          initialValue={{
            unit: isImmediately
              ? 'immediately'
              : selectedMessage.delay && selectedMessage.delay.unit,
          }}
          name={[field, 'delay', 'unit']}
          placeholder=''
          label=''
          dropdownStyle={{ minWidth: '97px' }}
          showSearch={false}
          multipleNewStyle
          option={timeUnitsOptions}
          allowClear={false}
          required
          dropdownClassName={'aibot-auto-message-follow-up-dropdown'}
          disabled={selectedMessage.isFirstItem ? true : false}
        />
        <span className='aibot-auto-message-descriptions-text'>
          {selectedMessage.isFirstItem
            ? getText('TEXT_WITH_CALENDAR_INVITE')
            : getText('WORD_BEFORE_APPOINTMENT')}
        </span>
      </div>
      {props.children}
    </div>
  )
}

export default CalendarInviteDelay
