import * as React from 'react'

function SVGPaid(props) {
  return (
    <svg
      data-name='Icon ionic-ios-checkmark-circle-outline'
      width={31}
      height={31}
      viewBox='0 0 31 31'
      {...props}
    >
      <path
        data-name='Path 5957'
        d='M23.861 11.063L22.48 9.79a.314.314 0 00-.22-.084.3.3 0 00-.22.084l-9.57 8.641-3.481-3.121a.328.328 0 00-.439 0l-1.4 1.252a.26.26 0 000 .4l4.396 3.938a1.459 1.459 0 00.918.4 1.524 1.524 0 00.91-.387h.008l10.487-9.45a.278.278 0 00-.008-.4z'
        fill={props.color || '#fff'}
      />
      <path
        data-name='Path 5958'
        d='M15.5 2.087a13.408 13.408 0 11-9.486 3.927A13.325 13.325 0 0115.5 2.087M15.5 0A15.5 15.5 0 1031 15.5 15.5 15.5 0 0015.5 0z'
        fill={props.color || '#fff'}
      />
    </svg>
  )
}

export default SVGPaid
