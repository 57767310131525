import React from 'react'
import SVGDelete from '../../../../icons/SVG/SVGDelete'
import SVGEdit from '../../../../icons/SVG/SVGEdit'
import { getText } from '../../../../lang'
import ConfirmPopup from '../../../../components/ConfirmPopup'
import MatRow from '../../../../components/MatRow'

const TemplateListItem = (props) => {
  const { item, onDelete, onEdit } = props

  return (
    <MatRow
      style={{ justifyContent: 'space-between' }}
      className='ai-bot-list-item-wrapper'
    >
      <div className='ai-bot-list-item'>
        <p>{getText('WORD_NAME_OF_TEMPLATE')}</p>
        <div className='title'>{item.name}</div>
      </div>
      <MatRow style={{ width: 'auto' }} className='edit-with-delete-btn'>
        <div
          onClick={() => {
            onEdit && onEdit(item)
          }}
          className='btn-block-item'
        >
          <SVGEdit color={'#747b95'} width={13} height={13} />
        </div>
        {item.type !== 'night' && (
          <ConfirmPopup
            title={getText('TEXT_ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_TEMPLATE')}
            placement={'topRight'}
            onConfirm={onDelete}
            trigger={
              <div className='btn-block-item'>
                <SVGDelete />
              </div>
            }
            okText={getText('WORD_YES')}
            cancelText={getText('WORD_NO')}
          />
        )}
      </MatRow>
    </MatRow>
  )
}

export default TemplateListItem
