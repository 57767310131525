import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='21'
      height='22'
      viewBox='0 0 21 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.0509 16.5455H6.07304C5.61558 16.5455 5.24431 16.1743 5.24431 15.7168C5.24431 15.2593 5.61558 14.8881 6.07304 14.8881H14.0509C14.5084 14.8881 14.8797 15.2593 14.8797 15.7168C14.8797 16.1743 14.5084 16.5455 14.0509 16.5455Z'
        fill={props.color || 'black'}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.0509 11.9196H6.07304C5.61558 11.9196 5.24431 11.5483 5.24431 11.0908C5.24431 10.6334 5.61558 10.2621 6.07304 10.2621H14.0509C14.5084 10.2621 14.8797 10.6334 14.8797 11.0908C14.8797 11.5483 14.5084 11.9196 14.0509 11.9196Z'
        fill={props.color || 'black'}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.11691 7.30431H6.07271C5.61525 7.30431 5.24398 6.93304 5.24398 6.47558C5.24398 6.01812 5.61525 5.64685 6.07271 5.64685H9.11691C9.57436 5.64685 9.94564 6.01812 9.94564 6.47558C9.94564 6.93304 9.57436 7.30431 9.11691 7.30431Z'
        fill={props.color || 'black'}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.2641 1.65746L5.76796 1.66188C3.19558 1.67735 1.65746 3.26851 1.65746 5.91934V16.0807C1.65746 18.7492 3.20994 20.3425 5.80773 20.3425L14.3039 20.3392C16.8762 20.3238 18.4144 18.7304 18.4144 16.0807V5.91934C18.4144 3.25083 16.863 1.65746 14.2641 1.65746ZM5.80884 22C2.33481 22 0 19.621 0 16.0807V5.91934C0 2.34696 2.26188 0.0254144 5.76243 0.00441989L14.2641 0C17.7381 0 20.0718 2.37901 20.0718 5.91934V16.0807C20.0718 19.6519 17.8099 21.9746 14.3094 21.9967L5.80884 22Z'
        fill={props.color || 'black'}
      />
    </svg>
  )
}

const SVGOrgTemplateIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGOrgTemplateIcon
