import React, { useCallback, useEffect, useState } from 'react'
import { debounce } from 'lodash'
import organizationActions from '../../store/modules/organizationActions'
import SelectChecked from '../Form/SelectChecked'
import SelectCheckedSecondary from '../Form/SelectChecked/SelectCheckedSecondary'

const PER_PAGE = 50

const SelectFromOrganizations = (props) => {
  const [searchValue, setSearchValue] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [organizations, setOrganizations] = useState([])
  const [totalOrg, setTotalOrg] = useState(0)

  // TODO fetch ALL organizations
  const getOrganizationsList = async () => {
    setIsLoading(true)
    let result = await organizationActions.getPageList(page, PER_PAGE, searchValue, {
      enabled: true,
    })
    if (result.success) {
      const organizationsList = result.data.map((org) => ({
        value: org._id,
        label: org.name,
        ...org,
      }))

      const newListOrganizations =
        page > 0 ? [...organizations, ...organizationsList] : organizationsList

      setOrganizations(newListOrganizations)
      setTotalOrg(result.max)
      props.onFetchedOrganizations &&
        props.onFetchedOrganizations(newListOrganizations)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    getOrganizationsList()
  }, [searchValue, page])

  const debouncedChange = useCallback(
    debounce((options) => {
      setPage(0)
      setSearchValue(options)
    }, 500),
    []
  )

  return props.secondarySelect ? (
    <SelectCheckedSecondary
      name={props.name}
      label={props.label}
      placeholder={props.placeholder}
      primary={props.primary}
      dropdownClassName={props.dropdownClassName}
      dropdownStyle={props.dropdownStyle}
      multipleNewStyle={props.multipleNewStyle}
      mode={props.mode}
      showArrow={props.showArrow}
      showSearch={true}
      option={organizations || []}
      loading={isLoading}
      allowClear={props.allowClear}
      onSearch={debouncedChange}
      maxTagCount={2}
      onLoadMore={() => {
        if (organizations.length < totalOrg && !isLoading) {
          setPage(page + 1)
        }
      }}
      onSelectItem={(organization) => {
        props.onSelectItem && props.onSelectItem(organization)
        setSearchValue('')
      }}
      onChange={props.onChange}
      onChangeItem={props.onChangeItem}
      onSelect={props.onSelect}
      onDeselect={props.onDeselect}
      required={props.required}
      labelInValue={props.labelInValue}
      message={props.message}
    />
  ) : (
    <SelectChecked
      name={props.name}
      label={props.label}
      placeholder={props.placeholder}
      primary={props.primary}
      dropdownClassName={props.dropdownClassName}
      dropdownStyle={props.dropdownStyle}
      multipleNewStyle={props.multipleNewStyle}
      mode={props.mode}
      showArrow={props.showArrow}
      showSearch={true}
      option={organizations || []}
      loading={isLoading}
      allowClear={props.allowClear}
      onSearch={debouncedChange}
      maxTagCount={2}
      onLoadMore={() => {
        if (organizations.length < totalOrg && !isLoading) {
          setPage(page + 1)
        }
      }}
      onSelectItem={(organization) => {
        props.onSelectItem && props.onSelectItem(organization)
        setSearchValue('')
      }}
      onChange={props.onChange}
      onChangeItem={props.onChangeItem}
      onSelect={props.onSelect}
      onDeselect={props.onDeselect}
      required={props.required}
      labelInValue={props.labelInValue}
      message={props.message}
    />
  )
}

export default SelectFromOrganizations
