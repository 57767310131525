import React, { memo } from 'react'
import MessageTabRightSelectLanguage from './MessageTabRightSelectLanguage'
import MessageTabRightSelectTags from './MessageTabRightSelectTags'
import MessageRightSelectLocations from './MessageRightSelectLocations'
import MessageTabRightPostPurchase from './MessageTabRightPostPurchase'
import MessageTabRightVehicleInterest from './MessageTabRightVehicleInterest'
import './MessageTabInfo.scss'

const MessageTabInfo = (props) => {
  const { conversation, onSaveReciver, refreshConversation, onSaveTags } = props

  return (
    <div className='message-tab-pane-wrapper'>
      <MessageRightSelectLocations
        conversation={conversation}
        onSelectItem={refreshConversation}
      />
      <MessageTabRightPostPurchase conversation={conversation} />
      <MessageTabRightVehicleInterest />
      <MessageTabRightSelectTags
        conversation={conversation}
        onSaveTags={onSaveTags}
      />
      {Boolean(conversation.receiver) && (
        <MessageTabRightSelectLanguage
          conversation={conversation}
          onSaveReciver={onSaveReciver}
        />
      )}
    </div>
  )
}

export default memo(MessageTabInfo)
