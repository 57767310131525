import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='30'
      height='30'
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle opacity='0.1' cx='15' cy='15' r='15' fill={props.color || '#5BC78B'} />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15 6C10.0345 6 6 10.0349 6 15C6 19.9651 10.0345 24 15 24C19.9655 24 24 19.9648 24 15C24 10.0352 19.9655 6 15 6ZM12.1035 11.7477C12.8579 11.7477 13.4806 12.3682 13.4806 13.1247C13.4806 13.8812 12.8589 14.5018 12.1035 14.5018C11.3469 14.5018 10.7199 13.8812 10.7199 13.1247C10.7199 12.3682 11.3479 11.7477 12.1035 11.7477ZM17.8966 11.7477C18.6523 11.7477 19.2737 12.3682 19.2737 13.1247C19.2737 13.8812 18.6531 14.5018 17.8966 14.5018C17.14 14.5018 16.5195 13.8812 16.5195 13.1247C16.5195 12.3682 17.1411 11.7477 17.8966 11.7477ZM19.1186 17.308C19.277 17.2998 19.4261 17.3832 19.502 17.5226C19.5779 17.6619 19.567 17.8325 19.4742 17.961C18.4823 19.4941 16.851 20.5149 15 20.5149C13.1471 20.5149 11.5112 19.4939 10.5196 17.961C10.4385 17.8404 10.4271 17.6858 10.4895 17.5545C10.5518 17.4233 10.6787 17.3344 10.8234 17.321C10.8386 17.3201 10.8536 17.3201 10.8687 17.321C11.0084 17.3233 11.1375 17.3963 11.2113 17.5149C12.0705 18.8427 13.448 19.6874 15.0001 19.6874C16.55 19.6874 17.9235 18.8424 18.7827 17.5149C18.8522 17.3933 18.9789 17.3154 19.1189 17.308L19.1186 17.308Z'
        fill={props.color || '#5BC78B'}
      />
    </svg>
  )
}

const SVGNPSScoreGoodIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGNPSScoreGoodIcon
