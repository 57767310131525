import React from "react";
import Icon from "@ant-design/icons";

const SVGIcon = (props) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        opacity="0.15"
        cx="12.5"
        cy="12"
        r="12"
        fill={props.color || "#EE7B6C"}
      />
      <path
        d="M12.5008 4.80078C8.52404 4.80078 5.30078 8.02411 5.30078 12.0008C5.30078 15.9774 8.52411 19.2008 12.5008 19.2008C16.4775 19.2008 19.7008 15.9774 19.7008 12.0008C19.7008 8.02411 16.4775 4.80078 12.5008 4.80078ZM14.7193 9.21706C15.2007 9.21706 15.5984 9.61477 15.5984 10.0961C15.5984 10.5775 15.2007 10.9752 14.7193 10.9752C14.238 10.9752 13.8403 10.5775 13.8403 10.0961C13.8403 9.61477 14.238 9.21706 14.7193 9.21706ZM10.4914 9.21706C10.9728 9.21706 11.3705 9.61477 11.3705 10.0961C11.3705 10.5775 10.9728 10.9752 10.4914 10.9752C10.0101 10.9752 9.61237 10.5775 9.61237 10.0961C9.61237 9.61477 10.0101 9.21706 10.4914 9.21706ZM15.5356 15.1403C15.389 15.224 15.2007 15.1822 15.096 15.0356C14.5309 14.1357 13.5471 13.6125 12.5006 13.6125C11.4332 13.6125 10.4495 14.1566 9.88435 15.0775C9.82157 15.1612 9.71689 15.2241 9.61222 15.2241C9.54944 15.2241 9.50753 15.2032 9.44476 15.1822C9.29817 15.0986 9.25642 14.8892 9.34007 14.7426C10.0308 13.6333 11.2029 12.9845 12.5005 12.9845C13.7772 12.9845 14.9493 13.6333 15.64 14.7009C15.724 14.8473 15.6822 15.0356 15.5356 15.1403Z"
        fill={props.color || "#EE7B6C"}
      />
    </svg>
  );
};

const SVGIntentNegativeIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />;
      }}
      {...props}
    />
  );
};

export default SVGIntentNegativeIcon;
