import React, { useEffect } from 'react'
import { getKValueFormat } from '../../utils'
import './ProgressBar.scss'

const ProgresBar = (props) => {
  useEffect(() => {
    maxNumberDigitCount()
  }, [])

  useEffect(() => {
    if (props.max) {
      maxNumberDigitCount()
    }
  }, [props.max])

  const maxNumberDigitCount = () => {
    if (props.max < 11) {
      return
    }
  }

  let { height, max, items, barColor, barConversationColor } = props
  const steps = []
  for (let i = 0; i < max; i += max / 4) {
    if (max > 4) {
      steps.push(Math.round(i))
    } else {
      steps.push(i)
    }
  }
  steps.push(max)

  if (!height) {
    height = (steps.length - 1) * 50
  }

  const stepHeight = height / (steps.length - 1) - 20
  const barHeight = height - 1

  barConversationColor = barConversationColor ? barConversationColor : '#eee'
  barColor = barColor ? barColor : '#222'

  return (
    <div className='progres-bar'>
      {steps.reverse().map((step, index) => {
        return (
          <div
            key={index}
            className='step'
            style={{ marginTop: index > 0 ? stepHeight : 0 }}
          >
            <label className='step-data'>{getKValueFormat(step)}</label>
            <div className='line' />
          </div>
        )
      })}

      <div className='bars' style={{ height: height }}>
        {items.map((obj, index) => {
          return (
            <div
              key={index}
              className='bar'
              style={{
                backgroundColor: barConversationColor,
                height: barHeight,
              }}
            >
              {
                <div
                  className='bar-value'
                  style={{
                    maxHeight: height,
                    backgroundColor: barColor,
                    height: (obj.Conversation_count / props.max) * 100,
                    borderRadius: `${15}`,
                    zIndex: 1,
                  }}
                />
              }
            </div>
          )
        })}
      </div>
      <div className='labels'>
        {items.map((obj, index) => (
          <label className='horizontal-label' key={index}>
            {index % 2 === 0 ? obj.label : ''}
          </label>
        ))}
      </div>
    </div>
  )
}

export default ProgresBar
