import React from 'react'
import { Space } from 'antd'

const BroadcastListMobileItemOptedOut = ({ title, infoItem, download }) => {
  return (
    <Space direction='vertical' className='broadcast-space-border-bottom'>
      <span className='info-item-mobile-title'>{title}</span>
      <div className='info-item-opted-out'>
        <h4>{infoItem}</h4>
        {download}
      </div>
    </Space>
  )
}

export default BroadcastListMobileItemOptedOut
