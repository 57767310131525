import React from 'react'

function SVGCalls(props) {
  const { activeIcon, ...rest } = props

  const SVGFile = (
    <svg width={30} height={30} viewBox='0 0 30 30' {...rest}>
      <g data-name='Group 7422'>
        <g data-name='Group 7281'>
          <circle
            data-name='Ellipse 603'
            cx={15}
            cy={15}
            r={15}
            fill={rest.color ? rest.color : '#46bfbe'}
          />
        </g>
        <g data-name='Group 1568'>
          <g data-name='Group 4'>
            <path
              data-name='Path 14'
              d='M11.618 14.642c-.454-.834.416-1.39.757-1.708.53-.516.757-1.311 0-2.5-.833-1.311-1.438-1.668-2.649-.318-3.1 4.528 4.5 13.108 9.045 10.209 1.362-1.192 1.06-1.867-.114-2.82-1.06-.874-1.817-.715-2.346-.159-.341.318-.946 1.192-1.7.675a10.885 10.885 0 01-2.993-3.379zm8.591.556a.38.38 0 11-.757-.04 5.123 5.123 0 00-4.806-5.363c-.492-.04-.454-.834.038-.794a5.882 5.882 0 015.523 6.197zm-3.863-.162a.38.38 0 11-.757.04 1.145 1.145 0 00-1.135-1.271c-.492-.04-.416-.834.076-.794a1.9 1.9 0 011.816 2.025zm1.93.04a.379.379 0 11-.757.04 3.076 3.076 0 00-2.952-3.337c-.492-.04-.416-.834.076-.794a3.853 3.853 0 013.633 4.094z'
              fill={rest.color ? rest.color : '#fff'}
              fillRule='evenodd'
            />
          </g>
        </g>
      </g>
    </svg>
  )

  if (!activeIcon) {
    return SVGFile
  }
  return (
    <div className={'mat-user-profile-letters'}>
      {activeIcon && <div className='active-icon' />}
      {SVGFile}
    </div>
  )
}

export default SVGCalls
