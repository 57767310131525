import React from "react";
import Icon from "@ant-design/icons";

const SVGIcon = (props) => (
  <svg
    width="12"
    height="15"
    viewBox="0 0 12 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.73754 0.556717C5.69965 0.572822 0.878781 2.59223 0.879832 2.59153C0.886834 2.58873 0.891035 2.60133 0.923595 2.59153C0.950763 2.58383 0.953914 2.58172 1.03297 2.56982C1.04557 2.56842 1.06742 2.57052 1.0768 2.56982C0.733838 2.57892 0.398438 2.81279 0.398438 3.27003C0.398438 6.43008 0.50592 7.71568 1.05488 9.28695C1.7761 11.3519 3.21006 13.0135 5.65001 14.4076C5.86525 14.5308 6.1349 14.5308 6.35022 14.4076C8.78926 13.0142 10.2237 11.3708 10.9453 9.30934C11.4943 7.74087 11.6018 6.43638 11.6018 3.29174C11.6018 3.01236 11.443 2.76798 11.186 2.65735L6.30646 0.556717C6.13042 0.481094 5.91378 0.481094 5.73754 0.556717ZM6.00011 3.99195C6.38684 3.99195 6.70032 4.30564 6.70032 4.69216V7.493C6.70032 7.87952 6.38684 8.19321 6.00011 8.19321C5.61339 8.19321 5.2999 7.87952 5.2999 7.493V4.69216C5.2999 4.30564 5.61339 3.99195 6.00011 3.99195ZM6.00011 8.89342C6.38684 8.89342 6.70032 9.20711 6.70032 9.59363C6.70032 9.98015 6.38684 10.2938 6.00011 10.2938C5.61339 10.2938 5.2999 9.98015 5.2999 9.59363C5.2999 9.20711 5.61339 8.89342 6.00011 8.89342Z"
      fill={props.color || "#E05D4C"}
    />
  </svg>
);

const SVGMatadorShieldRiskIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />;
      }}
      {...props}
    />
  );
};

export default SVGMatadorShieldRiskIcon;
