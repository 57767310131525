import React from 'react'
import { Form } from 'antd'
import './MatForm.scss'

const MatForm = (props) => {
  return (
    <Form
      form={props.form}
      size={props.size}
      name={props.name}
      initialValues={props.initialValues}
      onFinish={props.onFinish}
      onFinishFailed={props.onFinishFailed}
      scrollToFirstError
      validateTrigger={props.validateTrigger || ['onBlur']}
      layout='vertical'
      className={`mat-form ${props.className || ''}`}
      onValuesChange={props.onValuesChange}
    >
      {props.children}
    </Form>
  )
}

MatForm.defaultProps = {
  name: 'formSubmit',
  size: 'middle',
  initialValues: {},
  onFinish: () => {},
  onFinishFailed: () => {},
}

export default MatForm
