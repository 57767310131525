import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import { Form, Input } from 'antd'
import { getText } from '../../../lang'

const deviceSize = ['mobile', 'tabletPortrait', 'tablet']

const InputFormTextAreaSecondary = forwardRef((props, ref) => {
  const textAreaRef = useRef()

  useImperativeHandle(ref, () => ({
    autoFocus(position) {
      if (!deviceSize.includes(props.screenSize)) {
        setTimeout(() => {
          textAreaRef.current.focus()
          if (position) {
            textAreaRef.current.resizableTextArea.textArea.selectionStart = position
            textAreaRef.current.resizableTextArea.textArea.selectionEnd = position
          }
        }, 10)
      }
    },
  }))

  return (
    <Form.Item
      className={`mat-form-item ${props.formClassName || ''} mat-form-item-black`}
      label={props.label}
      name={props.name}
      hasFeedback={props.hasFeedback}
      initialValue={props.initialValue}
      getValueFromEvent={props.getValueFromEvent}
      hidden={props.hidden}
      style={props.formStyle}
      rules={
        props.rules || [
          {
            required: props.required,
            message: props.message
              ? props.message
              : `${getText('WORD_PLEASE_INPUT')} ${props.label ? props.label : props.placeholder}!`,
            whitespace: props.required,
          },
          {
            max: props.maxLength,
            message:
              props.maxLength &&
              (props.maxLenghthMsg ||
                `${props.label ? props.label : props.placeholder} ${getText('TEXT_MUST_BE_MAXIMUM')} ${
                  props.maxLength
                } ${getText('WORD_CHARACTERS')}.`),
          },
          {
            min: props.minLength,
            message:
              props.minLength &&
              `${props.label ? props.label : props.placeholder} ${getText('TEXT_MUST_BE_MINIMUM')} ${
                props.minLength
              } ${getText('WORD_CHARACTERS')}.`,
          },
        ]
      }
      layout='vertical'
    >
      <Input.TextArea
        className={`mat-textarea mat-textarea-secondary ${
          props.inputClassName || ''
        }`}
        placeholder={props.label || props.placeholder}
        autoSize={props.autoSize}
        disabled={props.disabled}
        onChange={props.onChange}
        maxLength={props.maxLength}
        ref={textAreaRef}
        value={props.value}
        allowClear={props.allowClear}
        showCount={props.showCount}
        onClick={props.onClick}
        onPressEnter={props.onPressEnter}
        onKeyUp={props.onKeyUp}
        onKeyDown={props.onKeyDown}
        style={{
          resize: 'none',
        }}
      />
    </Form.Item>
  )
})

InputFormTextAreaSecondary.defaultProps = {
  name: 'comment',
  placeholder: 'Comment',
  required: false,
  hasFeedback: false,
  allowClear: true,
  autoSize: { minRows: 5, maxRows: 5 },
}

export default InputFormTextAreaSecondary
