import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M8 14.665c3.667 0 6.667-3 6.667-6.666 0-3.667-3-6.667-6.667-6.667s-6.667 3-6.667 6.667c0 3.666 3 6.666 6.667 6.666zM8 5.332v3.333'
        stroke='#E05D4C'
        strokeWidth={1.5}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.996 10.668h.006'
        stroke='#E05D4C'
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

const SVGInfoIconOrange = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGInfoIconOrange
