import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='17'
      height='16'
      viewBox='0 0 17 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_19121_62322)'>
        <path
          d='M6.62156 11.7078C6.43402 11.8954 6.17967 12.0007 5.91445 12.0007H4C3.72386 12.0007 3.5 11.7769 3.5 11.5007V9.58626C3.5 9.32104 3.60536 9.06668 3.79289 8.87915L11.1233 1.54871C11.2484 1.42373 11.4179 1.35352 11.5947 1.35352C11.7714 1.35352 11.941 1.42373 12.066 1.54871L13.952 3.43471C14.077 3.55973 14.1472 3.72926 14.1472 3.90604C14.1472 4.08282 14.077 4.25236 13.952 4.37737L6.62156 11.7078ZM3.5 13.834C3.5 13.5579 3.72386 13.334 4 13.334H15C15.2761 13.334 15.5 13.5579 15.5 13.834V14.1674C15.5 14.4435 15.2761 14.6674 15 14.6674H4C3.72386 14.6674 3.5 14.4435 3.5 14.1674V13.834Z'
          fill={props.color || '#77808D'}
        />
      </g>
      <defs>
        <clipPath id='clip0_19121_62322'>
          <rect width='16' height='16' fill='white' transform='translate(0.5)' />
        </clipPath>
      </defs>
    </svg>
  )
}

const SVGCustomerEditIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGCustomerEditIcon
