import React, { useEffect, useState } from 'react'
import { getText, getTextServerError } from '../../../../lang'
import './TagItem.scss'
import { IconTag } from '../../../../icons/IconOrganization'
import SVGCloseTag from '../../../../icons/SVG/SVGCloseTag'
import InputFormText from '../../../../components/Form/InputFormText'
import MatRow from '../../../../components/MatRow'
import tagsActions from '../../../../store/modules/tagsActions'
import { notifyError, notifySuccess } from '../../../../utils/Notify'

const listColor = [
  '#47A4FE',
  '#9676E5',
  '#CE36D3',
  '#FF5B72',
  '#F8A326',
  '#FFE120',
  '#87CF48',
  '#46BFBE',
  '#9AAAB3',
]

const TagItem = (props) => {
  const { field, form, onDelete, onInitialValuesUpdate } = props

  const [isEdit, setIsEdit] = useState(false)
  const [inputValue, setInputValue] = useState('')

  const item = (form.getFieldValue('tags_items') &&
    form.getFieldValue('tags_items')[field.name]) || { color: '#47A4FE' }

  useEffect(() => {
    if (item.name === '') {
      setIsEdit(true)
    }
  }, [item])

  const handleEditTag = async () => {
    setIsEdit(false)

    let resultTag
    let isNew = false

    if (!item.name) {
      form.resetFields()
      return
    }

    const obj = {
      name: item.name,
      color: item.color,
    }

    if (!item.tmpId) {
      resultTag = await tagsActions.saveUpdate(obj, item._id)
    } else {
      resultTag = await tagsActions.saveNew(obj)
      isNew = true
      if (resultTag.success) {
        let list = form.getFieldsValue().tags_items
        const index = list.findIndex((tag) => tag.tmpId === item.tmpId)
        list[index] = resultTag.data
        form.setFieldsValue({ tags_items: list })
      }
    }

    if (resultTag.success) {
      notifySuccess(getText('TEXT_ORGANIZATION_WAS_SAVED_SUCCESSFULLY'))
      onInitialValuesUpdate && onInitialValuesUpdate(isNew)
    } else {
      form.resetFields()
      notifyError(getTextServerError(resultTag.errMsg, { organization: true }))
    }
    setInputValue('')
  }

  return (
    <MatRow className='tag-item'>
      <MatRow style={{ width: 'auto' }}>
        <div
          style={{
            backgroundColor: item.color,
            height: '100%',
            position: 'absolute',
            left: '0',
            width: '3px',
            top: '0',
          }}
        />
        <IconTag
          style={{ marginRight: '10px', minWidth: '14px' }}
          color={item.color}
        />
        {isEdit ? (
          <InputFormText
            name={[field.name, 'name']}
            fieldKey={[field.fieldKey, 'name']}
            inputClassName='tag-input'
            formStyle={{ marginBottom: '0px' }}
            allowClear={false}
            autoFocus={true}
            maxLength={30}
            placeholder={getText('WORD_TYPE_HERE')}
            onChange={(e) => {
              setInputValue(e.target.value)
            }}
            onPressEnter={() => {
              handleEditTag()
            }}
            onBlur={() => {
              handleEditTag()
            }}
            onFocus={() => {
              setInputValue(item.name)
            }}
          />
        ) : (
          <span onClick={() => setIsEdit(true)}>
            {inputValue || (item && item.name) || getText('WORD_TAG_NAME_HERE')}
          </span>
        )}
      </MatRow>
      <MatRow style={{ width: 'auto' }} className='tag-second-section'>
        <MatRow style={{ marginRight: '15px' }} className='tag-color'>
          {listColor.map((col, index) => {
            return (
              <div
                key={index}
                className={`tag-color-select ${
                  item.color === col ? 'selected' : ''
                }`}
                style={{ backgroundColor: col }}
                onClick={() => {
                  let list = form.getFieldsValue().tags_items
                  const index = list.findIndex((tag) => {
                    if (item._id) {
                      return tag._id === item._id
                    } else {
                      return tag._tmpId === item.tmpId
                    }
                  })
                  if (index > -1) {
                    list[index].color = col
                    form.setFieldsValue({ tags_items: list })
                    handleEditTag()
                  }
                }}
              />
            )
          })}
        </MatRow>
        <div
          style={{ cursor: 'pointer', display: 'flex' }}
          className='tag-close-btn'
          onClick={() => onDelete && onDelete(item)}
        >
          <SVGCloseTag />
        </div>
      </MatRow>
    </MatRow>
  )
}

export default TagItem
