import React from 'react'
import Icon from '@ant-design/icons'
import { MainColor } from '../../utils'

const SVGIcon = (props) => (
  <svg
    width={props.width || '16'}
    height={props.height || '16'}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M8.66683 2H7.3335C4.01979 2 1.3335 4.68629 1.3335 8V11.3333C1.3335 12.8061 2.5274 14 4.00016 14H8.66683C11.9805 14 14.6668 11.3137 14.6668 8C14.6668 4.68629 11.9805 2 8.66683 2Z'
      stroke={props.color || MainColor}
      strokeWidth='1.5'
      strokeLinejoin='round'
    />
    <path
      d='M6 6H11.3333M6 8.66667H11.3333M6 11.3333H8.66667'
      stroke={props.color || MainColor}
      strokeWidth='1.5'
      strokeLinecap='round'
    />
    <path
      d='M4 6H4.33333M4 8.66667H4.33333M4 11.3333H4.33333'
      stroke={props.color || MainColor}
      strokeWidth='1.5'
      strokeLinecap='round'
    />
  </svg>
)

const SVGAISummaryIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGAISummaryIcon
