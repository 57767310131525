import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={props.width || '16'}
    height={props.heigth || '16'}
    viewBox='0 0 60 60'
    // {...props}
  >
    <path
      d='M37.2 4.4c12.5 0 22.4 10.1 22.4 22.4 0 3.1-.5 6-1.8 8.8l2.1 14-14-2c-2.5 1-5.3 1.6-8 1.8-3.9 3.6-9.3 5.9-15.2  5.9-3 0-5.9-.7-8.6-1.8L0 55.6l2.1-14.1C.9 38.8.3 35.8.3 32.9.3 20.6 10 10.7 22 10.3c4.1-3.6 9.4-5.9 15.2-5.9zm-4 44.5C22.7 47 14.8 37.8 14.8 26.8c0-4.7 1.5-9.2 4.1-12.8-8.8 1.8-15.3 9.5-15.3 18.8 0 3 .7 5.5 1.9 8.2L3.8 51.8l10.7-1.6c2.7 1.3 5.1 1.9 8.2 1.9 3.9-.1 7.5-1.2 10.5-3.2zm4-41.2C26.6 7.7 18 16.2 18 26.8s8.6 19.3 19.3 19.3c3.1 0 5.6-.7 8.3-1.9l10.6 1.5-1.5-10.6c1.2-2.7 1.9-5.3 1.9-8.3-.1-10.6-8.8-19.1-19.4-19.1z'
      fill={props.color || '#a3a6be'}
    />
    <path
      d='M26.9 23.6c1.8 0 3.2 1.4 3.2 3.2 0 1.9-1.4 3.3-3.2 3.3-1.9 0-3.3-1.4-3.3-3.3 0-1.7 1.5-3.2 3.3-3.2zM37.2 23.6c1.9 0 3.3 1.4 3.3 3.2 0 1.9-1.4 3.3-3.3 3.3-1.8 0-3.2-1.4-3.2-3.3.1-1.7 1.5-3.2 3.2-3.2zM47.6 23.6c1.8 0 3.2 1.4 3.2 3.2 0 1.9-1.4 3.3-3.2 3.3-1.9 0-3.3-1.4-3.3-3.3 0-1.7 1.5-3.2 3.3-3.2z'
      fill={props.color || '#a3a6be'}
    />
  </svg>
)

const SVGLocTextMe = (props) => (
  <Icon component={() => <SVGIcon {...props} />} {...props} />
)

export default SVGLocTextMe
