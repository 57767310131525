import React, { useEffect, useState } from 'react'
import { Dropdown } from 'antd'
import { DownOutlined, LoadingOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { getText } from '../../../../../lang'
import useUpdateEffect from '../../../../../utils/useUpdateEffect'
import SVGChecked from '../../../../../icons/SVG/SVGChecked'
import userActions from '../../../../../store/modules/userActions'
import authActions from '../../../../../store/modules/authActions'
import InputText from '../../../../../components/InputText'
import SVGSearch from '../../../../../icons/SVG/SVGSearch'

const FETCH_SIZE = 50

const MenuItem = (props) => {
  const { users, label, handleClickUser, selected, assignedUsersIds = [] } = props
  return (
    <div className='select-option'>
      <label className='disabled-option'>{label}</label>
      {users.map((user, index) => {
        if (assignedUsersIds.includes(user._id)) {
          return null
        }
        return (
          <div
            className={`drop-item ${selected ? 'activ-option' : ''} ${
              user.type === 'night' ? 'assignToMe' : ''
            }`}
            key={`${index}+${JSON.stringify(user)}`}
            onClick={() => handleClickUser(user)}
          >
            <div className='drop-item-text'>{user.fullName}</div>
            {selected && (
              <span className='option-icon'>
                <SVGChecked />
              </span>
            )}
          </div>
        )
      })}
    </div>
  )
}

const SelectUsersAssigned = (props) => {
  const { questionData, isNewQuestion, form, modalOpen } = props

  const user = useSelector((state) => {
    return state.authReducer.user
  })

  const [isLoading, setIsLoading] = useState(true)
  const [usersData, setUsersData] = useState({
    list: [],
    max: 0,
  })
  const [assignedUsers, setAssignedUsers] = useState(
    (questionData && questionData.users) || []
  )
  const [usersToUnassign, setUsersToUnassign] = useState([])
  const [scrollPage, setScrollPage] = useState(0)
  const [searchKeyword, setSearchKeyword] = useState('')
  const authUser = authActions.getUserData()

  useEffect(() => {
    const initialAssignedUsers = isNewQuestion ? [] : questionData.users
    setAssignedUsers(initialAssignedUsers)
  }, [])

  useEffect(() => {
    if (!modalOpen) {
      setAssignedUsers([])
    } else {
      setAssignedUsers((questionData && questionData.users) || [])
    }
  }, [modalOpen])

  useEffect(() => {
    const getUsersList = async () => {
      setIsLoading(true)
      if (authUser.isAdmin || authUser.isManager) {
        const result = await userActions.getUsersForAssignUnassign(
          scrollPage,
          FETCH_SIZE,
          user._organization_id,
          searchKeyword
        )
        if (result.success) {
          setUsersData((prevUsersData) => ({
            max: result.max,
            list: [...prevUsersData.list, ...result.data],
          }))
        }
      }
      setIsLoading(false)
    }
    getUsersList()
  }, [scrollPage])

  useUpdateEffect(() => {
    const getUsersList = async () => {
      setIsLoading(true)
      if (authUser.isAdmin || authUser.isManager) {
        const result = await userActions.getUsersForAssignUnassign(
          scrollPage,
          FETCH_SIZE,
          user._organization_id,
          searchKeyword
        )
        if (result.success) {
          setUsersData({
            list: result.data,
            max: result.max,
          })
        }
      }
      setIsLoading(false)
    }
    getUsersList()
  }, [searchKeyword])

  const handleAssignUser = (newUser) => {
    const user = { _id: newUser._id, fullName: newUser.fullName }

    if (usersToUnassign.includes(user._id)) {
      const newUnAssignedUsers = usersToUnassign.filter(
        (userId) => userId !== user._id
      )
      setUsersToUnassign(newUnAssignedUsers)
      form.setFieldsValue({ removeUsers: newUnAssignedUsers })
    }

    const newAssignedUsers = [...assignedUsers, user]
    setAssignedUsers(newAssignedUsers)
    form.setFieldsValue({ newUsers: newAssignedUsers.map((user) => user._id) })
  }

  const handleUnAssignUser = (user) => {
    const newAssignedUsers = assignedUsers.filter((u) => u._id !== user._id)
    setAssignedUsers(newAssignedUsers)
    form.setFieldsValue({ newUsers: newAssignedUsers.map((u) => u._id) })

    const newRemoveUsers = [...usersToUnassign, user._id]
    // check if its in questionData.users before doing this
    form.setFieldsValue({ removeUsers: newRemoveUsers })
    setUsersToUnassign(newRemoveUsers)
  }

  const items = [
    {
      label: (
        <div className='svg-with-input'>
          <SVGSearch />
          <InputText
            placeholder={getText('TEXT_USERS_SEARCH')}
            onChange={(e) => setSearchKeyword(e.target.value)}
            allowClear={() => setSearchKeyword('')}
          />
        </div>
      ),
      disabled: true,
      key: 'search',
      className: 'assign-search-input',
    },
    {
      label: (
        <MenuItem
          label={getText('WORD_ASSIGNED_USERS') + ` ( ${assignedUsers.length} )`}
          users={assignedUsers}
          handleClickUser={handleUnAssignUser}
          selected
        />
      ),
      key: 'assigned_my_location',
    },
    {
      label: (
        <MenuItem
          label={getText('WORD_USERS')}
          users={usersData.list}
          assignedUsersIds={assignedUsers.map((user) => user._id)}
          handleClickUser={handleAssignUser}
        />
      ),
      key: 'assigned_other_location',
    },
  ]

  return (
    <div className='select-user-assignement'>
      <Dropdown
        menu={{ items }}
        disabled={isLoading}
        trigger='click'
        overlayClassName='my-assing-drop-overlay full'
        dropdownRender={(menu) => {
          return (
            <div
              style={{ maxHeight: '400px', overflowY: 'auto' }}
              className='my-assing-drop-overlay-wrapper'
              onScroll={(e) => {
                const { scrollHeight, scrollTop, clientHeight } = e.currentTarget
                if (scrollHeight - Math.ceil(scrollTop) <= clientHeight) {
                  if (usersData.list.length < usersData.max && !isLoading) {
                    setScrollPage((prevPage) => prevPage + 1)
                  }
                }
              }}
            >
              {React.cloneElement(menu)}
            </div>
          )
        }}
      >
        <div className='drop-down-trigger-container'>
          <div className='title-item'>
            {isLoading ? (
              <LoadingOutlined style={{ fontSize: 12 }} />
            ) : assignedUsers.length > 0 ? (
              getText('WORD_ASSIGNED_USERS') + ` ( ${assignedUsers.length} )`
            ) : (
              getText('ACTION_SELECT_USER')
            )}
          </div>
          <DownOutlined />
        </div>
      </Dropdown>
    </div>
  )
}

export default SelectUsersAssigned
