import React, { memo } from 'react'
import moment from 'moment'
import SVGPostPurchaseIcon from '../../../../../icons/SVG/SVGPostPurchaseIcon'
import { getText } from '../../../../../lang'
import MatRow from '../../../../../components/MatRow'

const MessageTabRightPostPurchase = (props) => {
  const { conversation } = props

  return (
    Boolean(conversation.receiver && conversation.receiver.serviceInformation) && (
      <div className='tab-info-interests-slider'>
        <MatRow flexStart className='tab-info-icon-with-text'>
          <SVGPostPurchaseIcon />
          <div className='tab-info-touchpoints'>
            <p className='drop-menu-label'>{getText('WORD_POST_PURCHASE')}</p>
            <div className='post-purchase-history'>
              <MatRow>
                <span>{getText('WORD_PURCHASE_DATE')}:</span>
                <span>
                  {moment(
                    conversation.receiver.serviceInformation.purchaseDate
                  ).format('DD/MM/YY')}
                </span>
              </MatRow>
              <MatRow>
                <span>{getText('WORD_LAST_R_O')}</span>
                <span>
                  {' '}
                  {moment(conversation.receiver.serviceInformation.lastRO).format(
                    'DD/MM/YY'
                  )}
                </span>
              </MatRow>
              <MatRow>
                <span>{getText('WORD_NEXT_APPOINTMENT')}:</span>
                <span>
                  {' '}
                  {moment(
                    conversation.receiver.serviceInformation.appointmentDate
                  ).format('DD/MM/YY')}
                </span>
              </MatRow>
            </div>
          </div>
        </MatRow>
      </div>
    )
  )
}

export default memo(MessageTabRightPostPurchase)
