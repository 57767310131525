import React from 'react'
import Icon from '@ant-design/icons'
import { MainColor } from '../../utils'

const SVGIcon = (props) => (
  <svg
    width={props.width || '16'}
    height={props.height || '16'}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M8.79968 14.2466C8.35968 14.8332 7.63967 14.8332 7.19967 14.2466L6.19967 12.9132C6.08633 12.7666 5.84634 12.6466 5.66634 12.6466H5.33301C2.66634 12.6466 1.33301 11.9799 1.33301 8.64657V5.31323C1.33301 2.64657 2.66634 1.31323 5.33301 1.31323H10.6663C13.333 1.31323 14.6663 2.64657 14.6663 5.31323V8.64657'
      stroke={props.color || MainColor}
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.6646 7.33333H10.6706'
      stroke={props.color || MainColor}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7.99666 7.33333H8.00265'
      stroke={props.color || MainColor}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.32967 7.33333H5.33566'
      stroke={props.color || MainColor}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14.6663 11.6667C14.6663 12.4667 14.313 13.18 13.7597 13.6667C13.2864 14.08 12.673 14.3333 11.9997 14.3333C10.5263 14.3333 9.33301 13.14 9.33301 11.6667C9.33301 10.8267 9.71967 10.0733 10.333 9.58667C10.793 9.22 11.373 9 11.9997 9C13.473 9 14.6663 10.1933 14.6663 11.6667Z'
      stroke={props.color || MainColor}
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.1663 10.8333V11.8333L11.333 12.3333'
      stroke={props.color || MainColor}
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

const SVGMessageSendLaterIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGMessageSendLaterIcon
