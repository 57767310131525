import React from 'react'
import { LockOutlined } from '@ant-design/icons'
import { Form, Input } from 'antd'
import { getText } from '../../../lang'

const InputFormPassword = (props) => {
  return (
    <Form.Item
      className={`mat-form-item ${props.formClassName || ''}`}
      label={props.label}
      name={props.name}
      hasFeedback={props.hasFeedback}
      rules={[{ required: props.required, message: props.message }]}
    >
      <Input.Password
        className={`mat-input-affix-wrapper ${props.inputClassName || ''}`}
        placeholder={props.label ? props.label : props.placeholder}
        prefix={<LockOutlined />}
        value={props.value}
        onChange={props.onChange}
        size={props.size}
        allowClear={props.allowClear}
        autoComplete={props.autoComplete}
      />
    </Form.Item>
  )
}

InputFormPassword.defaultProps = {
  name: 'password',
  placeholder: 'Password',
  message: `${getText('MSG_PLEASE_INPUT_PASSWORD')}!`,
  required: true,
  hasFeedback: false,
  allowClear: true,
  autoComplete: 'on',
  size: 'middle',
}

export default InputFormPassword
