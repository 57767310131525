import React from 'react'
import { Pagination, Empty } from 'antd'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import LoadingSpinner from '../LoadingSpinner'
import './MatList.scss'

const DraggableMatList = (props) => {
  const {
    onShowSizeChange,
    onPaginationChange,
    hideOnSinglePage,
    defaultPageSize,
    paginationTotalCount,
    renderItem,
    data,
    loading,
    paginationCurrentPage,
    pagination,
    grid,
    containerClassName,
    header,
    updateList,
  } = props

  const handleOnDragEnd = (result) => {
    if (!result.destination) return
    const items = Array.from(data)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)
    updateList(items)
  }

  return loading ? (
    <LoadingSpinner />
  ) : data && data.length ? (
    <>
      {grid ? (
        <div className={`grid-container ${containerClassName || ''}`}>
          {data.map((item, index) => {
            return (
              <div key={JSON.stringify(item)} className='grid-item'>
                {renderItem(item, index)}
              </div>
            )
          })}
        </div>
      ) : (
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId='items'>
            {(provided) => (
              <div
                className={`list-container ${containerClassName || ''}`}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {header}
                {data.map((item, index) => (
                  <Draggable
                    key={JSON.stringify(item)}
                    draggableId={JSON.stringify(item)}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        className='list-item'
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {renderItem(item, index)}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
      {pagination && (
        <Pagination
          current={paginationCurrentPage + 1}
          total={paginationTotalCount}
          defaultPageSize={defaultPageSize}
          hideOnSinglePage={hideOnSinglePage}
          onChange={(e) => {
            onPaginationChange(e - 1)
          }}
          onShowSizeChange={(e, ee) => {
            onShowSizeChange(ee)
          }}
          responsive
          showLessItems={true}
          showQuickJumper={false}
          showSizeChanger={false}
          showTitle={false}
          showTotal={false}
        />
      )}
    </>
  ) : (
    <Empty
      description={props.emptyDescription}
      image={Empty.PRESENTED_IMAGE_SIMPLE}
    />
  )
}

export default DraggableMatList
