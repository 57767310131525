import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width={props.width || '16'}
    height={props.height || '16'}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_23218_188033)'>
      <path
        d='M1.32812 8V4.66667C1.32812 3.19391 2.52203 2 3.99479 2H11.9948C13.4676 2 14.6615 3.19391 14.6615 4.66667V11.3333C14.6615 12.8061 13.4676 14 11.9948 14H5.32813M3.99479 5.33333L6.51559 7.01387C7.41132 7.61102 8.57826 7.61102 9.47399 7.01387L11.9948 5.33333'
        stroke={props.color || 'white'}
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M0.664062 11.493L1.83954 12.5086C2.11531 12.7468 2.51528 12.7115 2.75153 12.428L5.33073 9.33301'
        stroke={props.color || 'white'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_23218_188033'>
        <rect width='16' height='16' fill={props.color || 'white'} />
      </clipPath>
    </defs>
  </svg>
)

const SVGMarkAsRead = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGMarkAsRead
