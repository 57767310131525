import React, { useState, useEffect } from 'react'
import { Menu, Dropdown } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import { getText } from '../../../lang'
import SVGLocLocations from '../../../icons/SVG/SVGLocLocations'
import SVGLocLivechat from '../../../icons/SVG/SVGLocLivechat'
import SVGLocPromotions from '../../../icons/SVG/SVGLocPromotions'
import SVGLocReview from '../../../icons/SVG/SVGLocReview'
import SVGAppointmentDateIcon from '../../../icons/SVG/SVGAppointmentDateIcon'
import SVGLocTextMe from '../../../icons/SVG/SVGLocTextMe'
import SVGLocationEditIcon from '../../../icons/SVG/SVGLocationEditIcon'
import SVGLocationIntegrationIcon from '../../../icons/SVG/SVGLocationIntegrationIcon'

const LocationMenu = (props) => {
  const { locationObj, isNewLocation } = props
  const navigate = useNavigate()
  const location = useLocation()

  const navigationMenuIsOnTop =
    props.screenSize === 'mobile' || props.screenSize === 'tabletPortrait'
  const [selectedRoute, setSelectedRoute] = useState([])

  const isMatadorConnectSelected = () => {
    return !!subMenuMatadorConnect.find((x) => x.key === selectedRoute)
  }

  useEffect(() => {
    if (locationObj) {
      setSelectedRoute(location.pathname)
    }
  }, [location.pathname])

  const subMenuMatadorConnect = [
    {
      key: `/location/settings/${locationObj._id}/livechat-main`,
      label: getText('WORD_SETTINGS'),
    },
    {
      key: `/location/settings/${locationObj._id}/livechat-appearance`,
      label: getText('WORD_APPEARANCE'),
    },
    {
      key: `/location/settings/${locationObj._id}/livechat-questions`,
      label: getText('WORD_QUESTIONS'),
    },
    {
      key: `/location/settings/${locationObj._id}/livechat-greetings`,
      label: getText('WORD_GREETINGS'),
    },
    {
      key: `/location/settings/${locationObj._id}/stories/list`,
      label: getText('WORD_STORIES'),
    },
    props.user.isSuperAdmin && {
      key: `/location/settings/${locationObj._id}/flow-designer`,
      label: getText('WORD_FLOW_DESIGNER'),
    },
  ]

  const subMenuCta = [
    {
      key: `/location/settings/${locationObj._id}/cta/mobile-cta`,
      label: getText('WORD_MOBILE_CTA'),
    },
    {
      key: `/location/settings/${locationObj._id}/cta/integrated-cta`,
      label: getText('INTEGRATED_CTA'),
    },
    {
      key: `/location/settings/${locationObj._id}/subsrciber-growth`,
      label: getText('SUBSCRIBER_GROWTH'),
    },
  ]

  const subMenuPromotions = [
    {
      key: `/location/settings/${locationObj._id}/promotions/mobileCoupon`,
      label: 'Coupon',
    },
    {
      key: `/location/settings/${locationObj._id}/promotions/exitIntent`,
      label: getText('WORD_EXIT_INTENT'),
    },
  ]

  const RespSubMenuMatadorConnect = () => {
    const items = [
      {
        key: `/location/settings/${locationObj._id}/livechat-main`,
        label: (
          <div
            className={`drop-item ${
              selectedRoute.includes('livechat-main') ? 'active' : ''
            }`}
            onClick={() => {
              navigate(`/location/settings/${locationObj._id}/livechat-main`)
              setSelectedRoute(`/location/settings/${locationObj._id}/livechat-main`)
            }}
          >
            {getText('WORD_SETTINGS')}
          </div>
        ),
      },
      {
        key: `/location/settings/${locationObj._id}/livechat-appearance`,
        label: (
          <div
            className={`drop-item ${
              selectedRoute.includes('livechat-appearance') ? 'active' : ''
            }`}
            onClick={() => {
              navigate(`/location/settings/${locationObj._id}/livechat-appearance`)
              setSelectedRoute(
                `/location/settings/${locationObj._id}/livechat-appearance`
              )
            }}
          >
            {getText('WORD_APPEARANCE')}
          </div>
        ),
      },
      {
        key: `/location/settings/${locationObj._id}/livechat-questions`,
        label: (
          <div
            className={`drop-item ${
              selectedRoute.includes('livechat-questions') ? 'active' : ''
            }`}
            onClick={() => {
              navigate(`/location/settings/${locationObj._id}/livechat-questions`)
              setSelectedRoute(
                `/location/settings/${locationObj._id}/livechat-questions`
              )
            }}
          >
            {getText('WORD_QUESTIONS')}
          </div>
        ),
      },
      {
        key: `/location/settings/${locationObj._id}/livechat-greetings`,
        label: (
          <div
            className={`drop-item ${
              selectedRoute.includes('livechat-greetings') ? 'active' : ''
            }`}
            onClick={() => {
              navigate(`/location/settings/${locationObj._id}/livechat-greetings`)
              setSelectedRoute(
                `/location/settings/${locationObj._id}/livechat-greetings`
              )
            }}
          >
            {getText('WORD_GREETINGS')}
          </div>
        ),
      },
      {
        key: `/location/settings/${locationObj._id}/stories/list`,
        label: (
          <div
            className={`drop-item ${
              selectedRoute.includes('stories/list') ? 'active' : ''
            }`}
            onClick={() => {
              navigate(`/location/settings/${locationObj._id}/stories/list`)
              setSelectedRoute(`/location/settings/${locationObj._id}/stories/list`)
            }}
          >
            {getText('WORD_STORIES')}
          </div>
        ),
      },
    ]
    return (
      <Dropdown
        menu={{ items }}
        placement={'bottom'}
        overlayClassName='dropdown-matador-connect-overlay'
        trigger={['click']}
      >
        <SVGLocLivechat
          color={isMatadorConnectSelected() ? '#7854F6' : '#292D32'}
          width='22'
          heigth='22'
        />
      </Dropdown>
    )
  }

  const RespSubMenuCta = () => {
    const items = [
      {
        key: `/location/settings/${locationObj._id}/cta/mobile-cta`,
        label: (
          <div
            className={`drop-item ${
              selectedRoute.includes('mobile-cta') ? 'active' : ''
            }`}
            onClick={() => {
              navigate(`/location/settings/${locationObj._id}/cta/mobile-cta`)
              setSelectedRoute(
                `/location/settings/${locationObj._id}/cta/mobile-cta`
              )
            }}
          >
            {getText('WORD_MOBILE_CTA')}
          </div>
        ),
      },
      {
        key: `/location/settings/${locationObj._id}/cta/integrated-cta`,
        label: (
          <div
            className={`drop-item ${
              selectedRoute.includes('integrated-cta') ? 'active' : ''
            }`}
            onClick={() => {
              navigate(`/location/settings/${locationObj._id}/cta/integrated-cta`)
              setSelectedRoute(
                `/location/settings/${locationObj._id}/cta/integrated-cta`
              )
            }}
          >
            {getText('INTEGRATED_CTA')}
          </div>
        ),
      },
      {
        key: `/location/settings/${locationObj._id}/subsrciber-growth`,
        label: (
          <div
            className={`drop-item ${
              selectedRoute.includes('subsrciber-growth') ? 'active' : ''
            }`}
            onClick={() => {
              navigate(`/location/settings/${locationObj._id}/subsrciber-growth`)
              setSelectedRoute(
                `/location/settings/${locationObj._id}/subsrciber-growth`
              )
            }}
          >
            {getText('SUBSCRIBER_GROWTH')}
          </div>
        ),
      },
    ]
    return (
      <Dropdown
        menu={{ items }}
        placement={'bottom'}
        overlayClassName='dropdown-matador-connect-overlay'
        trigger={['click']}
      >
        <SVGLocTextMe width='22' heigth='22' color='#292D32' />
      </Dropdown>
    )
  }

  const RespSubMenuPromotions = () => {
    const items = [
      {
        key: `/location/settings/${locationObj._id}/promotions/mobileCoupon`,
        label: (
          <div
            className={`drop-item ${
              selectedRoute.includes('mobileCoupon') ? 'active' : ''
            }`}
            onClick={() => {
              navigate(
                `/location/settings/${locationObj._id}/promotions/mobileCoupon`
              )
              setSelectedRoute(
                `/location/settings/${locationObj._id}/promotions/mobileCoupon`
              )
            }}
          >
            Coupon
          </div>
        ),
      },
      {
        key: `/location/settings/${locationObj._id}/promotions/exitIntent`,
        label: (
          <div
            className={`drop-item ${
              selectedRoute.includes('exitIntent') ? 'active' : ''
            }`}
            onClick={() => {
              navigate(`/location/settings/${locationObj._id}/promotions/exitIntent`)
              setSelectedRoute(
                `/location/settings/${locationObj._id}/promotions/exitIntent`
              )
            }}
          >
            {getText('WORD_EXIT_INTENT')}
          </div>
        ),
      },
    ]
    return (
      <Dropdown
        menu={{ items }}
        placement={'bottom'}
        overlayClassName='dropdown-matador-connect-overlay'
        trigger={['click']}
      >
        <SVGLocPromotions width='22' heigth='22' color='#292D32' />
      </Dropdown>
    )
  }

  return (
    <div className='loc-side-menu-layout-wrapper'>
      <div className='icon-with-text'>
        <SVGLocLocations className='icon-wrapper' />
        <span className='location-name'>
          {!isNewLocation ? locationObj.name : getText('WORD_LOCATION_NAME')}
        </span>
      </div>
      <Menu
        className='side-bar-layout'
        mode='inline'
        forceSubMenuRender={true}
        onClick={(e) => {
          if (
            e.key === 'cta' ||
            e.key === 'matador-connect' ||
            e.key === 'promotions'
          ) {
            return
          }
          navigate(e.key)
          setSelectedRoute(e.key)
        }}
        selectedKeys={[selectedRoute]}
        defaultOpenKeys={['matador-connect', 'promotions', 'cta']}
        items={
          isNewLocation
            ? [
                {
                  key: `/location/settings/new`,
                  label: getText('ACTION_CREATE_LOCATION'),
                  icon: <SVGLocationEditIcon />,
                },
              ]
            : [
                {
                  key: `/location/settings/${locationObj._id}/edit`,
                  label: getText('ACTION_EDIT_LOCATION'),
                  icon: <SVGLocationEditIcon />,
                },
                props.user.isAdmin && {
                  key: `/location/settings/${locationObj._id}/integration`,
                  label: getText('WORD_INTEGRATIONS'),
                  icon: <SVGLocationIntegrationIcon />,
                },
                {
                  key: `/location/settings/${locationObj._id}/review`,
                  label: getText('ACTION_REVIEW_INVITE'),
                  icon: <SVGLocReview color='#292D32' />,
                },
                {
                  key: `/location/settings/${locationObj._id}/calendar`,
                  label: getText('TEXT_CALENDAR_INVITE'),
                  icon: (
                    <SVGAppointmentDateIcon width='22' heigth='22' color='#292D32' />
                  ),
                },
                navigationMenuIsOnTop
                  ? {
                      label: <RespSubMenuCta />,
                      key: 'cta',
                    }
                  : {
                      key: `cta`,
                      label: getText('WORD_LEAD_GEN_WIZARD'),
                      icon: <SVGLocTextMe width='22' heigth='22' color='#292D32' />,
                      children: subMenuCta,
                    },
                navigationMenuIsOnTop
                  ? {
                      label: <RespSubMenuMatadorConnect />,
                      key: 'matador-connect',
                    }
                  : {
                      key: `matador-connect`,
                      label: getText('TEXT_MATADOR_CONNECT'),
                      icon: (
                        <SVGLocLivechat
                          color={isMatadorConnectSelected() ? '#7854F6' : '#292D32'}
                          width='22'
                          heigth='22'
                        />
                      ),
                      children: subMenuMatadorConnect,
                    },
                navigationMenuIsOnTop
                  ? {
                      label: <RespSubMenuPromotions />,
                      key: 'promotions',
                    }
                  : {
                      key: `promotions`,
                      label: getText('WORD_PROMOTIONS'),
                      icon: (
                        <SVGLocPromotions width='22' heigth='22' color='#292D32' />
                      ),
                      children: subMenuPromotions,
                    },
              ]
        }
      />
    </div>
  )
}

export default LocationMenu
