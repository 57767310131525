import React from "react";
import Icon from "@ant-design/icons";

const SVGIcon = (props) => (
  <svg
    width={props.width || "33"}
    height={props.height || "32"}
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      opacity="0.1"
      x="0.5"
      width="32"
      height="32"
      rx="10"
      fill={props.color || "#52B387"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5 12.5723C10.5 10.7085 12.0113 9.19727 13.875 9.19727C15.7387 9.19727 17.25 10.7085 17.25 12.5723C17.25 14.436 15.7387 15.9473 13.875 15.9473C12.0113 15.9473 10.5 14.436 10.5 12.5723ZM9 20.4473C9 18.9143 9.92101 17.523 11.2965 16.9553C11.4885 16.8758 11.694 16.8923 11.883 16.9785C12.57 17.2913 13.2188 17.4473 13.875 17.4473C14.5335 17.4473 15.18 17.2995 15.8438 17.0018C16.0357 16.9163 16.26 16.9185 16.4535 17.0018C17.8237 17.5928 18.75 18.9503 18.75 20.4473V21.9473C18.75 22.3613 18.414 22.6973 18 22.6973H9.75C9.336 22.6973 9 22.3613 9 21.9473V20.4473ZM22.7035 13.2532C22.8498 13.107 23.0501 13.0425 23.2421 13.0425C23.4341 13.0425 23.635 13.107 23.7813 13.2532C24.0745 13.5465 24.0745 14.0385 23.7813 14.3317L20.7813 17.3317C20.4888 17.6242 19.996 17.6242 19.7035 17.3317L18.2035 15.8317C17.9103 15.5385 17.9103 15.0465 18.2035 14.7532C18.496 14.4607 18.9888 14.4607 19.2813 14.7532L20.2421 15.714L22.7035 13.2532Z"
      fill={props.color || "#52B387"}
    />
  </svg>
);

const SVGWorkflowAssignUserIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />;
      }}
      {...props}
    />
  );
};

export default SVGWorkflowAssignUserIcon;
