import React from 'react'
import MatRow from '../../components/MatRow'

const FieldItem = ({ icon, leftText, rightText, style }) => {
  return (
    <MatRow className='user-list-field-item' style={style}>
      <MatRow>
        {icon}
        {leftText}
      </MatRow>
      <MatRow flexEnd>{rightText}</MatRow>
    </MatRow>
  )
}

export default FieldItem
