import React from 'react'
import { Switch } from 'antd'
import './MatSwitch.scss'

const MatSwitch = (props) => {
  return (
    <div className={`mat-switch-wrapper ${props.parentClassName || ''}`}>
      {props.label && (
        <div className='switch-label'>
          {props.icon && props.icon} {props.label ? props.label : ''}
        </div>
      )}
      <Switch
        ref={props.ref}
        defaultChecked={props.defaultValue}
        disabled={props.disabled}
        checked={props.value}
        onChange={props.onChange}
        loading={props.loading}
        className={`mat-switch ${props.className || ''}`}
      />
      {props.switchText && <span className='switch-text'>{props.switchText}</span>}
    </div>
  )
}

export default MatSwitch
