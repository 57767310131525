import React, { useState, useEffect, useReducer } from 'react'
import { Form, Tabs } from 'antd'
import { notifyError, notifySuccess } from '../../../../utils/Notify'
import locationActions from '../../../../store/modules/locationActions'
import authActions from '../../../../store/modules/authActions'
import { getText, getTextServerError } from '../../../../lang'
import { getLongName, MainColor } from '../../../../utils'
import MatForm from '../../../../components/Form/MatForm'
import MatColorPicker from '../../../../components/MatColorPicker'
import LoadingSpinner from '../../../../components/LoadingSpinner'
import MatadorConnectLayout from '../matadorConnect/matadorConnectComponents/MatadorConnectLayout'
import MatadorConnectListTitle from '../matadorConnect/matadorConnectComponents/MatadorConnectListTitle'
import InputFormTextAreaSecondary from '../../../../components/Form/InputFormTextArea/InputFormTextAreaSecondary'
import './TextMe.scss'
import SubscriberGrowthInstructions from '../matadorConnect/instructions/SubscriberGrowthInstructions'
import PreviewMode from './Preview'

const reducerGrowth = (state, action) => {
  if (!action.type) {
    return { ...state, ...action }
  }
  switch (action.type) {
    case 'INITIAL_STATE':
      return { ...action.payload }
    default:
      break
  }
}

const SubscriberGrowth = (props) => {
  const { locationObj } = props
  const locationId = locationObj._id
  const organization = authActions.getOrganization()
  const defaultOrgLang = organization.defaultLanguage
  const [isLoading, setIsLoading] = useState(true)
  const [selectedLangTab, setSelectedLangTab] = useState(organization.languages[0])
  const [dataState, dispatch] = useReducer(reducerGrowth, {})
  const [form] = Form.useForm()

  useEffect(() => {
    props.setFooterButtons && props.setFooterButtons(['save', 'cancel'])
    setSelectedLangTab(organization.languages[0])
    const getButtonData = async () => {
      setIsLoading(true)
      const result = await locationActions.getSubscriberGrowth(locationId)

      const buttonData = result.data

      const initialState = {
        title: buttonData.title,
        description: buttonData.description,
        active: buttonData.active,
        primaryColor: getHexColor(buttonData.primaryColor || MainColor),
      }
      dispatch({ type: 'INITIAL_STATE', payload: initialState })
      setIsLoading(false)
    }
    if (locationId) {
      getButtonData()
    }
  }, [locationId])

  const getHexColor = (color) => {
    if (!color) {
      return '#FFF'
    }
    if (color.length <= 7) {
      return color
    }
    const rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(',')
    const hex = `#${(
      (1 << 24) +
      (parseInt(rgba[0]) << 16) +
      (parseInt(rgba[1]) << 8) +
      parseInt(rgba[2])
    )
      .toString(16)
      .slice(1)}`
    return hex
  }

  const onFinish = async (values) => {
    props.setLoading(true)
    const allValues = form.getFieldsValue(true)

    if (!allValues.title || !allValues.title[defaultOrgLang]) {
      return notifyError('Title is required')
    }

    if (!allValues.description || !allValues.description[defaultOrgLang]) {
      return notifyError('Description is required')
    }

    const newStyle = {
      active: true,
      primaryColor: allValues.primaryColor,
      title: allValues.title,
      description: allValues.description,
    }
    const result = await locationActions.saveSubscriberGrowth(newStyle, locationId)
    if (result.success) {
      notifySuccess(getText('SUBSCRIBER_GROWTH_BUTTON'))
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
    props.setLoading(false)
  }

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields.length > 0) {
      notifyError(errorInfo.errorFields[0].errors[0])
    }
  }

  if (isLoading || !locationId) {
    return <LoadingSpinner />
  }
  return (
    <MatadorConnectLayout className='text-me-wrapper subscriber-growth-wrapper'>
      <MatadorConnectListTitle title={getText('SUBSCRIBER_GROWTH')} />
      <div className='form-button-wrapper'>
        <MatForm
          className='subscriber-growth-form'
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={dataState}
        >
          <Tabs
            className='mat-tabs'
            onChange={(index) => {
              setSelectedLangTab(organization.languages[index])
            }}
            items={organization.languages.map((lng, index) => {
              return {
                label: getLongName(lng) + ' ' + getText('WORD_MESSAGES_LC'),
                key: index,
                forceRender: true,
                children: (
                  <>
                    <InputFormTextAreaSecondary
                      label={getText('LABEL_TITLE')}
                      name={['title', lng]}
                      onChange={(e) => {
                        e.persist()
                        dispatch({
                          title: {
                            ...dataState.title,
                            [lng]: e.target.value,
                          },
                        })
                      }}
                      required={lng === defaultOrgLang}
                      message={getText('ERR_VALIDATION_REQUIRED')}
                      autoSize={false}
                    />

                    <InputFormTextAreaSecondary
                      label={getText('LABEL_DESCRIPTION')}
                      name={['description', lng]}
                      onChange={(e) => {
                        e.persist()
                        dispatch({
                          description: {
                            ...dataState.description,
                            [lng]: e.target.value,
                          },
                        })
                      }}
                      required={lng === defaultOrgLang}
                      message={getText('ERR_VALIDATION_REQUIRED')}
                      autoSize={false}
                    />
                  </>
                ),
              }
            })}
          />

          <hr />

          <MatColorPicker
            black
            label={getText('WORD_PRIMARY_COLOR')}
            color={dataState.primaryColor}
            className='color-pick-text-me'
            onChangeColor={(newColor) => {
              dispatch({
                primaryColor: newColor,
              })
              form.setFieldsValue({
                primaryColor: newColor,
              })
            }}
          />
          <hr />

          <SubscriberGrowthInstructions locationId={locationId} />
        </MatForm>
        <PreviewMode
          locationId={locationId}
          settings={dataState}
          language={selectedLangTab}
        />
      </div>
    </MatadorConnectLayout>
  )
}

export default SubscriberGrowth
