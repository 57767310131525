import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.99996 1.66797C5.40829 1.66797 1.66663 5.40964 1.66663 10.0013C1.66663 14.593 5.40829 18.3346 9.99996 18.3346C14.5916 18.3346 18.3333 14.593 18.3333 10.0013C18.3333 5.40964 14.5916 1.66797 9.99996 1.66797ZM9.37496 6.66797C9.37496 6.3263 9.65829 6.04297 9.99996 6.04297C10.3416 6.04297 10.625 6.3263 10.625 6.66797V10.8346C10.625 11.1763 10.3416 11.4596 9.99996 11.4596C9.65829 11.4596 9.37496 11.1763 9.37496 10.8346V6.66797ZM10.7666 13.6513C10.725 13.7596 10.6666 13.843 10.5916 13.9263C10.5083 14.0013 10.4166 14.0596 10.3166 14.1013C10.2166 14.143 10.1083 14.168 9.99996 14.168C9.89163 14.168 9.78329 14.143 9.68329 14.1013C9.58329 14.0596 9.49163 14.0013 9.40829 13.9263C9.33329 13.843 9.27496 13.7596 9.23329 13.6513C9.19163 13.5513 9.16663 13.443 9.16663 13.3346C9.16663 13.2263 9.19163 13.118 9.23329 13.018C9.27496 12.918 9.33329 12.8263 9.40829 12.743C9.49163 12.668 9.58329 12.6096 9.68329 12.568C9.88329 12.4846 10.1166 12.4846 10.3166 12.568C10.4166 12.6096 10.5083 12.668 10.5916 12.743C10.6666 12.8263 10.725 12.918 10.7666 13.018C10.8083 13.118 10.8333 13.2263 10.8333 13.3346C10.8333 13.443 10.8083 13.5513 10.7666 13.6513Z'
        fill='#A3A1B7'
      />
    </svg>
  )
}

const SVGInfoIconSilver = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGInfoIconSilver
