import React, { useCallback } from 'react'
import SVGProfileBorderDashed from '../../icons/SVG/SVGProfileBorderDashed'
import SVGTempHotName from '../../icons/SVG/SVGTempHotName'
import SVGTempVeryHotName from '../../icons/SVG/SVGTempVeryHotName'
import SVGTempVeryColdName from '../../icons/SVG/SVGTempVeryColdName'
import SVGTempColdName from '../../icons/SVG/SVGTempColdName'
import SVGTempWarmName from '../../icons/SVG/SVGTempWarmName'
import './ProfileLetters.scss'

const alphabeticArray = [
  '+',
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
]

const ProfileLetters = ({
  name,
  className,
  withoutIcon,
  activeIcon,
  temperature,
  customeName,
  medium,
}) => {
  const getColor = useCallback(() => {
    const finalName = customeName || name
    const first = finalName ? finalName[0].toUpperCase() : ''
    if (first) {
      let index = alphabeticArray.indexOf(first)
      const color = ['blue', 'red', 'green', 'yellow'][index % 4]
      return color
    }
    return 'blue'
  }, [name, customeName])

  const getTempratureStatus = useCallback(
    (status) => {
      switch (status) {
        case 'very_cold':
          return <SVGTempVeryColdName className='temperature-icon' />
        case 'cold':
          return <SVGTempColdName className='temperature-icon' />
        case 'warm':
          return <SVGTempWarmName className='temperature-icon' />
        case 'hot':
          return <SVGTempHotName className='temperature-icon' />
        case 'very_hot':
          return <SVGTempVeryHotName className='temperature-icon' />
        default:
          break
      }
    },
    [temperature]
  )

  return (
    <>
      <div
        className={`mat-user-profile-letters-wrapper ${
          !withoutIcon ? 'with-icon' : ''
        } ${temperature ? 'temprature-letter' : ''}`}
      >
        {!withoutIcon ? <SVGProfileBorderDashed /> : null}
        <div
          className={`mat-user-profile-letters ${
            medium ? 'medium' : ''
          } ${getColor()} ${className || ''} ${temperature}`}
        >
          {Boolean(temperature) && getTempratureStatus(temperature)}
          {activeIcon && <div className='active-icon' />}
          {customeName || (
            <>
              {name === ''
                ? '-'
                : typeof name === 'string'
                  ? name &&
                    name
                      .toUpperCase()
                      .split(' ')
                      .map((word) => word.substring(0, 1))
                      .slice(0, 2)
                  : Array.isArray(name)
                    ? name.map((word) => {
                        return word
                          ? word.toUpperCase().substring(0, 1).slice(0, 2)
                          : null
                      })
                    : null}
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default ProfileLetters
