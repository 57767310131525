import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Empty } from 'antd'
import paymentsActions from '../../../store/modules/paymentsAction'
import { getText, getTextServerError } from '../../../lang'
import { formatAmount } from '../../../utils'
import MatModal from '../../../components/MatModal'
import MatTimeline from '../../../components/MatTimeline'
import { notifyError } from '../../../utils/Notify'
import LoadingSpinner from '../../../components/LoadingSpinner'

const PaymentTimelineModal = forwardRef(({ trigger, item }, ref) => {
  const [visible, setVisible] = useState(false)
  const [timelineData, setTimelineData] = useState([])
  const [loading, setLoading] = useState(false)

  const paymentEventColor = (value) => {
    let color = ''
    switch (value) {
      case 'matador_payment_request_created':
        color = 'blue'
        break
      case 'payment_request_failed':
        color = 'red'
        break
      case 'payment_request_canceled':
        color = 'black'
        break
      case 'payment_request_completed':
        color = 'green'
        break
      case 'matador_refund_request_created':
        color = 'yellow'
        break
      default:
        break
    }
    return color
  }

  useImperativeHandle(ref, () => ({
    onOpen() {
      setVisible(true)
    },
    onClose() {
      setVisible(false)
    },
  }))

  useEffect(() => {
    const fill = async () => {
      setLoading(true)
      const result = await paymentsActions.getPaymentsTimeline(item._id)
      if (result.success) {
        setTimelineData(result.data)
      } else {
        notifyError(getTextServerError(result.errMsg))
      }
      setLoading(false)
    }
    if (visible) {
      fill()
    }
  }, [item, visible])

  const getPaymentTimelineEvent = () => {
    let list = []
    const newList =
      timelineData &&
      timelineData.map((oo) => ({
        value: getText(oo.event_type),
        color: paymentEventColor(oo.event_type),
        date: oo.createdAt,
        ...oo,
      }))
    if (newList) {
      list = [...newList]
    }
    return list
  }

  return (
    <MatModal
      title={
        item.currency
          ? item.currency.toUpperCase() +
            ' ' +
            formatAmount(item.amount).replace('$', '')
          : formatAmount(item.amount).replace('$', 'USD')
      }
      trigger={trigger}
      width={400}
      visible={visible}
      onTrrigerClick={() => {
        setVisible(true)
      }}
      onCancel={() => {
        setVisible(false)
      }}
      hideSubmit
      forceRender={false}
      className='payment-modal-item-wrapper'
    >
      {!loading ? (
        getPaymentTimelineEvent() && getPaymentTimelineEvent().length > 0 ? (
          <MatTimeline options={getPaymentTimelineEvent()} />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )
      ) : (
        <LoadingSpinner />
      )}
    </MatModal>
  )
})

export default PaymentTimelineModal
