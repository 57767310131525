import React from 'react'
import { Form, InputNumber } from 'antd'

const InputFormNumberSecondary = (props) => {
  return (
    <Form.Item
      className={`mat-form-item mat-form-item-black ${props.formClassName || ''}`}
      label={props.label}
      name={props.name}
      initialValue={props.initialValue}
      hasFeedback={props.hasFeedback}
      rules={[
        {
          required: props.required,
          message: props.errorMessage,
          type: props.type,
          min: props.min,
          max: props.max,
        },
      ]}
      layout='vertical'
      style={props.style}
      noStyle={props.noStyle}
      validateStatus={props.validateStatus}
    >
      <InputNumber
        className={`mat-input-affix-wrapper mat-input-affix-secondary-wrapper ${
          props.inputClassName || ''
        }`}
        onChange={props.onChange}
        disabled={props.disabled}
        placeholder={props.placeholder ? props.placeholder : props.label}
        step={props.step}
        size={props.size}
        type='number'
        controls={props.controls}
      />
    </Form.Item>
  )
}

InputFormNumberSecondary.defaultProps = {
  name: 'numberValue',
  placeholder: '1',
  type: 'number',
  required: false,
  hasFeedback: true,
  step: 1,
  disabled: false,
  size: 'middle',
  controls: false,
}

export default InputFormNumberSecondary
