import * as React from "react"

function SVGPhotoIcon(props) {
    return (
        <svg
            width={20} height={18}
            viewBox="0 0 512 512"
            fill={props.color ? props.color : "#A3A6BE"}
            {...props}>
            <circle cx={255.811} cy={285.309} r={75.217} />
            <path d="M477 137H352.718L349 108c0-16.568-13.432-30-30-30H191c-16.568 0-30 13.432-30 30l-3.718 29H34c-11.046 0-20 8.454-20 19.5v258c0 11.046 8.954 20.5 20 20.5h443c11.046 0 20-9.454 20-20.5v-258c0-11.046-8.954-19.5-20-19.5zM255.595 408.562c-67.928 0-122.994-55.066-122.994-122.993 0-67.928 55.066-122.994 122.994-122.994s122.994 55.066 122.994 122.994c0 67.926-55.066 122.993-122.994 122.993zM474 190H369v-31h105v31z" />
        </svg>
    )
}

export default SVGPhotoIcon
