import React from 'react'
import './Broadcast.scss'

const BroadcastCampaignPage = (props) => {
  return (
    <div className={`create-campaign-types ${props.className || ''}`}>
      <div className='create-campaign-types-block'>
        <div className='create-campaign-header'>
          {props.title && <h2>{props.title}</h2>}
          {props.subTitle && <span>{props.subTitle}</span>}
        </div>
        <div className='create-campaign-body'>{props.children}</div>
      </div>
    </div>
  )
}

export default BroadcastCampaignPage
