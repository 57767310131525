import React, { useEffect, useState } from 'react'
import { CloseOutlined } from '@ant-design/icons'
import moment from 'moment'
import broadcastingActions from '../../../store/modules/broadcastingActions'
import { getText, getTextServerError } from '../../../lang'
import ConfirmPopup from '../../../components/ConfirmPopup'
import { notifyError, notifySuccess } from '../../../utils/Notify'
import { TABLE_DEFAULT_ROW_NUMBER } from '../../../devOptions'
import BroadcastListHeader from './BroadcastListHeader'
import BroadcastListMobile from './BroadcastListMobile'
import BroadcastListDesktop from './BroadcastListDesktop'
import './BroadcastList.scss'

const BroadcastingList = (props) => {
  let { setPageTitle, organization = {}, screenSize, automation } = props

  const [loadingData, setLoadingData] = useState(false)
  const [page, setPage] = useState(0)
  const [perPage] = useState(TABLE_DEFAULT_ROW_NUMBER)
  const [totalCount, setTotalCount] = useState(0)
  const [listData, setListData] = useState([])
  const [filter, setFilter] = useState('')

  useEffect(() => {
    setPageTitle &&
      setPageTitle(
        getText(automation ? 'WORD_AUTOMATIONS' : 'TITLE_BROADCASTING_LIST')
      )
  }, [automation])

  useEffect(() => {
    const fill = async () => {
      setLoadingData(true)
      const result = await broadcastingActions.getPageList(
        page,
        perPage,
        organization._id,
        {
          ...filter,
          creatorFilter: { value: automation ? 'automation' : 'manually' },
        }
      )
      if (!organization.enable_broadcast) {
        notifyError(
          getTextServerError(
            getText('TEXT_YOU_DO_NOT_HAVE_ACCESS_TO_THIS_FEATURE_BROADCAST')
          )
        )
      } else if (result.success) {
        setListData(result.data)
        setTotalCount(result.max)
      }
      setLoadingData(false)
    }
    if (organization && organization._id) {
      fill()
    }
  }, [organization, page, perPage, filter, automation])

  const cancelBroadcastSending = (_id) => {
    return (
      <ConfirmPopup
        title={getText(
          'MSG_ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_BROADCAST_CAMPAIGN'
        )}
        onConfirm={async () => {
          setLoadingData(true)
          const result = await broadcastingActions.cancel(_id)
          if (result.success) {
            const foundIndex = listData.findIndex((it) => it._id === _id)
            if (foundIndex >= 0) {
              setListData((oldList) => {
                oldList[foundIndex] = result.data
                return [...oldList]
              })
            }
            notifySuccess(getText('TEXT_CAMPAIGN_WAS_CANCELED_SUCCESSFULLY'))
            setLoadingData(false)
          } else {
            notifyError(getTextServerError(result.errMsg))
          }
        }}
        trigger={<CloseOutlined />}
        okText={getText('WORD_YES')}
        cancelText={getText('WORD_NO')}
      />
    )
  }

  const getStatus = (status, action_time, canceled, _id) => {
    const showCancelButton =
      !canceled && moment(action_time).add(1, 'day').isAfter(moment())
    switch (status) {
      case 'Finished':
        return (
          <div className='with-icon-broadcast'>
            <p className='sent'>{getText('WORD_SENT')}</p>
          </div>
        )
      case 'Canceled':
        return (
          <div className='with-icon-broadcast'>
            <p className='canceled'>{getText('WORD_CANCELLED')}</p>
          </div>
        )
      case 'Scheduled':
        return (
          <div className='with-icon-broadcast'>
            <p className='not-sent'>{getText('WORD_NOT_SENT')}</p>
            {showCancelButton && cancelBroadcastSending(_id)}
          </div>
        )
      default:
        return (
          <div className='with-icon-broadcast'>
            <p className='in-progress'>{getText('WORD_IN_PROGRESS')}</p>
            {showCancelButton && cancelBroadcastSending(_id)}
          </div>
        )
    }
  }

  return (
    <div className='broadcast-list-new-layout-wrapper'>
      <BroadcastListHeader
        screenSize={screenSize}
        automation={automation}
        totalCount={totalCount}
        onFiltersChange={(options) => {
          setFilter(options)
          setPage(0)
        }}
      />
      {screenSize === 'mobile' ? (
        <BroadcastListMobile
          loadingData={loadingData}
          listData={listData}
          page={page}
          setPage={setPage}
          totalCount={totalCount}
          getStatus={getStatus}
          setListData={setListData}
        />
      ) : (
        <BroadcastListDesktop
          loadingData={loadingData}
          getStatus={getStatus}
          listData={listData}
          page={page}
          setPage={setPage}
          totalCount={totalCount}
          setListData={setListData}
          onFilterName={(option) => {
            setFilter({ ...filter, ...option })
          }}
        />
      )}
    </div>
  )
}

export default BroadcastingList
