import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import chatSettingsMainActions from '../../../store/modules/chatSettingsMainActions'
import locationActions from '../../../store/modules/locationActions'
import { getText } from '../../../lang'
import LoadingSpinner from '../../../components/LoadingSpinner'
import LocationMenu from './LocationMenu'
import './LocationNavigation.scss'

const LocationNavigation = (props) => {
  const location = useLocation()
  const params = useParams()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(true)
  const [locationObj, setLocationObj] = useState({})
  const [settings, setSettings] = useState(null)
  const locEditId = params._id
  const isLiveChatRoute = location.pathname.includes('livechat')
  const isNewLocation = location.pathname.includes('new')

  useEffect(() => {
    props.setPageTitle &&
      props.setPageTitle(
        isNewLocation
          ? getText('ACTION_CREATE_LOCATION')
          : getText('ACTION_EDIT_LOCATION')
      )
  }, [])

  useEffect(() => {
    const getLocationFromParamsId = async () => {
      setIsLoading(true)
      const result = await locationActions.getSingle(locEditId, true)
      if (result.success) {
        setLocationObj(result.data)
      } else {
        navigate('/location/list')
      }
    }
    if (!isNewLocation) {
      getLocationFromParamsId()
    }
    setIsLoading(false)
  }, [locEditId])

  useEffect(() => {
    // this is used only in matador connect settings, appearance, and greeting edit
    const getChatSettings = async () => {
      setIsLoading(true)
      const result = await chatSettingsMainActions.getSingle(locEditId)
      if (result.success) {
        setSettings(result.data.settings)
      }
      setIsLoading(false)
    }
    if (isLiveChatRoute) {
      getChatSettings()
    }
  }, [locEditId, isLiveChatRoute])

  // to avoid confusion with react router location property, we'll pass current location under locationObj name
  return (
    <div className='loc-navigation-page'>
      <>
        <LocationMenu
          {...props}
          locationObj={locationObj}
          isNewLocation={isNewLocation}
        />
        {(!isLoading && (!isLiveChatRoute || settings)) || isNewLocation ? (
          <div className='loc-navigation-page-container'>
            {React.cloneElement(props.children, {
              ...props,
              locationObj,
              setLocationObj,
              ...(isLiveChatRoute && { settings, setSettings }),
              isNewLocation,
            })}
          </div>
        ) : (
          <LoadingSpinner />
        )}
      </>
    </div>
  )
}

export default LocationNavigation
