import { store } from '../index';
import { fetchFromUrlPOSTAsync, fetchFromUrlGETAsync } from '../../utils/UrlHelper';
import { pushTempUrlParamsForConversaction } from './meetActions';

const urlInvitations = "/invitations";
const urlInvitationCancel = "/invitations/cancel";

const fs = {
    getPageList: async (start, range, searchKeyword = "", paginationOptions = {}) => {
        const user = store.getState().authReducer.user;
        const urlParams = [
            { key: "page", value: start + 1 },
            { key: "perPage", value: range },
            { key: "_location_id", value: user._location_id },
        ];
        if (searchKeyword) {
            urlParams.push({ key: "search", value: searchKeyword });
        }
        const result = await fetchFromUrlGETAsync(urlInvitations, urlParams);
        if (result.success) {
            result.max = result.data.total_items;
            result.data = result.data.invitations;
        }
        return result;
    },

    sendInvite: async (obj, conv) => {
        if (!obj.email) {
            delete obj.email;
        }
        if (!obj.phone) {
            delete obj.phone;
        }
        const urlParams = Object.keys(obj).map(key => { return { key, value: obj[key] } });
        pushTempUrlParamsForConversaction(conv, urlParams,false);
        const result = await fetchFromUrlPOSTAsync(urlInvitations, urlParams);
        return result;
    },

    cancelInvite: async (itemId) => {
        const result = await fetchFromUrlPOSTAsync(urlInvitationCancel + "/" + itemId, []);
        return result;
    },

};


const loc = {};


const invitationActions = Object.assign(fs, loc);

export default invitationActions;