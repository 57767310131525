import React from 'react'
import LocationQuestionsOptions from './LocationQuestionsOptions'
import FinalViewOptions from './FinalViewOptions'
import LocationsOptions from './LocationsOptions'
import CustomQuestionSelector from './CustomQuestionSelector'
import QuestionsLocationsSelector from './QuestionsLocationsSelector'

const StepOptions = ({
  step,
  index,
  treeData,
  isDataTree,
  handleSelectedLocationChange,
  handleViewTypeChange,
  addSelectedLocations,
  getSelectedLocationIds,
  handleLocationReorder,
  handleCustomQuestionsChange,
  organizations,
  handleLocationChange,
  organization,
  getLocationsByQuestion,
  handleQuestionChange,
  handleLocationUpdate,
  handleQuestionLocationChange,
  handleQuestionLocationReorder,
}) => {
  return (
    <div>
      {step.type === 'Location Questions' && (
        <LocationQuestionsOptions
          step={step}
          index={index}
          treeData={treeData}
          isDataTree={isDataTree}
          handleSelectedLocationChange={handleSelectedLocationChange}
        />
      )}
      {step.type === 'Final View' && (
        <FinalViewOptions
          step={step}
          index={index}
          handleViewTypeChange={handleViewTypeChange}
        />
      )}
      {step.type === 'Locations' && (
        <LocationsOptions
          step={step}
          index={index}
          treeData={treeData}
          isDataTree={isDataTree}
          addSelectedLocations={addSelectedLocations}
          getSelectedLocationIds={getSelectedLocationIds}
          handleLocationReorder={handleLocationReorder}
          handleLocationChange={handleLocationChange}
          organization={organization}
        />
      )}
      {step.type === 'Custom Questions' && (
        <CustomQuestionSelector
          data={organizations}
          index={index}
          value={step.customQuestions}
          onChange={(value) => handleCustomQuestionsChange(index, value)}
        />
      )}
      {step.type === 'Questions -> Locations' && (
        <QuestionsLocationsSelector
          step={step}
          organizationsData={organizations}
          organization={organization}
          stepIndex={index}
          getLocationsByQuestion={getLocationsByQuestion}
          handleQuestionChange={handleQuestionChange}
          handleLocationUpdate={handleLocationUpdate}
          handleLocationChange={handleQuestionLocationChange}
          handleLocationReorder={handleQuestionLocationReorder}
        />
      )}
    </div>
  )
}

export default StepOptions
