import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import appActions from '../../store/modules/appActions'

const ShortLinkRedirect = () => {
  const navigate = useNavigate()

  useEffect(() => {
    ;(async () => {
      const url = window.location.href
      const hash = url.split('/').pop()
      const data = await appActions.getShortUrl(hash)
      if (data) {
        navigate(`/${data.fullLink}`)
      } else {
        navigate('/')
      }
    })()
  }, [])

  return <></>
}

export default ShortLinkRedirect
