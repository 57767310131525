import React from 'react'
import PropTypes from 'prop-types'
import LoadingSpinner from '../../../../../components/LoadingSpinner'
import { getText } from '../../../../../lang'

function SuperHumanItem({
  value,
  title,
  color,
  BgComponent,
  extraTitle = '',
  lodaing,
  onClick,
}) {
  return (
    <div
      className='matador-impact-item first-item'
      style={value === 0 ? { filter: 'grayscale(1)' } : {}}
    >
      <div className='matador-impact-bg'>
        <BgComponent />
        <div className='matador-impact-value-wrap'>
          {!lodaing ? (
            <>
              <div
                className='matador-impact-value'
                style={{ color }}
                onClick={() => {
                  onClick && onClick('superhuman_replies')
                }}
              >
                {value || ''}
              </div>
              <div
                className='matador-impact-title'
                style={{
                  textAlign: 'center',
                }}
              >
                {value === 0
                  ? getText(
                      'TEXT_PLEASE_CONTACT_CLEINTS_SUCCESS_TO_ACTIVATE_SUPERHUMAN'
                    )
                  : title}
              </div>
              {extraTitle && (
                <div className='matador-impact-extra-title' style={{ color }}>
                  <>
                    {getText('TEXT_SUPERHUMAN_ASSISTED_APPOINTMENTS')}:
                    <span
                      className='count'
                      style={{ cursor: 'pointer', marginLeft: 3 }}
                      onClick={() => {
                        onClick && onClick('superhuman_assisted_appointments')
                      }}
                    >
                      {extraTitle}
                    </span>
                  </>
                </div>
              )}
            </>
          ) : (
            <LoadingSpinner />
          )}
        </div>
      </div>
    </div>
  )
}

export default React.memo(SuperHumanItem)

SuperHumanItem.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  BgComponent: PropTypes.func,
  extraTitle: PropTypes.string,
  marginBottom: PropTypes.instanceOf(Object),
  menuOpen: PropTypes.bool,
}
