import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import SVGPushToCRMCrossIcon from '../../../../../icons/SVG/SVGPushToCRMCrossIcon'
import SVGOpenInCRMIcon from '../../../../../icons/SVG/SVGOpenInCRMIcon'
import { getText } from '../../../../../lang'
import MatDropMenu from '../../../../../components/MatDropMenu'
import locationActions from '../../../../../store/modules/locationActions'
import messageActions from '../../../../../store/modules/messageActions'
import { notifyError, notifySuccess } from '../../../../../utils/Notify'
import { myWebViewPost } from '../../../../../utils'
import {
  DEALER_SOCKET,
  ELEADS,
  VIN_SOLUTION,
  ACTIVIX,
} from '../../../../../config/constants'
import './MessageCRMButton.scss'
import { ARCHIVED } from '../../../utils/constants'

const MessageCenterHeaderCRMs = ({
  conversation,
  addToList,
  refreshConversation,
  messageType,
}) => {
  const [selectedLocation, setSelectedLocation] = useState()
  const [dropOptions, setDropOptions] = useState([])

  let organization = useSelector((state) => {
    return state.authReducer.user.organization
  })

  let user = useSelector((state) => {
    return state.authReducer.user
  })

  const isMobileApp = useSelector((state) => {
    return state.authReducer.isMobileApp
  })

  const getCRMItem = (location) => {
    if (!location) {
      return false
    }

    if (location.crmType === DEALER_SOCKET) {
      const entityId =
        conversation.crmInfo && conversation.crmInfo.dealerSocket.entityId
      const eventId =
        conversation.crmInfo && conversation.crmInfo.dealerSocket.eventId

      // // V1
      // if (entityId) {
      //   return `https://bb.dealersocket.com/#/crm/view/contact/${entityId}`
      // }

      // v2
      if (entityId && eventId) {
        const dealerNumber = location.crmConfigs.dealerSocket.dealerNumber || ''
        const [siteId] = dealerNumber.split('_') // 7260_12 => 7260

        // https://inoxoft.atlassian.net/browse/MAT-3212
        return `https://bb.dealersocket.com/#/crm/sales/view/${entityId}/${eventId}?siteId=${siteId}&fullscreen`
      }

      // // V3
      // if (entityId) {
      //   return `https://bb.dealersocket.com/#/crm/customers/${entityId}`
      // }
    }

    if (location.crmType === ELEADS) {
      if (conversation.crmInfo && conversation.crmInfo.eLeads.customer_id) {
        return `https://www.eleadcrm.com/evo2/fresh/eLead-V45/elead_track/newprospects/OpptyDetails.aspx?lPID=${conversation.crmInfo.eLeads.customer_id}`
      }
    }

    if (location.crmType === ACTIVIX) {
      if (
        conversation.crmInfo &&
        conversation.crmInfo.activix &&
        conversation.crmInfo.activix.lead_id
      ) {
        return `https://crm.activix.ca/leads/${conversation.crmInfo.activix.lead_id}`
      }
    }

    if (location.crmType === VIN_SOLUTION) {
      if (
        conversation.crmInfo &&
        conversation.crmInfo.vinSolution.contact.ContactId
      ) {
        return `https://apps.vinmanager.com/vinconnect/#/CarDashboard/Pages/LeadManagement/ActiveLeadsLayout.aspx?SelectedTab=t_ILM&leftpaneframe=HIDE&rightpaneframe=/CarDashboard/Pages/CRM/CustomerDashboard.aspx?GlobalCustomerID=${conversation.crmInfo.vinSolution.contact.ContactId}`
      }
    }
  }

  const setCRMDropDownItems = (location = {}) => {
    const dropOptions = []
    setDropOptions(dropOptions)

    const canOpenInCRM = [
      DEALER_SOCKET,
      // ELEADS,
      VIN_SOLUTION,
      ACTIVIX,
    ].includes(location.crmType)

    const pushToCRM = {
      name: getText('ACTION_SEND_TO_CRM'),
      img: <SVGPushToCRMCrossIcon />,
      value: 'push_to_crm',
      tcolor: '#77808d',
    }

    const openInCRM = {
      name: getText('ACTION_OPEN_IN_CRM'),
      img: <SVGOpenInCRMIcon />,
      value: 'open_in_crm',
      tcolor: '#77808d',
    }

    if (
      location.email &&
      conversation.kind !== 'gbm' &&
      (!user.isSupportAgent ||
        !organization.restrictions.restrictDisplayingPushToCRMButtonToSupportAgents)
    ) {
      dropOptions.push(pushToCRM)
    }

    if (canOpenInCRM) {
      if (getCRMItem(location)) {
        dropOptions.push(openInCRM)
      }
    }

    setDropOptions(dropOptions)
  }

  const sendCRM = async () => {
    if (conversation._id && selectedLocation._id) {
      let result = await messageActions.sendTranscriptionCRM(
        conversation._id,
        selectedLocation._id
      )
      if (result.success) {
        result.data.lastMessage._conversation_id = conversation._id
        addToList && addToList(result.data.lastMessage, true)
        refreshConversation && refreshConversation(conversation, false)
        notifySuccess(getText('NTF_SUCCESS_SEND_TO_CRM'))
      } else {
        notifyError(result.errMsg || getText('ERR_GENERAL'))
      }
    }
  }

  useEffect(() => {
    const loadSelecedLocation = async () => {
      setDropOptions([])

      let result
      if (conversation._location_id) {
        result = await locationActions.getSingle(conversation._location_id)
        if (result.success) {
          setSelectedLocation(result.data)
          setCRMDropDownItems(result.data)
        }
      } else {
        result = await locationActions.getSingle(user._location_id)
        if (result.success) {
          setSelectedLocation(result.data)
          setCRMDropDownItems(result.data)
        }
      }
    }
    loadSelecedLocation()
  }, [conversation._id, conversation._location_id])

  return dropOptions.length ? (
    <MatDropMenu
      bigText
      placement={messageType === ARCHIVED ? 'bottomLeft' : 'bottomRight'}
      showArrow={true}
      overlayStyle={{ width: '200px' }}
      options={dropOptions}
      onChange={(value) => {
        if (value === 'push_to_crm') {
          sendCRM()
        } else if (value === 'open_in_crm') {
          if (isMobileApp) {
            myWebViewPost(getCRMItem(selectedLocation))
          } else {
            window.open(getCRMItem(selectedLocation), '_blank')
          }
        }
      }}
      trigger={getText('WORD_CRM')}
      className={'crm_trigger_dropdown'}
    />
  ) : null
}

export default MessageCenterHeaderCRMs
