import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11 1.65C5.8443 1.65 1.65 5.8443 1.65 11C1.65 16.1557 5.8443 20.35 11 20.35C16.1557 20.35 20.35 16.1557 20.35 11C20.35 5.8443 16.1557 1.65 11 1.65ZM11 22C4.9346 22 0 17.0654 0 11C0 4.9346 4.9346 0 11 0C17.0654 0 22 4.9346 22 11C22 17.0654 17.0654 22 11 22Z'
        fill={props.color || 'black'}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.7743 15.0616C14.6302 15.0616 14.485 15.0242 14.3519 14.9461L10.2049 12.4722C9.95632 12.3226 9.80232 12.0531 9.80232 11.7627V6.42994C9.80232 5.97454 10.1719 5.60494 10.6273 5.60494C11.0838 5.60494 11.4523 5.97454 11.4523 6.42994V11.2941L15.1978 13.5271C15.5883 13.7614 15.717 14.2674 15.4838 14.659C15.3287 14.9175 15.0548 15.0616 14.7743 15.0616Z'
        fill={props.color || 'black'}
      />
    </svg>
  )
}

const SVGOrgOpeningHoursIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGOrgOpeningHoursIcon
