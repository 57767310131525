import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import organizationActions from '../../store/modules/organizationActions'

const StripeAuth = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  useEffect(() => {
    const init = async () => {
      if (window.opener && window.name === 'stripeAuth') {
        window.location.href = await organizationActions.refreshStripeAuth(id)
      } else {
        navigate('/')
      }
    }
    init()
  }, [])

  return null
}

export default StripeAuth
