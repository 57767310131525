import React, { useEffect } from 'react'
import { gapi } from 'gapi-script'
import { WarningOutlined } from '@ant-design/icons'
import GoogleLogin from 'react-google-login'
import { getText } from '../../lang'
import ConfirmPopup from '../ConfirmPopup'
import MatButton from '../MatButton'

const GoogleAuth = (props) => {
  const {
    disabled,
    isConnected,
    responseGoogle,
    responseGoogleLogout,
    clientId,
    scope,
    typeButton,
    title,
    accessType,
    button,
    onClick,
    warning,
    warningText,
    onVisibleChange,
  } = props

  const responseGoogleFailure = (error) => {
    console.log(error)
  }

  useEffect(() => {
    function start() {
      gapi.client.init({
        client_id: clientId || process.env.REACT_APP_GOOGLE_CLIENT_ID,
        scope:
          scope ||
          'https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/gmail.readonly',
        redirect_uri: 'stg.matador.ai',
        ux_mode: 'redirect',
      })
    }

    if (button === 'business' && gapi) {
      gapi.load('client:auth2', start)
    }
  }, [])

  return isConnected ? (
    <>
      {button === 'business' ? (
        <MatButton
          buttonText={title || getText('TEXT_DISCONNECT_YOUR_GMAIL_ACCOUNT')}
          disabled={disabled}
          typeButton={'primary'}
          onClick={onClick}
        />
      ) : (
        <>
          <ConfirmPopup
            title={
              <>
                {getText(
                  'TEXT_ARE_YOU_SURE_YOU_WANT_TO_DISCONNECT_YOUR_GOOGLE_ACCOUNT'
                )}
                {warning ? (
                  <div className='google-auth-warning'>
                    <WarningOutlined style={{ color: 'orange' }} />
                    {warningText}
                  </div>
                ) : null}
              </>
            }
            onConfirm={responseGoogleLogout}
            placement={button === 'business' ? 'rightTop' : 'top'}
            okText={getText('WORD_DISCONNECT')}
            cancelText={getText('ACTION_CANCEL')}
            onVisibleChange={onVisibleChange}
            trigger={
              <MatButton
                buttonText={title || getText('TEXT_DISCONNECT_YOUR_GMAIL_ACCOUNT')}
                disabled={disabled}
                typeButton={typeButton || 'gmail'}
              />
            }
          />
        </>
      )}
    </>
  ) : (
    <GoogleLogin
      clientId={clientId || process.env.REACT_APP_GOOGLE_CLIENT_ID}
      render={(renderProps) => {
        return (
          <MatButton
            buttonText={title || getText('TEXT_CONNECT_GMAIL')}
            disabled={disabled}
            typeButton={typeButton || 'gmail'}
            onClick={renderProps.onClick}
          />
        )
      }}
      buttonText='Login'
      onSuccess={responseGoogle}
      onFailure={responseGoogleFailure}
      cookiePolicy={'single_host_origin'}
      responseType='code'
      scope={
        scope ||
        'https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/gmail.readonly'
      }
      accessType={accessType || 'offline'}
      prompt='consent'
    />
  )
}

export default GoogleAuth
