import React from 'react'
import Icon from '@ant-design/icons'
import { MainColor } from '../../utils'

const SVGIcon = (props) => (
  <svg
    width={props.width || '16'}
    height={props.height || '16'}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M8.35301 13.6134H4.13967C2.03301 13.6134 1.33301 12.2134 1.33301 10.8067V5.19339C1.33301 3.08672 2.03301 2.38672 4.13967 2.38672H8.35301C10.4597 2.38672 11.1597 3.08672 11.1597 5.19339V10.8067C11.1597 12.9134 10.453 13.6134 8.35301 13.6134Z'
      stroke={props.color || MainColor}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M13.0135 11.4001L11.1602 10.1001V5.89342L13.0135 4.59342C13.9202 3.96009 14.6668 4.34675 14.6668 5.46009V10.5401C14.6668 11.6534 13.9202 12.0401 13.0135 11.4001Z'
      stroke={props.color || MainColor}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7.66699 7.3335C8.21928 7.3335 8.66699 6.88578 8.66699 6.3335C8.66699 5.78121 8.21928 5.3335 7.66699 5.3335C7.11471 5.3335 6.66699 5.78121 6.66699 6.3335C6.66699 6.88578 7.11471 7.3335 7.66699 7.3335Z'
      stroke={props.color || MainColor}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

const SVGMessageJoinCallIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGMessageJoinCallIcon
