import React from "react";
import Icon from "@ant-design/icons";

const SVGIcon = (props) => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="25" cy="25" r="25" fill="#F0EFFB" />
      <path
        d="M14.1641 20.6663C14.1641 18.2731 16.1042 16.333 18.4974 16.333H26.0807C28.474 16.333 30.4141 18.2731 30.4141 20.6663V29.333C30.4141 31.7262 28.474 33.6663 26.0807 33.6663H18.4974C16.1042 33.6663 14.1641 31.7262 14.1641 29.333V20.6663Z"
        stroke="#745AD2"
        strokeWidth="1.5"
      />
      <path
        d="M30.4141 22.1108L32.0834 20.3302C33.4268 18.8972 35.8307 19.8479 35.8307 21.812V28.1873C35.8307 30.1515 33.4268 31.1021 32.0834 29.6692L30.4141 27.8886V22.1108Z"
        stroke="#745AD2"
        strokeWidth="1.5"
      />
      <path
        d="M26.0807 22.833C26.0807 24.0296 25.1107 24.9997 23.9141 24.9997C22.7174 24.9997 21.7474 24.0296 21.7474 22.833C21.7474 21.6364 22.7174 20.6663 23.9141 20.6663C25.1107 20.6663 26.0807 21.6364 26.0807 22.833Z"
        stroke="#745AD2"
        strokeWidth="1.5"
      />
    </svg>
  );
};

const SVGVideoStatsHeader = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />;
      }}
      {...props}
    />
  );
};

export default SVGVideoStatsHeader;
