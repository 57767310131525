import React from 'react'
import Icon from '@ant-design/icons'
import { MainColor } from '../../utils'

const SVGIcon = (props) => (
  <svg
    width={props.width || '16'}
    height={props.height || '16'}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M12.9997 12.6667H10.333'
      stroke={props.color || MainColor}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.09987 7.24658C8.0332 7.23992 7.9532 7.23992 7.87987 7.24658C6.2932 7.19325 5.0332 5.89325 5.0332 4.29325C5.0332 2.65992 6.3532 1.33325 7.9932 1.33325C9.62654 1.33325 10.9532 2.65992 10.9532 4.29325C10.9465 5.89325 9.68654 7.19325 8.09987 7.24658Z'
      stroke={props.color || MainColor}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7.99336 14.54C6.78003 14.54 5.57336 14.2333 4.65336 13.62C3.04003 12.54 3.04003 10.78 4.65336 9.70662C6.48669 8.47995 9.49336 8.47995 11.3267 9.70662'
      stroke={props.color || MainColor}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

const SVGMessageUnassignIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGMessageUnassignIcon
