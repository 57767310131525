import React, { memo, useCallback, useEffect, useState } from 'react'
import { List } from 'antd'
import LinesEllipsis from 'react-lines-ellipsis'
import SVGArrowRight from '../../../../../icons/SVG/SVGArrowRight'
import { TABLE_FETCH_PAGE_SIZE, formatDateTime } from '../../../../../utils'
import MatButton from '../../../../../components/MatButton'
import notesActions from '../../../../../store/modules/notesActions'
import { getText } from '../../../../../lang'
import AddNoteModal from './AddNoteModal'
import './MessageTabNotes.scss'

const MessageTabNote = (props) => {
  const { conversation, screenSize } = props

  const [listNotes, setlistNotes] = useState([])
  const [page, setPage] = useState(0)
  const [totalItems, setTotalItems] = useState(0)
  const [loading, setloading] = useState(false)

  useEffect(() => {
    setlistNotes([])
    setPage(0)
  }, [conversation._id])

  const fill = useCallback(async () => {
    setloading(true)
    const result = await notesActions.getNotesList(
      conversation._id,
      page,
      TABLE_FETCH_PAGE_SIZE
    )
    if (result.success) {
      let list = result.data.notes
      let oldList = listNotes
      if (page > 0) {
        list = oldList.concat(list)
      }
      setlistNotes(list)
    }
    setTotalItems(result.data.total_items)
    setloading(false)
  }, [conversation._id, page])

  useEffect(() => {
    fill()
  }, [fill])

  return (
    <div className='message-tab-pane-wrapper'>
      <List
        loading={loading}
        dataSource={listNotes}
        header={
          <div className='tab-note-header'>
            {getText('WORD_NOTES')}
            <AddNoteModal
              conversation={conversation}
              screenSize={screenSize}
              trigger={<MatButton buttonText={getText('WORD_PLUS_ADD_NOTE')} />}
              onSuccess={(data) => {
                setlistNotes([data.note, ...listNotes])
              }}
            />
          </div>
        }
        renderItem={(item) => {
          return (
            <List.Item key={item._id} className='note-list-item'>
              <div className='message-note-item-wrapper'>
                <div className='item-header'>
                  <span className='name'>{item.user_name}</span>
                  <span className='date'>{formatDateTime(item.createdAt)}</span>
                </div>
                {item.text.length > 150 ? (
                  <LinesEllipsis
                    className='text'
                    maxLine='4'
                    ellipsis='...'
                    trimRight
                    text={item.text}
                  />
                ) : (
                  <div className='text'>{item.text}</div>
                )}
                {item.text.length > 150 ? (
                  <AddNoteModal
                    conversation={conversation}
                    note={item}
                    minRows={5}
                    trigger={
                      <MatButton
                        icon={<SVGArrowRight />}
                        buttonText={getText('ACTION_SHOW_MORE')}
                        typeButton={'white'}
                      />
                    }
                  />
                ) : null}
              </div>
            </List.Item>
          )
        }}
      />
      {totalItems > listNotes.length && (
        <MatButton
          buttonText={getText('ACTION_SHOW_MORE')}
          htmlType={'button'}
          loading={loading}
          style={{ margin: '0 auto 20px' }}
          onClick={() => {
            setPage(page + 1)
          }}
        />
      )}
    </div>
  )
}

export default memo(MessageTabNote)
