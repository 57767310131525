import React from 'react'
import SVGBotHeader from '../../../../../icons/SVG/SVGBotHeader'
import { getText } from '../../../../../lang'
import ChartContent from './ChartContent'
import './BotAndRating.scss'

function BotAndRating({
  totalLeads,
  messagesSent,
  aibotPercentage,
  menuOpen,
  screenSize,
}) {
  return (
    <div className={`bot-and-rating${menuOpen ? ' content-width' : ''}`}>
      <div className='header'>
        <div className='header-icon'>
          <SVGBotHeader />
        </div>
        <div className='header-text'>{getText('TEXT_DRIP_SEQUENCE_AT_WORK')}</div>
      </div>
      <ChartContent
        messagesSent={messagesSent || 0}
        totalLeads={totalLeads || 0}
        screenSize={screenSize}
        aibotPercentage={aibotPercentage || 0}
      />
    </div>
  )
}

export default BotAndRating
