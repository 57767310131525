import React, { useState, useEffect } from 'react'
import MatSelect from '../MatSelect'

const CustomTimePicker = ({
  hourValueInit,
  minuteValueInit,
  systemHourInit,
  onChange,
  index,
}) => {
  const [minuteValue, setminuteValue] = useState(minuteValueInit)
  const [hourValue, sethourValue] = useState(hourValueInit)
  const [systemHour, setsystemHour] = useState(systemHourInit)

  const handleChange = (e) => {
    const { maxLength, value, name } = e.target

    const fieldIndex = name.match(/-(\d+)(?:_|$)/)

    let fieldIntIndex = parseInt(fieldIndex[1], 10) + 1

    if (value.length >= maxLength) {
      // if (fieldIntIndex < 2) {
      const nextfield = document.querySelector(
        `input[name=field-${fieldIntIndex}${index ? `_${index}` : ''}]`
      )
      if (nextfield !== null) {
        nextfield.focus()
      }
      // }
    }
  }

  useEffect(() => {
    onChange && onChange(`${hourValue}:${minuteValue} ${systemHour}`)
  }, [minuteValue, hourValue, systemHour])

  return (
    <div className='time-picker-block-wrapper'>
      <div className='with-select'>
        <div className='time-picker-block'>
          <input
            name={`field-1${index ? `_${index}` : ''}`}
            value={hourValue}
            type='number'
            inputMode='numeric'
            onChange={(e) => {
              if (e.target.value.length === 3) {
                return false
              }
              if (e.target.value > 12 || e.target.value < 0) {
                return false
              }
              sethourValue(e.target.value)
              handleChange(e)
            }}
            onBlur={(e) => {
              if (e.target.value.length === 1) {
                sethourValue(e.target.value.toString().padStart(2, '0'))
                return
              }
            }}
            maxLength={2}
          />
          <div className='point'>:</div>
          <input
            name={`field-2${index ? `_${index}` : ''}`}
            value={minuteValue}
            type='number'
            inputMode='numeric'
            onChange={(e) => {
              if (e.target.value.length === 3) {
                return false
              }
              if (e.target.value > 59 || e.target.value < 0) {
                return false
              }
              setminuteValue(e.target.value)
              handleChange(e)
            }}
            onBlur={(e) => {
              if (e.target.value.length === 1) {
                setminuteValue('0' + e.target.value)
              }
            }}
            maxLength={2}
          />
        </div>
        <MatSelect
          value={systemHour}
          options={[
            {
              label: 'AM',
              value: 'AM',
            },
            {
              label: 'PM',
              value: 'PM',
            },
          ]}
          onSelectItem={(item) => {
            setsystemHour(item.value)
          }}
        />
      </div>
    </div>
  )
}

export default CustomTimePicker
