import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = ({ color }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
  >
    <rect width='24' height='24' rx='6' fill={color} />
    <path
      d='M15.108 7.44598C15.108 6.64799 15.756 6 16.554 6C17.352 6 18 6.64799 18 7.44598C18 8.24397 17.352 8.89196 16.554 8.89196C15.756 8.89196 15.108 8.24397 15.108 7.44598Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.07 7.06816C14.046 7.21816 14.034 7.36816 14.034 7.51816C14.034 8.86814 15.126 9.95952 16.4699 9.95952C16.6199 9.95952 16.7639 9.94212 16.9139 9.91812V14.7594C16.9139 16.794 15.714 18 13.674 18H9.24058C7.19999 18 6 16.794 6 14.7594V10.3201C6 8.28015 7.19999 7.06816 9.24058 7.06816H14.07ZM12.798 13.6555L14.532 11.4181L14.508 11.4301C14.604 11.2981 14.622 11.1301 14.556 10.9801C14.4906 10.8301 14.346 10.7281 14.1906 10.7161C14.028 10.6981 13.8666 10.7701 13.77 10.9021L12.3186 12.7801L10.656 11.4721C10.554 11.3941 10.434 11.3635 10.314 11.3761C10.1946 11.3941 10.0866 11.4595 10.014 11.5555L8.23859 13.8661L8.20199 13.9201C8.09999 14.1115 8.14799 14.3575 8.32799 14.4901C8.41199 14.5441 8.50199 14.5801 8.60399 14.5801C8.74259 14.5861 8.87398 14.5135 8.95798 14.4001L10.464 12.4615L12.174 13.7461L12.228 13.7815C12.42 13.8835 12.66 13.8361 12.798 13.6555Z'
      fill='white'
    />
  </svg>
)

const SVGSmsAutomation = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGSmsAutomation
