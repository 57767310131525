import React from "react";
import Icon from "@ant-design/icons";

const SVGIcon = (props) => (
  <svg
    width="80"
    height="28"
    viewBox="0 0 80 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_22747_241016)">
      <path
        d="M19.5 30C29.9414 30 28.0586 -1 38.5 -1"
        stroke={props.positivecolor || "#E5E9F2"}
        strokeWidth="2"
      />
      <path
        d="M41.5 -1C51.9414 -1 50.0586 30 60.5 30"
        stroke={props.noreplycolor || "#E5E9F2"}
        strokeWidth="2"
      />
      <rect
        width="2"
        height="31"
        transform="matrix(1 0 0 -1 38.5 30)"
        fill={props.negativecolor || "#E5E9F2"}
      />
    </g>
    <defs>
      <clipPath id="clip0_22747_241016">
        <rect width="80" height="28" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const SVGWorkflowLinears = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />;
      }}
      {...props}
    />
  );
};

export default SVGWorkflowLinears;
