import React from 'react'
import Icon from '@ant-design/icons'
import { MainColor } from '../../utils'

const SVGIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={props.width || '18'}
    height={props.height || '18'}
    viewBox='0 0 18 18'
  >
    <g id='Group_8768' data-name='Group 8768' transform='translate(-229 -151)'>
      <circle
        id='Ellipse_684'
        data-name='Ellipse 684'
        cx='9'
        cy='9'
        r='9'
        transform='translate(229 151)'
        fill={props.color || MainColor}
        opacity='0.1'
      />
      <path
        id='notifications-24px'
        d='M7.9,12.005a.978.978,0,0,0,.975-.975H6.925A.975.975,0,0,0,7.9,12.005Zm2.925-2.925V6.643A2.97,2.97,0,0,0,8.631,3.563V3.231a.731.731,0,1,0-1.462,0v.331A2.961,2.961,0,0,0,4.975,6.643V9.081L4,10.055v.487h7.8v-.487Z'
        transform='translate(234.854 149.493) rotate(30)'
        fill={props.color || MainColor}
      />
    </g>
  </svg>
)

const SVGBellEnable = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGBellEnable
