import React from 'react'
import PropTypes from 'prop-types'

function AssistedAppointmentsItem({
  value,
  title,
  color,
  BgComponent,
  extraTitle = '',
  marginBottom,
  onClick,
}) {
  return (
    <div
      className='matador-impact-item'
      style={value === 0 ? { filter: 'grayscale(1)' } : {}}
    >
      <div className='matador-impact-bg'>
        <BgComponent hide={value === 0 ? 'true' : undefined} />
        <div className='matador-impact-value-wrap'>
          <>
            <div
              className='matador-impact-value'
              style={marginBottom ? { ...marginBottom, color } : { color }}
              onClick={() => {
                onClick && onClick('assisted_appointments')
              }}
            >
              {value || ''}
            </div>
            {extraTitle && (
              <div className='matador-impact-extra-title' style={{ color }}>
                {extraTitle}
              </div>
            )}
            <div className='matador-impact-title' style={{ color }}>
              {title}
            </div>
          </>
        </div>
      </div>
    </div>
  )
}

export default React.memo(AssistedAppointmentsItem)

AssistedAppointmentsItem.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  BgComponent: PropTypes.func,
  extraTitle: PropTypes.string,
  marginBottom: PropTypes.instanceOf(Object),
  menuOpen: PropTypes.bool,
}
