import React from 'react'
import SVGChecked from '../../../../icons/SVG/SVGChecked'

const TouchpointAssignUserItem = (props) => {
  const { className, index, onClick, name, checked, lastItem, style } = props

  return (
    <div
      className={`user-field ${lastItem ? 'not-border-bottom' : ''} ${
        className || ''
      }`}
      key={`${index} + "_" + ${name} `}
      onClick={() => {
        onClick && onClick()
      }}
      style={style}
    >
      <div className='user-field-text'>{name}</div>
      {checked && <SVGChecked />}
    </div>
  )
}

export default TouchpointAssignUserItem
